import { Formik } from 'formik';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  Button,
} from '@chakra-ui/react';

import AtsDropZone from 'components/app/Dropzone';
import ScrollToFieldError from 'components/app/ScrollError';

import { LeadsAttachmentModalProps } from './types';
import { useAddAttachmentServices } from './useAddAttachmentServices';

export const LeadsAddAttachmentModal = ({
  isOpen,
  onClose,
}: LeadsAttachmentModalProps) => {
  const { initialValues, prefixStr, changePrefix, uploading, submitAgreement } =
    useAddAttachmentServices({ onClose });
  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={() => {
        onClose();
      }}
      size="3xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent fontFamily="NunitoSans Regular">
        <ModalHeader
          background="default.white.800"
          borderRadius="4px 4px 0"
          p="18px 32px"
          fontSize="18px"
          lineHeight="21.92px"
          textAlign="left"
        >
          Add Attachments
        </ModalHeader>
        <ModalCloseButton
          top="13px"
          right="20px"
          color="default.secondarytext"
        />
        <ModalBody borderRadius="0 0 4px 4px" p={0}>
          <Formik initialValues={initialValues} onSubmit={submitAgreement}>
            {({ values, handleSubmit, handleChange }) => (
              <form onSubmit={handleSubmit}>
                <ScrollToFieldError />
                <Box p="32px">
                  <Box mb="34px">
                    <FormControl>
                      <FormLabel
                        fontSize="14px"
                        lineHeight="18px"
                        color="default.secondarytext"
                      >
                        File Name
                      </FormLabel>
                      <Input
                        name="prefix"
                        type="text"
                        placeholder="File Name"
                        variant="outline"
                        value={prefixStr}
                        onChange={changePrefix}
                      />
                    </FormControl>
                  </Box>
                  <AtsDropZone
                    multipleFile={true}
                    uploadedEnd={(e: any) => {}}
                    deletedFile={(key: string) => {}}
                  />
                </Box>

                <ModalFooter
                  position="sticky"
                  bottom="0"
                  background="default.white.100"
                  boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                >
                  <Button variant="solid" type="submit" disabled={uploading}>
                    Add
                  </Button>
                </ModalFooter>
              </form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
