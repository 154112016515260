import { useLocation, useNavigate } from 'react-router';
import { Tooltip as Tooltip1 } from 'react-tooltip';

import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Badge,
  Radio,
  MenuItem,
  Switch,
  Tr,
  Td,
} from '@chakra-ui/react';

import Button from 'Library/Button';
import FAIcon from 'components/lib/FAIcon';

interface SubTableDataProps {
  handlePrimarySequence: (e: any) => void;
  handleActive: (e: any) => void;
  handleDuplicate: (e: any) => void;
  handleArchive: (e: any) => void;
  content: any;
}

export const SubTableData = ({
  handlePrimarySequence,
  handleActive,
  handleDuplicate,
  handleArchive,
  content,
}: SubTableDataProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const emailPopulate = (data: string[], id: any) => {
    if (data.length > 3) {
      return (
        <Flex gap="10px" justifyContent="space-between">
          {data
            .slice(0, 3)
            .map((val: any) => {
              return val?.outreach_email_connection?.email;
            })
            .join(', ')}
          <Badge
            id={`tagEmail${id}`}
            colorScheme="purple"
            border="0.5px solid #44337a"
            borderRadius="4px"
            height="fit-content"
          >
            + {data.length - 3}
          </Badge>
          <Tooltip1 anchorSelect={`#tagEmail${id}`} place="bottom">
            <Box maxWidth="200px" width="inherit" height="max-content">
              {data
                .slice(3)
                .map((val: any) => {
                  return val?.outreach_email_connection?.email;
                })
                .join(', ')}
            </Box>
          </Tooltip1>
        </Flex>
      );
    }
    return data
      .map((val: any) => {
        return val?.outreach_email_connection?.email;
      })
      .join(', ');
  };

  const sequenceSubTableData = (seq: any) => [
    {
      content: '',
      onClick: () => navigate(`${location.pathname}/candidates/${seq.id}/all`),
      sx: { py: '10px' },
    },
    {
      content: (
        <Radio
          colorScheme="purple"
          isChecked={seq.primary}
          onChange={() => handlePrimarySequence(seq.id)}
        />
      ),
      onClick: () => {},
      sx: { py: '10px' },
    },
    {
      content: seq.title,
      onClick: () => navigate(`${location.pathname}/candidates/${seq.id}/all`),
      sx: {
        py: '10px',
        fontWeight: 700,
        color: '#2B2D42',
      },
    },
    {
      content:
        seq?.mailboxes?.length > 0 && emailPopulate(seq?.mailboxes, seq.id),
      onClick: () => navigate(`${location.pathname}/candidates/${seq.id}/all`),
      sx: {
        maxWidth: '300px',
        whiteSpace: 'pre-line',
        py: '10px',
      },
    },
    {
      content: seq.sequence_candidate_count,
      onClick: () => navigate(`${location.pathname}/candidates/${seq.id}/all`),
      sx: {
        py: '10px',
        fontWeight: 700,
        color: '#2B2D42',
      },
    },
    {
      content: (
        <Switch
          value={seq.active === true ? 'true' : 'false'}
          defaultChecked={seq.active}
          onChange={(e) => {
            handleActive({
              id: seq.id,
              val: e.target.checked,
            });
          }}
          sx={{
            '.chakra-switch__track': {
              bgColor: '#0000000D',
            },
            '.chakra-switch__track[data-checked]:not([data-theme])': {
              bgColor: '#CEB8FF',
            },
          }}
        />
      ),
      onClick: () => {},
      sx: {},
    },
    {
      content: (
        <Menu closeOnSelect={false}>
          <MenuButton>
            <Button
              htmlType="menu"
              variant="ghost"
              iconName="ellipsis-h"
              iconSize="xl"
            />
          </MenuButton>
          <MenuList fontSize="12px" lineHeight="16px" fontWeight="600">
            <MenuItem
              icon={<FAIcon iconName="copy" />}
              onClick={() => handleDuplicate(seq.id)}
            >
              Duplicate
            </MenuItem>
            <MenuItem icon={<FAIcon iconName="plus" />} onClick={() => {}}>
              Add to Buzz
            </MenuItem>
            <MenuItem
              icon={<FAIcon iconName="trash" />}
              onClick={() => handleArchive(seq.id)}
            >
              Move to Archive
            </MenuItem>
          </MenuList>
        </Menu>
      ),
      onClick: () => {},
      sx: {
        py: '10px',
        textAlign: 'right',
      },
    },
  ];

  return (
    <>
      {content.map((seq: any, index: number) => {
        return (
          <Tr
            key={index}
            cursor="pointer"
            _hover={{
              bg: '#FAF5FF',
            }}
          >
            {sequenceSubTableData(seq).map((val, index) => (
              <Td key={index} sx={val.sx} onClick={val.onClick}>
                {val.content}
              </Td>
            ))}
          </Tr>
        );
      })}
    </>
  );
};
