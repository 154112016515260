import { Box, Stack, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useRouteError } from 'react-router';
import * as Sentry from '@sentry/react';
const sentry = process.env.REACT_APP_SENTRY;

export default function RouterErrorBoundary() {
  const error = useRouteError() as Error;
  console.log('🚀 ~ RouterErrorBoundary ~ error:', error);

  useEffect(() => {
    //logging in sentry
    if (sentry) {
      Sentry.captureException(error);
    }
  }, [error]);

  return (
    <Stack
      alignItems="center"
      pt={10}
      gap={3}
      w="80%"
      mx="auto"
      height="calc(100vh - 70px)"
    >
      <Text fontSize="xl" fontWeight="700" textAlign="center">
        Something went wrong
      </Text>
      <Text fontSize="medium" textAlign={'center'}>
        {error.message}
      </Text>
    </Stack>
  );
}
