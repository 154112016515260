export const SIDE_BAR_ITEMS = [
  {
    id: 1,
    label: 'SMS Automation',
    to: 'sms-automation',
  },
  {
    id: 2,
    label: 'SMS History',
    to: 'sms-history',
  },
];
