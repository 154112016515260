import { useSelector } from 'react-redux';

import {
  Box,
  Flex,
  GridItem,
  Image,
  Link,
  SimpleGrid,
  List,
  ListItem,
  useDisclosure,
} from '@chakra-ui/react';

import GoTo from '../../../assets/images/goto.svg';
import { useState } from 'react';
import ProfileCard from 'components/profile-card.component';
import { StreamAttachmentModal } from 'modules/Leads/components/detail-drawer/TabList/TabPanel/Attachments/Stream';

const ApprovalsDetails = () => {
  const { approvalData, approvalDetails } = useSelector(
    (state: any) => state.approvals
  );
  const [fileData, setFileData] = useState(null);
  const {
    isOpen: isStreamOpen,
    onOpen: onStreamOpen,
    onClose: onStreamClose,
  } = useDisclosure();

  return (
    <Box fontFamily="NunitoSans Regular" py={4}>
      <Flex gap={2}>
        <Box
          width="50%"
          py={4}
          borderRight="1px solid"
          borderColor="default.borderlight"
        >
          <GridItem>
            <Box>
              <SimpleGrid
                columns={2}
                w="90%"
                marginBottom="28px"
                fontWeight={500}
                fontSize="16px"
                lineHeight="18px"
              >
                <GridItem color="default.gray.600">Approval For</GridItem>
                <GridItem>
                  <Link
                    href={`mailto:${approvalData?.lead?.primary_email}`}
                    target="_blank"
                    style={{ display: 'flex', gap: '7px' }}
                    rel="noreferrer"
                  >
                    {approvalData?.lead?.primary_email}
                  </Link>
                </GridItem>
              </SimpleGrid>
              <SimpleGrid
                columns={2}
                w="90%"
                margin="28px 0"
                fontWeight={500}
                fontSize="16px"
                lineHeight="18px"
              >
                <GridItem color="default.gray.600">Created by</GridItem>
                <GridItem>
                  <ProfileCard
                    // id={approvalData?.user?.id}
                    id="2"
                    user_id={approvalData?.user?.id}
                    name={[
                      approvalData?.user?.first_name,
                      approvalData?.user?.last_name,
                    ].join(' ')}
                    email={approvalData?.user?.email}
                    thumbnail={approvalData?.user?.thumbnailSignedUrl}
                    profile={approvalData?.user?.signedUrl}
                  />
                </GridItem>
              </SimpleGrid>
              <SimpleGrid
                columns={2}
                w="90%"
                margin="28px 0"
                fontWeight={500}
                fontSize="16px"
                lineHeight="18px"
              >
                <GridItem color="default.gray.600">Lead Source</GridItem>
                <GridItem>{approvalData?.lead?.lead_source?.source}</GridItem>
              </SimpleGrid>
              <SimpleGrid
                columns={2}
                w="90%"
                margin="28px 0"
                fontWeight={500}
                fontSize="16px"
                lineHeight="18px"
              >
                <GridItem color="default.gray.600">Linkedin link</GridItem>
                <GridItem>
                  <Link
                    href={`${approvalData?.lead?.linked_in_url}`}
                    target="_blank"
                    style={{ display: 'flex', gap: '7px' }}
                    rel="noreferrer"
                  >
                    {approvalData?.lead?.linked_in_url}
                    {approvalData?.lead?.linked_in_url && (
                      <span>
                        <Image src={GoTo} alt="logo" />
                      </span>
                    )}
                  </Link>
                </GridItem>
              </SimpleGrid>
              <SimpleGrid
                columns={2}
                w="90%"
                margin="28px 0"
                fontWeight={500}
                fontSize="16px"
                lineHeight="18px"
              >
                <GridItem color="default.gray.600">Client Job Link</GridItem>
                <GridItem>
                  <Link
                    href={`${approvalData?.lead?.client_job_link}`}
                    target="_blank"
                    style={{ display: 'flex', gap: '7px' }}
                    rel="noreferrer"
                  >
                    {approvalData?.lead?.client_job_link}
                    {approvalData?.lead?.client_job_link && (
                      <span>
                        <Image src={GoTo} alt="logo" />
                      </span>
                    )}
                  </Link>
                </GridItem>
              </SimpleGrid>
              <SimpleGrid
                columns={2}
                w="90%"
                margin="28px 0"
                fontWeight={500}
                fontSize="16px"
                lineHeight="18px"
              >
                <GridItem color="default.gray.600">Email</GridItem>
                <GridItem>
                  <Link
                    href={`mailto:${approvalData?.lead?.primary_email}`}
                    target="_blank"
                    style={{ display: 'flex', gap: '7px' }}
                    rel="noreferrer"
                  >
                    {approvalData?.lead?.primary_email}
                  </Link>
                </GridItem>
              </SimpleGrid>
              <SimpleGrid
                columns={2}
                w="90%"
                margin="28px 0"
                fontWeight={500}
                fontSize="16px"
                lineHeight="18px"
              >
                <GridItem color="default.gray.600">Work Phone</GridItem>
                <GridItem>
                  <Link href={`tel:${approvalData?.lead?.work_phone}`}>
                    {approvalData?.lead?.extension &&
                    approvalData?.lead?.extension !== '' ? (
                      <span>
                        {approvalData?.lead?.work_phone}, Ext.{' '}
                        {approvalData?.lead?.extension}
                      </span>
                    ) : (
                      <span>{approvalData?.lead?.work_phone}</span>
                    )}
                  </Link>
                </GridItem>
              </SimpleGrid>
              <SimpleGrid
                columns={2}
                w="90%"
                margin="28px 0"
                fontWeight={500}
                fontSize="16px"
                lineHeight="18px"
              >
                <GridItem color="default.gray.600">Personal Phone</GridItem>
                <GridItem>
                  <Link href={`tel:${approvalData?.lead?.personal_phone}`}>
                    {approvalData?.lead?.personal_phone}
                  </Link>
                </GridItem>
              </SimpleGrid>
            </Box>
          </GridItem>
        </Box>
        <Box width="50%" py={4}>
          <GridItem>
            <Flex gap="18px" alignItems="center" textAlign="center" mb="18px">
              <Box
                pt="20px"
                pb="14px"
                width="249px"
                border="0.5px solid rgba(197, 197, 197, 0.8)"
                borderRadius="4px"
              >
                <Box
                  fontWeight={700}
                  fontSize="20px"
                  lineHeight="18px"
                  marginBottom="8px"
                >
                  {approvalDetails?.jobSummary?.total_opening}
                </Box>
                <Box
                  fontWeight={500}
                  fontSize="14px"
                  lineHeight="18px"
                  color="#6B6C7B"
                >
                  Open Jobs
                </Box>
              </Box>
              <Box
                pt="20px"
                pb="14px"
                width="249px"
                border="0.5px solid rgba(197, 197, 197, 0.8)"
                borderRadius="4px"
              >
                <Box
                  fontWeight={700}
                  fontSize="20px"
                  lineHeight="18px"
                  marginBottom="8px"
                >
                  {approvalDetails?.jobSummary?.total_interview}
                </Box>
                <Box
                  fontWeight={500}
                  fontSize="14px"
                  lineHeight="18px"
                  color="#6B6C7B"
                >
                  Interviews
                </Box>
              </Box>
              <Box
                pt="20px"
                pb="14px"
                width="249px"
                border="0.5px solid rgba(197, 197, 197, 0.8)"
                borderRadius="4px"
              >
                <Box
                  fontWeight={700}
                  fontSize="20px"
                  lineHeight="18px"
                  marginBottom="8px"
                >
                  {approvalDetails?.jobSummary?.total_submits}
                </Box>
                <Box
                  fontWeight={500}
                  fontSize="14px"
                  lineHeight="18px"
                  color="#6B6C7B"
                >
                  Submits
                </Box>
              </Box>
            </Flex>
          </GridItem>

          <Box
            fontSize="18px"
            fontWeight="600"
            color="default.primarytext"
            mb={2}
            width="100%"
          >
            Supporting Documents
          </Box>
          <List spacing={2}>
            {approvalDetails.att?.length > 0 &&
              approvalDetails.att.map((data: any) => {
                return (
                  <ListItem>
                    <Link
                      onClick={() => {
                        onStreamOpen();
                        setFileData(data?.attachments);
                      }}
                      style={{ display: 'flex', gap: '7px' }}
                    >
                      {data?.attachments?.original_file_name}
                      <span>
                        <Image src={GoTo} alt="logo" />
                      </span>
                    </Link>
                  </ListItem>
                );
              })}
          </List>
          <Box
            fontSize="18px"
            fontWeight="600"
            color="default.primarytext"
            mb={2}
            width="100%"
            mt={10}
          >
            Notes
          </Box>
          <List spacing={2}>
            {approvalDetails.notes?.length > 0 &&
              approvalDetails.notes.map((data: any) => {
                return (
                  <ListItem>
                    <Box>{data.notes.notes}</Box>
                  </ListItem>
                );
              })}
          </List>
        </Box>
      </Flex>
      {isStreamOpen && (
        <StreamAttachmentModal
          isOpen={isStreamOpen}
          onClose={onStreamClose}
          file={fileData}
        />
      )}
    </Box>
  );
};

export default ApprovalsDetails;
