import { useState, useRef, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import { Input } from '@chakra-ui/react';
import { debounce } from 'lodash';
import { useGetCandidatesMutation } from 'store/candidates.slice';
import { useTRservices } from '../../../Table/useTRservices';

//TODO: Remove direct api call dependency

function DynamicInputWidthForm({
  valueKey,
  candidateData,
  reqUpdate,
  resUpdate,
  reqCandidatesList,
  candidatePgBtn,
  placeholder,
  isMain,
}: any) {
  const spanRef = useRef<HTMLSpanElement | null>(null);
  const [inputWidth, setInputWidth] = useState<number>(
    spanRef.current?.offsetWidth
  );

  const { reloadGroups } = useTRservices();
  const [reqGetCandidate] = useGetCandidatesMutation();

  useEffect(() => {
    const newInputWidth = spanRef.current?.offsetWidth;
    if (inputWidth !== newInputWidth) {
      setInputWidth(newInputWidth + 2);
    }
  }, [spanRef.current?.offsetWidth]);

  useEffect(() => {
    if (resUpdate.isSuccess) {
      reqGetCandidate({ id: candidateData.id });
      reqCandidatesList({ data: candidatePgBtn });
      reloadGroups();
    }
  }, [resUpdate.isSuccess]);

  const onSubmit = debounce(async (e: any) => {
    if (candidateData[valueKey] !== e.target.value) {
      const updateData = { [valueKey]: e.target.value };
      await reqUpdate({ data: updateData, id: candidateData.id });
    }
  }, 500);

  return (
    <>
      <Formik
        initialValues={{
          [valueKey]: candidateData ? candidateData[valueKey] : '',
        }}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({ values, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <span
              ref={spanRef}
              style={{
                position: 'absolute',
                fontSize: isMain ? '22px' : '16px',
                opacity: 0,
                zIndex: -100,
                whiteSpace: 'pre',
              }}
            >
              {values[valueKey] || placeholder}
            </span>
            <Field
              as={Input}
              type="text"
              id={valueKey}
              name={valueKey}
              placeholder={placeholder}
              sx={{
                border: 'none',
                padding: 0,
                fontSize: isMain ? '22px' : '16px',
                fontWeight: isMain ? 700 : 400,
                height: isMain ? '24px' : '17px',
                width: `${inputWidth}px`,
                textTransform: 'capitalize',
              }}
              onChange={(e: any) => {
                handleChange(e);
                const newInputWidth = spanRef.current?.offsetWidth;
                setInputWidth(newInputWidth + 1);
              }}
              onBlur={onSubmit}
            />
          </Form>
        )}
      </Formik>
    </>
  );
}

export default DynamicInputWidthForm;
