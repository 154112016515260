/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { tabOptions } from './constants';

export const useSettingsTabListServices = () => {
  const navigate = useNavigate();
  const { settingType } = useParams();

  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    const tabIndex = tabOptions.indexOf(settingType);
    setSelectedTab(tabIndex >= 0 ? tabIndex : 0);
  }, [settingType]);

  const handleChangeTab = (index: number) => {
    setSelectedTab(index);
    navigate(`/engage/settings/recruitment/${tabOptions[index]}`);
  };

  return {selectedTab, handleChangeTab};
};
