/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { useToast } from '@chakra-ui/react';
import { usePostUpdateScheduleSequenceMutation } from 'store/outreach.slice';

import { DEFAULT_SCHEDULE_SCHEMA } from './constants';

export const useSchedulesServices = () => {
  const params = useParams();
  const toast = useToast();

  const [reqPostScheduleList, resPostScheduleList] =
    usePostUpdateScheduleSequenceMutation();

  const { sequencesData } = useSelector((state: any) => state.outreach);

  const initialValues = {
    timezone: sequencesData?.schedule?.timezone || 'US/Pacific',
    monday_start: sequencesData?.schedule?.monday_start || '00:00',
    monday_end: sequencesData?.schedule?.monday_end || '00:00',
    tuesday_start: sequencesData?.schedule?.tuesday_start || '00:00',
    tuesday_end: sequencesData?.schedule?.tuesday_end || '00:00',
    wednesday_start: sequencesData?.schedule?.wednesday_start || '00:00',
    wednesday_end: sequencesData?.schedule?.wednesday_end || '00:00',
    thursday_start: sequencesData?.schedule?.thursday_start || '00:00',
    thursday_end: sequencesData?.schedule?.thursday_end || '00:00',
    friday_start: sequencesData?.schedule?.friday_start || '00:00',
    friday_end: sequencesData?.schedule?.friday_end || '00:00',
    saturday_start: sequencesData?.schedule?.saturday_start || '00:00',
    saturday_end: sequencesData?.schedule?.saturday_end || '00:00',
    sunday_start: sequencesData?.schedule?.sunday_start || '00:00',
    sunday_end: sequencesData?.schedule?.sunday_end || '00:00',
  };

  const validationSchema = DEFAULT_SCHEDULE_SCHEMA;

  const handleSubmit = (data: any) => {
    reqPostScheduleList({ data: data, id: params.settingTypeId });
  };

  useEffect(() => {
    if (resPostScheduleList.isSuccess) {
      toast({
        title: 'Sequence Schedule saved.',
        description: "We've saved your sequence schedule settings.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostScheduleList.isSuccess]);

  return {
    initialValues,
    validationSchema,
    handleSubmit,
    isLoading: resPostScheduleList.isLoading,
  };
};
