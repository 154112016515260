export const POPULAR_PROMPTS = [
  {
    title: 'Welcome Email',
    prompt: 'Friendly greetings and gratitude messages',
  },
  { title: 'Onboarding Email', prompt: 'Detailed guides for new users' },
  { title: 'Newsletters', prompt: 'Informative updates on various topics' },
  {
    title: 'Promotional Email',
    prompt: 'Announcing employee role advancements',
  },
  {
    title: 'Seasonal Email',
    prompt: 'Festive greetings for special occasions',
  },
  {
    title: 'Transactional Email',
    prompt: 'Confirmation and status update notifications',
  },
  {
    title: 'Social Proof Email',
    prompt: 'Positive customer experiences and reviews',
  },
  { title: 'Announcement Email', prompt: 'Important news and event updates' },
  {
    title: 'Re-engagement Email',
    prompt: 'Encouraging return with special offers',
  },
  { title: 'Internal Email', prompt: 'Essential company news and updates' },
];
