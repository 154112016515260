/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useGetEmailConnectionListMutation,
  usePostEnableSMTPMutation,
} from 'store/outreach.slice';
import { LOGIN_SCHEMA } from './constants';

export const useLoginEmailServices = ({ onClose }: any) => {
  const toast = useToast();
  const { emailConnectionData, emailConnectionPgBtn } = useSelector(
    (state: any) => state.outreach
  );

  const initialValue = {
    login: emailConnectionData?.email || '',
    password: '',
  };

  const validationSchema: any = LOGIN_SCHEMA;

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const [reqPostEnableSMTP, resPostEnableSMTP] = usePostEnableSMTPMutation();
  const [reqGetEmailConnectionList] = useGetEmailConnectionListMutation();

  const handleSMTP = (data: any) => {
    const payload = {
      password: data.password,
    };

    reqPostEnableSMTP({ id: emailConnectionData.id, data: payload });
    console.log(data);
  };

  useEffect(() => {
    const postSMTP = resPostEnableSMTP?.data?.data?.data;
    if (postSMTP?.status === 1) {
      onClose();

      reqGetEmailConnectionList(emailConnectionPgBtn);
      toast({
        title: 'Email enabled SMTP.',
        description: "We've enabled your account's SMTP.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    } else if (postSMTP?.status === 0) {
      toast({
        title: 'Email enabled SMTP unsuccessful.',
        description: postSMTP?.error,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostEnableSMTP?.data?.data?.data?.status]);

  return {
    initialValue,
    validationSchema,
    show,
    handleClick,
    handleSMTP,
    isLoading: resPostEnableSMTP.isLoading,
  };
};
