/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { BarChart } from '@mui/x-charts';

import { ThemeProvider } from '@mui/system';
import { createTheme } from '@mui/material';

import BG from 'assets/images/bg.svg';
import BG1 from 'assets/images/bg1.svg';

import MdAccount from 'assets/images/MdAccount.gif';
import MdCall from 'assets/images/MdCall.gif';
import MdInbox from 'assets/images/MdInbox.gif';
import MdAssignment from 'assets/images/MdAssignment.gif';

import MdCall1 from 'assets/images/MdCall1.svg';
import MdSend from 'assets/images/MdSend.svg';
import MdCheck from 'assets/images/MdCheck.svg';
import MdFace from 'assets/images/MdFace.svg';
import MdOffer from 'assets/images/MdOffer.svg';

import MdUp from 'assets/images/MdUp.svg';
import MdDown from 'assets/images/MdDown.svg';
import Button from 'Library/Button';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  useActivityListMutation,
  useGetDealsMutation,
  useGetKanbanMutation,
  useGetWidgetsMutation,
} from 'store/dashboard.slice';

import { formatDigits } from 'utils/utilFormat';
import { byActivity, byMonth, chartMonths, tableHeader } from './constants';
import DahsboardActivity from './Activities';

const RecruiterDashboard = () => {
  const MuiTheme = createTheme({
    palette: {
      mode: 'light',
    },
  });

  const { first_name, id } = useSelector((state: any) => state.auth);
  const { activities, deals, widgets, kanban } = useSelector(
    (state: any) => state.dashboard
  );

  const [reqDeals] = useGetDealsMutation();
  const [reqWidgets] = useGetWidgetsMutation();
  const [reqKanban] = useGetKanbanMutation();
  const [reqActivity] = useActivityListMutation();

  const [selectByMonth, setByMonth] = useState(0);
  const [selectByActivity, setByActivity] = useState('current quarter');
  const [greeting, setGreeting] = useState('');

  useEffect(() => {
    reqDeals({ type: 'individual' });
    reqWidgets({});
    reqKanban({});
  }, []);
  const { hasSwitchedAccount } = useSelector((state: any) => state.app);

  // Cross check if Id is present, as recruiters key needs to have a non-null value while fetching data for recruiter dashboard
  // In case of fetching data of a switched user, use hasSwitchedAccount user.
  useEffect(() => {
    reqActivity({
      data: { target: selectByActivity, recruiters: hasSwitchedAccount != null ? hasSwitchedAccount.user_id : id },
    });
  }, [selectByActivity, id, hasSwitchedAccount]);

  const [userFirstName, setUserFirstName] = useState<string>(first_name);


  useEffect(() => {
    if (hasSwitchedAccount !== null) {
      setUserFirstName(
        hasSwitchedAccount?.first_name ?? hasSwitchedAccount?.last_name
      );
    } else if (hasSwitchedAccount === null) {
      setUserFirstName(first_name);
    }
  }, [hasSwitchedAccount?.first_name, hasSwitchedAccount]);

  return (
    <>
      <Flex sx={{ gap: '16px', mb: '16px' }}>
        <Flex
          sx={{
            borderRadius: '8px',
            background: '#fff',
            p: '16px',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box>
            <Box
              sx={{
                fontSize: '48px',
                lineHeight: '58.46px',
                fontWeight: 400,
                color: '#322659',
              }}
            >
              {widgets.leads}
            </Box>
            <Box
              sx={{
                fontSize: '14px',
                lineHeight: '17.05px',
                fontWeight: 700,
                color: '#718096',
              }}
            >
              Leads
            </Box>
          </Box>
          <Box
            sx={{
              border: '1px solid #E2E8F0',
              borderRadius: '8px',
              p: '10px',
            }}
          >
            <Image sx={{ height: '50px' }} src={MdAccount} />
          </Box>
        </Flex>
        <Flex
          sx={{
            borderRadius: '8px',
            background: '#fff',
            p: '16px',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box>
            <Box
              sx={{
                fontSize: '48px',
                lineHeight: '58.46px',
                fontWeight: 400,
                color: '#322659',
              }}
            >
              {widgets.outbound_calls}
            </Box>
            <Box
              sx={{
                fontSize: '14px',
                lineHeight: '17.05px',
                fontWeight: 700,
                color: '#718096',
              }}
            >
              Outbound Calls
            </Box>
          </Box>
          <Box
            sx={{
              border: '1px solid #E2E8F0',
              borderRadius: '8px',
              p: '10px',
            }}
          >
            <Image sx={{ height: '50px' }} src={MdCall} />
          </Box>
        </Flex>
        <Flex
          sx={{
            borderRadius: '8px',
            background: '#fff',
            p: '16px',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box>
            <Box
              sx={{
                fontSize: '48px',
                lineHeight: '58.46px',
                fontWeight: 400,
                color: '#322659',
              }}
            >
              {widgets.send_emails}
            </Box>
            <Box
              sx={{
                fontSize: '14px',
                lineHeight: '17.05px',
                fontWeight: 700,
                color: '#718096',
              }}
            >
              Sent Emails
            </Box>
          </Box>
          <Box
            sx={{
              border: '1px solid #E2E8F0',
              borderRadius: '8px',
              p: '10px',
            }}
          >
            <Image sx={{ height: '50px' }} src={MdInbox} />
          </Box>
        </Flex>
        <Flex
          sx={{
            borderRadius: '8px',
            background: '#fff',
            p: '16px',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box>
            <Box
              sx={{
                fontSize: '48px',
                lineHeight: '58.46px',
                fontWeight: 400,
                color: '#322659',
              }}
            >
              {widgets.tasks}
            </Box>
            <Box
              sx={{
                fontSize: '14px',
                lineHeight: '17.05px',
                fontWeight: 700,
                color: '#718096',
              }}
            >
              Tasks
            </Box>
          </Box>
          <Box
            sx={{
              border: '1px solid #E2E8F0',
              borderRadius: '8px',
              p: '10px',
            }}
          >
            <Image sx={{ height: '50px' }} src={MdAssignment} />
          </Box>
        </Flex>
      </Flex>

      <Flex sx={{ gap: '16px', mb: '16px' }}>
        <Flex
          sx={{
            width: '20%',
            flexDir: ' column',
            borderRadius: '8px',
            background: '#fff',
            backgroundImage: BG1,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom',
            p: '8px 16px',
          }}
        >
          <Box
            sx={{
              fontSize: '16px',
              lineHeight: '20px',
              fontWeight: 700,
              color: '#718096',
              boxShadow: '0px -3px 7px rgba(0, 0, 0, 0.05)',
            }}
          >
            Deals Closed
          </Box>
          <Box
            sx={{
              fontSize: '50px',
              lineHeight: '73.08px',
              fontWeight: 700,
              color: 'primary.500',
              textAlign: 'center',
              py: '88px',
            }}
          >
            {formatDigits(deals?.closed?.total_revenue)}
          </Box>
        </Flex>
        <Box
          sx={{
            width: '80%',
            borderRadius: '8px',
            background: '#fff',
            p: '8px 16px',
            boxShadow: '0px -3px 7px rgba(0, 0, 0, 0.05)',
          }}
        >
          <Flex sx={{ gap: '10px', alignItems: 'center' }}>
            <Box
              sx={{
                fontSize: '16px',
                lineHeight: '20px',
                fontWeight: 700,
                color: '#718096',
              }}
            >
              Deals
            </Box>

            <Flex width="175px">
              <Menu closeOnSelect={true}>
                <MenuButton type="button">
                  <Box
                    sx={{
                      button: {
                        p: 0,
                        fontSize: '14px',
                        border: 'none',
                        color: 'primary.500',
                      },

                      span: {
                        mb: '2px',
                      },
                    }}
                  >
                    <Button rightIcon="chevron-down" type="button">
                      {
                        byMonth.filter(
                          (val: any) => val.value === selectByMonth
                        )[0].label
                      }
                    </Button>
                  </Box>
                </MenuButton>
                {
                  <MenuList fontSize="sm" maxHeight="200px" overflowY="auto">
                    {byMonth.map((val: any) => {
                      return (
                        <MenuItem
                          type="button"
                          onClick={() => setByMonth(val.value)}
                        >
                          {val.label}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                }
              </Menu>
            </Flex>
          </Flex>
          <Flex sx={{ gap: '30px' }}>
            <Box>
              <Flex sx={{ py: '24px' }}>
                <Box
                  sx={{
                    height: 'fit-content',
                    border: '1px solid #E2E8F0',
                    borderRadius: '8px',
                    p: '16px',
                  }}
                >
                  <Box
                    as="span"
                    sx={{
                      fontSize: '32px',
                      lineHeight: '38.98px',
                      fontWeight: 400,
                      color: '#171923',
                      mb: '20px',
                    }}
                  >
                    {/* $
                    <Box
                      as="span"
                      sx={{
                        fontSize: '40px',
                        lineHeight: '48.72px',
                        fontWeight: 400,
                        color: '#171923',
                      }}
                    >
                      100
                    </Box>
                    K */}
                    {formatDigits(deals?.current_month?.total_revenue)}
                  </Box>
                  <Flex sx={{ gap: '12px', minWidth: '120px' }}>
                    <Box
                      sx={{
                        fontSize: '16px',
                        lineHeight: '20px',
                        fontWeight: 700,
                        color: '#718096',
                      }}
                    >
                      Current Month
                    </Box>
                    <Image src={MdUp} alt="up" />
                  </Flex>
                </Box>
              </Flex>
            </Box>
            <Box
              sx={{
                '.MuiChartsAxis-tickLabel': {
                  fontFamily: 'NunitoSans Regular',
                  fontWeight: 700,
                  fontSize: '14px !important',
                  fill: '#718096 !important',
                },
                height: '30vh',
                width: '100%',
              }}
            >
              <ThemeProvider theme={MuiTheme}>
                <BarChart
                  xAxis={[
                    {
                      id: 'barCategories',
                      data: chartMonths,
                      scaleType: 'band',
                    },
                  ]}
                  series={[
                    {
                      data:
                        deals?.chart?.length > 0
                          ? deals?.chart?.map((val: any) => val.total_revenue)
                          : [0],
                      color: '#805AD5',
                    },
                  ]}
                  yAxis={[
                    {
                      id: 'valueAxis',
                      position: 'left',
                      valueFormatter: (value) => formatDigits(value),
                    },
                  ]}
                />
              </ThemeProvider>
            </Box>
          </Flex>
        </Box>
      </Flex>

      <Box
        sx={{
          borderRadius: '8px',
          background: '#fff',
          mb: '16px',
          p: '8px 16px',
        }}
      >
        <DahsboardActivity
          byActivity={byActivity}
          selectByActivity={selectByActivity}
          setByActivity={(e) => setByActivity(e)}
          byUser={null}
          setSelectByUser={(e) => console.log(e)}
          selectByUser={hasSwitchedAccount != null ? hasSwitchedAccount.user_id : id}
          activities={activities}
          isrecruiters={true}
        />
      </Box>

      <Box sx={{ borderRadius: '8px', background: '#fff', p: '8px 16px' }}>
        <Box
          sx={{
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: 700,
            color: '#718096',
            mb: '8px',
          }}
        >
          Deals and Pipelines
        </Box>

        <Flex
          sx={{
            gap: '16px',
            mt: '24px',
            mb: '16px',
            alignItems: 'flex-start',
          }}
        >
          <Box
            sx={{
              height: 'fit-content',
              border: '1px solid #E2E8F0',
              borderRadius: '8px',
              p: '16px',
            }}
          >
            <Box
              as="span"
              sx={{
                fontSize: '40px',
                lineHeight: '48.72px',
                fontWeight: 400,
                color: '#171923',
                width: '200px',
              }}
            >
              {formatDigits(kanban?.total)}
            </Box>
            <Flex sx={{ justifyContent: 'space-between', minWidth: '170px' }}>
              <Box
                sx={{
                  fontSize: '16px',
                  lineHeight: '20px',
                  fontWeight: 700,
                  color: '#718096',
                }}
              >
                Total
              </Box>
              <Image src={MdUp} alt="Up" />
            </Flex>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Flex
              sx={{
                bg: '#CBD5E0',
                borderRadius: '8px',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: '18px 8px',
                width: '100%',
              }}
            >
              <Box sx={{ color: '#322659' }}>
                <Box
                  sx={{
                    fontSize: '24px',
                    lineHeight: '20px',
                    fontWeight: 400,
                    mb: '4px',
                  }}
                >
                  {formatDigits(kanban?.exploration?.amount)}
                </Box>
                <Box
                  sx={{
                    fontSize: '10px',
                    lineHeight: '20px',
                    fontWeight: 700,
                  }}
                >
                  {kanban?.exploration?.total} Exploration calls
                </Box>
              </Box>
              <Box
                sx={{
                  p: '10px',
                }}
              >
                <Image src={MdCall1} />
              </Box>
            </Flex>
            <Flex
              sx={{
                flexDirection: 'column',
                p: '8px',
                color: '#322659',
                gap: '10px',
              }}
            >
              {kanban?.exploration?.data?.map((val: any) => {
                return (
                  <Box
                    sx={{
                      border: '1px solid #E2E8F0',
                      borderRadius: '8px',
                      p: '8px',
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: '14px',
                        lineHeight: '17.05px',
                        fontWeight: 700,
                        mb: '8px',
                      }}
                    >
                      {val.title}
                    </Box>
                    <Box
                      sx={{
                        fontSize: '10px',
                        lineHeight: '12.18px',
                        fontWeight: 400,
                      }}
                    >
                      Amount: {formatDigits(val.amount)}
                    </Box>
                  </Box>
                );
              })}
            </Flex>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Flex
              sx={{
                bg: '#3182CE',
                borderRadius: '8px',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: '18px 8px',
                width: '100%',
              }}
            >
              <Box sx={{ color: '#fff' }}>
                <Box
                  sx={{
                    fontSize: '24px',
                    lineHeight: '20px',
                    fontWeight: 400,
                    mb: '4px',
                  }}
                >
                  {formatDigits(kanban?.roles_qualified?.amount)}
                </Box>
                <Box
                  sx={{
                    fontSize: '10px',
                    lineHeight: '20px',
                    fontWeight: 700,
                  }}
                >
                  {kanban?.roles_qualified?.total} Role(s) qualified
                </Box>
              </Box>
              <Box
                sx={{
                  p: '10px',
                }}
              >
                <Image src={MdCheck} />
              </Box>
            </Flex>
            <Flex
              sx={{
                flexDirection: 'column',
                p: '8px',
                color: '#322659',
                gap: '10px',
              }}
            >
              {kanban?.roles_qualified?.data?.map((val: any) => {
                return (
                  <Box
                    sx={{
                      border: '1px solid #E2E8F0',
                      borderRadius: '8px',
                      p: '8px',
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: '14px',
                        lineHeight: '17.05px',
                        fontWeight: 700,
                        mb: '8px',
                      }}
                    >
                      {val.title}
                    </Box>
                    <Box
                      sx={{
                        fontSize: '10px',
                        lineHeight: '12.18px',
                        fontWeight: 400,
                      }}
                    >
                      Amount: {formatDigits(val.amount)}
                    </Box>
                  </Box>
                );
              })}
            </Flex>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Flex
              sx={{
                bg: '#805AD5',
                borderRadius: '8px',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: '18px 8px',
                width: '100%',
              }}
            >
              <Box sx={{ color: '#fff' }}>
                <Box
                  sx={{
                    fontSize: '24px',
                    lineHeight: '20px',
                    fontWeight: 400,
                    mb: '4px',
                  }}
                >
                  {formatDigits(kanban?.submitted?.amount)}
                </Box>
                <Box
                  sx={{
                    fontSize: '10px',
                    lineHeight: '20px',
                    fontWeight: 700,
                  }}
                >
                  {kanban?.submitted?.total} candidates submitted
                </Box>
              </Box>
              <Box
                sx={{
                  p: '10px',
                }}
              >
                <Image src={MdSend} />
              </Box>
            </Flex>
            <Flex
              sx={{
                flexDirection: 'column',
                p: '8px',
                color: '#322659',
                gap: '10px',
              }}
            >
              {kanban?.submitted?.data?.map((val: any) => {
                return (
                  <Box
                    sx={{
                      border: '1px solid #E2E8F0',
                      borderRadius: '8px',
                      p: '8px',
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: '14px',
                        lineHeight: '17.05px',
                        fontWeight: 700,
                        mb: '8px',
                      }}
                    >
                      {val.title}
                    </Box>
                    <Box
                      sx={{
                        fontSize: '10px',
                        lineHeight: '12.18px',
                        fontWeight: 400,
                      }}
                    >
                      Amount: {formatDigits(val.amount)}
                    </Box>
                  </Box>
                );
              })}
            </Flex>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Flex
              sx={{
                bg: '#d62ea4',
                borderRadius: '8px',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: '18px 8px',
                width: '100%',
              }}
            >
              <Box sx={{ color: '#fff' }}>
                <Box
                  sx={{
                    fontSize: '24px',
                    lineHeight: '20px',
                    fontWeight: 400,
                    mb: '4px',
                  }}
                >
                  {formatDigits(kanban?.interview?.amount)}
                </Box>
                <Box
                  sx={{
                    fontSize: '10px',
                    lineHeight: '20px',
                    fontWeight: 700,
                  }}
                >
                  {kanban?.interview?.total} interview scheduled
                </Box>
              </Box>
              <Box
                sx={{
                  p: '10px',
                }}
              >
                <Image src={MdFace} />
              </Box>
            </Flex>
            <Flex
              sx={{
                flexDirection: 'column',
                p: '8px',
                color: '#322659',
                gap: '10px',
              }}
            >
              {kanban?.interview?.data?.map((val: any) => {
                return (
                  <Box
                    sx={{
                      border: '1px solid #E2E8F0',
                      borderRadius: '8px',
                      p: '8px',
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: '14px',
                        lineHeight: '17.05px',
                        fontWeight: 700,
                        mb: '8px',
                      }}
                    >
                      {val.title}
                    </Box>
                    <Box
                      sx={{
                        fontSize: '10px',
                        lineHeight: '12.18px',
                        fontWeight: 400,
                      }}
                    >
                      Amount: {formatDigits(val.amount)}
                    </Box>
                  </Box>
                );
              })}
            </Flex>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Flex
              sx={{
                bg: '#38A169',
                borderRadius: '8px',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: '18px 8px',
                width: '100%',
              }}
            >
              <Box sx={{ color: '#fff' }}>
                <Box
                  sx={{
                    fontSize: '24px',
                    lineHeight: '20px',
                    fontWeight: 400,
                    mb: '4px',
                  }}
                >
                  {formatDigits(kanban?.offer?.amount)}
                </Box>
                <Box
                  sx={{
                    fontSize: '10px',
                    lineHeight: '20px',
                    fontWeight: 700,
                  }}
                >
                  {kanban?.offer?.total} offer
                </Box>
              </Box>
              <Box
                sx={{
                  p: '10px',
                }}
              >
                <Image src={MdOffer} />
              </Box>
            </Flex>
            <Flex
              sx={{
                flexDirection: 'column',
                p: '8px',
                color: '#322659',
                gap: '10px',
              }}
            >
              {kanban?.offer?.data?.map((val: any) => {
                return (
                  <Box
                    sx={{
                      border: '1px solid #E2E8F0',
                      borderRadius: '8px',
                      p: '8px',
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: '14px',
                        lineHeight: '17.05px',
                        fontWeight: 700,
                        mb: '8px',
                      }}
                    >
                      {val.title}
                    </Box>
                    <Box
                      sx={{
                        fontSize: '10px',
                        lineHeight: '12.18px',
                        fontWeight: 400,
                      }}
                    >
                      Amount: {formatDigits(val.amount)}
                    </Box>
                  </Box>
                );
              })}
            </Flex>
          </Box>
        </Flex>
      </Box>
    </>
  );
};
export default RecruiterDashboard;
