import { useState, useEffect } from 'react';
import ResumeLoading from '../ResumeLoading';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'
import { useSelector } from 'react-redux';


interface PDFViewerProps {
  pdfUrl: string;
  setResumeUrl?: any;
  height: any;
}

const PDFViewer = ({ pdfUrl, setResumeUrl = null, height }: PDFViewerProps) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadPdf = async () => {
      const response = await fetch(pdfUrl);
      setLoading(response.ok);
      if (setResumeUrl) {
        const pdfData = await response.arrayBuffer();
        const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
        setResumeUrl(URL.createObjectURL(pdfBlob));
      }
    };
    loadPdf();
  }, [pdfUrl]);

  return (
    <>
      {loading && pdfUrl !== '' ? (
        <>
          <iframe
            title="PDF Viewer"
            src={pdfUrl + '#toolbar=1&navpanes=0&scrollbar=0&view=FitH'}
            width="100%"
            style={{ border: 'none', height: height }}
          >
            <meta name="sample" content="sample" />
          </iframe>
        </>
      ) : (
        <ResumeLoading />
      )}
    </>
  );
};

export default PDFViewer;
