import FAIcon from 'components/lib/FAIcon';
import { useEffect, useRef, useState } from 'react';
import { fileApplication } from 'utils/fileApplication';
import {
  ContactInt,
  EmailInt,
  ManualResumeInitValsInt,
  ResumeFormProps,
} from '../../types';
import * as Yup from 'yup';
import {
  getCandidate,
  useListCandidatesMutation,
  useManualWithJobCandidatesMutation,
} from 'store/candidates.slice';
import { useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useListJobsMutation } from 'store/jobs.slice';
import { JobsTypes, swalContent } from 'types';
import { AtsConfirm } from 'utils/swal';
import { useDeleteResumeMutation } from 'store/resumerparse.slice';
import { ResumeInt } from '../../../types';
import {
  useGetCandidateWorkStatusMutation,
  useGetCountriesMutation,
} from 'store/constant.slice';
import { changeUploadName } from 'utils/newFilename';
import { cleanUpload, uploadList } from 'store/uploads.slice';

export function useResumeFormServices({
  resumeData,
  handleSelect,
  selected,
  resumesLength,
  onClose,
  job_id,
  reqUnparsedList,
}: ResumeFormProps) {
  const dispatch = useDispatch();

  const { jobPgBtn } = useSelector((state: JobsTypes) => state.jobs);
  const { uploaded, prefix } = useSelector((state: any) => state.uploads);
  const { countries, candidate_work_status } = useSelector(
    (state: any) => state.constants
  );
  const { candidatePgBtn } = useSelector((state: any) => state.candidates);

  const [reqManualWithJobCreate, resManualWithJobCreate] =
    useManualWithJobCandidatesMutation();
  const [reqCandidates] = useListCandidatesMutation();
  const [reqJobs] = useListJobsMutation();
  const [reqDelResume, resDelResume] = useDeleteResumeMutation();
  const [reqCanWorkStat] = useGetCandidateWorkStatusMutation();
  const [reqCountry] = useGetCountriesMutation();

  const [countryOption, setCountryOption] = useState([]);
  const [countryDefOption, setCountryDefOption] = useState([]);
  const [isPdf, setIsPdf] = useState(true);
  const [hasSkills, setHasSkills] = useState('');
  const [resumeUrl, setResumeUrl] = useState('');
  const [hasTags, setHasTags] = useState('');

  const formRef = useRef<any>(null);

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen: onOpenAlert,
  } = useDisclosure({ defaultIsOpen: false });

  let initialValues: ManualResumeInitValsInt = {
    first_name: '',
    last_name: '',
    linkedin_url: '',
    profile_url: '',
    work_status: '',
    skills: [],
    zip_code: '',
    city: '',
    state_province: '',
    country: countryDefOption,
    // professional
    total_year_of_experience: 0,
    total_months_of_experience: 0,
    latest_job_title: '',
    latest_employer: '',
    latest_salary: 0,
    expected_salary_start: 0,
    expected_salary_end: 0,
    attachments: [],
    // exp
    experiences: [],
    // educ
    educations: [],
    // tags
    tags: [],
    //
    resume_link: '',
    emails: [
      {
        email: '',
        primary: true,
      },
    ],
    // contact
    contact: [
      {
        number: '',
        type: 'phone',
        primary: true,
        category: 'Personal',
      },
    ],
  };

  const contactCategoryOptions = [
    {
      value: 'Personal',
      label: <FAIcon iconName="user" />,
    },
    {
      value: 'Work',
      label: <FAIcon iconName="briefcase" />,
    },
  ];

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      // personal
      first_name: Yup.string(),
      last_name: Yup.string().required('Last name is required.'),
      linkedin_url: Yup.string(),
      profile_url: Yup.string(),
      work_status: Yup.string().nullable(),
      skills: Yup.array().of(Yup.string()),
      zip_code: Yup.string(),
      city: Yup.string(),
      state_province: Yup.string(),
      country: Yup.array().min(1, 'Pick at least 1 country').nullable(),
      // professional
      total_year_of_experience: Yup.number(),
      total_months_of_experience: Yup.number(),
      latest_job_title: Yup.string(),
      latest_employer: Yup.string(),
      latest_salary: Yup.string().matches(/^[\d,]+$/gi, 'Salary is invalid'),
      expected_salary_start: Yup.string().matches(
        /^[\d,]+$/gi,
        'Salary is invalid'
      ),
      expected_salary_end: Yup.string().matches(
        /^[\d,]+$/gi,
        'Salary is invalid'
      ),
      attachments: Yup.array().of(
        Yup.object().shape({
          file_name: Yup.string(),
          file_size: Yup.number(),
          file_type: Yup.string(),
          location: Yup.string(),
          original_file_name: Yup.string(),
        })
      ),
      // exp
      experiences: Yup.array().of(
        Yup.object().shape({
          company: Yup.string(),
          job_title: Yup.string(),
          start_date: Yup.string(),
          end_date: Yup.string(),
          currently_pursuing: Yup.boolean(),
        })
      ),
      // educ
      educations: Yup.array().of(
        Yup.object().shape({
          school: Yup.string(),
          major: Yup.string(),
          start_date: Yup.string(),
          end_date: Yup.string(),
          currently_pursuing: Yup.boolean(),
        })
      ),
      // tags
      tags: Yup.array().of(Yup.string()),
      //
      resume_link: Yup.string(),
      // emails
      emails: Yup.array()
        .of(
          Yup.object().shape({
            email: Yup.string().required('Email is required'),
            primary: Yup.boolean(),
          })
        )
        .test(
          'emails-one-primary',
          'There should only be one primary email',
          (value) => {
            // Count the number of primary emails
            const primaryCount = value.filter((item) => item?.primary).length;

            // There should be exactly one primary email
            return primaryCount === 1;
          }
        )
        .test('email-unique', 'Email should be unique', (value) => {
          const numArr = value.map((item: EmailInt) => item.email);
          return numArr.length === new Set(numArr).size;
        }),
      // contact
      contact: Yup.array()
        .of(
          Yup.object().shape({
            number: Yup.string().matches(
              /^[\d ()+-.]+$/gi,
              'Contact is invalid'
            ),
            primary: Yup.boolean(),
            type: Yup.string(),
            category: Yup.string(),
          })
        )
        .test(
          'contact-one-primary',
          'There should only be one primary contact',
          (value) => {
            // Count the number of primary contact
            const primaryCount = value.filter((item) => item?.primary).length;

            // There should be exactly one primary contact
            return primaryCount === 1;
          }
        )
        .test('contact-unique', 'Contact number should be unique', (value) => {
          const numArr = value.map((item: ContactInt) => item.number);
          return numArr.length === new Set(numArr).size;
        }),
    })
  );

  const handleClose = () => {
    onClose();
  };

  const handleDelete = async (resume: ResumeInt) => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: `You are about to delete resume: ${resume.original_file_name}`,
      buttons: ['Cancel', 'Delete'],
      icon: 'info',
    };

    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqDelResume({ id: resume.id });
      handleClose();
    }
  };

  const onSubmit = async (data: any) => {
    data['attachments'] = await changeUploadName(uploaded, prefix);
    data.latest_salary = Number(String(data.latest_salary)?.replace(/,/g, ''));
    data.expected_salary_start = Number(
      String(data.expected_salary_start)?.replace(/,/g, '')
    );
    data.expected_salary_end = Number(
      String(data.expected_salary_end)?.replace(/,/g, '')
    );
    const checkEmailPrimary = data.emails.some(
      (item: any) => item.primary === true
    );
    data.emails[0].primary = checkEmailPrimary ? data.emails[0]?.primary : true;

    if (data.contact.length > 0) {
      const checkContactPrimary = data.contact.some(
        (item: any) => item.primary === true
      );
      data.contact[0].primary = checkContactPrimary
        ? data.contact[0]?.primary
        : true;
      const filteredContact = data.contact.filter(
        (item: any) => item.number !== ''
      );
      data.contact = filteredContact;
    }
    data.contact = data.contact.map((item: any) => {
      return {
        ...item,
        category: item.category.value,
      };
    });
    data.country = data.country.label;
    data.job_id = job_id;
    await reqManualWithJobCreate({ data, id: resumeData?.id });
  };

  useEffect(() => {
    if (resumeData) {
      const ext = fileApplication(resumeData?.file_name);
      setIsPdf(true);
      if (ext != 'pdf') {
        setIsPdf(false);
      }
    }
  }, [resumeData]);

  useEffect(() => {
    reqCountry({});
    reqCanWorkStat({});
  }, []);

  useEffect(() => {
    let options: any = [];
    if (countries?.length > 0) {
      countries.map((item: any) => {
        options.push({
          label: item.country,
          value: item.id,
          code: item.code,
        });

        if (item.code === 'us') {
          setCountryDefOption(item.country);
        }
      });
    }
    setCountryOption(options);
  }, [countries]);

  useEffect(() => {
    dispatch(uploadList({ prefix: 'Attachment' }));
    dispatch(cleanUpload({ attachments: [] }));
    dispatch(cleanUpload({ uploaded: [] }));
    if (resManualWithJobCreate.isSuccess || resDelResume.isSuccess) {
      onCloseAlert();
      // handleClose();
      if (selected < resumesLength - 1) {
        reqUnparsedList({ id: job_id });
        handleSelect(selected);
      } else {
        handleClose();
      }
      dispatch(getCandidate({ candidateData: {} }));
      reqCandidates({ data: candidatePgBtn });
      reqJobs(jobPgBtn);
    } else if (resManualWithJobCreate.isError || resDelResume.isError) {
      onOpenAlert();
    }
  }, [
    candidatePgBtn,
    resManualWithJobCreate.isError,
    resManualWithJobCreate.isSuccess,
    resDelResume.isSuccess,
    resDelResume.isError,
  ]);

  return {
    onSubmit,
    setResumeUrl,
    setHasSkills,
    setHasTags,
    onCloseAlert,
    onOpenAlert,
    handleClose,
    handleDelete,
    countries,
    candidate_work_status,
    isPdf,
    resumeUrl,
    isOpenAlert,
    initialValues,
    countryOption,
    hasSkills,
    hasTags,
    contactCategoryOptions,
    formRef,
    validationSchema,
    resManualWithJobCreate,
    resDelResume,
  };
}
