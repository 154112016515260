import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { BsStar, BsStarFill } from 'react-icons/bs';
import { Formik } from 'formik';
import moment from 'moment';

import {
  Box,
  Checkbox,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';

import FAIcon from '../../../lib/FAIcon';

import {
  useMyListClientsMutation,
  listClients,
  useToggleMVPClientMutation,
  useToggleActiveClientMutation,
} from 'store/client.slice';

import MyClientsDrawer from './Drawer';
import Pagination from 'Library/Pagination';
import { filterVariables } from 'constants/fileVariables';
import ProfileCard from 'components/profile-card.component';
import TrLoading from 'components/app/TrLoading';

export default function MyClientsTable({ isOpen, onOpen, onClose }: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { clientNav, MyClients, clientPage, clientPageBtn, allClient } =
    useSelector((state: any) => state.clients);

  const [reqMyList, resMyList] = useMyListClientsMutation();
  const [reqMvp] = useToggleMVPClientMutation();
  const [reqActive] = useToggleActiveClientMutation();

  let [mvpList, setMvpList] = useState([]);

  const [rowId, setRowId] = useState(1);

  document.documentElement.dir = 'ltr';

  const [filterCheck] = useState(filterVariables);
  const [typeIsChecked, setTypeChecked] = useState([]);

  useEffect(() => {
    try {
      let list: any = [];

      if (resMyList.isSuccess) {
        MyClients.map(async (item: any, key: number) => {
          list.push({
            id: item.id,
            name: item.name,
            created_at: item.created_at,
            totalOpening: item.totalOpening,
            manager: [
              item.manager[0]?.user?.first_name,
              item.manager[0]?.user?.last_name,
            ].join(' '),
            manager_id: item.manager[0]?.user?.id,
            totalPlaced: item.totalPlaced,
            is_mvp: item.is_mvp,
            is_active: item.is_active === 1 ? true : false,
            data: item,
          });
        });
        setMvpList(list);
      }
    } catch (e) {
      console.log('e', e);
    }
  }, [resMyList.isSuccess, MyClients]);

  useEffect(() => {
    const clientID =
      MyClients.findIndex((x: any) => x.id === Number(params.clientsId)) + 1;
    setRowId(clientID);
  }, [MyClients, params.clientsId]);

  const stateTab: any = params.clientsTab;

  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchList = (param: object) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    reqMyList({ data: param, signal: abortControllerRef.current.signal });
  };

  useEffect(() => {
    if (clientNav === 'my-clients' && !params.clientId) {
      fetchList(clientPageBtn);
    }
  }, [clientNav]);

  useEffect(() => {
    if (
      clientNav === 'my-clients' &&
      stateTab !== 'all-clients' &&
      params.clientsId
    ) {
      const myClientsFilter: string | null =
        localStorage.getItem('myClientsFilter');
      const initFilter =
        myClientsFilter == null ? clientPageBtn : JSON.parse(myClientsFilter);

      dispatch(
        listClients({
          clientPageBtn: initFilter,
        })
      );
      setTypeChecked(initFilter.filters);
    }
  }, [clientNav, params.clientsId, stateTab]);

  useEffect(() => {
    if (clientNav === 'my-clients') {
      setTypeChecked(clientPageBtn.filters);
    }
  }, [clientNav, clientPageBtn.filters]);

  const handleClick = (client: any) => {
    onOpen();
    navigate(`/clients/${params.clientsTab}/${client.id}/overview`);

    const key = MyClients.findIndex((x: any) => x.id === client.id) + 1;
    setRowId(key);
  };

  const handlePrevData = () => {
    const clientID = MyClients[rowId - 1 - 1].id;
    setRowId((prevKey: number) => prevKey - 1);
    navigate(
      `/clients/${params.clientsTab}/${clientID}/${params.clientsSubTab}`
    );
  };

  const handleNextData = () => {
    if (rowId === MyClients.length && clientPage.nextPage) {
      let param = {
        ...clientPageBtn,
        page: clientPage.nextPage,
      };
      reqMyList({
        data: param,
      })
        .unwrap()
        .then((data) => {
          const rowId = 0;
          const clientID = data.data.data[0].id;
          setRowId(rowId);
          navigate(
            `/clients/${params.clientsTab}/${clientID}/${params.clientsSubTab}`
          );
        });
    } else {
      const row_id = rowId === 0 ? 1 : rowId;
      const clientID = MyClients[row_id - 1 + 1].id;
      setRowId((prevKey: number) => prevKey + 1);
      navigate(
        `/clients/${params.clientsTab}/${clientID}/${params.clientsSubTab}`
      );
    }
  };

  const tableHeader: { label: string }[] = [
    { label: 'Client' },
    { label: 'Added On' },
    { label: 'Account Manager' },
    { label: 'Active Jobs' },
    { label: 'Placements' },
    { label: 'Last Activity' },
    { label: '' },
    { label: '' },
  ];

  const pageChange = async (page: number) => {
    let newParam = {
      ...clientPageBtn,
      page: page,
    };

    dispatch(
      listClients({
        clientPageBtn: newParam,
      })
    );
    fetchList(newParam);
  };

  const initialValues = {
    search: '',
  };

  const onSubmit = (data: any) => {
    let newParam = {
      ...clientPageBtn,
      query: data.search,
    };

    dispatch(
      listClients({
        clientPageBtn: newParam,
      })
    );
    fetchList(newParam);
  };

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      let newParam = {
        ...clientPageBtn,
        query: '',
      };
      dispatch(
        listClients({
          clientPageBtn: newParam,
        })
      );
      fetchList(newParam);
    }
  };

  const mvpToggle = async (id: number, is_mvp: number, key: number) => {
    let mvp = is_mvp === 0 ? 1 : 0;
    mvpList[key].is_mvp = mvp;
    await setMvpList(mvpList);
    reqMvp({ data: { is_mvp: mvp }, id: id });
  };

  const activeToggle = async (id: number, key: number, e: any) => {
    const checked = e.target.checked;
    let active = checked ? 1 : 0;
    mvpList[key].is_active = active;
    await setMvpList(mvpList);
    reqActive({ data: { is_active: active }, id: id });
  };

  const handleCheckTypes = useCallback(
    (e: any) => {
      let newParam = {
        ...clientPageBtn,
        query: clientPageBtn.query || '',
        filters: typeIsChecked,
      };

      const { id, checked } = e.target;
      setTypeChecked([...typeIsChecked, id]);
      newParam.filters = [...typeIsChecked, id];

      if (!checked) {
        const isChecked = typeIsChecked.filter((item: number) => item !== id);
        setTypeChecked(isChecked);
        newParam.filters = isChecked;
      }

      dispatch(
        listClients({
          clientPageBtn: newParam,
        })
      );
      fetchList(newParam);
      localStorage.setItem('myClientsFilter', JSON.stringify(newParam));
    },
    [
      clientPageBtn.page,
      clientPageBtn.query,
      clientPageBtn.take,
      dispatch,
      reqMyList,
      typeIsChecked,
    ]
  );

  const entryChange = (entries: number) => {
    let newParam = {
      ...clientPageBtn,
      page: 1,
      take: entries,
    };

    dispatch(
      listClients({
        clientPageBtn: newParam,
      })
    );
    fetchList(newParam);
  };

  return (
    <Box fontFamily="NunitoSans Regular">
      <Flex justifyContent="flex-start" py={2} px={6}>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ values, handleChange, handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <Box pr={2}>
                <InputGroup background="#fff">
                  <InputRightElement
                    cursor="pointer"
                    children={<FAIcon iconName="search" />}
                  />
                  <Input
                    type="search"
                    placeholder="Search a client"
                    id="search"
                    value={values.search}
                    onChange={(e) => {
                      handleChange(e);
                      clearSearch(e);
                    }}
                  />
                </InputGroup>
              </Box>
            </form>
          )}
        </Formik>

        <Flex px={2} justifyContent="flex-start">
          {filterCheck.map((item: any, key: number) => {
            return (
              <Checkbox
                key={key}
                colorScheme="purple"
                color="default.secondarytext"
                mx={1}
                onChange={handleCheckTypes}
                id={item.id}
                marginRight={5}
                isChecked={typeIsChecked.includes(item.id)}
              >
                {item.label}
              </Checkbox>
            );
          })}
        </Flex>
      </Flex>

      <Box px={6} py={4} justifyContent="space-between">
        {resMyList.isLoading ? (
          <Box px={6} py={4} justifyContent="space-between">
            <TableContainer
              boxSizing="border-box"
              border="1px solid"
              borderColor="default.white.400"
              borderRadius="md"
              height="65vh"
              sx={{ overflowY: 'scroll' }}
            >
              <Table>
                <Thead height="40.5px">
                  <Tr bg="default.white.600">
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody
                  boxSizing="border-box"
                  background="default.white.100"
                  borderBottom="1px solid"
                  borderColor="default.white.400"
                >
                  <TrLoading rows={7} columns={5} />
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <TableContainer
            boxSizing="border-box"
            border="1px solid"
            borderColor="default.white.400"
            borderRadius="md"
            height="65vh"
            sx={{ overflowY: 'scroll' }}
          >
            <Table>
              <Thead>
                <Tr bg="default.white.600">
                  {tableHeader.map((title, i) => (
                    <Th key={title.label + i}>
                      <Box color="default.gray.600">{title.label}</Box>
                    </Th>
                  ))}
                </Tr>
              </Thead>

              <Tbody
                boxSizing="border-box"
                background="default.white.100"
                borderBottom="1px solid"
                borderColor="default.white.400"
              >
                {mvpList?.map((client: any, key: number) => {
                  return (
                    <Tr
                      key={client.id}
                      _hover={{
                        bg: '#f8f9fa',
                      }}
                      cursor="pointer"
                    >
                      <Td onClick={() => handleClick(client.data)}>
                        <Box fontWeight="bold"> {client.name}</Box>
                      </Td>
                      <Td onClick={() => handleClick(client.data)}>
                        <Box>
                          {moment.utc(client.created_at).format('MM/DD/YYYY')}
                        </Box>
                      </Td>
                      <Td>
                        <ProfileCard
                          id={client.id}
                          user_id={client.data?.manager[0]?.user?.id}
                          name={client.manager}
                          email={client.data?.manager[0]?.user?.email}
                          thumbnail={
                            client.data?.manager[0]?.user?.thumbnailSignedUrl
                          }
                          profile={client.data?.manager[0]?.user?.signedUrl}
                        />
                      </Td>
                      <Td
                        textAlign="center"
                        onClick={() => handleClick(client.data)}
                      >
                        {client.totalOpening}
                      </Td>
                      <Td
                        textAlign="center"
                        onClick={() => handleClick(client.data)}
                      >
                        {client.totalPlaced}
                      </Td>
                      <Td onClick={() => handleClick(client.data)}>
                        acitivity here
                      </Td>
                      <Td>
                        <Box
                          onClick={() =>
                            mvpToggle(client.id, client.is_mvp, key)
                          }
                        >
                          {client.is_mvp === 1 ? (
                            <Icon as={BsStarFill} color="yellow.400" />
                          ) : (
                            <Icon as={BsStar} />
                          )}
                        </Box>
                      </Td>
                      <Td>
                        <Box className={client.is_active.toString()}>
                          <Switch
                            colorScheme="purple"
                            defaultChecked={client.is_active}
                            onChange={(e) => activeToggle(client.id, key, e)}
                          />
                        </Box>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}

        {isOpen && (
          <MyClientsDrawer
            isOpen={isOpen}
            onClose={onClose}
            rowId={rowId}
            totalClients={clientPage.count}
            handlePrevData={handlePrevData}
            handleNextData={handleNextData}
          />
        )}
        <Pagination
          totalPages={clientPage.lastPage}
          currentPage={clientPage.currentPage}
          onPageChange={(page) => pageChange(page)}
          onEntryChange={(entries) => entryChange(entries)}
          totalEntries={clientPage.count}
          currentCount={MyClients?.length}
          targetCount={clientPageBtn.take}
        />
      </Box>
    </Box>
  );
}
