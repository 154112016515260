import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useNavigate, useParams } from 'react-router';
import { useGetEmailsMutation, listLeads } from 'store/leads.slice';

const TABS = [
  { id: 0, title: 'details', display: 'Details' },
  { id: 1, title: 'jobs', display: 'Jobs' },
  { id: 2, title: 'agreements', display: 'Agreements' },
  { id: 3, title: 'attachments', display: 'Attachments' },
  { id: 4, title: 'activity-log', display: 'Activity Log' },
  { id: 5, title: 'emails', display: 'Emails' },
  { id: 6, title: 'notes', display: 'Notes' },
];
export function useTabListServices() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const activeTab = useMemo(() => {
    const key = location.pathname.split('/').at(-1);
    const tab = TABS.find((tab) => tab.title === key);
    return tab;
  }, [location]);

  const handleRoute = (tab: number) => {
    const route = TABS[tab];
    const path = location.pathname.split('/').slice(0, -1).join('/');
    navigate(`${path}/${route.title}`);
  };

  const [reqGetEmails] = useGetEmailsMutation();

  const { leadData } = useSelector((state: any) => state.leads);

  useEffect(() => {
    if (activeTab?.title === 'emails') {
      reqGetEmails({ id: leadData.id });
    } else {
      dispatch(listLeads({ emails: [] }));
    }
  }, [leadData, activeTab?.title]);

  return { handleRoute, activeTab, TABS };
}
