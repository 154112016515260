import { MY_LEADS } from 'modules/Leads/constants/filter-constants';
import {
  useAddLeadMutation,
  useFetchLeadsQuery,
  useSendForApprovalMutation,
  useSetBulkLeadsInactiveMutation,
  useSetCancelApprovalMutation,
  useSetLeadActiveMutation,
  useSetLeadInactiveMutation,
  useSetLeadToClientMutation,
  useUpdateLeadMutation,
} from 'services/leads/leadsApi';
import { DateSort, LeadDetailDrawerContextType } from 'modules/Leads/types';
import { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router';
import { resetFilter } from 'store/leads.slice';
import { swalContent } from 'types';
import { AtsConfirm, ConfirmWithReason } from 'utils/swal';
import {
  Box,
  Button,
  Flex,
  Stack,
  Tab,
  TabList,
  Tabs,
  useDisclosure,
} from '@chakra-ui/react';
import { BiColumns, BiListUl } from 'react-icons/bi';
import { FaPlus } from 'react-icons/fa6';
import BulkAction from 'modules/Leads/components/table/components/BulkAction';
import Loader from 'Library/Loader';
import TableLoader from 'components/ui/Loaders/TableLoader';
import { useFetchLeadKanbanQuery } from 'services/leads/leads.query';
import MyLeadsKanban from 'modules/Leads/components/kanban/MyLeadsKanban';
import {
  useAddLeadBulkTagsMutation,
  useUpdateLeadStageMutation,
} from 'services/leads/leads.mutation';
import ModalLoader from 'components/ui/Loaders/ModalLoader';

const AddNewLeadModal = lazy(
  () => import('modules/Leads/components/add-edit-lead-modal/AddLeadModal')
);
const Table = lazy(() => import('modules/Leads/components/table/MyLeadsTable'));

let content: swalContent = {
  title: 'Are you sure?',
  text: 'You are about to mark this lead as inactive, continue?',
  buttons: ['Cancel', true],
  icon: 'warning',
};

export default function MyLeads() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status, query, dateFilter, miles, tags, locations } = useSelector(
    (state: {
      leads: {
        status: number[];
        dateFilter: string;
        query: string;
        miles: { value: string; label: string };
        locations: Record<'value', number>[];
        tags: Array<{ value: number; label: string }>;
      };
    }) => state.leads
  );
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(50);
  const [sortOrder, setSortOrder] = useState<DateSort>('DESC');
  const [leadDetailRowId, setLeadDetailRowId] = useState<number | null>(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedKanbanCard, setSelectedKanbanCard] = useState({
    colId: null,
    rowId: null,
  });
  const [layoutTab, setLayoutTab] = useState(0);

  const { data, isLoading } = useFetchLeadsQuery({
    unclaimed: false,
    is_prospect: 0,
    is_repeat_prospect: null,
    types: [] as number[],
    hot: false,
    qualified: false,
    status,
    query,
    date_filter: dateFilter,
    page,
    take,
    orderBy: {
      column_name: 'created_at',
      sort_order: sortOrder,
    },
    zip_code: {
      distance: parseInt(miles?.value),
    },
    tags: tags?.map((tag) => tag.value),
    location: locations?.map((item) => ({ value: item?.value })),
  });
  const { data: kanbanData, isLoading: isKanbanLoading } =
    useFetchLeadKanbanQuery(
      {},
      {
        skip: layoutTab === 0,
        selectFromResult: ({ data, ...other }) => ({
          ...other,
          data: data
            ? data?.reduce((acc, curr) => {
                acc[curr.id] = curr.leads;
                return acc;
              }, {} as any)
            : {},
        }),
      }
    );

  const [reqAddLead] = useAddLeadMutation();
  const [reqUpdateLead] = useUpdateLeadMutation();
  const [reqSendForApproval] = useSendForApprovalMutation();
  const [reqSetBulkLeadsInActive] = useSetBulkLeadsInactiveMutation();
  const [reqSetCancelApproval] = useSetCancelApprovalMutation();
  const [reqSetLeadActive] = useSetLeadActiveMutation();
  const [reqSetLeadInActive] = useSetLeadInactiveMutation();
  const [reqSetLeadToClient] = useSetLeadToClientMutation();
  const [reqLeadStageUpdate] = useUpdateLeadStageMutation();
  const [reqAddBulkTags] = useAddLeadBulkTagsMutation();

  function handlePageChange(page: number) {
    setPage(page);
  }
  function handleEntriesChange(take: number) {
    setTake(take);
  }
  function handleDateSort() {
    setSortOrder((state) => (state === 'DESC' ? 'ASC' : 'DESC'));
  }

  async function handleNextDrawerPagination() {
    if (layoutTab) {
      //for kanban
      const list = kanbanData[selectedKanbanCard.colId];

      if (list && list.length && list.length - 1 !== selectedKanbanCard.rowId) {
        const next = list[selectedKanbanCard.rowId + 1];
        setSelectedKanbanCard({
          rowId: selectedKanbanCard.rowId + 1,
          colId: selectedKanbanCard.colId,
        });
        navigate(`/leads/my-leads/${next.id}/details`);
      }
    } else {
      if (take === leadDetailRowId + 1 && data.count > take) {
        //reached end of current table pagination data
      } else {
        const nextLeadDetail = data.data[leadDetailRowId + 1];
        setLeadDetailRowId((state) => state + 1);
        navigate(`/leads/my-leads/${nextLeadDetail.id}/details`);
      }
    }
  }
  function handlePrevDrawerPagination() {
    if (layoutTab) {
      //for kanban
      const list = kanbanData[selectedKanbanCard.colId];

      if (selectedKanbanCard.rowId >= 0) {
        const prev = list[selectedKanbanCard.rowId - 1];
        setSelectedKanbanCard({
          rowId: selectedKanbanCard.rowId - 1,
          colId: selectedKanbanCard.colId,
        });
        navigate(`/leads/my-leads/${prev.id}/details`);
      }
    } else {
      if (leadDetailRowId >= 0) {
        const prevLeadDetail = data.data[leadDetailRowId - 1];
        setLeadDetailRowId((state) => state - 1);
        navigate(`/leads/my-leads/${prevLeadDetail.id}/details`);
      }
    }
  }

  async function onAddLead(formField: any) {
    try {
      const payload = await reqAddLead(formField).unwrap();
      return payload;
    } catch (err: any) {
      return err.data;
    }
  }
  async function onEditLead(formField: any, leadId: number) {
    try {
      const payload = await reqUpdateLead({
        data: formField,
        id: leadId,
      }).unwrap();
      return payload;
    } catch (err: any) {
      return err.data;
    }
  }
  async function onBulkInActive(idList: number[]) {
    const submittedReason = await ConfirmWithReason(content);
    if (submittedReason) {
      await reqSetBulkLeadsInActive({
        data: { idList, inactive_reason: submittedReason },
      });
    }
  }
  async function onCancelApproval(lead: any) {
    content.text = 'You are about to cancel this approval, continue?';
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqSetCancelApproval({ id: lead.id });
    }
  }
  async function onChangeToActiveStatus(lead: any) {
    content.text = 'You are about to mark this lead as inactive, continue?';
    const confirm = await AtsConfirm(content);

    if (confirm) {
      await reqSetLeadActive({ id: lead.id });
    }
  }
  async function onAddBulkTags(tags: Array<string>) {
    try {
      const payload = await reqAddBulkTags({
        data: {
          tags: tags,
          idList: selectedRows.map((row) => row.id),
        },
      }).unwrap();
      return payload;
    } catch (err: any) {
      return err.data;
    }
  }
  async function onChangeToInActiveStatus(lead: any) {
    const submittedReason = await ConfirmWithReason(content);

    if (submittedReason) {
      await reqSetLeadInActive({
        id: lead.id,
        inactive_reason: submittedReason,
      });
    }
  }
  async function onConvertToClient(formField: any, lead: any) {
    try {
      const payload = await reqSetLeadToClient({
        data: formField,
        id: lead.id,
      }).unwrap();
      return payload;
    } catch (err: any) {
      return err.data;
    }
  }
  async function onSendApproval(formField: any, lead: any) {
    try {
      const payload = await reqSendForApproval({
        data: formField,
        id: lead.id,
      }).unwrap();
      return payload;
    } catch (err: any) {
      return err.data;
    }
  }
  async function onStageUpdate(stageId: number, leadId: number) {
    try {
      const payload = await reqLeadStageUpdate({
        data: { stage: stageId },
        id: leadId,
      }).unwrap();
      return payload;
    } catch (err: any) {
      return err.data;
    }
  }

  useEffect(() => {
    const status = MY_LEADS[0].items
      .filter((stat) => stat.status)
      .map((item) => item.id);
    dispatch(resetFilter({ status }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack p={0} m={0} w={'calc(100% - 320px)'} gap={'16px'}>
      <Stack
        spacing={0}
        sx={{
          h: layoutTab ? 'fit-content' : '100%',
          w: '100%',
          bgColor: '#fff',
          borderRadius: '10px',
          p: '16px',
        }}
        gap="16px"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Flex gap={'8px'} alignItems="center">
            <Tabs
              bgColor="#E7EDF4"
              borderRadius="10px"
              variant="unstyled"
              p="4px"
              onChange={(index) => {
                setLayoutTab(index);
                setSelectedRows([]);
              }}
              index={layoutTab}
            >
              <TabList>
                <Tab
                  _selected={{ bgColor: '#fff' }}
                  borderRadius="10px"
                  width="46px"
                  height="46px"
                  p="0"
                >
                  <BiListUl fontSize="24px" />
                </Tab>
                <Tab
                  _selected={{ bgColor: '#fff' }}
                  borderRadius="10px"
                  width="46px"
                  height="46px"
                  p="0"
                >
                  <BiColumns fontSize="24px" />
                </Tab>
              </TabList>
            </Tabs>
            {selectedRows.length > 0 && layoutTab === 0 ? (
              <BulkAction
                leads={selectedRows}
                onAddTags={onAddBulkTags}
                onMarkInActive={() =>
                  onBulkInActive(selectedRows.map((data) => data.id))
                }
              />
            ) : (
              <Box mb={6}></Box>
            )}
          </Flex>

          <AddNewLeadBtn onSubmit={onAddLead} />
        </Flex>
        {Boolean(layoutTab === 0) ? (
          <Suspense
            fallback={
              <TableLoader
                header={[
                  '',
                  'Stage',
                  'Lead detail',
                  'created on',
                  'contact information',
                  'client job link',
                  'last activity',
                ]}
              />
            }
          >
            <Box h={'calc(100% - 65px)'} w="100%">
              <Table
                onDateSort={handleDateSort}
                onSubmitEmail={() => {}}
                onEditLead={onEditLead}
                onAddLead={onAddLead}
                onBulkInActive={onBulkInActive}
                onCancelApproval={onCancelApproval}
                onChangeToActiveStatus={onChangeToActiveStatus}
                onChangeToInActiveStatus={onChangeToInActiveStatus}
                onConvertToClient={onConvertToClient}
                onSendApproval={onSendApproval}
                onOpenDetailDrawer={(index: number) =>
                  setLeadDetailRowId(index)
                }
                rows={data?.data ?? []}
                onAllSelect={(selectedRows) => {
                  setSelectedRows(selectedRows);
                }}
                isLoading={isLoading}
                pagination={{
                  onEntryChange: handleEntriesChange,
                  onPageChange: handlePageChange,
                  currentCount: data?.data?.length,
                  currentPage: page,
                  targetCount: take,
                  totalEntries: data?.count,
                  totalPages: data?.lastPage,
                }}
              />
            </Box>
          </Suspense>
        ) : null}
      </Stack>
      {layoutTab === 1 ? (
        <Suspense fallback={<Loader />}>
          <MyLeadsKanban
            data={kanbanData}
            isLoading={isKanbanLoading}
            onStageUpdate={onStageUpdate}
            onOpenDrawer={(colId: number, rowId: number) => {
              setSelectedKanbanCard({ colId, rowId });
            }}
          />
        </Suspense>
      ) : null}

      {/* Render drawer */}
      <Outlet
        context={
          {
            rowId: leadDetailRowId + 1,
            totalLeads: data?.count,
            handlePrevData: handlePrevDrawerPagination,
            handleNextData: handleNextDrawerPagination,
            onClose: () => navigate('/leads/my-leads'),
          } satisfies LeadDetailDrawerContextType
        }
      />
    </Stack>
  );
}

function AddNewLeadBtn({ onSubmit }: { onSubmit: any }) {
  const {
    onOpen: onOpenAddLead,
    onClose: onCloseAddLead,
    isOpen: isAddLeadOpen,
  } = useDisclosure();
  return (
    <>
      <Button leftIcon={<FaPlus />} variant="solid" onClick={onOpenAddLead}>
        Add New lead
      </Button>
      {isAddLeadOpen ? (
        <Suspense fallback={<ModalLoader />}>
          <AddNewLeadModal
            isOpen={isAddLeadOpen}
            onClose={onCloseAddLead}
            onSubmit={onSubmit}
          />
        </Suspense>
      ) : null}
    </>
  );
}
