import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Center,
  Checkbox,
  CloseButton,
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  useDisclosure,
  VStack,
  Button,
  useToast,
  Text,
  RadioGroup,
  Radio,
  Stack,
  useRadioGroup,
} from '@chakra-ui/react';

import FAIcon from 'components/lib/FAIcon';
import { listJobs, useListJobsMutation } from 'store/jobs.slice';
import { Formik } from 'formik';
import { authStorage } from 'utils/localStorage';
import {
  listCandidates,
  useAssociateCandidatesMutation,
  useBulkAssociateCandidatesMutation,
  useListCandidatesMutation,
} from 'store/candidates.slice';
import LoadingPage from 'components/app/Loading';
import ScrollToFieldError from 'components/app/ScrollError';

interface CandidatesModalProps {
  isOpen: any;
  onClose: any;
  id?: any;
  idList?: number[];
  assoc_jobs?: any;
  selectedJob?: any;
  setSelectedJob?: any;
  isBulkAssociateJob?: boolean;
  fromUpload?: boolean;
}

const UploadResumeAssociateJobModal = ({
  isOpen,
  onClose,
  id,
  idList,
  assoc_jobs,
  selectedJob,
  setSelectedJob,
  isBulkAssociateJob = false,
  fromUpload = false,
}: CandidatesModalProps) => {
  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false });
  const dispatch = useDispatch();
  const { jobs } = useSelector((state: any) => state.jobs);
  const [errorMessage, setErrorMessage] = useState(
    'There was an error processing your request. Change a few things up and try again.'
  );

  const [checkedBox, setChecked] = useState([]);

  const initialValue = {
    search: '',
  };

  // const [reqCandidates, resCandidates] = useListCandidatesMutation();
  const [reqList, resJobs] = useListJobsMutation();
  const [reqAssocJob, resAssocJob] = useAssociateCandidatesMutation();
  const [reqBulkAssocJob, resBulkAssocJob] =
    useBulkAssociateCandidatesMutation();

  const [jobsOptions, setJobsOption] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const storageName = 'jobFilter';
  const jobFilerData: string | null = localStorage.getItem(storageName);
  const jobParams = {
    all: true,
    closed: false,
    draft: false,
    full: false,
    job_ads: true,
    job_assigned: true,
    open: true,
    page: 1,
    query: '',
    quickJobs: true,
    take: 100,
  };

  const abortControllerRef = useRef<AbortController | null>(null);

  const fetch = async (params: object) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    reqList({ data: params, signal: abortControllerRef.current.signal });
  };

  useEffect(() => {
    fetch(jobParams);
  }, [reqList]);

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      fetch(jobParams);
    }
  };

  const onSubmit = (data: any) => {
    const newParam = {
      ...jobParams,
      query: data?.search,
    };
    fetch(newParam);
  };
  const submit = () => {
    if (selectedIndex !== null) {
      const jobSelected = {
        title: jobs[selectedIndex]?.title,
        id: jobs[selectedIndex]?.id,
      };
      setSelectedJob(jobSelected);
      onClose();
    } else {
      onOpen();
      setErrorMessage('Please select at least one job');
    }
  };

  const locationParse = (location: any) => {
    try {
      const loc = [
        location?.address,
        location?.city,
        location?.state,
        location?.zip_code,
        location?.country?.country,
      ]
        .filter(Boolean)
        .join(', ');
      if (loc !== ', ') {
        return loc;
      }
    } catch (e) {
      console.log(e);
      return '';
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onCloseAlert();
          onClose();
        }}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="NunitoSans Regular">
          <ModalHeader
            background="#F4F6F9"
            borderBottom="1px solid gainsboro"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            Associate To Job
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          {isOpenAlert && (
            <Box>
              <Alert status="error" justifyContent="space-around">
                <AlertIcon />
                <Box>
                  <AlertTitle>Oh snap!</AlertTitle>
                  <AlertDescription>{errorMessage}</AlertDescription>
                </Box>
                <CloseButton
                  position="relative"
                  right={-1}
                  top={-3}
                  onClick={onCloseAlert}
                />
              </Alert>
            </Box>
          )}
          <ModalBody
            borderRadius="0 0 4px 4px"
            p={0}
            fontFamily="NunitoSans Regular"
          >
            <Box
              overflow="automatic"
              p="30px"
              background="#fff"
              borderRadius="8px"
            >
              <FormControl mb="16px">
                <Formik
                  enableReinitialize
                  initialValues={initialValue}
                  onSubmit={onSubmit}
                >
                  {({ values, handleChange, handleSubmit }) => (
                    <>
                      <form onSubmit={handleSubmit}>
                        <ScrollToFieldError />
                        <InputGroup background="#fff">
                          <InputRightElement
                            cursor="pointer"
                            children={<FAIcon iconName="search" />}
                          />
                          <Input
                            type="search"
                            placeholder="Search Jobs"
                            id="search"
                            onChange={(e) => {
                              handleChange(e);
                              clearSearch(e);
                            }}
                            value={values.search}
                          />
                        </InputGroup>
                      </form>
                    </>
                  )}
                </Formik>
              </FormControl>
            </Box>
            <Box>
              {resJobs.isLoading && <LoadingPage />}
              {jobs.length > 0 && (
                <RadioGroup
                  name="jobs"
                  onChange={(e) => {
                    setSelectedIndex(e);
                  }}
                >
                  <VStack
                    spacing={2}
                    align="stretch"
                    divider={<StackDivider borderColor="gray.200" />}
                  >
                    {jobs.map((job: any, index: number) => {
                      return (
                        <Box key={`associate-modal-${job?.id}`}>
                          <Flex>
                            <Center w="50px">
                              <Radio value={`${index}`} />
                            </Center>
                            <Box>
                              <VStack align="stretch" spacing={0}>
                                <Box>{job?.title}</Box>

                                <Box>
                                  {job?.client?.name} - {job?.serial_number}
                                </Box>
                                <Box>
                                  <Text fontSize="sm">
                                    {locationParse(job?.location)}
                                  </Text>
                                </Box>
                              </VStack>
                            </Box>
                          </Flex>
                        </Box>
                      );
                    })}
                  </VStack>
                </RadioGroup>
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="solid"
              onClick={submit}
              loadingText="Submitting"
              isDisabled={isOpenAlert}
              isLoading={resAssocJob.isLoading || resBulkAssocJob.isLoading}
            >
              Associate
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UploadResumeAssociateJobModal;
