import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import TrLoading from 'components/app/TrLoading';

const SkeletonLoading = () => {
  return (
    <Box
      sx={{
        height: '100%',
        w: '100%',
      }}
    >
      <TableContainer
        boxSizing="border-box"
        border="1px solid"
        borderColor="default.white.400"
        borderRadius="md"
        height="calc(75vh - 50px)"
      >
        <Table>
          <Thead height="40.5px">
            <Tr bg="default.white.600">
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody
            boxSizing="border-box"
            background="default.white.100"
            borderBottom="1px solid"
            borderColor="default.white.400"
          >
            <TrLoading rows={7} columns={10} />
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SkeletonLoading;
