// Chakra UI components
import {
  Box,
  Button,
  Flex,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
  useToast,
} from '@chakra-ui/react';

import { useState } from 'react';
import TabPannel from './components/tab-pannels';
import { Formik, FormikValues } from 'formik';
import {
  IntialValueType,
  fullJobValidationScheme,
  quickJobValidationScheme,
} from './utils/validationSchema';
import ScrollToFieldError from 'components/app/ScrollError';
import { useCreateInitialValue } from './hooks/useInitialValue';
import usePlaceholderPair from './hooks/usePlaceholderPair';
import FormErrorAlert from 'components/ui/Alerts/FormErrorAlert';
import { extractPayloadData } from './utils/util';
import { useNavigate } from 'react-router';

interface AddJobFormProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: FormikValues) => Promise<any>;
  onSaveDraft: (data: FormikValues) => Promise<any>;
  clientField?: {
    value: { label: string; value: number };
    isDisabled: boolean;
  };
  contactField?: {
    value: { label: string; value: number };
    isDisabled: boolean;
  };
}

const FULL_JOB_VALIDATION = fullJobValidationScheme;
const QUICK_JOB_VALIDATION = quickJobValidationScheme;

export default function AddJobForm({
  isOpen,
  onClose,
  onSubmit,
  onSaveDraft,
  contactField,
  clientField,
}: AddJobFormProps) {
  const navigate = useNavigate();
  const initialValue = useCreateInitialValue(
    clientField?.value,
    contactField?.value
  );
  const { fetchPlaceholderPair, updatePlaceholderPair } = usePlaceholderPair();
  const [validations, setValidations] = useState<any[]>(FULL_JOB_VALIDATION);
  const totalTabIndexes = validations.length - 1;
  const [tab, setTab] = useState(0);
  const [submitError, setSubmitError] = useState({
    open: false,
    message: null,
  });
  const [submitting, setSubmitting] = useState(false);
  const [draftSubmitting, setDraftSubmitting] = useState(false);

  const toast = useToast();

  async function handleSubmit(data: IntialValueType) {
    updatePlaceholderPair(data);
    //placeholder pair update
    if (tab === 0) {
      //on submission of 1st tab form
      const jobType = data.job_type;
      setValidations(
        jobType === '1' ? FULL_JOB_VALIDATION : QUICK_JOB_VALIDATION
      );

      fetchPlaceholderPair(data.client.value, data); //fetch placeholder pair on client change
    }

    //submission on last tab form
    if (tab === totalTabIndexes) {
      //   data['job_description'] = replacePlaceholder(data.job_description);
      const payload = extractPayloadData(data, false);
      setSubmitError({ open: false, message: null });
      setSubmitting(true);
      const res = await onSubmit(payload);

      setSubmitting(false);
      if (!res.success) {
        setSubmitError({
          open: true,
          message:
            (res?.error?.message || res?.message) ?? 'Something went wrong!',
        });
      } else {
        onClose();
        toast({
          title: `Added Job`,
          description: `New Job successfully added`,
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
        navigate(`/jobs/${res?.data?.data?.id}/details`);
      }
    }
    setTab((tab) => {
      if (tab < totalTabIndexes) {
        return tab + 1;
      }
      return tab;
    });
  }

  async function handleSaveDraft(data: IntialValueType) {
    setSubmitError({ open: false, message: null });
    setDraftSubmitting(true);
    const payload = extractPayloadData(data, true);
    const res = await onSaveDraft(payload);
    setDraftSubmitting(false);
    if (!res.success) {
      setSubmitError({
        open: true,
        message:
          (res?.error?.message || res?.message) ?? 'Something went wrong!',
      });
    } else {
      onClose();
      toast({
        title: 'Saved Job to Draft',
        description: `Job has been saved to draft`,
        status: 'success',
        isClosable: true,
        duration: 3000,
        position: 'top',
      });
      navigate(`/jobs/${res?.data?.data?.id}/details`);
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={onClose}
      scrollBehavior="inside"
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        maxW={{
          '1280px': '1200px', // 1280 x 832
          '1366px': '1200px', // 1366 x 768
          '1400px': '1300px', // 1400 x 900
          '1512px': '1400px', // 1512 x 982
          '1728px': '1500px', // 1728 x 1117
          '1920px': '1500px', // 1920 x 1280
        }}
        w={{
          '1280px': '1200px', // 1280 x 832
          '1366px': '1200px', // 1366 x 768
          '1400px': '1300px', // 1400 x 900
          '1512px': '1400px', // 1512 x 982
          '1728px': '1500px', // 1728 x 1117
          '1920px': '1500px', // 1920 x 1280
        }}
        maxH={{
          '1280px': '550px', // 1280 x 832
          '1366px': '550px', // 1366 x 768
          '1400px': '700px', // 1400 x 900
          '1512px': '700px', // 1512 x 982
          '1728px': '800px', // 1728 x 1117
          '1920px': '800px', // 1920 x 1280
        }}
        h={{
          '1280px': '550px', // 1280 x 832
          '1366px': '550px', // 1366 x 768
          '1400px': '700px', // 1400 x 900
          '1512px': '700px', // 1512 x 982
          '1728px': '800px', // 1728 x 1117
          '1920px': '800px', // 1920 x 1280
        }}
        fontFamily="NunitoSans Regular"
      >
        <ModalHeader
          sx={{
            background: '#F9FAFB',
            borderRadius: '4px 4px 0',
            p: '16px',
          }}
        >
          <Text
            sx={{
              py: '5px',
              fontSize: '18px',
              lineHeight: '18px',
              fontWeight: 700,
              color: '#2B2D42',
              textAlign: 'left',
            }}
          >
            Create Job
          </Text>
        </ModalHeader>

        {submitError.open && (
          <FormErrorAlert
            title="Oh snap!"
            onClose={() => setSubmitError({ open: false, message: null })}
            description={
              <>
                {typeof submitError.message === 'string' ? (
                  submitError.message
                ) : (
                  <>
                    There were some problems in you input. Change a few things
                    up and try again.
                    <UnorderedList
                      mt="10px"
                      fontSize="13px"
                      lineHeight="1.5"
                      color="var(--chakra-colors-red-500)"
                    >
                      {Object.values(submitError.message).map((key: any) => (
                        <ListItem key={key}>{key}</ListItem>
                      ))}
                    </UnorderedList>
                  </>
                )}
              </>
            }
          />
        )}

        <ModalBody
          sx={{
            bgColor: '#F9FAFB',
            borderRadius: '0 0 4px 4px',
            p: 0,
            h: '100%',
          }}
        >
          <Box
            position="relative"
            h="inherit"
            width="100%"
            sx={{
              p: '0 16px',
            }}
          >
            <Formik
              initialValues={initialValue}
              onSubmit={(data) => {
                handleSubmit(data);
              }}
              validationSchema={validations[tab]}
              validateOnBlur={false}
              validateOnChange={false}
              enableReinitialize
            >
              {(props) => (
                <form
                  onSubmit={props.handleSubmit}
                  style={{ height: '100%' }}
                  onChange={() => {
                    updatePlaceholderPair(props.values);
                  }}
                >
                  <Box h="100%" position="relative">
                    <ScrollToFieldError />

                    <TabPannel
                      tabIndex={tab}
                      isContactDisabled={contactField?.isDisabled}
                      isClientDisabled={clientField?.isDisabled}
                    />

                    <ModalFooter
                      position="sticky"
                      bottom={0}
                      background="#F9F9FB"
                      p="8px 0"
                    >
                      <Flex
                        gap="10px"
                        justifyContent="space-between"
                        sx={{ w: '100%' }}
                      >
                        {Boolean(tab > 0) ? (
                          <Button
                            onClick={() => {
                              setTab((tab) => tab - 1);
                            }}
                            type="button"
                            isDisabled={submitError.open}
                          >
                            Previous
                          </Button>
                        ) : (
                          <></>
                        )}
                        <Flex gap="10px" justifyContent="flex-end" width="100%">
                          <Button
                            onClick={() => {
                              onClose();
                            }}
                            type="button"
                          >
                            Cancel
                          </Button>

                          {Boolean(tab > 0 && tab < totalTabIndexes) && (
                            <Button
                              sx={{
                                height: '40px',
                              }}
                              onClick={() => {
                                props.validateForm().then((data) => {
                                  if (!Object.values(data).length) {
                                    handleSaveDraft(props.values);
                                  }
                                });
                              }}
                              isLoading={draftSubmitting}
                              isDisabled={submitError.open}
                            >
                              Save Draft
                            </Button>
                          )}
                          {tab === totalTabIndexes ? (
                            <Button
                              variant="solid"
                              sx={{
                                height: '40px',
                              }}
                              type="submit"
                              isDisabled={submitError.open}
                              isLoading={submitting}
                            >
                              Save
                            </Button>
                          ) : (
                            <Button
                              variant="solid"
                              sx={{
                                height: '40px',
                              }}
                              type="submit"
                              isDisabled={submitError.open}
                            >
                              Next
                            </Button>
                          )}
                        </Flex>
                      </Flex>
                    </ModalFooter>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
