export const CANDIDATE_STAT = [
  {
    id: 0,
    stat_id: [] as any,
    status: 1,
    name: 'All',
    items: [
      {
        id: 1,
        stat_id: [3, 4, 6],
        status: 1,
        name: 'Opened',
      },
      {
        id: 2,
        stat_id: [2, 3, 4, 5],
        status: 1,
        name: 'Messaged',
      },
      {
        id: 3,
        stat_id: [4],
        status: 1,
        name: 'Replied',
      },
      {
        id: 4,
        stat_id: [5],
        status: 1,
        name: 'Bounced',
      },
      {
        id: 5,
        stat_id: [15],
        status: 1,
        name: 'Unsubscribed',
      },
      {
        id: 6,
        stat_id: [9],
        status: 1,
        name: 'No Email',
      },
    ],
  },
];

export const CANDIDATE_FIL = [
  {
    id: 0,
    stat_id: [] as any,
    status: 1,
    name: 'All',
    items: [
      {
        id: 1,
        stat_id: [3],
        status: 1,
        name: 'Enriched',
      },
      {
        id: 2,
        stat_id: [2],
        status: 1,
        name: 'Not Enriched',
      },
    ],
  },
];

export const FILTER_STAT = [
  {
    id: 1,
    stat_id: 'active',
    status: 1,
    name: 'Active',
  },
  {
    id: 2,
    stat_id: 'archived',
    status: 0,
    name: 'Archived',
  },
];
