import { Box, Flex, Tab, TabList, Tabs, Text } from '@chakra-ui/react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { listCandidates } from 'store/candidates.slice';
import LoadingTab from './LoadingTab';
import viewCandidateConfig from '../VerticalFilter/config';

interface props {
  candidatePgBtn: any;
  job_id: number;
  reload: boolean;
  setIsClear: Dispatch<SetStateAction<boolean>>;
}

const ViewCandidateTab = ({ candidatePgBtn, setIsClear }: props) => {
  const { jobsId, jobsTab, jobsTabId, jobsTabNav } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { candidateLoading } = useSelector((state: any) => state.candidates);
  const [selectedTab, setSelectedTab] = useState(0);

  let {
    allCountParam,
    blastedCountParam,
    screenCountParam,
    submitCountParam,
    interviewCountParam,
    offersCountParam,
    hiredCountParam,
    rejectedCountParam,
  } = viewCandidateConfig(candidatePgBtn);

  const { candidatesCount, candidatesAllCount } = useSelector(
    (state: any) => state.candidates
  );

  const defaultStyle = {
    padding: '16px',
  };

  const tabStyle = {
    ...defaultStyle,
    backgroundColor: '#6930CA0D',
    borderRadius: '50px',
    border: '1px solid',
    fontWeight: 700,
  };

  const tabOptions = [
    'all',
    'blasted',
    'screen',
    'submits',
    'interviews',
    'offers',
    'hired',
    'rejected',
  ];

  const payloads = [
    allCountParam,
    blastedCountParam,
    screenCountParam,
    submitCountParam,
    interviewCountParam,
    offersCountParam,
    hiredCountParam,
    rejectedCountParam,
  ];

  useEffect(() => {
    const tabIndex = tabOptions.indexOf(jobsTabNav);
    setSelectedTab(tabIndex >= 0 ? tabIndex : 0);
  }, [jobsTabNav]);

  const handleChangeTab = (index: number) => {
    setIsClear(true);
    setSelectedTab(index);

    const payload = {
      ...candidatePgBtn,
      page: 1,
      job_status: payloads[index]?.job_status || null,
      status: payloads[index]?.status || [],
      query: payloads[index]?.query || '',
      search: payloads[index]?.search || '',
      filter: null,
    };

    dispatch(listCandidates({ candidatePgBtn: payload }));
    navigate(`/jobs/${jobsId}/${jobsTab}/${jobsTabId}/${tabOptions[index]}`);
  };

  return (
    <Box mt="24px" mb="28px">
      <Tabs
        colorScheme="purple"
        index={selectedTab}
        onChange={(index) => handleChangeTab(index)}
      >
        <TabList
          mx={0}
          style={{
            padding: '10px',
            backgroundColor: 'white',
            borderRadius: '30px',
            display: 'inline-flex',
            border: '1px solid #E7EDF4',
          }}
        >
          <Tab
            style={selectedTab === 0 ? tabStyle : defaultStyle}
            isDisabled={candidateLoading}
          >
            <Flex gap="16px" height="16px" alignItems="center">
              <Text
                sx={{
                  fontSize: '14px',
                  fontWeight: 700,
                  color: selectedTab === 0 ? '#6930CA' : '#6B6C7B',
                }}
              >
                All
              </Text>
              <LoadingTab count={candidatesAllCount} />
            </Flex>
          </Tab>

          <Tab
            style={selectedTab === 1 ? tabStyle : defaultStyle}
            isDisabled={candidateLoading}
          >
            <Flex gap="16px" height="16px" alignItems="center">
              <Text
                sx={{
                  fontSize: '14px',
                  fontWeight: 700,
                  color: selectedTab === 1 ? '#6930CA' : '#6B6C7B',
                }}
              >
                Blasted
              </Text>
              <LoadingTab
                count={candidatesCount && candidatesCount[6]?.count}
              />
            </Flex>
          </Tab>
          <Tab
            style={selectedTab === 2 ? tabStyle : defaultStyle}
            isDisabled={candidateLoading}
          >
            <Flex gap="16px" height="16px" alignItems="center">
              <Text
                sx={{
                  fontSize: '14px',
                  fontWeight: 700,
                  color: selectedTab === 2 ? '#6930CA' : '#6B6C7B',
                }}
              >
                Screen
              </Text>
              <LoadingTab
                count={candidatesCount && candidatesCount[1]?.count}
              />
            </Flex>
          </Tab>
          <Tab
            style={selectedTab === 3 ? tabStyle : defaultStyle}
            isDisabled={candidateLoading}
          >
            <Flex gap="16px" height="16px" alignItems="center">
              <Text
                sx={{
                  fontSize: '14px',
                  fontWeight: 700,
                  color: selectedTab === 3 ? '#6930CA' : '#6B6C7B',
                }}
              >
                Submits
              </Text>
              <LoadingTab
                count={candidatesCount && candidatesCount[0]?.count}
              />
            </Flex>
          </Tab>
          <Tab
            style={selectedTab === 4 ? tabStyle : defaultStyle}
            isDisabled={candidateLoading}
          >
            <Flex gap="16px" height="16px" alignItems="center">
              <Text
                sx={{
                  fontSize: '14px',
                  fontWeight: 700,
                  color: selectedTab === 4 ? '#6930CA' : '#6B6C7B',
                }}
              >
                Interviews
              </Text>
              <LoadingTab
                count={candidatesCount && candidatesCount[2]?.count}
              />
            </Flex>
          </Tab>
          <Tab
            style={selectedTab === 5 ? tabStyle : defaultStyle}
            isDisabled={candidateLoading}
          >
            <Flex gap="16px" height="16px" alignItems="center">
              <Text
                sx={{
                  fontSize: '14px',
                  fontWeight: 700,
                  color: selectedTab === 5 ? '#6930CA' : '#6B6C7B',
                }}
              >
                Offers
              </Text>
              <LoadingTab
                count={candidatesCount && candidatesCount[3]?.count}
              />
            </Flex>
          </Tab>
          <Tab
            style={selectedTab === 6 ? tabStyle : defaultStyle}
            isDisabled={candidateLoading}
          >
            <Flex gap="16px" height="16px" alignItems="center">
              <Text
                sx={{
                  fontSize: '14px',
                  fontWeight: 700,
                  color: selectedTab === 6 ? '#6930CA' : '#6B6C7B',
                }}
              >
                Hired
              </Text>
              <LoadingTab
                count={candidatesCount && candidatesCount[4]?.count}
              />
            </Flex>
          </Tab>
          <Tab
            style={selectedTab === 7 ? tabStyle : defaultStyle}
            isDisabled={candidateLoading}
          >
            <Flex gap="16px" alignItems="center">
              <Text
                sx={{
                  fontSize: '14px',
                  fontWeight: 700,
                  color: selectedTab === 7 ? '#6930CA' : '#6B6C7B',
                }}
              >
                Rejected
              </Text>
              <LoadingTab
                count={candidatesCount && candidatesCount[5]?.count}
              />
            </Flex>
          </Tab>
        </TabList>
      </Tabs>
    </Box>
  );
};

export default ViewCandidateTab;
