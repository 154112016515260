import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Flex, Image, Stack, Text } from '@chakra-ui/react';

import SearchDefault from 'assets/images/jobs/search.svg';
import SearchActive from 'assets/images/jobs/search-active.svg';
import InProgressDefault from 'assets/images/jobs/in-progress.svg';
import InProgressActive from 'assets/images/jobs/in-progress-active.svg';
// import MatchedDefault from 'assets/images/jobs/matched.svg';
// import WinnerDefault from 'assets/images/jobs/winners.svg';

import Highlight from 'assets/images/inboxes/MdHighlight.svg';

const ViewCandidateTab = () => {
  const navigate = useNavigate();
  const { jobsId, jobsTab } = useParams();

  const [tabValue, setTabValue] = useState('in-progress');

  const handleTab = (e: any) => {
    setTabValue(e);
    navigate(`/jobs/${jobsId}/${jobsTab}/${e}`);
  };

  const SideBarItem = [
    {
      id: 0,
      label: 'Search',
      image: SearchDefault,
      imageActive: SearchActive,
      value: 'search',
    },
    {
      id: 1,
      label: 'In Progress',
      image: InProgressDefault,
      imageActive: InProgressActive,
      value: 'in-progress',
    },
    // {
    //   id: 2,
    //   label: 'Matched',
    //   image: MatchedDefault,
    //   imageActive: MatchedDefault,
    //   value: 'matched',
    // },
    // {
    //   id: 3,
    //   label: 'Taliewinners',
    //   image: WinnerDefault,
    //   imageActive: WinnerDefault,
    //   value: 'talie-winners',
    // },
  ];

  return (
    <Stack
      sx={{
        bgColor: '#6930CA0D',
        borderRadius: '10px',
      }}
    >
      <Stack spacing="10px" sx={{ position: 'relative', p: '16px' }}>
        {SideBarItem.map((val) => {
          const isActive = tabValue === val.value;
          return (
            <Flex
              gap="10px"
              sx={{
                alignItems: 'center',
                p: '4px 8px',
                cursor: 'pointer',
                borderRadius: '4px',
                background: isActive && '#6930CA',
                color: isActive ? '#FFFFFF' : '#2B2D42',
              }}
              onClick={() => {
                handleTab(val.value);
              }}
            >
              {isActive && (
                <Image
                  src={Highlight}
                  height="35px"
                  width="4px"
                  sx={{ position: 'absolute', left: 0 }}
                />
              )}
              <Image
                src={isActive ? val.imageActive : val.image}
                height="24px"
                width="24px"
              />
              <Text sx={{ fontSize: '14px', fontWeight: 600 }}>
                {val.label}
              </Text>
            </Flex>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default ViewCandidateTab;
