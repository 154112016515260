/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { ImportExcelForm } from './Form';
import { useImportExcelServices } from './useImportExcelServices';

interface ImportExcelModalProps {
  isOpen: any;
  onClose: any;
}

export default function ImportExcelModal({
  isOpen,
  onClose,
}: ImportExcelModalProps) {
  const {
    handleCloseDrawer,
    sequencesData,
    isOpenAlert,
    submitErrorMessage,
    onCloseAlert,
    initialValues,
    validationSchema,
    handleSubmit,
    excelHeaders,
    handleUploadEnd,
    candidateFieldOptions,
    handleCandidateFieldSelect,
    importCount,
    errorHeaders,
  } = useImportExcelServices({ onClose });
  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={handleCloseDrawer}
      size="3xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent fontFamily="NunitoSans Regular">
        <ModalHeader
          background="default.white.800"
          borderRadius="4px 4px 0"
          p="18px 32px"
          fontSize="18px"
          lineHeight="21.92px"
          textAlign="left"
        >
          Import Excel - {sequencesData.title}
        </ModalHeader>
        <ModalCloseButton
          top="13px"
          right="20px"
          color="default.secondarytext"
        />
        {isOpenAlert && (
          <Box>
            <Alert px="8" status="error" justifyContent="space-between">
              <AlertIcon />
              <Box w="80%">
                <AlertTitle>Missing Required Fields</AlertTitle>
                <AlertDescription>{submitErrorMessage}</AlertDescription>
              </Box>
              <CloseButton
                position="relative"
                right={-3}
                top={-3}
                onClick={onCloseAlert}
              />
            </Alert>
          </Box>
        )}
        <ModalBody borderRadius="0 0 4px 4px" p={0}>
          <ImportExcelForm
            initialValues={initialValues}
            validationSchema={validationSchema}
            handleSubmit={handleSubmit}
            excelHeaders={excelHeaders}
            handleUploadEnd={handleUploadEnd}
            candidateFieldOptions={candidateFieldOptions}
            handleCandidateFieldSelect={handleCandidateFieldSelect}
            importCount={importCount}
            errorHeaders={errorHeaders}
            isOpenAlert={isOpenAlert}
            onClose={onClose}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
