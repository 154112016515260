import { Flex, Text } from '@chakra-ui/react';
import { getDuration } from 'modules/Leads/utils/date-format';
import moment from 'moment';
import React, { forwardRef } from 'react';
import { FaRegClock } from 'react-icons/fa6';

interface TimeChipInterface {
  text: Date; // A string representing the start time
  bgColor?: string; // Optional background color
  textColor?: string; // Optional text color
}

const TimeChip = forwardRef<HTMLDivElement, TimeChipInterface>(
  ({ bgColor = '#E9D8FD', textColor = 'primary.800', text, ...rest }, ref) => {
    const { days, hours } = getDuration(text);
    const str = hours > 0 ? `${days > 0 ? days : 0}d ${hours}h` : `0d 1h`;
    return (
      <Flex
        gap="4px"
        p="2px 4px"
        bgColor={bgColor}
        borderRadius="5px"
        alignItems="center"
        w="fit-content"
        h="fit-content"
        color={textColor}
        ref={ref}
        {...rest}
      >
        <FaRegClock fontSize="12px" />
        <Text
          fontSize="sm"
          lineHeight="16.37px"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {str}
        </Text>
      </Flex>
    );
  }
);

export default TimeChip;
