import { Box, useToast } from '@chakra-ui/react';
import Button from 'Library/Button';
import {
    fileFormat,
    agreementFileFormat,
    maxUpload,
    fileFormatLabel,
    agreementFileFormatLabel,
} from 'constants/files';
import { useState } from 'react';
import randomstring from 'utils/randomstring';
import { useDispatch, useSelector } from 'react-redux';
import { setResumeAttachments, setResumeProgress, setResumeUploaded, uploadList } from 'store/uploads.slice';
import { newFilename } from 'utils/newFilename';
import axios from 'axios';
import { BASE_URL } from 'constants/values';
interface AtsButtonDropzoneProp {
    onOpen: () => void;
}
const AtsButtonDropzone = ({
    onOpen
}: AtsButtonDropzoneProp) => {
    const dispatch = useDispatch()
    const toast = useToast()
    const {
        prefix,
        withPrefix,
        resumeAttachments,
    } = useSelector((state: any) => state.uploads);
    const [isUploadingFiles, setIsUploadingFiles] = useState<boolean>(false)

    const [isDragOver, setIsDragOver] = useState<boolean>(false)
    let url = BASE_URL + '/resume-parser/file'
    const handleFileUpload = async (files: File[]) => {

        onOpen();
        const uploadPromises = files.map(async (file) => {
            let id = randomstring();

            let count = resumeAttachments.length + 1
            let file_name = withPrefix
                ? newFilename(prefix + '-' + count, file.name)
                : file.name;
            dispatch(uploadList({ uploading: true }));
            setIsUploadingFiles(true)
            dispatch(
                setResumeAttachments({
                    id: id,
                    name: file_name,
                    progress: 0,
                    uploading: true,
                    file: file,
                })
            )
            const config = {
                withCredentials: true,
                onUploadProgress: (progressEvent: any) => {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    dispatch(
                        setResumeProgress({
                            id: id,
                            name: file_name,
                            progress: percentCompleted,
                            uploading: percentCompleted < 100 ? true : false,
                            file: file,
                        })
                    )

                },
            };
            let formData = new FormData();
            formData.append('file', file, file.name);
            await axios
                .post(url, formData, config)
                .then((res) => {
                    const data = {
                        ...res.data?.data,
                        local_id: id,
                    };
                    dispatch(setResumeUploaded(data));
                    // console.log('resume', isResume);
                    setIsUploadingFiles(false)
                })
                .catch((err) => {
                    console.log('err', err);
                });
        });

        await Promise.all(uploadPromises);
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const files = event.dataTransfer.files;

        if (files.length > 0) {
            // onFileDrop(file);
            const extensions = Object.values(files).map((file: File) => {
                console.log("file")
                const splittedFile = file.name.split(".")
                if (file) {
                    return "application/" + splittedFile[splittedFile.length - 1]
                }
            }).filter(Boolean)
            console.log("extensions ", extensions)
            const acceptedExtensions = [] as any[]
            extensions.map((extension: string) => {
                if (agreementFileFormat.includes(extension)) {
                    acceptedExtensions.push(extension)
                }
            })
            if (extensions.length === acceptedExtensions.length) {
                handleFileUpload(Object.values(files).filter(Boolean))
            } else {
                toast({
                    title: "Error Uploading Files",
                    description: "Please only upload files with the following extensions: .pdf, .docx, .doc",
                    status: 'error',
                    isClosable: true,
                    duration: 3000,
                    position: 'top',
                })
            }

        }
        setIsDragOver(false)
    };
    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        setIsDragOver(true)
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDragLeave = () => {
        setIsDragOver(false)
    }


    return (
        <Box
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            w="100%">
            <Button
                leftIcon={isDragOver ? "upload" : "plus"}
                onClick={onOpen} w="100%"
            // loading={isUploadingFiles}
            >
                {isDragOver ? 'Drop File Here' : 'Upload Resume'}
            </Button>
        </Box>
    )
}

export default AtsButtonDropzone