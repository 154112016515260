/* eslint-disable react-hooks/exhaustive-deps */
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { ChangeStatusForm } from './Form';

interface ChangeStatusModalProps {
  id: any;
  isOpen: any;
  onClose: any;
}

export default function ChangeStatusModal({
  id,
  isOpen,
  onClose,
}: ChangeStatusModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={() => {
        onClose();
      }}
      size="lg"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent fontFamily="NunitoSans Regular">
        <ModalHeader
          background="default.white.800"
          borderRadius="4px 4px 0"
          p="18px 32px"
          fontSize="18px"
          lineHeight="21.92px"
          textAlign="left"
        >
          Change Status
        </ModalHeader>
        <ModalCloseButton
          top="13px"
          right="20px"
          color="default.secondarytext"
        />
        <ModalBody borderRadius="0 0 4px 4px" p={0}>
          <ChangeStatusForm id={id} onClose={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
