export function replacePlaceholders(
  text: string,
  placeholders: Record<string, string>
) {
  try {
    for (const placeholder in placeholders) {
      const regex = new RegExp(`{{${placeholder}}}`, 'g');
      text = text.replace(regex, placeholders[placeholder]);
    }
    return text;
  } catch (e) {
    return text;
  }
}
