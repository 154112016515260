import * as Yup from 'yup';

export const stepsListTemp: any = {
  touches: [
    {
      id: 327,
      subject: 'Dolore qui dolores i',
      subject_remote: 'Dicta molestiae veli',
      content:
        '<p style="font-family: Verdana; font-size: 10pt; margin: 0px; margin-block: 0px;"><span style="font-family: Verdana; font-size: 10pt;">asdad</span></p>',
      touch_order: 0,
      active: 1,
      days_to_wait: 12,
      created_at: '2024-03-23T04:53:29.300Z',
      updated_at: '2024-03-23T04:53:29.300Z',
      deleted_at: null,
    },
  ],
};

export const STEPS_SCHEMA = Yup.lazy(() =>
  Yup.object().shape({
    days_to_wait: Yup.number(),
    subject: Yup.string(),
    subject_remote: Yup.string(),
    content: Yup.string(),
    order: Yup.number(),
    previewOpen: Yup.bool(),
    isNew: Yup.bool(),
  })
);

export const EMAIL_SCHEMA = Yup.lazy(() =>
  Yup.object().shape({
    to: Yup.string().email('Invalid email.').required('Email is required.'),
  })
);
