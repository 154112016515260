import { useSelector } from 'react-redux';

import { Box, Flex, GridItem, Image, Link, SimpleGrid } from '@chakra-ui/react';

import GoTo from 'assets/images/goto.svg';
import toUrl from 'utils/toUrl';
import ProfileCard from 'components/profile-card.component';

const LeadsDetails = () => {
  const { leadData, leadDetails } = useSelector((state: any) => state.leads);

  return (
    <Box py={4}>
      <Flex gap="18px" alignItems="center" textAlign="center">
        <Box
          pt="20px"
          pb="14px"
          width="160px"
          border="0.5px solid rgba(197, 197, 197, 0.8)"
          borderRadius="4px"
        >
          <Box
            fontWeight={700}
            fontSize="20px"
            lineHeight="18px"
            marginBottom="8px"
          >
            {leadDetails?.jobSummary?.total_opening}
          </Box>
          <Box
            fontWeight={500}
            fontSize="14px"
            lineHeight="18px"
            color="#6B6C7B"
          >
            Open Jobs
          </Box>
        </Box>
        <Box
          pt="20px"
          pb="14px"
          width="160px"
          border="0.5px solid rgba(197, 197, 197, 0.8)"
          borderRadius="4px"
        >
          <Box
            fontWeight={700}
            fontSize="20px"
            lineHeight="18px"
            marginBottom="8px"
          >
            {leadDetails?.jobSummary?.total_interview}
          </Box>
          <Box
            fontWeight={500}
            fontSize="14px"
            lineHeight="18px"
            color="#6B6C7B"
          >
            Interviews
          </Box>
        </Box>
        <Box
          pt="20px"
          pb="14px"
          width="160px"
          border="0.5px solid rgba(197, 197, 197, 0.8)"
          borderRadius="4px"
        >
          <Box
            fontWeight={700}
            fontSize="20px"
            lineHeight="18px"
            marginBottom="8px"
          >
            {leadDetails?.jobSummary?.total_submits}
          </Box>
          <Box
            fontWeight={500}
            fontSize="14px"
            lineHeight="18px"
            color="#6B6C7B"
          >
            Submits
          </Box>
        </Box>
      </Flex>
      <Box>
        <SimpleGrid
          columns={2}
          w="80%"
          margin="28px 0"
          fontWeight={500}
          fontSize="16px"
          lineHeight="18px"
        >
          <GridItem color="default.gray.600">Mail</GridItem>
          <GridItem color="primary.800">
            <Link
              href={`mailto:${leadData?.primary_email}`}
              target="_blank"
              style={{ display: 'flex', gap: '7px' }}
              rel="noreferrer"
            >
              {leadData?.primary_email}
            </Link>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid
          columns={2}
          w="80%"
          margin="28px 0"
          fontWeight={500}
          fontSize="16px"
          lineHeight="18px"
        >
          <GridItem color="default.gray.600">Work Phone</GridItem>
          <GridItem>
            <Link href={`tel:${leadData?.work_phone}`}>
              {leadData?.extension && leadData?.extension !== '' ? (
                <span>
                  {leadData?.work_phone}, Ext. {leadData?.extension}
                </span>
              ) : (
                <span>{leadData?.work_phone}</span>
              )}
            </Link>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid
          columns={2}
          w="80%"
          margin="28px 0"
          fontWeight={500}
          fontSize="16px"
          lineHeight="18px"
        >
          <GridItem color="default.gray.600">Personal Phone</GridItem>
          <GridItem>
            <Link href={`tel:${leadData?.personal_phone}`}>
              {leadData?.personal_phone}
            </Link>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid
          columns={2}
          w="80%"
          margin="28px 0"
          fontWeight={500}
          fontSize="16px"
          lineHeight="18px"
        >
          <GridItem color="default.gray.600">Linkedin Link</GridItem>
          <GridItem color="primary.800">
            <Link
              href={toUrl(leadData?.linked_in_url)}
              target="_blank"
              style={{ display: 'flex', gap: '7px' }}
              rel="noreferrer"
            >
              {leadData?.linked_in_url}
              {leadData?.linked_in_url && (
                <span>
                  <Image src={GoTo} alt="logo" />
                </span>
              )}
            </Link>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid
          columns={2}
          w="80%"
          margin="28px 0"
          fontWeight={500}
          fontSize="16px"
          lineHeight="18px"
        >
          <GridItem color="default.gray.600">Client Job Name</GridItem>
          <GridItem color="primary.800">
            <Link
              href={toUrl(leadData?.client_job_link)}
              target="_blank"
              style={{ display: 'flex', gap: '7px' }}
              rel="noreferrer"
            >
              {leadData?.client_job_title}
            </Link>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid
          columns={2}
          w="80%"
          margin="28px 0"
          fontWeight={500}
          fontSize="16px"
          lineHeight="18px"
        >
          <GridItem color="default.gray.600">Client Job Link</GridItem>
          <GridItem color="primary.800">
            <Link
              href={toUrl(leadData?.client_job_link)}
              target="_blank"
              style={{ display: 'flex', gap: '7px' }}
              rel="noreferrer"
            >
              {leadData?.client_job_link}
              {leadData?.client_job_link && (
                <span>
                  <Image src={GoTo} alt="logo" />
                </span>
              )}
            </Link>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid
          columns={2}
          w="80%"
          margin="28px 0"
          fontWeight={500}
          fontSize="16px"
          lineHeight="18px"
        >
          <GridItem color="default.gray.600">Created by</GridItem>
          <GridItem>
            <ProfileCard
              // id={leadData?.user?.id}
              id="2"
              user_id={leadData?.user?.id}
              name={[
                leadData?.user?.first_name,
                leadData?.user?.last_name,
              ].join(' ')}
              email={leadData?.user?.email || ''}
              thumbnail={leadData?.user?.thumbnailSignedUrl}
              profile={leadData?.user?.signedUrl}
            />
          </GridItem>
        </SimpleGrid>
        <SimpleGrid
          columns={2}
          w="80%"
          margin="28px 0"
          fontWeight={500}
          fontSize="16px"
          lineHeight="18px"
        >
          <GridItem color="default.gray.600">Lead Source</GridItem>
          <GridItem>{leadData?.lead_source?.source}</GridItem>
        </SimpleGrid>
        <SimpleGrid
          columns={2}
          w="80%"
          margin="28px 0"
          fontWeight={500}
          fontSize="16px"
          lineHeight="18px"
        >
          <GridItem color="default.gray.600">Last Activity</GridItem>
          <GridItem>{leadData?.primary_email}</GridItem>
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default LeadsDetails;
