import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';

import CandidateDetails from './TabPanel/Details';
import CandidateSequences from './TabPanel/Sequences';
import { CandidateActivities } from './TabPanel/Activities';
import { CandidatesNotes } from './TabPanel/Notes';

import { tabListIndex } from './constants';
import { useTabListServices } from './useTabListServices';

export const CandidatesTabList = () => {
  const { stateTab, handleRoute, activeTab } = useTabListServices();
  return (
    <Tabs colorScheme="purple" defaultIndex={tabListIndex[stateTab]}>
      <TabList fontSize="md">
        <Tab
          fontWeight="700"
          onClick={() => handleRoute('details')}
          _focus={{ boxShadow: 'none !important' }}
        >
          Details
        </Tab>
        <Tab
          fontWeight="700"
          onClick={() => handleRoute('sequences')}
          _focus={{ boxShadow: 'none !important' }}
        >
          Sequences
        </Tab>
        <Tab
          fontWeight="700"
          onClick={() => handleRoute('activities')}
          _focus={{ boxShadow: 'none !important' }}
        >
          Activities
        </Tab>
        <Tab
          fontWeight="700"
          onClick={() => handleRoute('notes')}
          _focus={{ boxShadow: 'none !important' }}
        >
          Notes
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel>{activeTab === 'details' && <CandidateDetails />}</TabPanel>
        <TabPanel>
          {activeTab === 'sequences' && <CandidateSequences />}
        </TabPanel>
        <TabPanel>
          {activeTab === 'activities' && <CandidateActivities />}
        </TabPanel>
        <TabPanel>{activeTab === 'notes' && <CandidatesNotes />}</TabPanel>
      </TabPanels>
    </Tabs>
  );
};
