export const SIDE_BAR_ITEMS = [
  {
    id: 1,
    label: 'Candidates',
    to: 'candidates',
  },
  {
    id: 2,
    label: 'Steps',
    to: 'steps',
  },
  {
    id: 3,
    label: 'Schedules',
    to: 'schedules',
  },
  {
    id: 4,
    label: 'Settings',
    to: 'settings',
  },
];
