/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  updateOutreach,
  useGetEmailConnectionListMutation,
  usePostChangeDefaultConnectionMutation,
  usePostDeleteEmailConnectionMutation,
  usePostDisableSMTPMutation,
  usePostDisconnectEmailConnectionMutation,
} from 'store/outreach.slice';
import { tabOptions } from '../../../constants';

interface EmailConnectionTableServicesProps {
  onOpenLoginEmail?: any;
}
export const useEmailConnectionTableServices = ({
  onOpenLoginEmail,
}: EmailConnectionTableServicesProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const toast = useToast();
  const { settingType } = useParams();

  const isEmailConnection = settingType === tabOptions[2];

  const {
    emailConnectionList,
    emailConnectionData,
    emailConnectionPgBtn,
    emailConnectionPage,
    isLoading,
  } = useSelector((state: any) => state.outreach);

  const [reqGetEmailConnectionList] = useGetEmailConnectionListMutation();
  const [reqPostChangeDefaultConnection, resPostChangeDefaultConnection] =
    usePostChangeDefaultConnectionMutation();
  const [reqPostDisableSMTP, resPostDisableSMTP] = usePostDisableSMTPMutation();

  const [rowId, setRowId] = useState(emailConnectionData?.rowId);
  const [totalConnectionList, setTotalConnectionList] = useState(
    emailConnectionList.length
  );

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [isIndeterminate, setisIndeterminate] = useState(false);

  const handleSMTP = (item: any) => {
    if (item.smtp_enable === 1) {
      reqPostDisableSMTP(item.id);
    } else {
      onOpenLoginEmail();
    }
  };

  const handleSelectAll = (e: any) => {
    const { checked } = e.target;
    setIsCheckAll(checked);
    setIsCheck(emailConnectionList.map((li: any) => li.id));

    if (!checked) {
      setIsCheck([]);
    }
  };

  const handleCheck = async (e: any) => {
    const { id, checked } = e.target;
    let ID = parseInt(id);
    setIsCheck([...isCheck, ID]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== ID));
    }
  };

  const handlePrevData = () => {
    const connectionID = emailConnectionList[rowId - 1 - 1].id;
    setRowId((prev: number) => prev - 1);
    navigate(
      `/engage/${params.settingNav}/${params.settingTab}/${params.settingType}/${connectionID}`
    );
  };

  const handleNextData = () => {
    const connectionID = emailConnectionList[rowId - 1 + 1].id;
    setRowId((prev: number) => prev + 1);
    navigate(
      `/engage/${params.settingNav}/${params.settingTab}/${params.settingType}/${connectionID}`
    );
  };

  const pageChange = async (page: number) => {
    let newParam = {
      ...emailConnectionPgBtn,
      page: page,
      take: emailConnectionPgBtn.take,
      query: emailConnectionPgBtn.query,
    };
    dispatch(
      updateOutreach({
        emailConnectionPgBtn: newParam,
      })
    );
    reqGetEmailConnectionList(newParam);
  };

  const entryChange = (entries: number) => {
    let newParam = {
      ...emailConnectionPgBtn,
      page: 1,
      take: entries,
    };
    dispatch(
      updateOutreach({
        candidateSequencePgBtn: newParam,
      })
    );
    reqGetEmailConnectionList(newParam);
  };

  const [reqPostDisconnectEmailConnection, resPostDisconnectEmailConnection] =
    usePostDisconnectEmailConnectionMutation();
  const [reqPostDeleteEmailConnection, resPostDeleteEmailConnection] =
    usePostDeleteEmailConnectionMutation();

  const handleDisconnectConnection = (id: number) => {
    reqPostDisconnectEmailConnection(id);
  };

  const handleDeleteConnection = (id: number) => {
    reqPostDeleteEmailConnection(id);
  };

  useEffect(() => {
    if (resPostDisconnectEmailConnection.isSuccess) {
      reqGetEmailConnectionList(emailConnectionPgBtn);

      toast({
        title: 'Email connection disconnect.',
        description: "We've disconnect your email connection.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostDisconnectEmailConnection.isSuccess]);

  useEffect(() => {
    if (resPostDeleteEmailConnection.isSuccess) {
      reqGetEmailConnectionList(emailConnectionPgBtn);

      toast({
        title: 'Email connection delete.',
        description: "We've delete your email connection.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostDeleteEmailConnection.isSuccess]);

  useEffect(() => {
    setisIndeterminate(false);
    if (isCheck.length > 0 && isCheck.length < emailConnectionList.length) {
      setisIndeterminate(true);
    }
    if (isCheck.length <= 0) {
      setIsCheckAll(false);
    }
    if (isCheck.length === emailConnectionList.length) {
      setIsCheckAll(true);
    }
  }, [isCheck, isCheckAll, emailConnectionList]);

  const handleChangeDefault = (id: number) => {
    reqPostChangeDefaultConnection(id);
  };

  useEffect(() => {
    if (isEmailConnection) {
      reqGetEmailConnectionList(emailConnectionPgBtn);
    }
  }, [isEmailConnection]);

  useEffect(() => {
    if (resPostChangeDefaultConnection.isSuccess) {
      reqGetEmailConnectionList(emailConnectionPgBtn);

      toast({
        title: 'Default connection updated.',
        description: "We've updated your default connection.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostChangeDefaultConnection.isSuccess]);

  useEffect(() => {
    if (resPostDisableSMTP.isSuccess) {
      reqGetEmailConnectionList(emailConnectionPgBtn);
      toast({
        title: 'Email disabled SMTP.',
        description: "We've disabled your account's SMTP.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostDisableSMTP?.isSuccess]);

  return {
    emailConnectionList,
    emailConnectionData,
    emailConnectionPgBtn,
    isCheck,
    isIndeterminate,
    isCheckAll,
    rowId,
    setRowId,
    totalConnectionList,
    setTotalConnectionList,
    handlePrevData,
    handleNextData,
    handleCheck,
    handleSelectAll,
    handleChangeDefault,
    emailConnectionPage,
    pageChange,
    entryChange,
    handleSMTP,
    handleDisconnectConnection,
    handleDeleteConnection,
    isLoading,
  };
};
