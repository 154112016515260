import { ClientListData } from 'services/client/client.type';

export function getClient(data: ClientListData['data']) {
  return data.map((data) => ({
    label: data.name,
    value: data.id,
    industry: data.specs,
    contact: data.leads.map((lead) => ({
      label: `${lead.first_name} ${lead.last_name}`,
      value: lead.id,
      title: lead?.title,
    })),
    email: data.leads.map((lead) => ({
      label: `${lead.first_name} ${lead.last_name}`,
      value: lead.primary_email,
    })),
    manager: data.manager.map((manager) => ({
      label: `${manager.user.first_name} ${manager.user.last_name}`,
      value: manager.user_id,
    })),
    company_website: data.website,
  }));
}

type SelectorType<T> = T[];
export function getLabelValueFormat<T, K extends keyof T>(
  data: SelectorType<T>,
  value: K,
  label: K
): { label: T[K]; value: T[K] }[] {
  if (!data) return [];
  return data?.map((data) => ({ label: data[label], value: data[value] }));
}
