import { debounce } from 'lodash';
import { TAB_TYPE } from 'modules/Engage/constant';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  updateOutreach,
  useGetEmailConnectionListMutation,
  useGetEmailTemplateListMutation,
  usePostListCandidatesMutation,
  usePostSequenceListMutation,
  usePostSMSHistoryListMutation,
} from 'store/outreach.slice';

export const useSideBarServices = () => {
  const dispatch = useDispatch();
  const { settingTypeId } = useParams();

  const [tab, setTab] = useState<string>(TAB_TYPE.recruitment);

  const [reqPostSequencesList, resPostSequencesList] =
    usePostSequenceListMutation();
  const [reqCandidateList, resCandidateList] = usePostListCandidatesMutation();
  const [reqPostSMSHistoryList, resPostSMSHistoryList] =
    usePostSMSHistoryListMutation();
  const [reqGetEmailTemplateList, resGetEmailTemplateList] =
    useGetEmailTemplateListMutation();
  const [reqGetEmailConnectionList, resGetEmailConnectionList] =
    useGetEmailConnectionListMutation();

  const [search, setSearch] = useState('');
  const [searchCandidate, setSearchCandidate] = useState('');
  const [searchSMSHistory, setSearchSMSHistory] = useState('');
  const [searchEmailTemplate, setSearchEmailTemplate] = useState('');
  const [searchEmailConnection, setSearchEmailConnection] = useState('');

  const [statusSequencePayload, setStatusSequencePayload] = useState([]);
  const [statusEnrichPayload, setStatusEnrichPayload] = useState([]);
  const [statusCandidatePayload, setStatusCandidatePayload] = useState([]);

  const {
    sequencesPgBtn,
    candidateSequencePgBtn,
    smsHistoryPgBtn,
    emailTemplatePgBtn,
    emailConnectionPgBtn,
  } = useSelector((state: any) => state.outreach);

  const handleSearch = debounce((e: any) => {
    let query = e.target.value;

    if (search === '') {
      query = '';
    }

    const params = {
      ...sequencesPgBtn,
      query: query,
    };

    dispatch(updateOutreach({ sequencesPgBtn: params }));
    if (query.length >= 3 || search.length >= 3) {
      reqPostSequencesList(params);
    }
  }, 300);

  const handleSearchCandidate = debounce((e: any) => {
    let query = e.target.value;

    if (searchCandidate === '') {
      query = '';
    }

    const payload = {
      ...candidateSequencePgBtn,
      query: query,
    };

    dispatch(updateOutreach({ candidateSequencePgBtn: payload }));

    if (query.length >= 3 || searchCandidate.length >= 3) {
      reqCandidateList(payload);
    }
  }, 300);

  const handleSearchSMSHistory = debounce((e: any) => {
    let query = e.target.value;

    if (searchSMSHistory === '') {
      query = '';
    }

    const params = {
      ...smsHistoryPgBtn,
      query: query,
    };

    dispatch(updateOutreach({ smsHistoryPgBtn: params }));
    if (query.length >= 3 || searchSMSHistory.length >= 3) {
      reqPostSMSHistoryList(params);
    }
  }, 300);

  const handleSearchEmailTemplate = debounce((e: any) => {
    let query = e.target.value;

    if (searchEmailTemplate === '') {
      query = '';
    }

    let params = {
      take: emailTemplatePgBtn.take,
      query: query,
      page: emailTemplatePgBtn.page,
    };

    dispatch(updateOutreach({ emailTemplatePgBtn: params }));
    if (query.length >= 3 || searchEmailTemplate.length >= 3) {
      reqGetEmailTemplateList(params);
    }
  }, 300);

  const handleSearchEmailConnection = debounce((e: any) => {
    let query = e.target.value;

    if (searchEmailConnection === '') {
      query = '';
    }

    let params = {
      take: emailConnectionPgBtn.take,
      query: query,
      page: emailConnectionPgBtn.page,
    };

    dispatch(updateOutreach({ emailConnectionPgBtn: params }));
    if (query.length >= 3 || searchEmailConnection.length >= 3) {
      reqGetEmailConnectionList(params);
    }
  }, 300);

  useEffect(() => {
    if (statusSequencePayload) {
      let params = {
        ...sequencesPgBtn,
        sequence_status:
          statusSequencePayload.length === 2 ? 'all' : statusSequencePayload[0],
      };

      dispatch(updateOutreach({ sequencesPgBtn: params }));
      reqPostSequencesList(params);
    }
  }, [statusSequencePayload]);

  useEffect(() => {
    if (statusCandidatePayload) {
      const flattenedStatus = statusCandidatePayload.flat();
      const uniqueStatus = [...new Set(flattenedStatus)];

      let payload = {
        ...candidateSequencePgBtn,
        query: candidateSequencePgBtn.query,
        sequence_id: settingTypeId,
        sequence_candidate_status:
          statusCandidatePayload.length > 1 &&
          statusCandidatePayload.length !== 7
            ? uniqueStatus
            : statusCandidatePayload[0],
      };

      dispatch(updateOutreach({ candidateSequencePgBtn: payload }));
      reqCandidateList(payload);
    }
  }, [statusCandidatePayload]);

  useEffect(() => {
    dispatch(
      updateOutreach({
        isLoading:
          resPostSequencesList.isLoading ||
          resCandidateList.isLoading ||
          resPostSMSHistoryList.isLoading ||
          resGetEmailTemplateList.isLoading ||
          resGetEmailConnectionList.isLoading,
      })
    );
  }, [
    resPostSequencesList.isLoading,
    resCandidateList.isLoading,
    resPostSMSHistoryList.isLoading,
    resGetEmailTemplateList.isLoading,
    resGetEmailConnectionList.isLoading,
  ]);

  return {
    tab,
    setTab,
    search,
    setSearch,
    handleSearch,
    searchCandidate,
    setSearchCandidate,
    handleSearchCandidate,
    searchSMSHistory,
    setSearchSMSHistory,
    handleSearchSMSHistory,
    searchEmailTemplate,
    setSearchEmailTemplate,
    handleSearchEmailTemplate,
    searchEmailConnection,
    setSearchEmailConnection,
    handleSearchEmailConnection,
    setStatusSequencePayload,
    setStatusEnrichPayload,
    setStatusCandidatePayload,
  };
};
