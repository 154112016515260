/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, Image, Stack, Text } from '@chakra-ui/react';
import parse from 'html-react-parser';

import Button from 'Library/Button';
import { useStepsServices } from './useStepsServices';
import { StepsForm } from './Form';
import { useStepsFormServices } from './Form/useStepsFormServices';

import MdTime from 'assets/images/engage/MdTime.svg';
import MdTrash from 'assets/images/engage/MdTrash.svg';
import MdActive from 'assets/images/engage/MdActive.svg';

const StepsList = () => {
  const { stepsData, sequencesData, handleStepsData, handleReset } =
    useStepsServices();
  const {
    handleDeleteStep,
    initialValues,
    validationSchema,
    handleOnSubmit,
    handleSendEmail,
    isLoading,
    intialValueEmail,
    validationSchemaEmail,
    previewData,
    handlePreview,
    isSpinner,
  } = useStepsFormServices();

  return (
    <Stack direction="row" fontFamily="NunitoSans Regular">
      <Stack width="50%">
        <Stack spacing="16px">
          {sequencesData?.touches &&
            sequencesData?.touches?.length > 0 &&
            sequencesData?.touches.map((touch: any, index: number) => {
              return (
                <Box
                  sx={{
                    bg: touch.id === stepsData.id ? '#FAF6FF' : '#fff',
                    border:
                      touch.id === stepsData.id
                        ? '1px solid #6930CA'
                        : '1px solid #E7EDF4',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                  _hover={{
                    bg: '#FAF6FF',
                    border: '1px solid #6930CA',
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{
                      borderBottom: '1px solid #E7EDF4',
                      borderRadius: '4px 4px 0px 0px',
                      m: '16px 12px 0',
                      pb: '16px',
                    }}
                  >
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      sx={{
                        fontSize: '14px',
                        color: '#718096',
                      }}
                    >
                      <Flex gap="4px">
                        <Image src={MdTime} />
                        <Text>
                          [EMAIL] Wait for {touch.days_to_wait} day(s) then
                        </Text>
                      </Flex>
                    </Box>

                    <Box onClick={() => handleDeleteStep(touch)}>
                      <Image src={MdTrash} />
                    </Box>
                  </Stack>

                  <Stack p="12px">
                    <Stack
                      spacing="10px"
                      sx={{
                        borderRadius: '4px 4px 0px 0px',
                        px: '10px',
                      }}
                      onClick={() => {
                        handleReset();
                        setTimeout(() => {
                          handleStepsData({ ...touch, isNew: false });
                        }, 500);
                      }}
                    >
                      <Flex gap="16px" alignItems="center" pt="10px">
                        <Image src={MdActive} />
                        <Text
                          sx={{
                            fontSize: '16px',
                            fontWeight: 700,
                            color: '#2B2D42',
                          }}
                        >
                          {touch.subject}
                        </Text>
                      </Flex>

                      <Box
                        sx={{
                          pb: '10px',
                          color: '#2B2D42',
                        }}
                      >
                        {parse(touch.content || '')}
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
              );
            })}

          <Button variant="solid" onClick={handleReset}>
            Add Email Step
          </Button>
        </Stack>
      </Stack>

      <StepsForm
        initialValues={initialValues}
        validationSchema={validationSchema}
        handleOnSubmit={handleOnSubmit}
        handleSendEmail={handleSendEmail}
        isLoading={isLoading}
        intialValueEmail={intialValueEmail}
        validationSchemaEmail={validationSchemaEmail}
        handleReset={handleReset}
        previewData={previewData}
        handlePreview={handlePreview}
        isSpinner={isSpinner}
      />
    </Stack>
  );
};

export default StepsList;
