import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
} from '@chakra-ui/react';
import { Field, ErrorMessage, FieldProps } from 'formik';
import AtsAutoComplete from 'components/app/Leads/components/Autocomplete';
import AtsEmailBody from 'components/app/Global/Email/AIBody';
import { useAIGeneratorServices } from 'components/app/AIGenerator/useAIGeneratorServices';
import { IntialValueType } from '../../utils/validationSchema';
import {
  useFetchCountriesQuery,
  useFetchWorkTypeQuery,
  useLazyFetchGeoLocationQuery,
} from 'services/constants/constants.query';
import { getLabelValueFormat } from '../../utils/selectors';
import Select from 'components/ui/Select/Select';
import { debounce } from 'lodash';
import { useRef } from 'react';

export default function JobAdsForm() {
  const {
    hasAIGenerate,
    setHasAIGenerate,
    setIsGenerate,
    handleValues,
    handleAIGenerator,
    setIsError,
    isMaximize,
  } = useAIGeneratorServices({ hasType: 'Jobs' });

  const { data: countriesList, isFetching: isCountriesListFetching } =
    useFetchCountriesQuery(
      {},
      {
        selectFromResult: ({ data, ...other }) => ({
          data: data ? getLabelValueFormat(data, 'code', 'country') : [],
          ...other,
        }),
      }
    );
  const { data: workTypeList, isFetching: isWorkTypeFetching } =
    useFetchWorkTypeQuery(
      {},
      {
        selectFromResult: ({ data, ...other }) => ({
          data: data ? getLabelValueFormat(data, 'id', 'name') : [],
          ...other,
        }),
      }
    );
  const [
    fetchGeoLocation,
    { data: geoLocationList, isFetching: isGeoLocationLoading },
  ] = useLazyFetchGeoLocationQuery({
    selectFromResult: ({ data, ...other }) => ({
      data: data
        ? data.map((data) => ({
            label: `${data.postal_code} ${data.city} ${data.state} ${data.country}`,
            value: data.postal_code,
            ...data,
          }))
        : [],
      ...other,
    }),
  });
  const fetchGeoLocationDebounce = useRef(
    debounce((value: string) => {
      fetchGeoLocation({ column: 'city', search: value });
    }, 1000)
  ).current;

  function handleUpdateQuery(value: string) {
    fetchGeoLocationDebounce.cancel();
    fetchGeoLocationDebounce(value);
  }

  return (
    <Field>
      {({
        form: { values, handleChange, errors, touched, setFieldValue },
      }: FieldProps<IntialValueType>) => (
        <Stack justifyContent="space-between" h="inherit">
          {/* Main form content container */}
          <Box h="inherit">
            <Flex h="inherit" gap="10px" justifyContent="space-between">
              {/* Job Ad Item */}
              <Stack
                h="inherit"
                spacing="24px"
                sx={{
                  display: hasAIGenerate !== '' ? 'none' : 'flex',
                  w: '35%',
                  p: '10px',
                  background: '#fff',
                  borderRadius: '8px',
                }}
              >
                {/* Posting Title Field */}
                <FormControl
                  isInvalid={Boolean(
                    !!errors.posting_title && touched.posting_title
                  )}
                  isRequired
                >
                  <FormLabel>Posting Title</FormLabel>

                  <Field
                    autoFocus
                    component={Input}
                    name="posting_title"
                    placeholder="Posting Title"
                    type="text"
                    variant="outline"
                    value={values.posting_title}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('posting_title', e.target.value)
                    }
                  />
                  <ErrorMessage
                    name="posting_title"
                    component={FormErrorMessage}
                  />
                </FormControl>

                {/* Work Type Field */}
                <FormControl
                  isInvalid={Boolean(!!errors.work_type && touched.work_type)}
                  isRequired
                >
                  <FormLabel>Work Type</FormLabel>
                  <Field
                    name="work_type"
                    placeholder="Select Type"
                    value={values.work_type}
                    component={Select}
                    options={workTypeList}
                    onChange={(e: any) => setFieldValue('work_type', e)}
                    isLoading={isWorkTypeFetching}
                    isError={Boolean(!!errors.work_type && touched.work_type)}
                  />
                  <ErrorMessage
                    name="work_type"
                    render={(msg: any) => (
                      <FormErrorMessage>{msg?.label}</FormErrorMessage>
                    )}
                  />
                </FormControl>

                {/* Location Fields (City, State, Country, Zip Code) */}
                <Flex gap="8px">
                  {/* City Field */}
                  <FormControl
                    isInvalid={Boolean(errors.city && touched.city)}
                    isRequired
                  >
                    <FormLabel>City</FormLabel>
                    <AtsAutoComplete
                      name="city"
                      placeholder="City"
                      type="text"
                      variant="outline"
                      value={values.city}
                      onChange={(e: any) => {
                        handleUpdateQuery(e.target.value);
                      }}
                      options={geoLocationList}
                      isLoading={isGeoLocationLoading}
                      onSelect={(item: any) => {
                        setFieldValue(`city`, item.city);
                        setFieldValue(`state`, item.state);
                        setFieldValue(`zip_code`, item.postal_code);
                        setFieldValue(`country`, {
                          label: item.country,
                          value: item.country_code,
                        });
                      }}
                    />
                    <ErrorMessage
                      name="city"
                      render={(msg: any) => (
                        <FormErrorMessage>{msg?.label}</FormErrorMessage>
                      )}
                    />
                  </FormControl>

                  {/* State/Province Field */}
                  <FormControl
                    isInvalid={Boolean(errors.state && touched.state)}
                    isRequired
                  >
                    <FormLabel>State / Province</FormLabel>
                    <Field
                      as={Input}
                      name="state"
                      type="text"
                      placeholder="State / Province"
                      onChange={handleChange}
                    />
                    <ErrorMessage name="state" component={FormErrorMessage} />
                  </FormControl>
                </Flex>

                <Flex gap="8px">
                  <FormControl
                    isInvalid={Boolean(errors.country && touched.country)}
                    isRequired
                  >
                    <FormLabel>Country</FormLabel>
                    <Field
                      name={`country`}
                      options={countriesList}
                      component={Select}
                      placeholder="Country"
                      value={values.country}
                      onChange={(e: any) => setFieldValue('country', e)}
                      isLoading={isCountriesListFetching}
                    />
                    <ErrorMessage
                      name="country"
                      render={(msg: any) => (
                        <FormErrorMessage>{msg?.label}</FormErrorMessage>
                      )}
                    />
                  </FormControl>
                  <FormControl
                    isInvalid={Boolean(errors.zip_code && touched.zip_code)}
                    isRequired
                  >
                    <FormLabel>Zip Code</FormLabel>
                    <Field
                      as={Input}
                      name="zip_code"
                      type="text"
                      placeholder="Zip Code"
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name="zip_code"
                      component={FormErrorMessage}
                    />
                  </FormControl>
                </Flex>
                <Box>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="19.1px"
                    color="#2B2D42"
                    fontWeight={700}
                    mb="16px"
                  >
                    Publish On
                  </FormLabel>

                  <Flex gap="8px" justifyContent="space-between">
                    <Stack spacing="8px">
                      <Checkbox
                        name={`career_page`}
                        isChecked={values.career_page}
                        onChange={handleChange}
                      >
                        Careers Page
                      </Checkbox>

                      <Checkbox
                        name={`paid_job`}
                        isChecked={values.paid_job}
                        onChange={handleChange}
                      >
                        Paid Job boards
                      </Checkbox>

                      <Checkbox
                        name={`free_job`}
                        isChecked={values.free_job}
                        onChange={handleChange}
                      >
                        Free Job boards
                      </Checkbox>
                    </Stack>
                    <Box>
                      <Checkbox
                        name={`show_profile`}
                        isChecked={values.show_profile}
                        onChange={handleChange}
                      >
                        Show Profile on Careers page
                      </Checkbox>
                    </Box>
                  </Flex>
                </Box>
              </Stack>
              <Flex
                h="inherit"
                justifyContent="center"
                gap="10px"
                sx={{
                  w: hasAIGenerate !== '' ? '100%' : '65%',
                  '& #ai': {
                    m: 0,
                    overflowY: 'scroll',
                    maxHeight: '450px',
                  },
                  '& ::-webkit-scrollbar': {
                    display: 'none',
                  },
                }}
              >
                <Stack
                  h="inherit"
                  spacing="10px"
                  sx={{
                    display: hasAIGenerate && isMaximize ? 'none' : 'flex',
                    w: hasAIGenerate !== '' ? '65%' : '100%',
                    position: 'relative',
                    bgColor: '#FFF',
                    borderRadius: '6px',
                    p: '16px',
                  }}
                >
                  <FormControl
                    isRequired
                    isInvalid={Boolean(
                      errors.job_description && touched.job_description
                    )}
                  >
                    <FormLabel>Job Description</FormLabel>

                    <Stack
                      h="60vh"
                      sx={{
                        position: 'relative',
                        overflowY: 'hidden',
                        '& ::-webkit-scrollbar': {
                          display: 'none',
                        },
                      }}
                    >
                      <AtsEmailBody
                        value={values.job_description}
                        onChange={(e: any) => {
                          setFieldValue(`job_description`, e);
                        }}
                        height={300}
                        hasAIGenerate={hasAIGenerate}
                        setHasAIGenerate={setHasAIGenerate}
                        setIsGenerate={setIsGenerate}
                        handleValues={handleValues}
                        setIsError={setIsError}
                        subject={values.subject}
                        body={values.job_description}
                        allowedCategory={['Candidates', 'Jobs']}
                      />
                    </Stack>
                    <ErrorMessage
                      name="job_description"
                      component={FormErrorMessage}
                    />
                  </FormControl>
                </Stack>

                {handleAIGenerator(setFieldValue)}
              </Flex>
            </Flex>
          </Box>
        </Stack>
      )}
    </Field>
  );
}
