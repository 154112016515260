/* eslint-disable react-hooks/exhaustive-deps */
// Chakra UI components for layout and notifications
import {
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';

// Application-specific assets
import MdError from 'assets/images/MdError.svg';

// Application-specific hooks
import { usePostAddToCampaignMutation } from 'store/outreach.slice';

// React hook for side effects
import { useEffect } from 'react';

interface AddToCampaignModalProps {
  id: number[];
  isOpen: boolean;
  onClose: () => void;
  isBulk: boolean;
}

export default function AddToCampaignModal({
  id,
  isOpen,
  onClose,
  isBulk = false,
}: AddToCampaignModalProps) {
  const toast = useToast();

  const [reqAddToCampaign, resAddToCampaign] = usePostAddToCampaignMutation();

  const handleSubmit = () => {
    const params = {
      assoc_ids: id,
      isBulk,
    };
    reqAddToCampaign(params);
  };

  useEffect(() => {
    if (resAddToCampaign.isSuccess) {
      onClose();

      toast({
        title: 'Campaign added.',
        description: "We've added this job to a campaign.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resAddToCampaign.isSuccess]);

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={() => {
        onClose();
      }}
      size="lg"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent fontFamily="NunitoSans Regular">
        <ModalBody borderRadius="5px" p="16px 24px">
          <Stack m="16px auto" textAlign="center" w="320px">
            <Image src={MdError} width="100px" height="100px" m="0 auto" />
            <Text sx={{ fontSize: '20px', fontWeight: 600, color: '#2B2D42' }}>
              You are about to add this candidate to a campaign.
            </Text>
            <Text sx={{ fontSize: '16px', fontWeight: 300, color: '#2B2D42' }}>
              Are you sure you want to continue?
            </Text>
          </Stack>
          <Flex gap="10px" mt="40px">
            <Button
              onClick={() => onClose()}
              w="100%"
              sx={{
                borderColor: '#D68309',
                color: '#D68309',
                fontSize: '14px',
              }}
              _hover={{
                borderColor: '#D68309',
                color: '#D68309',
              }}
            >
              Cancel
            </Button>
            <Button
              variant="solid"
              onClick={handleSubmit}
              w="100%"
              sx={{
                borderColor: '#D68309',
                bgColor: '#D68309',
                fontSize: '14px',
              }}
              _hover={{
                borderColor: '#D68309',
                bgColor: '#D68309',
              }}
            >
              Yes, confirm
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
