/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Flex, Image, Link, Stack, Switch, Text } from '@chakra-ui/react';

import GoTo from 'assets/images/goto.svg';
import Primary from 'assets/images/engage/MdPrimary.svg';
import NotPrimary from 'assets/images/engage/MdNotPrimary.svg';

import Valid from 'assets/images/engage/MdValid.svg';
import ValidDefault from 'assets/images/engage/MdValidDefault.svg';
import Invalid from 'assets/images/engage/MdInvalid.svg';
import InvalidDefault from 'assets/images/engage/MdInvalidDefault.svg';

import toUrl from 'utils/toUrl';
import { useDetailsServices } from './useDetailsServices';

const CandidateDetails = () => {
  const {
    isLoading,
    sequenceCandidateData,
    handlePrimaryEmail,
    handleValidEmail,
    handlePrimaryPhone,
    handleValidPhone,
  } = useDetailsServices();

  return (
    <Box p="8px 4px" sx={{ filter: isLoading && 'blur(2px)' }}>
      <Text sx={{ mb: '24px', fontSize: '18px', fontWeight: 700 }}>
        Personal Information
      </Text>
      <Stack spacing="16px" mb="66px">
        <Flex gap="80px">
          <Text
            sx={{
              width: '100px',
              color: '#9B9B9B',
              fontWeight: 700,
            }}
          >
            State
          </Text>
          <Text sx={{ fontWeight: 700, color: '#2B2D42' }}>
            {sequenceCandidateData?.state_province}
          </Text>
        </Flex>
        <Flex gap="80px">
          <Text
            sx={{
              width: '100px',
              color: '#9B9B9B',
              fontWeight: 700,
            }}
          >
            City
          </Text>
          <Text sx={{ fontWeight: 700, color: '#2B2D42' }}>
            {sequenceCandidateData?.city}
          </Text>
        </Flex>
        <Flex gap="80px">
          <Text
            sx={{
              width: '100px',
              color: '#9B9B9B',
              fontWeight: 700,
            }}
          >
            Country
          </Text>
          <Text sx={{ fontWeight: 700, color: '#2B2D42' }}>
            {sequenceCandidateData?.country}
          </Text>
        </Flex>
        <Flex gap="80px">
          <Text
            sx={{
              width: '100px',
              color: '#9B9B9B',
              fontWeight: 700,
            }}
          >
            Linkedin Link
          </Text>
          <Link
            href={toUrl(sequenceCandidateData?.linkedin_url)}
            target="_blank"
            style={{ display: 'flex', gap: '7px' }}
            rel="noreferrer"
            fontWeight={700}
          >
            {sequenceCandidateData?.linkedin_url}
            {sequenceCandidateData?.linkedin_url && (
              <span>
                <Image src={GoTo} alt="logo" />
              </span>
            )}
          </Link>
        </Flex>
      </Stack>

      <Stack spacing="24px">
        <Text sx={{ fontSize: '18px', fontWeight: 700 }}>
          Contact Information
        </Text>

        {(sequenceCandidateData?.emails?.filter(
          (val: any) => val.category === 'personal'
        ).length > 0 ||
          sequenceCandidateData?.contact?.filter(
            (val: any) => val.category === 'personal'
          ).length > 0) && (
          <Stack>
            <Box
              sx={{
                bgColor: '#E7E9ED',
                border: '1px solid #EEEEEE',
                borderRadius: '5px 5px 0 0',
                p: '14px',
                color: '#2B2D42',
              }}
            >
              Personal
            </Box>
            <Box
              sx={{
                border: '1px solid #EEEEEE',
                borderTop: 'none',
                borderRadius: ' 0 0 5px 5px',
              }}
            >
              {sequenceCandidateData?.emails?.filter(
                (val: any) => val.category === 'personal'
              ).length > 0 && (
                <Box sx={{ p: '12px 14px', borderBottom: '1px solid #EEEEEE' }}>
                  <Stack spacing="16px">
                    {sequenceCandidateData?.emails
                      ?.filter((val: any) => val.category === 'personal')
                      .map((val: any) => {
                        return (
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Flex gap="16px" alignItems="center">
                              <Switch
                                colorScheme="purple"
                                value={val.primary === true ? 'true' : 'false'}
                                isChecked={val.primary}
                                onChange={(e) => handlePrimaryEmail({ e, val })}
                              />
                              <Text>{val.email}</Text>
                              {val.primary ? (
                                <Image
                                  src={Primary}
                                  alt="ON"
                                  cursor="pointer"
                                />
                              ) : (
                                <Image
                                  src={NotPrimary}
                                  alt="ON"
                                  cursor="pointer"
                                />
                              )}
                            </Flex>
                            <Flex>
                              {val.valid === true ? (
                                <Flex gap="10px">
                                  <Image
                                    height="24px"
                                    width="24px"
                                    src={Valid}
                                    alt="Valid"
                                    cursor="pointer"
                                  />
                                  <Image
                                    height="24px"
                                    width="24px"
                                    src={InvalidDefault}
                                    alt="Invalid"
                                    cursor="pointer"
                                    onClick={() =>
                                      handleValidEmail({ val, valid: 0 })
                                    }
                                  />
                                </Flex>
                              ) : val.valid === false ? (
                                <Flex gap="10px">
                                  <Image
                                    height="24px"
                                    width="24px"
                                    src={ValidDefault}
                                    alt="Valid"
                                    cursor="pointer"
                                    onClick={() =>
                                      handleValidEmail({ val, valid: 1 })
                                    }
                                  />
                                  <Image
                                    height="24px"
                                    width="24px"
                                    src={Invalid}
                                    alt="Invalid"
                                    cursor="pointer"
                                  />
                                </Flex>
                              ) : (
                                <Flex gap="10px">
                                  <Image
                                    height="24px"
                                    width="24px"
                                    src={ValidDefault}
                                    alt="Valid"
                                    cursor="pointer"
                                    onClick={() =>
                                      handleValidEmail({ val, valid: 1 })
                                    }
                                  />
                                  <Image
                                    height="24px"
                                    width="24px"
                                    src={InvalidDefault}
                                    alt="Invalid"
                                    cursor="pointer"
                                    onClick={() =>
                                      handleValidEmail({ val, valid: 0 })
                                    }
                                  />
                                </Flex>
                              )}
                            </Flex>
                          </Flex>
                        );
                      })}
                  </Stack>
                </Box>
              )}
              {sequenceCandidateData?.contact?.filter(
                (val: any) => val.category === 'personal'
              ).length > 0 && (
                <Box sx={{ p: '12px 14px' }}>
                  <Stack spacing="16px">
                    {sequenceCandidateData?.contact
                      ?.filter((val: any) => val.category === 'personal')
                      .map((val: any) => {
                        return (
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Flex gap="16px" alignItems="center">
                              <Switch
                                colorScheme="purple"
                                value={val.primary === true ? 'true' : 'false'}
                                defaultChecked={val.primary}
                                isChecked={val.primary}
                                onChange={(e) => handlePrimaryPhone({ e, val })}
                              />
                              <Text>{val.number}</Text>
                              {val.primary ? (
                                <Image
                                  src={Primary}
                                  alt="ON"
                                  cursor="pointer"
                                />
                              ) : (
                                <Image
                                  src={NotPrimary}
                                  alt="ON"
                                  cursor="pointer"
                                />
                              )}
                            </Flex>
                            <Flex>
                              {val.valid === true ? (
                                <Flex gap="10px">
                                  <Image
                                    height="24px"
                                    width="24px"
                                    src={Valid}
                                    alt="Valid"
                                    cursor="pointer"
                                  />
                                  <Image
                                    height="24px"
                                    width="24px"
                                    src={InvalidDefault}
                                    alt="Invalid"
                                    cursor="pointer"
                                    onClick={() =>
                                      handleValidPhone({ val, valid: 0 })
                                    }
                                  />
                                </Flex>
                              ) : val.valid === false ? (
                                <Flex gap="10px">
                                  <Image
                                    height="24px"
                                    width="24px"
                                    src={ValidDefault}
                                    alt="Valid"
                                    cursor="pointer"
                                    onClick={() =>
                                      handleValidPhone({ val, valid: 1 })
                                    }
                                  />
                                  <Image
                                    height="24px"
                                    width="24px"
                                    src={Invalid}
                                    alt="Invalid"
                                    cursor="pointer"
                                  />
                                </Flex>
                              ) : (
                                <Flex gap="10px">
                                  <Image
                                    height="24px"
                                    width="24px"
                                    src={ValidDefault}
                                    alt="Valid"
                                    cursor="pointer"
                                    onClick={() =>
                                      handleValidPhone({ val, valid: 1 })
                                    }
                                  />
                                  <Image
                                    height="24px"
                                    width="24px"
                                    src={InvalidDefault}
                                    alt="Invalid"
                                    cursor="pointer"
                                    onClick={() =>
                                      handleValidPhone({ val, valid: 0 })
                                    }
                                  />
                                </Flex>
                              )}
                            </Flex>
                          </Flex>
                        );
                      })}
                  </Stack>
                </Box>
              )}
            </Box>
          </Stack>
        )}

        {(sequenceCandidateData?.emails?.filter(
          (val: any) => val.category === 'work'
        ).length > 0 ||
          sequenceCandidateData?.contact?.filter(
            (val: any) => val.category === 'work'
          ).length > 0) && (
          <Stack>
            <Box
              sx={{
                bgColor: '#E7E9ED',
                border: '1px solid #EEEEEE',
                borderRadius: '5px 5px 0 0',
                p: '14px',
                color: '#2B2D42',
              }}
            >
              Work
            </Box>
            <Box
              sx={{
                border: '1px solid #EEEEEE',
                borderTop: 'none',
                borderRadius: ' 0 0 5px 5px',
              }}
            >
              {sequenceCandidateData?.emails?.filter(
                (val: any) => val.category === 'work'
              ).length > 0 && (
                <Box sx={{ p: '12px 14px', borderBottom: '1px solid #EEEEEE' }}>
                  <Stack spacing="16px">
                    {sequenceCandidateData?.emails
                      ?.filter((val: any) => val.category === 'work')
                      .map((val: any) => {
                        return (
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Flex gap="16px" alignItems="center">
                              <Switch
                                colorScheme="purple"
                                value={val.primary === true ? 'true' : 'false'}
                                defaultChecked={val.primary}
                                isChecked={val.primary}
                                onChange={(e) => handlePrimaryEmail({ e, val })}
                              />
                              <Text>{val.email}</Text>
                              {val.primary ? (
                                <Image
                                  src={Primary}
                                  alt="ON"
                                  cursor="pointer"
                                />
                              ) : (
                                <Image
                                  src={NotPrimary}
                                  alt="ON"
                                  cursor="pointer"
                                />
                              )}
                            </Flex>
                            <Flex>
                              {val.valid === true ? (
                                <Flex gap="10px">
                                  <Image
                                    height="24px"
                                    width="24px"
                                    src={Valid}
                                    alt="Valid"
                                    cursor="pointer"
                                  />
                                  <Image
                                    height="24px"
                                    width="24px"
                                    src={InvalidDefault}
                                    alt="Invalid"
                                    cursor="pointer"
                                    onClick={() =>
                                      handleValidEmail({ val, valid: 0 })
                                    }
                                  />
                                </Flex>
                              ) : val.valid === false ? (
                                <Flex gap="10px">
                                  <Image
                                    height="24px"
                                    width="24px"
                                    src={ValidDefault}
                                    alt="Valid"
                                    cursor="pointer"
                                    onClick={() =>
                                      handleValidEmail({ val, valid: 1 })
                                    }
                                  />
                                  <Image
                                    height="24px"
                                    width="24px"
                                    src={Invalid}
                                    alt="Invalid"
                                    cursor="pointer"
                                  />
                                </Flex>
                              ) : (
                                <Flex gap="10px">
                                  <Image
                                    height="24px"
                                    width="24px"
                                    src={ValidDefault}
                                    alt="Valid"
                                    cursor="pointer"
                                    onClick={() =>
                                      handleValidEmail({ val, valid: 1 })
                                    }
                                  />
                                  <Image
                                    height="24px"
                                    width="24px"
                                    src={InvalidDefault}
                                    alt="Invalid"
                                    cursor="pointer"
                                    onClick={() =>
                                      handleValidEmail({ val, valid: 0 })
                                    }
                                  />
                                </Flex>
                              )}
                            </Flex>
                          </Flex>
                        );
                      })}
                  </Stack>
                </Box>
              )}
              {sequenceCandidateData?.contact?.filter(
                (val: any) => val.category === 'work'
              ).length > 0 && (
                <Box sx={{ p: '12px 14px' }}>
                  <Stack spacing="16px">
                    {sequenceCandidateData?.contact
                      ?.filter((val: any) => val.category === 'work')
                      .map((val: any) => {
                        return (
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Flex gap="16px" alignItems="center">
                              <Switch
                                colorScheme="purple"
                                value={val.primary === true ? 'true' : 'false'}
                                defaultChecked={val.primary}
                                isChecked={val.primary}
                                onChange={(e) => handlePrimaryPhone({ e, val })}
                              />
                              <Text>{val.number}</Text>
                              {val.primary ? (
                                <Image
                                  src={Primary}
                                  alt="ON"
                                  cursor="pointer"
                                />
                              ) : (
                                <Image
                                  src={NotPrimary}
                                  alt="ON"
                                  cursor="pointer"
                                />
                              )}
                            </Flex>
                            <Flex>
                              {val.valid === true ? (
                                <Flex gap="10px">
                                  <Image
                                    height="24px"
                                    width="24px"
                                    src={Valid}
                                    alt="Valid"
                                    cursor="pointer"
                                  />
                                  <Image
                                    height="24px"
                                    width="24px"
                                    src={InvalidDefault}
                                    alt="Invalid"
                                    cursor="pointer"
                                    onClick={() =>
                                      handleValidPhone({ val, valid: 0 })
                                    }
                                  />
                                </Flex>
                              ) : val.valid === false ? (
                                <Flex gap="10px">
                                  <Image
                                    height="24px"
                                    width="24px"
                                    src={ValidDefault}
                                    alt="Valid"
                                    cursor="pointer"
                                    onClick={() =>
                                      handleValidPhone({ val, valid: 1 })
                                    }
                                  />
                                  <Image
                                    height="24px"
                                    width="24px"
                                    src={Invalid}
                                    alt="Invalid"
                                    cursor="pointer"
                                  />
                                </Flex>
                              ) : (
                                <Flex gap="10px">
                                  <Image
                                    height="24px"
                                    width="24px"
                                    src={ValidDefault}
                                    alt="Valid"
                                    cursor="pointer"
                                    onClick={() =>
                                      handleValidPhone({ val, valid: 1 })
                                    }
                                  />
                                  <Image
                                    height="24px"
                                    width="24px"
                                    src={InvalidDefault}
                                    alt="Invalid"
                                    cursor="pointer"
                                    onClick={() =>
                                      handleValidPhone({ val, valid: 0 })
                                    }
                                  />
                                </Flex>
                              )}
                            </Flex>
                          </Flex>
                        );
                      })}
                  </Stack>
                </Box>
              )}
            </Box>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default CandidateDetails;
