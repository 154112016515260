import { Box, Flex, Skeleton } from '@chakra-ui/react';

const LoadingEditCandidate = () => {
  return (
    <>
      <Flex gap="32px" mb="34px">
        <Box width="50%">
          <Skeleton height="35px" />
        </Box>
        <Box width="50%">
          <Skeleton height="35px" />
        </Box>
      </Flex>
      <Flex gap="32px" mb="34px">
        <Box width="50%">
          <Skeleton height="35px" />
        </Box>
        <Box width="50%">
          <Skeleton height="35px" />
        </Box>
      </Flex>
      <Flex gap="32px" mb="34px">
        <Box width="50%">
          <Skeleton height="35px" />
        </Box>
        <Box width="50%">
          <Skeleton height="35px" />
        </Box>
      </Flex>
      <Flex gap="32px" mb="34px">
        <Box width="50%">
          <Skeleton height="35px" />
        </Box>
        <Box width="50%">
          <Skeleton height="35px" />
        </Box>
      </Flex>
      <Flex gap="32px" mb="34px">
        <Box width="50%">
          <Skeleton height="35px" />
        </Box>
        <Box width="50%">
          <Skeleton height="35px" />
        </Box>
      </Flex>
      <Flex gap="32px" mb="34px">
        <Box w="100%">
          <Skeleton height="35px" />
        </Box>
      </Flex>
    </>
  );
};
export default LoadingEditCandidate;
