import {
  Box,
  Flex,
  GridItem,
  Image,
  Link,
  SimpleGrid,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import toUrl from 'utils/toUrl';

import GoTo from '../../../../assets/images/goto.svg';
import ContactEmail from './Modal/Email';

const ClientsContactsOverview = () => {
  const { contactData } = useSelector((state: any) => state.contacts);
  const {
    isOpen: isOpenEmail,
    onOpen: onOpenEmail,
    onClose: onCloseEmail,
  } = useDisclosure();
  return (
    <Box fontFamily="NunitoSans Regular" py={4}>
      <SimpleGrid
        columns={2}
        margin="28px 0"
        fontWeight={500}
        fontSize="16px"
        lineHeight="18px"
      >
        <GridItem>
          <Box>
            <SimpleGrid
              columns={2}
              w="80%"
              margin="28px 0"
              marginTop="0"
              fontWeight={500}
              fontSize="16px"
              lineHeight="18px"
            >
              <GridItem color="default.gray.600">Mail</GridItem>
              <GridItem color="primary.800">
                <Link
                  onClick={() => onOpenEmail()}
                  style={{ display: 'flex', gap: '7px' }}
                  rel="noreferrer"
                >
                  {contactData?.primary_email}
                </Link>
              </GridItem>
            </SimpleGrid>
            <SimpleGrid
              columns={2}
              w="80%"
              margin="28px 0"
              fontWeight={500}
              fontSize="16px"
              lineHeight="18px"
            >
              <GridItem color="default.gray.600">Work Phone</GridItem>
              <GridItem>
                <Link href={`tel:${contactData?.work_phone}`}>
                  {contactData?.extension && contactData?.extension !== '' ? (
                    <span>
                      {contactData?.work_phone}, Ext. {contactData?.extension}
                    </span>
                  ) : (
                    <span>{contactData?.work_phone}</span>
                  )}
                </Link>
              </GridItem>
            </SimpleGrid>
            <SimpleGrid
              columns={2}
              w="80%"
              margin="28px 0"
              fontWeight={500}
              fontSize="16px"
              lineHeight="18px"
            >
              <GridItem color="default.gray.600">Personal Phone</GridItem>
              <GridItem>
                <Link href={`tel:${contactData?.personal_phone}`}>
                  {contactData?.personal_phone}
                </Link>
              </GridItem>
            </SimpleGrid>
            <SimpleGrid
              columns={2}
              w="80%"
              margin="28px 0"
              fontWeight={500}
              fontSize="16px"
              lineHeight="18px"
            >
              <GridItem color="default.gray.600">LinkedIn Link</GridItem>
              <GridItem>
                <Link
                  href={toUrl(contactData?.linked_in_url)}
                  target="_blank"
                  style={{ display: 'flex', gap: '7px' }}
                  rel="noreferrer"
                >
                  {contactData?.linked_in_url}
                  <span>
                    <Image src={GoTo} alt="logo" />
                  </span>
                </Link>
              </GridItem>
            </SimpleGrid>
            <SimpleGrid
              columns={2}
              w="80%"
              margin="28px 0"
              fontWeight={500}
              fontSize="16px"
              lineHeight="18px"
            >
              <GridItem color="default.gray.600">Created By</GridItem>
              <GridItem>
                {[
                  contactData?.user?.first_name,
                  contactData?.user?.last_name,
                ].join(' ')}
              </GridItem>
            </SimpleGrid>
            <SimpleGrid
              columns={2}
              w="80%"
              margin="28px 0"
              fontWeight={500}
              fontSize="16px"
              lineHeight="18px"
            >
              <GridItem color="default.gray.600">Source</GridItem>
              <GridItem>{contactData?.lead_source}</GridItem>
            </SimpleGrid>
            <SimpleGrid
              columns={2}
              w="80%"
              margin="28px 0"
              fontWeight={500}
              fontSize="16px"
              lineHeight="18px"
            >
              <GridItem color="default.gray.600">Last Activity</GridItem>
              <GridItem></GridItem>
            </SimpleGrid>
            <SimpleGrid
              columns={2}
              w="80%"
              margin="28px 0"
              fontWeight={500}
              fontSize="16px"
              lineHeight="18px"
            >
              <GridItem color="default.gray.600">Last Activity Date</GridItem>
              <GridItem></GridItem>
            </SimpleGrid>
            <Box py={2} fontSize="18px" fontWeight="bold" mb="16px">
              Client Details
            </Box>
            <SimpleGrid
              columns={2}
              w="80%"
              marginBottom="28px"
              fontWeight={500}
              fontSize="16px"
              lineHeight="18px"
            >
              <GridItem color="default.gray.600">Account Manager</GridItem>
              <GridItem>
                {[
                  contactData?.client?.manager?.[0]?.user?.first_name,
                  contactData?.client?.manager?.[0]?.user?.last_name,
                ].join(' ')}
              </GridItem>
            </SimpleGrid>
          </Box>
        </GridItem>
        <GridItem>
          <Flex gap="18px" alignItems="center" textAlign="center" mb="18px">
            <Box
              pt="20px"
              pb="14px"
              width="516px"
              border="0.5px solid rgba(197, 197, 197, 0.8)"
              borderRadius="4px"
            >
              <Box
                fontWeight={700}
                fontSize="20px"
                lineHeight="18px"
                marginBottom="8px"
              >
                0
              </Box>
              <Box
                fontWeight={500}
                fontSize="14px"
                lineHeight="18px"
                color="#6B6C7B"
              >
                Open Jobs
              </Box>
            </Box>
          </Flex>
          <Flex gap="18px" alignItems="center" textAlign="center" mb="18px">
            <Box
              pt="20px"
              pb="14px"
              width="249px"
              border="0.5px solid rgba(197, 197, 197, 0.8)"
              borderRadius="4px"
            >
              <Box
                fontWeight={700}
                fontSize="20px"
                lineHeight="18px"
                marginBottom="8px"
              >
                0
              </Box>
              <Box
                fontWeight={500}
                fontSize="14px"
                lineHeight="18px"
                color="#6B6C7B"
              >
                Submits
              </Box>
            </Box>
            <Box
              pt="20px"
              pb="14px"
              width="249px"
              border="0.5px solid rgba(197, 197, 197, 0.8)"
              borderRadius="4px"
            >
              <Box
                fontWeight={700}
                fontSize="20px"
                lineHeight="18px"
                marginBottom="8px"
              >
                0
              </Box>
              <Box
                fontWeight={500}
                fontSize="14px"
                lineHeight="18px"
                color="#6B6C7B"
              >
                Interviews
              </Box>
            </Box>
          </Flex>
          <Flex gap="18px" alignItems="center" textAlign="center">
            <Box
              pt="20px"
              pb="14px"
              width="249px"
              border="0.5px solid rgba(197, 197, 197, 0.8)"
              borderRadius="4px"
            >
              <Box
                fontWeight={700}
                fontSize="20px"
                lineHeight="18px"
                marginBottom="8px"
              >
                0
              </Box>
              <Box
                fontWeight={500}
                fontSize="14px"
                lineHeight="18px"
                color="#6B6C7B"
              >
                Offers
              </Box>
            </Box>
            <Box
              pt="20px"
              pb="14px"
              width="249px"
              border="0.5px solid rgba(197, 197, 197, 0.8)"
              borderRadius="4px"
            >
              <Box
                fontWeight={700}
                fontSize="20px"
                lineHeight="18px"
                marginBottom="8px"
              >
                0
              </Box>
              <Box
                fontWeight={500}
                fontSize="14px"
                lineHeight="18px"
                color="#6B6C7B"
              >
                Placements
              </Box>
            </Box>
          </Flex>
        </GridItem>
      </SimpleGrid>
      {isOpenEmail && (
        <ContactEmail
          isOpen={isOpenEmail}
          onClose={onCloseEmail}
          id={contactData.id}
          email={contactData?.primary_email || ''}
        />
      )}
    </Box>
  );
};

export default ClientsContactsOverview;
