import { Box, Flex, MenuItem } from '@chakra-ui/react';
import FAIcon from 'components/lib/FAIcon';
import { useEffect, useState } from 'react';

interface MenuItemInterface {
  type: string;
  nav?: string;
  id: any;
  openTags: (event: any) => void;
  addToCapaign?: (event: any) => void;
  addToScreen?: (event: any) => void;
  submitToClients?: (event: any) => void;
  scheduleInterview?: (event: any) => void;
  sendEmail?: (event: any) => void;
  changeStatus?: (event: any) => void;
  hide?: (event: any) => void;
  reject?: (event: any) => void;
  offer?: (event: any) => void;
  hired?: (event: any) => void;
  moveToHired?: (event: any) => void;
  unReject?: (event: any) => void;
  removeFromJob?: (event: any) => void;
  del?: (event: any) => void;
}

const MenuItemComponent = ({
  type,
  nav,
  id,
  openTags,
  addToCapaign,
  addToScreen,
  submitToClients,
  scheduleInterview,
  sendEmail,
  changeStatus,
  hide,
  reject,
  offer,
  moveToHired,
  unReject,
  removeFromJob,
  del,
}: MenuItemInterface) => {
  const staticStructure: any = {
    search: [
      {
        label: 'Submit to Client',
        icon: 'clipboard',
        click: submitToClients,
      },
      {
        label: 'Add to Screen',
        icon: 'plus',
        click: addToScreen,
      },
      {
        label: 'Add Tags',
        icon: 'tags',
        click: openTags,
      },
      {
        label: 'Hide',
        icon: 'eye-slash',
        click: hide,
      },
      {
        label: 'Delete',
        icon: 'trash',
        click: del,
      },
    ],
  };

  const inProgressStructure: any = {
    screen: [
      {
        label: 'Send Email',
        icon: 'envelope',
        click: sendEmail,
      },
      {
        label: 'Add to Campaign',
        icon: 'plus',
        click: addToCapaign,
      },
      {
        label: 'Submit to Client',
        icon: 'clipboard',
        click: submitToClients,
      },
      {
        label: 'Change Status',
        icon: 'person-circle-check',
        click: changeStatus,
      },
      {
        label: 'Add Tags',
        icon: 'tags',
        click: openTags,
      },
      {
        label: 'Remove from Job',
        icon: 'ban',
        click: removeFromJob,
      },
    ],
    blasted: [
      {
        label: 'Send Email',
        icon: 'envelope',
        click: sendEmail,
      },
      {
        label: 'Add to Campaign',
        icon: 'plus',
        click: addToCapaign,
      },
      {
        label: 'Submit to Client',
        icon: 'clipboard',
        click: submitToClients,
      },
      {
        label: 'Change Status',
        icon: 'person-circle-check',
        click: changeStatus,
      },
      {
        label: 'Add Tags',
        icon: 'tags',
        click: openTags,
      },
      {
        label: 'Remove from Job',
        icon: 'ban',
        click: removeFromJob,
      },
    ],
    hired: [
      {
        label: 'Send Email',
        icon: 'envelope',
        click: sendEmail,
      },
      {
        label: 'Change Status',
        icon: 'person-circle-check',
        click: changeStatus,
      },
      {
        label: 'Add Tags',
        icon: 'tags',
        click: openTags,
      },
      {
        label: 'Reject',
        icon: 'circle-xmark',
        click: reject,
      },
    ],
    rejected: [
      {
        label: 'Send Email',
        icon: 'envelope',
        click: sendEmail,
      },
      {
        label: 'Add Tags',
        icon: 'tags',
        click: openTags,
      },
      {
        label: 'Un - Reject',
        icon: 'rotate-left',
        click: unReject,
      },
    ],
    submits: [
      {
        label: 'Schedule Interview',
        icon: 'video',
        click: scheduleInterview,
      },
      {
        label: 'Send Email',
        icon: 'envelope',
        click: sendEmail,
      },
      {
        label: 'Change Status',
        icon: 'person-circle-check',
        click: changeStatus,
      },
      {
        label: 'Add Tags',
        icon: 'tags',
        click: openTags,
      },
      {
        label: 'Reject',
        icon: 'circle-xmark',
        click: reject,
      },
    ],
    interviews: [
      {
        label: 'Move to Offer',
        icon: 'file',
        click: offer,
      },
      {
        label: 'Send Email',
        icon: 'envelope',
        click: sendEmail,
      },
      {
        label: 'Change Status',
        icon: 'person-circle-check',
        click: changeStatus,
      },
      {
        label: 'Add Tags',
        icon: 'tags',
        click: openTags,
      },
      {
        label: 'Reject',
        icon: 'circle-xmark',
        click: reject,
      },
    ],
  };

  const inprogressUndefined: any = [
    {
      label: 'Move To Hired',
      icon: 'briefcase',
      click: moveToHired,
    },
    {
      label: 'Send Email',
      icon: 'envelope',
      click: sendEmail,
    },
    {
      label: 'Change Status',
      icon: 'person-circle-check',
      click: changeStatus,
    },
    {
      label: 'Add Tags',
      icon: 'tags',
      click: openTags,
    },
    {
      label: 'Reject',
      icon: 'circle-xmark',
      click: reject,
    },
  ];

  const [structure, setStructure] = useState([]);

  useEffect(() => {
    if (type === 'in-progress') {
      if (inProgressStructure[nav] !== undefined) {
        setStructure(inProgressStructure[nav]);
      } else {
        setStructure(inprogressUndefined);
      }
    } else {
      setStructure(staticStructure[type]);
    }
  }, [type, nav]);

  return (
    <>
      {structure?.length > 0 &&
        structure.map((btn: any, key: number) => {
          return (
            <MenuItem onClick={btn.click} id={id} key={`menu${id}-${key}`}>
              <Flex alignItems="center" justifyContent="space-between">
                <FAIcon iconName={btn.icon} />
                <Box ml={12} cursor="pointer">
                  {btn.label}
                </Box>
              </Flex>
            </MenuItem>
          );
        })}
    </>
  );
};

export default MenuItemComponent;
