import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

export const useInboxesServices = () => {
  const [sidebarIndex, setSidebarIndex] = useState(0);

  const params = useParams();

  const SideBarItem = [
    {
      id: 0,
      label: 'Emails',
      to: 'emails',
    },
    {
      id: 1,
      label: 'SMS',
      to: 'sms',
    },
    {
      id: 2,
      label: 'Phone Calls',
      to: 'phone-calls',
    },
    {
      id: 3,
      label: 'General Task',
      to: 'general-task',
    },
    {
      id: 4,
      label: 'Calendar',
      to: 'calendar',
    },
  ];

  useEffect(() => {
    if (params.settingTab) {
      const item = SideBarItem.filter((val) => params.settingTab === val.to)[0];
      setSidebarIndex(item?.id);
    }
  }, [params.settingTab]);

  return { sidebarIndex, setSidebarIndex };
};
