import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from './api.slice';
import { appError } from './app.slice';
import { listCandidates, candidatesInitialState } from './candidates.slice';

export const candidateFiltersSlice = createSlice({
  name: 'candidateFilters',
  initialState: {
    candidate_filters: [],
    candidate_pin_filters: [],
    filter_data: [],
    filter: null,
    params: '',
    filter_count: 0,
    group: false,
  },
  reducers: {
    getFilters: (state, { payload }) => ({ ...state, ...payload }),
    listFilters: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { getFilters, listFilters } = candidateFiltersSlice.actions;
export default candidateFiltersSlice.reducer;

/* ----------------------------------- API ---------------------------------- */

export const candidateFiltersApiSplice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    CreateCandidateFilter: builder.mutation({
      query: (data) => ({
        url: `/candidate-filters/create`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            listCandidates({
              candidateOthers: [
                ...candidatesInitialState.candidateOthers,
                { group_by_job: data.data.group_by_job },
              ],
              candidatePgBtn: {
                ...candidatesInitialState.candidatePgBtn,
                filter: data.data.id,
              },
            })
          );
          localStorage.setItem(
            'candidatesFilter',
            JSON.stringify({
              ...candidatesInitialState.candidatePgBtn,
              filter: data.data.id,
            })
          );
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),

    UpdateCandidateFilter: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidate-filters/update/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            listCandidates({
              candidateOthers: [
                ...candidatesInitialState.candidateOthers,
                { group_by_job: data.data.group_by_job },
              ],
              candidatePgBtn: {
                ...candidatesInitialState.candidatePgBtn,
                filter: data.data.id,
              },
            })
          );
          localStorage.setItem(
            'candidatesFilter',
            JSON.stringify({
              ...candidatesInitialState.candidatePgBtn,
              filter: data.data.id,
            })
          );
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),

    ListCandidateFilter: builder.mutation({
      query: (data) => ({
        url: `/candidate-filters/list`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listFilters({ candidate_filters: data.data }));
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),
    PinListCandidateFilter: builder.mutation({
      query: ({ data, signal }) => ({
        url: `/candidate-filters/pin-list`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
        signal,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // console.log("data ", data.data)
          dispatch(listFilters({ candidate_pin_filters: data.data }));
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),
    DelCandidateFilter: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidate-filters/delete/${id}`,
        method: 'DELETE',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),
    PinCandidateFilter: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidate-filters/pin/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),
    UnPinCandidateFilter: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidate-filters/unpin/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),
    ChangeFilterOrder: builder.mutation({
      query: ({ data }) => ({
        url: `/candidate-filters/change-order/`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  useCreateCandidateFilterMutation,
  useListCandidateFilterMutation,
  useUpdateCandidateFilterMutation,
  useDelCandidateFilterMutation,
  usePinCandidateFilterMutation,
  useUnPinCandidateFilterMutation,
  useChangeFilterOrderMutation,
  usePinListCandidateFilterMutation,
} = candidateFiltersApiSplice;
