const viewCandidateConfig = (candidatePgBtn: any) => {
  let allCountParam = {
    ...candidatePgBtn,
    job_status: null,
    status: [],
    query: '',
    search: '',
  };

  let blastedCountParam = {
    ...candidatePgBtn,
    job_status: null,
    status: [8],
    query: '',
    search: '',
    filter: null,
  };
  let screenCountParam = {
    ...candidatePgBtn,
    job_status: null,
    status: [2],
    query: '',
    search: '',
    filter: null,
  };

  let submitCountParam = {
    ...candidatePgBtn,
    job_status: null,
    status: [1],
    query: '',
    search: '',
    filter: null,
  };

  let interviewCountParam = {
    ...candidatePgBtn,
    job_status: null,
    status: [3],
    query: '',
    search: '',
    filter: null,
  };

  let offersCountParam = {
    ...candidatePgBtn,
    job_status: null,
    status: [4],
    query: '',
    search: '',
    filter: null,
  };

  let hiredCountParam = {
    ...candidatePgBtn,
    job_status: null,
    status: [5],
    query: '',
    search: '',
    filter: null,
  };

  let rejectedCountParam = {
    ...candidatePgBtn,
    job_status: null,
    status: [6],
    query: '',
    search: '',
    filter: null,
  };

  return {
    allCountParam,
    blastedCountParam,
    screenCountParam,
    submitCountParam,
    interviewCountParam,
    offersCountParam,
    hiredCountParam,
    rejectedCountParam,
  };
};

export default viewCandidateConfig;
