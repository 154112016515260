/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import MdError from 'assets/images/engage/MdError1.svg';

interface CancelModalProps {
  isOpen: any;
  onClose: any;
  handleSubmit: any;
  label: string;
  loading?: boolean;
}

export default function CancelModal({
  isOpen,
  onClose,
  handleSubmit,
  label,
  loading,
}: CancelModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={() => {
        onClose();
      }}
      size="lg"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent fontFamily="NunitoSans Regular">
        <ModalBody borderRadius="5px" p="16px 24px">
          <Stack m="16px auto" textAlign="center" w="400px">
            <Image src={MdError} width="100px" height="100px" m="0 auto" />
            <Text sx={{ fontSize: '20px', fontWeight: 600, color: '#2B2D42' }}>
              You are about to{' '}
              <Text as="span" sx={{ color: '#EC7272' }}>
                {label}.
              </Text>
            </Text>
            <Text sx={{ fontSize: '16px', fontWeight: 300, color: '#2B2D42' }}>
              Are you sure you want to continue?
            </Text>
          </Stack>
          <Flex gap="10px" mt="40px">
            <Button
              onClick={() => onClose()}
              w="100%"
              sx={{
                borderColor: '#EC7272',
                color: '#EC7272',
                fontSize: '14px',
              }}
              _hover={{
                borderColor: '#EC7272',
                color: '#EC7272',
              }}
            >
              Cancel
            </Button>
            <Button
              variant="solid"
              onClick={handleSubmit}
              w="100%"
              sx={{
                borderColor: '#EC7272',
                bgColor: '#EC7272',
                fontSize: '14px',
              }}
              _hover={{
                borderColor: '#EC7272',
                bgColor: '#EC7272',
              }}
              isLoading={loading}
            >
              Yes
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
