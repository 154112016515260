import { useEffect, useState } from "react"
import { DrawerTeamsServicesProps, MembersDataInt, UserDataInt } from "./types"

export default function useDrawerTeamsServices({ values }: DrawerTeamsServicesProps) {
    const [manager, setManager] = useState<UserDataInt>({} as UserDataInt)
    const [members, setMembers] = useState<MembersDataInt[]>(values.members)

    useEffect(() => {
        const filteredManager = values.members.filter((member: MembersDataInt) => member.role === "manager")[0]?.user
        setManager(filteredManager)

        const filteredMembers = values.members.filter((member: MembersDataInt) => member.role === "member")
        setMembers(filteredMembers)
    }, [])



    return {
        manager,
        members
    }
}