import { useEffect, useState } from 'react';
import { Stack } from '@chakra-ui/react';
import List from './List';
import { status } from './constants';

export const CheckboxLayout = ({ data, setStatusPayload, counts }: any) => {
  const [items, setItems]: any = useState(data);

  const setStatus = (root: any, status: any) => {
    root.status = status;
    if (Array.isArray(root.items)) {
      return root.items.forEach((item: any) => {
        setStatus(item, status);
      });
    }
  };

  const computeStatus = (items: any) => {
    let checked = 0;
    let indeterminate = 0;

    items.forEach((item: any) => {
      if (item.status && item.status === status.checked) checked++;
      if (item.status && item.status === status.indeterminate) indeterminate++;
    });

    if (checked === items.length) {
      return status.checked;
    } else if (checked > 0 || indeterminate > 0) {
      return status.indeterminate;
    }
  };

  // Depth-first traversal
  const traverse = (root: any, needle: any, status: any) => {
    let id;
    let items;

    if (Array.isArray(root)) {
      items = root;
    } else {
      id = root.id;
      items = root.items;
    }

    // return if needle is found
    // we don't have to compute the status of the items if root.id === needle
    if (id === needle) {
      return setStatus(root, status);
    }

    if (!items) {
      return root;
    } else {
      items.forEach((item: any) => traverse(item, needle, status));
      root.status = computeStatus(items);
    }
  };

  const compute = (checkboxId: any, newStatus: any) => {
    traverse(items, checkboxId, newStatus);
    const newItems = items.slice();
    setItems(newItems);

    let statusPayload: any = [];

    newItems.forEach((item: any) => {
      if (
        item.status === status.checked ||
        item.status === status.indeterminate
      ) {
        statusPayload.push(item.stat_id);
      }

      if (item.items) {
        item.items.forEach((subItem: any) => {
          if (
            subItem.status === status.checked ||
            subItem.status === status.indeterminate
          ) {
            statusPayload.push(subItem.stat_id);
          }
        });
      }
    });

    setStatusPayload(statusPayload);
  };

  return (
    <Stack
      sx={{
        'div:first-child': {
          pl: 0,
        },
      }}
    >
      <List items={items} compute={compute} counts={counts} />
    </Stack>
  );
};
