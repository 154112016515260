import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './api.slice';
import appReducer from './app.slice';
import authReducer from './auth.slice';
import inboxReducer from './inbox.slice';
import leadsReducer from './leads.slice';
import aiReducer from './ai.slice';
import leadattachmentReducer from './leadattachment.slice';
import leadagreementReducer from './leadagreement.sclice';
import leadnotesReducer from './leadnotes.slice';
import userReducer from './user.slice';
import candidatesReducer from './candidates.slice';
import candidateattachmentReducer from './candidateattachment.slice';
import jobsReducer from './jobs.slice';
import jobNoteReducer from './jobnote.slice';
import uploadsReducer from './uploads.slice';
import approvalReducer from './approval.slice';
import clientReducer from './client.slice';
import managerReducer from './manager.slice';
import dashboardReducer from './dashboard.slice';
import contactReducer from './contact.slice';
import constantsReducer from './constant.slice';
import candidateNoteReducer from './candidatenote.slice';
import candidateTagReducer from './candidatetag.slice';
import tagReducer from './tag.slice';
import clientAgreementReducer from './clientagreement.slice';
import msGraphReducer from './msgraph.slice';
import templateReducer from './template.slice';
import templateGroupReducer from './email_group.slice';
import templateAttReducer from './templateattachment.slice';
import signatureReducer from './signature.slice';
import ringCentralReducer from './ringcentral.slice';
import candidateResReducer from './candidateresume.slice';
import candidateInterviewReducer from './candidateinterview.slice';
import resumerParseReducer from './resumerparse.slice';
import geoLocationReducer from './geo.slice';
import skillReducer from './skills.slice';
import candidateFilterReducer from './candidatefilter.slice';
import candidateActivityLogsReducer from './candidateActivity';
import outreachReducer from './outreach.slice';
import teamsReducer from './teams.slice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    inbox: inboxReducer,
    leads: leadsReducer,
    ai: aiReducer,
    leadattachment: leadattachmentReducer,
    leadagreement: leadagreementReducer,
    leadnotes: leadnotesReducer,
    user: userReducer,
    candidates: candidatesReducer,
    candidateattachment: candidateattachmentReducer,
    candidateNotes: candidateNoteReducer,
    candidateTag: candidateTagReducer,
    jobs: jobsReducer,
    jobNotes: jobNoteReducer,
    uploads: uploadsReducer,
    approvals: approvalReducer,
    clients: clientReducer,
    managers: managerReducer,
    dashboard: dashboardReducer,
    contacts: contactReducer,
    constants: constantsReducer,
    tags: tagReducer,
    msgraph: msGraphReducer,
    template: templateReducer,
    teams: teamsReducer,
    signature: signatureReducer,
    clientAgreements: clientAgreementReducer,
    email_group: templateGroupReducer,
    templateAttachment: templateAttReducer,
    ringcentral: ringCentralReducer,
    candidateResume: candidateResReducer,
    candidatesInterview: candidateInterviewReducer,
    resumeParse: resumerParseReducer,
    geolocation: geoLocationReducer,
    skills: skillReducer,
    candidateFilters: candidateFilterReducer,
    candidateActivity: candidateActivityLogsReducer,
    outreach: outreachReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: {
      //   //* Ignore these action types
      //   ignoredActions: ['app/openModal', 'app/openDrawer'],
      //   //* Ignore these field paths in all actions
      //   // ? ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
      //   //* Ignore these paths in the state
      //   ignoredPaths: ['app.modal', 'app.drawer'],
      // },
      serializableCheck: false, //file upload issue need to set to false to ignore serialization
    }).concat(apiSlice.middleware),

  devTools: true,
});
