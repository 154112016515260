import * as Yup from 'yup';

import { Formik } from 'formik';
import { useState, useEffect } from 'react';

import {
    Box,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Icon,
    Input,
    InputGroup,
    InputLeftAddon,
    InputLeftElement,
    Button as ChakraButton
} from '@chakra-ui/react';
import ScrollToFieldError from 'components/app/ScrollError';
import { BsCurrencyDollar } from 'react-icons/bs';
import AtsDropZone from 'components/app/Dropzone';
import { fileUploadedInt } from 'types';

interface initialValueInt {
    total_year_of_experience: number;
    total_months_of_experience: number;
    latest_job_title: string;
    latest_employer: string;
    latest_salary: string;
    expected_salary_start: string;
    expected_salary_end: string;
    attachments: any[];
}

interface CandidateProfessionalProps {
    initialValues: initialValueInt;
    onSubmit: (e: any) => void;
    CandidateModalFooter?: any;
}

export default function CandidateProfessional({
    initialValues,
    onSubmit,
    CandidateModalFooter
}: CandidateProfessionalProps) {
    const [attachments, setAttachments] = useState(initialValues.attachments);

    const uploadedEnd = async (data: any) => {
        setAttachments([...attachments, data]);
    };

    const deleteFile = (key: string) => {
        const items = attachments.filter(
            (file: fileUploadedInt) => {
                return file.id !== key
            }
        );
        setAttachments(items);
    };

    const validationSchema = Yup.lazy(() =>
        Yup.object().shape({
            // professional
            total_year_of_experience: Yup.number().nullable(),
            total_months_of_experience: Yup.number().nullable(),
            latest_job_title: Yup.string(),
            latest_employer: Yup.string(),
            latest_salary: Yup.string().matches(/^[\d,]+$/gi, 'Salary is invalid'),
            expected_salary_start: Yup.string().matches(
                /^[\d,]+$/gi,
                'Salary is invalid'
            ),
            expected_salary_end: Yup.string().matches(
                /^[\d,]+$/gi,
                'Salary is invalid'
            ),
            attachments: Yup.array().of(
                Yup.object().shape({
                    file_name: Yup.string(),
                    file_size: Yup.number(),
                    file_type: Yup.string(),
                    location: Yup.string(),
                    original_file_name: Yup.string(),
                })
            ),
        })
    );

    const professionalSubmit = (data: any) => {
        const param = {
            ...data,
            attachments: attachments,
        }
        onSubmit(param)
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={professionalSubmit}
                enableReinitialize={true}
            >
                {({
                    values,
                    handleChange,
                    handleSubmit,
                    errors,
                    touched,
                    isValid,
                    setFieldValue,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <ScrollToFieldError />
                        {/* Professional Details */}
                        <Box
                            overflowY="scroll"
                            maxHeight="450px"
                            p="30px"
                            background="#fff"
                            borderRadius="8px"
                        >
                            <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                            >
                                Total Experience
                            </FormLabel>
                            <Flex gap="32px" mb="34px">
                                <FormControl
                                    isInvalid={Boolean(
                                        !!errors.total_year_of_experience &&
                                        touched.total_year_of_experience
                                    )}
                                >
                                    <InputGroup>
                                        <InputLeftAddon children="Years" />
                                        <Input
                                            id="total_year_of_experience"
                                            name="total_year_of_experience"
                                            type="text"
                                            min={0}
                                            max={100}
                                            value={values.total_year_of_experience}
                                            onChange={(e: any) => {
                                                const inputValue = e.target.value;
                                                const sanitizedValue =
                                                    inputValue.replace(/[^\d]/g, ''); // Remove non-digit characters
                                                const clampedValue = Math.min(
                                                    100,
                                                    Math.max(null, sanitizedValue)
                                                ); // Clamp value between 0 and 11

                                                handleChange({
                                                    target: {
                                                        name: 'total_year_of_experience',
                                                        value: clampedValue,
                                                    },
                                                });
                                            }}
                                        />
                                    </InputGroup>

                                    <FormErrorMessage>
                                        {String(errors.total_year_of_experience)}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    isInvalid={Boolean(
                                        !!errors.total_months_of_experience &&
                                        touched.total_months_of_experience
                                    )}
                                >
                                    <InputGroup>
                                        <InputLeftAddon children="Months" />
                                        <Input
                                            id="total_months_of_experience"
                                            name="total_months_of_experience"
                                            type="text"
                                            min={0}
                                            max={11}
                                            value={values.total_months_of_experience}
                                            onChange={(e: any) => {
                                                const inputValue = e.target.value;
                                                const sanitizedValue =
                                                    inputValue.replace(/[^\d]/g, ''); // Remove non-digit characters
                                                const clampedValue = Math.min(
                                                    11,
                                                    Math.max(null, sanitizedValue)
                                                ); // Clamp value between 0 and 11

                                                handleChange({
                                                    target: {
                                                        name: 'total_months_of_experience',
                                                        value: clampedValue,
                                                    },
                                                });
                                            }}
                                        />
                                    </InputGroup>

                                    <FormErrorMessage>
                                        {String(errors.total_months_of_experience)}
                                    </FormErrorMessage>
                                </FormControl>
                            </Flex>
                            <Flex gap="32px" mb="34px">
                                <FormControl
                                    isInvalid={Boolean(
                                        !!errors.latest_job_title &&
                                        touched.latest_job_title
                                    )}
                                >
                                    <FormLabel
                                        fontSize="14px"
                                        lineHeight="18px"
                                        color="default.secondarytext"
                                    >
                                        Current / Latest Job Title
                                    </FormLabel>
                                    <Input
                                        id="latest_job_title"
                                        name="latest_job_title"
                                        type="text"
                                        placeholder="Job Title"
                                        variant="outline"
                                        value={values.latest_job_title}
                                        onChange={handleChange}
                                    />
                                    <FormErrorMessage>
                                        {String(errors.latest_job_title)}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    isInvalid={Boolean(
                                        !!errors.latest_employer &&
                                        touched.latest_employer
                                    )}
                                >
                                    <FormLabel
                                        fontSize="14px"
                                        lineHeight="18px"
                                        color="default.secondarytext"
                                    >
                                        Curent / Latest Employer
                                    </FormLabel>
                                    <Input
                                        id="latest_employer"
                                        name="latest_employer"
                                        type="text"
                                        placeholder="Employer"
                                        variant="outline"
                                        value={values.latest_employer}
                                        onChange={handleChange}
                                    />
                                    <FormErrorMessage>
                                        {String(errors.latest_employer)}
                                    </FormErrorMessage>
                                </FormControl>
                            </Flex>
                            <Flex gap="32px" mb="34px">
                                <FormControl
                                    isInvalid={Boolean(
                                        !!errors.latest_salary &&
                                        touched.latest_salary
                                    )}
                                >
                                    <FormLabel
                                        fontSize="14px"
                                        lineHeight="18px"
                                        color="default.secondarytext"
                                    >
                                        Current / Latest Salary
                                    </FormLabel>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            children={
                                                <Icon
                                                    as={BsCurrencyDollar}
                                                    color="gray.500"
                                                />
                                            }
                                        />
                                        <Input
                                            id="latest_salary"
                                            name="latest_salary"
                                            type="text"
                                            placeholder="0.00"
                                            variant="outline"
                                            textAlign="right"
                                            value={values.latest_salary}
                                            onChange={(event: any) => {
                                                const { value } = event.target;

                                                // Remove any existing commas from the input
                                                const sanitizedValue = value.replace(
                                                    /,/g,
                                                    ''
                                                );

                                                // Format the number with commas
                                                const formattedValue =
                                                    Number(
                                                        sanitizedValue
                                                    )?.toLocaleString();

                                                setFieldValue(
                                                    'latest_salary',
                                                    formattedValue
                                                );
                                            }}
                                        />
                                    </InputGroup>
                                    <FormErrorMessage>
                                        {String(errors.latest_salary)}
                                    </FormErrorMessage>
                                </FormControl>
                                <Flex gap="32px" mb="34px"></Flex>
                                <FormControl
                                    isInvalid={Boolean(
                                        !!errors.expected_salary_start &&
                                        touched.expected_salary_start
                                    )}
                                >
                                    <FormLabel
                                        fontSize="14px"
                                        lineHeight="18px"
                                        color="default.secondarytext"
                                    >
                                        Expected Salary (Start)
                                    </FormLabel>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            children={
                                                <Icon
                                                    as={BsCurrencyDollar}
                                                    color="gray.500"
                                                />
                                            }
                                        />
                                        <Input
                                            id="expected_salary_start"
                                            name="expected_salary_start"
                                            type="text"
                                            placeholder="0.00"
                                            variant="outline"
                                            textAlign="right"
                                            value={values.expected_salary_start}
                                            onChange={(event: any) => {
                                                const { value } = event.target;

                                                // Remove any existing commas from the input
                                                const sanitizedValue = value.replace(
                                                    /,/g,
                                                    ''
                                                );

                                                // Format the number with commas
                                                const formattedValue =
                                                    Number(
                                                        sanitizedValue
                                                    )?.toLocaleString();

                                                setFieldValue(
                                                    'expected_salary_start',
                                                    formattedValue
                                                );
                                            }}
                                        />
                                    </InputGroup>
                                    <FormErrorMessage>
                                        {String(errors.expected_salary_start)}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    isInvalid={Boolean(
                                        !!errors.expected_salary_end &&
                                        touched.expected_salary_end
                                    )}
                                >
                                    <FormLabel
                                        fontSize="14px"
                                        lineHeight="18px"
                                        color="default.secondarytext"
                                    >
                                        Expected Salary (End)
                                    </FormLabel>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            children={
                                                <Icon
                                                    as={BsCurrencyDollar}
                                                    color="gray.500"
                                                />
                                            }
                                        />
                                        <Input
                                            id="expected_salary_end"
                                            name="expected_salary_end"
                                            type="text"
                                            placeholder="0.00"
                                            variant="outline"
                                            textAlign="right"
                                            value={values.expected_salary_end}
                                            onChange={(event: any) => {
                                                const { value } = event.target;

                                                // Remove any existing commas from the input
                                                const sanitizedValue = value.replace(
                                                    /,/g,
                                                    ''
                                                );

                                                // Format the number with commas
                                                const formattedValue =
                                                    Number(
                                                        sanitizedValue
                                                    )?.toLocaleString();

                                                setFieldValue(
                                                    'expected_salary_end',
                                                    formattedValue
                                                );
                                            }}
                                        />
                                    </InputGroup>
                                    <FormErrorMessage>
                                        {String(errors.expected_salary_end)}
                                    </FormErrorMessage>
                                </FormControl>
                            </Flex>

                            <Box mb="34px">
                                <FormControl mb="3">
                                    <FormLabel
                                        fontSize="14px"
                                        lineHeight="18px"
                                        color="default.secondarytext"
                                    >
                                        Resume
                                    </FormLabel>
                                </FormControl>
                                {/* <AtsDropZone
                                    multipleFile={true}
                                    isResume
                                    uploadedEnd={(e: any) => { }}
                                    deletedFile={(key: string) => { }}
                                /> */}
                                <AtsDropZone
                                    multipleFile
                                    uploadedEnd={(e: any) => uploadedEnd(e)}
                                    reference={attachments}
                                    hasRef={true}
                                    isResume
                                    deletedFile={(key: string) => deleteFile(key)}
                                />
                            </Box>
                        </Box>
                        {CandidateModalFooter(handleSubmit, errors, isValid, { ...values, attachments: attachments })}
                    </form>
                )}
            </Formik>
        </>
    )
};