import { apiSlice } from 'store/api.slice';
import { ApiResponse } from 'services/type';
import {
  ClientListPayloadType,
  ClientListData,
  ClientLocation,
  ClientSearch,
} from './client.type';

export const clientQuery = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchMyClientList: builder.query<ClientListData, ClientListPayloadType>({
      query: ({ data, signal }) => {
        return {
          url: '/clients/my-list',
          method: 'POST',
          credentials: 'include',
          withCredentials: true,
          crossorigin: true,
          headers: { 'Content-type': 'application/json; charset=UTF-8' },
          body: data,
          signal,
        };
      },
      providesTags: (result, error) =>
        result && error
          ? [
              ...result?.data?.map(
                (data: any) =>
                  ({
                    type: 'My-Client',
                    id: data.id,
                  } as const)
              ),
              { type: 'My-Client', id: 'List' },
            ]
          : [{ type: 'My-Client', id: 'List' }],
      transformResponse: (response: ApiResponse<ClientListData>) => {
        return response.success
          ? response.data
          : {
              count: 0,
              currentPage: null,
              data: [],
              lastPage: null,
              nextPage: null,
              other: null,
              prevPage: null,
              statusCode: '',
            };
      },
    }),
    fetchClientLocations: builder.query<
      ClientLocation[],
      { id: number; signal?: AbortSignal }
    >({
      query: ({ id, signal }) => ({
        url: `/clients/location/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        signal,
      }),
      providesTags: (result, error) =>
        result && error
          ? [
              ...result?.map(
                (data: any) =>
                  ({
                    type: 'Client-Locations',
                    id: data.id,
                  } as const)
              ),
              { type: 'Client-Locations', id: 'List' },
            ]
          : [{ type: 'Client-Locations', id: 'List' }],
      transformResponse: (response: ApiResponse<ClientLocation[]>) => {
        return response.success ? response.data : [];
      },
    }),
    fetchSearchClient: builder.query<
      ClientSearch[],
      { search: string; signal?: AbortSignal }
    >({
      query: ({ search, signal }) => ({
        url: `/clients/search`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        signal,
        body: { search },
      }),
      providesTags: (result, error) =>
        result && error
          ? [
              ...result?.map(
                (data: any) =>
                  ({
                    type: 'Client-Search',
                    id: data.id,
                  } as const)
              ),
              { type: 'Client-Search', id: 'List' },
            ]
          : [{ type: 'Client-Search', id: 'List' }],
      transformResponse: (response: ApiResponse<ClientSearch[]>) => {
        return response.success ? response.data : [];
      },
    }),
  }),
});

export const {
  useFetchMyClientListQuery,
  useFetchClientLocationsQuery,
  useLazyFetchClientLocationsQuery,
  useFetchSearchClientQuery,
} = clientQuery;
