import { Box, Tag, TagLabel } from '@chakra-ui/react';
interface LeadTypeInteface {
  type: string;
}
export default function LeadType({ type }: LeadTypeInteface) {
  const icons: any = [];
  icons['Lead'] = `🔥`;
  icons['Cold'] = `🥶`;
  icons['Contact'] = `💼`;
  icons['Qualified'] = `✅`;
  return (
    <Box gap={4} fontSize="md" fontWeight="regular">
      {icons[type] ?? icons[type]} {type}
    </Box>
  );
}
