import { useEffect, useState } from 'react';
import { MembersDataInt, TeamTrProps } from './types';

export default function useTableRowTeamsServices({ team, index }: TeamTrProps) {

    // INITIAL VALUES
    const [manager, setManager] = useState<MembersDataInt>({} as MembersDataInt);

    useEffect(() => {
        if (Object.keys(team).length > 0) {
            setManager(
                team?.members.filter(
                    (member: MembersDataInt) => member.user_id === team?.manager_id
                )[0]
            )
        }
    }, [team])
    return {
        manager,
    }
}