/* eslint-disable react-hooks/exhaustive-deps */
import {
  Tr,
  Td,
  Table,
  Tbody,
  Th,
  TableContainer,
  Box,
  Thead,
  Radio,
  Switch,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Badge,
  useDisclosure,
} from '@chakra-ui/react';
import TrLoading from 'components/app/TrLoading';
import FAIcon from 'components/lib/FAIcon';
import Button from 'Library/Button';
import Pagination from 'Library/Pagination';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  updateOutreach,
  useGetEmailConnectionListMutation,
} from 'store/outreach.slice';
import { EmailConnectionDrawer } from '../Drawer';
import EmailConnectionModal from '../Modal';
import { LoginEmail } from '../Modal/LoginEmail';
import { useEmailConnectionTableServices } from './useEmailConnectionTableServices';

const EmailConnectionConnectionTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const {
    isOpen: isOpenEditEmailConnectionModal,
    onOpen: onOpenEditEmailConnectionModal,
    onClose: onCloseEditEmailConnectionModal,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenEmailConnectionDrawer,
    onOpen: onOpenEmailConnectionDrawer,
    onClose: onCloseEmailConnectionDrawer,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenLoginEmail,
    onOpen: onOpenLoginEmail,
    onClose: onCloseLoginEmail,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    emailConnectionList,
    emailConnectionPgBtn,
    handleChangeDefault,
    emailConnectionPage,
    pageChange,
    entryChange,
    handleSMTP,
    handleDisconnectConnection,
    handleDeleteConnection,
    isLoading,
  } = useEmailConnectionTableServices({ onOpenLoginEmail });

  const [reqGetEmailConnectionList, resGetEmailConnectionList] =
    useGetEmailConnectionListMutation();

  useEffect(() => {
    if (params.settingTypeId) {
      reqGetEmailConnectionList(emailConnectionPgBtn);
    }
  }, [params.settingTypeId]);

  useEffect(() => {
    if (
      resGetEmailConnectionList.isSuccess &&
      params.settingType === 'email-connection' &&
      params.settingTypeId
    ) {
      const mappedEmailConnections = emailConnectionList
        .map((connection: any, index: any) => {
          return {
            ...connection,
            rowId: index + 1,
          };
        })
        .filter((x: any) => x.id === Number(params.settingTypeId))[0];

      dispatch(
        updateOutreach({
          emailConnectionData: mappedEmailConnections,
        })
      );

      onOpenEmailConnectionDrawer();
    }
  }, [
    resGetEmailConnectionList.isSuccess,
    params.settingType,
    params.settingTypeId,
  ]);

  return (
    <Box px={1} justifyContent="space-between">
      <TableContainer
        boxSizing="border-box"
        border="1px solid"
        borderColor="default.white.400"
        borderRadius="md"
        height="65vh"
        sx={{ overflowY: 'scroll' }}
      >
        <Table>
          <Thead>
            <Tr bg="default.white.600">
              <Th
                sx={{
                  width: '50px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Default
              </Th>
              <Th
                sx={{
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Name
              </Th>
              <Th
                sx={{
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Email
              </Th>
              <Th
                sx={{
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Provider
              </Th>
              <Th
                sx={{
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Interval
              </Th>
              <Th
                sx={{
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Limit Per Day
              </Th>
              <Th
                sx={{
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                SMTP
              </Th>
              <Th
                sx={{
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                DNS
              </Th>
              <Th
                sx={{
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Status
              </Th>
              <Th
                sx={{
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              ></Th>
            </Tr>
          </Thead>
          <Tbody bgColor="#fff" fontSize="16px" color="#6B6C7B">
            {isLoading ? (
              <TrLoading rows={3} columns={10} />
            ) : (
              emailConnectionList?.map((item: any, index: number) => {
                return (
                  <Tr cursor="pointer">
                    <Td textAlign="center">
                      <Radio
                        colorScheme="purple"
                        isChecked={item.is_default === 1 ? true : false}
                        onChange={() => handleChangeDefault(item.id)}
                      />
                    </Td>
                    <Td
                      onClick={() => {
                        onOpenEmailConnectionDrawer();
                        navigate(`${location.pathname}/${item.id}`);
                      }}
                    >
                      {item.full_name}
                    </Td>
                    <Td
                      onClick={() => {
                        onOpenEmailConnectionDrawer();
                        navigate(`${location.pathname}/${item.id}`);
                      }}
                    >
                      {item.email}
                    </Td>
                    <Td
                      sx={{ textTransform: 'capitalize' }}
                      onClick={() => {
                        onOpenEmailConnectionDrawer();
                        navigate(`${location.pathname}/${item.id}`);
                      }}
                    >
                      {item.provider}
                    </Td>
                    <Td
                      onClick={() => {
                        onOpenEmailConnectionDrawer();
                        navigate(`${location.pathname}/${item.id}`);
                      }}
                    >
                      {item.random_interval_from}-{item.random_interval_to}
                    </Td>
                    <Td
                      onClick={() => {
                        onOpenEmailConnectionDrawer();
                        navigate(`${location.pathname}/${item.id}`);
                      }}
                    >
                      {item.email_limit_per_day} emails
                    </Td>
                    <Td>
                      <Switch
                        value={item.smtp_enable}
                        defaultChecked={item.smtp_enable === 1 ? true : false}
                        colorScheme="purple"
                        onChange={() => {
                          dispatch(
                            updateOutreach({
                              emailConnectionData: item,
                            })
                          );
                          handleSMTP(item);
                        }}
                      />
                    </Td>
                    <Td
                      onClick={() => {
                        onOpenEmailConnectionDrawer();
                        navigate(`${location.pathname}/${item.id}`);
                      }}
                    >
                      {item.dns}
                    </Td>
                    <Td
                      onClick={() => {
                        onOpenEmailConnectionDrawer();
                        navigate(`${location.pathname}/${item.id}`);
                      }}
                    >
                      {item.is_disconnect === 0 ? (
                        <Badge
                          variant="subtle"
                          colorScheme="green"
                          sx={{
                            borderRadius: '2px',
                            p: '4px 8px',
                            fontSize: '14px',
                            textTransform: 'capitalize',
                            color: '#38B17A',
                          }}
                        >
                          Connected
                        </Badge>
                      ) : (
                        <Badge
                          variant="subtle"
                          colorScheme="red"
                          sx={{
                            borderRadius: '2px',
                            p: '4px 8px',
                            fontSize: '14px',
                            textTransform: 'capitalize',
                          }}
                        >
                          Disconnected
                        </Badge>
                      )}
                    </Td>
                    <Td>
                      <Menu closeOnSelect={false}>
                        <MenuButton>
                          <Button
                            htmlType="menu"
                            variant="ghost"
                            iconName="ellipsis-h"
                            iconSize="xl"
                          />
                        </MenuButton>
                        <MenuList
                          fontSize="12px"
                          lineHeight="16px"
                          fontWeight="600"
                        >
                          <MenuItem
                            icon={<FAIcon iconName="pencil" />}
                            onClick={() => {
                              dispatch(
                                updateOutreach({
                                  emailConnectionData: {
                                    ...item,
                                    rowId: index + 1,
                                  },
                                })
                              );
                              onOpenEditEmailConnectionModal();
                            }}
                          >
                            Edit
                          </MenuItem>
                          {item.is_disconnect === 0 && (
                            <MenuItem
                              icon={<FAIcon iconName="circle" />}
                              onClick={() => {
                                handleDisconnectConnection(item.id);
                              }}
                              sx={{ color: '#CB2C2C' }}
                            >
                              Disconnect
                            </MenuItem>
                          )}
                          <MenuItem
                            icon={<FAIcon iconName="trash" />}
                            onClick={() => {
                              handleDeleteConnection(item.id);
                            }}
                            sx={{ color: '#CB2C2C' }}
                          >
                            Delete
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                );
              })
            )}
          </Tbody>
        </Table>

        {isOpenEmailConnectionDrawer && (
          <EmailConnectionDrawer
            isOpen={isOpenEmailConnectionDrawer}
            onOpen={onOpenEditEmailConnectionModal}
            onClose={onCloseEmailConnectionDrawer}
          />
        )}

        {isOpenEditEmailConnectionModal && (
          <EmailConnectionModal
            isOpen={isOpenEditEmailConnectionModal}
            onOpen={onOpenEditEmailConnectionModal}
            onClose={onCloseEditEmailConnectionModal}
          />
        )}

        {isOpenLoginEmail && (
          <LoginEmail isOpen={isOpenLoginEmail} onClose={onCloseLoginEmail} />
        )}
      </TableContainer>

      <Box sx={{ boxShadow: '0px -4px 8px 0px #0000000A', mt: '32px' }}>
        <Pagination
          totalPages={emailConnectionPage.lastPage}
          currentPage={emailConnectionPage.currentPage}
          onPageChange={(page) => pageChange(page)}
          totalEntries={emailConnectionPage.count}
          onEntryChange={(entries) => entryChange(entries)}
          currentCount={emailConnectionList.length}
          targetCount={emailConnectionPgBtn.take}
        />
      </Box>
    </Box>
  );
};
export default EmailConnectionConnectionTable;
