import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { listCandidates } from 'store/candidates.slice';
import { listJobs } from 'store/jobs.slice';

interface useJobsMainModuleServicesProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}
export const useJobsMainModuleServices = ({
  isOpen,
  onOpen,
  onClose,
}: useJobsMainModuleServicesProps) => {
  // Hooks and Constants
  const dispatch = useDispatch();
  const params = useParams();
  document.documentElement.dir = 'ltr';
  const storageName = 'jobFilter';

  // Redux Hooks
  const { candidateOthers } = useSelector((state: any) => state.candidates);
  const { jobPgBtn } = useSelector((state: any) => state.jobs);

  // Filter and Form Data
  const jobFilerData: string | null = localStorage.getItem(storageName);
  const initFilter = jobFilerData == null ? jobPgBtn : JSON.parse(jobFilerData);
  const hasJobFormData: string | null = localStorage.getItem('jobFormData');

  // Local State
  const [modalTitle, setModalTitle] = useState('Create New Job');
  const [searchJobs, setSearchJobs] = useState(initFilter.query);
  const [isLoading, setIsLoading] = useState(false)

  // Effect to Open Modal if Job Form Data Exists
  useEffect(() => {
    const initJobFormData =
      hasJobFormData == null ? {} : JSON.parse(hasJobFormData);
    if (Object.keys(initJobFormData).length > 0) {
      const job_id = initJobFormData?.job_id;
      const modal_title = job_id ? 'Edit Job' : 'Create New Job';
      setModalTitle(modal_title);
      onOpen();
    }
  }, [onOpen]);

  // Effect to Handle Tab Changes and Candidate View
  useEffect(() => {
    dispatch(
      listCandidates({
        candidateOthers: [{ ...candidateOthers, group_by_job: false }],
      })
    );
  }, [params.jobsTab]);

  // Effect to Set Check States and Fetch Jobs
  useEffect(() => {
    dispatch(
      listJobs({
        jobPgBtn: initFilter,
      })
    );
  }, []);

  return {
    isOpen,
    onOpen,
    onClose,
    modalTitle,
    isLoading: isLoading,
    setIsLoading,
    searchJobs,
    setSearchJobs,
  };
};
