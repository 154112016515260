export const MY_LEADS = [
  {
    id: 0,
    stat_id: [] as any,
    status: -1,
    name: 'All',
    items: [
      {
        id: 1,
        stat_id: [1],
        status: 1,
        name: 'Draft',
      },
      {
        id: 2,
        stat_id: [2],
        status: 1,
        name: 'Active',
      },
      {
        id: 3,
        stat_id: [3],
        status: 1,
        name: 'Approval Pending',
      },
      {
        id: 4,
        stat_id: [4],
        status: 1,
        name: 'Rejected',
      },
      {
        id: 5,
        stat_id: [5],
        status: 0,
        name: 'Inactive',
      },
    ],
  },
];

export const UNCLAIMED = [
  {
    id: 0,
    stat_id: [] as any,
    status: 1,
    name: 'All',
    items: [
      {
        id: 2,
        stat_id: [2],
        status: 1,
        name: 'Active',
      },
      {
        id: 5,
        stat_id: [5],
        status: 0,
        name: 'Inactive',
      },
    ],
  },
];

export const PROSPECTS = [
  {
    id: 0,
    stat_id: [] as any,
    status: 1,
    name: 'All',
    countId: 'All',
    items: [
      {
        id: 6,
        stat_id: [6],
        status: 1,
        name: 'Contacted',
        countId: 'Contacted',
      },
      {
        id: 7,
        stat_id: [7],
        status: 1,
        name: 'Opened',
        countId: 'Opened',
      },
      {
        id: 8,
        stat_id: [8],
        status: 1,
        name: 'Not intrested',
        countId: 'Not_Interested',
      },
      {
        id: 9,
        stat_id: [9],
        status: 1,
        name: 'Out of office',
        countId: 'Out_Of_Office',
      },
      {
        id: 10,
        stat_id: [10],
        status: 1,
        name: 'Not the right person',
        countId: 'Not_the_right_person',
      },
      {
        id: 11,
        stat_id: [11],
        status: 1,
        name: 'Replied',
        countId: 'Replied',
      },
    ],
  },
];
