import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

export const useSMSAutomationTableServices = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { smsAutomationList, smsAutomationData, smsAutomationPgBtn } =
    useSelector((state: any) => state.outreach);

  const [rowId, setRowId] = useState(smsAutomationData?.rowId);
  const [totalSMSAutomationList, setTotalSMSAutomationList] = useState(
    smsAutomationList.length
  );

  const onSubmit = (data: any) => {
    console.log(data);
  };

  const handlePrevData = () => {
    const templateID = smsAutomationList[rowId - 1 - 1].id;
    setRowId((prev: number) => prev - 1);
    navigate(
      `/engage/${params.settingNav}/${params.settingTab}/${params.settingType}/${templateID}`
    );
  };

  const handleNextData = () => {
    const templateID = smsAutomationList[rowId - 1 + 1].id;
    setRowId((prev: number) => prev + 1);
    navigate(
      `/engage/${params.settingNav}/${params.settingTab}/${params.settingType}/${templateID}`
    );
  };

  useEffect(() => {
    setTotalSMSAutomationList(smsAutomationList.length);
  }, [smsAutomationList]);

  useEffect(() => {
    if (smsAutomationData?.rowId) {
      setRowId(smsAutomationData?.rowId);
    }
  }, [smsAutomationData?.rowId]);

  return {
    rowId,
    totalSMSAutomationList,
    smsAutomationList,
    smsAutomationData,
    smsAutomationPgBtn,
    onSubmit,
    handlePrevData,
    handleNextData,
  };
};
