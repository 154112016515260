import { useCallback, useEffect, useMemo, useState } from 'react';
import { Stack, Box, Flex, Image } from '@chakra-ui/react';

import MdLoading from 'assets/images/ai-generator/MdLoading.gif';
import { PaginationLayout } from './Pagination';
import { RegenerateLayout } from './Regenerate';
import TypingEffect from './TypingEffect';

interface ContentItemLayoutProps {
  isSubject: boolean;
  content: string[];
  setActive: (value: any) => void;
  item: number;
  title: string;
  handleRegenerate: ({ index, field }: any) => void;
  isRegenerated: boolean;
  isLoadingList: boolean;
  isLoading: boolean;
}

export const ContentItemLayout = ({
  isSubject,
  content,
  setActive,
  item,
  title,
  handleRegenerate,
  isRegenerated,
  isLoadingList,
  isLoading,
}: ContentItemLayoutProps) => {
  const pageTotal = content.length;
  const [pageNo, setPageNo] = useState(1);

  const handlePrev = () => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
    }
  };

  const handleNext = () => {
    if (pageNo < pageTotal) {
      setPageNo(pageNo + 1);
    }
  };

  const [selected, setSelected] = useState<string | null>(null);

  const handleClick = useCallback(
    (subVal: string) => {
      setActive((prev: any) => ({
        ...prev,
        [isSubject ? 'subject' : 'body']: {
          ...prev[isSubject ? 'subject' : 'body'],
          value: subVal,
        },
      }));
      setSelected(JSON.stringify([item, pageNo - 1]));
    },
    [pageNo, isSubject, setActive, setSelected]
  );

  const memoizedContent = useMemo(() => {
    return (
      content &&
      content
        ?.filter((_, index) => index === pageNo - 1)
        .map((subVal) => (
          <Box
            key={subVal}
            onClick={() => {
              handleClick(subVal);
            }}
          >
            <TypingEffect
              isLoading={isLoading}
              selected={selected === JSON.stringify([item, pageNo - 1])}
              text={subVal}
              pageNo={pageNo}
            />
          </Box>
        ))
    );
  }, [content, pageNo, isSubject, isLoading, selected, setActive, item]);

  useEffect(() => {
    if (isRegenerated) {
      setPageNo(pageTotal);
    }
  }, [isRegenerated, pageTotal]);

  return (
    <Stack spacing="8px">
      <Box sx={{ fontSize: '14px', color: '#2B2D42' }}>
        {isLoadingList ? (
          <Box
            sx={{
              '& img': { display: 'inline-block', verticalAlign: 'middle' },
            }}
          >
            <span style={{ display: 'inline-block' }}>
              <Image src={MdLoading} height="12px" width="12px" />
            </span>
          </Box>
        ) : (
          <>
            {memoizedContent}
            <Flex gap="8px">
              {pageTotal > 1 && (
                <PaginationLayout
                  pageNo={pageNo}
                  pageTotal={pageTotal}
                  handlePrev={handlePrev}
                  handleNext={handleNext}
                />
              )}
              <RegenerateLayout
                isLoading={isLoading || isLoadingList}
                handleRegenerate={() => {
                  setSelected(JSON.stringify([item, pageNo - 1]));
                  handleRegenerate({
                    index: [item, pageNo - 1],
                    field: title.toLowerCase(),
                  });
                }}
              />
            </Flex>
          </>
        )}
      </Box>
    </Stack>
  );
};
