export const filterVariables: any = [
  {
    id: 'is_active',
    label: 'Active',
    checked: true,
  },
  {
    id: 'inactive',
    label: 'Inactive',
    checked: false,
  },
  {
    id: 'is_signed',
    label: 'Signed',
    checked: false,
  },
  {
    id: 'is_paid',
    label: 'Paid',
    checked: false,
  },
  {
    id: 'is_repeat',
    label: 'Repeat',
    checked: false,
  },
  {
    id: 'is_mvp',
    label: 'MVP',
    checked: false,
  },
];

export const filterJobTitleVariables: any = [
  {
    id: 'is_current',
    label: 'Current',
    checked: false,
  },
  {
    id: 'is_past',
    label: 'Past',
    checked: false,
  },
];

export const filterContactVariables: any = [
  {
    id: 'is_active',
    label: 'Active',
    checked: true,
  },
  {
    id: 'inactive',
    label: 'Inactive',
    checked: false,
  },
  {
    id: 'is_paid',
    label: 'Paid',
    checked: false,
  },
  {
    id: 'is_mvp',
    label: 'MVP',
    checked: false,
  },
];
