import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Modal,
  Stack,
  Text,
  FormControl,
  FormLabel,
  Box,
  Input,
  FormErrorMessage,
  Flex,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import FAIcon from 'components/lib/FAIcon';

import { Formik } from 'formik';
import { useLoginEmailServices } from './useLoginEmailServices';

interface LoginEmailProps {
  isOpen: any;
  onClose: any;
}

export const LoginEmail = ({ isOpen, onClose }: LoginEmailProps) => {
  const {
    initialValue,
    validationSchema,
    show,
    handleClick,
    handleSMTP,
    isLoading,
  } = useLoginEmailServices({ onClose });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      size="2xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent fontFamily="NunitoSans Regular">
        <ModalHeader
          background="default.white.800"
          borderRadius="4px 4px 0"
          p="18px 32px"
          fontSize="18px"
          lineHeight="21.92px"
          textAlign="left"
        >
          Login your email account
        </ModalHeader>
        <ModalCloseButton
          top="13px"
          right="20px"
          color="default.secondarytext"
        />
        <ModalBody borderRadius="0 0 4px 4px" p="18px 32px 100px 32px">
          <Stack
            spacing="8px"
            sx={{
              border: '1px solid #E7EDF4',
              borderRadius: '6px',
              p: '16px',
            }}
          >
            <Text sx={{ fontStyle: '14px' }}>
              Use the login and password credentials you use to access your
              inbox.
            </Text>

            <Formik
              initialValues={initialValue}
              validationSchema={validationSchema}
              onSubmit={handleSMTP}
              enableReinitialize={true}
            >
              {({ values, handleSubmit, handleChange, errors, touched }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Stack py="12px" spacing="10px">
                      <FormControl
                        isInvalid={Boolean(!!errors.login && touched.login)}
                        sx={{ pb: '8px' }}
                      >
                        <FormLabel sx={{ fontSize: '14px' }}>
                          Login{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          name="login"
                          value={values.login}
                          onChange={handleChange}
                          color="default.primarytext"
                          disabled
                        />
                        <FormErrorMessage>
                          {String(errors.login)}
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl
                        isInvalid={Boolean(
                          !!errors.password && touched.password
                        )}
                        sx={{ pb: '8px' }}
                      >
                        <FormLabel sx={{ fontSize: '14px' }}>
                          Password{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <InputGroup>
                          <Input
                            id="password"
                            name="password"
                            type={show ? 'text' : 'password'}
                            value={values.password}
                            onChange={handleChange}
                            color="default.primarytext"
                          />
                          <InputRightElement color="#718096">
                            <Box onClick={handleClick} cursor="pointer">
                              <FAIcon iconName={show ? 'eye' : 'eye-slash'} />
                            </Box>
                          </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>
                          {String(errors.password)}
                        </FormErrorMessage>
                      </FormControl>
                    </Stack>

                    <ModalFooter
                      position="absolute"
                      bottom="0"
                      background="default.white.100"
                      boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                      width="100%"
                      left={0}
                    >
                      <Flex gap="10px">
                        <Button onClick={onClose}>Cancel</Button>
                        <Button
                          variant="solid"
                          type="submit"
                          isLoading={isLoading}
                        >
                          Connect
                        </Button>
                      </Flex>
                    </ModalFooter>
                  </form>
                );
              }}
            </Formik>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
