import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Field, FieldArray, Formik } from 'formik';
import * as Yup from 'yup';

import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Radio,
  Textarea,
} from '@chakra-ui/react';
import FAIcon from 'components/lib/FAIcon';
import AtsSelect from '../../AtsSelect';
import Button from 'Library/Button';

import {
  useGetCandidatesMutation,
  useQuickUpdateCandidatesMutation,
} from 'store/candidates.slice';
import {
  useGetCandidateWorkStatusMutation,
  useGetCountriesMutation,
} from 'store/constant.slice';
import { useUserListMutation } from 'store/user.slice';
import AddressDrawerComponent from '../components/addressDrawer';

import Verified from 'assets/images/MdVerified.svg';
import NotVerified from 'assets/images/MdNotVerified.svg';
import ScrollToFieldError from '../../ScrollError';
import LoadingPage from '../../Loading';

const CandidatesDrawerForm = ({ setIsEdit }: any) => {
  const { candidateData, candidatePgBtn } = useSelector(
    (state: any) => state.candidates
  );
  const { countries, candidate_work_status } = useSelector(
    (state: any) => state.constants
  );
  const { userList, UserPgBtn } = useSelector((state: any) => state.user);

  const [reqCandidateUpdate, resCandidateUpdate] =
    useQuickUpdateCandidatesMutation();

  const [reqGetCandidate] = useGetCandidatesMutation();
  const [reqCountry] = useGetCountriesMutation();
  const [reqCanWorkStat] = useGetCandidateWorkStatusMutation();
  const [reqUserList, resUserList] = useUserListMutation();

  const [countryOption, setCountryOption] = useState([]);

  const populateContact = (contacts: any) => {
    let data: any = [];
    contacts?.map((item: any) => {
      data.push({
        number: item.number,
        primary: item.primary,
        valid: item.valid,
        category: {
          value: item.category,
          label: (
            <FAIcon
              iconName={
                item.category.toLowerCase() === 'personal'
                  ? 'user'
                  : 'briefcase'
              }
            />
          ),
        },
      });
    });
    if (data.length === 0) {
      data.push({
        number: '',
        primary: true,
        category: {
          value: 'personal',
          label: <FAIcon iconName="user" />,
        },
      });
    }
    return data;
  };

  const populateEmails = (emails: any) => {
    let data: any = [];

    emails?.map((item: any) => {
      data.push({
        email: item.email,
        primary: item.primary,
        valid: item.valid,
      });
    });

    if (data.length === 0) {
      data.push({
        email: '',
        primary: true,
      });
    }
    return data;
  };

  const initialPersonalValues = candidateData &&
    Object.keys(candidateData).length !== 0 && {
      // personal
      emails: populateEmails(candidateData.emails) || [
        {
          email: '',
          primary: true,
          valid: false,
        },
      ],
      contacts: populateContact(candidateData.contact) || [
        {
          number: '',
          primary: true,
          category: {
            value: 'personal',
            label: <FAIcon iconName="user" />,
          },
        },
      ],
      linkedin_url: candidateData?.linkedin_url || '',
      work_status_id: candidateData?.work_status_id?.toString(),
      zip_code: candidateData?.zip_code,
      city: candidateData?.city,
      state: candidateData?.state_province,
      country: (countries?.length > 0 &&
        countries
          .map((item: any) => ({
            label: item.country,
            value: item.id,
            code: item.code,
          }))
          .filter((val: any) => val.label === candidateData.country))[0],
      notes: '',
    };

  const usPhoneRegExp =
    /^(\+1[-.\s]?|1[-.\s]?)?(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$|^\+1\d{10}$|^1\d{10}$|^\d{10}$/;

  const validationPersonalSchema = Yup.lazy(() =>
    Yup.object().shape({
      // emails
      emails: Yup.array()
        .of(
          Yup.object().shape({
            email: Yup.string(),
            primary: Yup.boolean(),
          })
        )
        .test(
          'emails-one-primary',
          'There should only be one primary email',
          (value) => {
            const primaryCount = value?.filter((item) => item?.primary).length;
            return primaryCount === 1;
          }
        )
        .test('email-unique', 'Email should be unique', (value) => {
          const numArr = value?.map((item: any) => item?.email);
          return numArr.length === new Set(numArr).size;
        }),
      // contact
      contacts: Yup.array()
        .of(
          Yup.object().shape({
            number: Yup.string()
              .matches(/^[\d ()+-.]+$/gi, 'Contact is invalid')
              .matches(
                usPhoneRegExp,
                'Contact number must be a valid US number'
              ),
            primary: Yup.boolean(),
          })
        )
        .test(
          'contact-one-primary',
          'There should only be one primary contact',
          (value) => {
            const primaryCount = value?.filter((item) => item?.primary).length;
            return primaryCount === 1;
          }
        )
        .test('contact-unique', 'Contact number should be unique', (value) => {
          const numArr = value?.map((item: any) => item?.number);
          return numArr.length === new Set(numArr).size;
        }),
      linkedin_url: Yup.string(),
      work_status_id: Yup.string().nullable(),
      zip_code: Yup.string().nullable(),
      city: Yup.string().nullable(),
      state: Yup.string().nullable(),
      country: Yup.object(),
      notes: Yup.string(),
    })
  );

  const onSubmit = async (data: any) => {
    const checkEmailPrimary = data.emails.some(
      (item: any) => item.primary === true
    );
    data.emails[0].primary = checkEmailPrimary ? data.emails[0]?.primary : true;

    if (data.contacts.length > 0) {
      const checkContactPrimary = data.contacts.some(
        (item: any) => item.primary === true
      );
      data.contacts[0].primary = checkContactPrimary
        ? data.contacts[0]?.primary
        : true;
      const filteredContact = data.contacts.filter(
        (item: any) => item.number !== ''
      );
      data.contacts = filteredContact;
    }

    data.contacts = data.contacts.map((item: any) => {
      return {
        ...item,
        category: item.category.value || 'personal',
        valid: item.valid ? true : false,
      };
    });

    data.work_status_id = data.work_status_id || 4;

    data.country = data.country && data.country.label;

    if (data.notes !== '') {
      //removing this causing issues on font size
      // const notes = document.createElement('p');
      // notes.innerHTML = data.notes;
      // notes.style.fontFamily = 'Verdana';
      // notes.style.fontSize = '10pt';
      // notes.style.lineHeight = 'normal';
      // data.notes = String(notes.outerHTML);
    }

    await reqCandidateUpdate({ data, id: candidateData.id });
    await reqGetCandidate({ id: candidateData.id });
  };

  useEffect(() => {
    reqCountry({});
    reqCanWorkStat({});
    reqUserList({ data: UserPgBtn });
  }, [candidateData]);

  useEffect(() => {
    if (resUserList.isSuccess) {
      let option: any = [];
      if (Object.values(userList)?.length > 0) {
        Object.values(userList).map((item: any) => {
          option.push({
            value: item.id,
            label: [item?.first_name, item?.last_name].join(' '),
          });
        });
      }
    }
  }, [resUserList.isSuccess]);

  useEffect(() => {
    if (resCandidateUpdate.isSuccess) {
      setIsEdit((prev: boolean) => !prev);
    }
  }, [resCandidateUpdate.isSuccess]); //removing unnecessary dependencies here

  useEffect(() => {
    let options: any = [];
    if (countries?.length > 0) {
      countries.map((item: any) => {
        options.push({
          label: item.country,
          value: item.id,
          code: item.code,
        });
      });
    }
    setCountryOption(options);
  }, [countries]);

  return (
    <>
      {resCandidateUpdate.isLoading ? (
        <LoadingPage />
      ) : (
        <Box color="default.primarytext">
          <Formik
            initialValues={initialPersonalValues}
            validationSchema={validationPersonalSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              errors,
              touched,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <ScrollToFieldError />
                <Box background="#fff" borderRadius="8px" mb="50px">
                  <Flex py={2} gap="16px">
                    <FormLabel
                      fontSize="16px"
                      lineHeight="18px"
                      color="default.gray.600"
                      textAlign="left"
                      width="100px"
                    >
                      Contact
                    </FormLabel>
                    <FieldArray name="contacts">
                      {({ remove, push }) => (
                        <div>
                          {values.contacts?.map((cont: any, index: any) => (
                            <FormControl
                              isInvalid={Boolean(
                                !!errors.contacts && touched.contacts
                              )}
                            >
                              <FormControl
                                isInvalid={Boolean(
                                  !!(errors.contacts as any[])?.[index]
                                    ?.number &&
                                    (touched.contacts as any[])?.[index]?.number
                                )}
                              >
                                <Flex
                                  width="100%"
                                  direction="row"
                                  key={index}
                                  mb="12px"
                                  alignItems="center"
                                  gap="12px"
                                >
                                  <Flex alignItems="center">
                                    <Flex>
                                      <FormControl
                                        isInvalid={Boolean(
                                          !!(errors.contacts as any[])?.[index]
                                            ?.category &&
                                            (touched.contacts as any[])?.[index]
                                              ?.category
                                        )}
                                      >
                                        <Flex
                                          justifyContent="center"
                                          alignItems="center"
                                        >
                                          <AtsSelect
                                            variant="outline"
                                            defaultValue={
                                              values.contacts[index].category
                                            }
                                            onChange={(e: any) => {
                                              setFieldValue(
                                                `contacts.${index}.category`,
                                                e.value
                                              );
                                            }}
                                            options={[
                                              {
                                                value: 'personal',
                                                label: (
                                                  <Box fontSize="12px">
                                                    <FAIcon iconName="user" />
                                                  </Box>
                                                ),
                                              },
                                              {
                                                value: 'work',
                                                label: (
                                                  <Box fontSize="12px">
                                                    <FAIcon iconName="briefcase" />
                                                  </Box>
                                                ),
                                              },
                                            ]}
                                            isSearchable={false}
                                            styles={{
                                              zIndex: '9999',
                                              indicatorSeparator: () => {},
                                              dropdownIndicator: (
                                                defaultStyles: any
                                              ) => ({
                                                ...defaultStyles,
                                                paddingLeft: '0px',
                                                '& svg': {
                                                  width: '14px',
                                                  height: '14px',
                                                },
                                              }),

                                              control: (baseStyles: any) => ({
                                                ...baseStyles,
                                                height: '33px',
                                                minHeight: '33px',
                                                borderRadius: '4px 0 0 4px',
                                                borderColor: '#E2E8F0',
                                                div: {
                                                  alignItems: 'start',
                                                  fontSize: '14px',
                                                  color: '#6930CA',
                                                },
                                              }),
                                            }}
                                          />
                                        </Flex>
                                      </FormControl>
                                    </Flex>

                                    <Flex
                                      sx={{
                                        input: {
                                          height: '33px',
                                          fontSize: '14px',
                                          borderRadius: '0 4px 4px 0',
                                          borderColor: '#E2E8F0',
                                        },
                                      }}
                                    >
                                      <Field
                                        as={Input}
                                        name={`contacts[${index}].number`}
                                        placeholder="Contact"
                                        size="md"
                                        type="text"
                                        onChange={handleChange}
                                        sx={{
                                          height: '33px',
                                          width: '210px',
                                          borderRadius: '2px',
                                          fontSize: '14px',
                                        }}
                                      />
                                    </Flex>
                                  </Flex>

                                  <Flex
                                    justifyContent="center"
                                    alignItems="center"
                                  >
                                    <FormControl>
                                      <Flex
                                        justifyContent="center"
                                        alignItems="center"
                                      >
                                        <Radio
                                          id={`contacts[${index}].primary`}
                                          name={`contactPrimary`} // Set the same name for all radio buttons
                                          value={index} // Use the index as the value
                                          isChecked={cont.primary}
                                          onChange={(e) => {
                                            // Handle radio button change to update the selected row
                                            const updatedContact =
                                              values.contacts.map(
                                                (
                                                  contacts: any,
                                                  key: number
                                                ) => ({
                                                  ...contacts,
                                                  primary: key === index,
                                                })
                                              );
                                            setFieldValue(
                                              'contacts',
                                              updatedContact
                                            );
                                          }}
                                        ></Radio>
                                      </Flex>
                                    </FormControl>
                                  </Flex>
                                  <Flex
                                    justifyContent="center"
                                    alignItems="center"
                                  >
                                    <FormControl>
                                      <Flex
                                        justifyContent="center"
                                        alignItems="center"
                                        width="85%"
                                      >
                                        {cont.valid === true ? (
                                          <Image
                                            src={Verified}
                                            alt="ON"
                                            cursor="pointer"
                                            onClick={() => {
                                              setFieldValue(
                                                `contacts[${index}].valid`,
                                                false
                                              );
                                            }}
                                          />
                                        ) : (
                                          <Image
                                            src={NotVerified}
                                            alt="OFF"
                                            cursor="pointer"
                                            onClick={() =>
                                              setFieldValue(
                                                `contacts[${index}].valid`,
                                                true
                                              )
                                            }
                                          />
                                        )}
                                      </Flex>
                                    </FormControl>
                                  </Flex>
                                  <Flex
                                    justifyContent="center"
                                    alignItems="center"
                                  >
                                    {values.contacts.length > 1 && (
                                      <Flex
                                        onClick={() => {
                                          remove(index);
                                          if (values.contacts.length === 2) {
                                            console.log(
                                              'went here to make last one email primary to true'
                                            );
                                            const updatedContact =
                                              values.contacts
                                                .map(
                                                  (
                                                    contacts: any,
                                                    key: number
                                                  ) => ({
                                                    ...contacts,
                                                    primary: true,
                                                  })
                                                )
                                                .filter(
                                                  (
                                                    contacts: any,
                                                    key: number
                                                  ) => index !== key
                                                );
                                            setFieldValue(
                                              'contacts',
                                              updatedContact
                                            );
                                          }
                                        }}
                                        justifyContent="center"
                                        alignItems="center"
                                        color="#D5605D"
                                        cursor="pointer"
                                      >
                                        <FAIcon iconName="trash" />
                                      </Flex>
                                    )}
                                  </Flex>
                                </Flex>
                                <FormErrorMessage mb="12px">
                                  {String(
                                    (errors.contacts as any[])?.[index]?.number
                                  )}
                                </FormErrorMessage>
                              </FormControl>
                              {index + 1 === values.contacts.length &&
                                typeof errors.contacts !== 'object' && (
                                  <FormErrorMessage mb="12px">
                                    {String(errors.contacts)}
                                  </FormErrorMessage>
                                )}
                            </FormControl>
                          ))}

                          <Box
                            id={`tagTooltip`}
                            color="primary.600"
                            fontSize="14px"
                            lineHeight="14.62px"
                            height="fit-content"
                            cursor="pointer"
                            onClick={() => {
                              push({
                                number: '',
                                primary: false,
                                category: {
                                  value: 'personal',
                                  label: <FAIcon iconName="user" />,
                                },
                              });
                            }}
                          >
                            <FAIcon iconName="plus" /> Add Contact
                          </Box>
                        </div>
                      )}
                    </FieldArray>
                  </Flex>

                  <Flex py={2} gap="16px">
                    <FormLabel
                      fontSize="16px"
                      lineHeight="18px"
                      color="default.gray.600"
                      textAlign="left"
                      width="100px"
                    >
                      Email
                    </FormLabel>
                    <FieldArray name="emails">
                      {({ remove, push }) => (
                        <div>
                          {values.emails?.map((email: any, index: any) => (
                            <FormControl
                              isInvalid={Boolean(
                                !!errors.emails && touched.emails
                              )}
                            >
                              <FormControl
                                isInvalid={Boolean(
                                  !!(errors.emails as any[])?.[index]?.email &&
                                    (touched.emails as any[])?.[index]?.email
                                )}
                              >
                                <Flex
                                  width="100%"
                                  direction="row"
                                  key={index}
                                  mb="12px"
                                  alignItems="center"
                                  gap="12px"
                                >
                                  <Flex>
                                    <Field
                                      as={Input}
                                      name={`emails.${index}.email`}
                                      placeholder="Email Address"
                                      size="sm"
                                      type="text"
                                      onChange={handleChange}
                                      sx={{
                                        height: '33px',
                                        width: '265px',
                                        borderRadius: '2px',
                                        fontSize: '14px',
                                      }}
                                    />
                                  </Flex>

                                  <Flex
                                    width="120%"
                                    justifyContent="center"
                                    alignItems="center"
                                    gap="12px"
                                  >
                                    <FormControl>
                                      <Flex
                                        justifyContent="center"
                                        alignItems="center"
                                      >
                                        <Radio
                                          id={`emails.${index}.primary`}
                                          name={`emailsPrimary`} // Set the same name for all radio buttons
                                          value={index} // Use the index as the value
                                          isChecked={email.primary}
                                          onChange={(e) => {
                                            // Handle radio button change to update the selected row
                                            const updatedEmails =
                                              values.emails.map(
                                                (email: any, key: number) => ({
                                                  ...email,
                                                  primary: key === index,
                                                })
                                              );
                                            setFieldValue(
                                              'emails',
                                              updatedEmails
                                            );
                                          }}
                                        ></Radio>
                                      </Flex>
                                    </FormControl>
                                    <FormControl>
                                      <Flex
                                        justifyContent="center"
                                        alignItems="center"
                                      >
                                        {email.valid === true ? (
                                          <Image
                                            src={Verified}
                                            alt="ON"
                                            cursor="pointer"
                                            onClick={() => {
                                              setFieldValue(
                                                `emails[${index}].valid`,
                                                false
                                              );
                                            }}
                                          />
                                        ) : (
                                          <Image
                                            src={NotVerified}
                                            alt="OFF"
                                            cursor="pointer"
                                            onClick={() =>
                                              setFieldValue(
                                                `emails[${index}].valid`,
                                                true
                                              )
                                            }
                                          />
                                        )}
                                      </Flex>
                                    </FormControl>
                                  </Flex>

                                  <Flex
                                    width="5%"
                                    justifyContent="center"
                                    alignItems="center"
                                  >
                                    {values.emails.length > 1 && (
                                      <Flex
                                        onClick={() => {
                                          remove(index);
                                          if (values.emails.length === 2) {
                                            console.log(
                                              'went here to make last one email primary to true'
                                            );
                                            const updatedEmails = values.emails
                                              .map(
                                                (email: any, key: number) => ({
                                                  ...email,
                                                  primary: true,
                                                })
                                              )
                                              .filter(
                                                (email: any, key: number) =>
                                                  index !== key
                                              );
                                            setFieldValue(
                                              'emails',
                                              updatedEmails
                                            );
                                          }
                                        }}
                                        justifyContent="center"
                                        alignItems="center"
                                        color="#D5605D"
                                        cursor="pointer"
                                      >
                                        <FAIcon iconName="trash" />
                                      </Flex>
                                    )}
                                  </Flex>
                                </Flex>
                                <FormErrorMessage mb="12px">
                                  {String(
                                    (errors.emails as any[])?.[index]?.email
                                  )}
                                </FormErrorMessage>
                              </FormControl>
                              {index + 1 === values.emails.length &&
                                typeof errors.emails !== 'object' && (
                                  <FormErrorMessage mb="12px">
                                    {String(errors.emails)}
                                  </FormErrorMessage>
                                )}
                            </FormControl>
                          ))}

                          <Box
                            id={`tagTooltip`}
                            color="primary.600"
                            fontSize="14px"
                            lineHeight="14.62px"
                            height="fit-content"
                            cursor="pointer"
                            onClick={() => {
                              push({
                                email: '',
                                primary: false,
                              });
                            }}
                          >
                            <FAIcon iconName="plus" /> Add Email
                          </Box>
                        </div>
                      )}
                    </FieldArray>
                  </Flex>

                  <Flex py={2} gap="16px">
                    <FormLabel
                      fontSize="16px"
                      lineHeight="18px"
                      color="default.gray.600"
                      textAlign="left"
                      width="100px"
                    >
                      Linkedin
                    </FormLabel>
                    <Flex>
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.linkedin_url && touched.linkedin_url
                        )}
                      >
                        <Input
                          id="linkedin_url"
                          name="linkedin_url"
                          type="text"
                          placeholder="LinkedIn Url"
                          variant="outline"
                          value={values.linkedin_url}
                          onChange={handleChange}
                          sx={{
                            height: '33px',
                            width: '350px',
                            borderRadius: '2px',
                            fontSize: '14px',
                          }}
                        />
                      </FormControl>
                    </Flex>
                  </Flex>

                  <Flex py={2} gap="16px">
                    <FormLabel
                      fontSize="16px"
                      lineHeight="18px"
                      color="default.gray.600"
                      textAlign="left"
                      width="100px"
                    >
                      Work Status
                    </FormLabel>
                    <Flex width="175px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.work_status_id && touched.work_status_id
                        )}
                      >
                        <Menu closeOnSelect={true}>
                          <MenuButton type="button">
                            <Box
                              sx={{
                                button: {
                                  p: '4px 8px',
                                  pl: 0,
                                  height: '33px',
                                  fontSize: '14px',
                                  border: 'none',
                                  color: 'primary.500',
                                },
                              }}
                            >
                              <Button rightIcon="chevron-down" type="button">
                                {candidate_work_status?.length > 0 &&
                                  candidate_work_status.filter(
                                    (val: any) =>
                                      val.id ===
                                      (Number(values.work_status_id) || 4)
                                  )[0].status}
                              </Button>
                            </Box>
                          </MenuButton>
                          {
                            <MenuList
                              fontSize="sm"
                              maxHeight="120px"
                              overflowY="auto"
                            >
                              {candidate_work_status.map((val: any) => {
                                return (
                                  <MenuItem
                                    type="button"
                                    onClick={() =>
                                      setFieldValue(
                                        'work_status_id',
                                        Number(val.id)
                                      )
                                    }
                                  >
                                    {val.status}
                                  </MenuItem>
                                );
                              })}
                            </MenuList>
                          }
                        </Menu>
                        <FormErrorMessage>
                          {String(errors.work_status_id)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                  </Flex>

                  <Flex py={2} gap="16px">
                    <FormLabel
                      fontSize="16px"
                      lineHeight="18px"
                      color="default.gray.600"
                      textAlign="left"
                      width="100px"
                    >
                      Address
                    </FormLabel>
                    <AddressDrawerComponent
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      countryOption={countryOption}
                      countryField={{
                        name: 'country',
                        value: values.country,
                        errors: errors.country,
                        touched: touched.country,
                      }}
                      cityField={{
                        name: 'city',
                        value: values.city,
                        errors: errors.city,
                        touched: touched.city,
                      }}
                      stateField={{
                        name: 'state',
                        value: values.state,
                        errors: errors.state,
                        touched: touched.state,
                      }}
                      zipField={{
                        name: 'zip_code',
                        value: values.zip_code,
                        errors: errors.zip_code,
                        touched: touched.zip_code,
                      }}
                    />
                  </Flex>
                  <Flex py={2} gap="16px">
                    <FormLabel
                      fontSize="16px"
                      lineHeight="18px"
                      color="default.gray.600"
                      textAlign="left"
                      width="100px"
                    >
                      Notes
                    </FormLabel>
                    <Flex>
                      <Field
                        as={Textarea}
                        id="notes"
                        name="notes"
                        type="text"
                        placeholder="Type your notes here..."
                        variant="outline"
                        value={values.notes}
                        sx={{
                          width: '350px',
                          zIndex: 0,
                        }}
                      />
                    </Flex>
                  </Flex>
                </Box>

                <Flex
                  sx={{
                    gap: '10px',
                    justifyContent: 'flex-end',
                    padding: '12px',
                    borderTop: '1px solid #E6E9EE',
                    position: 'fixed',
                    bottom: '0',
                    right: '0',
                    width: '40%',
                    background: 'default.white.100',
                  }}
                >
                  <Box
                    sx={{
                      button: {
                        background: 'none',
                        borderRadius: '6px',
                      },
                    }}
                  >
                    <Button onClick={() => setIsEdit((prev: boolean) => !prev)}>
                      Cancel
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      button: {
                        borderRadius: '6px',
                      },
                    }}
                  >
                    <Button variant="solid" onClick={handleSubmit}>
                      Save Changes
                    </Button>
                  </Box>
                </Flex>
              </form>
            )}
          </Formik>
        </Box>
      )}
    </>
  );
};

export default CandidatesDrawerForm;
