import { useTheme } from '@chakra-ui/system';
import React from 'react';
import { GroupBase } from 'react-select';

import ReactCreatableSelect, { CreatableProps } from 'react-select/creatable';
import { ATSTheme } from 'theme/theme';

interface CreatableSelectPropsType<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends CreatableProps<Option, IsMulti, Group> {
  isError?: boolean;
  CreateComponent?: React.ReactNode;
}
export default function CreatableSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: CreatableSelectPropsType<Option, IsMulti, Group>) {
  const { colors } = useTheme<ATSTheme>();
  return (
    <ReactCreatableSelect
      {...props}
      styles={{
        control: (styles, { isFocused }) => ({
          ...styles,
          fontSize: '14px',
          borderWidth: '1.5px',
          borderColor: isFocused
            ? colors.primary[800]
            : props?.isError
            ? ' #E53E3E'
            : '#E7E9ED',
          borderRadius: '4px',
          cursor: 'pointer',
          boxShadow: 'none',
          '&:active': {
            borderColor: colors.primary[800],
            outlineWidth: 2,
          },
          '&:hover': {
            ...styles[':hover'],
          },
          '& ::-webkit-scrollbar': {
            display: 'none',
          },
        }),
        option: (styles, { isSelected, isFocused, isDisabled }) => ({
          ...styles,
          background: isSelected
            ? colors.primary[800]
            : isFocused
            ? colors.primary[100]
            : styles.color,
          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled
              ? isSelected
                ? styles.color
                : colors.primary[200]
              : undefined,
          },
          cursor: isDisabled ? 'not-allowed' : 'default',
          zIndex: 20,
        }),
        valueContainer: (styles: any) => ({
          ...styles,
          maxHeight: '33px',
          overflowY: 'scroll',
        }),
        dropdownIndicator: (styles: any) => ({
          ...styles,
          color: '#676767',
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        placeholder: (styles: any) => ({
          ...styles,
          fontSize: '14px',
          color: '#9B9B9B',
        }),
      }}
    />
  );
}
