/* eslint-disable react-hooks/exhaustive-deps */
import {
  Tr,
  Td,
  Table,
  Tbody,
  Th,
  TableContainer,
  Box,
  Thead,
  useDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
  Text,
} from '@chakra-ui/react';

import Button from 'Library/Button';
import FAIcon from 'components/lib/FAIcon';
import moment from 'moment';
import parse from 'html-react-parser';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  updateOutreach,
  usePostSMSAutomationDeleteMutation,
  usePostSMSAutomationListMutation,
} from 'store/outreach.slice';
import { SMSAutomationDrawer } from '../Drawer';
import SMSAutomationModal from '../Modal';
import { useSMSAutomationTableServices } from './useSMSAutomationTableServices';
import ConfirmationModal from '../Modal/Confirmation/ConfirmationModal';
import TrLoading from 'components/app/TrLoading';

const SMSAutomationListTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const { smsAutomationList, smsAutomationPgBtn, smsAutomationData } =
    useSMSAutomationTableServices();

  const [reqPostSMSAutomationList, resPostSMSAutomationList] =
    usePostSMSAutomationListMutation();

  const {
    isOpen: isOpenEditSMSAutomationModal,
    onOpen: onOpenEditSMSAutomationModal,
    onClose: onCloseEditSMSAutomationModal,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenSMSAutomationDrawer,
    onOpen: onOpenSMSAutomationDrawer,
    onClose: onCloseSMSAutomationDrawer,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenConfirmation,
    onOpen: onOpenConfirmation,
    onClose: onCloseConfirmation,
  } = useDisclosure();

  useEffect(() => {
    if (params.settingType === 'sms-automation') {
      reqPostSMSAutomationList(smsAutomationPgBtn);
    }
  }, [params.settingType]);

  useEffect(() => {
    if (
      params.settingType === 'sms-automation' &&
      params.settingTypeId &&
      resPostSMSAutomationList.isSuccess
    ) {
      const mappedSMSAutomations = smsAutomationList
        .map((template: any, index: any) => {
          return {
            ...template,
            rowId: index + 1,
          };
        })
        .filter((x: any) => x.id === Number(params.settingTypeId))[0];

      dispatch(
        updateOutreach({
          smsAutomationData: mappedSMSAutomations,
        })
      );

      onOpenSMSAutomationDrawer();
    }
  }, [
    params.settingType,
    params.settingTypeId,
    resPostSMSAutomationList.isSuccess,
  ]);

  return (
    <Box px={6} justifyContent="space-between">
      <TableContainer
        boxSizing="border-box"
        border="1px solid"
        borderColor="default.white.400"
        borderRadius="md"
        height="80vh"
        sx={{ overflowY: 'scroll' }}
      >
        <Table>
          <Thead>
            <Tr bg="default.white.600">
              <Th
                sx={{
                  width: '50px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Automation Name
              </Th>
              <Th
                sx={{
                  width: '50px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Trigger
              </Th>
              <Th
                sx={{
                  width: '50px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                SMS Message
              </Th>
              <Th
                sx={{
                  width: '50px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Date Added
              </Th>
              <Th
                sx={{
                  width: '50px',
                }}
              />
            </Tr>
          </Thead>
          {resPostSMSAutomationList.isLoading ? (
            <TrLoading rows={3} columns={6} />
          ) : (
            <Tbody bgColor="#fff" fontSize="16px" color="#6B6C7B">
              {smsAutomationList.map((item: any, index: number) => {
                return (
                  <Tr cursor="pointer">
                    <Td
                      onClick={() => {
                        onOpenSMSAutomationDrawer();
                        navigate(`${location.pathname}/${item.id}`);
                      }}
                    >
                      <Text sx={{ width: '150px', whiteSpace: 'pre-line' }}>
                        {item.name}
                      </Text>
                    </Td>
                    <Td
                      onClick={() => {
                        onOpenSMSAutomationDrawer();
                        navigate(`${location.pathname}/${item.id}`);
                      }}
                    >
                      <Text sx={{ width: '150px', whiteSpace: 'pre-line' }}>
                        {item.trigger}
                      </Text>
                    </Td>
                    <Td
                      onClick={() => {
                        onOpenSMSAutomationDrawer();
                        navigate(`${location.pathname}/${item.id}`);
                      }}
                    >
                      <Text sx={{ width: '300px', whiteSpace: 'pre-line' }}>
                        {parse(item.message)}
                      </Text>
                    </Td>
                    <Td
                      onClick={() => {
                        onOpenSMSAutomationDrawer();
                        navigate(`${location.pathname}/${item.id}`);
                      }}
                    >
                      {moment(item.created_at).format('MMM DD, YYYY')}
                    </Td>
                    <Td>
                      <Menu closeOnSelect={false}>
                        <MenuButton>
                          <Button
                            htmlType="menu"
                            variant="ghost"
                            iconName="ellipsis-h"
                            iconSize="xl"
                          />
                        </MenuButton>
                        <MenuList
                          fontSize="12px"
                          lineHeight="16px"
                          fontWeight="600"
                        >
                          <MenuItem
                            icon={<FAIcon iconName="pencil" />}
                            onClick={() => {
                              dispatch(
                                updateOutreach({
                                  smsAutomationData: item,
                                })
                              );
                              onOpenEditSMSAutomationModal();
                            }}
                          >
                            Edit
                          </MenuItem>
                          <MenuItem
                            icon={<FAIcon iconName="trash" />}
                            onClick={() => {
                              dispatch(
                                updateOutreach({ smsAutomationData: item })
                              );
                              onOpenConfirmation();
                            }}
                            sx={{ color: '#CB2C2C' }}
                          >
                            Delete
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>

        {isOpenEditSMSAutomationModal && (
          <SMSAutomationModal
            isEdit
            isOpen={isOpenEditSMSAutomationModal}
            onClose={onCloseEditSMSAutomationModal}
          />
        )}

        {isOpenSMSAutomationDrawer && (
          <SMSAutomationDrawer
            isOpen={isOpenSMSAutomationDrawer}
            onClose={onCloseSMSAutomationDrawer}
          />
        )}

        {isOpenConfirmation && (
          <ConfirmationModal
            id={smsAutomationData.id}
            isOpen={isOpenConfirmation}
            onClose={onCloseConfirmation}
          />
        )}
      </TableContainer>
    </Box>
  );
};
export default SMSAutomationListTable;
