import { useSelector } from 'react-redux';

import {
  Badge,
  Box,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import { LEAD_STATUS, SEQUENCE_STATUS } from '../../../../constant';

const CandidateSequences = () => {
  const { sequenceCandidateData } = useSelector((state: any) => state.outreach);
  return (
    <Box py={4}>
      <TableContainer
        boxSizing="border-box"
        bgColor="#F9FAFB"
        border="1px solid"
        borderColor="default.white.400"
        borderRadius="md"
        height="auto"
        sx={{ overflowY: 'scroll' }}
      >
        <Table>
          <Thead>
            <Tr bg="default.white.600">
              <Th
                sx={{
                  width: '200px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Sequence Name
              </Th>
              <Th
                sx={{
                  width: '200px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Job
              </Th>
              <Th
                sx={{
                  width: '200px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Owner
              </Th>
              <Th
                sx={{
                  width: '200px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Lead Status
              </Th>
              <Th
                sx={{
                  width: '200px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Sequence Status
              </Th>
            </Tr>
          </Thead>
          <Tbody bgColor="#fff" fontSize="16px" color="#6B6C7B">
            {sequenceCandidateData?.sequence &&
              sequenceCandidateData?.sequence?.length > 0 &&
              sequenceCandidateData?.sequence?.map((val: any) => {
                return (
                  <Tr>
                    <Td>
                      <Link
                        href={`/engage/sequences/recruitment/candidates/${val?.suquence?.id}`}
                        target="_blank"
                      >
                        {val?.suquence?.title}
                      </Link>
                    </Td>
                    <Td>
                      <Link
                        href={`/jobs/${val?.suquence?.job?.id}/details`}
                        target="_blank"
                      >
                        {val?.suquence?.job?.title}
                      </Link>
                    </Td>
                    <Td>
                      {[
                        val?.suquence?.user?.first_name,
                        val?.suquence?.user?.last_name,
                      ].join(' ')}
                    </Td>
                    <Td>
                      <Badge
                        sx={{
                          borderRadius: '2px',
                          p: '4px 8px',
                          fontSize: '14px',
                          fontWeight: 700,
                          textTransform: 'capitalize',
                          bgColor:
                            LEAD_STATUS[val?.suquence?.job?.job_status?.status]
                              ?.bgColor,
                          color:
                            LEAD_STATUS[val?.suquence?.job?.job_status?.status]
                              ?.color,
                        }}
                      >
                        {
                          LEAD_STATUS[val?.suquence?.job?.job_status?.status]
                            ?.label
                        }
                      </Badge>
                    </Td>
                    <Td>
                      <Badge
                        sx={{
                          borderRadius: '2px',
                          p: '4px 8px',
                          fontSize: '14px',
                          fontWeight: 700,
                          textTransform: 'capitalize',
                          bgColor:
                            SEQUENCE_STATUS[
                              val?.sequence_candidate_status?.status
                            ]?.bgColor,
                          color:
                            SEQUENCE_STATUS[
                              val?.sequence_candidate_status?.status
                            ]?.color,
                        }}
                      >
                        {
                          SEQUENCE_STATUS[
                            val?.sequence_candidate_status?.status
                          ]?.label
                        }
                      </Badge>
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CandidateSequences;
