import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from './api.slice';
import { appError } from './app.slice';

export const teamsSlice = createSlice({
  name: 'teams',
  initialState: {
    teamList: [],
    teamMembers: [],
    savedUserId: null,
    teamData: {},
  },
  reducers: {
    updateTeam: (state, { payload }) => ({ ...state, ...payload }),
    setTeamData: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { updateTeam, setTeamData } = teamsSlice.actions;
export default teamsSlice.reducer;

const teamApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    ListTeams: builder.mutation({
      query: (data) => ({
        url: `/teams/`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // dispatch(listTemplate({ tags: data.data }));
          dispatch(updateTeam({ teamList: data.data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    CreateTeam: builder.mutation({
      query: (data) => ({
        url: `/teams/create`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),

    UpdateTeam: builder.mutation({
      query: ({ data, id }) => ({
        url: `/teams/update/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),

    DeleteTeam: builder.mutation({
      query: ({ id }) => ({
        url: `/teams/delete/${id}`,
        method: 'DELETE',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),

    SwitchAccount: builder.mutation({
      query: ({ data }) => ({
        url: `/teams/switch-account`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          console.log('data', data);
          dispatch(updateTeam({ savedUserId: data.data.data }));
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),

    YourTeam: builder.mutation({
      query: (data) => ({
        url: `/teams/get-team-by-manager`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        // body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setTeamData({ teamData: data.data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  useListTeamsMutation,
  useCreateTeamMutation,
  useUpdateTeamMutation,
  useDeleteTeamMutation,
  useSwitchAccountMutation,
  useYourTeamMutation,
} = teamApiSlice;
