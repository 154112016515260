import { useState } from 'react';
import { useSelector } from 'react-redux';

export function useActivityLogServices() {
  const { leadData } = useSelector((state: any) => state.leads);

  const [hasFilteredDay, setHasFilteredDay] = useState<string>('Last 7 Days');

  return { hasFilteredDay, setHasFilteredDay, leadData };
}
