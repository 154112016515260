import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { SIDE_BAR_ITEMS } from '../constants';
import SMSAutomation from './TabPanel/SMSAutomation';
import SMSHistory from './TabPanel/SMSHistory';

import { useSMSTabListServices } from './useSMSTabListServices';

const SMSTabList = () => {
  const { selectedTab, handleChangeTab } = useSMSTabListServices();

  return (
    <Box sx={{ bgColor: '#fff', borderRadius: '5px', p: '16px' }}>
      <Tabs
        colorScheme="purple"
        index={selectedTab}
        onChange={(index) => handleChangeTab(index)}
      >
        <TabList sx={{ mx: '20px' }}>
          {SIDE_BAR_ITEMS.map((barItem) => {
            return (
              <Tab
                sx={{
                  color: '#2B2D42',
                  fontWeight: 700,
                }}
              >
                {barItem.label}
              </Tab>
            );
          })}
        </TabList>

        <TabPanels>
          <TabPanel>
            <SMSAutomation />
          </TabPanel>
          <TabPanel>
            <SMSHistory />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default SMSTabList;
