export const tabOptions = ['email-thread', 'activities'];

export const SIDE_BAR_ITEMS = [
  {
    id: 1,
    label: 'Email Thread',
    to: 'email-thread',
  },
  {
    id: 2,
    label: 'Activities',
    to: 'activities',
  },
];
