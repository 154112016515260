import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from './api.slice';
import { appError } from './app.slice';

export const geoLocationSlice = createSlice({
  name: 'geolocation',
  initialState: {
    geo_location: [],
  },
  reducers: {
    loadGeoLocation: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { loadGeoLocation } = geoLocationSlice.actions;
export default geoLocationSlice.reducer;

const geoLocationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    SearchGeoTypes: builder.mutation({
      query: (data) => ({
        url: '/geo-location/search',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(loadGeoLocation({ geo_location: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    WildSearchGeoTypes: builder.mutation({
      query: (data) => ({
        url: '/geo-location/wild-card-search',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(loadGeoLocation({ geo_location: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const { useSearchGeoTypesMutation, useWildSearchGeoTypesMutation } =
  geoLocationApiSlice;
