/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';

import {
  useGetSequenceDataMutation,
  usePostPreviewEmailMutation,
  usePostSendEmailMutation,
  usePostUpdateTouchesSequenceMutation,
} from 'store/outreach.slice';

import { EMAIL_SCHEMA, STEPS_SCHEMA } from '../constant';
import { useStepsServices } from '../useStepsServices';

export const useStepsFormServices = () => {
  const toast = useToast();

  const { sequencesData, stepsData, previewData } = useSelector(
    (state: any) => state.outreach
  );
  const { currentProfile } = useSelector((state: any) => state.auth);

  const { handleReset } = useStepsServices();

  const [reqPostUpdateTouchesSequence, resPostUpdateTouchesSequence] =
    usePostUpdateTouchesSequenceMutation();
  const [reqGetSequenceData] = useGetSequenceDataMutation();
  const [reqPostSendEmail, resPostSendEmail] = usePostSendEmailMutation();
  const [reqPostPreviewEmail, resPostPreviewEmail] =
    usePostPreviewEmailMutation();

  const [initialValues, setInitialValues] = useState(stepsData);

  const intialValueEmail = {
    to: sequencesData?.user?.email || currentProfile?.email || '',
  };

  const handlePreview = (values: any) => {
    const payload = {
      subject: values.subject,
      body: values.content,
      sequence_id: sequencesData.id,
    };
    reqPostPreviewEmail(payload);
  };

  useEffect(() => {
    setInitialValues(stepsData);
  }, [stepsData]);

  const validationSchema = STEPS_SCHEMA;
  const validationSchemaEmail = EMAIL_SCHEMA;

  const [touches, setTouches] = useState(sequencesData.touches);

  useEffect(() => {
    setTouches(sequencesData.touches);
  }, [sequencesData.touches]);

  const handleSendEmail = (data: any) => {
    const payload = {
      subject: data.subject,
      body: data.content,
      to: data.to,
      sequence_id: sequencesData.id,
    };

    reqPostSendEmail(payload);
  };

  const handleOnSubmit = (data: any) => {
    const indexToUpdate = touches.findIndex(
      (touch: any) => touch.id === data.id
    );

    // Create a copy of touches array to avoid mutating state directly
    const updatedTouches = [...touches];

    if (indexToUpdate !== -1) {
      // Overwrite the existing touch
      updatedTouches[indexToUpdate] = data;
    } else {
      // Append data to touches
      updatedTouches.push({ ...data, touch_order: touches.length });
    }

    // Update state with the new array
    setTouches(updatedTouches);

    const params = updatedTouches.map((val) => {
      return {
        subject: val.subject,
        subject_remote: val.subject_remote,
        content: val.content,
        order: val.touch_order,
        days_to_wait: val.days_to_wait,
      };
    });

    reqPostUpdateTouchesSequence({
      data: { touches: params },
      id: sequencesData.id,
    });
  };

  const handleDeleteStep = (data: any) => {
    const updatedTouches = touches.filter((touch: any) => touch.id !== data.id);

    setTouches(updatedTouches);

    const params = updatedTouches.map((val: any) => {
      return {
        subject: val.subject,
        subject_remote: val.subject_remote,
        content: val.content,
        order: val.touch_order,
        days_to_wait: val.days_to_wait,
      };
    });

    reqPostUpdateTouchesSequence({
      data: { touches: params },
      id: sequencesData.id,
    });
  };

  useEffect(() => {
    if (resPostUpdateTouchesSequence.isSuccess) {
      reqGetSequenceData({ id: sequencesData.id });
      handleReset();
    }
  }, [resPostUpdateTouchesSequence.isSuccess]);

  useEffect(() => {
    if (resPostUpdateTouchesSequence.isSuccess) {
      toast({
        title: 'Email step saved.',
        description: "We've saved the email step.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostUpdateTouchesSequence.isSuccess]);

  useEffect(() => {
    if (resPostSendEmail.isSuccess) {
      toast({
        title: 'Email preview sent.',
        description: "We've emailed a template.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostSendEmail.isSuccess]);

  return {
    stepsData,
    sequencesData,
    initialValues,
    validationSchema,
    handleDeleteStep,
    handleOnSubmit,
    handleSendEmail,
    isLoading:
      resPostUpdateTouchesSequence.isLoading || resPostSendEmail.isLoading,
    isSpinner: resPostPreviewEmail.isLoading,
    intialValueEmail,
    validationSchemaEmail,
    previewData,
    handlePreview,
  };
};
