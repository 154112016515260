import { useState } from 'react';
import { useSelector } from 'react-redux';

export function useActivityLogServices() {
  const { inboxData } = useSelector((state: any) => state.inbox);

  const activities = inboxData?.data?.candidate?.actvities;

  const [hasView, setView] = useState({});

  return { activities, hasView, setView };
}
