import { swalContent } from "types";

export const tableHeader: { label: string }[] = [
  { label: 'File Name' },
  { label: 'Uploaded By' },
  { label: 'Uploaded On' },
  { label: '' },
];

export const content: swalContent = {
  title: 'Are you sure?',
  text: 'Once deleted, you will not be able to recover this file.',
  buttons: ['Cancel', true],
  icon: 'warning',
};
