import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { Field, FieldProps } from 'formik';
import { InitialValueType } from '../type';

export default function LeadDetails() {
  return (
    <>
      <Flex gap="32px" mb="34px">
        <Field name="first_name">
          {({
            form: { errors, touched, values, handleChange },
          }: FieldProps<InitialValueType>) => (
            <FormControl
              isInvalid={Boolean(!!errors.first_name && touched.first_name)}
              isRequired
            >
              <FormLabel>First Name</FormLabel>
              <Input
                name="first_name"
                type="text"
                placeholder="First Name"
                value={values.first_name}
                onChange={handleChange}
              />
              <FormErrorMessage>{String(errors.first_name)}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Field name="last_name">
          {({
            form: { errors, touched, values, handleChange },
          }: FieldProps<InitialValueType>) => (
            <FormControl
              isInvalid={Boolean(!!errors.last_name && touched.last_name)}
              isRequired
            >
              <FormLabel>Last Name</FormLabel>
              <Input
                name="last_name"
                type="text"
                placeholder="Last Name"
                value={values.last_name}
                onChange={handleChange}
              />
              <FormErrorMessage>{String(errors.last_name)}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
      </Flex>

      <Flex gap="32px" mb="34px">
        <Field name="personal_phone">
          {({
            form: { errors, touched, values, handleChange },
          }: FieldProps<InitialValueType>) => (
            <FormControl
              isInvalid={Boolean(
                !!errors.personal_phone && touched.personal_phone
              )}
            >
              <FormLabel>Personal Phone</FormLabel>
              <Input
                name="personal_phone"
                type="text"
                placeholder="Personal Phone"
                value={values.personal_phone}
                onChange={handleChange}
              />
              <FormErrorMessage>
                {String(errors.personal_phone)}
              </FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Flex width="100%" gap="32px">
          <Field name="work_phone">
            {({
              form: { errors, touched, values, handleChange },
            }: FieldProps<InitialValueType>) => (
              <FormControl
                isInvalid={Boolean(!!errors.work_phone && touched.work_phone)}
              >
                <FormLabel>Work Phone</FormLabel>
                <Input
                  name="work_phone"
                  type="text"
                  placeholder="Work Phone"
                  value={values.work_phone}
                  onChange={handleChange}
                />
                <FormErrorMessage>{String(errors.work_phone)}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Flex width="30%">
            <Field name="extension">
              {({
                form: { errors, touched, values, handleChange },
              }: FieldProps<InitialValueType>) => (
                <FormControl
                  isInvalid={Boolean(!!errors.extension && touched.extension)}
                >
                  <FormLabel>Extension</FormLabel>
                  <Input
                    name="extension"
                    type="text"
                    placeholder="Ext"
                    value={values.extension}
                    onChange={handleChange}
                  />
                  <FormErrorMessage>
                    {String(errors.extension)}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Flex>
        </Flex>
      </Flex>

      <Flex gap="32px" mb="40px">
        <Field name="email">
          {({
            form: { errors, touched, values, handleChange },
          }: FieldProps<InitialValueType>) => (
            <FormControl
              isInvalid={Boolean(!!errors.email && touched.email)}
              isRequired
            >
              <FormLabel>Email</FormLabel>
              <Input
                name="email"
                type="text"
                placeholder="Email"
                value={values.email}
                onChange={handleChange}
              />
              <FormErrorMessage>{String(errors.email)}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Field name="linkedIn_url">
          {({
            form: { errors, touched, values, handleChange },
          }: FieldProps<InitialValueType>) => (
            <FormControl
              isInvalid={Boolean(!!errors.linkedIn_url && touched.linkedIn_url)}
              isRequired
            >
              <FormLabel> LinkedIn Link</FormLabel>
              <Input
                name="linkedIn_url"
                type="text"
                placeholder="LinkedIn Link"
                value={values.linkedIn_url}
                onChange={handleChange}
              />
              <FormErrorMessage>{String(errors.linkedIn_url)}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
      </Flex>
    </>
  );
}
