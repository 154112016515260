/* eslint-disable react-hooks/exhaustive-deps */
import { useToast, useDisclosure } from '@chakra-ui/react';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  useListLeadsMutation,
  useGetLeadsMutation,
  useInactiveLeadsMutation,
  useReActivateMutation,
  useCancelApprovalMutation,
  listLeads,
  getLead,
  useBulkInactiveMutation,
} from 'store/leads.slice';
import { LeadsTypes, swalContent } from 'types';
import { AtsConfirm } from 'utils/swal';

export function useTableServices() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const toast = useToast();

  const { leads, leadPage, leadPgBtn } = useSelector(
    (state: any) => state.leads
  );

  const [reqLeads, resLeads] = useListLeadsMutation();
  const [reqGetLead] = useGetLeadsMutation();
  const [reqInactiveLead, resInactiveLead] = useInactiveLeadsMutation();
  const [reqBulkInactive, resBulkInactive] = useBulkInactiveMutation();
  const [reqActivate, resActivate] = useReActivateMutation();
  const [reqCancelApproval, resCancelApproval] = useCancelApprovalMutation();

  const [rowId, setRowId] = useState(1);
  const [isSendApproval, setIsSendApproval] = useState(false);

  const [isSorted, setIsSorted] = useState(false);
  const [sortOrder, setSortOrder] = useState(true);
  const [orderBy, setOrderBy] = useState(null);

  document.documentElement.dir = 'ltr';

  const {
    isOpen: isOpenDrawer,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure();
  const {
    isOpen: isEditLeadOpen,
    // onOpen: onEditLeadOpen,
    onClose: onEditLeadClose,
  } = useDisclosure();

  const {
    isOpen: isConvertClientOpen,
    onOpen: onConvertClientOpen,
    onClose: onConvertClientClose,
  } = useDisclosure();

  const {
    isOpen: isConvertLeadOpen,
    onOpen: onConvertLeadOpen,
    onClose: onConvertLeadClose,
  } = useDisclosure();

  useEffect(() => {
    const leadID =
      leads.findIndex((x: LeadsTypes) => x.id === Number(params.leadsId)) + 1;
    setRowId(leadID);
  }, [leads, params.leadsId]);

  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchLeads = async (param: object) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    reqLeads({ data: param, signal: abortControllerRef.current.signal });
  };

  useEffect(() => {
    if (isSorted) {
      const params = {
        ...leadPgBtn,
        ...(orderBy !== null && {
          orderBy: {
            column_name: orderBy?.column_name,
            sort_order: orderBy?.sort_order,
          },
        }),
      };

      dispatch(
        listLeads({
          leadPgBtn: params,
        })
      );
      fetchLeads(params);
      setIsSorted(false);
    }
  }, [isSorted]);

  useEffect(() => {
    if (resActivate.isSuccess) {
      fetchLeads(leadPgBtn);

      try {
        let title = '';
        let description = '';
        let status: 'error' | 'warning' | 'success' | 'info' = 'success';

        if (
          resActivate.isSuccess &&
          resActivate.data.data.message.includes('Lead has been reactivated')
        ) {
          title = 'Mark as Active';
          description = 'Lead status was changed to Active';
        }

        if (
          resActivate.isSuccess &&
          resActivate.data.data.message.includes('Failed to reactivate')
        ) {
          title = 'Failed to reactivate';
          description = 'No logs found to retrace it. Status still Inactive';
          status = 'warning';
        }

        toast({
          title: title,
          description: description,
          status: status,
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      } catch (e) {
        console.log('error', e);
      }
    }
  }, [resActivate.isSuccess]);

  useEffect(() => {
    if (resInactiveLead.isSuccess || resBulkInactive.isSuccess) {
      fetchLeads(leadPgBtn);

      try {
        let title = '';
        let description = '';
        let status: 'error' | 'warning' | 'success' | 'info' = 'success';

        if (
          resInactiveLead.isSuccess &&
          resInactiveLead.data.data.message.includes(
            'Lead has been deactivated'
          )
        ) {
          title = 'Mark as Inactive';
          description = 'Lead status was changed to Inactive';
          status = 'info';
        }

        if (resBulkInactive.isSuccess) {
          title = 'Mark as Inactive';
          description = 'Lead status were changed to Inactive';
          status = 'info';
          setIsCheck([]);
        }

        toast({
          title: title,
          description: description,
          status: status,
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      } catch (e) {
        console.log('error', e);
      }
    }
  }, [resInactiveLead.isSuccess, resBulkInactive.isSuccess]);

  useEffect(() => {
    if (resCancelApproval.isSuccess) {
      fetchLeads(leadPgBtn);

      try {
        let title = '';
        let description = '';
        let status: 'error' | 'warning' | 'success' | 'info' = 'success';

        if (
          resCancelApproval.isSuccess &&
          resCancelApproval.data.data.message.includes(
            'Approval has been canceld'
          )
        ) {
          title = 'Cancel Approval';
          description = 'Lead approval was cancelled';
          status = 'info';
        }

        if (
          resCancelApproval.isSuccess &&
          resCancelApproval.data.data.message.includes(
            'Failed to cancel Approval'
          )
        ) {
          title = 'Failed to cancel Approval';
          description = 'There is no logs to retrace';
          status = 'warning';
        }

        toast({
          title: title,
          description: description,
          status: status,
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      } catch (e) {
        console.log('error', e);
      }
    }
  }, [resCancelApproval.isSuccess]);

  // ONLY OPEN DRAWER
  // GET LEAD WILL BE EXECUTED AT DRAWER COMPONENT
  useEffect(() => {
    if (params.leadsId && !leadPgBtn.is_repeat_prospect) {
      onOpenDrawer();
    }
  }, [params.leadsId, leadPgBtn.is_repeat_prospect]);

  const handleClick = (lead: any) => {
    onOpenDrawer();
    navigate(`/leads/${lead.id}/details`);

    const key = leads.findIndex((x: LeadsTypes) => x.id === lead.id) + 1;
    setRowId(key);
  };

  const handleBulkInactive = async () => {
    let listId = [] as number[];
    const confirm = await AtsConfirm(content);
    if (confirm) {
      try {
        if (leads.length > 0) {
          leads.map((lead: any) => {
            if (isCheck.includes(lead.id)) {
              listId.push(lead.id);
            }
          });
          if (listId.length > 0) {
            const data = {
              idList: listId,
            };
            await reqBulkInactive({ data });
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  let content: swalContent = {
    title: 'Are you sure?',
    text: 'You are about to mark this lead as inactive, continue?',
    buttons: ['Cancel', true],
    icon: 'warning',
  };

  const handleInactive = async (lead: LeadsTypes) => {
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqInactiveLead({ id: lead.id });
      dispatch(getLead({ leadData: {} }));
    }
  };

  const stateTab: any = params.leadsTab;

  const handlePrevData = () => {
    const leadID = leads[rowId - 1 - 1].id;
    setRowId((prevKey: number) => prevKey - 1);
    navigate(`/leads/${leadID}/${stateTab}`);
  };

  const handleNextData = () => {
    const leadID = leads[rowId - 1 + 1].id;
    setRowId((prevKey: number) => prevKey + 1);
    navigate(`/leads/${leadID}/${stateTab}`);
  };

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [isIndeterminate, setisIndeterminate] = useState(false);

  const handleSelectAll = (e: any) => {
    const { checked } = e.target;
    setIsCheckAll(checked);
    setIsCheck(leads.map((li: any) => li.id));

    if (!checked) {
      setIsCheck([]);
    }
  };

  const handleCheck = async (e: any) => {
    const { id, checked } = e.target;
    let ID = parseInt(id);
    setIsCheck([...isCheck, ID]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== ID));
    }
  };

  const handleSort = async (column_name: string) => {
    const newSortOrder = sortOrder ? 'ASC' : 'DESC';
    // console.log('click handle Sort');
    setSortOrder(!sortOrder);
    setIsSorted(true);
    setOrderBy({
      column_name: column_name,
      sort_order: newSortOrder,
    });
  };

  useEffect(() => {
    setisIndeterminate(false);
    if (isCheck.length > 0 && isCheck.length < leadPgBtn.take) {
      setisIndeterminate(true);
    }
    if (isCheck.length <= 0) {
      setIsCheckAll(false);
    }
    if (isCheck.length === leadPgBtn.take) {
      setIsCheckAll(true);
    }
  }, [isCheck, isCheckAll, leadPgBtn.take]);

  const pageChange = async (page: number) => {
    let newParam = {
      ...leadPgBtn,
      page: page,
    };
    dispatch(
      listLeads({
        leadPgBtn: newParam,
      })
    );
    fetchLeads(newParam);
  };

  const converClientClick = (lead: any) => {
    dispatch(getLead({ leadData: lead }));
    onConvertClientOpen();
  };

  const sendForApproval = (lead: any) => {
    dispatch(getLead({ leadData: lead }));
    setIsSendApproval(true);
    onConvertLeadOpen();
  };

  const cancelApprovalClick = async (lead: any) => {
    content.text = 'You are about to cancel this approval, continue?';
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqCancelApproval({ id: lead.id });
    }
  };

  const marAsActiveClick = async (lead: any) => {
    content.text = 'You are about to mark this lead as active, continue?';
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqActivate({ id: lead.id });
    }
  };

  const entryChange = (entries: number) => {
    let newParam = {
      // page: leadPgBtn.page,
      ...leadPgBtn,
      page: 1,
      take: entries,
    };
    dispatch(
      listLeads({
        leadPgBtn: newParam,
      })
    );
    fetchLeads(newParam);
  };

  return {
    isCheck,
    isIndeterminate,
    isCheckAll,
    leads,
    handleCheck,
    handleClick,
    handleSelectAll,
    handleSort,
    converClientClick,
    handleInactive,
    cancelApprovalClick,
    sendForApproval,
    marAsActiveClick,
    isOpenDrawer,
    onCloseDrawer,
    rowId,
    handlePrevData,
    handleNextData,
    isConvertClientOpen,
    onConvertClientClose,
    isConvertLeadOpen,
    onConvertLeadClose,
    isSendApproval,
    setIsSendApproval,
    leadPage,
    leadPgBtn,
    isEditLeadOpen,
    onEditLeadClose,
    pageChange,
    entryChange,
    handleBulkInactive,
  };
}
