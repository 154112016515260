import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from './api.slice';
import { appError } from './app.slice';

export const tagSlice = createSlice({
  name: 'tags',
  initialState: {
    tags: [],
    tagsPageBtn: {
      page: 1,
      take: 10,
      query: '',
    },
  },
  reducers: {
    listTags: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { listTags } = tagSlice.actions;
export default tagSlice.reducer;

const tagApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    tagList: builder.mutation({
      query: (data) => ({
        url: `/tags/search`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listTags({ tags: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    tagListing: builder.mutation({
      query: (data) => ({
        url: `/tags/list`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listTags({ tags: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const { useTagListMutation, useTagListingMutation } = tagApiSlice;
