import { useTheme } from '@chakra-ui/react';
import { GroupBase } from 'react-select';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';
import { ATSTheme } from 'theme/theme';

interface DefaultOptionSelectPropsType<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends CreatableProps<Option, IsMulti, Group> {
  isError?: boolean;
  CreateComponent?: React.ReactNode;
  field?: any;
  onChange?: any;
  loadOptions?: any;
  onInputChange?: any;
}
export default function AtsCraetableSelDyn<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(FieldProps: DefaultOptionSelectPropsType<Option, IsMulti, Group>) {
  const { colors } = useTheme<ATSTheme>();
  return (
    <CreatableSelect
      {...FieldProps.field}
      onChange={(option) => {
        // FieldProps.form.setFieldValue(FieldProps.field.name, option);
        if (typeof FieldProps.onChange === 'function') {
          FieldProps.onChange(option);
        }
      }}
      className={FieldProps.className}
      options={FieldProps.options}
      placeholder={FieldProps.placeholder}
      defaultValue={FieldProps.defaultValue}
      value={FieldProps.value}
      isMulti={FieldProps.isMulti}
      onInputChange={FieldProps.onInputChange}
      isClearable={FieldProps.isClearable}
      //   isDisabled={FieldProps.isLoading}
      isLoading={FieldProps.isLoading}
      loadOptions={FieldProps.loadOptions}
      noOptionsMessage={() =>
        FieldProps.isLoading ? 'Loading...' : 'No options found'
      }
      formatCreateLabel={FieldProps.formatCreateLabel}
      styles={{
        control: (styles, { isFocused }) => ({
          ...styles,
          fontSize: '14px',
          borderWidth: '1.5px',
          borderColor: isFocused
            ? colors.primary[800]
            : FieldProps?.isError
            ? ' #E53E3E'
            : '#E7E9ED',
          borderRadius: '4px',
          cursor: 'pointer',
          boxShadow: 'none',
          '&:active': {
            borderColor: colors.primary[800],
            outlineWidth: 2,
          },
          '&:hover': {
            ...styles[':hover'],
          },
          '& ::-webkit-scrollbar': {
            display: 'none',
          },
        }),
        option: (styles, { isSelected, isFocused, isDisabled }) => ({
          ...styles,
          background: isSelected
            ? colors.primary[800]
            : isFocused
            ? colors.primary[100]
            : styles.color,
          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled
              ? isSelected
                ? styles.color
                : colors.primary[200]
              : undefined,
          },
          cursor: isDisabled ? 'not-allowed' : 'default',
        }),
        valueContainer: (styles: any) => ({
          ...styles,
          maxHeight: '33px',
          overflowY: 'scroll',
        }),
        dropdownIndicator: (styles: any) => ({
          ...styles,
          color: '#676767',
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        placeholder: (styles: any) => ({
          ...styles,
          fontSize: '14px',
          color: '#9B9B9B',
        }),
      }}
      components={FieldProps.components}
    />
  );
}
