import { Text } from '@chakra-ui/react';
import { countInt } from './interface';

const LoadingTab = ({ count }: countInt) => {
  const nan2Zero = (value: any) => {
    return isNaN(value) ? 0 : value;
  };

  return (
    <Text
      sx={{
        bgColor: '#6930CA14',
        borderRadius: '2px',
        px: '4px',
        fontSize: '12px',
        fontWeight: 700,
        color: '#6930CA',
      }}
    >
      {nan2Zero(count)}
    </Text>
  );
};
export default LoadingTab;
