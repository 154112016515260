import {
  Box,
  Flex,
  Icon,
  Text,
  useDisclosure,
  Spinner,
} from '@chakra-ui/react';
import FAIcon from 'components/lib/FAIcon';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  useListCandidateFilterMutation,
  usePinCandidateFilterMutation,
  usePinListCandidateFilterMutation,
  useUnPinCandidateFilterMutation,
} from 'store/candidatefilter.slice';
import { listCandidates } from 'store/candidates.slice';
import CandidateViewModal from '../Candidates/Modals/CandidateView';
import { BsPinAngleFill, BsPinAngle } from 'react-icons/bs';
import { FaPencilAlt } from 'react-icons/fa';

const CustomOption = ({
  label,
  value,
  item,
  onEdit,
  innerProps,
  onPinChange,
  loadingStates = null,
  isActive,
}: {
  label: string;
  value: number;
  item?: any;
  onEdit: () => void;
  innerProps: any;
  onPinChange?: () => void;
  loadingStates?: any;
  isActive: number;
}) => {
  const dispatch = useDispatch();
  const { candidatePgBtn } = useSelector((state: any) => state.candidates);
  return (
    <Flex
      sx={{
        fontFamily: 'NunitoSans Regular',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: '12px 24px',
        cursor: 'pointer',
        backgroundColor:
          label === 'Create New View'
            ? 'rgba(239, 229, 255, 0.4)'
            : isActive === value
            ? '#a475f4'
            : '#fff',
        color: isActive === value && '#fff',
      }}
      _hover={{
        backgroundColor: 'rgba(239, 229, 255, 0.4)',
        color: '#1B202B',
      }}
    >
      {label === 'Create New View' ? (
        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            gap: '12px',
            fontSize: '12px',
            fontWeight: 600,
            color: 'primary.800',
            margin: '0 auto',
          }}
          onClick={(e) => {
            innerProps.onClick(e);
          }}
        >
          <FAIcon iconName="plus" />
          <Text sx={{ textAlign: 'center' }}>{label}</Text>
        </Flex>
      ) : (
        <Box
          sx={{
            fontSize: '12px',
            color:
              isActive !== value && item?.pin ? 'primary.800' : 'secondarytext',
            fontWeight: 600,
          }}
          onClick={(e) => {
            innerProps.onClick(e);
            let params = {
              // CLEARS FILTER
              job_filter: '',
              job_filter_include: 'Current',
              location: '',
              tags: [] as any,
              skills: [] as any,
              experience: [] as any,
              work_status: null as any,
              job_id: null as any,
              excluded_job_id: null as any,
              status: null as any,
              job_status: null as any,
              resume_updated_on: null as any,
              search: '',
              boolean: true,
              reject_reason: null as any,
              source: null as any,
              sub_source: null as any,
              page: 1,
              take: 50,
              has_resume: false,
              query: '',
              // SAVES NEW FILTER
              // filter: label == 'All Candidates' ? null : value,
              filter: value || null,
              zip_code: null as any,
            };

            dispatch(
              listCandidates({
                candidatePgBtn: params,
              })
            );
            localStorage.setItem('candidatesFilter', JSON.stringify(params));
          }}
        >
          <Text
            sx={{
              maxWidth: '130px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {label}
          </Text>
        </Box>
      )}
      {label !== 'Create New View' && (
        <Flex ml="20px" gap="15px" alignItems="center">
          {loadingStates[value] ? (
            <Box sx={{ cursor: 'pointer', color: 'primary.800' }}>
              <Spinner size="sm" speed="0.65s" />
            </Box>
          ) : (
            <Box
              sx={{
                // display: label === "All Candidates" && 'none',
                cursor: 'pointer',
                color: item?.pin ? 'primary.800' : 'gray.600',
                fontSize: '12px',
              }}
              onClick={onPinChange}
            >
              {item?.pin ? (
                <Icon as={BsPinAngleFill} />
              ) : (
                <Icon as={BsPinAngle} />
              )}
            </Box>
          )}
          {/* {label !== 'All Candidates' ? ( */}
          <Box
            sx={{ cursor: 'pointer', color: 'gray.600', fontSize: '12px' }}
            onClick={onEdit}
          >
            <Icon as={FaPencilAlt} />
          </Box>
          {/* ) :
           (
            <Box sx={{ visibility: 'hidden', fontSize: '12px' }}>
              <Icon as={FaPencilAlt} />
            </Box>
          )} */}
        </Flex>
      )}
    </Flex>
  );
};

export default function AtsSelectCandidateView(FieldProps: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [candidateViewData, setCandidateViewData] = useState();
  const [options, setOptions] = useState([]);
  const [filterId, setFilterId] = useState(0);
  const [editFilter, setEditFilter] = useState(false);

  const { candidatePgBtn } = useSelector((state: any) => state.candidates);
  const { candidate_filters } = useSelector(
    (state: any) => state.candidateFilters
  );
  const [loadingStates, setLoadingStates] = useState({});

  const setLoadingState = (optionValue: any, isLoading: any) => {
    setLoadingStates((prevStates) => ({
      ...prevStates,
      [optionValue]: isLoading,
    }));
  };

  const [reqList] = useListCandidateFilterMutation();
  const [reqPinList] = usePinListCandidateFilterMutation();

  const [reqPin, resPin] = usePinCandidateFilterMutation();
  const [reqUnpin, resUnpin] = useUnPinCandidateFilterMutation();

  const abortFilterControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    reqList(candidatePgBtn);
    if (abortFilterControllerRef.current) {
      abortFilterControllerRef.current.abort();
    }

    abortFilterControllerRef.current = new AbortController();
    reqPinList({
      data: candidatePgBtn,
      signal: abortFilterControllerRef.current.signal,
    });
  }, []);
  useEffect(() => {
    if (resPin.isSuccess || resUnpin.isSuccess) {
      reqList(candidatePgBtn);
      if (abortFilterControllerRef.current) {
        abortFilterControllerRef.current.abort();
      }

      abortFilterControllerRef.current = new AbortController();
      reqPinList({ signal: abortFilterControllerRef.current.signal });
    }
  }, [resPin.isSuccess, resUnpin.isSuccess]);

  useEffect(() => {
    let options: any = [];
    // options.push({
    //   label: 'All Candidates',
    //   value: null,
    // });

    if (candidate_filters?.length > 0) {
      // candidate_filters.map((item: any) => {
      //   if (item.title === 'All Candidates') {
      //     options.push({
      //       data: item,
      //       label: item.title,
      //       value: item.id,
      //       group: item?.group_by_job,
      //     });
      //     return;
      //   }
      // });

      candidate_filters.map((item: any) => {
        if (item.title !== 'All Candidates') {
          options.push({
            data: item,
            label: item.title,
            value: item.id,
            group: item?.group_by_job,
          });
        }
      });
    }
    options.push({
      label: 'Create New View',
      value: null,
      group: false,
    });
    setOptions(options);
  }, [candidate_filters]);

  interface editOptionInt {
    data: any;
    edit?: boolean;
    id?: number;
  }

  interface pinInterface {
    id: number;
    pinned: boolean;
  }

  const handleEditOption = ({ data, edit = false, id = 0 }: editOptionInt) => {
    const format = formatData(data?.data);
    setEditFilter(edit);
    setFilterId(id);
    setCandidateViewData(format?.data || {});
    // if (abortFilterControllerRef.current) {
    //   abortFilterControllerRef.current.abort();
    // }

    // abortFilterControllerRef.current = new AbortController();
    // reqPinList({ signal: abortFilterControllerRef.current.signal });
    onOpen();
  };

  const [filters, setFilters] = useState([]);

  useEffect(() => {
    const candidatedFilterPinData: string | null = null;
    // localStorage.getItem(
    //   'candidatesFilterPin'
    // );

    setFilters(
      candidatedFilterPinData === null
        ? []
        : JSON.parse(candidatedFilterPinData)
    );
  }, [candidate_filters]);

  const handlePin = ({ id, pinned }: pinInterface) => {
    setLoadingState(id, true);

    if (pinned) {
      reqUnpin({ id }).then(() => {
        setLoadingState(id, false); // Clear loading state
      });
      const updatedFilters = filters.filter((filter) => filter.id !== id);

      // localStorage.setItem(
      //   'candidatesFilterPin',
      //   JSON.stringify(updatedFilters)
      // );
      setFilters(updatedFilters);
    } else {
      const filterToAdd = candidate_filters.find(
        (candidateFilter: any) => candidateFilter.id === id
      );
      const filterExists = filters.some((filter: any) => filter.id === id);

      if (!filterExists && filterToAdd) {
        // localStorage.setItem(
        //   'candidatesFilterPin',
        //   JSON.stringify([...filters, filterToAdd])
        // );
      }

      reqPin({ id }).then(() => {
        setLoadingState(id, false); // Clear loading state
      });
    }
  };

  const formatData = (data: any) => {
    try {
      let modules: any = [];
      let users: any = [];
      let operators: any = [];
      if (data.condition?.length > 0) {
        data?.condition.map((item: any) => {
          modules.push({
            criteria: item?.column?.label,
            condition: item?.condition.label,
            query: item?.condition_value,
          });
          if (item?.condition_operator != '' && item?.condition_operator) {
            operators.push({
              id: 0,
              operator: item?.condition_operator,
            });
          }
        });
      }

      if (data?.users?.length > 0) {
        data?.users.map((item: any) => {
          users.push({
            value: item?.user?.id,
            label: [item?.user?.first_name, item?.user?.last_name].join(' '),
          });
        });
      }

      let columns = {
        candidate_details: data.candidate_details,
        contact: data.contact,
        location: data.location,
        resume_update_on: data.resume_update_on,
        work_status: data.work_status,
        last_activity: data.last_activity,
        associated_jobs: data.associated_jobs,
        tags: data.tags,
        distance: data.distance,
        submitted_on: data.submitted_on,
        interview_status: data.interview_status,
        interview_date: data.interview_date,
        offered_date: data.offered_date,
        placement_date: data.placement_date,
        candidate_start_date: data.candidate_start_date,
        last_messaged_on: data.last_messaged_on,
        last_called_on: data.last_called_on,
      };
      let sortedColumns = Object.entries(columns)
        .filter(([key, value]) => value !== 0)
        .map(([key, value]) => ({ key, value }));
      sortedColumns.sort((a, b) => a.value - b.value);
      let newColumns = {} as any;
      sortedColumns.forEach((item: any) => (newColumns[item.key] = item.value));

      let format: any = {
        value: data?.id,
        label: data?.title,
        data: {
          title: data?.title,
          module: modules,
          share: data?.shareable,
          user: users,
          operators: operators,
          group_by_job: data.group_by_job,
          columns: newColumns,
        },
      };

      return format;
    } catch (e) {
      return null;
    }
  };

  return (
    <>
      <Select
        options={options}
        {...FieldProps.field}
        onChange={(option) => {
          if (typeof FieldProps.onChange === 'function') {
            FieldProps.onChange(option);
          }
        }}
        styles={{
          control: (styles) => ({
            ...styles,
            background: '#a475f4',
            border: '1px solid #E2E8F0',
            boxShadow: 'none',
            '&:hover': { border: '1px solid #E2E8F0' },
          }),
          singleValue: (styles) => ({
            ...styles,
            color: 'white',
            fontSize: '14px',
          }),
          dropdownIndicator: (styles) => ({
            ...styles,
            color: 'white',
          }),
          placeholder: (styles) => ({
            ...styles,
            color: 'white',
          }),
        }}
        components={{
          Option: ({ label, data, innerProps }) => (
            <div
              {...innerProps}
              onClick={(e) => {
                label === 'Create New View' && handleEditOption({ data });
              }}
            >
              <CustomOption
                label={label}
                value={(data as any)?.value}
                item={(data as any)?.data}
                onEdit={() =>
                  handleEditOption({
                    data,
                    edit: true,
                    id: Number((data as any)?.value),
                  })
                }
                innerProps={innerProps}
                onPinChange={() =>
                  handlePin({
                    id: Number((data as any)?.value),
                    pinned: (data as any)?.data?.pin ? true : false,
                  })
                }
                loadingStates={loadingStates}
                isActive={FieldProps.isActive}
              />
            </div>
          ),
        }}
        isMulti={FieldProps.isMulti}
        defaultValue={FieldProps.defaultValue}
        isLoading={FieldProps.isLoading}
        placeholder={FieldProps.placeholder}
        menuIsOpen={FieldProps.menuIsOpen}
        // styles={FieldProps.styles}
        value={FieldProps.value}
      />

      {isOpen && (
        <CandidateViewModal
          isOpen={isOpen}
          onClose={onClose}
          candidateViewData={candidateViewData}
          id={filterId}
          isEdit={editFilter}
        />
      )}
    </>
  );
}
