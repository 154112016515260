import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';

import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
} from '@chakra-ui/react';

import Button from 'Library/Button';
import FAIcon from 'components/lib/FAIcon';

import ScrollToFieldError from 'components/app/ScrollError';
import LoadingPage from 'components/app/Loading';
import TemplateGroupTable from './table';
import TemplateAddGroup from './modal/add';
import {
  listGroupTemplate,
  useListGroupMutation,
} from 'store/email_group.slice';

const GroupTemplate = () => {
  const {
    isOpen: groupAddOpen,
    onOpen: groupOnAddOpen,
    onClose: groupOnAddClose,
  } = useDisclosure();

  const dispatch = useDispatch();
  const [reqGroup, resGroup] = useListGroupMutation();

  const { groupPageBtn } = useSelector((state: any) => state.email_group);
  const initialValue = {
    search: '',
  };

  const submitData = (form: any) => {
    reqGroup(form);
    dispatch(listGroupTemplate({ groupPageBtn: form }));
  };

  const onSubmit = (data: any) => {
    const form = {
      page: 1,
      take: groupPageBtn.take,
      query: data.search,
    };
    submitData(form);
  };
  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      const form = {
        page: 1,
        take: groupPageBtn.take,
        query: '',
      };
      submitData(form);
    }
  };

  return (
    <Box fontFamily="NunitoSans Regular">
      <Flex justifyContent="space-between" px={6}>
        <Formik
          enableReinitialize
          initialValues={initialValue}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, handleSubmit }) => (
            <Flex
              justifyContent="space-around"
              maxWidth="40%"
              alignItems="center"
            >
              <Box pr={2}>
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />
                  <InputGroup background="#fff">
                    <InputRightElement
                      cursor="pointer"
                      children={<FAIcon iconName="search" />}
                    />
                    <Input
                      type="search"
                      placeholder="Search Group"
                      value={values.search}
                      id="search"
                      onChange={(e) => {
                        handleChange(e);
                        clearSearch(e);
                      }}
                    />
                  </InputGroup>
                </form>
              </Box>
            </Flex>
          )}
        </Formik>
        <Button leftIcon="plus" variant="solid" onClick={groupOnAddOpen}>
          Add Group
        </Button>
      </Flex>
      <Box>
        <TemplateGroupTable />
      </Box>
      {groupAddOpen && (
        <TemplateAddGroup isOpen={groupAddOpen} onClose={groupOnAddClose} />
      )}
    </Box>
  );
};

export default GroupTemplate;
