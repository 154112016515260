import moment from 'moment';

import {
  Box,
  TableContainer,
  Table,
  Thead,
  Link,
  Image,
  Tr,
  Th,
  Tbody,
  Td,
  Flex,
  Menu,
  MenuButton,
} from '@chakra-ui/react';

import Button from 'Library/Button';
import GoTo from 'assets/images/goto.svg';

import { StreamAttachmentModal } from '../../Attachments/Stream';

import { tableHeader } from './constants';
import { LeadAgreementAttachmentTypes } from 'types';
import { leadAgreementAttachmentInt } from './types';
import { useAgreementAttachmentsServices } from './useAgreementAttachmentsServices';

export const LeadAgreementAttachments = ({
  attachments,
}: leadAgreementAttachmentInt) => {
  const {
    onStreamOpen,
    setFileData,
    delAttachment,
    isStreamOpen,
    onStreamClose,
    fileData,
  } = useAgreementAttachmentsServices();
  return (
    <Box>
      <Box>
        <TableContainer
          boxSizing="border-box"
          border="1px solid"
          borderColor="default.white.400"
          borderRadius="md"
        >
          <Table>
            <Thead>
              <Tr bg="default.white.800">
                {tableHeader.map((title) => (
                  <Th key={title.label}>
                    <Box color="default.gray.600">{title.label}</Box>
                  </Th>
                ))}
              </Tr>
            </Thead>

            <Tbody
              boxSizing="border-box"
              background="default.white.100"
              borderBottom="1px solid"
              borderColor="default.white.400"
            >
              {attachments?.map(
                (item: LeadAgreementAttachmentTypes, key: number) => (
                  <Tr
                    key={item.original_file_name}
                    cursor="pointer"
                    onClick={() => {}}
                  >
                    <Td>
                      <Flex alignItems="center" color="primary.800">
                        <Link
                          style={{ display: 'flex', gap: '7px' }}
                          rel="noreferrer"
                          onClick={() => {
                            onStreamOpen();
                            setFileData(item);
                          }}
                        >
                          {item.original_file_name}
                          <span>
                            <Image src={GoTo} alt="logo" />
                          </span>
                        </Link>
                      </Flex>
                    </Td>
                    <Td></Td>
                    <Td>
                      {moment.utc(item.created_at).format('MM/DD/YYYY hh:mm A')}
                    </Td>
                    <Td>
                      <Menu>
                        <MenuButton onClick={() => delAttachment(item.id, key)}>
                          <Button
                            htmlType="menu"
                            variant="ghost"
                            iconName="trash"
                            iconSize="xl"
                          />
                        </MenuButton>
                      </Menu>
                    </Td>
                  </Tr>
                )
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      {isStreamOpen && (
        <StreamAttachmentModal
          isOpen={isStreamOpen}
          onClose={onStreamClose}
          file={fileData}
          route={'/lead-agreement/download/'}
        />
      )}
    </Box>
  );
};
