/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetEmailConnectionListMutation,
  usePostUpdateEmailConnectionMutation,
  updateOutreach,
} from 'store/outreach.slice';
import { EMAIL_CONNECTION_SCHEMA } from './constant';

export const useEmailConnectionModalServices = ({ isOpen, onClose }: any) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const [reqGetEmailConnectionList] = useGetEmailConnectionListMutation();
  const [reqPostUpdateEmailConnection, resPostUpdateEmailConnection] =
    usePostUpdateEmailConnectionMutation();

  const { emailConnectionData, emailConnectionPgBtn } = useSelector(
    (state: any) => state.outreach
  );

  const initialValues = {
    email: emailConnectionData.email,
    random_interval_from: emailConnectionData.random_interval_from,
    random_interval_to: emailConnectionData.random_interval_to,
    email_limit_per_day: emailConnectionData.email_limit_per_day,
    percentage_step_0: emailConnectionData.percentage_step_0,
    percentage_step_1: emailConnectionData.percentage_step_1,
    percentage_step_2: emailConnectionData.percentage_step_2,
    percentage_step_3: emailConnectionData.percentage_step_3,
    percentage_step_4: emailConnectionData.percentage_step_4,
    percentage_step_5: emailConnectionData.percentage_step_5,
    dns: emailConnectionData.dns,
    signature: emailConnectionData.signature,
  };

  const validationSchema = EMAIL_CONNECTION_SCHEMA;

  const handleOnSubmit = (data: any) => {
    const { email, ...remainingData } = data;

    reqPostUpdateEmailConnection({
      data: remainingData,
      id: emailConnectionData.id,
    });

    console.log(remainingData, 'xx submit');
    dispatch(
      updateOutreach({
        emailConnectionData: { ...emailConnectionData, ...remainingData },
      })
    );
  };

  useEffect(() => {
    if (resPostUpdateEmailConnection.isSuccess) {
      reqGetEmailConnectionList(emailConnectionPgBtn);
      onClose();

      toast({
        title: 'Email Connection updated.',
        description: "We've updated your email template.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostUpdateEmailConnection.isSuccess]);

  return {
    initialValues,
    validationSchema,
    isOpen,
    onClose,
    handleOnSubmit,
  };
};
