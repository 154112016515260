import {
  FormControl,
  Flex,
  FormLabel,
  Input,
  FormErrorMessage,
  Stack,
} from '@chakra-ui/react';

import { ErrorMessage, Field, FieldProps } from 'formik';
import { useAIGeneratorServices } from 'components/app/AIGenerator/useAIGeneratorServices';
import AtsEmailBody from 'components/app/Global/Email/AIBody';
import { IntialValueType } from '../../utils/validationSchema';
import Select from 'components/ui/Select/Select';
import { useSelector } from 'react-redux';
import { replacePlaceholders } from 'utils/placeholders';

export default function CustomizePitchFullJobForm() {
  const {
    hasAIGenerate,
    setHasAIGenerate,
    setIsGenerate,
    handleValues,
    handleAIGenerator,
    setIsError,
    isMaximize,
  } = useAIGeneratorServices({ hasType: 'Jobs' });

  const placeholderPair = useSelector(
    (state: any) => state.template.placehoderPair
  );

  return (
    <Field>
      {({
        form: { values, errors, touched, setFieldValue },
      }: FieldProps<IntialValueType>) => {
        return (
          <Stack justifyContent="space-between" h="inherit">
            <Flex
              h="inherit"
              justifyContent="center"
              gap="10px"
              sx={{
                overflow: 'hidden',
                '& #ai': {
                  m: 0,
                  overflowY: 'scroll',
                  h: 'inherit',
                },
                '& ::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              <Stack
                h="inherit"
                spacing="10px"
                sx={{
                  display: hasAIGenerate && isMaximize ? 'none' : 'flex',
                  w: hasAIGenerate !== '' ? '65%' : '100%',
                  mr: hasAIGenerate && 0,
                }}
              >
                <Stack
                  spacing="16px"
                  sx={{
                    background: '#fff',
                    borderRadius: '6px',
                    p: '16px',
                  }}
                >
                  <FormControl
                    isInvalid={Boolean(
                      !!errors.campaign_status && touched.campaign_status
                    )}
                  >
                    <FormLabel>Campaign Status</FormLabel>
                    <Field
                      name="campaign_status"
                      component={Select}
                      value={values.campaign_status}
                      onChange={(e: any) => setFieldValue('campaign', e)}
                      options={[
                        { label: 'Active', value: true },
                        {
                          label: 'Inactive',
                          value: false,
                        },
                      ]}
                    />

                    <ErrorMessage
                      name="subject"
                      render={(msg: any) => {
                        return (
                          <FormErrorMessage>{msg?.label}</FormErrorMessage>
                        );
                      }}
                    />
                  </FormControl>

                  <FormControl
                    isInvalid={Boolean(!!errors.subject && touched.subject)}
                  >
                    <FormLabel fontSize="14px" lineHeight="18px">
                      Subject
                    </FormLabel>

                    <Field
                      component={Input}
                      name="subject"
                      type="text"
                      placeholder="Email Subject"
                      variant="outline"
                      value={replacePlaceholders(
                        values.subject,
                        placeholderPair
                      )}
                      onChange={(e: any) => {
                        setFieldValue('subject', e.target.value);
                      }}
                    />

                    <ErrorMessage name="subject" component={FormErrorMessage} />
                  </FormControl>
                </Stack>

                <Stack
                  h="45vh"
                  sx={{
                    position: 'relative',
                    bgColor: '#FFF',
                    borderRadius: '6px',
                    p: '16px',
                    overflowY: 'hidden',
                    '& ::-webkit-scrollbar': {
                      display: 'none',
                    },
                  }}
                >
                  <Field
                    component={AtsEmailBody}
                    name="body"
                    value={values.body}
                    onChange={(e: any) => {
                      setFieldValue('body', e);
                    }}
                    height={220}
                    hasAIGenerate={hasAIGenerate}
                    setHasAIGenerate={setHasAIGenerate}
                    setIsGenerate={setIsGenerate}
                    handleValues={handleValues}
                    setIsError={setIsError}
                    subject={values.subject}
                    body={values.body}
                  />

                  <ErrorMessage name="body" component={FormErrorMessage} />
                </Stack>
              </Stack>

              {handleAIGenerator(setFieldValue)}
            </Flex>
          </Stack>
        );
      }}
    </Field>
  );
}
