import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Skeleton,
  SkeletonCircle,
  Stack,
  Td,
  Tr,
} from '@chakra-ui/react';

const FollowUpLoading = () => {
  return (
    <>
      <Flex>
        <Box
          width="60%"
          height="90vh"
          py={2}
          borderRight="1px solid"
          borderColor="default.borderlight"
          px={3}
          p={10}
        >
          <Box mb="10px">
            <Box width="100%" mb="12px">
              <Skeleton height="35px" />
            </Box>
          </Box>
          <Box mb="10px">
            <Box width="100%" mb="12px">
              <Skeleton height="35px" />
            </Box>
          </Box>
          <Box mb="10px">
            <Box width="100%" mb="12px">
              <Skeleton height="35px" />
            </Box>
          </Box>
        </Box>

        <Box width="40%" p="32px" sx={{ overflowY: 'scroll', height: '90vh' }}>
          <>
            <Box mb="10px">
              <Box width="100%" mb="12px">
                <Skeleton height="35px" />
              </Box>
            </Box>
            <Box mb="10px">
              <Box width="100%" mb="12px">
                <Skeleton height="35px" />
              </Box>
            </Box>
            <Box mb="10px">
              <Box width="100%" mb="12px">
                <Skeleton height="35px" />
              </Box>
            </Box>
            <Box mb="10px">
              <Box width="100%" mb="12px">
                <Skeleton height="35px" />
              </Box>
            </Box>
            <Box mb="34px">
              <Box width="100%" mb="12px">
                <Skeleton height="35px" />
              </Box>
            </Box>
            <Box mb="250px">
              <Box width="100%" mb="12px">
                <Skeleton height="250px" />
              </Box>
            </Box>
          </>
        </Box>
      </Flex>
    </>
  );
};

export default FollowUpLoading;
