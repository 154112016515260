import viewCandidateConfig from '.';

const candidatePayload = (param: string, candidatePgBtn: any) => {
  let {
    blastedCountParam,
    screenCountParam,
    submitCountParam,
    interviewCountParam,
    offersCountParam,
    hiredCountParam,
    rejectedCountParam,
  } = viewCandidateConfig(candidatePgBtn);

  const tabOptions = [
    'blasted',
    'screen',
    'submits',
    'interviews',
    'offers',
    'hired',
    'rejected',
  ];

  const payloads = [
    blastedCountParam,
    screenCountParam,
    submitCountParam,
    interviewCountParam,
    offersCountParam,
    hiredCountParam,
    rejectedCountParam,
  ];

  const tabIndex = tabOptions.indexOf(param);

  return payloads[tabIndex] || null;
};

export default candidatePayload;
