import { defineStyleConfig } from '@chakra-ui/styled-system';

export const Modal = defineStyleConfig({
  baseStyle: {
    fontWeight: 'bold',
    borderRadius: 'md',
    border: '1px solid',
  },
  variants: {
    form: {
      header: {
        background: 'default.white.800',
        borderRadius: '4px 4px 0',
        p: '18px 32px',
        fontSize: '18px',
        lineHeight: '21.92px',
        textAlign: 'left',
      },
      closeButton: {
        top: '13px',
        right: '20px',
        color: 'default.secondarytext',
      },
      body: {
        borderRadius: '0 0 4px 4px',
        p: 0,
      },
      footer: {
        position: 'sticky',
        bottom: '0',
        background: 'default.white.100',
        py: 2,
        px: 4,
        boxShadow: '0px -3px 7px rgba(0, 0, 0, 0.05)',
      },
      dialogContainer: {
        fontFamily: 'NunitoSans Regular',
      },
    },
  },

  sizes: {
    sm: {
      h: '400px',
      w: '4000px',
    },
    md: {
      h: '600px',
      w: '6000px',
    },
    lg: {
      minHeight: '800px',
      w: '800px',
    },
  },

  defaultProps: {
    size: 'lg',
  },
});
