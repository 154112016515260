import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import Button from 'Library/Button';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useMsGraphLoginMutation,
  useMsGraphLogoutMutation,
  useMsGraphMeMutation,
} from 'store/msgraph.slice';
import { swalContent } from 'types';
import { AtsConfirm } from 'utils/swal';

export default function MsGraph() {
  const dispatch = useDispatch();
  const [reqSignIn, resSignIn] = useMsGraphLoginMutation();
  const [reqMe, resMe] = useMsGraphMeMutation();
  const [errorMessage, setErrMsg] = useState('');
  const [reqLogout, resLogout] = useMsGraphLogoutMutation();

  const { msAccount } = useSelector((state: any) => state.msgraph);

  useEffect(() => {
    reqMe({});
  }, []);

  useEffect(() => {
    if (resMe.isError) {
      const error: any = resMe.error;
      setErrMsg(error?.data?.error?.message);
    } else {
      setErrMsg('');
    }
  }, [resMe]);

  useEffect(() => {
    if (resSignIn.isSuccess) {
      const res: any = resSignIn.data;
      window.location.href = res.data['url'];
    }
  }, [resSignIn.isSuccess]);

  useEffect(() => {}, [resSignIn.isError]);

  const signInClick = () => {
    reqSignIn({ data: [] });
  };

  useEffect(() => {
    if (resLogout.isSuccess) {
      reqMe({});
    }
  }, [resLogout.isSuccess]);

  const singOutClick = async () => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: `You are about to sign out to your ms graph account. Continue?`,
      buttons: ['Cancel', true],
      icon: 'info',
    };

    const confirm = await AtsConfirm(content);
    if (confirm) {
      reqLogout({});
    }
  };

  return (
    <Box
      fontFamily="NunitoSans Regular"
      padding="8px 16px"
      minHeight="88vh"
      textAlign="center"
    >
      {resMe.isError && (
        <>
          <Box mb="34px">
            <Alert
              status="error"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle>Oh snap!</AlertTitle>
              <AlertDescription>{errorMessage}</AlertDescription>
            </Alert>
          </Box>
          <Button loading={resSignIn.isLoading} onClick={() => signInClick()}>
            Sign In MS Graph
          </Button>
        </>
      )}
      {resMe.isSuccess && (
        <>
          <Box mb="34px">
            <Alert
              status="success"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                Success
              </AlertTitle>
              <AlertDescription maxWidth="sm">
                Your account is connected
              </AlertDescription>
            </Alert>
          </Box>
          <Box
            boxSizing="border-box"
            border="1px solid"
            borderColor="default.white.400"
            borderRadius="md"
            p="10"
            mb="34px"
            textAlign="left"
          >
            <Flex gap={5}>
              <Text as="b">Diplay Name : </Text>
              <Text>{msAccount?.isValid?.displayName}</Text>
            </Flex>
            <Flex gap={5}>
              <Text as="b">Given Name : </Text>
              <Text>{msAccount?.isValid?.givenName}</Text>
            </Flex>
            <Flex gap={5}>
              <Text as="b">Surname : </Text>
              <Text>{msAccount?.isValid?.surname}</Text>
            </Flex>
            <Flex gap={5}>
              <Text as="b">Email : </Text>
              <Text>{msAccount?.isValid?.mail}</Text>
            </Flex>
          </Box>
          <Box>
            <Button
              loading={resLogout.isLoading}
              onClick={() => singOutClick()}
            >
              Sign Out MS Graph
            </Button>
          </Box>
        </>
      )}
      {resMe.isLoading && (
        <Stack>
          <Skeleton height="80px"></Skeleton>
          <Skeleton height="80px"></Skeleton>
        </Stack>
      )}
    </Box>
  );
}
