import { Box, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import Button from 'Library/Button';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCandidate,
  useGetCandidatesMutation,
  useWorkStatusUpdateCandidatesMutation,
} from 'store/candidates.slice';

interface WorkStatusInt {
  candidateData: any;
  SetWorkStatusUpdate: any;
}

const WorkStatus = ({ candidateData, SetWorkStatusUpdate }: WorkStatusInt) => {
  const { candidate_work_status } = useSelector(
    (state: any) => state.constants
  );

  const dispatch = useDispatch();

  const [workStatusLabel, setWorkStatusLabel] = useState('');
  const [reqWorkStatusUpdate, resWorkStatusUpdate] =
    useWorkStatusUpdateCandidatesMutation();
  const [reqGetCandidate] = useGetCandidatesMutation();

  useEffect(() => {
    if (candidate_work_status?.length > 0) {
      const status = candidate_work_status.filter(
        (val: any) => val.id === (candidateData?.work_status_id || 4)
      )[0].status;
      setWorkStatusLabel(status);
    }
  }, [candidateData]);

  useEffect(() => {
    if (resWorkStatusUpdate.isSuccess) {
      reqGetCandidate({ id: candidateData?.id });
      SetWorkStatusUpdate(true);
    }
  }, [resWorkStatusUpdate.isSuccess]);

  const workStatusChange = async (val: any) => {
    setWorkStatusLabel(val.status);

    const newData = {
      ...candidateData,
      work_status: val,
      work_status_id: val.id,
    };
    await dispatch(getCandidate({ candidateData: newData }));

    await reqWorkStatusUpdate({
      data: {
        work_status_id: val.id,
      },
      id: candidateData?.id,
    });
  };

  return (
    <>
      <Box
        fontSize="16px"
        lineHeight="18px"
        color="default.gray.600"
        textAlign="left"
        width="100px"
      >
        Work Status
      </Box>
      <Menu closeOnSelect={true}>
        <MenuButton>
          <Box
            sx={{
              button: {
                p: '4px 8px',
                pl: 0,
                height: '25px',
                fontSize: '14px',
                border: 'none',
                color: 'primary.500',
              },
            }}
          >
            <Button rightIcon="chevron-down">{workStatusLabel}</Button>
          </Box>
        </MenuButton>
        {
          <MenuList fontSize="sm" maxHeight="120px" overflowY="auto">
            {candidate_work_status.map((val: any, key: number) => {
              return (
                <MenuItem
                  onClick={async () => workStatusChange(val)}
                  key={`ws-${key}`}
                >
                  {val.status}
                </MenuItem>
              );
            })}
          </MenuList>
        }
      </Menu>
    </>
  );
};

export default WorkStatus;
