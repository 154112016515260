import './emailBody.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Editor } from '@tiptap/react';
import {
  Box,
  Button,
  Flex,
  Text,
  Wrap,
  WrapItem,
  Grid,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  Stack,
  HStack,
  Progress,
  TagLabel,
  Tag,
  Image as Image1,
} from '@chakra-ui/react';
import {
  Bold,
  Italic,
  Strikethrough,
  Underline,
  ListOrdered,
  List,
  AlignLeftIcon,
  AlignCenterIcon,
  AlignRightIcon,
  AlignJustifyIcon,
  Baseline,
  RemoveFormattingIcon,
  YoutubeIcon,
  Image,
  Link,
  Highlighter,
} from 'lucide-react';

import MdPlaceholder from 'assets/images/ai-generator/MdPlaceholder.svg';
import MdPdf from 'assets/images/ai-generator/MdPdf.svg';
import MdImage from 'assets/images/ai-generator/MdImage.svg';
import MdDocs from 'assets/images/ai-generator/MdDocs.svg';
import MdTrash from 'assets/images/ai-generator/MdTrash.svg';

import { AIGeneratorBar } from 'components/app/AIGenerator/Bar';
import { FaRegCopy } from 'react-icons/fa6';

import { usePlaceHoldersMutation } from 'store/template.slice';
import AtsDropZone from 'components/app/DropzoneV2';
import { UploadTypes } from 'types';
import { removeAttachment, removeUploaded } from 'store/uploads.slice';
import {
  mediaFormatLabel,
  pdfFormatLabel,
  docsFormatLabel,
} from 'constants/files';
import replacePlaceholders from 'utils/textPlaceholders';

interface ToolbarInterface {
  editor: Editor;
  content: string;
  disabled: boolean;
  allowedCategory: any;
}

export default function ToolBar({
  editor,
  content,
  disabled,
  allowedCategory = [
    'Candidates',
    'Jobs',
    'Interview',
    'Follow-up',
    'Signatures',
  ],
}: ToolbarInterface) {
  const dispatch = useDispatch();
  const colorInputRef = useRef(null);
  const highlightInputRef = useRef(null);

  const toast = useToast();

  const width = '640';
  const height = '480';

  const { placehodlers: placeholders } = useSelector(
    (state: any) => state.template
  );
  const { attachments } = useSelector((state: any) => state.uploads);

  const [reqPlaceHolder, resPlaceholder] = usePlaceHoldersMutation();
  const { placehoderPair } = useSelector((state: any) => state.template);

  const [placehoder, setPlaceHoders] = useState(placehoderPair);
  useEffect(() => {
    const load = async () => {
      await reqPlaceHolder({});
    };

    load();
  }, []);
  const placholders = (text: string) => {
    return replacePlaceholders(text, placehoder);
  };

  const placeHolderClick = (holder: string) => {
    const text = placholders(`{{${holder}}}`);
    const htmlContent = `<span data-variable="${holder}">{{${holder}}}</span>`;

    // Create a Blob with the HTML content
    const blob = new Blob([htmlContent], { type: 'text/html' });

    // Create a ClipboardItem from the Blob
    const clipboardItem = new ClipboardItem({ 'text/html': blob });

    // Write the ClipboardItem to the clipboard
    navigator.clipboard
      .write([clipboardItem])
      .then(() => {
        // Listen for the paste event on the document
        const pasteHandler = (event: any) => {
          // Get the target element
          const target = event.target as HTMLInputElement;

          // Check if the target is an input element
          if (target.tagName.toLowerCase() === 'input') {
            // Prevent the default paste behavior for input elements
            event.preventDefault();
            // Paste plain text only
            const plainText = text;

            // Get the current cursor position
            const start = target.selectionStart;
            const end = target.selectionEnd;

            // Set the value of the input to include the new text
            target.value =
              target.value.slice(0, start) +
              plainText +
              target.value.slice(end);

            // Update the cursor position
            target.setSelectionRange(
              start + plainText.length,
              start + plainText.length
            );
          } else if (target.isContentEditable) {
            // Prevent the default paste behavior for contenteditable
            event.preventDefault();
          }

          navigator.clipboard.writeText('').catch((err) => {
            console.error('Failed to clear clipboard: ', err);
          });

          // Remove the paste event listener after handling it
          document.removeEventListener('paste', pasteHandler);
        };

        document.addEventListener('paste', pasteHandler);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });

    toast({
      title: 'Placeholder copied',
      duration: 1000,
      position: 'top',
    });
  };

  const addYoutubeVideo = (e: any) => {
    e.preventDefault();
    const url = prompt('Enter YouTube URL');

    if (url) {
      editor.commands.setYoutubeVideo({
        src: url,
        width: Math.max(320, parseInt(width, 10)) || 640,
        height: Math.max(180, parseInt(height, 10)) || 480,
      });
    }
  };

  const setLink = useCallback(
    (e: any) => {
      e.preventDefault();
      const previousUrl = editor.getAttributes('link').href;
      const url = window.prompt('URL', previousUrl);

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (url === '') {
        editor.chain().focus().extendMarkRange('link').unsetLink().run();

        return;
      }

      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run();
    },
    [editor]
  );

  const addImage = useCallback(
    (e: any) => {
      e.preventDefault();

      const url = window.prompt('URL');

      if (url) {
        editor.chain().focus().setImage({ src: url }).run();
      }
    },
    [editor]
  );

  const handleColorChange = () => {
    if (colorInputRef.current) {
      colorInputRef.current.click();
    }
  };

  const handleHighlightChange = () => {
    if (highlightInputRef.current) {
      highlightInputRef.current.click();
    }
  };
  useEffect(() => {
    setPlaceHoders(placehoderPair);
  }, [placehoderPair]);
  if (!editor) {
    return null;
  }

  const activeColorScheme = '#1a202c';

  const iconAttributeProps = {
    width: '18px',
    height: '18px',
  };

  interface TagEleInt {
    data: UploadTypes;
    key: number;
  }

  const handleIcon = (name: string) => {
    const fileExtension = name.substring(name.lastIndexOf('.'));

    if (mediaFormatLabel.includes(fileExtension)) {
      return <Image1 src={MdImage} />;
    }
    if (docsFormatLabel.includes(fileExtension)) {
      return <Image1 src={MdDocs} />;
    }
    if (pdfFormatLabel.includes(fileExtension)) {
      return <Image1 src={MdPdf} />;
    }
  };

  const TagElement = ({ data, key }: TagEleInt) => {
    return (
      <Tag style={{ display: 'inline-block' }} key={data?.id} mr="10px">
        <Stack spacing={1}>
          {data.uploading && (
            <Progress value={data.progress} style={{ height: '5px' }} />
          )}
          <HStack sx={{ p: '8px' }}>
            {handleIcon(data.name || data.original_file_name)}
            <TagLabel maxW="100px">
              {data.name || data.original_file_name}
            </TagLabel>
            <Image1
              src={MdTrash}
              onClick={() => removeAtt(data?.id)}
              sx={{ cursor: 'pointer' }}
            />
          </HStack>
        </Stack>
      </Tag>
    );
  };

  const removeAtt = (key: any) => {
    dispatch(removeAttachment(key));
    dispatch(removeUploaded(key));
  };

  return (
    <Stack
      spacing={0}
      sx={{
        position: 'absolute',
        width: 'inherit',
        bottom: 0,
        background: '#fff',
        zIndex: 1,
        px: 3,
        py: 4,
      }}
    >
      <Stack direction={['column', 'row']} spacing="24px" mb="10px">
        <Box gap="10px" display="inline-block">
          {attachments.length > 0 &&
            attachments.map((item: UploadTypes, key: number) => {
              return <TagElement data={item} key={key} />;
            })}
        </Box>
      </Stack>

      <Flex
        justifyContent="flex-start"
        alignItems="center"
        gap="3"
        w="100%"
        flexWrap="wrap"
      >
        <button
          onClick={(e: any) => {
            e.preventDefault();
            editor.chain().focus().toggleBold().run();
          }}
          className={editor.isActive('bold') ? 'is-active' : ''}
        >
          <Bold {...iconAttributeProps} />
        </button>
        <button
          onClick={(e: any) => {
            e.preventDefault();
            editor.chain().focus().toggleItalic().run();
          }}
          className={editor.isActive('italic') ? 'is-active' : ''}
        >
          <Italic {...iconAttributeProps} />
        </button>
        <button
          onClick={(e: any) => {
            e.preventDefault();
            editor.chain().focus().toggleUnderline().run();
          }}
          className={editor.isActive('underline') ? 'is-active' : ''}
        >
          <Underline {...iconAttributeProps} />
        </button>
        <button
          onClick={(e: any) => {
            e.preventDefault();
            editor.chain().focus().toggleStrike().run();
          }}
          className={editor.isActive('strike') ? 'is-active' : ''}
        >
          <Strikethrough {...iconAttributeProps} />
        </button>
        <Box
          sx={{
            'svg > path:nth-child(1)': {
              color: editor.getAttributes('textStyle').color,
            },
          }}
          cursor="pointer"
          onClick={handleColorChange}
          position="relative"
          color={activeColorScheme}
        >
          <Baseline {...iconAttributeProps} />
        </Box>
        <input
          onInput={(event: any) => {
            event.preventDefault();
            editor.chain().focus().setColor(event.target.value).run();
          }}
          value={editor.getAttributes('textStyle').color}
          data-testid="setColor"
          type="color"
          ref={colorInputRef}
          style={{
            visibility: 'hidden',
            position: 'absolute',
            width: 0,
            height: 0,
          }}
        />
        <Box
          cursor="pointer"
          onClick={handleHighlightChange}
          position="relative"
          className={editor.isActive('highlight') ? 'is-active' : ''}
        >
          <Highlighter {...iconAttributeProps} />
        </Box>
        <input
          type="color"
          onInput={(event: any) => {
            event.preventDefault();
            editor
              .chain()
              .focus()
              .toggleHighlight({ color: event.target.value })
              .run();
          }}
          data-testid="setColor"
          ref={highlightInputRef}
          style={{
            visibility: 'hidden',
            position: 'absolute',
            width: 0,
            height: 0,
          }}
        />
        <button
          onClick={(event: any) => {
            event.preventDefault();
            editor.chain().focus().setTextAlign('left').run();
          }}
          className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}
        >
          <AlignLeftIcon {...iconAttributeProps} />
        </button>
        <button
          onClick={(event: any) => {
            event.preventDefault();
            editor.chain().focus().setTextAlign('center').run();
          }}
          className={
            editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''
          }
        >
          <AlignCenterIcon {...iconAttributeProps} />
        </button>
        <button
          onClick={(event: any) => {
            event.preventDefault();
            editor.chain().focus().setTextAlign('right').run();
          }}
          className={editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}
        >
          <AlignRightIcon {...iconAttributeProps} />
        </button>
        <button
          onClick={(event: any) => {
            event.preventDefault();
            editor.chain().focus().setTextAlign('justify').run();
          }}
          className={editor.isActive('justify') ? 'is-active' : ''}
        >
          <AlignJustifyIcon {...iconAttributeProps} />
        </button>
        <button
          onClick={(event: any) => {
            event.preventDefault();
            editor.chain().focus().toggleOrderedList().run();
          }}
          className={editor.isActive('orderedList') ? 'is-active' : ''}
        >
          <ListOrdered {...iconAttributeProps} />
        </button>
        <button
          onClick={(event: any) => {
            event.preventDefault();
            editor.chain().focus().toggleBulletList().run();
          }}
          className={editor.isActive('bulletList') ? 'is-active' : ''}
        >
          <List {...iconAttributeProps} />
        </button>
        <>
          <AtsDropZone
            multipleFile
            uploadedEnd={(e: any) => {}}
            deletedFile={(key: string) => {}}
            icon={<Image {...iconAttributeProps} />}
          />
          <AtsDropZone
            multipleFile
            isAgreement
            uploadedEnd={(e: any) => {}}
            deletedFile={(key: string) => {}}
            icon={<Link {...iconAttributeProps} />}
          />
        </>
        <button
          id="add"
          onClick={addYoutubeVideo}
          className={editor.isActive('youtube') ? 'is-active' : ''}
        >
          <YoutubeIcon {...iconAttributeProps} />
        </button>
        <button
          onClick={(event: any) => {
            event.preventDefault();
            editor.chain().focus().unsetAllMarks().run();
          }}
        >
          <RemoveFormattingIcon
            {...iconAttributeProps}
            color={activeColorScheme}
          />
        </button>

        <>
          <Popover placement="top-start" isLazy>
            <PopoverTrigger>
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  editor.chain().focus().unsetAllMarks().run();
                }}
              >
                <img src={MdPlaceholder} {...iconAttributeProps} />
              </Box>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverHeader>
                <Flex gap="10px" alignItems="center">
                  <img src={MdPlaceholder} {...iconAttributeProps} />
                  <Text
                    sx={{
                      fontSize: '16px',
                      color: '#2B2D42',
                    }}
                  >
                    Variables
                  </Text>
                </Flex>
              </PopoverHeader>
              <PopoverBody sx={{ height: '200px', overflowY: 'scroll' }}>
                <Grid templateColumns="repeat(1, 1fr)" gap={2}>
                  {Object.values(placeholders).length > 0 &&
                    Object.values(placeholders).map((category: any) => {
                      if (allowedCategory?.includes(category.category)) {
                        return (
                          <Box mb="20px">
                            <Text color="#2B2D42" mb="8px" fontSize="14px">
                              {category.category}
                            </Text>
                            <Wrap spacing={1}>
                              {category.subs.map((item: any) => {
                                return (
                                  <WrapItem>
                                    <Button
                                      variant="gray"
                                      leftIcon={<FaRegCopy />}
                                      onClick={() => placeHolderClick(item)}
                                      sx={{
                                        height: 'fit-content',
                                        p: '8px',
                                        fontSize: '12px',
                                        fontWeight: 400,
                                      }}
                                    >
                                      {item}
                                    </Button>
                                  </WrapItem>
                                );
                              })}
                            </Wrap>
                          </Box>
                        );
                      }
                    })}
                </Grid>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </>
      </Flex>
    </Stack>
  );
}
