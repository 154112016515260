import { useState } from 'react';
import { useSelector } from 'react-redux';

export function useActivityLogServices() {
  const { sequenceCandidateData } = useSelector((state: any) => state.outreach);

  const activities = sequenceCandidateData?.actvities;

  const [hasView, setView] = useState({});

  return { activities, hasView, setView };
}
