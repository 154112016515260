import {
  Box,
  Checkbox,
  Flex,
  Icon,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import EmailsModal from 'components/app/Global/Email/Lead';
import LeadStatus from 'components/app/LeadStatus';
import FAIcon from 'components/lib/FAIcon';
import Pagination from 'Library/Pagination';
import moment from 'moment';
import { ReactElement, useMemo, useState } from 'react';
import { BsChevronDown, BsChevronRight } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router';
import toUrl from 'utils/toUrl';
import {
  ExistingClientResponseType,
  LeadsAPIResponseType,
  NewProspectAPIResponseType,
} from '../../types';
import BulkAction from './components/BulkAction';
import SkeletonLoader from './components/SkeletonLoader';
import { useTableServices } from './useTableServices';

interface TableProps {
  rows: ExistingClientResponseType[];
  onDateSort: () => void;
  onSubmitEmail: () => void;
  isLoading?: boolean;
  onAddTags: (tags: Array<string>, ids: Array<number>) => Promise<any>;
  onBulkInactive: (ids: Array<number>) => Promise<any>;
  pagination: {
    totalPages: number;
    currentPage: number;
    onPageChange: (page: number) => void;
    totalEntries: number;
    onEntryChange: (entries: number) => void;
    currentCount: number;
    targetCount: number;
  };
}
export default function ExistingClientProspects({
  rows,
  onDateSort,
  onSubmitEmail,
  onBulkInactive,
  onAddTags,
  isLoading,
  pagination: {
    totalPages,
    currentPage,
    onPageChange,
    totalEntries,
    onEntryChange,
    currentCount,
    targetCount,
  },
}: TableProps) {
  const { handleClick } = useTableServices(); //TODO: remove this dependency
  const navigate = useNavigate();

  let allLeads = useMemo(() => {
    let all: any = [];
    rows?.forEach((client) => {
      all = all.concat(
        client?.leads?.map((lead) => ({
          id: lead.id,
          email: lead.primary_email,
        }))
      );
    });
    return all;
  }, [rows]);
  const [checkAll, setCheckAll] = useState(false);
  const [checked, setChecked] = useState([]);

  const headers: { label: ReactElement | string; width?: number }[] =
    useMemo(() => {
      function handleAllChecked(e: any) {
        const { checked } = e.target;
        if (checked) {
          setChecked(allLeads);
        } else {
          setChecked([]);
        }
        setCheckAll(checked);
      }
      return [
        {
          label: (
            <Checkbox
              onChange={handleAllChecked}
              isIndeterminate={
                checked.length && checked.length !== allLeads.length
              }
              checked={checkAll}
              defaultChecked={false}
              colorScheme="purple"
            />
          ),
        },
        { label: 'Prospect Details' },
        { label: 'Contact Information' },
        { label: 'Client Job Link' },
        { label: 'Status ' },
        { label: 'City ' },
        { label: 'State ' },
        {
          label: (
            <Flex gap="10px" justifyContent="start" alignItems="center">
              Created Date
              <Flex onClick={onDateSort} cursor="pointer">
                <FAIcon iconName="sort" />
              </Flex>
            </Flex>
          ),
        },
      ];
    }, [checked, onDateSort, checkAll, allLeads]);
  const [emailModal, setEmailModal] = useState<{
    open: boolean;
    email: string | null;
    id: number | null;
  }>({ open: false, email: null, id: null });
  const [leadDetail, setLeadDetail] = useState<{
    leads: NewProspectAPIResponseType[];
    rowId: null | number;
  }>({ leads: [], rowId: null });

  function handleCheck(checks: any) {
    console.log('🚀 ~ handleCheck ~ checks:', checks);
    const unchecked = checks.filter((data: any) => !data.checked);
    const checked = checks.filter((data: any) => data.checked);

    setChecked((state) => {
      const filteredState = state.filter(
        (data) => !unchecked.some((uncheck: any) => uncheck.id === data.id)
      );

      return [...filteredState, ...checked];
    });
  }
  const handlePrevData = () => {
    const leadID = leadDetail.leads[leadDetail.rowId - 1 - 1].id;
    setLeadDetail((state) => ({ ...state, rowId: state.rowId - 1 }));
    navigate(`/leads/prospects/existing-client-prospects/${leadID}/details`);
  };

  const handleNextData = () => {
    const leadID = leadDetail.leads[leadDetail.rowId - 1 + 1].id;
    setLeadDetail((state) => ({ ...state, rowId: state.rowId + 1 }));
    navigate(`/leads/existing-client-prospects/${leadID}/details`);
  };
  if (isLoading) return <SkeletonLoader />;
  return (
    <>
      <Flex justifyContent="space-between" pb={'16px'}>
        <Flex gap={4}>
          {checked.length > 0 ? (
            <BulkAction
              leads={checked}
              onAddTags={(tags) =>
                onAddTags(
                  tags,
                  checked.map((data) => data.id)
                )
              }
              onMarkInActive={() => {
                onBulkInactive(checked.map((data) => data.id));
              }}
            />
          ) : (
            <Box mb={6}></Box>
          )}
        </Flex>
      </Flex>
      <TableContainer
        boxSizing="border-box"
        border="1px solid"
        borderColor="default.white.400"
        borderRadius="md"
        height="inherit"
        sx={{ overflowY: 'auto' }}
      >
        <Table>
          <Thead>
            <Tr bg="default.white.600">
              {headers.map((title, key: number) => (
                <Th
                  key={`lead-th-${key}`}
                  sx={{ position: 'sticky', top: 0, zIndex: 10 }}
                  bg="default.white.600"
                >
                  <Box color="default.gray.600">{title.label}</Box>
                </Th>
              ))}
            </Tr>
          </Thead>

          <Tbody
            boxSizing="border-box"
            background="default.white.100"
            borderBottom="1px solid"
            borderColor="default.white.400"
          >
            {rows.map((client: any, index) => (
              <TableRow
                client={client}
                handleClick={handleClick}
                checked={checked}
                handleCheck={handleCheck}
                onEmailClick={(email, id) =>
                  setEmailModal({ open: true, email, id })
                }
              />
            ))}
          </Tbody>
        </Table>
        {emailModal.open ? (
          <EmailsModal
            isOpen={emailModal.open}
            onClose={() =>
              setEmailModal({ open: false, email: null, id: null })
            }
            email={emailModal.email || ''}
            id={emailModal.id}
            allowedCategory={['Client', 'Contact', 'Signatures']}
          />
        ) : null}
      </TableContainer>
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={onPageChange}
        totalEntries={totalEntries}
        onEntryChange={onEntryChange}
        currentCount={currentCount}
        targetCount={targetCount}
      />
      <Outlet
        context={{
          onPaginationNext: handleNextData,
          onPaginationPrev: handlePrevData,
          rowId: leadDetail?.rowId,
          totalLeads: leadDetail?.leads?.length,
        }}
      />
    </>
  );
}

function TableRow({
  client,
  handleCheck,
  checked,
  onEmailClick,
}: {
  client: { client_name: string; leads: any[]; client_id: number };
  handleClick: (lead: any) => void;
  handleCheck: (
    check: { id: number; email: string; checked: boolean }[]
  ) => void;
  checked: Array<{ id: number; email: string; checked: boolean }>;
  onEmailClick: (email: string, id: number) => void;
}) {
  const navigate = useNavigate();
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  const leadsIds = useMemo(() => {
    return client.leads.map((lead) => ({
      id: lead.id,
      email: lead.primary_email,
    }));
  }, [client]);
  const leadsChecked = useMemo(() => {
    return leadsIds.filter((lead) =>
      checked.some((data: any) => data.id === lead.id)
    );
  }, [checked, leadsIds]);

  function handleAllChecked(e: any) {
    const { checked } = e.target;
    const checks = leadsIds.map((data) => ({
      id: data.id,
      email: data.email,
      checked,
    }));
    handleCheck(checks);
  }

  function handleClick(lead: any) {
    navigate(`/leads/existing-client-prospects/${lead.id}/details`);
  }

  return (
    <>
      <Tr
        sx={{
          bg: '#FAF5FF',
          color: 'primary.600',
          fontSize: '12px',
          fontWeight: 700,
          textTransform: 'uppercase',
          cursor: 'pointer',
          zIndex: 0,
        }}
        _hover={{
          bg: '#f8f9fa',
          zIndex: 0,
        }}
      >
        <Td w={50} cursor="pointer" py={'8px'}>
          <Checkbox
            isIndeterminate={
              leadsChecked.length && leadsChecked.length !== client.leads.length
            }
            isChecked={
              leadsChecked.length && leadsChecked.length === client.leads.length
            }
            defaultChecked={false}
            colorScheme="purple"
            onChange={handleAllChecked}
          />
        </Td>
        <Td
          colSpan={6}
          alignItems="flex-start"
          color={'#6930CA'}
          py="8px"
          sx={{ cursor: 'pointer' }}
          onClick={onToggle}
        >
          <Flex alignItems={'center'} gap="8px" w="max-content">
            <Box width="16px">
              {isOpen ? (
                <Icon as={BsChevronDown} />
              ) : (
                <Icon as={BsChevronRight} />
              )}
            </Box>

            <Link
              fontSize="12px"
              href={`/clients/my-clients/${client.client_id}/overview`}
              target="_blank"
              rel="noreferrer"
            >
              {client.client_name}
            </Link>
          </Flex>
        </Td>
        <Td colSpan={1}></Td>
      </Tr>

      {isOpen && (
        <>
          {client.leads.map((lead, index) => (
            <Tr
              key={`${lead.id}--${index}`}
              _hover={{
                bg: '#f8f9fa',
              }}
            >
              <Td cursor="pointer">
                <Checkbox
                  isChecked={leadsChecked.some((data) => data.id === lead.id)}
                  defaultChecked={false}
                  colorScheme="purple"
                  onChange={(e) =>
                    handleCheck([
                      {
                        id: lead.id,
                        email: lead.primary_email,
                        checked: e.target.checked,
                      },
                    ])
                  }
                />
              </Td>
              <Td
                onClick={() => handleClick(lead)}
                cursor="pointer"
                py={'20px'}
              >
                <Flex direction="column" width={250}>
                  <Text
                    fontWeight="bold"
                    textTransform="capitalize"
                    style={{ textWrap: 'nowrap' }}
                    isTruncated
                  >
                    {[lead.first_name, lead.last_name].join(' ')}
                  </Text>
                  <Box
                    fontSize="14px"
                    color="default.secondarytext"
                    isTruncated
                  >
                    {lead.title}
                  </Box>
                </Flex>
              </Td>

              <Td cursor="pointer">
                <Flex
                  fontSize="14px"
                  color="default.primarytext"
                  sx={{ textWrap: 'balance' }}
                >
                  <Link
                    fontWeight="bold"
                    style={{ textWrap: 'nowrap' }}
                    onClick={() => onEmailClick(lead.primary_email, lead.id)}
                  >
                    {lead.primary_email}
                  </Link>
                  <Box>
                    <Link href={`tel:${lead.personal_phone}`}>
                      {lead.personal_phone}
                    </Link>
                  </Box>
                </Flex>
              </Td>
              <Td cursor="pointer" p="5px 24px" maxW={250}>
                <Box w="100%" isTruncated fontSize={'14px'}>
                  <Link href={toUrl(lead.client_job_link)} target="_blank">
                    {lead?.client_job_title || lead.client_job_link}
                  </Link>
                </Box>
              </Td>
              <Td onClick={() => handleClick(lead)} cursor="pointer">
                <LeadStatus status={lead.leadStatus.lead_status} />
              </Td>
              <Td onClick={() => handleClick(lead)} cursor="pointer">
                {lead.city}
              </Td>
              <Td onClick={() => handleClick(lead)} cursor="pointer">
                {lead.state_province}
              </Td>
              <Td
                fontSize="14px"
                onClick={() => handleClick(lead)}
                cursor="pointer"
                p="5px 24px"
              >
                {moment.utc(lead?.created_at).format('MM/DD/YYYY')}
              </Td>
            </Tr>
          ))}
        </>
      )}
    </>
  );
}
