import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  Flex,
} from '@chakra-ui/react';
import React from 'react';

export default function FormErrorAlert({
  text,
  onClose,
}: {
  text: React.ReactNode;
  onClose: () => void;
}) {
  return (
    <Box>
      <Alert status="error" justifyContent="space-between" px={7}>
        <Flex>
          <AlertIcon />
          <Box>
            <AlertTitle>Oh snap!</AlertTitle>
            <AlertDescription>{text}</AlertDescription>
          </Box>
        </Flex>
        <CloseButton
          position="relative"
          right={-1}
          top={-3}
          onClick={onClose}
        />
      </Alert>
    </Box>
  );
}
