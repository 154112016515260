import { useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useGetJobsMutation, useListByContactCandidatesMutation } from "store/jobs.slice";

interface followUpClickProps {
    open: boolean;
    all: boolean;
    job_id: number;
}

interface followUpModalProps {
    lead_id: number | null;
    job_id: number | null;
}

export const useGroupRowServices = () => {
    const [reqGetJob, resGetJob] = useGetJobsMutation();
    const [reqListByContactCandidates, resListByContactCandidates] = useListByContactCandidatesMutation();


    const {
        isOpen: isFollowUpOpen,
        onOpen: onFollowUpOpen,
        onClose: onFollowUpClose,
    } = useDisclosure({ defaultIsOpen: false });
    const [followUpParam, setFollowUpParam] = useState<followUpModalProps>({
        lead_id: null,
        job_id: null,
    });
    const [isFollowUp, setIsFollowUp] = useState(false);
    const [isFollowUpAll, setIsFollowUpAll] = useState(false);

    const followUpClick = (params: followUpClickProps) => {
        const { open, all, job_id } = params;
        setFollowUpParam((prev) => ({
            ...prev,
            job_id
        }));
        setIsFollowUp(open);
        setIsFollowUpAll(all);
        reqGetJob({ id: job_id });
    };

    useEffect(() => {
        if (resGetJob.isSuccess) {
            const data = resGetJob.data.data.data
            setFollowUpParam((prev) => ({
                ...prev,
                lead_id: data?.lead_id,
            }));
            if (isFollowUpAll) {
                reqListByContactCandidates({
                    id: data?.lead_id,
                });
            }
        }
    }, [resGetJob.isSuccess])

    useEffect(() => {
        if (isFollowUp && resGetJob.isSuccess && !isFollowUpAll) {
            onFollowUpOpen();
        }
    }, [isFollowUp, resGetJob.isSuccess]);

    useEffect(() => {
        if (resListByContactCandidates.isSuccess && isFollowUpAll) {
            onFollowUpOpen();
        }
    }, [resListByContactCandidates.isSuccess])

    return {
        followUpClick,
        setIsFollowUp,
        onFollowUpClose,
        followUpParam,
        isFollowUpAll,
        isFollowUpOpen,
    }
}