import { Formik } from 'formik';

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  ModalFooter,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';

import ScrollToFieldError from 'components/app/ScrollError';
import { useChangeStatusServices } from '../useChangeStatusServices';

interface ChangeStatusFormProps {
  id: any;
  onClose: any;
}

export const ChangeStatusForm = ({ id, onClose }: ChangeStatusFormProps) => {
  const { initialValues, handleSubmit, changeStatusOptions, isLoading } =
    useChangeStatusServices({ id, onClose });
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ values, handleSubmit, handleChange, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <ScrollToFieldError />
          <Box p="18px 32px">
            <Box p="16px" border="1px solid #EEEEEE" borderRadius="6px">
              <FormLabel
                fontSize="16px"
                lineHeight="18px"
                color="#000000"
                mb="24px"
              >
                Select a Status
              </FormLabel>

              <FormControl>
                <RadioGroup defaultValue={values?.status?.toString()}>
                  <Stack spacing="24px">
                    {changeStatusOptions.map((val: any) => {
                      return (
                        <Radio
                          name="status"
                          color="primary.800"
                          colorScheme="purple"
                          value={val?.value?.toLocaleString()}
                          onChange={handleChange}
                        >
                          {val.label}
                        </Radio>
                      );
                    })}
                  </Stack>
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>

          <ModalFooter
            position="sticky"
            bottom="0"
            background="default.white.100"
            boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
          >
            <Button mr={3} onClick={() => onClose()}>
              Close
            </Button>
            <Button variant="solid" type="submit" isLoading={isLoading}>
              Update
            </Button>
          </ModalFooter>
        </form>
      )}
    </Formik>
  );
};
