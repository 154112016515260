export const TONE_OPTIONS = [
  {
    label: 'Shorten',
    tone: 'shorten',
  },
  {
    label: 'Formal',
    tone: 'formal',
  },
  {
    label: 'Casual',
    tone: 'casual',
  },
  {
    label: 'Creative',
    tone: 'creative',
  },
  {
    label: 'Joyful',
    tone: 'joyful',
  },
];

export const AI_LIST: any = [
  {
    subject: [],
    body: [],
  },
  {
    subject: [],
    body: [],
  },
];
