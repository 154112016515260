export const SIDE_BAR_ITEMS = [
  {
    id: 1,
    label: 'Default Schedule',
    to: 'default-schedule',
  },
  {
    id: 2,
    label: 'Email Template',
    to: 'email-template',
  },
  {
    id: 3,
    label: 'Email Connection',
    to: 'email-connection',
  },

  {
    id: 4,
    label: 'Suppresion List',
    to: 'suppresion-list',
  },
  {
    id: 5,
    label: 'LinkedIn Connection',
    to: 'buzz-connection',
  },
];
