import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from 'constants/values';

const CANDIDATES = ['Candidates'];
const LEADS = [
  'Leads',
  'Leads-Count',
  'Leads-Details',
  'Existing-Client-Prospects',
  'Leads-Types',
  'Lead-Status',
  'Lead-Types',
  'Leads-Client',
  'Leads-as-Contact',
  'Leads-Job',
  'Leads-Submits',
  'Leads-Kanban',
];
const CONSTANTS = [
  'Countries',
  'Lead-Sources',
  'Job-Specifications',
  'Education',
  'Experience-level',
  'Employee-Type',
  'Compensation',
  'Work-Type',
  'Skills',
  'Geo-Location',
  'Default-Pitches',
  'Placeholder-Pair',
  'Tags',
];
const USER = ['User'];
const CLIENT = ['My-Client', 'Client-Locations', 'Client-Search'];
const JOBS = ['Job-Detail'];

export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: () => ({}),
  tagTypes: [
    ...CONSTANTS,
    ...CANDIDATES,
    ...LEADS,
    ...USER,
    ...CLIENT,
    ...JOBS,
  ],
});
