import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Checkbox,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import Button from 'Library/Button';
import Pagination from 'Library/Pagination';
import FAIcon from 'components/lib/FAIcon';
import { useParams } from 'react-router';
import {
  listGroupTemplate,
  useDeleteGroupMutation,
  useListGroupMutation,
} from 'store/email_group.slice';
import TrLoading from 'components/app/TrLoading';
import TemplateGroupditGroup from './modal/edit';
import { swalContent } from 'types';
import { AtsConfirm } from 'utils/swal';

const TemplateGroupTable = () => {
  const dispatch = useDispatch();
  const [reqGroup, resGroup] = useListGroupMutation();
  const [reqDelGroup, resDelGroup] = useDeleteGroupMutation();

  const { groupPage, groupPageBtn, groupList } = useSelector(
    (state: any) => state.email_group
  );
  const tableHeader: { label: any }[] = [
    {
      label: <Checkbox defaultChecked={false} colorScheme="blue" />,
    },
    { label: 'Group Name' },
    { label: '' },
  ];

  const [itemData, setItemData] = useState(null);

  const params = useParams();

  useEffect(() => {
    if (params.templateTab == 'group' || resDelGroup.isSuccess) {
      reqGroup(groupPageBtn);
    }
  }, [params.templateTab, resDelGroup.isSuccess]);

  const pageChange = async (page: number) => {
    let newParam = {
      page: page,
      take: groupPageBtn.take,
      query: groupPageBtn.query,
    };
    dispatch(
      listGroupTemplate({
        groupPageBtn: newParam,
      })
    );
    reqGroup(newParam);
  };

  const entryChange = (entries: number) => {
    let newParam = {
      page: 1,
      take: entries,
      query: groupPageBtn.query,
    };
    dispatch(
      listGroupTemplate({
        groupPageBtn: newParam,
      })
    );
    reqGroup(newParam);
  };

  const {
    isOpen: groupEditOpen,
    onOpen: groupOnEditOpen,
    onClose: groupOnEditClose,
  } = useDisclosure();

  const editClick = (data: any) => {
    setItemData(data);
    groupOnEditOpen();
  };

  let content: swalContent = {
    title: 'Are you sure?',
    text: '',
    buttons: ['Cancel', true],
    icon: 'warning',
  };

  const delClick = async (data: any) => {
    content.text = `You are about to delete ${data.name}`;
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqDelGroup({ data: [], id: data.id });
    }
  };

  return (
    <Box px={6} py={4} justifyContent="space-between">
      <TableContainer
        boxSizing="border-box"
        borderX="1px solid"
        borderTop="1px solid"
        borderColor="default.white.400"
        borderRadius="md"
        minHeight="70vh"
      >
        <Table>
          <Thead>
            <Tr bg="default.white.600">
              {tableHeader.map((title) => (
                <Th key={title.label}>
                  <Box color="default.gray.600">{title.label}</Box>
                </Th>
              ))}
            </Tr>
          </Thead>

          <Tbody
            boxSizing="border-box"
            background="default.white.100"
            borderBottom="1px solid"
            borderColor="default.white.400"
          >
            {resGroup.isLoading ? (
              <TrLoading rows={3} columns={5} />
            ) : (
              groupList?.length > 0 &&
              groupList.map((item: any, key: number) => {
                return (
                  <Tr
                    key={key}
                    _hover={{
                      bg: '#f8f9fa',
                    }}
                    cursor="pointer"
                  >
                    <Td></Td>
                    <Td onClick={() => editClick(item)}>{item.name}</Td>
                    <Td>
                      <Menu>
                        <MenuButton>
                          <Button
                            htmlType="menu"
                            variant="ghost"
                            iconName="ellipsis-h"
                            iconSize="xl"
                          />
                        </MenuButton>

                        <MenuList fontSize="sm">
                          <MenuItem onClick={() => editClick(item)}>
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <FAIcon iconName="pencil" />
                              <Box ml={12}>Edit</Box>
                            </Flex>
                          </MenuItem>
                          <MenuItem
                            onClick={() => delClick(item)}
                            color="caution.800"
                          >
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <FAIcon iconName="trash" />
                              <Box ml={12}>Delete</Box>
                            </Flex>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                );
              })
            )}
          </Tbody>
        </Table>
      </TableContainer>

      <Pagination
        totalPages={groupPage.lastPage}
        currentPage={groupPage.currentPage}
        onPageChange={(page) => pageChange(page)}
        onEntryChange={(entries) => entryChange(entries)}
        totalEntries={groupPage.count}
        currentCount={Object.values(groupList).length}
        targetCount={groupPageBtn.take}
      />
      {groupEditOpen && (
        <TemplateGroupditGroup
          onClose={groupOnEditClose}
          isOpen={groupEditOpen}
          groupoData={itemData}
        />
      )}
    </Box>
  );
};

export default TemplateGroupTable;
