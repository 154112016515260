import { useEffect } from "react";
import { useSelector } from "react-redux";
import { SettingsProps } from "./types";


export default function useSettingsServices({ setTabIndex }: SettingsProps) {
    const { settingsTabIndex } = useSelector((state: any) => state.app)
    useEffect(() => {
        setTabIndex(settingsTabIndex)
    }, [settingsTabIndex])

    return {

    }
}