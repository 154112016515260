export const MILES = [
  { label: '1 mile', value: 1 },
  { label: '5 miles', value: 5 },
  { label: '10 miles', value: 10 },
  { label: '25 miles', value: 25 },
  { label: '50 miles', value: 50 },
  { label: '100 miles', value: 100 },
  { label: '200 miles', value: 200 },
  { label: '300 miles', value: 300 },
  { label: '400 miles', value: 400 },
  { label: '500 miles', value: 500 },
];
