import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  Stack,
  Radio,
  RadioGroup,
} from '@chakra-ui/react';
import { FieldArray, Field, FieldProps, ErrorMessage } from 'formik';

import Button from 'Library/Button';
import { BsPercent } from 'react-icons/bs';
import { IntialValueType } from '../../utils/validationSchema';
import Select from 'components/ui/Select/Select';
import { useFetchUsersQuery } from 'services/user/users.query';

export default function RecruitingTeam() {
  const { data: recruiterList, isFetching: isRecruiterListLoading } =
    useFetchUsersQuery(
      {},
      {
        selectFromResult: ({ data, ...other }) => ({
          data: data
            ? data?.map((user) => ({
                label: `${user.first_name} ${user.last_name}`,
                value: user.id,
              }))
            : [],
          ...other,
        }),
      }
    );

  return (
    <Field>
      {({
        form: { values, errors, touched, setFieldValue },
      }: FieldProps<IntialValueType>) => {
        return (
          <Stack justifyContent="space-between" h="inherit">
            <Stack spacing="10px">
              <Box p="16px" background="#fff" borderRadius="8px">
                <Flex gap="16px" mb="24px">
                  <FormControl
                    isInvalid={Boolean(
                      !!errors.account_manager && touched.account_manager
                    )}
                    mb="16px"
                    isRequired
                  >
                    <FormLabel>Account Manager</FormLabel>
                    <Field
                      name="account_manager"
                      component={Select}
                      value={values.account_manager}
                      placeholder="Select"
                      onChange={(e: any) => setFieldValue('account_manager', e)}
                      options={values.managerList}
                      isError={Boolean(
                        !!errors.account_manager && touched.account_manager
                      )}
                    />

                    <ErrorMessage
                      name="account_manager"
                      render={(msg: any) => (
                        <FormErrorMessage>{msg?.label}</FormErrorMessage>
                      )}
                    />
                  </FormControl>
                  <FormControl
                    isInvalid={Boolean(
                      !!errors.primary_recruiter && touched.primary_recruiter
                    )}
                    isRequired
                  >
                    <FormLabel>Primary Recruiter</FormLabel>
                    <Field
                      name="primary_recruiter"
                      value={values.primary_recruiter}
                      component={Select}
                      placeholder="Select"
                      onChange={(e: any) =>
                        setFieldValue('primary_recruiter', e)
                      }
                      options={recruiterList}
                      isError={Boolean(
                        !!errors.primary_recruiter && touched.primary_recruiter
                      )}
                      isLoading={isRecruiterListLoading}
                    />

                    <ErrorMessage
                      name="primary_recruiter"
                      render={(msg: any) => (
                        <FormErrorMessage>{msg?.label}</FormErrorMessage>
                      )}
                    />
                  </FormControl>
                </Flex>
              </Box>

              <Box
                h="inherit"
                p={values.recruiters?.length === 0 && '16px'}
                background={values.recruiters?.length === 0 && '#fff'}
                borderRadius="8px"
              >
                <FieldArray name="recruiters">
                  {({ remove, push }) => (
                    <Stack spacing="8px">
                      {values.recruiters &&
                        values.recruiters?.length > 0 &&
                        values.recruiters.map(
                          (recruiterItem: any, index: number) => {
                            console.log('ERRR', errors);
                            return (
                              <Box
                                background="#fff"
                                borderRadius="8px"
                                p="16px"
                                pr="28px"
                              >
                                <Box className="row" key={`recr-${index}`}>
                                  {values.recruiters?.length > 0 && (
                                    <Box mb="10px" textAlign="right">
                                      <Button
                                        variant="delete"
                                        leftIcon="close"
                                        onClick={() => remove(index)}
                                        size="sm"
                                      >
                                        Remove
                                      </Button>
                                    </Box>
                                  )}
                                  <Flex gap="8px" mb="24px">
                                    <FormControl
                                      isInvalid={Boolean(
                                        !!(errors as any)?.recruiters?.[index]
                                          ?.recruiter &&
                                          (touched as any)?.recruiters?.[index]
                                            ?.recruiter
                                      )}
                                      isRequired
                                    >
                                      <FormLabel>Recruiter</FormLabel>
                                      <Field
                                        name={`recruiters.${[index]}.recruiter`}
                                        component={Select}
                                        autoFocus
                                        placeholder="Select Recruiter"
                                        value={recruiterItem.recruiter}
                                        onChange={(e: any) =>
                                          setFieldValue(
                                            `recruiters.${[index]}.recruiter`,
                                            e
                                          )
                                        }
                                        options={recruiterList}
                                        isLoading={isRecruiterListLoading}
                                        isError={Boolean(
                                          (errors as any)?.recruiters?.[index]
                                            ?.recruiter &&
                                            (touched as any)?.recruiters?.[
                                              index
                                            ]?.recruiter
                                        )}
                                      />

                                      <ErrorMessage
                                        name={`recruiters.${[index]}.recruiter`}
                                        render={(msg: any) => {
                                          return (
                                            <FormErrorMessage>
                                              {msg?.label}
                                            </FormErrorMessage>
                                          );
                                        }}
                                      />
                                    </FormControl>

                                    <FormControl
                                      isInvalid={Boolean(
                                        !!(errors as any)?.recruiters?.[index]
                                          ?.split &&
                                          (touched as any)?.recruiters?.[index]
                                            ?.split
                                      )}
                                      isRequired
                                    >
                                      <FormLabel>Split Percentage</FormLabel>

                                      <InputGroup>
                                        <InputLeftElement
                                          pointerEvents="none"
                                          zIndex={0}
                                          children={
                                            <Icon
                                              as={BsPercent}
                                              color="#718096"
                                            />
                                          }
                                          sx={{
                                            height: '38px',
                                          }}
                                        />
                                        <Field
                                          component={Input}
                                          name={`recruiters.${[index]}.split`}
                                          placeholder="0"
                                          type="number"
                                          variant="outline"
                                          pl="35px"
                                          max={100}
                                          min={0}
                                          value={recruiterItem?.split}
                                          maxLength={3} // Limit the input to 3 digits
                                          onChange={(e: any) => {
                                            const value = e.target.value;
                                            // Regex to allow only numbers with a maximum of 3 digits
                                            setFieldValue(
                                              `recruiters.${[index]}.split`,
                                              value
                                            );
                                          }}
                                        />
                                      </InputGroup>
                                      <ErrorMessage
                                        name={`recruiters.${[index]}.split`}
                                        component={FormErrorMessage}
                                      />
                                    </FormControl>
                                  </Flex>

                                  <Box mb="24px" color="#2B2D42">
                                    <Checkbox
                                      name={`recruiters.${[
                                        index,
                                      ]}.post_new_job_ads`}
                                      isChecked={recruiterItem.post_new_job_ads}
                                      onChange={(e: any) => {
                                        setFieldValue(
                                          `recruiters.${[
                                            index,
                                          ]}.post_new_job_ads`,
                                          e.target.checked
                                        );
                                      }}
                                    >
                                      Allow recruiter to post new job (Enabling
                                      this will share the client with the
                                      recruiter)
                                    </Checkbox>
                                  </Box>

                                  <Box mb="24px">
                                    <FormLabel>Submittal Review</FormLabel>

                                    <RadioGroup
                                      name={`recruiters.${[index]}.review`}
                                      value={values.recruiters[index].review}
                                      onChange={(value: string) =>
                                        setFieldValue(
                                          `recruiters.${[index]}.review`,
                                          value
                                        )
                                      }
                                    >
                                      <Stack spacing="10px">
                                        <Radio
                                          colorScheme="purple"
                                          value="no_submittal_review"
                                        >
                                          No Submittal Review
                                        </Radio>
                                        <Radio
                                          colorScheme="purple"
                                          value="review_send_as_client_owner"
                                        >
                                          Review Send as Client Owner
                                        </Radio>
                                        <Radio
                                          colorScheme="purple"
                                          value="review_send_as_client_recruiter"
                                        >
                                          Review Send as Client Recruiter
                                        </Radio>
                                      </Stack>
                                    </RadioGroup>
                                  </Box>

                                  <Box mb="24px">
                                    <FormLabel>Interview Settings</FormLabel>

                                    <RadioGroup
                                      name={`recruiters.${[index]}.interview`}
                                      value={values.recruiters[index].interview}
                                      onChange={(value: string) =>
                                        setFieldValue(
                                          `recruiters.${[index]}.interview`,
                                          value
                                        )
                                      }
                                    >
                                      <Stack spacing="10px">
                                        <Radio
                                          colorScheme="purple"
                                          value="interview_send_as_client_owner"
                                        >
                                          Send interview as client owner
                                        </Radio>
                                        <Radio
                                          colorScheme="purple"
                                          value="interview_send_as_recruiter"
                                        >
                                          Send interview as recruiter
                                        </Radio>
                                      </Stack>
                                    </RadioGroup>
                                  </Box>
                                </Box>

                                <Box sx={{ w: 'fit-content' }}>
                                  <Button
                                    leftIcon="plus"
                                    onClick={() =>
                                      push({
                                        recruiter: '',
                                        split: '',
                                        post_new_job_ads: false,
                                        review: '',
                                        interview: '',
                                      })
                                    }
                                  >
                                    Add Recruiter
                                  </Button>
                                </Box>
                              </Box>
                            );
                          }
                        )}

                      {values.recruiters?.length === 0 && (
                        <Box
                          sx={{
                            h: '50vh',
                          }}
                        >
                          <Button
                            sx={{ w: 'fit-content' }}
                            leftIcon="plus"
                            onClick={() =>
                              push({
                                recruiter: '',
                                split: '',
                                post_new_job_ads: false,
                                review: '',
                                interview: '',
                              })
                            }
                          >
                            Add Recruiter
                          </Button>
                        </Box>
                      )}
                    </Stack>
                  )}
                </FieldArray>
              </Box>
            </Stack>
          </Stack>
        );
      }}
    </Field>
  );
}
