const extractBooleanSearchTerms = (input: string): string[] => {
  // This function recursively parses and extracts terms from a boolean expression, excluding terms after "not".
  function parseTerms(expression: string): string[] {
    const terms: string[] = [];
    // Map to store quoted phrases temporarily
    const quotes: Record<string, string> = {};
    let quoteIndex = 0;

    // Replace quoted phrases and store them
    expression = expression.replace(/"([^"]+)"/g, (match: string) => {
      const key: string = `__quote${quoteIndex++}__`;
      quotes[key] = match.replace(/"/g, '');
      return key;
    });

    // Properly handle negations by removing the entire 'not' condition from the parse
    expression = expression.replace(/\bnot\s+[^()]+\b/gi, '');

    // Recursively parse nested expressions if any, excluding them from being split later
    expression = expression.replace(
      /\(([^)]+)\)/g,
      (match: string, group: string) => {
        terms.push(...parseTerms(group));
        return ''; // Remove the processed group from the main expression
      }
    );

    // Split the cleaned expression by logical operators 'and' or 'or' outside of quoted text
    expression.split(/\s+(?:and|or)\s+/gi).forEach((term: string) => {
      term = term.trim();
      if (term.startsWith('__quote')) {
        // Replace back the quotes
        terms.push(quotes[term]);
      } else if (term) {
        terms.push(term);
      }
    });

    return terms;
  }

  return parseTerms(input);
};

export default extractBooleanSearchTerms;
