import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from './api.slice';
import { appError } from './app.slice';

export const templateSlice = createSlice({
  name: 'template',
  initialState: {
    templateNav: 'email-templates',
    placehoderPair: {},
    templateRawList: [],
    templateListConfirmation: [],
    templatesList: [],
    placehodlers: [],
    templateData: {},
    aiTemplate: [],
    defaultTemplateList: [],
    templatePage: {
      count: 0,
      currentPage: 1,
      lastPage: 1,
      nextPage: null,
      prevPage: null,
    },
    templatePageBtn: {
      page: 1,
      take: 50,
      query: '',
    },
  },
  reducers: {
    listTemplate: (state, { payload }) => ({ ...state, ...payload }),
    navTemplate: (state, { payload }) => ({ ...state, ...payload }),
    setPairTemplate: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { listTemplate, navTemplate, setPairTemplate } =
  templateSlice.actions;
export default templateSlice.reducer;

const templateApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createTemplate: builder.mutation({
      query: (data) => ({
        url: `/email-template/create`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listTemplate({ tags: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ListTemplate: builder.mutation({
      query: (data) => ({
        url: `/email-template/list`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // dispatch(listTemplate({ tags: data.data }));
          dispatch(listTemplate({ templatesList: data.data.data }));
          dispatch(
            listTemplate({
              templatePage: {
                count: data.data.count,
                currentPage: data.data.currentPage,
                lastPage: data.data.lastPage,
                nextPage: data.data.nextPage,
                prevPage: data.data.nextPage,
              },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    placeHolders: builder.mutation({
      query: (data) => ({
        url: `/email-template/placeholders`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listTemplate({ placehodlers: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    updateTemplate: builder.mutation({
      query: ({ data, id }) => ({
        url: `/email-template/update/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listTemplate({ tags: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    deActivateTemplate: builder.mutation({
      query: ({ data, id }) => ({
        url: `/email-template/de-activate/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listTemplate({ tags: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ActivateTemplate: builder.mutation({
      query: ({ data, id }) => ({
        url: `/email-template/activate/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listTemplate({ tags: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    DefaultTemplate: builder.mutation({
      query: ({ id }) => ({
        url: `/email-template/default/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          // const { data } = await queryFulfilled;
          // dispatch(listTemplate({ tags: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    DuplicateTemplate: builder.mutation({
      query: ({ id }) => ({
        url: `/email-template/duplicate/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          // const { data } = await queryFulfilled;
          // dispatch(listTemplate({ tags: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    DefaultTemplateList: builder.mutation({
      query: () => ({
        url: `/email-template/default-list`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listTemplate({ defaultTemplateList: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    DelTemplate: builder.mutation({
      query: ({ data, id }) => ({
        url: `/email-template/delete/${id}`,
        method: 'DELETE',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listTemplate({ tags: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    RawListTemplate: builder.mutation({
      query: ({ data, id }) => ({
        url: `/email-template/raw-list/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // dispatch(listTemplate({ tags: data.data }));
          dispatch(listTemplate({ templateRawList: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    RawListTemplateConfirmation: builder.mutation({
      query: ({ data, id }) => ({
        url: `/email-template/raw-list/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // dispatch(listTemplate({ tags: data.data }));
          dispatch(listTemplate({ templateListConfirmation: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    placeHolderPair: builder.mutation({
      query: (data) => ({
        url: `/placeholder/pair`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listTemplate({ placehoderPair: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  useCreateTemplateMutation,
  useListTemplateMutation,
  usePlaceHoldersMutation,
  useUpdateTemplateMutation,
  useActivateTemplateMutation,
  useDeActivateTemplateMutation,
  useDelTemplateMutation,
  usePlaceHolderPairMutation,
  useRawListTemplateMutation,
  useDefaultTemplateMutation,
  useDuplicateTemplateMutation,
  useDefaultTemplateListMutation,
  useRawListTemplateConfirmationMutation,
} = templateApiSlice;
