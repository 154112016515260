import { Box } from '@chakra-ui/react';
import Button from 'Library/Button';

const TabSearch = () => {
  return (
    <Box
      mt="26px"
      mb="28px"
      mx={0}
      sx={{
        button: {
          padding: '16px',
          backgroundColor: '#6930CA0D',
          borderRadius: '4px',
        },
        display: 'inline-flex',
      }}
    >
      <Button variant="outline">Talently Search</Button>
    </Box>
  );
};

export default TabSearch;
