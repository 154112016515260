import { Box, Td, Tr, Icon, Flex, Link } from '@chakra-ui/react';
import TrLoading from 'components/app/TrLoading';
import { useEffect, useState } from 'react';
import { BsChevronDown, BsChevronRight, BsChevronUp } from 'react-icons/bs';
import { useWeeklyActivityListMutation } from 'store/dashboard.slice';
import { formatDigits } from 'utils/utilFormat';
import ByWeek from './ByWeek';

interface TrActivitiesInt {
  item: any;
  index: number;
  activities: any[];
  selectByUser: any;
  BreakDownclick: (type: string, week: number) => void;
  dayBreakclick: (type: string, date: any) => void;
}

const TrActivities = ({
  item,
  index,
  activities,
  selectByUser,
  BreakDownclick,
  dayBreakclick,
}: TrActivitiesInt) => {
  const [open, setOpen] = useState(false);
  const [reqWeekly, resWeekly] = useWeeklyActivityListMutation();
  const [weeklyData, setWeeklyData] = useState<any[]>([]);
  const openBreak = (item: any) => {
    setOpen(!open);
  };
  useEffect(() => {
    // Comment: send recruiters which is the userid when fetching for individual dashboard. its null when its a team dashboard
    if (open) {
      // Comment: send recruiters which is the userid when fetching for individual dashboard. its null when its a team dashboard
      reqWeekly({ data: { week: item.label, recruiters: selectByUser } });
    }
  }, [open, selectByUser]);
  useEffect(() => {
    if (resWeekly.isSuccess) {
      setWeeklyData(resWeekly.data?.data);
    }
  }, [resWeekly.isSuccess]);

  const clickBreakDown = (event: any, type: string) => {
    event.stopPropagation();
    BreakDownclick(type, Number(item.label));
  };

  return (
    <>
      <Tr
        cursor="pointer"
        key={`activity-logs-${item.label}`}
        sx={{
          fontSize: '16px',
          lineHeight: '16px',
          fontWeight: 700,
        }}
        onClick={() => openBreak(item)}
      >
        <Td
          sx={{
            bg: '#DEE2E6 !important',
            border: '1px solid #bababa !important',
            borderTopColor: '#e1e1e1',

            ...(index == activities.length - 1
              ? {
                  borderBottomColor: '#6930CA !important',
                }
              : { borderBottomColor: '#e1e1e1' }),
          }}
          // width={'230px'}
        >
          <Flex alignItems="center" width="100%" gap="10px">
            <Box width="16px">
              {open ? <Icon as={BsChevronUp} /> : <Icon as={BsChevronDown} />}
            </Box>
            Week {item.label}
          </Flex>
        </Td>
        <Td
          style={{
            border: '1px solid #e1e1e1',
          }}
          sx={{
            ...(index == activities.length - 1 && {
              borderBottomColor: '#6930CA !important',
            }),
          }}
          textAlign="center"
        >
          {Number(item.new_leads_talently) !== 0
            ? <Link onClick={(e) => clickBreakDown(e, 'new leads talently')}>
            {item.new_leads_talently}
          </Link>
            : '-'}
        </Td>
        <Td
          style={{
            border: '1px solid #e1e1e1',
            borderRightColor: '#bababa',
          }}
          sx={{
            ...(index == activities.length - 1 && {
              borderBottomColor: '#6930CA !important',
            }),
          }}
          textAlign="center"
        >
          {Number(item.new_leads_repeat) !== 0 ? (
            <Link onClick={(e) => clickBreakDown(e, 'new leads repeat')}>
              {item.new_leads_repeat}
            </Link>
          ) : (
            '-'
          )}
        </Td>
        <Td
          style={{
            border: '1px solid #bababa',
            borderTopColor: '#e1e1e1',
            borderBottomColor: '#e1e1e1',
          }}
          sx={{
            ...(index == activities.length - 1 && {
              borderBottomColor: '#6930CA !important',
            }),
          }}
          textAlign="center"
        >
          {Number(item.new_client_interview) !== 0 ? (
            <Link onClick={(e) => clickBreakDown(e, 'new client w/ interview')}>
              {item.new_client_interview}
            </Link>
          ) : (
            '-'
          )}
        </Td>
        <Td
          style={{ border: '1px solid #e1e1e1' }}
          textAlign="center"
          sx={{
            ...(index == activities.length - 1 && {
              borderBottomColor: '#6930CA !important',
            }),
          }}
        >
          {Number(item.job_posted) !== 0 ? item.job_posted : '-'}
        </Td>
        <Td
          style={{
            border: '1px solid #e1e1e1',
            borderRightColor: '#bababa',
          }}
          sx={{
            ...(index == activities.length - 1 && {
              borderBottomColor: '#6930CA !important',
            }),
          }}
          textAlign="center"
        >
          {Number(item.job_total) !== 0 ? item.job_total : '-'}
        </Td>
        <Td
          style={{
            border: '1px solid #bababa',
            borderTopColor: '#e1e1e1',
            borderBottomColor: '#e1e1e1',
          }}
          sx={{
            ...(index == activities.length - 1 && {
              borderBottomColor: '#6930CA !important',
            }),
          }}
          textAlign="center"
        >
          {Number(item.submits) !== 0 ? (
            <Link onClick={(e) => clickBreakDown(e, 'submits')}>
              {item.submits}
            </Link>
          ) : (
            '-'
          )}
        </Td>
        <Td
          style={{
            border: '1px solid #bababa',
            borderRightColor: '#e1e1e1',
            borderTopColor: '#e1e1e1',
            borderBottomColor: '#e1e1e1',
          }}
          sx={{
            ...(index == activities.length - 1 && {
              borderBottomColor: '#6930CA !important',
            }),
          }}
          textAlign="center"
        >
          {Number(item.first_interview) !== 0 ? (
            <Link onClick={(e) => clickBreakDown(e, 'first interview')}>
              {item.first_interview}
            </Link>
          ) : (
            '-'
          )}
        </Td>
        <Td
          style={{
            border: '1px solid #bababa',
            borderLeftColor: '#e1e1e1',
            borderTopColor: '#e1e1e1',
            borderBottomColor: '#e1e1e1',
          }}
          sx={{
            ...(index == activities.length - 1 && {
              borderBottomColor: '#6930CA !important',
            }),
          }}
          textAlign="center"
        >
          {Number(item.interviews) !== 0 ? (
            <Link onClick={(e) => clickBreakDown(e, 'interviews')}>
              {item.interviews}
            </Link>
          ) : (
            '-'
          )}
        </Td>
        <Td
          style={{
            border: '1px solid #bababa',
            borderTopColor: '#e1e1e1',
            borderBottomColor: '#e1e1e1',
          }}
          sx={{
            ...(index == activities.length - 1 && {
              borderBottomColor: '#6930CA !important',
            }),
          }}
          textAlign="center"
        >
          {Number(item.offers) !== 0 ? (
            <Link onClick={(e) => clickBreakDown(e, 'offers')}>
              {item.offers}
            </Link>
          ) : (
            '-'
          )}
        </Td>
        <Td
          style={{
            border: '1px solid #bababa',
            borderTopColor: '#e1e1e1',
            borderBottomColor: '#e1e1e1',
          }}
          sx={{
            ...(index == activities.length - 1 && {
              borderBottomColor: '#6930CA !important',
            }),
          }}
          textAlign="center"
        >
          {Number(item.placements) !== 0 ? (
            <Link onClick={(e) => clickBreakDown(e, 'placements')}>
              {item.placements}
            </Link>
          ) : (
            '-'
          )}
        </Td>

        <Td
          sx={{
            color: Number(item.revenue) !== 0 && '#D69E2E',
            ...(index == activities.length - 1 && {
              borderBottomColor: '#6930CA !important',
            }),
          }}
          style={{
            border: '1px solid #bababa',
            borderTopColor: '#e1e1e1',
            borderBottomColor: '#e1e1e1',
          }}
          textAlign="center"
        >
          {Number(item.revenue) !== 0 ? formatDigits(item.revenue) : '-'}
        </Td>
        <Td
          style={{
            border: '1px solid #bababa',
            borderTopColor: '#e1e1e1',
            borderBottomColor: '#e1e1e1',
          }}
          sx={{
            ...(index == activities.length - 1 && {
              borderBottomColor: '#6930CA !important',
            }),
          }}
          textAlign="center"
        >
          {Number(item.blasted) ? (
              <Link onClick={(e) => clickBreakDown(e, 'blasted')}>{item.blasted}</Link>
          ) : (
            '-'
          )}
        </Td>
        <Td
          style={{
            border: '1px solid #bababa',
            borderTopColor: '#e1e1e1',
            borderBottomColor: '#e1e1e1',
          }}
          sx={{
            ...(index == activities.length - 1 && {
              borderBottomColor: '#6930CA !important',
            }),
          }}
          textAlign="center"
        >
          {Number(item.calls) !== 0 ? item.calls : '-'}
        </Td>
        <Td
          style={{
            border: '1px solid #bababa',
            borderTopColor: '#e1e1e1',
            borderBottomColor: '#e1e1e1',
          }}
          sx={{
            ...(index == activities.length - 1 && {
              borderBottomColor: '#6930CA !important',
            }),
          }}
          textAlign="center"
        >
          {Number(item.emails) !== 0 ? item.emails : '-'}
        </Td>
        <Td
          style={{
            border: '1px solid #bababa',
            borderTopColor: '#e1e1e1',
            borderBottomColor: '#e1e1e1',
          }}
          sx={{
            ...(index == activities.length - 1 && {
              borderBottomColor: '#6930CA !important',
            }),
          }}
          textAlign="center"
        >
          {Number(item.sms) !== 0 ? item.sms : '-'}
        </Td>
        <Td
          style={{
            border: '1px solid #bababa',
            borderTopColor: '#e1e1e1',
            borderBottomColor: '#e1e1e1',
          }}
          sx={{
            ...(index == activities.length - 1 && {
              borderBottomColor: '#6930CA !important',
            }),
          }}
          textAlign="center"
        >
          {Number(item.inmail) !== 0 ? item.inmail : '-'}
        </Td>
      </Tr>
      {open ? (
        resWeekly.isLoading ? (
          <TrLoading rows={7} columns={16} />
        ) : (
          <ByWeek
            data={weeklyData}
            key={`weekly-logs-${item.label}`}
            dayBreakclick={(type: any, date: any) => dayBreakclick(type, date)}
          />
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default TrActivities;
