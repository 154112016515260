import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateAI } from 'store/ai.slice';
import { AI_LIST } from '../Rewrite/constants';

interface useGenerateServicesProps {
  setFieldValue: any;
  handleInsert: () => void;
}
export const useGenerateServices = ({
  setFieldValue,
  handleInsert,
}: useGenerateServicesProps) => {
  const dispatch = useDispatch();

  const { aiList } = useSelector((state: any) => state.ai);

  const [active, setActive] = useState({
    subject: {
      item: null,
      value: '',
    },
    body: {
      item: null,
      value: '',
    },
  });

  const handleInsertButton = async () => {
    if (active.subject.value)
      await setFieldValue('subject', active.subject.value);
    if (active.body.value) await setFieldValue('body', active.body.value);

    handleInsert();

    setActive({
      subject: {
        item: null,
        value: '',
      },
      body: {
        item: null,
        value: '',
      },
    });

    dispatch(updateAI({ aiList: AI_LIST }));
  };

  return { aiList, active, setActive, handleInsertButton };
};
