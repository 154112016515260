import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  Flex,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';

interface Props {
  title: React.ReactNode;
  description: any;
  onClose?: () => void;
}
export default function FormErrorAlert({ title, description, onClose }: Props) {
  return (
    <Box>
      <Alert status="error" alignItems="flex-start" justifyContent="flex-start">
        <Flex gap="20px" position="relative" left="20px">
          <AlertIcon />
          <Box>
            <AlertTitle>{title}</AlertTitle>
            <AlertDescription>{description}</AlertDescription>
          </Box>
        </Flex>

        {onClose && (
          <CloseButton position="absolute" right="20px" onClick={onClose} />
        )}
      </Alert>
    </Box>
  );
}
