import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import moment from 'moment';
import 'moment-timezone';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { Tooltip } from 'react-tooltip';
import { getUser } from 'store/user.slice';
import FAIcon from './lib/FAIcon';

interface ProfileCardProps {
  id: any;
  user_id: any;
  name: any;
  email: any;
  thumbnail?: any;
  profile: any;
}

const ProfileCard = ({ id, user_id, name, email, thumbnail, profile }: ProfileCardProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  let boxBg = useColorModeValue('white !important', '#111c44 !important');
  let mainText = useColorModeValue('gray.800', 'white');
  let secondaryText = useColorModeValue('gray.400', 'gray.400');

  const handleClick = () => {
    dispatch(getUser({
      currentUser: {
        id: user_id,
        name: name,
        email: email,
        profile: profile
      }
    }))
    navigate(`/profile/${parseInt(user_id)}`)
  }
  return (
    <>
      <Box id={`accTooltip${id}`} cursor="pointer">
        {name}
      </Box>
      <Tooltip
        anchorSelect={`#accTooltip${id}`}
        place="bottom"
        style={{
          background: '#fff',
          opacity: 1,
          border: '1px solid #E2E8F0',
          boxShadow: '0px -3px 7px rgba(0, 0, 0, 0.05)',
        }}
        clickable
      >
        <Flex
          bg={boxBg}
          p="5px"
          pt="15px"
          h="100%"
          w={{ base: '215px', md: '245px' }}
          alignItems="center"
          direction="column"
        >
          {(thumbnail !== "" && thumbnail !== null && thumbnail)
            ? <Image
              src={thumbnail}
              maxW="100%"
            />
            // ? <Image
            //   src="https://pm1.narvii.com/5806/aada0e38da33e4a8e697edf596a52a3a0f2cc40e_00.jpg"
            //   maxW="100%"
            // />
            : <Image
              src="https://pm1.narvii.com/5806/aada0e38da33e4a8e697edf596a52a3a0f2cc40e_00.jpg"
              maxW="100%"
            />
          }

          <Box w="100%" px="15px" mt="20px" mb="10px">
            <Text fontWeight="600" color={mainText} fontSize="xl">
              {name}
            </Text>
          </Box>
          <Box w="100%" px="15px">
            <Flex gap="10px" alignItems="center">
              <Box color="primary.800">
                <FAIcon iconName="envelope" />
              </Box>
              <Text
                color={secondaryText}
                textAlign="center"
                fontSize="sm"
                fontWeight="500"
              >
                <Link
                  href={`mailto:${email}`}
                  target="_blank"
                  rel="noreferrer"
                  fontSize="sm"
                >
                  {email}
                </Link>
              </Text>
            </Flex>
            <Flex gap="10px" alignItems="center">
              <Box color="primary.800">
                <FAIcon iconName="clock" />
              </Box>
              <Text
                color={secondaryText}
                textAlign="center"
                fontSize="sm"
                fontWeight="500"
              >
                {moment().tz('America/Los_Angeles').format('hh:mma')} PST
              </Text>
            </Flex>
            <Button
              mt="20px"
              w="100%"
              onClick={handleClick}>
              View Profile
            </Button>
          </Box>
        </Flex>
      </Tooltip>
    </>
  );
};

export default ProfileCard;
