export const suppressionListTemp = [
  {
    id: 1,
    company: 'Seven States Power Corporation',
    type: 'Small Business (below 50)',
    domain: '---',
    duration: 'Permanent',
    date_created: 'February 27, 2024',
    description: ''
  },
  {
    id: 2,
    company: 'Seven States Power Corporation',
    type: 'Small Business (below 50)',
    domain: '---',
    duration: 'Permanent',
    date_created: 'February 27, 2024',
    description: 'abc'
  },
  {
    id: 3,
    company: 'Seven States Power Corporation',
    type: 'Small Business (below 50)',
    domain: '---',
    duration: 'Permanent',
    date_created: 'February 27, 2024',
    description: ''
  },
  {
    id: 4,
    company: 'Seven States Power Corporation',
    type: 'Small Business (below 50)',
    domain: '---',
    duration: 'Permanent',
    date_created: 'February 27, 2024',
    description: ''
  },
];
