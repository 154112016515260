import Select from 'react-select';
export default function AtsSelect3(FieldProps: any) {
  const onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName(`${FieldProps.className}__option--is-selected`)[0];
      if (selectedEl) {
        selectedEl.scrollIntoView({ behavior: 'auto', block: 'nearest' });
      }
    }, 10);
  };

  return (
    <Select
      options={FieldProps.options}
      {...FieldProps}
      onChange={(option) => {
        if (typeof FieldProps.onChange === 'function') {
          FieldProps.onChange(option);
        }
      }}
      isMulti={FieldProps.isMulti}
      defaultValue={FieldProps.defaultValue || FieldProps.value}
      isLoading={FieldProps.isLoading}
      placeholder={FieldProps.placeholder}
      menuIsOpen={FieldProps.menuIsOpen}
      styles={FieldProps.styles}
      menuPosition={FieldProps.menuPosition}
      isSearchable={FieldProps.isSearchable}
      onBlur={FieldProps.onBlur}
      onMenuOpen={onMenuOpen}
    />
  );
}
