import { Button as ChakraButton, IconButton } from '@chakra-ui/react';
import { FC } from 'react';
import FAIcon from '../components/lib/FAIcon';

/* type= "button" | "reset" | "submit" */

interface ButtonProps {
  target?: string;
  href?: string;
  onClick?: any;
  loading?: boolean;
  leftIcon?: string;
  rightIcon?: string;
  iconName?: string;
  iconType?: string;
  iconColor?: string;
  iconSize?: string;
  htmlType?: string;
  children?: any;
  variant?: string;
  size?: string;
  type?: string;
  [rest: string]: any;
}

const Button: FC<ButtonProps> = (props): JSX.Element => {
  if (!props.children) {
    return (
      <IconButton
        variant={props.variant}
        onClick={props.onClick}
        isLoading={props.loading}
        type={props.htmlType}
        icon={
          props.iconName ? (
            <FAIcon
              iconName={props.iconName}
              type={props.iconType}
              iconColor={props.iconColor}
            />
          ) : null
        }
        color={props.iconColor}
        size={props.iconSize}
        {...props.rest}
      />
    );
  }

  return (
    <ChakraButton
      variant={props.variant}
      children={props.children}
      onClick={props.onClick}
      isLoading={props.loading}
      isDisabled={props.disabled}
      type={props.htmlType}
      size={props.size}
      leftIcon={
        props.leftIcon ? (
          <FAIcon
            iconName={props.leftIcon}
            type={props.iconType}
            iconColor={props.iconColor}
          />
        ) : null
      }
      rightIcon={
        props.rightIcon ? (
          <FAIcon
            iconName={props.rightIcon}
            type={props.iconType}
            iconColor={props.iconColor}
          />
        ) : null
      }
      {...props.rest}
    />
  );
};

export default Button;
