import { Box, Flex, Spacer, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BG from 'assets/images/bg.svg';
import AdminDashboard from './AdminDashboard';
import RecruiterDashboard from './RecruiterDashboard';
import AdminSwitch from './AdminSwtich';
import moment from 'moment';
// import useSocket from 'services/useSocket';

const Dashboard = () => {
  const { deals } = useSelector((state: any) => state.dashboard);
  const { first_name, manager, id } = useSelector((state: any) => state.auth);
  const { hasSwitchedAccount } = useSelector((state: any) => state.app);
  const [isAdmin, setIsAdmin] = useState<boolean>(manager || false);
  const [greeting, setGreeting] = useState('');
  const [userFirstName, setUserFirstName] = useState<string>(first_name);
  const [switchValue, setSwitchValue] = useState('team');

  // note : this is for testing only
  // This section is for testing purposes only. It defines the room ID based on a given user ID.
  // If the user ID is defined, it concatenates it with '_user_room' to form the room ID.
  // If no user ID is present, it sets roomId to undefined, which will prevent socket connection initialization.
  const roomId = id ? `${id}_user_room` : undefined;

  // This utilizes a custom React hook, useSocket, to establish a socket connection.
  // The hook returns a socket object if connected, otherwise null. It uses the roomId to connect to a specific room.
  // const socket = useSocket(roomId);

  // This useEffect hook is responsible for setting up and cleaning up event listeners related to the socket.
  // useEffect(() => {
  //   // Log the current state of socket and roomId for debugging purposes.
  //   console.log({ socket, roomId });

  //   // Check if the socket is connected and available.
  //   if (socket) {
  //     // Once the socket is ready, set up a listener for 'test-room-message' events.
  //     // When a message is received, it logs the data to the console.
  //     socket.on('test-room-message', (data: any) => {
  //       console.log('Received message:', data);
  //     });

  //     // Return a cleanup function that removes the message listener when the component unmounts,
  //     // or when the socket connection changes, to prevent memory leaks and redundant listeners.
  //     return () => {
  //       console.log('Cleaning up message listeners.');
  //       socket.off('test-room-message');
  //     };
  //   }
  // }, [socket]); // Dependency array includes socket to re-run the effect when the socket instance changes.

  //this will be needed for the first load, sometimes first_name seems to be undefined onmount
  useEffect(() => {
    setUserFirstName(first_name);
    setIsAdmin(manager || false);
  }, [first_name]);

  useEffect(() => {
    if (hasSwitchedAccount !== null) {
      setUserFirstName(
        hasSwitchedAccount?.first_name ?? hasSwitchedAccount?.last_name
      );
    } else if (hasSwitchedAccount === null) {
      setUserFirstName(first_name);
    }
  }, [hasSwitchedAccount?.first_name, hasSwitchedAccount]);

  useEffect(() => {
    const currentHour = moment().hour();

    const splitAfternoon = 12; // 24hr time to split the afternoon
    const splitEvening = 17; // 24hr time to split the evening

    if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
      // Between 12 PM and 5PM
      setGreeting('Good afternoon');
    } else if (currentHour >= splitEvening) {
      // Between 5PM and Midnight
      setGreeting('Good evening');
    } else {
      // Between dawn and noon
      setGreeting('Good morning');
    }
  }, []);

  const today = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const switchOnChange = (val: string) => {
    setSwitchValue(val);
    setIsAdmin(val == 'team' ? true : false);
  };

  // return isAdmin ? <AdminDashboard /> : <RecruiterDashboard />;
  return (
    <Box
      sx={{
        background: '#F5F5F5',
        backgroundImage: BG,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        fontFamily: 'NunitoSans Regular',
        p: '24px',
      }}
    >
      <Flex justifyContent="space-between" mb="6">
        <Box
          sx={{
            fontSize: '24px',
            lineHeight: '29.23px',
            fontWeight: 700,
            mb: '24px',
            color: '#fff',
          }}
        >
          {greeting}, {userFirstName}!
        </Box>
        <Spacer />
        <VStack spacing={4} align="stretch">
          <Box
            textAlign="right"
            sx={{
              fontSize: '15px',
              fontWeight: 700,
              color: '#fff',
            }}
          >
            Today is {today}
          </Box>
          {manager && (
            <AdminSwitch
              value={switchValue}
              onToggle={(value) => switchOnChange(value)}
            />
          )}
        </VStack>
      </Flex>
      {isAdmin ? <AdminDashboard /> : <RecruiterDashboard />}
    </Box>
  );
};
export default Dashboard;
