import * as Yup from 'yup';
import {
    Box,
    Checkbox,
    CloseButton,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Input,
    InputGroup,
    InputRightElement,
    Radio,
    Stack,
    TabPanels,
    Tag,
    TagCloseButton,
    TagLabel,
    Text,
    Button as ChakraButton,
    ModalFooter
} from '@chakra-ui/react';
import ScrollToFieldError from 'components/app/ScrollError';
import { Field, FieldArray, Formik } from 'formik';
import { CheckIcon } from '@chakra-ui/icons';
import { useState } from 'react';

interface initialValueInt {
    tags: string[];
}

interface CandidateTagProps {
    initialValues: initialValueInt;
    onSubmit: (e: any) => void;
    CandidateModalFooter?: any;
}

export default function CandidateTag({
    initialValues,
    onSubmit,
    CandidateModalFooter,
}: CandidateTagProps) {
    const [hasTags, setHasTags] = useState('');

    return (
        <>
            <Formik
                initialValues={initialValues}
                // validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {({
                    values,
                    handleChange,
                    handleSubmit,
                    errors,
                    touched,
                    isValid,
                    setFieldValue,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <ScrollToFieldError />
                        {/* Tags */}
                        <Box
                            overflowY="scroll"
                            minHeight="450px"
                            p="30px"
                            background="#fff"
                            borderRadius="8px"
                        >
                            <FormControl>
                                <FieldArray name="tags">
                                    {({ push }) => {
                                        const handleKeyDown = (e: any) => {
                                            if (e.key === 'Enter') {
                                                push(hasTags);
                                                setHasTags('');
                                            }
                                        };
                                        return (
                                            <FormControl mb="16px">
                                                <Flex
                                                    justifyContent="space-between"
                                                    alignItems="flex-end"
                                                >
                                                    <FormLabel
                                                        fontSize="14px"
                                                        lineHeight="18px"
                                                        color="default.secondarytext"
                                                    >
                                                        Add Tags
                                                    </FormLabel>
                                                </Flex>
                                                <InputGroup>
                                                    <Input
                                                        name="tag"
                                                        id="tag"
                                                        placeholder="Type Tag Name"
                                                        type="text"
                                                        variant="outline"
                                                        value={hasTags}
                                                        onChange={(e) =>
                                                            setHasTags(e.target.value)
                                                        }
                                                        onKeyDown={handleKeyDown}
                                                    />
                                                    <InputRightElement
                                                        background="#F3EEFB"
                                                        borderRadius="0 4px 4px 0"
                                                        children={
                                                            <CheckIcon color="priamry.800" />
                                                        }
                                                    />
                                                </InputGroup>
                                                <Input display="none" />
                                            </FormControl>
                                        );
                                    }}
                                </FieldArray>
                                <FieldArray name="tags">
                                    {({ remove }) => (
                                        <Flex gap="10px" display="inline-block">
                                            {values.tags?.length > 0 &&
                                                values.tags?.map((tag, index) => (
                                                    <Tag
                                                        style={{
                                                            display: 'inline-block',
                                                        }}
                                                        key={index}
                                                        mr="10px"
                                                        mb="10px"
                                                    >
                                                        <Stack spacing={1}>
                                                            <HStack>
                                                                <TagLabel maxW="100px">
                                                                    {tag as String}
                                                                </TagLabel>
                                                                <TagCloseButton
                                                                    onClick={() => {
                                                                        remove(index);
                                                                    }}
                                                                />
                                                            </HStack>
                                                        </Stack>
                                                    </Tag>
                                                ))}
                                        </Flex>
                                    )}
                                </FieldArray>
                            </FormControl>
                        </Box>
                        {CandidateModalFooter(handleSubmit, errors, isValid, values)}
                    </form>
                )}
            </Formik>
        </>
    )
};