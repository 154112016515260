import { Input } from '@chakra-ui/react';
import { useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './style.css';

export default function AtsDatePicker(FieldProps: any) {
  return (
    <DatePicker
      {...FieldProps}
      showIcon
      selected={FieldProps.selected}
      onChange={FieldProps.onChange}
      className="ats-datepicker"
      placeholderText="MM/DD/YYYY"
      startDate={FieldProps.startDate}
      endDate={FieldProps.endDate}
      selectsRange={FieldProps.selectsRange}
      isClearable={FieldProps.isClearable}
    />
  );
}
