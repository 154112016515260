export function replacePlaceholdersWithSpans(html: string) {
  // Regular expression to match {{...}} pattern
  const regex = /\{\{(.*?)\}\}/g;

  // Replace each match with the desired <span> structure
  return (
    html?.replace(regex, (match, p1) => {
      return `<span  data-variable="${p1}" >${match}</span>`;
    }) ?? ''
  );
}

export function replaceSpansWithPlaceholders(html: string) {
  // Regular expression to match <span data-placeholder="true" class="placeholder-node" ...>...</span>
  const regex =
    /<span\s+data-placeholder="true"\s+class="placeholder-node"(?:\s+[^>]*)?\s+variablename="(.*?)">.*?<\/span>/g;

  // Replace each match with the {{...}} format
  return (
    html?.replace(regex, (match, variableName) => {
      return `{{${variableName}}}`;
    }) ?? ''
  );
}
