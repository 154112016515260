export const STATUS_LABEL: any = {
  ongoing: 'Ongoing',
  messaged: 'Messaged',
  opened: 'Opened',
  replied: 'Replied',
  bounced: 'Bounced',
  clicked: 'Clicked',
  bounce_check: 'Bounced Check',
  ats_update: 'ATS Updated',
  no_email: 'No Email',
  take_action: 'Take Action',
  ooo: 'Out of Office',
  li_replied: 'LI Replied',
  li_request: 'LI Request',
  li_connected: 'LI Connected',
};
