import { Box, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import Button from 'Library/Button';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useChangeEducationCandidateMutation,
  useGetCandidatesMutation,
} from 'store/candidates.slice';

interface DegreeInt {
  candidateData: any;
}

const Degree = ({ candidateData }: DegreeInt) => {
  const { educations } = useSelector((state: any) => state.constants);

  const [reqDegree, resDegree] = useChangeEducationCandidateMutation();
  const [reqGetCandidate] = useGetCandidatesMutation();

  const [educ, setEduc] = useState(null);
  const [educLabel, setEducLabel] = useState('None');

  useEffect(() => {
    if (candidateData?.educations?.length > 0) {
      const educs = candidateData?.educations[0];
      setEduc(educs);
      SetEducationLabel(educs);
    }
  }, [candidateData]);

  useEffect(() => {
    if (resDegree.isSuccess) {
      reqGetCandidate({ id: candidateData.id });
    }
  }, [resDegree.isSuccess]);

  const SetEducationLabel = (educs: any) => {
    const label = educations.filter(
      (obj: any) => obj.id === educs.degree_level_id
    );
    if (label.length > 0) setEducLabel(label[0]?.name || 'None');
  };

  const EducChange = async (val: any) => {
    await reqDegree({
      data: {
        degree_level_id: Number(val.id),
        candidate_id: Number(candidateData?.id),
      },
      id: educ?.id,
    });
    setEducLabel(val?.name || 'None');
  };

  return (
    <>
      <Box
        fontSize="16px"
        lineHeight="18px"
        color="default.gray.600"
        textAlign="left"
        width="100px"
      >
        Degree
      </Box>
      <Menu closeOnSelect={true}>
        <MenuButton>
          <Box
            sx={{
              button: {
                p: '4px 8px',
                pl: 0,
                height: '25px',
                fontSize: '14px',
                border: 'none',
                color: 'primary.500',
              },
            }}
          >
            <Button rightIcon="chevron-down">{educLabel}</Button>
          </Box>
        </MenuButton>
        {
          <MenuList fontSize="sm" maxHeight="120px" overflowY="auto">
            {educations.map((val: any, key: number) => {
              return (
                <MenuItem onClick={() => EducChange(val)} key={`degree-${key}`}>
                  {val.name}
                </MenuItem>
              );
            })}
          </MenuList>
        }
      </Menu>
    </>
  );
};

export default Degree;
