import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';

import LeadsDetails from './TabPanel/Details';
import { LeadsJobs } from './TabPanel/Jobs';
import { LeadsAgreements } from './TabPanel/Agreements';
import { LeadsAttachments } from './TabPanel/Attachments';
import { LeadsActivityLog } from './TabPanel/ActivityLog';

import { tabListIndex } from './constants';
import { useTabListServices } from './useTabListServices';
import { LeadsNotes } from './TabPanel/Notes';
import { LeadsEmails } from './TabPanel/Emails';

export const LeadsTabList = () => {
  const { handleRoute, activeTab, TABS } = useTabListServices();
  return (
    <Tabs colorScheme="purple" index={activeTab?.id} onChange={handleRoute}>
      <TabList fontSize="md" fontWeight="bold">
        {TABS.map((tab) => (
          <Tab _focus={{ boxShadow: 'none !important' }}>{tab.display}</Tab>
        ))}
      </TabList>

      <TabPanels>
        <TabPanel>
          {activeTab?.title === 'details' && <LeadsDetails />}
        </TabPanel>
        <TabPanel>{activeTab?.title === 'jobs' && <LeadsJobs />}</TabPanel>
        <TabPanel>
          {activeTab?.title === 'agreements' && <LeadsAgreements />}
        </TabPanel>
        <TabPanel>
          {activeTab?.title === 'attachments' && <LeadsAttachments />}
        </TabPanel>
        <TabPanel>
          {activeTab?.title === 'activity-log' && <LeadsActivityLog />}
        </TabPanel>
        <TabPanel
          sx={{
            p: 0,
          }}
        >
          {activeTab?.title === 'emails' && <LeadsEmails />}
        </TabPanel>
        <TabPanel>{activeTab?.title === 'notes' && <LeadsNotes />}</TabPanel>
      </TabPanels>
    </Tabs>
  );
};
