import { Flex, Skeleton, SkeletonText, Stack } from '@chakra-ui/react';
import React from 'react';

export default function FormLoader() {
  return (
    <Stack p="32px" gap="16px">
      <Flex gap="30px">
        <Skeleton w="50%" h="40px" />
        <Skeleton w="50%" h="40px" />
      </Flex>
      <Flex gap="30px">
        <Skeleton w="50%" h="40px" />
        <Skeleton w="50%" h="40px" />
      </Flex>
      <Stack>
        <SkeletonText noOfLines={1} skeletonHeight="3" w="45%" />
        <Flex gap="30px">
          <Skeleton w="30%" h="40px" />
          <Skeleton w="35%" h="40px" />
          <Skeleton w="35%" h="40px" />
        </Flex>
        <Flex gap="30px">
          <Skeleton w="30%" h="40px" />
          <Skeleton w="35%" h="40px" />
          <Skeleton w="35%" h="40px" />
        </Flex>
        <Flex gap="30px">
          <Skeleton w="30%" h="40px" />
          <Skeleton w="35%" h="40px" />
          <Skeleton w="35%" h="40px" />
        </Flex>
      </Stack>
      <Stack>
        <SkeletonText noOfLines={1} skeletonHeight="3" w="45%" />
        <Flex gap="30px">
          <Skeleton w="100%" h="40px" />
        </Flex>
        <Flex gap="30px">
          <Skeleton w="50%" h="40px" />
          <Skeleton w="50%" h="40px" />
        </Flex>
        <Flex gap="30px">
          <Skeleton w="50%" h="40px" />
          <Skeleton w="50%" h="40px" />
        </Flex>
      </Stack>
    </Stack>
  );
}
