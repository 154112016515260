import { apiSlice } from 'store/api.slice';

export const leadsQuery = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addLead: builder.mutation({
      query: (data) => ({
        url: '/leads/create',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      invalidatesTags: (result, error, arg) =>
        result && !error
          ? [{ type: 'Leads', id: 'Leads List' }, 'Leads-Count']
          : [],
    }),
    addLeadContact: builder.mutation({
      query: (data) => ({
        url: '/leads/create/contact',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      invalidatesTags: (result, error, arg) =>
        result && !error ? [{ type: 'My-Client', id: 'List' }] : [],
    }),
    updateLead: builder.mutation({
      query: ({ data, id }) => ({
        url: `/leads/update/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      invalidatesTags: (result: any, error: any, { id }) =>
        result && !error
          ? [
              { type: 'Leads', id: 'Leads List' },
              { type: 'Leads', id },
            ]
          : [],
    }),
    setLeadInactive: builder.mutation({
      query: ({ id, inactive_reason }) => ({
        url: `/leads/inactive/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: { inactive_reason },
      }),
      invalidatesTags: (result: any, error: any, { id }) =>
        result && !error
          ? [
              { type: 'Leads', id: 'Leads List' },
              { type: 'Leads', id },
              { type: 'Leads-Details', id },
              { type: 'Leads-Details', id: 'LIST' },
              { type: 'Existing-Client-Prospects', id: 'New Prospect list' },
              'Leads-Count',
            ]
          : [],
    }),
    setLeadQualified: builder.mutation({
      query: ({ data, id }) => ({
        url: `/leads/qualified/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      invalidatesTags: (result: any, error: any, { id }) =>
        result && !error
          ? [
              { type: 'Leads', id: 'Leads List' },
              { type: 'Leads', id },
            ]
          : [],
    }),
    setLeadToClient: builder.mutation({
      query: ({ data, id }) => ({
        url: `/leads/convert-to-client/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      invalidatesTags: (result: any, error: any, { id }) =>
        result && !error
          ? [
              { type: 'Leads', id: 'Leads List' },
              { type: 'Leads', id },
            ]
          : [],
    }),
    sendForApproval: builder.mutation({
      query: ({ data, id }) => ({
        url: `/leads/send-for-approval/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      invalidatesTags: (result: any, error: any, { id }) =>
        result && !error
          ? [
              { type: 'Leads', id: 'Leads List' },
              { type: 'Leads', id },
            ]
          : [],
    }),
    setLeadActive: builder.mutation({
      query: ({ id }) => ({
        url: `/leads/re-activate/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      invalidatesTags: (result: any, error: any, { id }) =>
        result && !error
          ? [
              { type: 'Leads', id: 'Leads List' },
              { type: 'Leads', id },
              'Leads-Count',
              { type: 'Existing-Client-Prospects', id: 'New Prospect list' },
            ]
          : [],
    }),
    setCancelApproval: builder.mutation({
      query: ({ id }) => ({
        url: `/leads/cancel-approval/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      invalidatesTags: (result: any, error: any, { id }) =>
        result && !error
          ? [
              { type: 'Leads', id: 'Leads List' },
              { type: 'Leads', id },
            ]
          : [],
    }),
    addLeadTag: builder.mutation({
      query: ({ id, data }) => ({
        url: `/leads/tag/create/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      invalidatesTags: (result: any, error: any, { id }) =>
        result && !error
          ? [
              { type: 'Leads', id: 'Leads List' },
              'Tags',
              { type: 'Leads', id },
              { type: 'Existing-Client-Prospects', id: 'New Prospect list' },
            ]
          : [],
    }),
    setBulkLeadsInactive: builder.mutation({
      query: ({ data }) => ({
        url: `/leads/bulk-inactive`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      invalidatesTags: (result: any, error) =>
        result && !error
          ? [
              { type: 'Leads', id: 'Leads List' },
              { type: 'Existing-Client-Prospects', id: 'New Prospect list' },
              'Leads-Count',
            ]
          : [],
    }),
    addLeadBulkTags: builder.mutation({
      query: ({ data }) => ({
        url: `/leads/tag/bulk-insert`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      invalidatesTags: (result: any, error) =>
        result && !error
          ? [
              { type: 'Leads', id: 'Leads List' },
              'Tags',
              { type: 'Existing-Client-Prospects', id: 'New Prospect list' },
            ]
          : [],
    }),
    sendLeadsBulkEmail: builder.mutation({
      query: ({ data }) => ({
        url: `/leads/email/bulk/send`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
    }),
    updateContactLead: builder.mutation({
      query: ({ data, id }) => ({
        url: `/leads/update-contact/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
    }),
    setLeadClaim: builder.mutation({
      query: ({ data, id }) => ({
        url: `/leads/claim/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      invalidatesTags: (result: any, error: any, { id }) =>
        result && !error
          ? [
              { type: 'Leads', id: 'Leads List' },
              { type: 'Leads', id },
              'Leads-Count',
            ]
          : [],
    }),
    addLeadNotes: builder.mutation({
      query: ({ data, id }) => ({
        url: `/lead-notes/create/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      invalidatesTags: (result: any, error: any, { id }) =>
        result && !error
          ? [
              { type: 'Leads', id: 'Leads List' },
              { type: 'Leads-Details', id: 'Detail' },
              { type: 'Leads-Details', id },
            ]
          : [],
    }),
    updateLeadStage: builder.mutation<
      any,
      { data: { stage: number }; id: number }
    >({
      query: ({ data, id }) => ({
        url: `/leads/update/stage/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      invalidatesTags: (result: any, error: any, { id }) =>
        result && !error
          ? [
              { type: 'Leads', id: 'Leads List' },
              { type: 'Leads-Kanban' },
              { type: 'Leads-Details', id: 'Detail' },
              { type: 'Leads-Details', id },
            ]
          : [],
    }),
  }),
});

export const {
  useAddLeadContactMutation,
  useUpdateLeadMutation,
  useSendForApprovalMutation,
  useAddLeadMutation,
  useSetBulkLeadsInactiveMutation,
  useSetCancelApprovalMutation,
  useSetLeadActiveMutation,
  useSetLeadClaimMutation,
  useUpdateContactLeadMutation,
  useSetLeadToClientMutation,
  useSetLeadQualifiedMutation,
  useSetLeadInactiveMutation,
  useAddLeadNotesMutation,
  useUpdateLeadStageMutation,
  useSendLeadsBulkEmailMutation,
  useAddLeadBulkTagsMutation,
  useAddLeadTagMutation,
} = leadsQuery;
