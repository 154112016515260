import { Box, Button, Flex, Image, Stack, Text } from '@chakra-ui/react';
import MdError from 'assets/images/ai-generator/MdError.svg';
import FAIcon from 'components/lib/FAIcon';

export const ErrorPage = ({ handleGenerate, isLoading }: any) => {
  return (
    <Stack
      id="ai"
      justifyContent="center"
      sx={{
        width: '50%',
        border: '1px solid #EEEEEE',
        borderRadius: '6px',
        my: '16px',
        mr: '32px',
        p: '16px',
        fontFamily: 'NunitoSans Regular',
      }}
    >
      <Stack spacing="10px" justifyContent="center" alignItems="center">
        <Image src={MdError} />
        <Stack>
          <Text sx={{ fontSize: '12px', color: '#718096' }}>
            An error occurred.
          </Text>
          <Button
            variant="borderless"
            leftIcon={
              <Box>
                <FAIcon iconName="refresh" />
              </Box>
            }
            sx={{
              height: '18px',
              p: 0,
              fontSize: '12px',
              fontWeight: 400,
              color: '#EA6E6E',
            }}
            isLoading={isLoading}
            onClick={() => handleGenerate()}
            _hover={{}}
          >
            Retry
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
