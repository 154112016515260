import BreakDown from './Breakdown';

interface ByWeekInt {
  data: any[];
  dayBreakclick: (type: string, date: string) => void;
}
const ByWeek = ({ data, dayBreakclick }: ByWeekInt) => {
  return (
    <>
      {data.map((item: any, key: number) => {
        return (
          <BreakDown
            data={item}
            key={item.day_start_date}
            index={key}
            dayBreakclick={(type, date) => dayBreakclick(type, date)}
          />
        );
      })}
    </>
  );
};

export default ByWeek;
