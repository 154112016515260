import { Skeleton, Td, Tr } from '@chakra-ui/react';

interface trInterface {
  rows: number;
  columns: number;
}

export default function TrLoading({ rows, columns }: trInterface) {
  return (
    <>
      {Array.from(Array(rows), (e, i) => {
        return (
          <Tr key={i + 'loading-skeleton'}>
            <Td colSpan={columns}>
              <Skeleton height="30px" />
            </Td>
          </Tr>
        );
      })}
    </>
  );
}
