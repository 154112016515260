import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  listClients,
  navClient,
  useViewClientMutation,
} from 'store/client.slice';
import { listContact } from 'store/contact.slice';

const useFetchClientData = (): any => {
  const dispatch = useDispatch();
  const params = useParams();
  const [reqGetClient, resGetClient] = useViewClientMutation();

  const loadClient = async (clientId: number) => {
    await reqGetClient({ id: clientId });
  };

  useEffect(() => {
    if (params.clientsId && !isNaN(Number(params.clientsId))) {
      loadClient(Number(params.clientsId));
    }
  }, [params.clientsId]);

  useEffect(() => {
    if (params.clientsTabId) {
      switch (params.clientsTabId) {
        case 'my-clients':
          dispatch(
            navClient({ clientNav: 'my-clients', clientTab: 'my-clients' })
          );
          break;
        case 'all-clients':
          dispatch(
            navClient({ clientNav: 'all-clients', clientTab: 'all-clients' })
          );
          break;
        case 'contacts':
          dispatch(navClient({ clientNav: 'contacts', clientTab: 'contacts' }));
          break;
        default:
          dispatch(
            navClient({ clientNav: 'all-clients', clientTab: 'all-clients' })
          );
      }
    }
  }, [params.clientsTabId]);

  return { resGetClient };
};

export default useFetchClientData;
