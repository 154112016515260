import { Box, Flex } from '@chakra-ui/react';
import CandidatesAll from './All';
import CandidatesFilterBar from './VerticalFilterBar';

export default function CandidatesPage() {
  document.documentElement.dir = 'ltr';

  return (
    <Flex gap="8px" mt="4px" p="8px" fontFamily="NunitoSans Regular">
      <CandidatesFilterBar />
      <Box w="calc(100% - 300px)" background="default.white.800">
        <CandidatesAll />
      </Box>
    </Flex>
  );
}
