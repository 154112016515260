/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import {
  useGetSequenceCandidateDataMutation,
  useGetSequenceDataMutation,
  usePostChangeStatusMutation,
  usePostListCandidatesMutation,
} from 'store/outreach.slice';

import { useToast } from '@chakra-ui/react';
import { STATUS_LABEL } from './constants';

interface ChangeStatusServicesProps {
  id: any;
  onClose: any;
}
export const useChangeStatusServices = ({
  id,
  onClose,
}: ChangeStatusServicesProps) => {
  const toast = useToast();
  const params = useParams();

  const initialValues = {
    status: 1,
  };

  const [reqPostChangeStatus, resPostChangeStatus] =
    usePostChangeStatusMutation();
  const [reqCandidateList] = usePostListCandidatesMutation();
  const [reqGetSequenceData] = useGetSequenceDataMutation();
  const [reqGetSequenceCandidateData] = useGetSequenceCandidateDataMutation();
  const { candidateSequencePgBtn } = useSelector(
    (state: any) => state.outreach
  );

  const handleSubmit = (data: any) => {
    const params = {
      status: Number(data.status),
      candidate_sequence_id: id,
    };
    reqPostChangeStatus(params);
  };

  useEffect(() => {
    if (resPostChangeStatus.isSuccess) {
      onClose();
      reqCandidateList(candidateSequencePgBtn);
      reqGetSequenceData({ id: params.settingTypeId });

      if (params.settingSubId) reqGetSequenceCandidateData(params.settingSubId);
      
      toast({
        title: 'Candidate changed status.',
        description: "We've changed your candidate status.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostChangeStatus.isSuccess, params]);

  const { sequencesCandidateStatus } = useSelector(
    (state: any) => state.outreach
  );

  const changeStatusOptions = sequencesCandidateStatus.map((val: any) => {
    return { label: STATUS_LABEL[val.status], value: val.id };
  });

  return {
    initialValues,
    handleSubmit,
    changeStatusOptions,
    isLoading: resPostChangeStatus.isLoading,
  };
};
