import {
  FormControl,
  Flex,
  FormLabel,
  Input,
  FormErrorMessage,
  Stack,
} from '@chakra-ui/react';

import { ErrorMessage, Field, FieldProps } from 'formik';
import { useAIGeneratorServices } from 'components/app/AIGenerator/useAIGeneratorServices';
import AtsEmailBody from 'components/app/Global/Email/AIBody';
import { IntialValueType } from '../../utils/validationSchema';
import { replacePlaceholders } from 'utils/placeholders';
import { useSelector } from 'react-redux';

export default function CustomizePitchQuickForm() {
  const {
    hasAIGenerate,
    setHasAIGenerate,
    setIsGenerate,
    handleValues,
    handleAIGenerator,
    setIsError,
    isMaximize,
  } = useAIGeneratorServices({ hasType: 'Jobs' });
  const placeholderPair = useSelector(
    (state: any) => state.template.placehoderPair
  );

  return (
    <Field>
      {({
        form: { values, errors, touched, setFieldValue },
      }: FieldProps<IntialValueType>) => (
        <Stack justifyContent="space-between" h="inherit">
          <Flex
            justifyContent="center"
            gap="10px"
            sx={{
              overflow: 'hidden',
              '& #ai': {
                m: 0,
                overflowY: 'scroll',
                h: 'inherit',
              },
              '& ::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            <Stack
              h="inherit"
              spacing="10px"
              sx={{
                display: hasAIGenerate && isMaximize ? 'none' : 'flex',
                w: hasAIGenerate !== '' ? '65%' : '100%',
                mr: hasAIGenerate && 0,
              }}
            >
              <Stack
                spacing="16px"
                sx={{
                  background: '#fff',
                  borderRadius: '6px',
                  p: '16px',
                }}
              >
                <FormControl
                  isInvalid={Boolean(!!errors.subject && touched.subject)}
                >
                  <Flex justifyContent="space-between" alignItems="center">
                    <FormLabel lineHeight="18px">Subject</FormLabel>
                  </Flex>

                  <Input
                    name="subject"
                    type="text"
                    placeholder="Email Subject"
                    variant="outline"
                    value={replacePlaceholders(values.subject, placeholderPair)}
                    onChange={(e: any) =>
                      setFieldValue('subject', e.target.value)
                    }
                    sx={{
                      height: '38px',
                      border: '1px solid #E7EDF4',
                      p: '8px 12px',
                      fontSize: '14px',
                    }}
                  />

                  <ErrorMessage name="subject" component={FormErrorMessage} />
                </FormControl>
              </Stack>

              <Stack
                h="45vh"
                sx={{
                  position: 'relative',
                  bgColor: '#FFF',
                  borderRadius: '6px',
                  p: '16px',
                  overflowY: 'hidden',
                  '& ::-webkit-scrollbar': {
                    display: 'none',
                  },
                }}
              >
                <AtsEmailBody
                  id="body"
                  value={values.body}
                  onChange={(e: any) => {
                    setFieldValue('body', e);
                  }}
                  height={220}
                  hasAIGenerate={hasAIGenerate}
                  setHasAIGenerate={setHasAIGenerate}
                  setIsGenerate={setIsGenerate}
                  handleValues={handleValues}
                  setIsError={setIsError}
                  subject={values.subject}
                  body={values.body}
                />

                <ErrorMessage name="body" component={FormErrorMessage} />
              </Stack>
            </Stack>

            {handleAIGenerator(setFieldValue)}
          </Flex>
        </Stack>
      )}
    </Field>
  );
}
