import { Tr, Th } from '@chakra-ui/react';

import TrLoading from 'components/app/TrLoading';

import { TableData } from './SequenceTableData';
import { SubTableData } from './OutreachSequenceTableData';

interface TableContentProps {
  openAccordionItems: any[];
  handlePrimarySequence: (e: any) => void;
  handleActive: (e: any) => void;
  handleDuplicate: (e: any) => void;
  handleArchive: (e: any) => void;
  handleOpenAccordion: (e: any) => void;
  isLoading: boolean;
  sequencesList: any;
}

const thStyle = {
  py: '12px',
  width: '50px',
  fontSize: '10px',
  color: '#9B9B9B',
  letterSpacing: '1.5px',
};

export const TableContent = ({
  openAccordionItems,
  handlePrimarySequence,
  handleActive,
  handleDuplicate,
  handleArchive,
  handleOpenAccordion,
  isLoading,
  sequencesList,
}: TableContentProps) => {
  const sequenceSubTableHeader = [
    {
      content: '',
      sx: {
        py: '15px',
        width: '50px',
      },
    },
    { content: 'Default', sx: thStyle },
    { content: 'Sequence Name', sx: thStyle },
    { content: 'Sender Email', sx: thStyle },
    { content: 'Candidates', sx: thStyle },
    { content: 'Active', sx: thStyle },
    { content: '', sx: {} },
  ];

  if (isLoading) {
    return <TrLoading rows={3} columns={7} />;
  }

  return (
    <>
      {sequencesList?.map((item: any) => {
        return (
          <>
            <Tr
              cursor="pointer"
              _hover={{
                bg: '#FAF5FF',
              }}
              sx={{
                bg: openAccordionItems.includes(item.id) && '#FAF5FF',
                minHeight: '70px',
                td: {
                  py: '10px',
                },
              }}
            >
              <TableData
                openAccordionItems={openAccordionItems}
                handleOpenAccordion={handleOpenAccordion}
                content={item}
              />
            </Tr>

            {openAccordionItems.includes(item.id) &&
              item.outreachsequence &&
              item.outreachsequence?.length > 0 && (
                <>
                  <Tr sx={{ bgColor: 'default.white.600' }}>
                    {sequenceSubTableHeader.map((val, index) => (
                      <Th key={index} sx={val.sx}>
                        {val.content}
                      </Th>
                    ))}
                  </Tr>

                  <SubTableData
                    handlePrimarySequence={handlePrimarySequence}
                    handleActive={handleActive}
                    handleDuplicate={handleDuplicate}
                    handleArchive={handleArchive}
                    content={item.outreachsequence}
                  />
                </>
              )}
          </>
        );
      })}
    </>
  );
};
