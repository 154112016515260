import { useState } from 'react';
import { useSelector } from 'react-redux';

export function useNotesServices() {
  const parse = require('html-react-parser');

  const { leadDetails } = useSelector((state: any) => state.leads);

  const [hover, setHover] = useState({ id: null, hover: null });

  return { parse, leadDetails, hover, setHover };
}
