import { FieldArray, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  Flex,
  InputGroup,
  InputRightElement,
  Tag,
  Stack,
  HStack,
  TagLabel,
  TagCloseButton,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  UnorderedList,
  ListItem,
  CloseButton,
  Checkbox,
} from '@chakra-ui/react';

import { CheckIcon } from '@chakra-ui/icons';

import { cleanUpload } from 'store/uploads.slice';

import AtsDropZone from '../../Dropzone';
import ScrollToFieldError from 'components/app/ScrollError';
import { useReParseMutation } from 'store/resumerparse.slice';
import { useParams } from 'react-router';

interface CandidateUpdateResumeModalProps {
  isOpen: any;
  onClose: any;
}

export default function CandidateUpdateResumeModal({
  isOpen,
  onClose,
}: CandidateUpdateResumeModalProps) {
  const dispatch = useDispatch();
  const params = useParams();
  const { resumeUploaded, resumeAttachments, uploading } = useSelector(
    (state: any) => state.uploads
  );

  const { isOpen: isOpenAlert, onClose: onCloseAlert } = useDisclosure({
    defaultIsOpen: false,
  });

  // USE STATES
  const [isPrimary, setIsPrimary] = useState(true);
  const [hasTags, setHasTags] = useState('');
  const [errMsg] = useState(
    'There was an error processing your request. Change a few things up and try again.'
  );
  const [errorList] = useState({});
  const [reqParse, resParse] = useReParseMutation();

  const submitAgreement = async (data: any) => {
    let form: any = data;
    form['primary'] = isPrimary;
    reqParse({ id: resumeUploaded[0]?.id, data: form });
    onClose();
    dispatch(cleanUpload({ resumeAttachments: [] }));
    dispatch(cleanUpload({ resumeUploaded: [] }));
  };

  let initialValues: any = {
    tags: [],
  };

  const changePrimary = async (e: any) => {
    let primary = e.target.value;
    setIsPrimary(primary);
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onClose();
          dispatch(cleanUpload({ resumeAttachments: [] }));
          dispatch(cleanUpload({ resumeUploaded: [] }));
        }}
        size="3xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="NunitoSans Regular">
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            Update Resume
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          {isOpenAlert && (
            <Box>
              <Alert
                status="error"
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <Flex gap="20px" position="relative" left="20px">
                  <AlertIcon />
                  <Box>
                    <AlertTitle>Oh snap!</AlertTitle>
                    <AlertDescription>
                      {errMsg || (
                        <>
                          There were some problems in you input. Change a few
                          things up and try again.
                          <UnorderedList
                            mt="10px"
                            fontSize="13px"
                            lineHeight="1.5"
                            color="var(--chakra-colors-red-500)"
                          >
                            {Object.values(errorList).map((key: any) => (
                              <ListItem key={key}>{key}</ListItem>
                            ))}
                          </UnorderedList>
                        </>
                      )}
                    </AlertDescription>
                  </Box>
                </Flex>

                <CloseButton
                  position="absolute"
                  right="20px"
                  onClick={onCloseAlert}
                />
              </Alert>
            </Box>
          )}
          <ModalBody borderRadius="0 0 4px 4px" p={0}>
            <Formik initialValues={initialValues} onSubmit={submitAgreement}>
              {({ values, handleSubmit, handleChange }) => (
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />
                  <Box p="32px">
                    <Box mb="34px">
                      <AtsDropZone
                        multipleFile={false}
                        isResume = {true}
                        candidate_id={
                          Number(params.candidateId) ||
                          Number(params.candidatesId) ||
                          Number(params.jobsTabNav)
                        }
                        uploadedEnd={(e: any) => {}}
                        deletedFile={(key: string) => {}}
                      />
                      <Flex gap="32px" alignItems="center">
                        <FormControl mt="15px">
                          <Checkbox
                            id="isPrimary"
                            name="isPrimary"
                            isChecked={isPrimary}
                            onChange={changePrimary}
                          >
                            Make it as primary
                          </Checkbox>
                        </FormControl>
                      </Flex>
                    </Box>
                    <FormControl>
                      <FieldArray name="tags">
                        {({ push }) => {
                          const handleKeyDown = (e: any) => {
                            if (e.key === 'Enter') {
                              push(hasTags);
                              setHasTags('');
                            }
                          };
                          return (
                            <FormControl mb="16px">
                              <Flex
                                justifyContent="space-between"
                                alignItems="flex-end"
                              >
                                <FormLabel
                                  fontSize="14px"
                                  lineHeight="18px"
                                  color="default.secondarytext"
                                >
                                  Add Tags
                                </FormLabel>
                              </Flex>
                              <InputGroup>
                                <Input
                                  name="tag"
                                  id="tag"
                                  placeholder="Type Tag Name"
                                  type="text"
                                  variant="outline"
                                  value={hasTags}
                                  onChange={(e) => setHasTags(e.target.value)}
                                  onKeyDown={handleKeyDown}
                                />
                                <InputRightElement
                                  background="#F3EEFB"
                                  borderRadius="0 4px 4px 0"
                                  children={<CheckIcon color="primary.800" />}
                                />
                              </InputGroup>
                              <Input display="none" />
                            </FormControl>
                          );
                        }}
                      </FieldArray>
                      <FieldArray name="tags">
                        {({ remove }) => (
                          <Flex gap="10px" display="inline-block">
                            {values.tags?.length > 0 &&
                              values.tags?.map((tag: any, index: number) => (
                                <Tag
                                  style={{
                                    display: 'inline-block',
                                  }}
                                  key={index}
                                  mr="10px"
                                  mb="10px"
                                >
                                  <Stack spacing={1}>
                                    <HStack>
                                      <TagLabel maxW="100px">
                                        {tag as String}
                                      </TagLabel>
                                      <TagCloseButton
                                        onClick={() => {
                                          remove(index);
                                        }}
                                      />
                                    </HStack>
                                  </Stack>
                                </Tag>
                              ))}
                          </Flex>
                        )}
                      </FieldArray>
                    </FormControl>
                  </Box>

                  <ModalFooter
                    position="sticky"
                    bottom="0"
                    background="default.white.100"
                    boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                  >
                    <Button
                      variant="solid"
                      type="submit"
                      disabled={uploading || resumeAttachments.length <= 0}
                      isLoading={resParse.isLoading}
                    >
                      Update
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
