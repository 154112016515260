/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import {
  Box,
  Flex,
  Image,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import Button from 'Library/Button';

import MdInfoCircle from 'assets/images/MdInfoCircle.svg';

import { updateOutreach } from 'store/outreach.slice';

import CandidatesListTable from './Table';
import ImportExcelModal from './Modal/ImportExcel';

import CandidatesModal from 'components/app/Candidates/Modals/modal';

import { useCandidateTabPanelServices } from './useCandidateTabPanel';
import { useNavigate, useParams } from 'react-router';

const CandidateTabPanel = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { content, handleExport, sequenceStatus, setSequenceStatus } =
    useCandidateTabPanelServices();

  const {
    isOpen: isOpenAddCandidate,
    onOpen: onOpenAddCandidate,
    onClose: onCloseAddCandidate,
  } = useDisclosure();

  const {
    isOpen: isOpenImportModal,
    onOpen: onOpenImportModal,
    onClose: onCloseImportModal,
  } = useDisclosure();

  return (
    <Box fontFamily="NunitoSans Regular">
      <Stack direction="row" spacing="16px" my="20px">
        {content.map((val: any) => {
          return (
            <Stack
              key={val.title}
              spacing="8px"
              sx={{
                bg: '#fff',
                border: '1px solid',
                borderColor:
                  JSON.stringify(val.status) === JSON.stringify(sequenceStatus)
                    ? '#6930CA'
                    : '#BABABA66',
                borderRadius: '4px',
                p: '14px',
                cursor: 'pointer',
              }}
              onClick={() => {
                setSequenceStatus(val.status);
                navigate(
                  `/engage/${params.settingNav}/${params.settingTab}/${params.settingType}/${params.settingTypeId}/${val.to}`
                );
              }}
            >
              <Flex gap="8px" alignItems="center">
                <Text
                  sx={{ fontSize: '12px', fontWeight: 700, color: '#6B6C7B' }}
                >
                  {val.title}
                </Text>
                <Tooltip
                  label={val.tooltip}
                  placement="top"
                  bg="#6930CA"
                  borderRadius="2px"
                  p="6px 9px"
                  color="#fff"
                  maxW="200px"
                  textAlign="center"
                  hasArrow
                >
                  <Box>
                    <Image sx={{ height: '16px' }} src={MdInfoCircle} />
                  </Box>
                </Tooltip>
              </Flex>
              <Text
                sx={{ fontSize: '20px', fontWeight: 700, color: '#2B2D42' }}
              >
                {val.value}
              </Text>
            </Stack>
          );
        })}
      </Stack>
      <Flex justifyContent="flex-end" mb="20px">
        <Flex gap={2}>
          <Button leftIcon="file" onClick={handleExport}>
            Export CSV
          </Button>
          <Button leftIcon="file" onClick={onOpenImportModal}>
            Import Excel
          </Button>
          <Button
            leftIcon="plus"
            variant="solid"
            onClick={() => {
              onOpenAddCandidate();
              dispatch(updateOutreach({ sequenceCandidateData: {} }));
            }}
          >
            Add Candidate
          </Button>
        </Flex>
      </Flex>

      <CandidatesListTable />

      {isOpenImportModal && (
        <ImportExcelModal
          isOpen={isOpenImportModal}
          onClose={onCloseImportModal}
        />
      )}

      {isOpenAddCandidate && (
        <CandidatesModal
          isOpen={isOpenAddCandidate}
          onClose={onCloseAddCandidate}
        />
      )}
    </Box>
  );
};

export default CandidateTabPanel;
