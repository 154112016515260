import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import AtsSelect from 'components/app/AtsSelect';
import AtsEmailBody from 'components/app/EmailBody';
import ScrollToFieldError from 'components/app/ScrollError';
import { ErrorMessage, Field, Formik } from 'formik';
import Button from 'Library/Button';
import { EmailConnectionModalProps } from '../types';
import { dnsOption } from './constant';
import { useEmailConnectionModalServices } from './useEmailConnectionModalServices';

const EmailConnectionModal = ({
  isEdit,
  isOpen,
  onClose,
}: EmailConnectionModalProps) => {
  const { initialValues, validationSchema, handleOnSubmit } =
    useEmailConnectionModalServices({ isEdit, isOpen, onClose });

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={() => {
        onClose();
      }}
      size="6xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent fontFamily="NunitoSans Regular">
        <ModalHeader
          background="default.white.800"
          borderRadius="4px 4px 0"
          p="18px 32px"
          fontSize="18px"
          lineHeight="21.92px"
          textAlign="left"
        >
          Edit Email Connection
        </ModalHeader>
        <ModalCloseButton
          top="13px"
          right="20px"
          color="default.secondarytext"
        />
        <ModalBody borderRadius="0 0 4px 4px" p={0}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setFieldError }) => {
              const {
                percentage_step_0,
                percentage_step_1,
                percentage_step_2,
                percentage_step_3,
                percentage_step_4,
                percentage_step_5,
              } = values;
              const totalPercentage =
                (percentage_step_0 || 0) +
                (percentage_step_1 || 0) +
                (percentage_step_2 || 0) +
                (percentage_step_3 || 0) +
                (percentage_step_4 || 0) +
                (percentage_step_5 || 0);

              // Check if total percentage exceeds 100
              if (totalPercentage > 100) {
                setFieldError(
                  'percentage_step_0',
                  'Total percentage exceeds 100'
                );
                return; // Prevent form submission
              } else {
                handleOnSubmit(values);
              }
            }}
            enableReinitialize={true}
          >
            {({
              values,
              handleSubmit,
              handleChange,
              setFieldValue,
              errors,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                <ScrollToFieldError />
                <Box
                  sx={{
                    border: '1px solid #EEEEEE',
                    borderRadius: '6px',
                    p: '18px 32px',
                  }}
                >
                  <Stack direction="row" spacing="8px">
                    <Stack
                      width="60%"
                      sx={{
                        border: '0.5px solid #E7E9ED',
                        borderRadius: '6px',
                        p: '16px',
                      }}
                    >
                      <Stack spacing="24px" mb="24px">
                        <FormControl
                          isInvalid={Boolean(!!errors.email && touched.email)}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Email{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <Input
                            id="email"
                            name="email"
                            type="text"
                            placeholder="Name"
                            variant="outline"
                            value={values.email}
                            disabled
                          />
                          <FormErrorMessage>
                            {String(errors.email)}
                          </FormErrorMessage>
                        </FormControl>

                        <Stack direction="row" spacing="8px">
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.random_interval_from &&
                                touched.random_interval_from
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Min Interval{' '}
                              <Box as="span" color="caution.800">
                                *
                              </Box>
                            </FormLabel>
                            <Input
                              id="random_interval_from"
                              name="random_interval_from"
                              type="number"
                              min={0}
                              placeholder="Name"
                              variant="outline"
                              value={values.random_interval_from}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.random_interval_from)}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.random_interval_to &&
                                touched.random_interval_to
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Max Interval{' '}
                              <Box as="span" color="caution.800">
                                *
                              </Box>
                            </FormLabel>
                            <Input
                              id="random_interval_to"
                              name="random_interval_to"
                              type="number"
                              min={0}
                              placeholder="Name"
                              variant="outline"
                              value={values.random_interval_to}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.random_interval_to)}
                            </FormErrorMessage>
                          </FormControl>
                        </Stack>

                        <Stack direction="row" spacing="8px">
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.email_limit_per_day &&
                                touched.email_limit_per_day
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Limit Per Day{' '}
                              <Box as="span" color="caution.800">
                                *
                              </Box>
                            </FormLabel>
                            <Input
                              id="email_limit_per_day"
                              name="email_limit_per_day"
                              type="number"
                              min={0}
                              placeholder="Name"
                              variant="outline"
                              value={values.email_limit_per_day}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.email_limit_per_day)}
                            </FormErrorMessage>
                          </FormControl>

                          <FormControl
                            isInvalid={Boolean(!!errors.dns && touched.dns)}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              DNS{' '}
                              <Box as="span" color="caution.800">
                                *
                              </Box>
                            </FormLabel>
                            <Field
                              name="dns"
                              options={dnsOption}
                              component={AtsSelect}
                              value={
                                dnsOption.filter(
                                  (val) => val.value === values.dns
                                )[0]
                              }
                              placeholder="Select"
                              onChange={(e: any) => {
                                setFieldValue('dns', String(e.value));
                              }}
                            />
                            <FormErrorMessage>
                              {String(errors.dns)}
                            </FormErrorMessage>
                          </FormControl>
                        </Stack>

                        <FormControl
                          isInvalid={Boolean(
                            !!errors.signature && touched.signature
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Email Signature{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <Box
                            sx={{
                              '& .ql-editor': {
                                minHeight: '40vh !important',
                              },
                            }}
                          >
                            <AtsEmailBody
                              value={values.signature}
                              onChange={(e: any) => {
                                setFieldValue(`signature`, e);
                              }}
                              height={200}
                            />
                            <FormErrorMessage>
                              {String(errors.signature)}
                            </FormErrorMessage>
                          </Box>
                        </FormControl>
                      </Stack>
                    </Stack>

                    <Stack width="40%">
                      <Text sx={{ fontSize: '14px', color: '#000000' }}>
                        Percentage per Step
                      </Text>

                      <Stack
                        spacing="8px"
                        p="12px"
                        sx={{
                          border: '0.5px solid #EEEEEE',
                          borderRadius: '6px',
                        }}
                      >
                        <Stack
                          direction="row"
                          spacing="24px"
                          alignItems="center"
                        >
                          <Text
                            fontSize="14px"
                            lineHeight="18px"
                            color="#000000"
                          >
                            Step
                          </Text>
                          <Text
                            fontSize="14px"
                            lineHeight="18px"
                            color="#000000"
                          >
                            Percentage
                          </Text>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing="24px"
                          alignItems="center"
                        >
                          <Text
                            fontSize="14px"
                            lineHeight="18px"
                            color="#000000"
                            width="30px"
                            textAlign="center"
                          >
                            1
                          </Text>
                          <FormControl>
                            <Input
                              id="percentage_step_0"
                              name="percentage_step_0"
                              type="number"
                              min={0}
                              placeholder="Name"
                              variant="outline"
                              value={values.percentage_step_0}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.percentage_step_0)}
                            </FormErrorMessage>
                          </FormControl>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing="24px"
                          alignItems="center"
                        >
                          <Text
                            fontSize="14px"
                            lineHeight="18px"
                            color="#000000"
                            width="30px"
                            textAlign="center"
                          >
                            2
                          </Text>
                          <FormControl>
                            <Input
                              id="percentage_step_1"
                              name="percentage_step_1"
                              type="number"
                              min={0}
                              placeholder="Name"
                              variant="outline"
                              value={values.percentage_step_1}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.percentage_step_1)}
                            </FormErrorMessage>
                          </FormControl>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing="24px"
                          alignItems="center"
                        >
                          <Text
                            fontSize="14px"
                            lineHeight="18px"
                            color="#000000"
                            width="30px"
                            textAlign="center"
                          >
                            3
                          </Text>
                          <FormControl>
                            <Input
                              id="percentage_step_2"
                              name="percentage_step_2"
                              type="number"
                              min={0}
                              placeholder="Name"
                              variant="outline"
                              value={values.percentage_step_2}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.percentage_step_2)}
                            </FormErrorMessage>
                          </FormControl>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing="24px"
                          alignItems="center"
                        >
                          <Text
                            fontSize="14px"
                            lineHeight="18px"
                            color="#000000"
                            width="30px"
                            textAlign="center"
                          >
                            4
                          </Text>
                          <FormControl>
                            <Input
                              id="percentage_step_3"
                              name="percentage_step_3"
                              type="number"
                              min={0}
                              placeholder="Name"
                              variant="outline"
                              value={values.percentage_step_3}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.percentage_step_3)}
                            </FormErrorMessage>
                          </FormControl>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing="24px"
                          alignItems="center"
                        >
                          <Text
                            fontSize="14px"
                            lineHeight="18px"
                            color="#000000"
                            width="30px"
                            textAlign="center"
                          >
                            5
                          </Text>
                          <FormControl>
                            <Input
                              id="percentage_step_4"
                              name="percentage_step_4"
                              type="number"
                              min={0}
                              placeholder="Name"
                              variant="outline"
                              value={values.percentage_step_4}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.percentage_step_4)}
                            </FormErrorMessage>
                          </FormControl>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing="24px"
                          alignItems="center"
                        >
                          <Text
                            fontSize="14px"
                            lineHeight="18px"
                            color="#000000"
                            width="30px"
                            textAlign="center"
                          >
                            6
                          </Text>
                          <FormControl>
                            <Input
                              id="percentage_step_5"
                              name="percentage_step_5"
                              type="number"
                              min={0}
                              placeholder="Name"
                              variant="outline"
                              value={values.percentage_step_5}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.percentage_step_5)}
                            </FormErrorMessage>
                          </FormControl>
                        </Stack>

                        <Box color="caution.800">
                          <ErrorMessage name="percentage_step_0" />
                        </Box>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>

                <ModalFooter
                  position="sticky"
                  bottom="0"
                  background="default.white.100"
                  boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                >
                  <Stack direction="row" spacing="10px">
                    <Button onClick={onClose}>Close</Button>
                    <Button
                      variant="solid"
                      type="submit"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Submit
                    </Button>
                  </Stack>
                </ModalFooter>
              </form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EmailConnectionModal;
