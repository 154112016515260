/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from 'react-router';
import SubPage from './SubPage';
import { MainPage } from './MainPage';
import { Box } from '@chakra-ui/react';

const SequencesList = () => {
  const params = useParams();
  const { settingType } = params;

  return (
    <Box sx={{ bgColor: '#fff', borderRadius: '5px', p: '16px' }}>
      {!settingType ? <MainPage /> : <SubPage />}
    </Box>
  );
};

export default SequencesList;
