import { Box, Image } from '@chakra-ui/react';

import { fileApplication } from 'utils/fileApplication';

import UnknownImage from '../../../assets/images/icons/unkown.svg';
import PDFViewer from 'components/app/PDFViewerV2';
import AtsDocViewer from 'components/app/AtsDocViewer';

interface StreamModalProps {
  filename?: string;
  fileUrl?: string;
  setResumeUrl?: any;
  html?: any;
  pdfHeight: any;
  docHeight: any;
}

export default function StreamAttachmentComponent({
  filename,
  fileUrl,
  setResumeUrl,
  pdfHeight,
  docHeight = 700,
}: StreamModalProps) {
  const StreamComponent = () => {
    if (filename) {
      switch (fileApplication(filename)) {
        case 'doc':
          const doc = [{ uri: fileUrl, fileType: 'doc' }];
          return (
            <AtsDocViewer
              docUrl={fileUrl}
              documents={doc}
              setResumeUrl={setResumeUrl}
              height={docHeight}
            />
          );
        case 'docx':
          const docx = [{ uri: fileUrl, fileType: 'docx' }];
          return (
            <AtsDocViewer
              docUrl={fileUrl}
              documents={docx}
              setResumeUrl={setResumeUrl}
              height={docHeight}
            />
          );
        case 'pdf':
          return (
            <PDFViewer
              pdfUrl={fileUrl}
              setResumeUrl={setResumeUrl}
              height={pdfHeight}
            />
          );

        default:
          return (
            <Image
              style={{ maxHeight: '70vh', width: '100%', objectFit: 'contain' }}
              objectFit="contain"
              src={UnknownImage}
            />
          );
      }
    }
  };

  return (
    <>
      <StreamComponent />
    </>
  );
}
