// Chakra UI components
import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';

// Local components
import JobsTable from './Table';
import TrLoading from 'components/app/TrLoading';

// Local utilities
import { useJobsMainModuleServices } from './useJobsMainModuleServices';
import { JobsSideBar } from 'components/app/Jobs/Sidebar';

export const JobMainModule = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { modalTitle, isLoading, setIsLoading } = useJobsMainModuleServices({
    isOpen,
    onOpen,
    onClose,
  });

  const tableLoading = () => {
    return (
      <Box
        sx={{
          height: '100%',
          w: 'calc(100% - 300px)',
          bgColor: '#fff',
          borderRadius: '10px',
          p: '16px',
        }}
      >
        <TableContainer
          boxSizing="border-box"
          border="1px solid"
          borderColor="default.white.400"
          borderRadius="md"
          height="calc(75vh - 50px)"
        >
          <Table>
            <Thead height="40.5px">
              <Tr bg="default.white.600">
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody
              boxSizing="border-box"
              background="default.white.100"
              borderBottom="1px solid"
              borderColor="default.white.400"
            >
              <TrLoading rows={7} columns={10} />
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    );
  };
  return (
    <Flex
      gap="8px"
      sx={{
        mt: '4px',
        p: '8px',
        fontFamily: 'NunitoSans Regular',
        overflow: 'hidden',
        height: '90vh',
      }}
    >
      <JobsSideBar setIsLoading={setIsLoading} />

      {isLoading ? (
        tableLoading()
      ) : (
        <JobsTable
          isOpenModal={isOpen}
          onOpenModal={onOpen}
          onCloseModal={onClose}
          modalJobTitle={modalTitle}
        />
      )}
    </Flex>
  );
};
