
interface PDFViewerProps {
  pdfUrl: string;
  setResumeUrl?: any;
  height: any;
}

const PDFViewer = ({ pdfUrl, setResumeUrl = null, height }: PDFViewerProps) => {

  return (
    <>
      {
        <>
          <iframe
            title="PDF Viewer"
            src={pdfUrl + '#toolbar=1&navpanes=0&scrollbar=0&view=FitH'}
            width="100%"
            style={{ border: 'none', height: height }}
          >
            <meta name="sample" content="sample" />
          </iframe>
        </>
      }
    </>
  );
};

export default PDFViewer;
