import { useParams } from 'react-router';
import { CommandCenterModule } from './CommandCenter';
import SequencesList from './Sequences';
import SettingsTabList from './Settings/TabList';
import SMSTabList from './SMS/TabList';

export const tabOptions: any = {
  'command-center': CommandCenterModule,
  sequences: SequencesList,
  settings: SettingsTabList,
  sms: SMSTabList,
};

const RecruitmentLayout = () => {
  const params = useParams();
  const settingNav: string = params.settingNav;

  const Component = tabOptions[settingNav];

  if (Component) return <Component />;
};

export default RecruitmentLayout;
