import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import Button from 'Library/Button';
import SMSAutomationModal from './Modal';
import SMSAutomationListTable from './Table';

const SMSAutomationList = () => {
  const {
    isOpen: isOpenSuppressionModal,
    onOpen: onOpenSuppressionModal,
    onClose: onCloseSuppressionModal,
  } = useDisclosure({ defaultIsOpen: false });

  return (
    <Box fontFamily="NunitoSans Regular">
      <Flex justifyContent="flex-end" mb="16px" px={6}>
        <Flex>
          <Button
            leftIcon="plus"
            variant="solid"
            onClick={onOpenSuppressionModal}
          >
            Add Automation
          </Button>
        </Flex>
      </Flex>
      <SMSAutomationListTable />

      {isOpenSuppressionModal && (
        <SMSAutomationModal
          isOpen={isOpenSuppressionModal}
          onClose={onCloseSuppressionModal}
        />
      )}
    </Box>
  );
};

export default SMSAutomationList;
