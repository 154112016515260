import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  Textarea,
} from '@chakra-ui/react';
import AtsSelect from 'components/app/AtsSelect';
import AtsSelect2 from 'components/app/AtsSelect2';
import AtsDropZone from 'components/app/Dropzone';
import Select from 'components/ui/Select/Select';
import { Field, FieldProps } from 'formik';
import { useState } from 'react';
import { useFetchLeadSourcesQuery } from 'services/constants/constants.query';
import { useFetchUsersQuery } from 'services/user/users.query';
import { InitialValueType } from '../type';

const protocolRegex = /^(.*?:\/\/)/;

export default function AdditionalInformation({
  isAddNew,
}: {
  isAddNew: boolean;
}) {
  const { data: userList } = useFetchUsersQuery(
    {},
    {
      selectFromResult: ({ data, ...other }) => ({
        data: data
          ? data.map((data: any) => ({
              label: `${data.first_name} ${data.last_name}`,
              value: data.id,
            }))
          : [],
        ...other,
      }),
    }
  );
  const { data: leadSourceList } = useFetchLeadSourcesQuery(
    {},
    {
      selectFromResult: ({ data, ...other }) => ({
        data: data
          ? data.map((data: any) => ({
              label: data.source,
              value: data.id,
            }))
          : [],
        ...other,
      }),
    }
  );

  const [protocol, setProtocol] = useState({
    value: 'https://',
    label: 'https://',
  });

  function handleProtocol(value: string) {
    const match = value.match(protocolRegex);
    if (match) {
      const protocol = match[1];
      setProtocol({
        value: protocol,
        label: protocol,
      });
    }
  }
  return (
    <>
      <Box>
        <Text fontWeight={600} mb="34px">
          Additional Information
        </Text>
        <Box my="34px">
          <Field name="client_job_link">
            {({
              form: { errors, touched, values, handleChange, setFieldValue },
            }: FieldProps<InitialValueType>) => (
              <FormControl
                isInvalid={Boolean(
                  !!errors.client_job_link && touched.client_job_link
                )}
                width="100%"
                mb="34px"
              >
                <FormLabel>Client Job Link</FormLabel>
                <Flex
                  width="100%"
                  direction="row"
                  alignItems="center"
                  alignSelf="stretch"
                >
                  <Flex alignSelf="stretch">
                    <Flex alignItems="center">
                      <AtsSelect
                        variant="outline"
                        menuPosition="fixed"
                        value={protocol}
                        onChange={(e: any) => {
                          setProtocol(e);
                        }}
                        styles={{
                          control: (styles: any) => ({
                            ...styles,
                            width: '110px',
                          }),
                        }}
                        options={[
                          {
                            value: 'https://',
                            label: 'https://',
                          },
                          {
                            value: 'http://',
                            label: 'http://',
                          },
                        ]}
                        isSearchable={false}
                      />
                    </Flex>
                  </Flex>
                  <Flex width="100%">
                    <Flex width="100%">
                      <Field
                        as={Input}
                        value={values.client_job_link}
                        name="client_job_link"
                        margin="0px"
                        padding="0px"
                        type="text"
                        onChange={handleChange}
                        onBlur={(e: any) => {
                          setFieldValue(
                            'client_job_link',
                            e.target.value.replace(/(^\w+:|^)\/\//, '')
                          );
                          const { value } = e.target;
                          handleProtocol(value);
                        }}
                      />
                    </Flex>
                  </Flex>
                </Flex>
                <FormErrorMessage mb="12px">
                  {String(errors.client_job_link)}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <FormControl>
            <FormLabel>Multiple Account Managers?</FormLabel>
          </FormControl>

          <Flex mb="20px">
            <FormLabel display="flex" gap="10px">
              <Field
                id="isMultipleAccountManager"
                name="isMultipleAccountManager"
                type="radio"
                variant="outline"
                value={'true'}
              />
              Yes
            </FormLabel>
            <FormLabel display="flex" gap="10px">
              <Field
                id="isMultipleAccountManager"
                name="isMultipleAccountManager"
                type="radio"
                variant="outline"
                value={'false'}
              />
              No
            </FormLabel>
          </Flex>

          <Field name="manager">
            {({
              form: { errors, touched, values },
            }: FieldProps<InitialValueType>) => (
              <FormControl
                isInvalid={Boolean(!!errors.manager && touched.manager)}
                mb="34px"
                isRequired
              >
                <FormLabel>Account Manager </FormLabel>
                <Field
                  name="manager"
                  isMulti={
                    values.isMultipleAccountManager === 'true' ? true : false
                  }
                  options={userList}
                  component={AtsSelect2}
                  defaultValue={values.manager}
                />
                <FormErrorMessage>{String(errors.manager)}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Box>

        <Flex gap="32px" mb="34px">
          <Field name="created_by">
            {({
              form: { errors, touched, values, setFieldValue },
            }: FieldProps<InitialValueType>) => (
              <FormControl
                isInvalid={Boolean(!!errors.created_by && touched.created_by)}
              >
                <FormLabel>Created By:</FormLabel>

                <Select
                  id="created_by"
                  name="created_by"
                  placeholder="Select"
                  value={
                    userList.find(
                      (user) => user.value === parseInt(values.created_by)
                    ) || values.created_by
                  }
                  onChange={(e: any) =>
                    setFieldValue('created_by', String(e.value))
                  }
                  options={userList}
                />
                <FormErrorMessage>{String(errors.created_by)}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="lead_source">
            {({
              form: { errors, touched, values, setFieldValue },
            }: FieldProps<InitialValueType>) => (
              <FormControl
                isInvalid={Boolean(!!errors.lead_source && touched.lead_source)}
                isRequired
              >
                <FormLabel>Source</FormLabel>
                {/* <Input
                  id="lead_source"
                  name="lead_source"
                  type="text"
                  placeholder="Lead Source"
                  variant="outline"
                  value={values.lead_source}
                  onChange={handleChange}
                /> */}
                <Field
                  id="lead_source"
                  name="lead_source"
                  options={leadSourceList}
                  component={Select}
                  defaultValue={values.lead_source}
                  onChange={(e: any) => {
                    setFieldValue(`lead_source`, e);
                  }}
                />
                <FormErrorMessage>
                  {errors.lead_source &&
                    touched.lead_source &&
                    String((errors.lead_source as any).label)}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Flex>
        <Flex mb="34px" gap="32px">
          <Field name="lead_owner">
            {({
              form: { errors, touched, values, setFieldValue },
            }: FieldProps<InitialValueType>) => (
              <FormControl
                isInvalid={Boolean(!!errors.lead_owner && touched.lead_owner)}
                isRequired
              >
                <FormLabel>Lead Owner </FormLabel>

                <Select
                  id="lead_owner"
                  name="lead_owner"
                  placeholder="Select"
                  // value={values.lead_owner}
                  value={
                    userList.find(
                      (val) => val.value === parseInt(values.lead_owner)
                    ) || values.lead_owner
                  }
                  onChange={(e: any) => setFieldValue('lead_owner', e.value)}
                  options={userList}
                />
                <FormErrorMessage>{String(errors.lead_owner)}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <FormControl />
        </Flex>
      </Box>
      {isAddNew && (
        <>
          <Box mb="34px">
            <FormControl>
              <FormLabel>Attachments</FormLabel>
            </FormControl>
            <AtsDropZone
              multipleFile={true}
              uploadedEnd={(e: any) => {}}
              deletedFile={(key: string) => {}}
            />
          </Box>
          <Box>
            <Field name="notes">
              {({
                form: { errors, touched, values, handleChange },
              }: FieldProps<InitialValueType>) => (
                <FormControl
                  isInvalid={Boolean(!!errors.notes && touched.notes)}
                >
                  <FormLabel mb="8px">Notes</FormLabel>
                  <Textarea
                    id="notes"
                    name="notes"
                    placeholder="Type here"
                    variant="outline"
                    value={values.notes}
                    onChange={handleChange}
                  />
                  <Text
                    mt="8px"
                    fontSize="12px"
                    lineHeight="18px"
                    color="default.secondarytext"
                  >
                    Maximum 200 characters allowed
                  </Text>
                  <FormErrorMessage>{String(errors.notes)}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Box>
        </>
      )}
    </>
  );
}
