import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Formik } from 'formik';

import {
  Box,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Menu,
  MenuButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import FAIcon from 'components/lib/FAIcon';

import Button from 'Library/Button';
import Pagination from 'Library/Pagination';

import ScrollToFieldError from '../../ScrollError';

import TrLoading from '../../TrLoading';

import { listJobs, useListClientJobsMutation } from 'store/jobs.slice';
import AddJobForm from 'modules/Jobs/components/job-form/AddJobForm';
import {
  useCreateFullJobMutation,
  useCreateQuickJobMutation,
} from 'services/jobs/job.mutation';

const ClientsJobs = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const { jobs, jobPage, jobPgBtn } = useSelector((state: any) => state.jobs);
  const { clientData } = useSelector((state: any) => state.clients);
  const { contactData } = useSelector((state: any) => state.contacts);
  const [reqjobs, resjobs] = useListClientJobsMutation();

  //for job form
  const clientOption = clientData?.name
    ? {
        label: clientData?.name ?? '',
        value: parseInt(clientData?.id),
      }
    : {
        label: contactData?.company,
        value: contactData?.client_id,
      }; //required
  const contactOption = contactData?.id
    ? {
        label: `${contactData?.first_name} ${contactData?.last_name}`,
        value: contactData?.id,
      }
    : null; //option - only required in contact drawer
  const clientID = params.clientsTabId || params.clientsId;
  const clientsTabId = params.clientsTabId || null;

  const [isOpenCheck, setOpenCheck] = useState(true);
  const [isCloseCheck, setCloseCheck] = useState(false);
  const [isQuickJobCheck, setQuickJobCheck] = useState(false);
  const [isAssignCheck, setAssignCheck] = useState(false);

  const [contactOptionValue, setcontactOptionValue] = useState([]);

  useEffect(() => {
    let options: any = [];

    clientData?.leads?.map((item: any) => {
      if (clientsTabId !== null && item.id == clientsTabId) {
        options.push({
          label: [item.first_name, item.last_name].join(' '),
          value: item.id,
        });
      }
      if (clientsTabId == null) {
        options.push({
          label: [item.first_name, item.last_name].join(' '),
          value: item.id,
        });
      }
    });

    if (options.length >= 1) {
      setcontactOptionValue(options);
    }
  }, [clientData]);

  const tableHeader: { label: any }[] = [
    { label: 'Job Details' },
    {
      label: (
        <Box>
          <FAIcon iconName="clipboard" /> Submits
        </Box>
      ),
    },
    {
      label: (
        <Box>
          <FAIcon iconName="phone" /> Interview
        </Box>
      ),
    },
    {
      label: (
        <Box>
          <FAIcon iconName="location-pin" /> Offered
        </Box>
      ),
    },
    {
      label: (
        <Box>
          <FAIcon iconName="diamond" /> Placed
        </Box>
      ),
    },
    { label: '' },
  ];
  useEffect(() => {
    if (
      clientID &&
      (params.clientsSubTab === 'jobs' || params.clientSubSubTab === 'jobs')
    ) {
      let params = {
        page: 1,
        take: jobPgBtn.take,
        query: jobPgBtn.query,
        closed: isCloseCheck,
        open: isOpenCheck,
        quickJobs: isQuickJobCheck,
        job_assigned: isAssignCheck,
        job_ads: jobPgBtn.job_ads,
      };
      dispatch(
        listJobs({
          jobPgBtn: params,
        })
      );
      reqjobs({ data: params, id: clientID });
    }
  }, [clientID, params.clientsSubTab, params.clientSubSubTab]);

  const initialValues = {
    search: '',
  };

  const pageChange = async (page: number) => {
    let newParam = {
      page: page,
      take: jobPgBtn.take,
      query: jobPgBtn.query,
    };
    dispatch(
      listJobs({
        jobPgBtn: newParam,
      })
    );
    reqjobs({ data: newParam, id: clientID });
  };

  const onSubmit = (data: any) => {
    let param = {
      query: data.search,
      page: jobPgBtn.page,
      take: jobPgBtn.take,
    };

    reqjobs({ data: param, id: clientID });
    dispatch(listJobs({ jobPgBtn: param }));
  };

  const entryChange = (entries: number) => {
    let newParam = {
      page: 1,
      take: entries,
      query: jobPgBtn.query,
    };
    dispatch(
      listJobs({
        jobPgBtn: newParam,
      })
    );
    reqjobs({ data: newParam, id: clientID });
  };

  const submitForm = (params: any) => {
    dispatch(
      listJobs({
        jobPgBtn: params,
      })
    );
    reqjobs({ data: params, id: clientID });
  };

  const filterSubmit = (params: any) => {
    submitForm(params);
  };

  return (
    <Box fontFamily="NunitoSans Regular">
      <Flex justifyContent="space-between" py={6}>
        <Flex>
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, handleChange, handleSubmit, errors, touched }) => (
              <form onSubmit={handleSubmit}>
                <ScrollToFieldError />
                <Box pr={2}>
                  <InputGroup>
                    <InputLeftElement children={<FAIcon iconName="search" />} />
                    <Input
                      type="search"
                      placeholder="Search Jobs"
                      name="search"
                      value={values.search}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </Box>
              </form>
            )}
          </Formik>

          <Flex px={2} justifyContent="flex-start">
            <Checkbox
              colorScheme="purple"
              color="default.secondarytext"
              mx={1}
              isChecked={isOpenCheck}
              onChange={(e) => {
                setOpenCheck(e.target.checked);
                let params = {
                  page: 1,
                  take: jobPgBtn.take,
                  query: jobPgBtn.query,
                  closed: isCloseCheck,
                  open: e.target.checked,
                  quickJobs: isQuickJobCheck,
                  job_assigned: isAssignCheck,
                  job_ads: jobPgBtn.job_ads,
                };
                filterSubmit(params);
              }}
            >
              Open
            </Checkbox>
            <Checkbox
              colorScheme="purple"
              color="default.secondarytext"
              mx={1}
              isChecked={isCloseCheck}
              onChange={(e) => {
                setCloseCheck(e.target.checked);
                let params = {
                  page: 1,
                  take: jobPgBtn.take,
                  query: jobPgBtn.query,
                  closed: e.target.checked,
                  open: isOpenCheck,
                  quickJobs: isQuickJobCheck,
                  job_assigned: isAssignCheck,
                  job_ads: jobPgBtn.job_ads,
                };
                filterSubmit(params);
              }}
            >
              Close
            </Checkbox>
          </Flex>
        </Flex>

        <Flex>
          <Flex px={2} justifyContent="flex-start">
            <Checkbox
              colorScheme="purple"
              color="default.secondarytext"
              mx={1}
              isChecked={isQuickJobCheck}
              onChange={(e) => {
                setQuickJobCheck(e.target.checked);
                let params = {
                  page: 1,
                  take: jobPgBtn.take,
                  query: jobPgBtn.query,
                  closed: isCloseCheck,
                  open: isOpenCheck,
                  quickJobs: e.target.checked,
                  job_assigned: isAssignCheck,
                  job_ads: jobPgBtn.job_ads,
                };
                filterSubmit(params);
              }}
            >
              Quick Jobs
            </Checkbox>
            <Checkbox
              colorScheme="purple"
              color="default.secondarytext"
              mx={1}
              isChecked={isAssignCheck}
              onChange={(e) => {
                setAssignCheck(e.target.checked);
                let params = {
                  page: 1,
                  take: jobPgBtn.take,
                  query: jobPgBtn.query,
                  closed: isCloseCheck,
                  open: isOpenCheck,
                  quickJobs: isQuickJobCheck,
                  job_assigned: e.target.checked,
                  job_ads: jobPgBtn.job_ads,
                };
                filterSubmit(params);
              }}
            >
              Job Assigned to me
            </Checkbox>
          </Flex>

          <CreateJobBtn client={clientOption} contact={contactOption} />
        </Flex>
      </Flex>

      <Box>
        <TableContainer
          boxSizing="border-box"
          border="1px solid"
          borderColor="default.white.400"
          borderRadius="md"
          minHeight="45vh"
        >
          <Table>
            <Thead>
              <Tr bg="default.white.800" key="client-job-header">
                {tableHeader.map((title) => (
                  <Th key={title.label}>
                    <Box color="default.gray.600">{title.label}</Box>
                  </Th>
                ))}
              </Tr>
            </Thead>

            <Tbody
              boxSizing="border-box"
              background="default.white.100"
              borderBottom="1px solid"
              borderColor="default.white.400"
            >
              {resjobs.isLoading ? (
                <TrLoading rows={3} columns={6} />
              ) : (
                jobs?.map((jobs: any) => (
                  <Tr
                    key={'client-job-' + jobs.id}
                    cursor="pointer"
                    onClick={() => {}}
                  >
                    <Td>
                      <Link href={`/jobs/${jobs.id}/details`}>
                        {jobs.title}
                      </Link>
                    </Td>
                    <Td>{jobs.submits}</Td>
                    <Td>{jobs.interview}</Td>
                    <Td>{jobs.offered}</Td>
                    <Td>{jobs.placed}</Td>
                    <Td>
                      <Menu>
                        <MenuButton>
                          <Button
                            htmlType="menu"
                            variant="ghost"
                            iconName="ellipsis-h"
                            iconSize="xl"
                          />
                        </MenuButton>
                      </Menu>
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </TableContainer>

        <Pagination
          totalPages={jobPage.lastPage}
          currentPage={jobPage.currentPage}
          onPageChange={(page) => pageChange(page)}
          onEntryChange={(entries) => entryChange(entries)}
          totalEntries={jobPage.count}
          targetCount={jobPgBtn.take}
        />
      </Box>
    </Box>
  );
};

export default ClientsJobs;

function CreateJobBtn({
  client,
  contact,
}: {
  client: { label: string; value: number };
  contact?: { label: string; value: number } | null;
}) {
  const [reqCreateFullJob] = useCreateFullJobMutation();
  const [reqCreateQuickJob] = useCreateQuickJobMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  async function onSubmitCreateForm(formField: any) {
    try {
      const res = await (formField.job_type_id === 1
        ? reqCreateFullJob(formField)
        : reqCreateQuickJob(formField)
      ).unwrap();
      return res;
    } catch (err: any) {
      return err.data;
    }
  }
  return (
    <>
      <Button leftIcon="plus" variant="solid" onClick={onOpen}>
        Create New Job
      </Button>

      {isOpen && (
        <AddJobForm
          isOpen={isOpen}
          onClose={onClose}
          onSaveDraft={onSubmitCreateForm}
          onSubmit={onSubmitCreateForm}
          clientField={client && { value: client, isDisabled: true }}
          contactField={contact && { value: contact, isDisabled: true }}
        />
      )}
    </>
  );
}
