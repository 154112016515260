import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { SIDE_BAR_ITEMS } from '../constants';
import { BuzzConnection } from './TabPanel/BuzzConnection';
import DefaultSchedule from './TabPanel/DefaultSchedule';
import EmailConnection from './TabPanel/EmailConnection';
import EmailTemplate from './TabPanel/EmailTemplate';
import SuppressionList from './TabPanel/SuspressionList';
import { useSettingsTabListServices } from './useSettingsTabListServices';

const SettingsTabList = () => {
  const { selectedTab, handleChangeTab } = useSettingsTabListServices();

  return (
    <Box sx={{ bgColor: '#fff', borderRadius: '5px', p: '16px' }}>
      <Tabs
        colorScheme="purple"
        index={selectedTab}
        onChange={(index) => handleChangeTab(index)}
      >
        <TabList sx={{ mx: '20px' }}>
          {SIDE_BAR_ITEMS.map((barItem) => {
            return (
              <Tab
                sx={{
                  color: '#2B2D42',
                  fontWeight: 700,
                }}
              >
                {barItem.label}
              </Tab>
            );
          })}
        </TabList>

        <TabPanels>
          <TabPanel>
            <DefaultSchedule />
          </TabPanel>
          <TabPanel>
            <EmailTemplate />
          </TabPanel>
          <TabPanel>
            <EmailConnection />
          </TabPanel>
          <TabPanel>
            <SuppressionList />
          </TabPanel>
          <TabPanel>
            <BuzzConnection />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default SettingsTabList;
