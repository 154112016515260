import { useDispatch, useSelector } from 'react-redux';
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import EmailTemplates from './template';
import GroupTemplate from './group';
import { navTemplate } from 'store/template.slice';
import { useNavigate } from 'react-router';

export default function EmailTemplateTab() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tabIndex: any = {
    email: 0,
    group: 1,
  };

  const clickNav = (route: string) => {
    dispatch(navTemplate({ templateNav: route }));
    navigate(`/email-template/${route}`);
  };

  const { templateNav } = useSelector((state: any) => state.template);

  return (
    <Box>
      <Tabs colorScheme="purple" defaultIndex={tabIndex[templateNav]}>
        <TabList fontSize="md" fontWeight="bold" mx={10}>
          <Tab
            _focus={{ boxShadow: 'none !important' }}
            onClick={() => clickNav('email')}
          >
            Templates
          </Tab>
          <Tab
            _focus={{ boxShadow: 'none !important' }}
            onClick={() => clickNav('group')}
          >
            Groups
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <EmailTemplates />
          </TabPanel>
          <TabPanel>
            <GroupTemplate />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
