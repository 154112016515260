/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { updateOutreach } from 'store/outreach.slice';

export const useStepsServices = () => {
  const dispatch = useDispatch();

  const { stepsData, sequencesData } = useSelector(
    (state: any) => state.outreach
  );

  const handleStepsData = (data: any) => {
    dispatch(updateOutreach({ stepsData: data }));
    dispatch(updateOutreach({ previewData: {} }));
  };

  const handleReset = () => {
    dispatch(updateOutreach({ stepsData: {} }));
    dispatch(updateOutreach({ previewData: {} }));

    setTimeout(() => {
      handleStepsData({
        subject: '',
        subject_remote: '',
        content: '',
        touch_order: null,
        days_to_wait: 0,
        previewOpen: false,
        isNew: true,
      });
    }, 500);
  };

  return {
    stepsData,
    sequencesData,
    handleStepsData,
    handleReset,
  };
};
