import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Box,
  Flex,
  Image,
  Stack,
  Text,
  Icon,
  AccordionPanel,
  FormControl,
  FormLabel,
  Portal,
} from '@chakra-ui/react';
import highlight from 'assets/images/inboxes/MdHighlight.svg';
import DetailsActive from 'assets/images/leads/details-active.svg';
import DetailsDefault from 'assets/images/leads/details-default.svg';
import NewProspectActive from 'assets/images/leads/new-prospects-active.svg';
import NewProspectDefault from 'assets/images/leads/new-prospects.svg.svg';
import ExistingClientProspectActive from 'assets/images/leads/existing-client-prospect-active.svg';
import ExistingClientProspectDefault from 'assets/images/leads/existing-client-prospect.svg.svg';
import UserActive from 'assets/images/leads/user-active.svg';
import UserDefault from 'assets/images/leads/user-default.svg';
import AtsSelect from 'components/app/AtsSelect';
import { CheckboxLayout } from 'components/app/Global/Checkbox';
import { SearchBar } from 'components/app/Global/SearchBar';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import {
  setDateFilter,
  setLocations,
  setMiles,
  setQuery as setFilterQuery,
  setStatus,
  setTags,
  setZipCode,
} from 'store/leads.slice';
import {
  MY_LEADS,
  PROSPECTS,
  UNCLAIMED,
} from '../../constants/filter-constants';
import { useFetchLeadsCountsQuery } from '../../../../services/leads/leadsApi';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa6';
import Select from 'components/ui/Select/Select';
import { MILES } from 'constants/form-select-options';
import {
  useFetchGeoLocationQuery,
  useFetchSearchTagsQuery,
} from 'services/constants/constants.query';

const MAIN_MENU: {
  id: number;
  label: string;
  image: string;
  imageActive: string;
  countId:
    | 'myleads'
    | 'unclaimed'
    | 'new_prospects'
    | 'existing_client_prospects';
  link: string;
  value: string;
}[] = [
  {
    id: 0,
    label: 'My Leads',
    image: UserDefault,
    imageActive: UserActive,
    value: 'my-leads',
    link: '/leads/my-leads',
    countId: 'myleads',
  },
  {
    id: 1,
    label: 'Unclaimed Leads',
    image: DetailsDefault,
    imageActive: DetailsActive,
    value: 'unclaimed',
    link: '/leads/unclaimed-leads',
    countId: 'unclaimed',
  },
  {
    id: 2,
    label: 'New Prospects',
    image: NewProspectDefault,
    imageActive: NewProspectActive,
    value: 'new_prospects',
    link: '/leads/new-prospects',
    countId: 'new_prospects',
  },
  {
    id: 3,
    label: 'Prospects w/ Existing Clients',
    image: ExistingClientProspectDefault,
    imageActive: ExistingClientProspectActive,
    value: 'existing_client_prospects',
    link: '/leads/existing-client-prospects',
    countId: 'existing_client_prospects',
  },
];

type DateOptionType = { label: string; value: string };
const DATE_OPTIONS: Array<DateOptionType> = [
  { label: 'All', value: 'null' },
  { label: 'Last Week', value: 'Last Week' },
  { label: 'Last Month', value: 'Last Month' },
  { label: 'Last 3 Months', value: 'Last 3 Months' },
  { label: 'Last 6 Months', value: 'Last  6 Months' },
];
const LeadSideBar = () => {
  const dispatch = useDispatch();
  const { data } = useFetchLeadsCountsQuery({});
  const { pathname } = useLocation();
  const { dateFilter, isPrivateTag, miles, tags, location } = useSelector(
    (state: any) => state.leads
  );
  const [query, setQuery] = useState('');
  const [locationQuery, setLocationQuery] = useState({
    zipCode: '',
    location: '',
  });
  const [tagQuery, setTagsQuery] = useState('');

  const { data: locationsList, isFetching: isLoadingLocationList } =
    useFetchGeoLocationQuery(
      { search: locationQuery.location, column: 'city,zip_code,state' },
      {
        selectFromResult: ({ data, ...other }) => ({
          data: data
            ? data.map((item) => ({
                ...item,
                value: item.id,
                label: `${item.city}, ${item.state}, ${item.country}, ${item.postal_code}`,
              }))
            : [],
          ...other,
        }),
        skip: !Boolean(locationQuery.location),
      }
    );

  const { data: tagsList, isFetching: isLoadingTags } = useFetchSearchTagsQuery(
    { tag: tagQuery, is_private: isPrivateTag },
    {
      selectFromResult: ({ data, ...other }) => ({
        data: data
          ? data.map((tag) => ({ value: tag.id, label: tag.tag_name }))
          : [],
        ...other,
      }),
    }
  );

  const {
    isProspectRoute,
    isUnclaimedLeadsRoute,
    isMyLeadsRoute,
    isNewProspects,
  } = useMemo(() => {
    return {
      isProspectRoute: pathname.includes('prospects'),
      isUnclaimedLeadsRoute: pathname.includes('/unclaimed-leads'),
      isMyLeadsRoute: pathname.includes('/my-leads'),
      isNewProspects: pathname.includes('/new-prospects'),
    };
  }, [pathname]);

  const handleSearchChange = debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;
      dispatch(setFilterQuery({ query: value }));
    },
    300
  );

  const handleSelectQuery = debounce(
    (e: string, key: 'location' | 'zipCode') => {
      setLocationQuery((state) => ({ ...state, [key]: e }));
    },
    400
  );
  const handleTagQuery = debounce((e: string) => {
    setTagsQuery(e);
  }, 400);

  function handleStatusChange(filter: number[][]) {
    const status = [...new Set(filter.flat())];
    dispatch(setStatus({ status }));
  }

  return (
    <Stack
      spacing="9px"
      sx={{
        borderRadius: '10px',
        width: '320px',
        bgColor: '#F9F9FB',
        fontFamily: 'NunitoSans Regular',
        overflowY: 'auto',
      }}
      h="100%"
    >
      <Stack
        spacing="16px"
        sx={{
          bgColor: '#fff',
          borderRadius: '10px',
        }}
      >
        <Stack spacing="10px" sx={{ position: 'relative', p: '16px' }}>
          {MAIN_MENU.map((val) => {
            return (
              <NavLink to={val.link}>
                {({ isActive }) => (
                  <Flex
                    gap="10px"
                    sx={{
                      alignItems: 'center',
                      p: '4px 8px',
                      cursor: 'pointer',
                      borderRadius: '4px',
                      background: isActive && '#6930CA',
                      color: isActive ? '#FFFFFF' : '#2B2D42',
                    }}
                  >
                    {isActive && (
                      <Image
                        src={highlight}
                        height="35px"
                        width="4px"
                        sx={{ position: 'absolute', left: 0 }}
                        alt="highlight"
                      />
                    )}
                    <Image
                      src={isActive ? val.imageActive : val.image}
                      height="24px"
                      width="24px"
                      alt={`${val.label}--${isActive ? 'active' : 'inactive'}`}
                    />
                    <Flex alignItems="center" columnGap="8px">
                      <Text sx={{ fontSize: '14px', fontWeight: 600 }}>
                        {val.label}
                      </Text>
                      <Flex
                        bgColor={isActive ? '#ffff' : '#6930CA14'}
                        color="#6930CA"
                        borderRadius={'2px'}
                        minW={'23px'}
                        h={'18px'}
                        justifyContent="center"
                        alignItems="center"
                        p={'4px'}
                      >
                        <Text fontSize={'12px'} fontWeight={600}>
                          {data?.[`${val.countId}`]}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                )}
              </NavLink>
            );
          })}
        </Stack>
      </Stack>

      <Stack overflowY={'auto'} h="100%">
        <Stack
          direction={'column'}
          gap="16px"
          sx={{
            bgColor: '#fff',
            borderRadius: '10px',
            p: '16px',
          }}
        >
          <Stack direction="column" gap="16px">
            <Flex
              sx={{
                borderBottom: '1px solid #E7EDF4',
                pb: '8px',
                color: '#718096',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '21.82px',
              }}
            >
              Filters
            </Flex>

            <SearchBar
              search={query}
              setSearch={setQuery}
              placeholder={
                isProspectRoute ? 'Search prospects...' : 'Search leads...'
              }
              handleOnChange={handleSearchChange}
            />
          </Stack>
        </Stack>
        <Accordion allowMultiple defaultIndex={[]} p={0}>
          <Stack gap="8px">
            <AccordionItem
              bgColor="#fff"
              border={0}
              p="16px"
              borderRadius="10px"
            >
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    _expanded={{ color: 'primary.800', pb: '0px' }}
                    _focus={{ border: 'none' }}
                    fontWeight="600"
                    _hover={{ bg: 'none' }}
                  >
                    <Flex
                      sx={{
                        gap: '5px',
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '21.82px',
                        color: '#2B2D42',
                        w: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Text>Location</Text>
                      <Box>
                        {isExpanded ? (
                          <Icon
                            as={FaAngleUp}
                            w="16px"
                            h="16px"
                            color="#6930CA"
                          />
                        ) : (
                          <Icon as={FaAngleDown} w="16px" h="16px" />
                        )}
                      </Box>
                    </Flex>
                  </AccordionButton>
                  <AccordionPanel pt="8px" pb="0">
                    <Stack gap="12px">
                      <FormControl>
                        <Select
                          isMulti
                          value={location}
                          options={locationsList}
                          isLoading={isLoadingLocationList}
                          placeholder="Location Search"
                          defaultValue={location}
                          onInputChange={(e) => {
                            handleSelectQuery(e, 'location');
                          }}
                          onChange={(e: any) => {
                            dispatch(setLocations({ locations: e }));
                          }}
                          isClearable
                        />
                      </FormControl>

                      <FormControl>
                        <FormLabel>Miles</FormLabel>
                        <Select
                          defaultValue={miles}
                          options={MILES}
                          placeholder="Miles"
                          onChange={(e: any) => {
                            dispatch(setMiles({ miles: e }));
                          }}
                          isClearable
                        />
                      </FormControl>
                    </Stack>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
            <AccordionItem
              bgColor="#fff"
              border={0}
              p="16px"
              borderRadius="10px"
            >
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    _expanded={{ color: 'primary.800', pb: '0px' }}
                    _focus={{ border: 'none' }}
                    fontWeight="600"
                    _hover={{ bg: 'none' }}
                  >
                    <Flex
                      sx={{
                        gap: '5px',
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '21.82px',
                        color: '#2B2D42',
                        w: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Text>Tags</Text>
                      <Box>
                        {isExpanded ? (
                          <Icon
                            as={FaAngleUp}
                            w="16px"
                            h="16px"
                            color="#6930CA"
                          />
                        ) : (
                          <Icon as={FaAngleDown} w="16px" h="16px" />
                        )}
                      </Box>
                    </Flex>
                  </AccordionButton>
                  <AccordionPanel pt="8px" pb="0">
                    <Stack gap="12px">
                      <FormControl>
                        <Select
                          isMulti
                          value={tags}
                          options={tagsList}
                          isLoading={isLoadingTags}
                          isClearable
                          placeholder="Select"
                          onChange={(tags) => dispatch(setTags({ tags: tags }))}
                          onInputChange={(e: string) => handleTagQuery(e)}
                        />
                      </FormControl>
                    </Stack>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <AccordionItem
              bgColor="#fff"
              border={0}
              p="16px"
              borderRadius="10px"
            >
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    _expanded={{ color: 'primary.800', pb: '0px' }}
                    _focus={{ border: 'none' }}
                    fontWeight="600"
                    _hover={{ bg: 'none' }}
                  >
                    <Flex
                      sx={{
                        gap: '5px',
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '21.82px',
                        color: '#2B2D42',
                        w: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Text>Created Date</Text>
                      <Box>
                        {isExpanded ? (
                          <Icon
                            as={FaAngleUp}
                            w="16px"
                            h="16px"
                            color="#6930CA"
                          />
                        ) : (
                          <Icon as={FaAngleDown} w="16px" h="16px" />
                        )}
                      </Box>
                    </Flex>
                  </AccordionButton>
                  <AccordionPanel pt="8px" pb="0">
                    <Select
                      placeholder="Select"
                      defaultValue={{ value: dateFilter, label: dateFilter }}
                      options={DATE_OPTIONS}
                      onChange={(e: any) =>
                        dispatch(
                          setDateFilter({
                            dateFilter: e.value,
                          })
                        )
                      }
                    />
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <AccordionItem
              bgColor="#fff"
              border={0}
              p="16px"
              borderRadius="10px"
            >
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    _expanded={{ color: 'primary.800', pb: '0px' }}
                    _focus={{ border: 'none' }}
                    fontWeight="600"
                    _hover={{ bg: 'none' }}
                  >
                    <Flex
                      sx={{
                        gap: '5px',
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '21.82px',
                        color: '#2B2D42',
                        w: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Text>
                        {isProspectRoute ? 'Prospect' : 'Lead'} Status
                      </Text>
                      <Box>
                        {isExpanded ? (
                          <Icon
                            as={FaAngleUp}
                            w="16px"
                            h="16px"
                            color="#6930CA"
                          />
                        ) : (
                          <Icon as={FaAngleDown} w="16px" h="16px" />
                        )}
                      </Box>
                    </Flex>
                  </AccordionButton>
                  <AccordionPanel pt="8px" pb="0">
                    <>
                      {isMyLeadsRoute && (
                        <CheckboxLayout
                          data={MY_LEADS}
                          setStatusPayload={handleStatusChange}
                        />
                      )}

                      {isUnclaimedLeadsRoute && (
                        <CheckboxLayout
                          data={UNCLAIMED}
                          setStatusPayload={handleStatusChange}
                        />
                      )}

                      {isProspectRoute && (
                        <CheckboxLayout
                          data={PROSPECTS}
                          setStatusPayload={handleStatusChange}
                          counts={
                            isNewProspects
                              ? data?.prospect_status_count
                              : data?.prospect_repeat_status_count
                          }
                        />
                      )}
                    </>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Stack>
        </Accordion>
      </Stack>
    </Stack>
  );
};
export default LeadSideBar;
