import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { useDisclosure } from '@chakra-ui/react';

import {
  useDelAttachmentMutation,
  useListLeadAttachmentMutation,
} from 'store/leadattachment.slice';
import { getAttachment } from 'store/candidateattachment.slice';
import { useGetLeadsMutation } from 'store/leads.slice';

import { AtsConfirm } from 'utils/swal';

import { content } from './constants';

export function useAttachmentsServices() {
  const dispatch = useDispatch();
  const { LAlist, LAPage, LAPgBtn } = useSelector(
    (state: any) => state.leadattachment
  );
  const [reqdel, resdel] = useDelAttachmentMutation();
  const [reqleads] = useGetLeadsMutation();
  const [reqList] = useListLeadAttachmentMutation();
  const [fileData, setFileData] = useState(null);
  const params = useParams();
  const leadID = params.clientsTabId || params.leadsId;

  const {
    isOpen: isAddAttachmentOpen,
    onOpen: onAddAttachmentOpen,
    onClose: onAddAttachmentClose,
  } = useDisclosure();

  const {
    isOpen: isStreamOpen,
    onOpen: onStreamOpen,
    onClose: onStreamClose,
  } = useDisclosure();

  const delAttachment = async (id: number) => {
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqdel({ id });
    }
  };

  const pageChange = async (page: number) => {
    let newParam = {
      page: page,
      take: LAPgBtn.take,
      query: LAPgBtn.query,
    };
    await dispatch(
      getAttachment({
        LAPgBtn: newParam,
      })
    );
    reqList({ data: newParam, id: leadID });
  };

  const initialValue = {
    search: '',
  };

  const onSubmit = async (data: any) => {
    let newParam = {
      page: LAPgBtn.page,
      take: LAPgBtn.take,
      query: data.search,
    };
    await dispatch(
      getAttachment({
        LAPgBtn: newParam,
      })
    );

    reqList({ data: newParam, id: leadID });
  };

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      let newParam = {
        page: 1,
        take: LAPgBtn.take,
        query: '',
        status: LAPgBtn.status,
        types: LAPgBtn.types,
      };
      dispatch(
        getAttachment({
          LAPgBtn: newParam,
        })
      );
      reqList({ data: newParam, id: leadID });
    }
  };

  useEffect(() => {
    reqList({ data: LAPgBtn, id: leadID });
    if (resdel.isSuccess) {
      reqleads({ id: leadID });
    }
  }, [LAPgBtn, leadID, reqList, reqleads, resdel.isSuccess]);

  const entryChange = async (entries: number) => {
    let newParam = {
      // page: LAPgBtn.page,
      page: 1,
      take: entries,
      query: LAPgBtn.query,
    };
    await dispatch(
      getAttachment({
        LAPgBtn: newParam,
      })
    );
    reqList({ data: newParam, id: leadID });
  };

  return {
    initialValue,
    onSubmit,
    clearSearch,
    LAlist,
    onStreamOpen,
    setFileData,
    delAttachment,
    LAPage,
    pageChange,
    entryChange,
    LAPgBtn,
    onAddAttachmentOpen,
    isAddAttachmentOpen,
    onAddAttachmentClose,
    isStreamOpen,
    onStreamClose,
    fileData,
  };
}
