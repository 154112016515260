const replacePTags = (htmlContent: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');
  const paragraphs = doc.querySelectorAll('p');
  const lists = doc.querySelectorAll('ul, ol');

  paragraphs.forEach((pTag) => {
    const newDiv = doc.createElement('div');
    while (pTag.firstChild) {
      newDiv.appendChild(pTag.firstChild);
    }
    pTag.parentNode.replaceChild(newDiv, pTag);
  });

  lists.forEach((list) => {
    list.classList.add('default-list-style');
  });

  const divs = doc.querySelectorAll('div');
  divs.forEach((div) => {
    if (div.innerHTML.trim() === '') {
      div.innerHTML = '&nbsp;';
    }
  });

  // const modifiedHtml = doc.documentElement.innerHTML.replace(
  //   /style="[^"]*"/g,
  //   ''
  // );
  const modifiedHtml = doc.documentElement.innerHTML;

  return modifiedHtml;
};

export default replacePTags;
