import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { SIDE_BAR_ITEMS } from './constants';
import { Activities } from './TabPanel/Activities';
import { EmailThread } from './TabPanel/EmailThread';
import { useEmailsTabListServices } from './useEmailsTabListServices';

interface EmailsTabListProps {
  active: number;
}

export const EmailsTabList = ({ active }: EmailsTabListProps) => {
  const { selectedTab, handleChangeTab } = useEmailsTabListServices();

  return (
    <Tabs
      colorScheme="purple"
      index={selectedTab}
      onChange={(index) => handleChangeTab(index)}
    >
      <TabList sx={{ py: '16px' }}>
        {SIDE_BAR_ITEMS.map((barItem) => {
          return (
            <Tab
              sx={{
                color: '#2B2D42',
                fontWeight: 700,
              }}
            >
              {barItem.label}
            </Tab>
          );
        })}
      </TabList>

      <TabPanels>
        <TabPanel sx={{ p: 0 }}>
          <EmailThread active={active} />
        </TabPanel>
        <TabPanel sx={{ p: 0 }}>
          <Activities />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
