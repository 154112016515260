import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Modal,
  Stack,
  Text,
  Flex,
  Image,
} from '@chakra-ui/react';

import MdGmail from 'assets/images/engage/MdGmail.svg';
import MdOutlook from 'assets/images/engage/MdOutlook.svg';

interface ConnetEmailProps {
  isOpen: any;
  onClose: any;
  handleConnectToOutlook: any;
  handleConnectToGmail: any;
  isLoadingOutlook: any;
  isLoadingGmail: any;
}

export const ConnectEmail = ({
  isOpen,
  onClose,
  handleConnectToOutlook,
  handleConnectToGmail,
  isLoadingOutlook,
  isLoadingGmail,
}: ConnetEmailProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent fontFamily="NunitoSans Regular">
        <ModalHeader
          background="default.white.800"
          borderRadius="4px 4px 0"
          p="18px 32px"
          fontSize="18px"
          lineHeight="21.92px"
          textAlign="left"
        >
          Select your email provider
        </ModalHeader>
        <ModalCloseButton
          top="13px"
          right="20px"
          color="default.secondarytext"
        />
        <ModalBody borderRadius="0 0 4px 4px" p="18px 32px">
          <Stack
            spacing="8px"
            sx={{
              border: '1px solid #E7EDF4',
              borderRadius: '6px',
              p: '16px',
            }}
          >
            <Flex gap="10px" justifyContent="space-between">
              <Flex
                sx={{
                  border: '1px solid #E7EDF4',
                  borderRadius: '6px',
                  p: '21px 16px',
                  alignItems: 'center',
                  width: '100%',
                  cursor: 'pointer',
                }}
                _hover={{
                  bgColor: '#EFE5FF45',
                  borderColor: '#6930CA',
                }}
              >
                <Flex gap="10px" sx={{ width: '100%', alignItems: 'center' }}>
                  <Image src={MdGmail} width="30px" height="30px" />
                  <Text>Google / Gmail</Text>
                </Flex>
                <Button
                  sx={{
                    boxShadow: '0px 2px 8px 0px #0000000A',
                    border: '1px solid #E7EDF4 !important',
                    p: '11px 16px',
                    bgColor: '#fff',
                    color: '#718096',
                  }}
                  _hover={{
                    border: '1px solid #E7EDF4',
                    bgColor: '#fff',
                    color: '#718096',
                  }}
                  isLoading={isLoadingGmail}
                  onClick={handleConnectToGmail}
                >
                  Connect
                </Button>
              </Flex>
            </Flex>

            <Flex gap="10px" justifyContent="space-between">
              <Flex
                sx={{
                  border: '1px solid #E7EDF4',
                  borderRadius: '6px',
                  p: '21px 16px',
                  alignItems: 'center',
                  width: '100%',
                  cursor: 'pointer',
                }}
                _hover={{
                  bgColor: '#EFE5FF45',
                  borderColor: '#6930CA',
                }}
              >
                <Flex gap="10px" sx={{ width: '100%', alignItems: 'center' }}>
                  <Image src={MdOutlook} width="30px" height="30px" />
                  <Text>Microsoft / Outlook</Text>
                </Flex>
                <Button
                  sx={{
                    boxShadow: '0px 2px 8px 0px #0000000A',
                    border: '1px solid #E7EDF4 !important',
                    p: '11px 16px',
                    bgColor: '#fff',
                    color: '#718096',
                  }}
                  _hover={{
                    border: '1px solid #E7EDF4',
                    bgColor: '#fff',
                    color: '#718096',
                  }}
                  isLoading={isLoadingOutlook}
                  onClick={handleConnectToOutlook}
                >
                  Connect
                </Button>
              </Flex>
            </Flex>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
