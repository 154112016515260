/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import {
  updateOutreach,
  useBulkDeleteEmailTemplateMutation,
  useGetEmailTemplateListMutation,
  usePostChangePrimaryTemplateMutation,
  usePostSendEmailMutation,
  usePostSyncPrimaryTemplateMutation,
} from 'store/outreach.slice';

import { tabOptions } from '../../../constants';
import { EMAIL_SCHEMA } from './constants';

export const useEmailTemplateTableServices = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const toast = useToast();

  const { settingType } = useParams();

  const isEmailTemplate = settingType === tabOptions[1];

  const {
    sequencesData,
    emailTemplateList,
    emailTemplateData,
    emailTemplatePgBtn,
    emailTemplatePage,
    isLoading,
  } = useSelector((state: any) => state.outreach);
  const { currentProfile } = useSelector((state: any) => state.auth);

  const [reqGetEmailTemplateList] = useGetEmailTemplateListMutation();
  const [reqPostChangePrimaryTemplate, resPostChangePrimaryTemplate] =
    usePostChangePrimaryTemplateMutation();
  const [reqPostSyncPrimaryTemplate, resPostSyncPrimaryTemplate] =
    usePostSyncPrimaryTemplateMutation();
  const [reqBulkDeleteEmailTemplate, resBulkDeleteEmailTemplate] =
    useBulkDeleteEmailTemplateMutation();
  const [reqPostSendEmail, resPostSendEmail] = usePostSendEmailMutation();

  const [rowId, setRowId] = useState(emailTemplateData?.rowId);
  const [totalTemplateList, setTotalTemplateList] = useState(
    emailTemplateList.length
  );

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [isIndeterminate, setisIndeterminate] = useState(false);

  const initialValueEmail = {
    to: sequencesData?.user?.email || currentProfile?.email || '',
  };

  const validationSchemaEmail = EMAIL_SCHEMA;

  const handleSendEmail = (data: any) => {
    const payload = {
      subject: data.subject,
      body: data.content,
      to: data.to,
    };

    reqPostSendEmail(payload);
  };

  const handleSelectAll = (e: any) => {
    const { checked } = e.target;
    setIsCheckAll(checked);
    setIsCheck(emailTemplateList.map((li: any) => li.id));

    if (!checked) {
      setIsCheck([]);
    }
  };

  const handleCheck = async (e: any) => {
    const { id, checked } = e.target;
    let ID = parseInt(id);
    setIsCheck([...isCheck, ID]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== ID));
    }
  };

  const pageChange = async (page: number) => {
    let newParam = {
      ...emailTemplatePgBtn,
      page: page,
      take: emailTemplatePgBtn.take,
      query: emailTemplatePgBtn.query,
    };
    dispatch(
      updateOutreach({
        emailTemplatePgBtn: newParam,
      })
    );
    reqGetEmailTemplateList(newParam);
  };

  const entryChange = (entries: number) => {
    let newParam = {
      ...emailTemplatePgBtn,
      page: 1,
      take: entries,
    };
    dispatch(
      updateOutreach({
        emailTemplatePgBtn: newParam,
      })
    );
    reqGetEmailTemplateList(newParam);
  };

  useEffect(() => {
    setisIndeterminate(false);
    if (isCheck.length > 0 && isCheck.length < emailTemplateList.length) {
      setisIndeterminate(true);
    }
    if (isCheck.length <= 0) {
      setIsCheckAll(false);
    }
    if (isCheck.length === emailTemplateList.length) {
      setIsCheckAll(true);
    }
  }, [isCheck, isCheckAll, emailTemplateList]);

  const handlePrevData = () => {
    const templateID = emailTemplateList[rowId - 1 - 1].id;
    setRowId((prev: number) => prev - 1);
    navigate(
      `/engage/${params.settingNav}/${params.settingTab}/${params.settingType}/${templateID}`
    );
  };

  const handleNextData = () => {
    const templateID = emailTemplateList[rowId - 1 + 1].id;
    setRowId((prev: number) => prev + 1);
    navigate(
      `/engage/${params.settingNav}/${params.settingTab}/${params.settingType}/${templateID}`
    );
  };

  const handleChangePrimary = (id: number) => {
    reqPostChangePrimaryTemplate(id);
  };

  const handleSyncPrimary = (id: number) => {
    reqPostSyncPrimaryTemplate(id);
  };

  const handleBulkDeleteEmailTemplate = () => {
    const payload = {
      ids: isCheck,
    };

    reqBulkDeleteEmailTemplate(payload);
  };

  useEffect(() => {
    setTotalTemplateList(emailTemplateList.length);
  }, [emailTemplateList]);

  useEffect(() => {
    if (emailTemplateData?.rowId) {
      setRowId(emailTemplateData?.rowId);
    }
  }, [emailTemplateData?.rowId]);

  useEffect(() => {
    if (isEmailTemplate) {
      reqGetEmailTemplateList(emailTemplatePgBtn);
    }
  }, [isEmailTemplate]);

  useEffect(() => {
    if (resBulkDeleteEmailTemplate.isSuccess) {
      reqGetEmailTemplateList(emailTemplatePgBtn);

      setIsCheckAll(false);
      setIsCheck([]);
      toast({
        title: 'Deleted template(s).',
        description: "We've deleted your template(s).",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resBulkDeleteEmailTemplate.isSuccess]);

  useEffect(() => {
    if (resPostChangePrimaryTemplate.isSuccess) {
      reqGetEmailTemplateList(emailTemplatePgBtn);

      toast({
        title: 'Primary template updated.',
        description: "We've updated your primary template.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostChangePrimaryTemplate.isSuccess]);

  useEffect(() => {
    if (resPostSyncPrimaryTemplate.isSuccess) {
      reqGetEmailTemplateList(emailTemplatePgBtn);

      const result = resPostSyncPrimaryTemplate.data.data.template;

      dispatch(
        updateOutreach({
          emailTemplateData: {
            ...emailTemplateData,
            title: result.title,
            role: result.role,
            description: result.description,
            primary: result.primary,
          },
        })
      );

      toast({
        title: 'Primary template synced.',
        description: "We've sync your primary template.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostSyncPrimaryTemplate.isSuccess]);

  useEffect(() => {
    if (resPostSendEmail.isSuccess) {
      toast({
        title: 'Email preview sent.',
        description: "We've emailed a template.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostSendEmail.isSuccess]);

  return {
    rowId,
    setRowId,
    isCheck,
    isIndeterminate,
    isCheckAll,
    handleCheck,
    handleSelectAll,
    totalTemplateList,
    setTotalTemplateList,
    handlePrevData,
    handleNextData,
    emailTemplateData,
    emailTemplateList,
    emailTemplatePgBtn,
    handleChangePrimary,
    handleSyncPrimary,
    handleBulkDeleteEmailTemplate,
    handleSendEmail,
    isLoading,
    initialValueEmail,
    validationSchemaEmail,
    emailTemplatePage,
    pageChange,
    entryChange,
  };
};
