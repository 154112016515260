import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Formik } from 'formik';

import {
  Box,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from '@chakra-ui/react';

import Button from 'Library/Button';
import FAIcon from 'components/lib/FAIcon';

import ScrollToFieldError from 'components/app/ScrollError';
import LoadingPage from 'components/app/Loading';
import TemplateTable from './table';
import TemplateAddModal from '../../../Global/TemplateModal';
import { useListTemplateMutation } from 'store/template.slice';
import { useGetMeetingTypesMutation } from 'store/constant.slice';

export default function EmailTemplates() {
  const initialValue = {
    search: '',
  };

  const { templateNav, templatePage, templatePageBtn } = useSelector(
    (state: any) => state.template
  );
  const [reqListTemplate, resListTemplate] = useListTemplateMutation();
  const [getMeetingType] = useGetMeetingTypesMutation();

  useEffect(() => {
    getMeetingType({});
  }, []);

  const {
    isOpen: addOpen,
    onOpen: addOnOpen,
    onClose: addOnClose,
  } = useDisclosure();

  const onSubmit = (data: any) => {
    let params = {
      page: 1,
      take: templatePageBtn.take,
      query: data.search,
    };
    submitForm(params);
  };

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      let params = {
        page: 1,
        take: templatePageBtn.take,
        query: '',
      };
      submitForm(params);
    }
  };

  const submitForm = (form: any) => {
    reqListTemplate(form);
  };

  const [tabIndex, setTabIndex] = useState(1);

  const [checkboxes, setCheckboxes] = useState([
    { label: 'All', isChecked: false },
    { label: 'My Templates', isChecked: false },
    { label: 'Organization', isChecked: false },
  ]);

  const handleCheckboxChange = (index: any) => (e: any) => {
    const newCheckboxes = checkboxes.map((checkbox, i) => ({
      ...checkbox,
      isChecked: i === index ? e.target.checked : false,
    }));
    setCheckboxes(newCheckboxes);
  };

  return (
    <Box fontFamily="NunitoSans Regular">
      <Flex justifyContent="space-between" px={6}>
        <Flex>
          <Formik
            enableReinitialize
            initialValues={initialValue}
            onSubmit={onSubmit}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Flex
                justifyContent="space-around"
                maxWidth="40%"
                alignItems="center"
              >
                <Box pr={2}>
                  <form onSubmit={handleSubmit}>
                    <ScrollToFieldError />
                    <InputGroup background="#fff">
                      <InputRightElement
                        cursor="pointer"
                        children={<FAIcon iconName="search" />}
                      />
                      <Input
                        type="search"
                        placeholder="Search Template"
                        value={values.search}
                        id="search"
                        onChange={(e) => {
                          handleChange(e);
                          clearSearch(e);
                        }}
                      />
                    </InputGroup>
                  </form>
                </Box>
              </Flex>
            )}
          </Formik>

          <Flex px={2} justifyContent="flex-start">
            {checkboxes.map((checkbox, index) => (
              <Checkbox
                key={index}
                colorScheme="purple"
                color="default.secondarytext"
                mx={1}
                isChecked={checkbox.isChecked}
                onChange={handleCheckboxChange(index)}
              >
                {checkbox.label}
              </Checkbox>
            ))}
          </Flex>
        </Flex>
        <Button leftIcon="plus" variant="solid" onClick={addOnOpen}>
          Add Template
        </Button>
      </Flex>
      <Box>
        <Box position="relative">
          <Tabs colorScheme="purple" index={tabIndex - 1} m="10px 30px">
            <TabList
              sx={{
                pt: '16px',
                fontSize: '14px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
              }}
            >
              <Tab
                sx={{
                  fontWeight: 700,
                  cursor: 'auto',
                  '&:focus': {
                    boxShadow: 'none',
                    background: 'none',
                  },
                }}
                onClick={() => setTabIndex(1)}
              >
                Candidate
              </Tab>
              <Tab
                sx={{
                  fontWeight: 700,
                  cursor: 'auto',
                  '&:focus': {
                    boxShadow: 'none',
                    background: 'none',
                  },
                }}
                onClick={() => setTabIndex(2)}
              >
                Submittal
              </Tab>
              <Tab
                sx={{
                  fontWeight: 700,
                  cursor: 'auto',
                  '&:focus': {
                    boxShadow: 'none',
                    background: 'none',
                  },
                }}
                onClick={() => setTabIndex(3)}
              >
                Client
              </Tab>
              <Tab
                sx={{
                  fontWeight: 700,
                  cursor: 'auto',
                  '&:focus': {
                    boxShadow: 'none',
                    background: 'none',
                  },
                }}
                onClick={() => setTabIndex(4)}
              >
                Interview
              </Tab>
              <Tab
                sx={{
                  fontWeight: 700,
                  cursor: 'auto',
                  '&:focus': {
                    boxShadow: 'none',
                    background: 'none',
                  },
                }}
                onClick={() => setTabIndex(5)}
              >
                Interview Invite
              </Tab>
              <Tab
                sx={{
                  fontWeight: 700,
                  cursor: 'auto',
                  '&:focus': {
                    boxShadow: 'none',
                    background: 'none',
                  },
                }}
                onClick={() => setTabIndex(6)}
              >
                Follow-up
              </Tab>
            </TabList>
            <TabIndicator color="#6930CA" />
            <TabPanels>
              <TabPanel p={0}>
                {tabIndex === 1 && (
                  <TemplateTable tabIndex={tabIndex} checkboxes={checkboxes} />
                )}
              </TabPanel>
              <TabPanel p={0}>
                {tabIndex === 2 && (
                  <TemplateTable tabIndex={tabIndex} checkboxes={checkboxes} />
                )}
              </TabPanel>
              <TabPanel p={0}>
                {tabIndex === 3 && (
                  <TemplateTable tabIndex={tabIndex} checkboxes={checkboxes} />
                )}
              </TabPanel>
              <TabPanel p={0}>
                {tabIndex === 4 && (
                  <TemplateTable tabIndex={tabIndex} checkboxes={checkboxes} />
                )}
              </TabPanel>
              <TabPanel p={0}>
                {tabIndex === 5 && (
                  <TemplateTable tabIndex={tabIndex} checkboxes={checkboxes} />
                )}
              </TabPanel>
              <TabPanel p={0}>
                {tabIndex === 6 && (
                  <TemplateTable tabIndex={tabIndex} checkboxes={checkboxes} />
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
      {addOpen && <TemplateAddModal isOpen={addOpen} onClose={addOnClose} />}
    </Box>
  );
}
