import { Flex, Text } from '@chakra-ui/react';
import { useState } from 'react';

interface SwitchButtonProps {
  active: number;
  setActive: any;
  content: any;
}
export const SwitchButton = ({
  active,
  setActive,
  content,
}: SwitchButtonProps) => {
  return (
    <Flex
      sx={{
        bgColor: '#fff',
        borderRadius: '70px',
        p: '4px 10px',
        fontFamily: 'NunitoSans Regular',
      }}
    >
      {content.map((val: any, index: any) => {
        const isActive = val.id === active;
        return (
          <Flex
            key={index}
            gap="8px"
            sx={{
              bgColor: isActive ? '#E9D8FD' : '#fff',
              borderRadius: '50px',
              p: '7px 16px',
              cursor: 'pointer',
            }}
            onClick={() => {
              setActive(val.id);
            }}
          >
            <Text
              sx={{
                fontSize: '14px',
                fontWeight: 700,
                color: isActive ? '#6930CA' : '#6B6C7B',
              }}
            >
              {val.label}
            </Text>
            <Text sx={{ fontSize: '14px', fontWeight: 700, color: '#2B2D42' }}>
              {val.value}
            </Text>
          </Flex>
        );
      })}
    </Flex>
  );
};
