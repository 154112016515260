import { useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDelAgreementAttachmentMutation } from 'store/leadagreement.sclice';
import { removeAttachment } from 'store/uploads.slice';
import { swalContent } from 'types';
import { AtsConfirm } from 'utils/swal';

export const useAgreementAttachmentsServices = () => {
  const dispatch = useDispatch();

  const [fileData, setFileData] = useState(null);

  const [reqdel] = useDelAgreementAttachmentMutation();

  const content: swalContent = {
    title: 'Are you sure?',
    text: 'Once deleted, you will not be able to recover this file.',
    buttons: ['Cancel', true],
    icon: 'warning',
  };

  const {
    isOpen: isStreamOpen,
    onOpen: onStreamOpen,
    onClose: onStreamClose,
  } = useDisclosure();

  const delAttachment = async (id: number, key: number) => {
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqdel({ id });
      dispatch(removeAttachment(key));
    }
  };

  return {
    onStreamOpen,
    setFileData,
    delAttachment,
    isStreamOpen,
    onStreamClose,
    fileData,
  };
};
