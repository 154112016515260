import * as Yup from 'yup';

export const EMAIL_TEMPLATE_SCHEMA = Yup.lazy(() =>
  Yup.object().shape({
    title: Yup.string().required('Title Name is required.'),
    role: Yup.string(),
    primary: Yup.bool(),
    touches: Yup.array().of(
      Yup.object().shape({
        days_to_wait: Yup.number(),
        subject: Yup.string(),
        subject_remote: Yup.string(),
        content: Yup.string(),
        order: Yup.number(),
        previewOpen: Yup.bool(),
      })
    ),
  })
);

export const EMAIL_SCHEMA = Yup.lazy(() =>
  Yup.object().shape({
    to: Yup.string().email('Invalid email.').required('Email is required.'),
  })
);
