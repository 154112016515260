import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from './api.slice';
import { appError } from './app.slice';

export const ringCentralSlice = createSlice({
  name: 'ringcentral',
  initialState: {
    ringcentralData: {},
  },
  reducers: {
    getRingCentral: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { getRingCentral } = ringCentralSlice.actions;
export default ringCentralSlice.reducer;

const msGraphApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRingCentral: builder.mutation({
      query: () => ({
        url: `/ring-central/view`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getRingCentral({ ringcentralData: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    setRingCentral: builder.mutation({
      query: (data) => ({
        url: `/ring-central/create`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getRingCentral({ leads: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    authRingCentral: builder.mutation({
      query: (data) => ({
        url: `/ring-central/auth`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    synchRingCentral: builder.mutation({
      query: (data) => ({
        url: `/ring-central/get-logs`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  useGetRingCentralMutation,
  useSetRingCentralMutation,
  useAuthRingCentralMutation,
  useSynchRingCentralMutation,
} = msGraphApiSlice;
