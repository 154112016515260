import { Box, Flex, Grid, GridItem, Stack, Text } from '@chakra-ui/react';
import { createTheme, ThemeProvider } from '@mui/material';
import { PieChart } from '@mui/x-charts';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';

export const CommandCenterModule = () => {
  const MuiTheme = createTheme({
    palette: {
      mode: 'light',
    },
  });

  const content = [
    {
      email: 'shrnatienza@gmail.com',
      total: 0,
      step1: 0,
      step2: 0,
      step3: 0,
      step4: 0,
      step5: 0,
      step6: 0,
    },
    {
      email: 'atienzasharina@gmail.com',
      total: 0,
      step1: 0,
      step2: 0,
      step3: 0,
      step4: 0,
      step5: 0,
      step6: 0,
    },
    {
      email: 'test.email@example.com',
      total: 25,
      step1: 5,
      step2: 5,
      step3: 5,
      step4: 5,
      step5: 5,
      step6: 0,
    },
  ];

  const data = [
    { value: 5, label: 'Step 1', color: '#6930CA' },
    { value: 10, label: 'Step 2', color: '#8759D5' },
    { value: 15, label: 'Step 3', color: '#A875FF' },
    { value: 20, label: 'Step 4', color: '#CEB8FF' },
    { value: 20, label: 'Step 5', color: '#CD5CFF' },
    { value: 20, label: 'Step 6', color: '#B100FF' },
  ];

  const size = {
    width: 400,
    height: 150,
  };

  const StyledText = styled('text')(() => ({
    fill: '#6930CA',
    textAnchor: 'middle',
    fontSize: '24px',
    fontWeight: 700,
  }));

  const StyledText1 = styled('text')(() => ({
    fill: '#AE8FE2',
    textAnchor: 'middle',
    fontSize: '16px',
    padding: '4px',
    backgroundColor: '#FAF5FF',
    borderRadius: '8px',
  }));

  function PieCenterLabel({ children }: { children: React.ReactNode }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText
        x={left + width / 2}
        y={top + height / 2}
        fontFamily="NunitoSans Regular"
      >
        {children}
      </StyledText>
    );
  }

  function PieCenterLabel1({ children }: { children: React.ReactNode }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText1
        x={left + width / 2}
        y={top + 25 + height / 2}
        fontFamily="NunitoSans Regular"
      >
        {children}
      </StyledText1>
    );
  }

  return (
    <Stack spacing="36px" fontFamily="NunitoSans Regular">
      <Flex gap="24px">
        <Box
          sx={{
            w: '30%',
            bgColor: '#fff',
            border: '1px solid #EEEEEE',
            borderRadius: '5px',
            p: '16px',
          }}
        >
          <Text sx={{ fontSize: '16px', color: '#2B2D42' }}>
            Pending Email Per Step
          </Text>

          <Box sx={{ my: '30px', height: '30vh', width: '100%' }}>
            <ThemeProvider theme={MuiTheme}>
              <PieChart
                series={[
                  {
                    data,
                    highlightScope: { faded: 'global', highlighted: 'item' },
                    innerRadius: 45,
                  },
                ]}
                margin={{ left: 0, right: 0 }}
                slotProps={{
                  legend: {
                    itemGap: 5,
                    direction: 'column',
                    position: { vertical: 'middle', horizontal: 'left' },
                    padding: 0,
                    labelStyle: {
                      fontSize: 14,
                    },
                  },
                }}
                {...size}
              >
                <PieCenterLabel>Total</PieCenterLabel>
                <PieCenterLabel1>466</PieCenterLabel1>
              </PieChart>
            </ThemeProvider>
          </Box>
        </Box>
        <Box
          sx={{
            w: '70%',
            bgColor: '#fff',
            border: '1px solid #EEEEEE',
            borderRadius: '5px',
            p: '16px',
          }}
        >
          <Text sx={{ mb: '10px', fontSize: '16px', color: '#2B2D42' }}>
            Queued Email per Mailbox
          </Text>
          <Stack spacing="32px" sx={{ p: '10px' }}>
            <Stack spacing="8px">
              <Text
                sx={{ fontSize: '16px', color: '#718096', fontWeight: 700 }}
              >
                Total
              </Text>
              <Text sx={{ fontSize: '24px', color: '#2B2D42' }}>25</Text>
            </Stack>
            <Stack>
              {content.map((val) => {
                return (
                  <Grid templateColumns="repeat(8, 1fr)" gap={3}>
                    <GridItem w="200px">
                      <Text sx={{ color: '#2B2D42' }}>{val.email}</Text>
                    </GridItem>
                    <GridItem w="50px">
                      <Text sx={{ color: '#718096', textAlign: 'center' }}>
                        {val.total}
                      </Text>
                    </GridItem>
                    <GridItem
                      w="100%"
                      sx={{
                        bgColor: '#FAF5FF',
                      }}
                    >
                      <Text
                        sx={{
                          borderRadius: '2px',
                          p: '4px',
                          textAlign: 'center',
                          color: '#6930CA',
                        }}
                      >
                        {val.step1}
                      </Text>
                    </GridItem>
                    <GridItem
                      w="100%"
                      sx={{
                        bgColor: '#FAF5FF',
                      }}
                    >
                      <Text
                        sx={{
                          borderRadius: '2px',
                          p: '4px',
                          textAlign: 'center',
                          color: '#6930CA',
                        }}
                      >
                        {val.step2}
                      </Text>
                    </GridItem>
                    <GridItem
                      w="100%"
                      sx={{
                        bgColor: '#FAF5FF',
                      }}
                    >
                      <Text
                        sx={{
                          borderRadius: '2px',
                          p: '4px',
                          textAlign: 'center',
                          color: '#6930CA',
                        }}
                      >
                        {val.step3}
                      </Text>
                    </GridItem>
                    <GridItem
                      w="100%"
                      sx={{
                        bgColor: '#FAF5FF',
                      }}
                    >
                      <Text
                        sx={{
                          borderRadius: '2px',
                          p: '4px',
                          textAlign: 'center',
                          color: '#6930CA',
                        }}
                      >
                        {val.step4}
                      </Text>
                    </GridItem>
                    <GridItem
                      w="100%"
                      sx={{
                        bgColor: '#FAF5FF',
                      }}
                    >
                      <Text
                        sx={{
                          borderRadius: '2px',
                          p: '4px',
                          textAlign: 'center',
                          color: '#6930CA',
                        }}
                      >
                        {val.step5}
                      </Text>
                    </GridItem>
                    <GridItem
                      w="100%"
                      sx={{
                        bgColor: '#FAF5FF',
                      }}
                    >
                      <Text
                        sx={{
                          borderRadius: '2px',
                          p: '4px',
                          textAlign: 'center',
                          color: '#6930CA',
                        }}
                      >
                        {val.step6}
                      </Text>
                    </GridItem>
                  </Grid>
                );
              })}
              <Grid templateColumns="repeat(8, 1fr)" gap={3} mt="10px">
                <GridItem w="200px" />
                <GridItem w="50px" />
                <GridItem w="100%">
                  <Text
                    sx={{
                      borderRadius: '2px',
                      p: '4px',
                      textAlign: 'center',
                      color: '#2B2D42',
                    }}
                  >
                    Step 1
                  </Text>
                </GridItem>
                <GridItem w="100%">
                  <Text
                    sx={{
                      borderRadius: '2px',
                      p: '4px',
                      textAlign: 'center',
                      color: '#2B2D42',
                    }}
                  >
                    Step 2
                  </Text>
                </GridItem>
                <GridItem w="100%">
                  <Text
                    sx={{
                      borderRadius: '2px',
                      p: '4px',
                      textAlign: 'center',
                      color: '#2B2D42',
                    }}
                  >
                    Step 3
                  </Text>
                </GridItem>
                <GridItem w="100%">
                  <Text
                    sx={{
                      borderRadius: '2px',
                      p: '4px',
                      textAlign: 'center',
                      color: '#2B2D42',
                    }}
                  >
                    Step 4
                  </Text>
                </GridItem>
                <GridItem w="100%">
                  <Text
                    sx={{
                      borderRadius: '2px',
                      p: '4px',
                      textAlign: 'center',
                      color: '#2B2D42',
                    }}
                  >
                    Step 5
                  </Text>
                </GridItem>
                <GridItem w="100%">
                  <Text
                    sx={{
                      borderRadius: '2px',
                      p: '4px',
                      textAlign: 'center',
                      color: '#2B2D42',
                    }}
                  >
                    Step 6
                  </Text>
                </GridItem>
              </Grid>
            </Stack>
          </Stack>
        </Box>
      </Flex>

      <Flex gap="24px">
        <Box
          sx={{
            w: '30%',
            bgColor: '#fff',
            border: '1px solid #EEEEEE',
            borderRadius: '5px',
            p: '16px',
          }}
        >
          <Text sx={{ fontSize: '16px', color: '#2B2D42' }}>
            Sent Email per Step (Today)
          </Text>

          <Box sx={{ my: '30px', height: '30vh', width: '100%' }}>
            <ThemeProvider theme={MuiTheme}>
              <PieChart
                series={[
                  {
                    data,
                    highlightScope: { faded: 'global', highlighted: 'item' },
                    innerRadius: 45,
                  },
                ]}
                margin={{ left: 0, right: 0 }}
                slotProps={{
                  legend: {
                    itemGap: 5,
                    direction: 'column',
                    position: { vertical: 'middle', horizontal: 'left' },
                    padding: 0,
                    labelStyle: {
                      fontSize: 14,
                    },
                  },
                }}
                {...size}
              >
                <PieCenterLabel>Total</PieCenterLabel>
                <PieCenterLabel1>466</PieCenterLabel1>
              </PieChart>
            </ThemeProvider>
          </Box>
        </Box>
        <Box
          sx={{
            w: '70%',
            bgColor: '#fff',
            border: '1px solid #EEEEEE',
            borderRadius: '5px',
            p: '16px',
          }}
        >
          <Text sx={{ mb: '10px', fontSize: '16px', color: '#2B2D42' }}>
            Sent Email per Mailbox (Today)
          </Text>
          <Stack spacing="32px" sx={{ p: '10px' }}>
            <Stack spacing="8px">
              <Text
                sx={{ fontSize: '16px', color: '#718096', fontWeight: 700 }}
              >
                Total
              </Text>
              <Text sx={{ fontSize: '24px', color: '#2B2D42' }}>25</Text>
            </Stack>
            <Stack>
              {content.map((val) => {
                return (
                  <Grid templateColumns="repeat(8, 1fr)" gap={3}>
                    <GridItem w="200px">
                      <Text sx={{ color: '#2B2D42' }}>{val.email}</Text>
                    </GridItem>
                    <GridItem w="50px">
                      <Text sx={{ color: '#718096', textAlign: 'center' }}>
                        {val.total}
                      </Text>
                    </GridItem>
                    <GridItem
                      w="100%"
                      sx={{
                        bgColor: '#FAF5FF',
                      }}
                    >
                      <Text
                        sx={{
                          borderRadius: '2px',
                          p: '4px',
                          textAlign: 'center',
                          color: '#6930CA',
                        }}
                      >
                        {val.step1}
                      </Text>
                    </GridItem>
                    <GridItem
                      w="100%"
                      sx={{
                        bgColor: '#FAF5FF',
                      }}
                    >
                      <Text
                        sx={{
                          borderRadius: '2px',
                          p: '4px',
                          textAlign: 'center',
                          color: '#6930CA',
                        }}
                      >
                        {val.step2}
                      </Text>
                    </GridItem>
                    <GridItem
                      w="100%"
                      sx={{
                        bgColor: '#FAF5FF',
                      }}
                    >
                      <Text
                        sx={{
                          borderRadius: '2px',
                          p: '4px',
                          textAlign: 'center',
                          color: '#6930CA',
                        }}
                      >
                        {val.step3}
                      </Text>
                    </GridItem>
                    <GridItem
                      w="100%"
                      sx={{
                        bgColor: '#FAF5FF',
                      }}
                    >
                      <Text
                        sx={{
                          borderRadius: '2px',
                          p: '4px',
                          textAlign: 'center',
                          color: '#6930CA',
                        }}
                      >
                        {val.step4}
                      </Text>
                    </GridItem>
                    <GridItem
                      w="100%"
                      sx={{
                        bgColor: '#FAF5FF',
                      }}
                    >
                      <Text
                        sx={{
                          borderRadius: '2px',
                          p: '4px',
                          textAlign: 'center',
                          color: '#6930CA',
                        }}
                      >
                        {val.step5}
                      </Text>
                    </GridItem>
                    <GridItem
                      w="100%"
                      sx={{
                        bgColor: '#FAF5FF',
                      }}
                    >
                      <Text
                        sx={{
                          borderRadius: '2px',
                          p: '4px',
                          textAlign: 'center',
                          color: '#6930CA',
                        }}
                      >
                        {val.step6}
                      </Text>
                    </GridItem>
                  </Grid>
                );
              })}
              <Grid templateColumns="repeat(8, 1fr)" gap={3} mt="10px">
                <GridItem w="200px" />
                <GridItem w="50px" />
                <GridItem w="100%">
                  <Text
                    sx={{
                      borderRadius: '2px',
                      p: '4px',
                      textAlign: 'center',
                      color: '#2B2D42',
                    }}
                  >
                    Step 1
                  </Text>
                </GridItem>
                <GridItem w="100%">
                  <Text
                    sx={{
                      borderRadius: '2px',
                      p: '4px',
                      textAlign: 'center',
                      color: '#2B2D42',
                    }}
                  >
                    Step 2
                  </Text>
                </GridItem>
                <GridItem w="100%">
                  <Text
                    sx={{
                      borderRadius: '2px',
                      p: '4px',
                      textAlign: 'center',
                      color: '#2B2D42',
                    }}
                  >
                    Step 3
                  </Text>
                </GridItem>
                <GridItem w="100%">
                  <Text
                    sx={{
                      borderRadius: '2px',
                      p: '4px',
                      textAlign: 'center',
                      color: '#2B2D42',
                    }}
                  >
                    Step 4
                  </Text>
                </GridItem>
                <GridItem w="100%">
                  <Text
                    sx={{
                      borderRadius: '2px',
                      p: '4px',
                      textAlign: 'center',
                      color: '#2B2D42',
                    }}
                  >
                    Step 5
                  </Text>
                </GridItem>
                <GridItem w="100%">
                  <Text
                    sx={{
                      borderRadius: '2px',
                      p: '4px',
                      textAlign: 'center',
                      color: '#2B2D42',
                    }}
                  >
                    Step 6
                  </Text>
                </GridItem>
              </Grid>
            </Stack>
          </Stack>
        </Box>
      </Flex>
    </Stack>
  );
};
