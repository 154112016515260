import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Link,
} from '@chakra-ui/react';

import { downloadLink } from 'utils/downloadlink';
import { StreamModalProps } from './constants';
import { useStreamAttachmentServices } from './useStreamAttachment';

export const StreamAttachmentModal = ({
  isOpen,
  onClose,
  file,
  route = '/lead-attachment/download/',
}: StreamModalProps) => {
  const { StreamComponent } = useStreamAttachmentServices({ file });
  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={() => {
        onClose();
      }}
      size="3xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent fontFamily="NunitoSans Regular">
        <ModalHeader
          background="default.white.800"
          borderRadius="4px 4px 0"
          p="18px 32px"
          fontSize="18px"
          lineHeight="21.92px"
          textAlign="left"
        >
          {file.original_file_name}
        </ModalHeader>
        <ModalCloseButton
          top="13px"
          right="20px"
          color="default.secondarytext"
        />
        <ModalBody
          borderRadius="0 0 4px 4px"
          p={0}
          alignItems="center"
          textAlign={'center'}
        >
          <StreamComponent />
        </ModalBody>
        <ModalFooter
          position="sticky"
          bottom="0"
          background="default.white.100"
          boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
        >
          <Link variant="solid" href={downloadLink(file.id, route)}>
            Download
          </Link>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
