import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Checkbox,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Radio,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import Button from 'Library/Button';
import Pagination from 'Library/Pagination';
import FAIcon from 'components/lib/FAIcon';
import { useParams } from 'react-router';
import {
  listTemplate,
  useActivateTemplateMutation,
  useDeActivateTemplateMutation,
  useDefaultTemplateListMutation,
  useDefaultTemplateMutation,
  useDelTemplateMutation,
  useListTemplateMutation,
} from 'store/template.slice';
import TrLoading from 'components/app/TrLoading';
import TemplateEditModal from './modal/edit';
import { swalContent } from 'types';
import { AtsConfirm } from 'utils/swal';
import PreviewTemplate from './modal/preview';
import replacePlaceholders from 'utils/textPlaceholders';

const TemplateTable = ({ tabIndex, checkboxes }: any) => {
  const dispatch = useDispatch();
  const params = useParams();
  const tableHeader: { label: any }[] = [
    { label: 'Template Name' },
    { label: 'Template Type' },
    { label: 'Default' },
    { label: '' },
  ];

  const { userList } = useSelector((state: any) => state.user);
  const { id: userId } = useSelector((state: any) => state.auth);
  const { templateNav, templatePage, templatePageBtn, templatesList } =
    useSelector((state: any) => state.template);
  const [reqListTemplate, resListTemplate] = useListTemplateMutation();
  const [templateData, setTemplateData] = useState(null);
  const [reqActivate, resActivate] = useActivateTemplateMutation();
  const [reqDeActivate, resDeActivate] = useDeActivateTemplateMutation();
  const [reqDefault, resDefault] = useDefaultTemplateMutation();
  const [reqDel, resDel] = useDelTemplateMutation();
  const [reqDefTempList, resDefTempList] = useDefaultTemplateListMutation()

  const {
    isOpen: editOpen,
    onOpen: editOnOpen,
    onClose: editOnClose,
  } = useDisclosure();
  useEffect(() => {
    if (
      params.templateTab == 'email' ||
      params.templateTab == undefined ||
      resListTemplate.isSuccess
    ) {
      reqListTemplate(templatePageBtn);
      reqDefTempList({})
    }
  }, [
    params.templateTab,
    resActivate.isSuccess,
    resDeActivate.isSuccess,
    resDel.isSuccess,
  ]);

  const pageChange = async (page: number) => {
    let newParam = {
      page: page,
      take: templatePageBtn.take,
      query: templatePageBtn.query,
    };
    dispatch(
      listTemplate({
        templatePageBtn: newParam,
      })
    );
    reqListTemplate(newParam);
  };

  let content: swalContent = {
    title: 'Are you sure?',
    text: '',
    buttons: ['Cancel', true],
    icon: 'warning',
  };

  const emailStatus = (status: boolean) => {
    if (status)
      return (
        <Box>
          <Tag
            borderRadius="2px"
            variant="subtle"
            color="success.400"
            bg="success.100"
          >
            <TagLabel>Active</TagLabel>
          </Tag>
        </Box>
      );
    else
      return (
        <Box>
          <Tag
            borderRadius="2px"
            variant="subtle"
            color="caution.400"
            bg="caution.100"
          >
            <TagLabel>INACTIVE</TagLabel>
          </Tag>
        </Box>
      );
  };

  const entryChange = (entries: number) => {
    let newParam = {
      // page: UserPgBtn.page,
      page: 1,
      take: entries,
      query: templatePage.query,
    };
    dispatch(
      listTemplate({
        templatePage: newParam,
      })
    );
    reqListTemplate(newParam);
  };

  const editClick = (data: any) => {
    setTemplateData(data);
    editOnOpen();
  };

  const changeStatus = async (template: any) => {
    const status = template.active ? 'deactivate' : 'activate';
    content.text = `You are about to ${status} ${template.title}`;
    const confirm = await AtsConfirm(content);
    if (confirm) {
      if (template.active) {
        await reqDeActivate({ id: template.id });
      } else {
        await reqActivate({ id: template.id });
      }
    }
  };

  const delClick = async (template: any) => {
    content.text = `You are about to delete ${template.title}`;
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqDel({ id: template?.id });
      if (isOpenPreview) {
        onClosePreview()
      }
    }
  };


  const filteredTemplateList = templatesList.filter((item: any) => {
    if (
      checkboxes.find(
        (checkbox: any) =>
          checkbox.label === 'Organization' && checkbox.isChecked
      )
    ) {
      return item.shareable === 'everyone' && item.group_id === tabIndex;
    } else if (
      checkboxes.find(
        (checkbox: any) =>
          checkbox.label === 'My Templates' && checkbox.isChecked
      )
    ) {
      return item.shareable === 'onlye me' && item.group_id === tabIndex;
    }
    return item.group_id === tabIndex; // If 'All' is selected or no checkboxes are selected
  });

  const {
    isOpen: isOpenPreview,
    onOpen: onOpenPreview,
    onClose: onClosePreview,
  } = useDisclosure();

  interface ConfirmationPreviewInt {
    group_name: string;
    title: string;
    subject: string;
    body: string;
    email: any;
    isOwn: boolean;
    id: number;
  }

  const [PrevParam, setPrevParam] = useState<ConfirmationPreviewInt>();
  const { placehoderPair } = useSelector((state: any) => state.template);

  const placeholders = (text: string) => {
    return replacePlaceholders(text, placehoderPair);
  };

  const [selectedData, setSelectedData] = useState() as any;
  const PreviewClick = (values: any) => {
    setSelectedData(values)
    const param = {
      group_name: values.group.name,
      title: values.title,
      subject: placeholders(values.subject),
      body: placeholders(values.body),
      email: values.email,
      isOwn: userId === values.user_id,
      id: values.id,
    } as ConfirmationPreviewInt;

    setPrevParam(param);
    onOpenPreview();
  };

  const handleChangeDefault = async (value: number) => {
    await reqDefault({ id: value })
  }

  useEffect(() => {
    if (resDefault.isSuccess) {
      reqListTemplate(templatePageBtn);
      reqDefTempList({})
    }
  }, [resDefault.isSuccess])

  return (
    <Box py={4} justifyContent="space-between">
      <TableContainer
        boxSizing="border-box"
        borderX="1px solid"
        borderTop="1px solid"
        borderColor="default.white.400"
        borderRadius="md"
        minHeight="70vh"
      >
        <Table>
          <Thead>
            <Tr bg="default.white.600">
              {tableHeader.map((title) => (
                <Th key={title.label} textAlign="left">
                  <Box color="default.gray.600">{title.label}</Box>
                </Th>
              ))}
            </Tr>
          </Thead>

          <Tbody
            boxSizing="border-box"
            background="default.white.100"
            borderBottom="1px solid"
            borderColor="default.white.400"
          >
            {resListTemplate.isLoading ? (
              <TrLoading rows={3} columns={5} />
            ) : (
              templatesList?.length > 0 &&
              filteredTemplateList.map((item: any, key: number) => {
                const getFirstNameById = (userId: any) => {
                  const user = Object.values(userList).find(
                    (user: any) => user.id === userId
                  );
                  return user as any;
                };
                return (
                  <Tr
                    key={key}
                    _hover={{
                      bg: '#f8f9fa',
                    }}
                    cursor="pointer"
                  >
                    <Td
                      onClick={() => {
                        PreviewClick({
                          ...item,
                          email: getFirstNameById(userId)?.email,
                        });

                      }}
                    >
                      {item.title}
                    </Td>
                    <Td
                      onClick={() =>
                        PreviewClick({
                          ...item,
                          email: getFirstNameById(userId)?.email,
                        })
                      }
                    >
                      {item.shareable === 'everyone'
                        ? 'Organization'
                        : `${getFirstNameById(userId).first_name}'s Template`}
                    </Td>
                    <Td>
                      <Radio
                        colorScheme="purple"
                        name="default"
                        onChange={(e) => {
                          handleChangeDefault(Number(e.target.value))
                        }}
                        value={item.id}
                        isChecked={item.is_default}
                      />
                    </Td>
                    <Td>
                      <Menu>
                        <MenuButton>
                          <Button
                            htmlType="menu"
                            variant="ghost"
                            iconName="ellipsis-h"
                            iconSize="xl"
                          />
                        </MenuButton>

                        <MenuList fontSize="sm">
                          {userId === item.user_id && (
                            <MenuItem onClick={() => editClick(item)}>
                              <Flex
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <FAIcon iconName="pencil" />
                                <Box ml={12}>Edit</Box>
                              </Flex>
                            </MenuItem>
                          )}
                          <MenuItem
                            onClick={() => changeStatus(item)}
                            color={item.active ? 'caution.800' : 'green'}
                          >
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <FAIcon
                                iconName={item.active ? 'ban' : 'check'}
                              />
                              <Box ml={12}>
                                {item.active ? 'Deactivate' : 'Activate'}
                              </Box>
                            </Flex>
                          </MenuItem>
                          <MenuItem
                            onClick={() => delClick(item)}
                            color="caution.800"
                          >
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <FAIcon iconName="trash" />
                              <Box ml={12}>Delete</Box>
                            </Flex>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                );
              })
            )}
          </Tbody>
        </Table>
      </TableContainer>

      {isOpenPreview && (
        <PreviewTemplate
          isOpen={isOpenPreview}
          onClose={onClosePreview}
          handleEdit={editClick}
          handleDel={delClick}
          param={PrevParam}
          selectedData={selectedData}
          title="View Template"
        />
      )}

      <Pagination
        totalPages={templatePage.lastPage}
        currentPage={templatePage.currentPage}
        onPageChange={(page) => pageChange(page)}
        onEntryChange={(entries) => entryChange(entries)}
        totalEntries={templatePageBtn.count}
        currentCount={templatesList.length}
        targetCount={templatePage.take}
      />
      {editOpen && (
        <TemplateEditModal
          isOpen={editOpen}
          onClose={editOnClose}
          template={templateData}
        />
      )}
    </Box>
  );
};

export default TemplateTable;
