import moment from 'moment';
import { Box, Flex } from '@chakra-ui/react';

import { useNotesServices } from './useNotesServices';

export const LeadsNotes = () => {
  const { parse, leadDetails, hover, setHover } = useNotesServices();
  return (
    <Box mb="300px">
      <Box mb={3}>
        {leadDetails &&
          leadDetails.notes
            ?.map((notes: any) => {
              return (
                <Box borderBottom="1px solid #E6E9EE" py={4}>
                  <Flex
                    gap={2}
                    alignItems="center"
                    justifyContent="flex-start"
                    fontWeight={500}
                    fontSize="md"
                    color="#545454"
                  >
                    <Box>
                      {[notes?.user?.first_name, notes?.user?.last_name].join(
                        ' '
                      )}
                    </Box>
                    <Box color="#9B9B9B">|</Box>
                    <Box
                      onMouseEnter={() => {
                        setHover({ id: notes.id, hover: true });
                      }}
                      onMouseLeave={() => {
                        setHover({ id: notes.id, hover: false });
                      }}
                    >
                      {hover.id === notes.id && hover.hover
                        ? moment
                            .utc(notes.created_at)
                            .format('MM/DD/YYYY hh:mm:ss A')
                        : moment.utc(notes.created_at).format('MM/DD/YYYY')}
                    </Box>
                  </Flex>
                  <Box fontSize="lg">{parse(notes.notes)}</Box>
                </Box>
              );
            })
            .reverse()}
      </Box>
    </Box>
  );
};
