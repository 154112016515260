import { useState, useRef, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import { Input } from '@chakra-ui/react';
import { debounce } from 'lodash';
import { useGetDealsMutation } from 'store/dashboard.slice';
import { formatDigits } from 'utils/utilFormat';

function InputWidthForm({
  valueKey,
  dashboardData,
  reqUpdate,
  resUpdate,
  placeholder,
}: any) {
  const [data, setData] = useState(dashboardData);

  const spanRef = useRef<HTMLSpanElement | null>(null);
  const [inputWidth, setInputWidth] = useState<number>(
    spanRef.current?.offsetWidth
  );

  const [reqDeals] = useGetDealsMutation();

  useEffect(() => {
    setData(dashboardData);
  }, [dashboardData]);

  useEffect(() => {
    const newInputWidth = spanRef.current?.offsetWidth;
    if (inputWidth !== newInputWidth) {
      setInputWidth(newInputWidth + 2);
    }
  }, [spanRef.current?.offsetWidth]);

  useEffect(() => {
    if (resUpdate.isSuccess) {
      reqDeals({});
    }
  }, [resUpdate.isSuccess]);

  const onSubmit = debounce(async ({ e, setFieldValue }: any) => {
    let { value } = e.target;
    if (data[valueKey] !== Number(value.replace(/[^0-9.]/g, ''))) {
      const updateData = { [valueKey]: value.replace(/[^0-9.]/g, '') };
      await reqUpdate({ data: updateData, id: data.id });

      setFieldValue(`${valueKey}`, formatDigits(value.replace(/[^0-9.]/g, '')));
    }
  }, 500);

  return (
    <>
      <Formik
        initialValues={{
          [valueKey]: data[valueKey]?.toLocaleString(),
        }}
        onSubmit={onSubmit}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <span
              ref={spanRef}
              style={{
                position: 'absolute',
                fontSize: '16px',
                opacity: 0,
                zIndex: -100,
                whiteSpace: 'pre',
              }}
            >
              {values[valueKey]?.toLocaleString() || placeholder}
            </span>
            <Field
              as={Input}
              type="text"
              id={valueKey}
              name={valueKey}
              placeholder={placeholder}
              sx={{
                border: 'none',
                background: '#e7e7e7',
                padding: '10px',
                fontSize: '16px',
                fontWeight: 700,
                height: '17px',
                width: `${inputWidth + 30}px`,
                textTransform: 'capitalize',
                textAlign: 'center',
                bottom: '-5px',
              }}
              onClick={() => setFieldValue(`${valueKey}`, data[valueKey])}
              onChange={(event: any) => {
                let { value } = event.target;

                value = String(value);

                // Allow only numeric characters and a dot
                value = value.replace(/[^0-9.]/g, '');

                // Remove leading zeros
                value = value.replace(/^0+/g, '');

                // Format the number with commas
                const formattedValue = Number(value)?.toLocaleString();

                setFieldValue(`${valueKey}`, formattedValue);
                const newInputWidth = spanRef.current?.offsetWidth;
                setInputWidth(newInputWidth + 1);
              }}
              onBlur={(e: any) => {
                onSubmit({ e, setFieldValue });
              }}
            />
          </Form>
        )}
      </Formik>
    </>
  );
}

export default InputWidthForm;
