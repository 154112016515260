import moment from 'moment';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Flex,
  FormControl,
  FormLabel,
  ModalFooter,
  Button,
} from '@chakra-ui/react';

import { LeadAgreementAttachments } from '../Attachment';
import { LeadsEditAgreementModal } from '../Edit';

import { LeadsAgrrmentModalProps } from './types';
import { useViewAgreementServices } from './useViewAgreementServices';

export const LeadsViewAgreementModal = ({
  isOpen,
  onClose,
}: LeadsAgrrmentModalProps) => {
  const {
    agreementData,
    attachments,
    onEditAgreementOpen,
    isEditAgreementOpen,
    onEditAgreementClose,
  } = useViewAgreementServices();
  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onClose();
        }}
        size="3xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="NunitoSans Regular">
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            View Agreement
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          <ModalBody borderRadius="0 0 4px 4px" p={0}>
            <Box p="32px">
              <Flex gap="32px" mb="34px">
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.secondarytext"
                  >
                    Title
                  </FormLabel>
                  {agreementData?.title}
                </FormControl>
              </Flex>
              <Box mb="34px">
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.secondarytext"
                  >
                    Content
                  </FormLabel>
                  {agreementData?.content}
                </FormControl>
              </Box>

              <Flex gap="32px" mb="34px">
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.secondarytext"
                  >
                    Signed On:{' '}
                    {moment
                      .utc(agreementData?.date_signed)
                      .format('MM/DD/YYYY')}
                  </FormLabel>
                </FormControl>
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.secondarytext"
                  >
                    Valid Until:{' '}
                    {agreementData?.valid_until
                      ? moment
                          .utc(agreementData?.valid_until)
                          .format('MM/DD/YYYY')
                      : 'No Expiry'}
                  </FormLabel>
                </FormControl>
              </Flex>
              <Box mb="34px">
                <LeadAgreementAttachments attachments={attachments} />
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter
            position="sticky"
            bottom="0"
            background="default.white.100"
            boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
          >
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="solid" type="button" onClick={onEditAgreementOpen}>
              Edit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {isEditAgreementOpen && (
        <LeadsEditAgreementModal
          isOpen={isEditAgreementOpen}
          onClose={onEditAgreementClose}
        />
      )}
    </>
  );
};
