import {
  Box,
  Button as ChakraButton,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ModalFooter,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import Button from 'Library/Button';
import * as Yup from 'yup';
import Select from 'react-select';

import AtsDropZone from 'components/app/Dropzone';
import ScrollToFieldError from 'components/app/ScrollError';
import { Field, Formik, useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  listTemplate,
  setPairTemplate,
  useRawListTemplateMutation,
} from 'store/template.slice';
import AtsCraetableSelect from 'components/app/AtsCreatabeSelect';
import {
  clientSelectType,
  ConfirmationPreviewInt,
  fileUploadedInt,
} from 'types';
import { BsEyeFill } from 'react-icons/bs';
import PreviewConfirmation from './previews/Confirmation';
import NotesModal from '../../../Modals/Notes';
import AtsSelectContact from 'components/app/AtsSelectContact';
import moment from 'moment';
import replacePlaceholders from 'utils/textPlaceholders';
import AtsEmailBody from 'components/app/Global/Email/AIBody';
import { useAIGeneratorServices } from 'components/app/AIGenerator/useAIGeneratorServices';
import TemplateAddModal from 'components/app/Global/TemplateModal';
import FAIcon from 'components/lib/FAIcon';
import { uploadList } from 'store/uploads.slice';

interface initialvaluesInt {
  to: string;
  bcc: any;
  cc: any;
  subject: string;
  body: string;
  auto_interview: boolean;
  template: any;
  attachment: any;
}

interface ConfirmationInterview {
  initialvalues: initialvaluesInt;
  onSubmit: (e: any) => void;
  onPrev: (data: any) => void;
  // placeholders: (body: any) => string | null;
  panelOptions: any;
  loading: boolean;
  userOptions: any;
  jobData: any;
  candidatePairing: (e: any) => void;
  submitDetails: any;
  ccOptions: any[];
  bccOptions: any[];
  contactOptionsEmail: any[];
  setCcOptions: any;
  setBccOptions: any;
  onClose: any;
}

const InterviewConfirmation = ({
  initialvalues,
  onSubmit,
  onPrev,
  // placeholders,
  panelOptions,
  loading,
  userOptions,
  jobData,
  candidatePairing,
  submitDetails,
  ccOptions,
  bccOptions,
  contactOptionsEmail,
  setCcOptions,
  setBccOptions,
  onClose,
}: ConfirmationInterview) => {
  const dispatch = useDispatch();

  const { placehoderPair, templateListConfirmation } = useSelector(
    (state: any) => state.template
  );
  const {
    attachments: attachment,
    attachmentConfirmation,
    uploaded,
    uploading,
  } = useSelector((state: any) => state.uploads);
  const { userList } = useSelector((state: any) => state.user);

  const [confirmInitial, setInitialValue] = useState(initialvalues);

  const [attachments, setAttachments] = useState(attachmentConfirmation);
  const [template, setTemplate] = useState([]);
  const [forceRender, setForceRender] = useState(0); // Force re-render
  const [panelOption, setPanelOptions] = useState<any>([]);
  const [company, setCompany] = useState<clientSelectType | object>({});

  useEffect(() => {
    setInitialValue(initialvalues);
  }, [initialvalues]);

  const [bccValue, setBccValue] = useState([]);
  const [ccValue, setCcValue] = useState([]);

  const populateUSers = () => {
    let emails = [] as any;

    Object.values(userList).map((item: any) => {
      emails.push({
        label: [item.first_name, item.last_name].filter(Boolean).join(' '),
        value: item.email,
      });
    });

    return emails;
  };

  const onCcInputChange = (e: any) => {
    setCcValue(e);
    if (e.length > 2) {
      const options = [...contactOptionsEmail, ...populateUSers()];
      setCcOptions(options);
    } else {
      setCcOptions(contactOptionsEmail);
    }
  };

  const onBccInputChange = (e: any) => {
    setBccValue(e);
    if (e.length > 2) {
      const options = [...contactOptionsEmail, ...populateUSers()];
      setBccOptions(options);
    } else {
      setBccOptions(contactOptionsEmail);
    }
  };

  useEffect(() => {
    setAttachments(attachmentConfirmation || attachment);
  }, [attachment, attachmentConfirmation]);

  useEffect(() => {
    dispatch(
      uploadList({
        attachments: attachmentConfirmation,
      })
    );
  }, [attachmentConfirmation]);

  useEffect(() => {
    if (uploaded && uploaded.length > 0) {
      dispatch(
        uploadList({
          attachmentConfirmation: uploaded,
        })
      );
    }
  }, [uploaded]);

  useEffect(() => {
    setPanelOptions(panelOptions);
  }, [panelOptions]);

  useEffect(() => {
    const popluateTemplates = async () => {
      let option: any = [];
      if (templateListConfirmation.length > 0) {
        await Promise.all(
          templateListConfirmation.map((item: any) => {
            let pushed = false;
            if (submitDetails.mode_id === item.mode_id) {
              pushed = true;
            }

            // for On-site type
            if (submitDetails.type_id === 3 && item.type_id === 3) {
              pushed = true;
            }

            if (pushed) {
              option.push({
                value: item.id,
                label: item.title,
                subject: item.subject,
                body: item.body,
                attachments: item.attachments,
              });
            }
          })
        );
      }
      if (option.length >= 1) {
        setInitialValue((prevState) => ({
          ...prevState,
          subject: confirmInitial.subject || option[0]?.subject,
          body: confirmInitial.body || option[0]?.body,
          attachment: confirmInitial.attachment || option[0]?.attachments,
          template: option[0],
        }));

        setForceRender((prev) => prev + 1); // Force re-render
      }
      setTemplate([
        ...option,
        {
          value: null,
          label: 'Create New Template',
          subject: '',
          body: '',
          attachments: null,
        },
      ]);
      dispatch(
        listTemplate({
          aiTemplate: [
            ...option,
            {
              value: null,
              label: 'Create New Template',
              subject: '',
              body: '',
              attachments: null,
            },
          ],
        })
      );
    };
    popluateTemplates();
  }, [templateListConfirmation]);

  useEffect(() => {
    if (jobData) {
      // console.log("jobData?.client_id", jobData?.client_id)
      setCompany({
        label: jobData ? jobData?.client?.name : '',
        value: jobData ? jobData?.client_id : '',
      });
    }
  }, [jobData]);

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      to: Yup.string().when('auto_interview', {
        is: false,
        then: Yup.string(),
        otherwise: Yup.string().required('To is required'),
      }),
      bcc: Yup.array().of(
        Yup.object().shape({
          value: Yup.mixed().test(
            'is-string-or-number',
            'Must be a string or a number',
            (value) => {
              return typeof value === 'string' || typeof value === 'number';
            }
          ),
          label: Yup.string().required('Label is required'),
        })
      ),
      cc: Yup.array().of(
        Yup.object().shape({
          value: Yup.mixed().test(
            'is-string-or-number',
            'Must be a string or a number',
            (value) => {
              return typeof value === 'string' || typeof value === 'number';
            }
          ),
          label: Yup.string().required('Label is required'),
        })
      ),
      body: Yup.string().when('auto_interview', {
        is: false,
        then: Yup.string(),
        otherwise: Yup.string().required('Body is required'),
      }),
      subject: Yup.string().when('auto_interview', {
        is: false,
        then: Yup.string(),
        otherwise: Yup.string().required('Subject is required'),
      }),
      auto_interview: Yup.boolean(),
    })
  );

  const ConfirmSubmit = (data: any) => {
    const param = {
      ...data,
      attachment: attachment || attachmentConfirmation,
      cc: ccValue,
      bcc: bccValue,
    };

    onSubmit(param);
    if (attachment && attachment.length > 0) {
      dispatch(
        uploadList({
          attachmentConfirmation: attachment,
        })
      );
    }

    dispatch(
      uploadList({
        uploaded: [],
        attachments: [],
      })
    );
  };

  const ClickBack = (values: any) => {
    const data = {
      ...values,
      cc: ccValue,
      bcc: bccValue,
    };
    onPrev(data);
    // console.log('values', values);
  };

  const {
    isOpen: isOpenNotes,
    onClose: onCloseNotes,
    onOpen: onOpenNotes,
  } = useDisclosure();

  const {
    hasAIGenerate,
    setHasAIGenerate,
    setIsGenerate,
    handleValues,
    handleAIGenerator,
    setIsError,
    isMaximize,
  } = useAIGeneratorServices({ hasType: 'Candidate' });

  useEffect(() => {
    const pair = {
      ...placehoderPair,
      panel_members_details:
        placehoderPair?.['panel_members_details']?.replace(
          /<div>{{panel_members_details}}<\/div>/g,
          `<ul>${placehoderPair['panel_members_details']}</ul>`
        ) ?? '',
    };

    dispatch(setPairTemplate({ placehoderPair: pair }));
  }, [submitDetails]);

  return (
    <Formik
      initialValues={confirmInitial}
      validationSchema={validationSchema}
      onSubmit={ConfirmSubmit}
      enableReinitialize={true}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
      }) => (
        <form onSubmit={handleSubmit} style={{ height: '100%' }}>
          <ScrollToFieldError />

          <Flex
            h="100%"
            justifyContent="center"
            gap="8px"
            sx={{
              px: '8px',

              '& #ai': {
                overflowY: 'scroll',
                height: '80vh',
              },
              '& ::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            <Stack
              spacing="8px"
              sx={{
                display: hasAIGenerate && isMaximize ? 'none' : 'flex',
                position: 'relative',
                borderRadius: '6px',
                w: hasAIGenerate !== '' ? '65%' : '100%',
                m: 0,
                mt: hasAIGenerate && '-2px',
                mr: hasAIGenerate && 0,
              }}
            >
              <Stack
                spacing="8px"
                sx={{
                  bgColor: '#FFF',
                  borderRadius: '6px',
                  p: '16px',
                }}
              >
                <Checkbox
                  colorScheme="purple"
                  defaultChecked={values.auto_interview}
                  onChange={handleChange}
                  name="auto_interview"
                >
                  Send Auto-Interview Confirmation to the candidate
                </Checkbox>

                <Flex gap="16px">
                  <FormControl isInvalid={Boolean(!!errors.to && touched.to)}>
                    <FormLabel fontSize="14px" lineHeight="18px">
                      To{' '}
                      <Box as="span" color="caution.800">
                        *
                      </Box>
                    </FormLabel>
                    <Input
                      id="to"
                      name="to"
                      type="text"
                      placeholder="To"
                      variant="outline"
                      value={values.to}
                      onChange={handleChange}
                      disabled={!values.auto_interview}
                      sx={{
                        height: '38px',
                        border: '1px solid #E7EDF4',
                        p: '8px 12px',
                        fontSize: '14px',
                      }}
                    />
                    <FormErrorMessage>{String(errors.to)}</FormErrorMessage>
                  </FormControl>

                  <FormControl>
                    <FormLabel fontSize="14px" lineHeight="18px">
                      Cc
                    </FormLabel>

                    <Field
                      id="cc"
                      name="cc"
                      component={AtsSelectContact}
                      value={values.cc}
                      client={company}
                      isDisabled={!values.auto_interview}
                      options={ccOptions}
                      isMulti={true}
                      placeholder="cc"
                      defaultValue={values.cc}
                      onInputChange={onCcInputChange}
                      isSaveInput={true}
                      styles={{
                        control: (styles: any) => ({
                          ...styles,
                          fontSize: '14px',
                          border: '1px solid #E7E9ED',
                          borderRadius: '4px',
                          cursor: 'pointer',
                          boxShadow: 'none',
                          '&:hover': {},
                        }),
                        valueContainer: (styles: any) => ({
                          ...styles,
                          maxHeight: '33px',
                          overflowY: 'scroll',
                        }),
                        dropdownIndicator: (styles: any) => ({
                          ...styles,
                          color: '#676767',
                        }),
                        indicatorSeparator: () => ({
                          display: 'none',
                        }),
                        placeholder: (styles: any) => ({
                          ...styles,
                          fontSize: '14px',
                          color: '#9B9B9B',
                        }),
                        multiValue: (styles: any) => ({
                          ...styles,
                          background: '#F7FAFC',
                          border: '1px solid #E2E8F0',
                          borderRadius: '6px',
                          '&& div:first-of-type': {
                            color: '#2B2D42',
                          },
                        }),
                      }}
                    />
                    <FormErrorMessage>{String(errors.cc)}</FormErrorMessage>
                  </FormControl>

                  <FormControl>
                    <FormLabel fontSize="14px" lineHeight="18px">
                      Bcc
                    </FormLabel>

                    <Field
                      id="bcc"
                      name="bcc"
                      component={AtsSelectContact}
                      value={values.bcc}
                      client={company}
                      isDisabled={!values.auto_interview}
                      options={bccOptions}
                      isMulti={true}
                      placeholder="bcc"
                      defaultValue={values.bcc}
                      onInputChange={onBccInputChange}
                      onChange={(event: any) => setBccValue(event)}
                      isSaveInput={true}
                      styles={{
                        control: (styles: any) => ({
                          ...styles,
                          fontSize: '14px',
                          border: '1px solid #E7E9ED',
                          borderRadius: '4px',
                          cursor: 'pointer',
                          boxShadow: 'none',
                          '&:hover': {},
                        }),
                        valueContainer: (styles: any) => ({
                          ...styles,
                          maxHeight: '33px',
                          overflowY: 'scroll',
                        }),
                        dropdownIndicator: (styles: any) => ({
                          ...styles,
                          color: '#676767',
                        }),
                        indicatorSeparator: () => ({
                          display: 'none',
                        }),
                        placeholder: (styles: any) => ({
                          ...styles,
                          fontSize: '14px',
                          color: '#9B9B9B',
                        }),
                        multiValue: (styles: any) => ({
                          ...styles,
                          background: '#F7FAFC',
                          border: '1px solid #E2E8F0',
                          borderRadius: '6px',
                          '&& div:first-of-type': {
                            color: '#2B2D42',
                          },
                        }),
                      }}
                    />
                    <FormErrorMessage>{String(errors.bcc)}</FormErrorMessage>
                  </FormControl>
                </Flex>

                <FormControl
                  isInvalid={Boolean(!!errors.subject && touched.subject)}
                >
                  <Flex justifyContent="space-between" alignItems="center">
                    <FormLabel fontSize="14px" lineHeight="18px">
                      Subject{' '}
                      <Box as="span" color="caution.800">
                        *
                      </Box>
                    </FormLabel>
                  </Flex>
                  <Input
                    name="subject"
                    type="text"
                    placeholder="Subject"
                    variant="outline"
                    value={replacePlaceholders(values.subject, placehoderPair)}
                    onChange={() => {}}
                    disabled
                    sx={{
                      height: '38px',
                      border: '1px solid',
                      borderColor: '#E7E9ED',
                      p: '8px 12px',
                      fontSize: '14px',
                    }}
                  />
                  <FormErrorMessage>{String(errors.subject)}</FormErrorMessage>
                </FormControl>
              </Stack>

              <Stack
                h="100%"
                sx={{
                  position: 'relative',
                  bgColor: '#FFF',
                  borderRadius: '6px',
                  p: '16px',
                  '& ::-webkit-scrollbar': {
                    display: 'none',
                  },
                  overflow: 'hidden',
                }}
              >
                <AtsEmailBody
                  value={values.body}
                  onChange={(e: any) => {
                    setFieldValue('body', e);
                  }}
                  height={200}
                  hasAIGenerate={hasAIGenerate}
                  setHasAIGenerate={setHasAIGenerate}
                  setIsGenerate={setIsGenerate}
                  handleValues={handleValues}
                  setIsError={setIsError}
                  subject={values.subject}
                  body={values.body}
                  attachments={attachments}
                  setAttachments={setAttachments}
                />
                <FormErrorMessage>{String(errors.body)}</FormErrorMessage>
              </Stack>
            </Stack>

            {handleAIGenerator(setFieldValue)}
          </Flex>

          <ModalFooter
            position="sticky"
            bottom="0"
            background="default.white.100"
            boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
            gap={5}
            p="10px"
            zIndex={2}
          >
            <Flex width="100%" justifyContent="space-between">
              <Flex gap="10px">
                <Button
                  variant="ghost"
                  type="button"
                  onClick={() => ClickBack(values)}
                  disabled={loading}
                  sx={{
                    borderRadius: '8px',
                    color: '#2B2D42',
                    boxShadow: '0px 2px 8px 0px #0000000A',
                  }}
                >
                  Back
                </Button>
                <ChakraButton
                  variant="outline"
                  onClick={onOpenNotes}
                  left="0px"
                  color="primary.800"
                >
                  Notes
                </ChakraButton>
              </Flex>
              <Flex gap="10px">
                <ChakraButton
                  variant="borderless"
                  onClick={() => {
                    onClose();
                    dispatch(
                      uploadList({
                        uploaded: [],
                        attachments: [],
                        attachmentInvite: [],
                        attachmentConfirmation: [],
                      })
                    );
                    dispatch(listTemplate({ aiTemplate: [] }));
                  }}
                >
                  Cancel
                </ChakraButton>

                <Menu closeOnSelect={true}>
                  <Flex>
                    <Box
                      sx={{
                        button: {
                          p: '8px 4px',
                          pl: '8px',
                          height: '40px',
                          fontSize: '12px',
                          borderRight: 'none',
                          borderRadius: '6px 0 0 6px',
                        },
                      }}
                    >
                      <ChakraButton type="button" onClick={() => {}}>
                        Save As
                      </ChakraButton>
                    </Box>
                    <MenuButton
                      as={ChakraButton}
                      type="button"
                      sx={{
                        p: '8px',
                        height: '40px',
                        fontSize: '12px',
                        borderLeft: 'none',
                        borderRadius: '0 6px 6px 0',
                      }}
                    >
                      |{' '}
                      <Box as="span" ml="10px">
                        <FAIcon iconName="chevron-down" />
                      </Box>
                    </MenuButton>
                  </Flex>
                  {
                    <MenuList fontSize="sm" maxHeight="120px" overflowY="auto">
                      <MenuItem onClick={() => {}}>Save as a template</MenuItem>
                    </MenuList>
                  }
                </Menu>
                <ChakraButton
                  variant="solid"
                  type="submit"
                  isLoading={loading}
                  isDisabled={uploading || loading}
                  onClick={() => ConfirmSubmit(values)} //for some reason the submit is not triggering, calling the function here for now
                >
                  Submit
                </ChakraButton>
              </Flex>
            </Flex>
          </ModalFooter>

          {isOpenNotes && (
            <NotesModal isOpen={isOpenNotes} onClose={onCloseNotes} />
          )}
        </form>
      )}
    </Formik>
  );
};

export default InterviewConfirmation;
