import {
  Badge,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Stack,
  Text,
} from '@chakra-ui/react';
import Button from 'Library/Button';
import moment from 'moment';
import parse from 'html-react-parser';
import { SMSAutomationModalProps } from '../types';
import { useSMSAutomationTableServices } from '../Table/useSMSAutomationTableServices';
import { useNavigate } from 'react-router';

export const SMSAutomationDrawer = ({
  isOpen,
  onClose,
}: SMSAutomationModalProps) => {
  const navigate = useNavigate();

  const {
    rowId,
    smsAutomationData,
    totalSMSAutomationList,
    handlePrevData,
    handleNextData,
  } = useSMSAutomationTableServices();

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => {
        onClose();
        navigate('/engage/sms/recruitment/sms-automation');
      }}
      size="xl"
    >
      <DrawerOverlay />
      <DrawerContent fontFamily="NunitoSans Regular">
        <DrawerCloseButton
          position="absolute"
          top="0px"
          left="-40px"
          borderRadius="unset"
          p="20px"
          bg="#E6E9EE"
          color="default.secondarytext"
          _hover={{
            backgroundColor: 'none',
          }}
        />
        <Flex
          justifyContent="space-between"
          alignItems="center"
          pl={8}
          pr={0}
          borderBottom="1px solid #E6E9EE"
          fontSize="sm"
          fontWeight="normal"
          color="default.secondarytext"
        >
          <Box sx={{ fontSize: '14px', color: '#6B6C7B' }}>
            Showing <b>{rowId}</b> of {totalSMSAutomationList} entries
          </Box>
          <Flex>
            <Box borderLeft="1px solid #6B6C7B" borderRadius="unset">
              <Button
                iconName="chevron-left"
                variant="ghost"
                onClick={() => rowId !== 1 && handlePrevData()}
                disabled={rowId === 1}
              />
            </Box>

            <Box borderLeft="1px solid #6B6C7B" borderRadius="unset">
              <Button
                iconName="chevron-right"
                variant="ghost"
                onClick={() =>
                  rowId !== totalSMSAutomationList && handleNextData()
                }
                disabled={rowId === totalSMSAutomationList}
              />
            </Box>
          </Flex>
        </Flex>

        <DrawerBody p="24px 36px">
          <Stack>
            <Stack spacing="16px" mb="32px">
              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Automation Name
                </Text>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Text
                    sx={{
                      fontSize: '16px',
                      fontWeight: 700,
                      color: '#2B2D42',
                    }}
                  >
                    {smsAutomationData?.name}
                  </Text>
                </Stack>
              </Stack>

              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Trigger
                </Text>
                <Text
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#2B2D42',
                  }}
                >
                  {smsAutomationData?.trigger}
                </Text>
              </Stack>

              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Source List
                </Text>

                <Stack direction="row" spacing="8px">
                  {smsAutomationData?.sources?.map((val: any) => {
                    return (
                      <Badge
                        sx={{
                          p: '4px 8px',
                          borderRadius: '2px',
                          fontSize: '14px',
                          textTransform: 'capitalize',
                        }}
                      >
                        {val?.candidate_source?.source}
                      </Badge>
                    );
                  })}
                </Stack>
              </Stack>

              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Time Schedule
                </Text>
                <Text
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#2B2D42',
                  }}
                >
                  {moment(
                    smsAutomationData?.schedule_details?.start_time
                  ).format('MMMM DD, YYYY')}
                </Text>
              </Stack>
            </Stack>

            <Stack spacing="16px">
              <Text sx={{ fontSize: '16px', color: '#2B2D42' }}>
                SMS Message
              </Text>
              <Box
                sx={{
                  py: '8px',
                  fontWeight: 300,
                }}
              >
                {parse(smsAutomationData?.message || 'No sms message available')}
              </Box>
            </Stack>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
