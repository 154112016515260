import * as Yup from 'yup';
import moment from 'moment';

export const timezoneOptions = [
  'US/Pacific',
  'US/Central',
  'US/Eastern',
  'US/Alaska',
  'US/Mountain',
  'US/Hawaii',
].map((val) => ({ label: val, value: val }));

// Generate time options using Moment.js
export const timeOptions: any[] = [];
for (let i = 0; i < 24; i++) {
  for (let j = 0; j < 60; j += 30) {
    const time = moment({ hour: i, minute: j }).format('hh:mm A');
    timeOptions.push({
      label: time,
      value: moment(time, 'hh:mm A').format('HH:mm'),
    });
  }
}

export const DEFAULT_SCHEDULE_SCHEMA = Yup.lazy(() =>
  Yup.object().shape({
    timezone: Yup.string(),
    monday_start: Yup.string(),
    monday_end: Yup.string(),
    tuesday_start: Yup.string(),
    tuesday_end: Yup.string(),
    wednesday_start: Yup.string(),
    wednesday_end: Yup.string(),
    thursday_start: Yup.string(),
    thursday_end: Yup.string(),
    friday_start: Yup.string(),
    friday_end: Yup.string(),
    saturday_start: Yup.string(),
    saturday_end: Yup.string(),
    sunday_start: Yup.string(),
    sunday_end: Yup.string(),
  })
);
