/* eslint-disable react-hooks/exhaustive-deps */
import {
  Tr,
  Td,
  Table,
  Tbody,
  Th,
  TableContainer,
  Box,
  Thead,
  Checkbox,
  Flex,
  Tag,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
} from '@chakra-ui/react';
import FAIcon from 'components/lib/FAIcon';
import Button from 'Library/Button';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { updateOutreach } from 'store/outreach.slice';
import { SuppressionDrawer } from '../Drawer';
import SuppressionModal from '../Modal';
import { useSuppressionTableServices } from './useSuppressionTableServices';

const SuspressionListTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const {
    suppressionList,
    isCheck,
    isIndeterminate,
    isCheckAll,
    handleCheck,
    handleSelectAll,
    handleBulkDeleteSuppression,
  } = useSuppressionTableServices();

  const {
    isOpen: isOpenEditSuppressionModal,
    onOpen: onOpenEditSuppressionModal,
    onClose: onCloseEditSuppressionModal,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenSuppressionDrawer,
    onOpen: onOpenSuppressionDrawer,
    onClose: onCloseSuppressionDrawer,
  } = useDisclosure({ defaultIsOpen: false });

  useEffect(() => {
    if (params.settingType === 'suppression-list' && params.settingTypeId) {
      const mappedSuppressions = suppressionList
        .map((template: any, index: any) => {
          return {
            ...template,
            rowId: index + 1,
          };
        })
        .filter((x: any) => x.id === Number(params.settingTypeId))[0];

      dispatch(
        updateOutreach({
          suppressionData: mappedSuppressions,
        })
      );

      onOpenSuppressionDrawer();
    }
  }, [params.settingType, params.settingTypeId]);

  return (
    <Box px={1} justifyContent="space-between">
      {isCheck.length > 0 && (
        <Flex pb={6} gap={4}>
          <Tag
            display="flex"
            borderRadius="4px"
            variant="subtle"
            color="#545454"
            bg="#FAF7FF"
            fontWeight="regular"
            fontSize="md"
            border="1px solid #AE8FE2"
            height="32px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex gap={2} fontSize="14px" alignItems="center">
              <Box color="#6930CA" fontSize="sm">
                {isCheck.length}
              </Box>{' '}
              {isCheck.length > 1 ? 'items' : 'item'} selected
            </Flex>
          </Tag>
          <Button
            variant="basic"
            leftIcon="trash"
            size="sm"
            onClick={handleBulkDeleteSuppression}
          >
            Delete Selected
          </Button>
        </Flex>
      )}
      <TableContainer
        boxSizing="border-box"
        border="1px solid"
        borderColor="default.white.400"
        borderRadius="md"
        height="65vh"
        sx={{ overflowY: 'scroll' }}
      >
        <Table>
          <Thead>
            <Tr bg="default.white.600">
              <Th width="50px">
                <Checkbox
                  onChange={handleSelectAll}
                  isIndeterminate={isIndeterminate}
                  checked={isCheckAll}
                  defaultChecked={false}
                  colorScheme="purple"
                />
              </Th>
              <Th
                sx={{
                  width: '50px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Company Name
              </Th>
              <Th
                sx={{
                  width: '50px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Suppression Type
              </Th>
              <Th
                sx={{
                  width: '50px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Domain
              </Th>
              <Th
                sx={{
                  width: '50px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Suppression Duration
              </Th>
              <Th
                sx={{
                  width: '50px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Date Created
              </Th>
              <Th
                sx={{
                  width: '50px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              ></Th>
            </Tr>
          </Thead>
          <Tbody bgColor="#fff" fontSize="16px" color="#6B6C7B">
            {suppressionList.map((item: any, index: number) => {
              return (
                <Tr cursor="pointer">
                  <Td>
                    <Checkbox
                      onChange={handleCheck}
                      isChecked={isCheck.includes(item.id)}
                      id={item?.id?.toString()}
                      key={item.id}
                      colorScheme="purple"
                    />
                  </Td>

                  <Td
                    onClick={() => {
                      onOpenSuppressionDrawer();
                      navigate(`${location.pathname}/${item.id}`);
                    }}
                  >
                    {item.company}
                  </Td>
                  <Td
                    onClick={() => {
                      onOpenSuppressionDrawer();
                      navigate(`${location.pathname}/${item.id}`);
                    }}
                  >
                    {item.type}
                  </Td>
                  <Td
                    onClick={() => {
                      onOpenSuppressionDrawer();
                      navigate(`${location.pathname}/${item.id}`);
                    }}
                  >
                    {item.domain}
                  </Td>
                  <Td
                    onClick={() => {
                      onOpenSuppressionDrawer();
                      navigate(`${location.pathname}/${item.id}`);
                    }}
                  >
                    {item.duration}
                  </Td>
                  <Td
                    onClick={() => {
                      onOpenSuppressionDrawer();
                      navigate(`${location.pathname}/${item.id}`);
                    }}
                  >
                    {item.date_created}
                  </Td>
                  <Td>
                    <Menu closeOnSelect={false}>
                      <MenuButton>
                        <Button
                          htmlType="menu"
                          variant="ghost"
                          iconName="ellipsis-h"
                          iconSize="xl"
                        />
                      </MenuButton>
                      <MenuList
                        fontSize="12px"
                        lineHeight="16px"
                        fontWeight="600"
                      >
                        <MenuItem
                          icon={<FAIcon iconName="pencil" />}
                          onClick={() => {
                            dispatch(
                              updateOutreach({
                                suppressionData: {
                                  ...item,
                                  rowId: index + 1,
                                },
                              })
                            );
                            onOpenEditSuppressionModal();
                          }}
                        >
                          Edit
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        {isOpenEditSuppressionModal && (
          <SuppressionModal
            isEdit
            isOpen={isOpenEditSuppressionModal}
            onClose={onCloseEditSuppressionModal}
          />
        )}

        {isOpenSuppressionDrawer && (
          <SuppressionDrawer
            isOpen={isOpenSuppressionDrawer}
            onOpen={onOpenEditSuppressionModal}
            onClose={onCloseSuppressionDrawer}
          />
        )}
      </TableContainer>
    </Box>
  );
};
export default SuspressionListTable;
