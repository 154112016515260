import { FaBriefcase, FaUser } from 'react-icons/fa6';
import { contactInt, leadInt } from './details.interface';

const contactsPopulate = async (leads: leadInt[]) => {
  let contacts: contactInt[] = [];
  await Promise.all(
    leads?.map((item: leadInt) => {
      contacts.push({
        label: [item.first_name, item.last_name].filter(Boolean).join(' '),
        value: item.id,
        email: item.primary_email || '',
        phone: [
          {
            value: item.personal_phone,
            label: item.personal_phone,
            type: 'personal',
            primary: false,
            icon: FaUser,
          },
          {
            value: item.work_phone,
            label: item.work_phone,
            type: 'work',
            primary: false,
            icon: FaBriefcase,
          },
        ],
      });
    })
  );

  return contacts;
};

export default contactsPopulate;
