import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';

export function useTabListServices() {
  const navigate = useNavigate();
  const params = useParams();

  const [activeTab, setActiveTab] = useState(params.settingSubType);

  const stateTab: any = params.settingSubType;

  const handleRoute = (route: string) => {
    navigate(
      `/engage/sequences/recruitment/${params.settingType}/${params.settingTypeId}/${route}/${params.settingSubId}`
    );
    setActiveTab(route);
  };

  return { stateTab, handleRoute, activeTab };
}
