import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Image,
  useDisclosure,
  Link,
} from '@chakra-ui/react';
import FAIcon from 'components/lib/FAIcon';
import { BsChatDotsFill } from 'react-icons/bs';
import { MdCall } from 'react-icons/md';

interface props {
  index: any;
  contact: any;
  candidateData: any;
  Verified: any;
}

const InformationContact = ({
  index,
  contact,
  candidateData,
  Verified,
}: props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Flex
      gap="10px"
      alignItems="center"
      key={`info-${index}`}
      // onMouseEnter={onOpen}
      // onMouseLeave={onClose}
    >
      <Box color="primary.800">
        <FAIcon
          iconName={
            contact?.category.toLowerCase() === 'work' ? 'briefcase' : 'user'
          }
        />
      </Box>
      <Box sx={{ fontWeight: 400 }}>
        {index + 1 !== candidateData?.contact.length ? (
          <Link href={`tel:${contact?.number}`}>{contact?.number}</Link>
        ) : (
          <Link href={`tel:${contact?.number}`}>{contact?.number}</Link>
        )}
      </Box>
      <Flex gap="5px" alignItems="center">
        {contact?.valid && <Image src={Verified} alt="logo" height="14px" />}
        {contact?.primary && (
          <Box
            sx={{
              button: {
                p: '4px 8px',
                borderRadius: '6px',
                height: '20px',
              },
            }}
          >
            <Button variant="solid">Primary</Button>
          </Box>
        )}
      </Flex>
      {isOpen && (
        <Box>
          <ButtonGroup isAttached size="xxs" colorScheme={'purple.500'}>
            <Link href={`tel:${contact?.number}`}>
              <IconButton
                aria-label="call"
                size="xs"
                icon={<MdCall />}
                border="1px"
                sx={{
                  borderTopRightRadius: '0px !important',
                  borderBottomRightRadius: '0px !important',
                }}
                _hover={{ bg: 'purple.500', color: 'white' }}
              />
            </Link>
            <Link href={`sms:${contact?.number}`}>
              <IconButton
                aria-label="text"
                size="xs"
                icon={<BsChatDotsFill />}
                border="1px"
                borderLeft="0px"
                sx={{
                  borderTopLeftRadius: '0px !important',
                  borderBottomLeftRadius: '0px !important',
                }}
                _hover={{ bg: 'purple.500', color: 'white' }}
              />
            </Link>
          </ButtonGroup>
        </Box>
      )}
    </Flex>
  );
};

export default InformationContact;
