import { useSelector } from 'react-redux';
import {
  Box,
  Menu,
  MenuButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import Button from 'Library/Button';

const ApprovalsJobs = () => {
  const { approvalData } = useSelector((state: any) => state.approvals);

  const tableHeader: { label: string }[] = [
    { label: 'Lead Details' },
    { label: 'Submits' },
    { label: 'Interview' },
    { label: 'Offered' },
    { label: 'Placed' },
    { label: '' },
  ];

  return (
    <Box py={6}>
      <Box>
        <TableContainer
          boxSizing="border-box"
          border="1px solid"
          borderColor="default.white.400"
          borderRadius="md"
        >
          <Table>
            <Thead>
              <Tr bg="default.white.800">
                {tableHeader.map((title) => (
                  <Th key={title.label}>
                    <Box color="default.gray.600">{title.label}</Box>
                  </Th>
                ))}
              </Tr>
            </Thead>

            <Tbody
              boxSizing="border-box"
              background="default.white.100"
              borderBottom="1px solid"
              borderColor="default.white.400"
            >
              {approvalData?.jobs?.length > 0 ? (
                approvalData.jobs.map((job: any) => {
                  return (
                    <Tr key={job.id} cursor="pointer" onClick={() => {}}>
                      <Td>{job.title}</Td>
                      <Td>{job.submits}</Td>
                      <Td>{job.interview}</Td>
                      <Td>{job.offered}</Td>
                      <Td>{job.placed}</Td>
                      <Td>
                        <Menu>
                          <MenuButton>
                            <Button
                              htmlType="menu"
                              variant="ghost"
                              iconName="ellipsis-h"
                              iconSize="xl"
                            />
                          </MenuButton>
                        </Menu>
                      </Td>
                    </Tr>
                  );
                })
              ) : (
                <>
                  <Tr key={0}>
                    <Td colSpan={6} textAlign={'center'}>
                      No data found
                    </Td>
                  </Tr>
                </>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ApprovalsJobs;
