import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from './api.slice';
import { appError } from './app.slice';

export const templateAttachmentSlice = createSlice({
  name: 'templateAttachment',
  initialState: {
    attachments: [],
  },
  reducers: {
    getAttachmentTemplate: (state, { payload }) => ({ ...state, ...payload }),
    removeAttachmentTemplate: (state, { payload }) => {
      state.attachments.splice(payload, 1);
    },
  },
});

export const { getAttachmentTemplate, removeAttachmentTemplate } =
  templateAttachmentSlice.actions;
export default templateAttachmentSlice.reducer;

/* ----------------------------------- API ---------------------------------- */

export const candidateAttachmentApiSplice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    DownloadTemplateAttachment: builder.mutation({
      query: ({ data, id }) => ({
        url: `/email-template/attachments/download/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getAttachmentTemplate({ candidates: data.data.data }));
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),

    CreateTemplateAttachment: builder.mutation({
      query: ({ data, id }) => ({
        url: `/email-template/attachments/create/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),

    DelTemplateAttachment: builder.mutation({
      query: ({ id }) => ({
        url: `/email-template/attachments/delete/${id}`,
        method: 'DELETE',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: [],
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),
    ListTemplateAttachment: builder.mutation({
      query: ({ data, id }) => ({
        url: `/email-template/attachments/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getAttachmentTemplate({ attachments: data.data }));
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  useCreateTemplateAttachmentMutation,
  useDelTemplateAttachmentMutation,
  useDownloadTemplateAttachmentMutation,
  useListTemplateAttachmentMutation,
} = candidateAttachmentApiSplice;
