import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Checkbox,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from '@chakra-ui/react';
import { ActivityLogData } from 'types';
import { tableHeader } from './constants';

import Button from 'Library/Button';
import { useActivityLogServices } from './useActivityLogServices';

export const LeadsActivityLog = () => {
  const { hasFilteredDay, setHasFilteredDay, leadData } =
    useActivityLogServices();
  return (
    <Box>
      <Flex gap="16px" py={6}>
        <Menu closeOnSelect={false}>
          <MenuButton>
            <Button rightIcon="chevron-down" variant="skeleton">
              {hasFilteredDay}
            </Button>
          </MenuButton>
          <MenuList py={4}>
            <MenuItem onClick={() => setHasFilteredDay('Today')}>
              Today
            </MenuItem>
            <MenuItem onClick={() => setHasFilteredDay('Last 7 Days')}>
              Last 7 Days
            </MenuItem>
            <MenuItem onClick={() => setHasFilteredDay('Last 15 Days')}>
              Last 15 Days
            </MenuItem>
            <MenuItem onClick={() => setHasFilteredDay('Last 30 Days')}>
              Last 30 Days
            </MenuItem>
            <MenuItem onClick={() => setHasFilteredDay('Custom Date Range')}>
              Custom Date Range
            </MenuItem>
          </MenuList>
        </Menu>
        <Menu closeOnSelect={false}>
          <MenuButton>
            <Button rightIcon="chevron-down" variant="skeleton">
              Filters
            </Button>
          </MenuButton>
          <MenuList minWidth="500px" py={4}>
            <Flex
              fontSize="16px"
              lineHeight="20px"
              fontWeight="600"
              color="#2B2D42"
              justifyContent="space-between"
              alignItems="center"
              px={6}
            >
              Filters
              <Flex justifyContent="space-between" width="35%">
                <Button variant="ghost">Clear All</Button>
                <Button variant="solid">Apply</Button>
              </Flex>
            </Flex>

            <Accordion allowMultiple>
              <AccordionItem
                mt="24px"
                fontSize="14px"
                lineHeight="18px"
                fontWeight="500"
              >
                {({ isExpanded }) => (
                  <>
                    <h2>
                      <AccordionButton _expanded={{ bg: 'primary.100' }}>
                        <Box
                          flex="1"
                          textAlign="left"
                          fontSize="14px"
                          lineHeight="18px"
                          fontWeight="500"
                          color="#2B2D42"
                          px={2}
                        >
                          Lead Type
                        </Box>
                        <AccordionIcon
                          color={isExpanded ? 'purple.800' : 'gray.300'}
                        />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel py={4}>
                      <Flex px={2} justifyContent="flex-start">
                        <Checkbox
                          colorScheme="purple"
                          color="default.secondarytext"
                          value="hot"
                        >
                          Hot
                        </Checkbox>
                        <Checkbox
                          colorScheme="purple"
                          color="default.secondarytext"
                          mx={12}
                          value="qualified"
                        >
                          Qualified
                        </Checkbox>
                      </Flex>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>

              <AccordionItem fontSize="14px" lineHeight="18px" fontWeight="500">
                {({ isExpanded }) => (
                  <>
                    <h2>
                      <AccordionButton _expanded={{ bg: 'primary.100' }}>
                        <Box
                          flex="1"
                          textAlign="left"
                          fontSize="14px"
                          lineHeight="18px"
                          fontWeight="500"
                          color="#2B2D42"
                          px={2}
                        >
                          Status
                        </Box>
                        <AccordionIcon
                          color={isExpanded ? 'purple.800' : 'gray.300'}
                        />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel py={4}>
                      <Flex px={2} justifyContent="space-between">
                        <Checkbox
                          colorScheme="purple"
                          color="default.secondarytext"
                          value="active"
                        >
                          Active
                        </Checkbox>
                        <Checkbox
                          colorScheme="purple"
                          color="default.secondarytext"
                          value="draft"
                        >
                          Draft
                        </Checkbox>
                        <Checkbox
                          colorScheme="purple"
                          color="default.secondarytext"
                          value="approvalPending"
                        >
                          Approval Pending
                        </Checkbox>
                        <Checkbox
                          colorScheme="purple"
                          color="default.secondarytext"
                          value="rejected"
                        >
                          Rejected
                        </Checkbox>
                        <Checkbox
                          colorScheme="purple"
                          color="default.secondarytext"
                          value="inactive"
                        >
                          Inactive
                        </Checkbox>
                      </Flex>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
          </MenuList>
        </Menu>
      </Flex>

      <Box>
        <TableContainer
          boxSizing="border-box"
          border="1px solid"
          borderColor="default.white.400"
          borderRadius="md"
        >
          <Table>
            <Thead>
              <Tr bg="default.white.800">
                {tableHeader.map((title) => (
                  <Th key={title.label}>
                    <Box color="default.gray.600">{title.label}</Box>
                  </Th>
                ))}
              </Tr>
            </Thead>

            <Tbody
              boxSizing="border-box"
              background="default.white.100"
              borderBottom="1px solid"
              borderColor="default.white.400"
            >
              {leadData &&
                leadData.activityLog?.map((activityLog: ActivityLogData) => (
                  <Tr key={activityLog.id} cursor="pointer" onClick={() => {}}>
                    <Td>{activityLog.activityTime}</Td>
                    <Td>{activityLog.activityDescription}</Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
