import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Checkbox,
    Flex,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Table,
    TableContainer,
    Tag,
    TagLabel,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useDisclosure,
} from '@chakra-ui/react';
import Button from 'Library/Button';
import Pagination from 'Library/Pagination';
import FAIcon from 'components/lib/FAIcon';
import { useParams } from 'react-router';
import {
    listSignature,
    useActivateSignatureMutation,
    useDeActivateSignatureMutation,
    useDelSignatureMutation,
    useListSignatureMutation,
} from 'store/signature.slice';
import TrLoading from 'components/app/TrLoading';
import SignatureEditModal from './modal/edit';
import { swalContent } from 'types';
import { AtsConfirm } from 'utils/swal';

const SignatureTable = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const tableHeader: { label: any }[] = [
        {
            label: <Checkbox defaultChecked={false} colorScheme="blue" />,
        },
        { label: 'Title' },
        { label: 'Status' },
        { label: '' },
    ];

    const { signaturePage, signaturePageBtn, signaturesList } =
        useSelector((state: any) => state.signature);
    const [reqListSignature, resListSignature] = useListSignatureMutation();
    const [signatureData, setSignatureData] = useState(null);
    const [reqActivate, resActivate] = useActivateSignatureMutation();
    const [reqDeActivate, resDeActivate] = useDeActivateSignatureMutation();
    const [reqDel, resDel] = useDelSignatureMutation();

    const {
        isOpen: editOpen,
        onOpen: editOnOpen,
        onClose: editOnClose,
    } = useDisclosure();
    useEffect(() => {
        if (
            params.signatureTab == 'email' ||
            params.signatureTab == undefined ||
            resListSignature.isSuccess
        ) {
            reqListSignature(signaturePageBtn);
        }
    }, [
        params.signatureTab,
        resActivate.isSuccess,
        resDeActivate.isSuccess,
        resDel.isSuccess,
    ]);

    const pageChange = async (page: number) => {
        let newParam = {
            page: page,
            take: signaturePageBtn.take,
            query: signaturePageBtn.query,
        };
        dispatch(
            listSignature({
                signaturePageBtn: newParam,
            })
        );
        reqListSignature(newParam);
    };

    let content: swalContent = {
        title: 'Are you sure?',
        text: '',
        buttons: ['Cancel', true],
        icon: 'warning',
    };

    const emailStatus = (status: boolean) => {
        if (status)
            return (
                <Box>
                    <Tag
                        borderRadius="2px"
                        variant="subtle"
                        color="success.400"
                        bg="success.100"
                    >
                        <TagLabel>Active</TagLabel>
                    </Tag>
                </Box>
            );
        else
            return (
                <Box>
                    <Tag
                        borderRadius="2px"
                        variant="subtle"
                        color="caution.400"
                        bg="caution.100"
                    >
                        <TagLabel>INACTIVE</TagLabel>
                    </Tag>
                </Box>
            );
    };

    const entryChange = (entries: number) => {
        let newParam = {
            // page: UserPgBtn.page,
            page: 1,
            take: entries,
            query: signaturePage.query,
        };
        dispatch(
            listSignature({
                signaturePage: newParam,
            })
        );
        reqListSignature(newParam);
    };

    const editClick = (data: any) => {
        setSignatureData(data);
        editOnOpen();
    };

    const changeStatus = async (signature: any) => {
        console.log('signature', signature);
        const status = signature.active ? 'deactivate' : 'activate';
        content.text = `You are about to ${status} ${signature.title}`;
        const confirm = await AtsConfirm(content);
        if (confirm) {
            if (signature.active) {
                await reqDeActivate({ id: signature.id });
            } else {
                await reqActivate({ id: signature.id });
            }
        }
    };

    const delClick = async (signature: any) => {
        content.text = `You are about to delete ${signature.title}`;
        const confirm = await AtsConfirm(content);
        if (confirm) {
            await reqDel({ id: signature?.id });
        }
    };

    return (
        <Box px={6} py={4} justifyContent="space-between">
            <TableContainer
                boxSizing="border-box"
                borderX="1px solid"
                borderTop="1px solid"
                borderColor="default.white.400"
                borderRadius="md"
                minHeight="70vh"
            >
                <Table>
                    <Thead>
                        <Tr bg="default.white.600">
                            {tableHeader.map((title) => (
                                <Th key={title.label} textAlign="left">
                                    <Box color="default.gray.600">{title.label}</Box>
                                </Th>
                            ))}
                        </Tr>
                    </Thead>

                    <Tbody
                        boxSizing="border-box"
                        background="default.white.100"
                        borderBottom="1px solid"
                        borderColor="default.white.400"
                    >
                        {resListSignature.isLoading ? (
                            <TrLoading rows={3} columns={5} />
                        ) : (
                            signaturesList?.length > 0 &&
                            signaturesList.map((item: any, key: number) => {
                                return (
                                    <Tr
                                        key={key}
                                        _hover={{
                                            bg: '#f8f9fa',
                                        }}
                                        cursor="pointer"
                                    >
                                        <Td></Td>
                                        <Td onClick={() => editClick(item)}>{item.title}</Td>
                                        <Td onClick={() => editClick(item)}>
                                            {emailStatus(item.active)}
                                        </Td>
                                        <Td>
                                            <Menu>
                                                <MenuButton>
                                                    <Button
                                                        htmlType="menu"
                                                        variant="ghost"
                                                        iconName="ellipsis-h"
                                                        iconSize="xl"
                                                    />
                                                </MenuButton>

                                                <MenuList fontSize="sm">
                                                    <MenuItem onClick={() => editClick(item)}>
                                                        <Flex
                                                            alignItems="center"
                                                            justifyContent="space-between"
                                                        >
                                                            <FAIcon iconName="pencil" />
                                                            <Box ml={12}>Edit</Box>
                                                        </Flex>
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => changeStatus(item)}
                                                        color={item.active ? 'caution.800' : 'green'}
                                                    >
                                                        <Flex
                                                            alignItems="center"
                                                            justifyContent="space-between"
                                                        >
                                                            <FAIcon
                                                                iconName={item.active ? 'ban' : 'check'}
                                                            />
                                                            <Box ml={12}>
                                                                {item.active ? 'Deactivate' : 'Activate'}
                                                            </Box>
                                                        </Flex>
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => delClick(item)}
                                                        color="caution.800"
                                                    >
                                                        <Flex
                                                            alignItems="center"
                                                            justifyContent="space-between"
                                                        >
                                                            <FAIcon iconName="trash" />
                                                            <Box ml={12}>Delete</Box>
                                                        </Flex>
                                                    </MenuItem>
                                                </MenuList>
                                            </Menu>
                                        </Td>
                                    </Tr>
                                );
                            })
                        )}
                    </Tbody>
                </Table>
            </TableContainer>

            <Pagination
                totalPages={signaturePage.lastPage}
                currentPage={signaturePage.currentPage}
                onPageChange={(page) => pageChange(page)}
                onEntryChange={(entries) => entryChange(entries)}
                totalEntries={signaturePageBtn.count}
                currentCount={signaturesList.length}
                targetCount={signaturePage.take}
            />
            {editOpen && (
                <SignatureEditModal
                    isOpen={editOpen}
                    onClose={editOnClose}
                    signature={signatureData}
                />
            )}
        </Box>
    );
};

export default SignatureTable;
