import { Flex, Stack } from '@chakra-ui/react';
import React from 'react';
import { Outlet } from 'react-router';
import LeadSideBar from './Sidebar';

export default function Layout() {
  return (
    <Flex
      gap="8px"
      sx={{
        mt: '4px',
        p: '8px',
        fontFamily: 'NunitoSans Regular',
        overflow: 'hidden',
        height: 'calc(100vh - 68px)',
      }}
    >
      <LeadSideBar />

      <>
        <Outlet />
      </>
    </Flex>
  );
}
