import { Box, Skeleton, SkeletonCircle, Td, Tr } from '@chakra-ui/react';

export default function ParseLoading() {
  const rows = 7;
  return (
    <>
      <Box gap="10px" p="5" minHeight="68vh">
        {Array.from(Array(rows), (e, i) => {
          return (
            <Box mb="12px" key={i + 'loading'}>
              <Skeleton height="30px" />
              <Box w="25%" mt="5px">
                <Skeleton height="15px" />
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
}
