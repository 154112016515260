import React, { forwardRef } from 'react';
import Select from 'react-select';
import { Box, Icon } from '@chakra-ui/react';
import { phoneInt } from '../Candidates/Drawer/Interviews/modal/details.interface';

// Define the type for the option
interface OptionType {
  value: string;
  label: string;
  icon: React.ElementType;
}

export default function AtsIconSelect(FieldProps: any) {
  const onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName(
        `${FieldProps.className}__option--is-selected`
      )[0];
      if (selectedEl) {
        selectedEl.scrollIntoView({ behavior: 'auto', block: 'nearest' });
      }
    }, 10);
  };

  const formatOptionLabel = (option: phoneInt) => (
    <Box display="flex" alignItems="center">
      <Icon as={option.icon} marginRight={2} />
      {option.label}
    </Box>
  );

  return (
    <Select
      options={FieldProps.options}
      {...FieldProps}
      onChange={(option) => {
        if (typeof FieldProps.onChange === 'function') {
          FieldProps.onChange(option);
        }
      }}
      isMulti={FieldProps.isMulti}
      defaultValue={FieldProps.defaultValue || FieldProps.value}
      isLoading={FieldProps.isLoading}
      placeholder={FieldProps.placeholder}
      menuIsOpen={FieldProps.menuIsOpen}
      styles={FieldProps.styles}
      menuPosition={FieldProps.menuPosition}
      isSearchable={FieldProps.isSearchable}
      onBlur={FieldProps.onBlur}
      onMenuOpen={onMenuOpen}
      formatOptionLabel={formatOptionLabel}
    />
  );
}
