import { Button, Flex, Image, Stack } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { updateAI } from 'store/ai.slice';
import { AI_LIST } from '../Rewrite/constants';
import { GenerateContent } from './Layout/Content';
import { useGenerateServices } from './useGenerateServices';

import MdClose from 'assets/images/ai-generator/MdClose.svg';
import MdMaximize from 'assets/images/ai-generator/MdMaximize.svg';
import MdMinimize from 'assets/images/ai-generator/MdMinimize.svg';

interface AIGeneratorGenerateProps {
  setIsGenerate: any;
  setHasPrompt: any;
  handleRegenerate: ({ index, field }: any) => void;
  handleInsert: any;
  setFieldValue: any;
  isRegenerated: boolean;
  isLoadingList: boolean;
  isLoading: boolean;
  isMaximize: boolean;
  setIsMaximize: any;
}

export const AIGeneratorGenerate = ({
  setIsGenerate,
  setHasPrompt,
  handleRegenerate,
  handleInsert,
  setFieldValue,
  isRegenerated,
  isLoadingList,
  isLoading,
  isMaximize,
  setIsMaximize,
}: AIGeneratorGenerateProps) => {
  const dispatch = useDispatch();

  const { aiList, active, setActive, handleInsertButton } = useGenerateServices(
    { setFieldValue, handleInsert }
  );

  return (
    <Stack
      id="ai"
      justifyContent="space-between"
      sx={{
        width: !isMaximize ? '35%' : '100%',
        height: !isMaximize ? '65vh !important' : '70vh',
        overflowY: 'scroll',
        bgColor: '#fff',
        borderRadius: '6px',
        m: '8px 16px',
        ml: !isMaximize && 0,
        p: '16px',
        pb: 0,
        fontFamily: 'NunitoSans Regular',
      }}
    >
      <Stack spacing="16px">
        <Flex justifyContent="flex-end" alignItems="center">
          <Flex gap="10px">
            <Image
              src={!isMaximize ? MdMaximize : MdMinimize}
              sx={{ cursor: 'pointer' }}
              onClick={() => setIsMaximize(!isMaximize)}
            />
            <Image
              src={MdClose}
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                dispatch(updateAI({ aiList: AI_LIST }));
                setIsGenerate(false);
                setHasPrompt('');
              }}
            />
          </Flex>
        </Flex>

        <GenerateContent
          title="Subject"
          content={aiList}
          active={active}
          setActive={setActive}
          handleRegenerate={handleRegenerate}
          isRegenerated={isRegenerated}
          isLoadingList={isLoadingList}
          isLoading={isLoading}
        />
        <GenerateContent
          title="Body"
          content={aiList}
          active={active}
          setActive={setActive}
          handleRegenerate={handleRegenerate}
          isRegenerated={isRegenerated}
          isLoadingList={isLoadingList}
          isLoading={isLoading}
        />
      </Stack>

      <Flex
        justifyContent="flex-end"
        sx={{
          position: 'sticky',
          bottom: 0,
          background: '#fff',
          padding: '10px 5px',
        }}
      >
        <Button
          variant="skeleton"
          sx={{
            backgroundColor: '#6930CA',
            borderRadius: '4px',
            color: '#FFF',
          }}
          _hover={{}}
          onClick={handleInsertButton}
        >
          Insert
        </Button>
      </Flex>
    </Stack>
  );
};
