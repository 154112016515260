import { Button, Flex } from '@chakra-ui/react';

interface SwitchInt {
  value: string;
  onToggle: (value: string) => void;
}

const AdminSwitch = ({ value = 'team', onToggle }: SwitchInt) => {
  return (
    <Flex w="220px" bg="#FAF6FF" borderRadius="10px" p="1" color="#718096">
      <Button
        variant="ghost"
        sx={{
          fontSize: '14px',
          lineHeight: '18px',
          fontWeight: 700,
          py: value === 'team' && '14px',
          borderRadius: value === 'team' && '10px',
          bg: value === 'team' ? '#FFF' : '#FAF6FF',
          color: value === 'team' && 'primary.800',
        }}
        _focus={{ border: 'none' }}
        w="100%"
        onClick={() => onToggle('team')}
      >
        Team
      </Button>
      <Button
        variant="ghost"
        sx={{
          fontSize: '14px',
          lineHeight: '18px',
          fontWeight: 700,
          py: value === 'individual' && '14px',
          borderRadius: value === 'individual' && '10px',
          bg: value === 'individual' ? '#FFF' : '#FAF6FF',
          color: value === 'individual' && 'primary.800',
        }}
        _focus={{ border: 'none' }}
        w="100%"
        onClick={() => onToggle('individual')}
      >
        Individual
      </Button>
    </Flex>
  );
};

export default AdminSwitch;
