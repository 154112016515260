import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { updateOutreach } from 'store/outreach.slice';

export const useSuppressionTableServices = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { suppressionList, suppressionData, suppressionPgBtn } = useSelector(
    (state: any) => state.outreach
  );

  const [rowId, setRowId] = useState(suppressionData?.rowId);
  const [totalSuppressionList, setTotalSuppressionList] = useState(
    suppressionList.length
  );

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [isIndeterminate, setisIndeterminate] = useState(false);

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      let params = {
        take: suppressionPgBtn.take,
        query: '',
        page: suppressionData.page,
      };
      dispatch(
        updateOutreach({
          suppressionPgBtn: params,
        })
      );
    }
  };

  const onSubmit = (data: any) => {
    // let params = {
    //   take: suppressionPgBtn.take,
    //   query: data.search,
    //   page: suppressionData.page,
    // };
    // reqGetSuppressionList(params);
  };

  const handleSelectAll = (e: any) => {
    const { checked } = e.target;
    setIsCheckAll(checked);
    setIsCheck(suppressionList.map((li: any) => li.id));

    if (!checked) {
      setIsCheck([]);
    }
  };

  const handleCheck = async (e: any) => {
    const { id, checked } = e.target;
    let ID = parseInt(id);
    setIsCheck([...isCheck, ID]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== ID));
    }
  };

  useEffect(() => {
    setisIndeterminate(false);
    if (isCheck.length > 0 && isCheck.length < suppressionList.length) {
      setisIndeterminate(true);
    }
    if (isCheck.length <= 0) {
      setIsCheckAll(false);
    }
    if (isCheck.length === suppressionList.length) {
      setIsCheckAll(true);
    }
  }, [isCheck, isCheckAll, suppressionList]);

  const handlePrevData = () => {
    const templateID = suppressionList[rowId - 1 - 1].id;
    setRowId((prev: number) => prev - 1);
    navigate(
      `/engage/${params.settingNav}/${params.settingTab}/${params.settingType}/${templateID}`
    );
  };

  const handleNextData = () => {
    const templateID = suppressionList[rowId - 1 + 1].id;
    setRowId((prev: number) => prev + 1);
    navigate(
      `/engage/${params.settingNav}/${params.settingTab}/${params.settingType}/${templateID}`
    );
  };

  const handleBulkDeleteSuppression = () => {
    // const payload = {
    //   ids: isCheck,
    // };

    // reqBulkDeleteSuppression(payload);
  };

  useEffect(() => {
    setTotalSuppressionList(suppressionList.length);
  }, [suppressionList]);


  useEffect(() => {
    if (suppressionData?.rowId) {
      setRowId(suppressionData?.rowId);
    }
  }, [suppressionData?.rowId]);

  return {
    rowId,
    totalSuppressionList,
    suppressionList,
    suppressionData,
    suppressionPgBtn,
    isCheck,
    isIndeterminate,
    isCheckAll,
    handleCheck,
    handleSelectAll,
    clearSearch,
    onSubmit,
    handlePrevData,
    handleNextData,
    handleBulkDeleteSuppression,
  };
};
