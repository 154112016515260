import * as Yup from 'yup';

export const SETTINGS_SCHEMA = Yup.lazy(() =>
  Yup.object().shape({
    primary: Yup.bool(),
    job_opening: Yup.string(),
    sequence_name: Yup.string(),
    mailboxes: Yup.object().nullable(),
    template: Yup.object().nullable(),
  })
);
