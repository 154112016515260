import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  useCreateCandidateNoteMutation,
  useListCandidateNoteMutation,
  useUpdateCandidateNoteMutation,
} from 'store/candidatenote.slice';

export function useNotesServices() {
  const parse = require('html-react-parser');

  const { candidateDetails } = useSelector((state: any) => state.candidates);

  const [hover, setHover] = useState({ id: null, hover: null });
  const { candidateNotes, candidateNotePgBtn } = useSelector(
    (state: any) => state.candidateNotes
  );
  const { sequenceCandidateData } = useSelector((state: any) => state.outreach);

  const [reqCreate, resCreate] = useCreateCandidateNoteMutation();
  const [reqList] = useListCandidateNoteMutation();
  const [reqUpdate, resUpdate] = useUpdateCandidateNoteMutation();

  const [quilValue, setquilValue] = useState('');
  const [hasEditNote, SetHasEditNote] = useState(false);
  const [noteId, setNoteId] = useState(null);

  const candidateID = sequenceCandidateData?.id;

  useEffect(() => {
    if (resCreate.isSuccess || resUpdate.isSuccess) {
      setquilValue('');
      reqList({ data: candidateNotePgBtn, id: candidateID });
    }
    if (resUpdate.isSuccess) {
      SetHasEditNote(false);
      setNoteId(null);
    }
  }, [resCreate.isSuccess, resUpdate.isSuccess]);

  const submitNote = () => {
    if (quilValue !== '') {
      setquilValue('');
      reqCreate({ data: { notes: quilValue }, id: candidateID });
    }
  };

  const cancelUpdate = () => {
    setquilValue('');
    SetHasEditNote(false);
    setNoteId(null);
  };
  const UpdateNotes = async () => {
    if (quilValue !== '') {
      reqUpdate({ data: { notes: quilValue }, id: noteId });
    }
  };

  return {
    parse,
    candidateDetails,
    hover,
    setHover,
    candidateNotes,
    quilValue,
    setquilValue,
    hasEditNote,
    cancelUpdate,
    UpdateNotes,
    submitNote,
    isLoading: resCreate.isLoading || resUpdate.isLoading,
    candidateNotePgBtn,
    candidateID,
    reqList,
  };
}
