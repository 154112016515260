import { Checkbox } from '@chakra-ui/react';
import { useRef, useLayoutEffect } from 'react';
import { status } from '../constants';

export default function CheckboxComponent(props: any) {
  const { indeterminate, checked, id, compute, ...rest } = props;
  const inputRef = useRef(null);

  useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = indeterminate;
      inputRef.current.checked = checked;
    }
  }, [indeterminate, checked]);

  return (
    <input
      {...rest}
      as={Checkbox}
      ref={inputRef}
      type="checkbox"
      style={{
        accentColor: '#6930CA',
        cursor: 'pointer',
      }}
      onChange={() => {
        const newStatus = inputRef.current.checked
          ? status.checked
          : status.unchecked;
        compute(id, newStatus);
      }}
    />
  );
}
