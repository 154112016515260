import { Alert, AlertIcon, Flex } from '@chakra-ui/react';
import React from 'react';

interface SuccessAlertInterfcae {
  content: React.ReactNode;
}
//TODO: move this as common component
export default function SuccessAlert({ content }: SuccessAlertInterfcae) {
  return (
    <Flex
      sx={{
        zIndex: 1, //TODO: Remove if it is not required
        position: 'absolute',
        top: '10px',
        left: 0,
        right: 0,
        width: 'fit-content',
        margin: '0 auto',
        fontSize: '20px',
      }}
    >
      <Alert
        status="success"
        colorScheme="purple"
        sx={{
          borderRadius: '10px',
          p: '20px',
        }}
      >
        <AlertIcon />
        {content}
      </Alert>
    </Flex>
  );
}
