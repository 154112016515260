import {
  Box,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import AtsSelect from 'components/app/AtsSelect';
import AtsEmailBody from 'components/app/EmailBody';
import ScrollToFieldError from 'components/app/ScrollError';
import { Field, Formik } from 'formik';
import Button from 'Library/Button';
import { SuppressionModalProps } from '../types';
import { suppressionTypeOption } from './constant';
import { useSuppressionModalServices } from './useSuppressionModalServices';

const SuppressionModal = ({
  isEdit,
  isOpen,
  onClose,
}: SuppressionModalProps) => {
  const { initialValues, validationSchema, handleOnSubmit } =
    useSuppressionModalServices({ isEdit, isOpen, onClose });

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={() => {
        onClose();
      }}
      size="6xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent fontFamily="NunitoSans Regular">
        <ModalHeader
          background="default.white.800"
          borderRadius="4px 4px 0"
          p="18px 32px"
          fontSize="18px"
          lineHeight="21.92px"
          textAlign="left"
        >
          {isEdit ? 'Edit Suppression Record' : 'Add Suppression Record'}
        </ModalHeader>
        <ModalCloseButton
          top="13px"
          right="20px"
          color="default.secondarytext"
        />
        <ModalBody borderRadius="0 0 4px 4px" p={0}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleOnSubmit}
            enableReinitialize={true}
          >
            {({
              values,
              handleSubmit,
              handleChange,
              setFieldValue,
              errors,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                <ScrollToFieldError />
                <Box
                  sx={{
                    border: '1px solid #EEEEEE',
                    borderRadius: '6px',
                    p: '18px 32px',
                  }}
                >
                  <Stack direction="row" spacing="8px">
                    <Stack
                      width="80%"
                      sx={{
                        border: '0.5px solid #E7E9ED',
                        borderRadius: '6px',
                        p: '16px',
                        margin: '0 auto',
                      }}
                    >
                      <Stack spacing="24px" mb="24px">
                        <Stack direction="row" spacing="8px">
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.company && touched.company
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Company Name{' '}
                              <Box as="span" color="caution.800">
                                *
                              </Box>
                            </FormLabel>
                            <Input
                              id="company"
                              name="company"
                              type="text"
                              placeholder="Company Name"
                              variant="outline"
                              value={values.company}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.company)}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.type &&
                                touched.type
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Suppression Type{' '}
                              <Box as="span" color="caution.800">
                                *
                              </Box>
                            </FormLabel>
                            <Field
                              name="type"
                              options={suppressionTypeOption}
                              component={AtsSelect}
                              value={
                                suppressionTypeOption.filter(
                                  (val) => val.value === values.type
                                )[0]
                              }
                              placeholder="Select"
                              onChange={(e: any) => {
                                setFieldValue(
                                  'type',
                                  String(e.value)
                                );
                              }}
                            />
                            <FormErrorMessage>
                              {String(errors.type)}
                            </FormErrorMessage>
                          </FormControl>
                        </Stack>

                        <Stack direction="row" spacing="8px">
                          <FormControl
                            w="50%"
                            isInvalid={Boolean(!!errors.domain && touched.domain)}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Email / Domain{' '}
                              <Box as="span" color="caution.800">
                                *
                              </Box>
                            </FormLabel>
                            <Input
                              id="domain"
                              name="domain"
                              type="text"
                              placeholder="Email / Domain"
                              variant="outline"
                              value={values.domain}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.domain)}
                            </FormErrorMessage>
                          </FormControl>
                          <Stack w="50%">
                            <FormControl
                              isInvalid={Boolean(
                                !!errors.expiration_date &&
                                  touched.expiration_date
                              )}
                            >
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                Expiration Date{' '}
                                <Box as="span" color="caution.800">
                                  *
                                </Box>
                              </FormLabel>
                              <Input
                                id="expiration_date"
                                name="expiration_date"
                                type="date"
                                placeholder="mm/dd/yyyy"
                                variant="outline"
                                value={values.expiration_date}
                                onChange={handleChange}
                              />
                              <FormErrorMessage>
                                {String(errors.expiration_date)}
                              </FormErrorMessage>
                            </FormControl>
                            <FormControl>
                              <Checkbox
                                id="is_permanent"
                                name="is_permanent"
                                isChecked={values.is_permanent}
                                onChange={handleChange}
                                colorScheme="purple"
                              >
                                Set as Permanent
                              </Checkbox>
                            </FormControl>
                          </Stack>
                        </Stack>

                        <FormControl
                          isInvalid={Boolean(
                            !!errors.description && touched.description
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Description
                          </FormLabel>
                          <Box
                            sx={{
                              '& .ql-editor': {
                                minHeight: '40vh !important',
                              },
                            }}
                          >
                            <AtsEmailBody
                              value={values.description}
                              onChange={(e: any) => {
                                setFieldValue(`description`, e);
                              }}
                              height={200}
                            />
                            <FormErrorMessage>
                              {String(errors.description)}
                            </FormErrorMessage>
                          </Box>
                        </FormControl>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>

                <ModalFooter
                  position="sticky"
                  bottom="0"
                  background="default.white.100"
                  boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                >
                  <Stack direction="row" spacing="10px">
                    <Button onClick={onClose}>Close</Button>
                    <Button
                      variant="solid"
                      type="submit"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Submit
                    </Button>
                  </Stack>
                </ModalFooter>
              </form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SuppressionModal;
