import * as Yup from 'yup';

export const SEQUENCE_SCHEMA = Yup.lazy(() =>
  Yup.object().shape({
    primary: Yup.bool(),
    job_id: Yup.string(),
    name: Yup.string(),
    sender_email: Yup.object().nullable(),
    email_template: Yup.string(),
  })
);

export const suppressionTypeOption = [
  {
    label: 'General',
    value: 'General',
  },
  {
    label: 'Staffing',
    value: 'Staffing',
  },
  {
    label: 'Non-profit',
    value: 'Non-profit',
  },
  {
    label: 'Government',
    value: 'Government',
  },
  {
    label: 'Schools',
    value: 'Schools',
  },
  {
    label: 'Small Business',
    value: 'Small Business',
  },
];
