import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tag,
  TagLabel,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { InterviewPreviewInt } from 'types';
import replacePTags from 'utils/replaceTags';
import replacePlaceholders from 'utils/textPlaceholders';
import { boolean } from 'yup';

interface propsInt {
  param: InterviewPreviewInt;
  isOpen: boolean;
  onClose: () => void;
}

const PreviewDetails = ({ param, isOpen, onClose }: propsInt) => {
  const { placehoderPair } = useSelector((state: any) => state.template);
  const placeholders = (text: string) => {
    return replacePlaceholders(text, placehoderPair);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onClose();
        }}
        size="3xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="Proxima Nova Regular">
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            Interview Preview
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          <ModalBody borderRadius="0 0 4px 4px" p="24px">
            <Flex gap="16px" direction="column">
              <Box sx={{ fontSize: '18px', fontWeight: 700, color: '#2D3748' }}>
                {param.subject}
              </Box>
              <Flex gap="8px" direction="column">
                <Box sx={{ fontSize: '14px', color: '#718096' }}>
                  Meeting Mode:{' '}
                  <span style={{ color: '#000' }}>{param.mode}</span>
                </Box>
                <Box sx={{ fontSize: '14px', color: '#718096' }}>
                  Meeting Location:{' '}
                  <span style={{ color: '#000' }}>{param.location}</span>
                </Box>
                <Box sx={{ fontSize: '14px', color: '#718096' }}>
                  Date & Time:{' '}
                  <span style={{ color: '#000' }}>{param.date_time}</span>
                </Box>
                <Box sx={{ fontSize: '14px', color: '#718096' }}>
                  Timezone:{' '}
                  <span style={{ color: '#000' }}>{param.timezone}</span>
                </Box>
              </Flex>
              <Divider />
              <Flex gap="8px" direction="column">
                <Box sx={{ fontSize: '14px', color: '#718096' }}>
                  To: <span style={{ color: '#000' }}>{param.to}</span>
                </Box>

                <Box sx={{ fontSize: '14px', color: '#718096' }}>
                  Panels:{' '}
                  <span style={{ color: '#000' }}>
                    {param.panels.filter(Boolean).join(', ')}
                  </span>
                </Box>
              </Flex>
              <Divider />
              <Box>
                <div
                  style={{
                    fontFamily: 'Verdana',
                    fontSize: '10pt',
                    margin: 0,
                    padding: 0,
                  }}
                  dangerouslySetInnerHTML={{ __html: replacePTags(param.body) }}
                />
              </Box>
              <Flex gap="5px" direction="column">
                {param.attachments.length > 0 &&
                  param.attachments.map((item: any, key: number) => {
                    return (
                      <>
                        {item ? (
                          <Tag key={'attachments-' + key} width="fit-content">
                            <Stack spacing={1}>
                              <HStack>
                                <TagLabel sx={{ fontWeight: 600 }}>
                                  {item}
                                </TagLabel>
                              </HStack>
                            </Stack>
                          </Tag>
                        ) : (
                          <></>
                        )}
                      </>
                    );
                  })}
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default PreviewDetails;
