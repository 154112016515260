export const SEQUENCE_STATUS: any = {
  ongoing: {
    label: 'Ongoing',
    bgColor: '#D9E7F2',
    color: '#0061AB',
  },
  messaged: {
    label: 'Messaged',
    bgColor: '#D9E7F2',
    color: '#0061AB',
  },
  opened: {
    label: 'Opened',
    bgColor: '#F9ECDA',
    color: '#D68309',
  },
  replied: {
    label: 'Replied',
    bgColor: '#38B17A1A',
    color: '#38B17A',
  },
  bounced: {
    label: 'Bounced',
    bgColor: '#CB2C2C1A',
    color: '#CB2C2C',
  },
  clicked: {
    label: 'Clicked',
    bgColor: '#38B17A1A',
    color: '#38B17A',
  },
  ats_update: {
    label: 'ATS Update',
    bgColor: '#F9ECDA',
    color: '#D68309',
  },
  no_email: {
    label: 'No Email',
    bgColor: '#CB2C2C1A',
    color: '#CB2C2C',
  },
  take_action: {
    label: 'Take Action',
    bgColor: '#CB2C2C1A',
    color: '#CB2C2C',
  },
  ooo: {
    label: 'Out of Office',
    bgColor: '#2B2D421A',
    color: '#2B2D42',
  },
  li_replied: {
    label: 'LinkedIn Replied (Buzz)',
    bgColor: '#38B17A1A',
    color: '#38B17A',
  },
  li_request: {
    label: 'LinkedIn Requested (Buzz)',
    bgColor: '#F9ECDA',
    color: '#D68309',
  },
  li_connected: {
    label: 'LinkedIn Connected (Buzz)',
    bgColor: '#F9ECDA',
    color: '#D68309',
  },
};

export const LEAD_STATUS: any = {
  Open: {
    label: 'Open',
    bgColor: '#38B17A1A',
    color: '#38B17A',
  },
  Draft: {
    label: 'Draft',
    bgColor: '#F9ECDA',
    color: '#D68309',
  },
  Close: {
    label: 'Close',
    bgColor: '#CB2C2C1A',
    color: '#CB2C2C',
  },
};

export const CANDIDATE_STAT = [
  {
    id: 0,
    stat_id: [] as any,
    status: 1,
    name: 'All',
    items: [
      {
        id: 1,
        stat_id: [3, 4, 6],
        status: 1,
        name: 'Opened',
      },
      {
        id: 2,
        stat_id: [2, 3, 4, 5],
        status: 1,
        name: 'Messaged',
      },
      {
        id: 3,
        stat_id: [4],
        status: 1,
        name: 'Replied',
      },
      {
        id: 4,
        stat_id: [5],
        status: 1,
        name: 'Bounced',
      },
      {
        id: 5,
        stat_id: [15],
        status: 1,
        name: 'Unsubscribed',
      },
      {
        id: 6,
        stat_id: [9],
        status: 1,
        name: 'No Email',
      },
    ],
  },
];

export const CANDIDATE_FIL = [
  {
    id: 0,
    stat_id: [] as any,
    status: 1,
    name: 'All',
    items: [
      {
        id: 1,
        stat_id: [3],
        status: 1,
        name: 'Enriched',
      },
      {
        id: 2,
        stat_id: [2],
        status: 1,
        name: 'Not Enriched',
      },
    ],
  },
];
