import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import AtsSelect from 'components/app/AtsSelect';
import AtsSelect3 from 'components/app/AtsSelect3';
import AtsEmailBody from 'components/app/EmailBody';
import ScrollToFieldError from 'components/app/ScrollError';
import { Field, Formik } from 'formik';
import Button from 'Library/Button';
import {
  timeOptions,
  timezoneOptions,
} from 'modules/Engage/Page/Layout/Recruitment/Settings/TabList/TabPanel/DefaultSchedule/constants';
import moment from 'moment';
import { SMSAutomationModalProps } from '../types';
import { smsAutomationTriggerOption } from './constant';
import { useSMSAutomationModalServices } from './useSMSAutomationModalServices';

const SMSAutomationModal = ({
  isEdit,
  isOpen,
  onClose,
}: SMSAutomationModalProps) => {
  const { initialValues, validationSchema, handleOnSubmit, candSource } =
    useSMSAutomationModalServices({ isEdit, isOpen, onClose });

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={() => {
        onClose();
      }}
      size="6xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent fontFamily="NunitoSans Regular">
        <ModalHeader
          background="default.white.800"
          borderRadius="4px 4px 0"
          p="18px 32px"
          fontSize="18px"
          lineHeight="21.92px"
          textAlign="left"
        >
          {isEdit ? 'Edit SMS Automation' : 'Add SMS Automation'}
        </ModalHeader>
        <ModalCloseButton
          top="13px"
          right="20px"
          color="default.secondarytext"
        />
        <ModalBody borderRadius="0 0 4px 4px" p={0}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleOnSubmit}
            enableReinitialize={true}
          >
            {({
              values,
              handleSubmit,
              handleChange,
              setFieldValue,
              errors,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                <ScrollToFieldError />
                <Box
                  sx={{
                    border: '1px solid #EEEEEE',
                    borderRadius: '6px',
                    p: '18px 32px',
                  }}
                >
                  <Stack direction="row" spacing="8px">
                    <Stack
                      width="80%"
                      sx={{
                        border: '0.5px solid #E7E9ED',
                        borderRadius: '6px',
                        p: '16px',
                        margin: '0 auto',
                      }}
                    >
                      <Stack spacing="24px" mb="24px">
                        <FormControl
                          isInvalid={Boolean(!!errors.name && touched.name)}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Automation Name{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <Input
                            id="name"
                            name="name"
                            type="text"
                            placeholder="Automation Name"
                            variant="outline"
                            value={values.name}
                            onChange={handleChange}
                          />
                          <FormErrorMessage>
                            {String(errors.name)}
                          </FormErrorMessage>
                        </FormControl>

                        <FormControl
                          isInvalid={Boolean(
                            !!errors.trigger && touched.trigger
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Trigger{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <Field
                            name="trigger"
                            options={smsAutomationTriggerOption}
                            component={AtsSelect}
                            value={
                              smsAutomationTriggerOption.filter(
                                (val) => val.value === values.trigger
                              )[0]
                            }
                            placeholder="Select"
                            onChange={(e: any) => {
                              setFieldValue('trigger', String(e.value));
                            }}
                          />
                          <FormErrorMessage>
                            {String(errors.trigger)}
                          </FormErrorMessage>
                        </FormControl>

                        <FormControl
                          isInvalid={Boolean(
                            !!errors.sources && touched.sources
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Select Source{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <Field
                            name="sources"
                            options={candSource}
                            component={AtsSelect}
                            isMulti
                            value={values.sources}
                            placeholder="Select"
                            onChange={(e: any) => {
                              setFieldValue('sources', e);
                            }}
                          />
                          <FormErrorMessage>
                            {String(errors.sources)}
                          </FormErrorMessage>
                        </FormControl>

                        <FormControl
                          isInvalid={Boolean(
                            !!errors.message && touched.message
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            SMS Message{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <Box
                            sx={{
                              '& .p-editor-content': {
                                minHeight: '40vh !important',
                                height: '40vh !important',
                              },
                            }}
                          >
                            <AtsEmailBody
                              value={values.message}
                              onChange={(e: any) => {
                                setFieldValue(`message`, e);
                              }}
                              height={200}
                            />
                            <FormErrorMessage>
                              {String(errors.message)}
                            </FormErrorMessage>
                          </Box>
                        </FormControl>

                        <FormControl>
                          <RadioGroup
                            defaultValue={values.send_immediately?.toLocaleString()}
                          >
                            <Stack spacing={5} direction="row">
                              <Radio
                                name="send_immediately"
                                color="primary.800"
                                colorScheme="purple"
                                value="true"
                                onChange={handleChange}
                              >
                                Send Immediately
                              </Radio>
                              <Radio
                                name="send_immediately"
                                color="primary.800"
                                colorScheme="purple"
                                value="false"
                                onChange={handleChange}
                              >
                                Set a Schedule
                              </Radio>
                            </Stack>
                          </RadioGroup>
                        </FormControl>

                        {values.send_immediately?.toLocaleString() ===
                          'false' && (
                          <>
                            <FormControl id="time-zone">
                              <FormLabel>
                                Timezone{' '}
                                <Box as="span" color="caution.800">
                                  *
                                </Box>
                              </FormLabel>
                              <AtsSelect3
                                id="timezone"
                                name="timezone"
                                variant="outline"
                                value={{
                                  label: values.schedule_details.timezone,
                                  value: values.schedule_details.timezone,
                                }}
                                onChange={(e: any) => {
                                  setFieldValue(
                                    'schedule_details.timezone',
                                    e.value
                                  );
                                }}
                                options={timezoneOptions}
                              />
                            </FormControl>

                            <Stack justify="space-between">
                              <Text fontWeight="medium">
                                Time{' '}
                                <Box as="span" color="caution.800">
                                  *
                                </Box>
                              </Text>
                              <Stack direction="row">
                                <FormControl>
                                  <AtsSelect3
                                    id="schedule_details.start_time"
                                    name="schedule_details.start_time"
                                    placeholder="00:00 AM"
                                    variant="outline"
                                    onChange={(e: any) => {
                                      setFieldValue(
                                        'schedule_details.start_time',
                                        e.value
                                      );
                                    }}
                                    isMulti={false}
                                    options={timeOptions}
                                    value={{
                                      label: moment(
                                        values.schedule_details.start_time,
                                        'HH:mm'
                                      ).format('hh:mm A'),
                                      value: moment(
                                        values.schedule_details.start_time,
                                        'hh:mm A'
                                      ).format('HH:mm'),
                                    }}
                                  />
                                </FormControl>

                                <FormControl>
                                  <AtsSelect3
                                    id="schedule_details.end_time"
                                    name="schedule_details.end_time"
                                    placeholder="00:00 PM"
                                    variant="outline"
                                    onChange={(e: any) => {
                                      setFieldValue(
                                        'schedule_details.end_time',
                                        e.value
                                      );
                                    }}
                                    isMulti={false}
                                    options={timeOptions}
                                    value={{
                                      label: moment(
                                        values.schedule_details.end_time,
                                        'HH:mm'
                                      ).format('hh:mm A'),
                                      value: moment(
                                        values.schedule_details.end_time,
                                        'hh:mm A'
                                      ).format('HH:mm'),
                                    }}
                                  />
                                </FormControl>
                              </Stack>
                            </Stack>
                          </>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>

                <ModalFooter
                  position="sticky"
                  bottom="0"
                  background="default.white.100"
                  boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                >
                  <Stack direction="row" spacing="10px">
                    <Button onClick={onClose}>Close</Button>
                    <Button
                      variant="solid"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      {isEdit ? 'Update' : ' Submit'}
                    </Button>
                  </Stack>
                </ModalFooter>
              </form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SMSAutomationModal;
