import {
  Badge,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from '@chakra-ui/react';
import Button from 'Library/Button';
import parse from 'html-react-parser';
import { EmailConnectionModalProps } from '../types';
import FAIcon from 'components/lib/FAIcon';
import { useDispatch } from 'react-redux';
import { updateOutreach } from 'store/outreach.slice';
import { useEmailConnectionDrawerServices } from './useEmailConnectionDrawerServices';
import { useNavigate } from 'react-router';
import { useEmailConnectionTableServices } from '../Table/useEmailConnectionTableServices';

export const EmailConnectionDrawer = ({
  isOpen,
  onOpen,
  onClose,
}: EmailConnectionModalProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    rowId,
    emailConnectionData,
    totalConnectionList,
    handlePrevData,
    handleNextData,
  } = useEmailConnectionTableServices({});

  const { handleDisconnectConnection, handleDeleteConnection } =
    useEmailConnectionDrawerServices({
      onClose,
    });

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => {
        onClose();
        navigate('/engage/settings/recruitment/email-connection');
      }}
      size="xl"
    >
      <DrawerOverlay />
      <DrawerContent fontFamily="NunitoSans Regular">
        <DrawerCloseButton
          position="absolute"
          top="0px"
          left="-40px"
          borderRadius="unset"
          p="20px"
          bg="#E6E9EE"
          color="default.secondarytext"
          _hover={{
            backgroundColor: 'none',
          }}
        />
        <Flex
          justifyContent="space-between"
          alignItems="center"
          pl={8}
          pr={0}
          borderBottom="1px solid #E6E9EE"
          fontSize="sm"
          fontWeight="normal"
          color="default.secondarytext"
        >
          <Box sx={{ fontSize: '14px', color: '#6B6C7B' }}>
            Showing <b>{rowId}</b> of {totalConnectionList} entries
          </Box>
          <Flex>
            <Box borderLeft="1px solid #6B6C7B" borderRadius="unset">
              <Button
                iconName="chevron-left"
                variant="ghost"
                onClick={() => rowId !== 1 && handlePrevData()}
                disabled={rowId === 1}
              />
            </Box>

            <Box borderLeft="1px solid #6B6C7B" borderRadius="unset">
              <Button
                iconName="chevron-right"
                variant="ghost"
                onClick={() =>
                  rowId !== totalConnectionList && handleNextData()
                }
                disabled={rowId === totalConnectionList}
              />
            </Box>
          </Flex>
        </Flex>

        <DrawerBody p="24px 36px">
          <Stack>
            <Stack spacing="16px" mb="32px">
              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Email
                </Text>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Text
                    sx={{
                      fontSize: '16px',
                      fontWeight: 700,
                      color: '#2B2D42',
                    }}
                  >
                    {emailConnectionData?.email}
                  </Text>

                  <Menu closeOnSelect={false}>
                    <MenuButton>
                      <Button
                        htmlType="menu"
                        variant="ghost"
                        iconName="ellipsis-h"
                        iconSize="xl"
                      />
                    </MenuButton>
                    <MenuList
                      fontSize="12px"
                      lineHeight="16px"
                      fontWeight="600"
                    >
                      <MenuItem
                        icon={<FAIcon iconName="pencil" />}
                        onClick={() => {
                          dispatch(
                            updateOutreach({
                              emailConnectionData: emailConnectionData,
                            })
                          );
                          onOpen();
                        }}
                      >
                        Edit
                      </MenuItem>
                      {emailConnectionData.is_disconnect === 0 && (
                        <MenuItem
                          icon={<FAIcon iconName="circle" />}
                          onClick={() => {
                            handleDisconnectConnection();
                          }}
                          sx={{ color: '#CB2C2C' }}
                        >
                          Disconnect
                        </MenuItem>
                      )}
                      <MenuItem
                        icon={<FAIcon iconName="trash" />}
                        onClick={() => {
                          handleDeleteConnection();
                        }}
                        sx={{ color: '#CB2C2C' }}
                      >
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Stack>
              </Stack>

              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Interval
                </Text>
                <Text
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#2B2D42',
                  }}
                >
                  {emailConnectionData?.random_interval_from}-
                  {emailConnectionData?.random_interval_to}
                </Text>
              </Stack>

              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Provider
                </Text>
                <Text
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#2B2D42',
                    textTransform: 'capitalize',
                  }}
                >
                  {emailConnectionData?.provider}
                </Text>
              </Stack>

              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Limit per day
                </Text>
                <Text
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#2B2D42',
                  }}
                >
                  {emailConnectionData?.email_limit_per_day}
                </Text>
              </Stack>

              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  DNS
                </Text>
                <Text
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#2B2D42',
                    textTransform: 'capitalize',
                  }}
                >
                  {emailConnectionData?.dns}
                </Text>
              </Stack>

              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Status
                </Text>
                {emailConnectionData?.is_disconnect === 0 ? (
                  <Badge
                    variant="subtle"
                    colorScheme="green"
                    sx={{
                      borderRadius: '2px',
                      p: '4px 8px',
                      fontSize: '14px',
                      textTransform: 'capitalize',
                      color: '#38B17A',
                    }}
                  >
                    Connected
                  </Badge>
                ) : (
                  <Badge
                    variant="subtle"
                    colorScheme="red"
                    sx={{
                      borderRadius: '2px',
                      p: '4px 8px',
                      fontSize: '14px',
                      textTransform: 'capitalize',
                    }}
                  >
                    Disconnected
                  </Badge>
                )}
              </Stack>
            </Stack>

            <Stack spacing="16px" mb="32px !important">
              <Text
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  color: '#2B2D42',
                }}
              >
                Percentage per Step
              </Text>

              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Step 1
                </Text>
                <Text
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#2B2D42',
                  }}
                >
                  {emailConnectionData?.percentage_step_0}
                </Text>
              </Stack>

              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Step 2
                </Text>
                <Text
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#2B2D42',
                    textTransform: 'capitalize',
                  }}
                >
                  {emailConnectionData?.percentage_step_1}
                </Text>
              </Stack>

              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Step 3
                </Text>
                <Text
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#2B2D42',
                  }}
                >
                  {emailConnectionData?.percentage_step_2}
                </Text>
              </Stack>

              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Step 4
                </Text>
                <Text
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#2B2D42',
                  }}
                >
                  {emailConnectionData?.percentage_step_3}
                </Text>
              </Stack>

              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Step 5
                </Text>
                <Text
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#2B2D42',
                  }}
                >
                  {emailConnectionData?.percentage_step_4}
                </Text>
              </Stack>

              <Stack direction="row" spacing="24px">
                <Text
                  sx={{
                    minWidth: '200px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#9B9B9B',
                  }}
                >
                  Step 6
                </Text>
                <Text
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#2B2D42',
                  }}
                >
                  {emailConnectionData?.percentage_step_5}
                </Text>
              </Stack>
            </Stack>

            <Stack spacing="16px" mb="32px">
              <Stack spacing="24px">
                <Text
                  sx={{
                    fontSize: '16px',
                    fontWeight: 600,
                    color: '#2B2D42',
                  }}
                >
                  Email Signature
                </Text>

                <Stack>
                  {emailConnectionData?.signature &&
                    parse(emailConnectionData?.signature)}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
