import { Box, Flex, Skeleton, SkeletonCircle, Stack } from '@chakra-ui/react';

export default function FormSkeleton() {
  return (
    <Stack py={4} rowGap={6}>
      <Flex gap={5} alignItems="center">
        <Flex alignItems="center" gap="10px">
          <SkeletonCircle size="4" />
          <Skeleton height="15px" width="200px" />
        </Flex>
        <Flex alignItems="center" gap="10px">
          <SkeletonCircle size="4" />
          <Skeleton height="15px" width="200px" />
        </Flex>
      </Flex>

      <Flex gap="32px">
        <Box width="50%">
          <Skeleton height="35px" />
        </Box>
        <Box width="50%">
          <Skeleton height="35px" />
        </Box>
      </Flex>
      <Flex gap="32px">
        <Box w="100%">
          <Skeleton height="35px" />
        </Box>
      </Flex>
      <Flex gap="32px">
        <Box w="100%">
          <Skeleton height="35px" />
        </Box>
      </Flex>
      <Flex gap="32px">
        <Box w="100%">
          <Skeleton height="35px" />
        </Box>
      </Flex>
      <Flex gap="32px">
        <Box width="50%">
          <Skeleton height="35px" />
        </Box>
        <Box width="50%">
          <Skeleton height="35px" />
        </Box>
      </Flex>
    </Stack>
  );
}
