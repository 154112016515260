import * as Yup from 'yup';

export const SUPPRESSION_RECORD_SCHEMA = Yup.lazy(() =>
  Yup.object().shape({
    company: Yup.string().required('Company name is required.'),
    type: Yup.string().required('Type is required.'),
    domain: Yup.string().required('Domain is required.'),
    expiration_date: Yup.string().required('Expiration date is required.'),
    is_permanent: Yup.bool(),
    description: Yup.string(),
  })
);

export const suppressionTypeOption = [
  {
    label: 'General',
    value: 'General',
  },
  {
    label: 'Staffing',
    value: 'Staffing',
  },
  {
    label: 'Non-profit',
    value: 'Non-profit',
  },
  {
    label: 'Government',
    value: 'Government',
  },
  {
    label: 'Schools',
    value: 'Schools',
  },
  {
    label: 'Small Business',
    value: 'Small Business',
  },
];
