import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from './api.slice';
import { appError } from './app.slice';

export const clientAgreementSlice = createSlice({
  name: 'clientAgreements',
  initialState: {
    clientAgreements: [],
    clientAgreementData: [],
    clientAttachments: [],
    clientAgreementPage: {
      count: 0,
      currentPage: 1,
      lastPage: 1,
      nextPage: null,
      prevPage: null,
    },
    clientAgreementPgBtn: {
      page: 1,
      take: 50,
      query: '',
    },
  },
  reducers: {
    setClientAgreement: (state, { payload }) => ({ ...state, ...payload }),
    removeClientAttachment: (state, { payload }) => {
      state.clientAttachments.splice(payload, 1);
    },
  },
});

export const { setClientAgreement, removeClientAttachment } =
  clientAgreementSlice.actions;
export default clientAgreementSlice.reducer;

/* ----------------------------------- API ---------------------------------- */

export const clientAgreementApiSplice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    GetClientAgreement: builder.mutation({
      query: ({ id }) => ({
        url: `/clients-agreement/view/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            setClientAgreement({
              clientAgreementData: { ...data.data.data, ...data.data.data },
            })
          );
          dispatch(
            setClientAgreement({ clientAttachments: data.data.attachments })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),

    CreateClientAgreement: builder.mutation({
      query: ({ data, id }) => ({
        url: `/clients-agreement/create/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),

    UpdateClientAgreement: builder.mutation({
      query: ({ data, id }) => ({
        url: `/clients-agreement/update/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),

    DelClientAgreement: builder.mutation({
      query: ({ id }) => ({
        url: `/clients-agreement/delete/${id}`,
        method: 'DELETE',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),

    DelClientAgreementAttachment: builder.mutation({
      query: ({ data, id }) => ({
        url: `/clients-agreement/delete-file/${id}`,
        method: 'DELETE',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),
    ListClientAgreement: builder.mutation({
      query: ({ data, id, signal }) => ({
        url: `/clients-agreement/list/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
        signal,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setClientAgreement({ clientAgreements: data.data.data }));
          dispatch(
            setClientAgreement({
              clientAgreementPage: {
                count: data.data.count,
                currentPage: data.data.currentPage,
                lastPage: data.data.lastPage,
                nextPage: data.data.nextPage,
                prevPage: data.data.nextPage,
              },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  useCreateClientAgreementMutation,
  useDelClientAgreementAttachmentMutation,
  useDelClientAgreementMutation,
  useGetClientAgreementMutation,
  useListClientAgreementMutation,
  useUpdateClientAgreementMutation,
} = clientAgreementApiSplice;
