import * as Yup from 'yup';

export const SMS_AUTOMATION_RECORD_SCHEMA = Yup.lazy(() =>
  Yup.object().shape({
    name: Yup.string().required('Name is required'),
    trigger: Yup.string().required('Trigger is required'),
    sources: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string(),
          value: Yup.string(),
        })
      )
      .min(1, 'Pick at least 1 source'),
    send_immediately: Yup.bool(),
    schedule_details: Yup.object().when('is_immediately', {
      is: false,
      then: Yup.object().shape({
        timezone: Yup.string(),
        start_time: Yup.string(),
        end_time: Yup.string(),
      }),
      otherwise: Yup.object().shape({
        timezone: Yup.string().required('Timezone is required'),
        start_time: Yup.string().required('Start time is required'),
        end_time: Yup.string().required('End time is required'),
      }),
    }),
    message: Yup.string().required('SMS message is required'),
  })
);

export const smsAutomationTriggerOption = [
  {
    label: 'Change Status from Any Status to Left a Voicemail/SMS',
    value: 'Change Status from Any Status to Left a Voicemail/SMS',
  },
  {
    label: 'Change Status from Any Status to Contacted - Touch 2',
    value: 'Change Status from Any Status to Contacted - Touch 2',
  },
];

export const smsAutomationSourceOption = [
  {
    label: 'Added by User',
    value: 'Added by User',
  },
  {
    label: 'Advertisement',
    value: 'Advertisement',
  },
  {
    label: 'Interseller',
    value: 'Interseller',
  },
  {
    label: 'Interseller - Responded',
    value: 'Interseller - Responded',
  },
  {
    label: 'CareerSite',
    value: 'CareerSite',
  },
  {
    label: 'Cold Call',
    value: 'Cold Call',
  },
  {
    label: 'Embed',
    value: 'Embed',
  },
  {
    label: 'Linkedin Limited Listings',
    value: 'Linkedin Limited Listings',
  },
  {
    label: 'Linkedin Sales Navigator',
    value: 'Linkedin Sales Navigator',
  },
  {
    label: 'Linkedin Recruiter Job Posting',
    value: 'Linkedin Recruiter Job Posting',
  },
  {
    label: 'Imported - LI Sales Nav',
    value: 'Imported - LI Sales Nav',
  },
  {
    label: 'Employee Referral',
    value: 'Employee Referral',
  },
  {
    label: 'External Referral',
    value: 'External Referral',
  },
  {
    label: 'Job Mail Box',
    value: 'Job Mail Box',
  },
  {
    label: 'Facebook',
    value: 'Facebook',
  },
  {
    label: 'Gapps',
    value: 'Gapps',
  },
  {
    label: 'Google import',
    value: 'Google import',
  },
  {
    label: 'Import',
    value: 'Import',
  },
  {
    label: 'Imported by parser',
    value: 'Imported by parser',
  },
  {
    label: 'Internal',
    value: 'Internal',
  },
  {
    label: 'Partner',
    value: 'Partner',
  },
  {
    label: 'Quickli',
    value: 'Quickli',
  },
  {
    label: 'Resume Inbox',
    value: 'Resume Inbox',
  },
  {
    label: 'Search Engine',
    value: 'Search Engine',
  },
  {
    label: 'Twitter',
    value: 'Twitter',
  },
  {
    label: 'Imported from Zoho CRM',
    value: 'Imported from Zoho CRM',
  },
  {
    label: 'Indeed Resume',
    value: 'Indeed Resume',
  },
  {
    label: 'Vendor',
    value: 'Vendor',
  },
  {
    label: 'Careersite - Chatbot',
    value: 'Careersite - Chatbot',
  },
  {
    label: 'Calendar Booking',
    value: 'Calendar Booking',
  },
  {
    label: 'Google',
    value: 'Google',
  },
  {
    label: 'JobisJob',
    value: 'JobisJob',
  },
  {
    label: 'neuvon',
    value: 'neuvon',
  },
  {
    label: 'neuvoo',
    value: 'neuvoo',
  },
  {
    label: 'Indeed',
    value: 'Indeed',
  },
  {
    label: 'Linkedin',
    value: 'Linkedin',
  },
  {
    label: 'Swordfish',
    value: 'Swordfish',
  },
  {
    label: 'Indeed Resume via Interseller',
    value: 'Indeed Resume via Interseller',
  },
  {
    label: 'Remotive',
    value: 'Remotive',
  },
  {
    label: 'ZipRecruiter Organic',
    value: 'ZipRecruiter Organic',
  },
  {
    label: 'Monster-Organic',
    value: 'Monster-Organic',
  },
  {
    label: 'Linkedin Recruiter Inmail',
    value: 'Linkedin Recruiter Inmail',
  },
  {
    label: 'Google X-ray Search',
    value: 'Google X-ray Search',
  },
  {
    label: 'Talently Chrome Extension',
    value: 'Talently Chrome Extension',
  },
];
