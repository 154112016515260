import moment from 'moment';

export function getDuration(text: Date) {
  const startDate = moment(text); // Parse the provided time
  const endDate = moment(); // Current time
  // Calculate duration between start and end time
  const duration = moment.duration(endDate.diff(startDate));
  const days = Math.floor(duration.asDays());
  const hours = duration.hours();

  return { days, hours };
}
