import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Skeleton,
  Text,
  useDisclosure,
  Button,
  Select,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  UnorderedList,
  CloseButton,
} from '@chakra-ui/react';
import { Field, FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import FAIcon from 'components/lib/FAIcon';
import {
  useAuthRingCentralMutation,
  useGetRingCentralMutation,
  useSetRingCentralMutation,
  useSynchRingCentralMutation,
} from 'store/ringcentral.slice';
import ScrollToFieldError from 'components/app/ScrollError';

export default function RingCentral() {
  const [isEdit, setIsEdit] = useState(false);
  const [show, setShow] = useState(false);
  const [reqGet, resGet] = useGetRingCentralMutation();
  const [reqSet, resSet] = useSetRingCentralMutation();
  const [reqAuth, resAuth] = useAuthRingCentralMutation();
  const [reqSynch, resSynch] = useSynchRingCentralMutation();
  const { ringcentralData } = useSelector((state: any) => state.ringcentral);
  const [dataType, setType] = useState(ringcentralData?.type || 'password');
  const [sucMsg, setSucMsg] = useState('');
  const [errMsg, setErrMes] = useState('');
  const initialValues = {
    client_id: ringcentralData?.client_id,
    secret: ringcentralData?.secret,
    jwt: ringcentralData?.jwt,
    server_url: ringcentralData?.server_url,
    type: ringcentralData?.type,
    username: ringcentralData?.username,
    password: ringcentralData?.password,
    extension: ringcentralData?.extension,
  };

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen: openAlert,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenSuccess,
    onClose: onCloseSuccess,
    onOpen: openSuccess,
  } = useDisclosure({ defaultIsOpen: false });

  useEffect(() => {
    if (resAuth.isError) {
      const error: any = resAuth.error;
      console.log('data', error.data.error.message);
      openAlert();
      setErrMes(error.data.error.message);
    }
    if (resAuth.isSuccess) {
      openSuccess();
      setSucMsg('RingCentral Client authenticated.');
    }
  }, [resAuth.isError, resAuth.isSuccess]);

  useEffect(() => {
    if (resSynch.isError) {
      const error: any = resSynch.error;
      console.log('data', error.data.error.message);
      openAlert();
      setErrMes(error.data.error.message);
    }
    if (resSynch.isSuccess) {
      openSuccess();
      const data: any = resSynch.data;
      setSucMsg(data.data.message);
    }
  }, [resSynch.isError, resSynch.isSuccess]);

  const synchClick = () => {
    reqSynch({});
    onCloseSuccess();
  };

  const authClick = () => {
    reqAuth({});
    onCloseSuccess();
  };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      client_id: Yup.string().required('RingCentral Client ID is required.'),
      secret: Yup.string().required('RingCentral Client Secret is required.'),

      server_url: Yup.string().required('RingCentral Client Url is required.'),
      type: Yup.string().required('Grant Type is required.'),
      username: Yup.string().when('type', {
        is: 'password',
        then: Yup.string().required('RingCentral Client Username is required.'),
        otherwise: Yup.string().nullable(),
      }),

      password: Yup.string().when('type', {
        is: 'password',
        then: Yup.string().required('RingCentral Client Password is required.'),
        otherwise: Yup.string().nullable(),
      }),
      extension: Yup.number().when('type', {
        is: 'password',
        then: Yup.number().required(
          'RingCentral Client Extension is required.'
        ),
        otherwise: Yup.number().nullable(),
      }),
      jwt: Yup.string().when('type', {
        is: 'jwt',
        then: Yup.string().required('JWT Token is required.'),
        otherwise: Yup.string().nullable(),
      }),
    })
  );
  const tooglePassword = () => setShow(!show);

  useEffect(() => {
    reqGet({});
  }, [true]);

  useEffect(() => {
    if (resSet.isSuccess) {
      reqGet({});
      setIsEdit(false);
    }
  }, [resSet.isSuccess]);

  const onSubmit = (data: any) => {
    reqSet(data);
  };

  const capitalizeFirst = (str: any) => {
    try {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } catch (e) {
      return str;
    }
  };

  return (
    <Box fontFamily="NunitoSans Regular">
      <Box
        bg="white"
        boxSizing="border-box"
        border="1px solid"
        borderColor="default.white.400"
        borderRadius="md"
      >
        {isOpenSuccess && (
          <Box>
            <Alert
              status="success"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Flex gap="20px" position="relative" left="20px">
                <AlertIcon />
                <Box>
                  <AlertTitle>Success!</AlertTitle>
                  <AlertDescription>{sucMsg}</AlertDescription>
                </Box>
              </Flex>

              <CloseButton
                position="absolute"
                right="20px"
                onClick={onCloseSuccess}
              />
            </Alert>
          </Box>
        )}
        {isOpenAlert && (
          <Box>
            <Alert
              status="error"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Flex gap="20px" position="relative" left="20px">
                <AlertIcon />
                <Box>
                  <AlertTitle>Oh snap!</AlertTitle>
                  <AlertDescription>
                    {errMsg || (
                      <>
                        There were some problems in you input. Change a few
                        things up and try again.
                      </>
                    )}
                  </AlertDescription>
                </Box>
              </Flex>

              <CloseButton
                position="absolute"
                right="20px"
                onClick={onCloseAlert}
              />
            </Alert>
          </Box>
        )}
        {isEdit ? (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({
              values,
              handleSubmit,
              handleChange,
              errors,
              touched,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <ScrollToFieldError />
                <Box p="32px" mb="34px">
                  <Flex gap="32px" mb="34px">
                    <Flex width="50%" gap="32px" direction="column">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.client_id && touched.client_id
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          RingCentral Client ID
                        </FormLabel>
                        <Input
                          name="client_id"
                          type="text"
                          placeholder="RingCentral Client ID"
                          variant="outline"
                          value={values.client_id}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.client_id)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Flex width="50%" gap="32px" direction="column">
                      <FormControl
                        isInvalid={Boolean(!!errors.secret && touched.secret)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          RingCentral Client Secret
                        </FormLabel>
                        <Input
                          name="secret"
                          type="text"
                          placeholder="RingCentral Client Secret"
                          variant="outline"
                          value={values.secret}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.secret)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                  </Flex>
                  <Flex gap="32px" mb="34px">
                    <Flex width="50%" gap="32px" direction="column">
                      <FormControl
                        isInvalid={Boolean(!!errors.type && touched.type)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          RingCentral Server URL
                        </FormLabel>
                        <Input
                          name="server_url"
                          type="url"
                          placeholder="RingCentral Server URL"
                          variant="outline"
                          value={values.server_url}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.server_url)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Flex width="50%" gap="32px" direction="column">
                      <FormControl
                        isInvalid={Boolean(!!errors.type && touched.type)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Grant Type
                        </FormLabel>
                        <Select
                          name="type"
                          value={values.type}
                          onChange={(e) => {
                            setType(e.target.value);
                            setFieldValue('type', e.target.value);
                          }}
                        >
                          <option value="password">Password</option>
                          <option value="jwt">JWT Auth</option>
                        </Select>
                        <FormErrorMessage>
                          {String(errors.type)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                  </Flex>
                  {dataType == 'password' ? (
                    <>
                      <Flex gap="32px" mb="34px">
                        <Flex width="50%" gap="32px" direction="column">
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.username && touched.username
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              RingCentral client Username
                            </FormLabel>
                            <Input
                              name="username"
                              type="username"
                              placeholder="RingCentral Client Username"
                              variant="outline"
                              value={values.username}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.username)}
                            </FormErrorMessage>
                          </FormControl>
                        </Flex>
                        <Flex width="50%" gap="32px" direction="column">
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.password && touched.password
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              RingCentral client Password
                            </FormLabel>
                            <InputGroup>
                              <Input
                                name="password"
                                type={show ? 'text' : 'password'}
                                placeholder="RingCentral Server URL"
                                variant="outline"
                                value={values.password}
                                onChange={handleChange}
                              />
                              <InputRightElement>
                                <Box onClick={tooglePassword} cursor="pointer">
                                  <FAIcon
                                    iconName={show ? 'eye' : 'eye-slash'}
                                  />
                                </Box>
                              </InputRightElement>
                            </InputGroup>

                            <FormErrorMessage>
                              {String(errors.password)}
                            </FormErrorMessage>
                          </FormControl>
                        </Flex>
                      </Flex>
                      <Flex gap="32px" mb="34px">
                        <Flex width="50%" gap="32px" direction="column">
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.extension && touched.extension
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              RingCentral Client Extension
                            </FormLabel>
                            <Input
                              name="extension"
                              type="number"
                              placeholder="RingCentral Client Extension"
                              variant="outline"
                              value={values.extension}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.extension)}
                            </FormErrorMessage>
                          </FormControl>
                        </Flex>
                      </Flex>
                    </>
                  ) : (
                    <Flex gap="32px" mb="34px">
                      <FormControl
                        isInvalid={Boolean(!!errors.jwt && touched.jwt)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          RingCentral JWT Token
                        </FormLabel>
                        <Input
                          name="jwt"
                          type="text"
                          placeholder="RingCentral JWT Token"
                          variant="outline"
                          value={values.jwt}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.jwt)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                  )}
                </Box>
                <Flex gap="20px" p="32px" justifyContent="end">
                  <Button type="button" onClick={() => setIsEdit(false)}>
                    Cancel
                  </Button>
                  <Button
                    variant="solid"
                    type="submit"
                    isLoading={resSet.isLoading}
                    disabled={resSet.isLoading}
                  >
                    Update
                  </Button>
                </Flex>
              </form>
            )}
          </Formik>
        ) : (
          <>
            <Box p="32px" mb="34px">
              <Flex gap="32px" mb="34px">
                <Flex width="50%" gap="32px" direction="column">
                  <FormControl>
                    <FormLabel
                      fontSize="14px"
                      lineHeight="18px"
                      color="default.secondarytext"
                    >
                      RingCentral Client ID
                    </FormLabel>
                    {resGet.isLoading ? (
                      <Skeleton height="25px" />
                    ) : (
                      <Text as="b">{ringcentralData?.client_id}</Text>
                    )}
                  </FormControl>
                </Flex>
                <Flex width="50%" gap="32px" direction="column">
                  <FormControl>
                    <FormLabel
                      fontSize="14px"
                      lineHeight="18px"
                      color="default.secondarytext"
                    >
                      RingCentral Client Secret
                    </FormLabel>
                    {resGet.isLoading ? (
                      <Skeleton height="25px" />
                    ) : (
                      <Text as="b">{ringcentralData?.secret}</Text>
                    )}
                  </FormControl>
                </Flex>
              </Flex>

              <Flex gap="32px" mb="34px">
                <Flex width="50%" gap="32px" direction="column">
                  <FormControl>
                    <FormLabel
                      fontSize="14px"
                      lineHeight="18px"
                      color="default.secondarytext"
                    >
                      RingCentral Server URL
                    </FormLabel>
                    {resGet.isLoading ? (
                      <Skeleton height="25px" />
                    ) : (
                      <Text as="b">{ringcentralData?.server_url}</Text>
                    )}
                  </FormControl>
                </Flex>
                <Flex width="50%" gap="32px" direction="column">
                  <FormControl>
                    <FormLabel
                      fontSize="14px"
                      lineHeight="18px"
                      color="default.secondarytext"
                    >
                      RingCentral Grant Type
                    </FormLabel>
                    {resGet.isLoading ? (
                      <Skeleton height="25px" />
                    ) : (
                      <Text as="b">
                        {capitalizeFirst(ringcentralData?.type)}
                      </Text>
                    )}
                  </FormControl>
                </Flex>
              </Flex>
              {ringcentralData?.type == 'password' ? (
                <>
                  <Flex gap="32px" mb="34px">
                    <Flex width="50%" gap="32px" direction="column">
                      <FormControl>
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          RingCentral client Username
                        </FormLabel>
                        <Text as="b">{ringcentralData?.username}</Text>
                      </FormControl>
                    </Flex>
                    <Flex width="50%" gap="32px" direction="column">
                      <FormControl>
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          RingCentral client Password
                        </FormLabel>
                        <Text
                          as="b"
                          style={{
                            color: 'transparent',
                            textShadow: '0 0 8px #000',
                          }}
                        >
                          {ringcentralData?.password}
                        </Text>
                      </FormControl>
                    </Flex>
                  </Flex>
                  <Flex gap="32px" mb="34px">
                    <Flex width="50%" gap="32px" direction="column">
                      <FormControl>
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          RingCentral Client Extension
                        </FormLabel>
                        <Text as="b">{ringcentralData?.extension}</Text>
                      </FormControl>
                    </Flex>
                  </Flex>
                </>
              ) : (
                <Flex gap="32px" mb="34px">
                  <FormControl>
                    <FormLabel
                      fontSize="14px"
                      lineHeight="18px"
                      color="default.secondarytext"
                    >
                      RingCentral JWT Token
                    </FormLabel>
                    {resGet.isLoading ? (
                      <Skeleton height="25px" />
                    ) : (
                      <Text
                        as="b"
                        style={{
                          color: 'transparent',
                          textShadow: '0 0 8px #000',
                        }}
                      >
                        {ringcentralData?.jwt}
                      </Text>
                    )}
                  </FormControl>
                </Flex>
              )}
            </Box>
            <Flex gap="20px" p="32px" justifyContent="end">
              <Button
                type="button"
                isLoading={resAuth.isLoading}
                onClick={() => authClick()}
                disabled={
                  resSynch.isLoading || resAuth.isLoading || isOpenAlert
                }
              >
                Authenticate
              </Button>
              <Button
                type="button"
                onClick={() => synchClick()}
                isLoading={resSynch.isLoading}
                disabled={
                  resSynch.isLoading || resAuth.isLoading || isOpenAlert
                }
              >
                Manual Synch
              </Button>
              <Button
                variant="solid"
                type="button"
                onClick={() => setIsEdit(true)}
              >
                Edit
              </Button>
            </Flex>
          </>
        )}
      </Box>
    </Box>
  );
}
