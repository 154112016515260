import {
  Box,
  Flex,
  Icon,
  Link,
  Td,
  Tr,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Checkbox,
} from '@chakra-ui/react';
import { countInt } from 'modules/Jobs/Layout/ViewCandidatesModule/Tabs/interface';
import { useEffect, useState } from 'react';
import { BsChevronDown, BsChevronRight } from 'react-icons/bs';
import {
  CandidateData,
  candidateDataGroup,
  candidateGroups,
  rowData,
} from 'types';
import Button from 'Library/Button';
import ByJob from './ByJob';
import mapPin from '../../../../../../assets/images/mapPin.svg';
import FAIcon from 'components/lib/FAIcon';
import { useGroupRowServices } from './useGroupRowServices';
import { FollowUpModal } from 'components/app/Jobs/Modal/FollowUp';

interface props {
  openAccordionItems: any[];
  setOpenAccordionItems: (e: any) => void;
  setReload: (e: boolean) => void;
  item: candidateGroups;
  isCheckedJob: number[];
  isCheck: number[];
  handleCheck: (e: any, job_id: number) => void;
  handleCheckJob: (e: any) => void;
  isCheckWithJob: any[];
  candidateOpen: (data: any) => void;
  associateToJob: (candidate: CandidateData) => void;
  editCandidate: (candidate: CandidateData) => void;
  updateCount: (data: any) => void;
  reload: boolean;
  setTotalCandidate: (count: number) => void;
  setActiveList: (data: rowData[]) => void;
  setFirstLoad: (value: boolean) => void;
  fetchGroup: any;
  groupList: candidateGroups[];
  colCount: number;
  columns?: string[];
  setJobId: (job_id: number) => void;
}
const GroupRow = ({
  openAccordionItems,
  setOpenAccordionItems,
  setReload,
  item,
  isCheck,
  isCheckedJob,
  handleCheck,
  handleCheckJob,
  isCheckWithJob,
  candidateOpen,
  associateToJob,
  editCandidate,
  updateCount,
  reload,
  setTotalCandidate,
  setActiveList,
  setFirstLoad,
  fetchGroup,
  groupList,
  colCount,
  columns,
  setJobId,
}: props) => {
  const [counterCheck, setCounterCheck] = useState<number>(1);
  const countChecker = async (data: any) => {
    if (data.count === 0) {
      setCounterCheck(Number(data.count));
    } else {
      updateCount(data);
    }
  };
  const {
    followUpClick,
    isFollowUpOpen,
    isFollowUpAll,
    followUpParam,
    onFollowUpClose,
    setIsFollowUp,
  } = useGroupRowServices();
  return (
    counterCheck > 0 && (
      <>
        <Tr
          sx={{
            bg: '#FAF5FF',
            color: 'primary.600',
            fontSize: '12px',
            fontWeight: 700,
            textTransform: 'uppercase',
            cursor: 'pointer',
            zIndex: 0,
          }}
          _hover={{
            bg: '#f8f9fa',
            zIndex: 0,
          }}
        >
          <Td
            colSpan={2}
            sx={{
              position: 'sticky',
              left: 0,
              p: '8px 24px',
              zIndex: 0,
            }}
            _hover={{
              bg: '#f8f9fa',
              zIndex: 0,
            }}
          >
            <Flex gap="30px">
              <Checkbox
                onChange={(e: any) => {
                  handleCheckJob(e);
                }}
                isChecked={isCheckedJob.includes(item.job_id)}
                id={String(item.job_id)}
                key={item.job_id}
                colorScheme="purple"
              />
              <Flex
                alignItems="center"
                width="100%"
                gap="10px"
                onClick={() => {
                  const isOpen = openAccordionItems.includes(item.job_id);
                  if (isOpen) {
                    const accordion = openAccordionItems.filter(
                      (acc) => acc !== item.job_id
                    );
                    setOpenAccordionItems(accordion);
                  } else {
                    setOpenAccordionItems([...openAccordionItems, item.job_id]);
                  }
                  setReload(false);
                }}
              >
                {
                  <Box width="16px">
                    {openAccordionItems.includes(item.job_id) ? (
                      <Icon as={BsChevronDown} />
                    ) : (
                      <Icon as={BsChevronRight} />
                    )}
                  </Box>
                }
                <Flex
                  flexWrap="wrap"
                  sx={{
                    whiteSpace: 'initial',
                    width: '500px',
                    textTransform: 'capitalize',
                  }}
                  gap="8px"
                >
                  <Flex gap="5px">
                    <Link
                      fontSize="12px"
                      href={`/clients/my-clients/${item.client_id}/overview`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.client_name}
                    </Link>
                    -
                    <Link
                      fontSize="12px"
                      href={`/jobs/${item.job_id}/details`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.job_title}
                    </Link>
                    ({item.total})
                  </Flex>
                  {item.city &&
                    item.city != '' &&
                    item.state &&
                    item.state != '' && (
                      <Flex
                        gap="5px"
                        alignItems="center"
                        color="default.gray.1000"
                      >
                        <img
                          src={mapPin}
                          alt="Closemark Circle"
                          style={{
                            width: '14px',
                            height: '14px',
                            objectFit: 'contain',
                          }}
                        />
                        <Text>
                          {[item.city, item.state].filter(Boolean).join(', ')}
                        </Text>
                      </Flex>
                    )}
                </Flex>
              </Flex>
              <Menu preventOverflow={true}>
                <MenuButton
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  zIndex="0"
                >
                  <Button
                    htmlType="menu"
                    variant="ghost"
                    iconName="ellipsis-h"
                    iconSize="xl"
                  />
                </MenuButton>

                <MenuList
                  fontSize="sm"
                  sx={{
                    position: 'relative',
                    left: '38px',
                    zIndex: 999999,
                    opacity: 100,
                  }}
                >
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      followUpClick({
                        open: true,
                        all: false,
                        job_id: Number(item.job_id),
                      });
                    }}
                  >
                    <Flex alignItems="center" justifyContent="space-between">
                      <FAIcon iconName="envelope" />
                      <Box ml={12} cursor="pointer">
                        Follow-up
                      </Box>
                    </Flex>
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      followUpClick({
                        open: true,
                        all: true,
                        job_id: Number(item.job_id),
                      });
                    }}
                  >
                    <Flex alignItems="center" justifyContent="space-between">
                      <FAIcon iconName="envelope" />
                      <Box ml={12} cursor="pointer">
                        Follow-up for All Jobs
                      </Box>
                    </Flex>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </Td>
          <Td colSpan={7} />
        </Tr>
        {openAccordionItems.includes(item.job_id) && (
          <ByJob
            job_id={item.job_id}
            colCount={colCount}
            client_name={item.client_name}
            job_title={item.job_title}
            isCheck={isCheck}
            handleCheck={handleCheck}
            isCheckWithJob={isCheckWithJob}
            onOpen={(data: candidateDataGroup) => candidateOpen(data)}
            associateToJob={associateToJob}
            editCandidate={editCandidate}
            updateCount={(data) => countChecker(data)}
            reload={reload}
            setTotalCandidate={(count: number) => {
              setTotalCandidate(count);
            }}
            setActiveList={(data: rowData[]) => setActiveList(data)}
            setFirstLoad={(load: boolean) => setFirstLoad(load)}
            groupItem={item}
            fetchGroup={fetchGroup}
            groupList={groupList}
            columns={columns}
            setJobId={(job_id) => setJobId(job_id)}
          />
        )}
        {isFollowUpOpen && (
          <FollowUpModal
            isOpen={isFollowUpOpen}
            onClose={() => {
              onFollowUpClose();
              setIsFollowUp(false);
            }}
            isAll={isFollowUpAll}
            job_id={followUpParam.job_id}
          />
        )}
      </>
    )
  );
};
export default GroupRow;
