import { defineStyleConfig } from '@chakra-ui/styled-system';

export const Radio = defineStyleConfig({
  baseStyle: {
    control: {
      _checked: {
        borderColor: 'primary.800',
        bg: 'primary.800',
        color: 'white',
        _hover: {
          borderColor: 'primary.500',
        },
      },
      _hover: {
        borderColor: 'primary.500',
      },
    },
  },
  defaultProps: {
    colorScheme: 'purple',
  },
});
