/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
} from '@chakra-ui/react';
import AtsSelect from 'components/app/AtsSelect';
import { Field, Formik } from 'formik';
import Button from 'Library/Button';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useListJobsMutation } from 'store/jobs.slice';
import {
  useGetEmailConnectionListMutation,
  useGetEmailTemplateListMutation,
} from 'store/outreach.slice';
import { SequencesModalProps } from './types';
import { useSequenceModalServices } from './useSequenceModalServices';

const SequencesModal = ({ isEdit, isOpen, onClose }: SequencesModalProps) => {
  const params = useParams();

  const {
    jobs,
    emailConnectionPgBtn,
    emailConnectionList,
    emailTemplatePgBtn,
    emailTemplateList,
    initialValues,
    validationSchema,
    handleOnSubmit,
    isLoading,
  } = useSequenceModalServices({ isEdit, isOpen, onClose });

  const [reqGetEmailConnectionList] = useGetEmailConnectionListMutation();
  const [reqGetEmailTemplateList] = useGetEmailTemplateListMutation();
  const [reqJobs] = useListJobsMutation();

  const jobParams = {
    all: true,
    closed: false,
    draft: false,
    full: false,
    job_ads: true,
    job_assigned: true,
    open: true,
    page: 1,
    query: '',
    quickJobs: true,
    take: 100,
  };

  useEffect(() => {
    if (isOpen) {
      reqJobs(jobParams);
      reqGetEmailConnectionList(emailConnectionPgBtn);
      reqGetEmailTemplateList(emailTemplatePgBtn);
    }
  }, [params.settingTypeId]);

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={() => {
        onClose();
      }}
      size="3xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent fontFamily="NunitoSans Regular">
        <ModalHeader
          background="default.white.800"
          borderRadius="4px 4px 0"
          p="18px 32px"
          fontSize="18px"
          lineHeight="21.92px"
          textAlign="left"
        >
          Add Sequence
        </ModalHeader>
        <ModalCloseButton
          top="13px"
          right="20px"
          color="default.secondarytext"
        />
        <ModalBody borderRadius="0 0 4px 4px" p={0}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleOnSubmit}
            enableReinitialize={true}
          >
            {({
              values,
              handleSubmit,
              handleChange,
              setFieldValue,
              errors,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    border: '1px solid #EEEEEE',
                    borderRadius: '6px',
                    p: '18px 32px',
                  }}
                >
                  <Stack direction="row" spacing="8px">
                    <Stack
                      width="80%"
                      sx={{
                        border: '0.5px solid #E7E9ED',
                        borderRadius: '6px',
                        p: '16px',
                        margin: '0 auto',
                      }}
                    >
                      <Stack spacing="24px" mb="24px">
                        <FormControl>
                          <Switch
                            name="primary"
                            colorScheme="purple"
                            value={values.primary === true ? 'true' : 'false'}
                            defaultChecked={values.primary}
                            onChange={handleChange}
                          >
                            Set as Default
                          </Switch>
                        </FormControl>

                        <Stack direction="row" spacing="8px" mb="34px">
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.job_id && touched.job_id
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Job Opening{' '}
                              <Box as="span" color="caution.800">
                                *
                              </Box>
                            </FormLabel>
                            <Field
                              name="job_id"
                              options={jobs?.map((val: any) => {
                                return {
                                  value: val?.id,
                                  label: val?.title,
                                };
                              })}
                              component={AtsSelect}
                              value={
                                jobs?.filter(
                                  (val: any) => val.id === values.job_id
                                )[0]
                              }
                              placeholder="Select a Job"
                              onChange={(e: any) => {
                                setFieldValue('job_id', String(e.value));
                              }}
                            />
                            <FormErrorMessage>
                              {String(errors.job_id)}
                            </FormErrorMessage>
                          </FormControl>

                          <FormControl
                            isInvalid={Boolean(!!errors.name && touched.name)}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Sequence Name{' '}
                              <Box as="span" color="caution.800">
                                *
                              </Box>
                            </FormLabel>
                            <Input
                              id="name"
                              name="name"
                              type="text"
                              placeholder="Sequence Name"
                              variant="outline"
                              value={values.name}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.name)}
                            </FormErrorMessage>
                          </FormControl>
                        </Stack>

                        <FormControl
                          isInvalid={Boolean(
                            !!errors.sender_email && touched.sender_email
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Sender Email
                          </FormLabel>
                          <Field
                            name="sender_email"
                            options={emailConnectionList?.map((val: any) => {
                              return {
                                value: val?.id,
                                label: val?.email,
                              };
                            })}
                            component={AtsSelect}
                            value={values.sender_email}
                            placeholder="Select"
                            onChange={(e: any) => {
                              setFieldValue('sender_email', e);
                            }}
                            isMulti
                          />
                          <FormErrorMessage>
                            {String(errors.sender_email)}
                          </FormErrorMessage>
                        </FormControl>

                        <FormControl
                          isInvalid={Boolean(
                            !!errors.email_template && touched.email_template
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Email Template{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <Field
                            name="template"
                            options={emailTemplateList?.map((val: any) => {
                              return {
                                value: val?.id,
                                label: val?.title,
                              };
                            })}
                            component={AtsSelect}
                            value={
                              emailTemplateList?.filter(
                                (val: any) => val.id === values.email_template
                              )[0]
                            }
                            placeholder="Select"
                            onChange={(e: any) => {
                              setFieldValue('email_template', String(e.value));
                            }}
                          />
                          <FormErrorMessage>
                            {String(errors.email_template)}
                          </FormErrorMessage>
                        </FormControl>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>

                <ModalFooter bottom="0">
                  <Stack direction="row" spacing="10px">
                    <Button
                      variant="solid"
                      type="submit"
                      isLoading={isLoading}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Save Changes
                    </Button>
                  </Stack>
                </ModalFooter>
              </form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SequencesModal;
