import { Box, Flex } from '@chakra-ui/react';
import BusinessLayout from './Layout/Business';
import RecruitmentLayout from './Layout/Recruitment';
import EngageSideBar from '../../../components/app/Engage/SideBar';
import { useEngagePageServices } from './useEngagePageServices';
import { TAB_TYPE } from '../constant';
import { AlertModal } from 'components/app/Engage/Alert';

const EngagePage = () => {
  const { tab, setTab, hasAlert, isEmailConnectionTab } =
    useEngagePageServices();

  return (
    <Flex fontFamily="NunitoSans Regular" overflow="hidden">
      <EngageSideBar tab={tab} setTab={setTab} />

      <Box w="80%" py="24px" px={6} background="#F9FAFB">
        {!isEmailConnectionTab && hasAlert && <AlertModal />}

        <Box>
          {tab === TAB_TYPE.recruitment ? (
            <RecruitmentLayout />
          ) : (
            <BusinessLayout />
          )}
        </Box>
      </Box>
    </Flex>
  );
};

export default EngagePage;
