import Select from 'react-select';
export default function AtsSelect2(FieldProps: any) {
  return (
    <Select
      options={FieldProps.options}
      {...FieldProps.field}
      onChange={(option) => {
        FieldProps.form.setFieldValue(FieldProps.field.name, option);
        if (typeof FieldProps.onChange === 'function') {
          FieldProps.onChange(option);
        }
      }}
      isMulti={FieldProps.isMulti}
      defaultValue={FieldProps.defaultValue}
      isLoading={FieldProps.isLoading}
      placeholder={FieldProps.placeholder}
      menuIsOpen={FieldProps.menuIsOpen}
      styles={FieldProps.styles}
      onFocus={FieldProps.onFocus}
      onBlur={FieldProps.onBlur}
      onMenuClose={FieldProps.onMenuClose}
      onMenuOpen={FieldProps.onMenuOpen}
      onKeyDown={FieldProps.onKeyDown}
      onInputChange={(option) => {
        if (typeof FieldProps.onInputChange === 'function') {
          FieldProps.onInputChange(option);
        }
      }}
    />
  );
}
