export const candidateFieldOptions = [
  { label: 'first_name', value: 'first_name' },
  { label: 'last_name', value: 'last_name' },
  { label: 'primary_email', value: 'primary_email' },
  { label: 'secondary_emails', value: 'secondary_emails' },
  { label: 'job_title', value: 'job_title' },
  { label: 'current_company', value: 'current_company' },
  { label: 'profile_url', value: 'profile_url' },
  { label: 'primary_contact', value: 'primary_contact' },
  { label: 'secondary_contacts', value: 'secondary_contacts' },
  { label: 'candidate_status', value: 'candidate_status' },
  { label: 'city', value: 'city' },
  { label: 'state', value: 'state' },
  { label: 'country', value: 'country' },
  { label: 'zipcode', value: 'zipcode' },
];
