import { apiSlice } from 'store/api.slice';
import { UserListResponseInterface, UserListPayloadType } from './user.type';
import { ApiResponse } from 'services/type';

export const userQuery = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchUsers: builder.query<UserListResponseInterface[], UserListPayloadType>(
      {
        query: (data) => ({
          url: `/user/auth/list`,
          method: 'POST',
          credentials: 'include',
          withCredentials: true,
          crossorigin: true,
          body: data,
        }),
        providesTags: (result, error) =>
          result && error
            ? [
                ...result.map(
                  (data) =>
                    ({
                      type: 'User',
                      id: data.id,
                    } as const)
                ),
                { type: 'User', id: 'User List' },
              ]
            : [{ type: 'User', id: 'User List' }],
        transformResponse: (
          response: ApiResponse<UserListResponseInterface[]>
        ) => {
          return response.success ? response.data : [];
        },
      }
    ),
  }),
});

export const { useFetchUsersQuery } = userQuery;
