import moment from 'moment';

import { Box, Checkbox, Flex, Td, Tr } from '@chakra-ui/react';

interface CandidateTrInterface {
  candidate: any;
  handleCheck: any;
  isCheck: any;
  isCheckWithJob?: any;
  fromGroupByJobs?: boolean;
  key?: any;
}
const CandidateTRV2 = ({
  candidate,
  handleCheck,
  isCheck,
  isCheckWithJob = [],
  fromGroupByJobs = false,
  key = 0,
}: CandidateTrInterface) => {
  const submitted_interview_date =
    candidate?.jobs[0]?.interview_date ??
    (candidate?.jobs[0]?.interview_date || candidate?.jobs[0]?.submit_date);

  return (
    <>
      <Tr key={`candidate-tr-${key}-${candidate.id}`}>
        <Td position="sticky" left={0} bg="white">
          <Flex gap="30px">
            <Checkbox
              onChange={(e: any) => {
                !fromGroupByJobs
                  ? handleCheck(e)
                  : handleCheck(
                      e,
                      candidate.jobs[0]?.id,
                      candidate.jobs[0]?.status?.id,
                      candidate
                    );
              }}
              isChecked={
                !fromGroupByJobs
                  ? isCheck.includes(candidate.denorm_id)
                  : isCheckWithJob.some(
                      (obj: any) =>
                        obj.id === candidate.denorm_id &&
                        obj.job_id === candidate?.jobs[0]?.id
                    )
              }
              id={candidate.denorm_id.toString()}
              key={candidate.denorm_id}
              colorScheme="purple"
            />
            <Flex alignItems="center" justifyContent="space-between" gap="20px">
              <Flex
                flexDirection="column"
                justifyContent="center"
                width="200px"
                minH="60px"
              >
                <Box
                  fontWeight="bold"
                  fontSize="14px"
                  color="default.primarytext"
                  isTruncated
                  textTransform="capitalize"
                >
                  {[candidate.first_name, candidate.last_name].join(' ')}
                </Box>
                {candidate.latest_job_title && (
                  <Box
                    fontSize="14px"
                    color="default.secondarytext"
                    isTruncated
                  >
                    {candidate.latest_job_title}
                  </Box>
                )}
                {candidate.latest_employer && (
                  <Box
                    fontSize="14px"
                    color="default.secondarytext"
                    isTruncated
                  >
                    {candidate.latest_employer}
                  </Box>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Td>
        <Td>
          <Box fontSize="14px" color="default.primarytext">
            {moment.utc(submitted_interview_date).format('MM/DD/YYYY')}
          </Box>
        </Td>
      </Tr>
    </>
  );
};

export default CandidateTRV2;
