/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { tabOptions } from './constants';

export const useEmailsTabListServices = () => {
  const { settingType } = useParams();

  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    const tabIndex = tabOptions.indexOf(settingType);
    setSelectedTab(tabIndex >= 0 ? tabIndex : 0);
  }, [settingType]);

  const handleChangeTab = (index: number) => {
    setSelectedTab(index);
  };

  return { selectedTab, handleChangeTab };
};
