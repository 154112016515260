export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

export const CAREER_PAGE_URL = process.env.REACT_APP_BASE_CAREER_URL;
export const RESUME_PAGE_URL =
  process.env.REACT_APP_RESUME_URL || 'http://localhost:3001/';

export const CANDIDATE_LIST = [
  {
    value: 0,
    label: 'Candidate View 1',
    data: {
      title: 'Candidate View 1',
      module: [
        {
          criteria: 'First Name',
          condition: 'is',
          query: 'Joe',
        },
        {
          criteria: 'Last Name',
          condition: 'is empty',
          query: '${EMPTY}',
        },
      ],
      share: 'selected users',
      user: [
        {
          label: 'Admin ',
          value: 1,
        },
        {
          label: 'jimar zape',
          value: 2,
        },
      ],
      operators: [
        {
          id: 0,
          operator: 'ADD',
        },
      ],
    },
  },
  {
    value: 1,
    label: 'Candidate View 2',
    data: {
      title: 'Candidate View 2',
      module: [
        {
          criteria: 'First Name',
          condition: 'is',
          query: 'Joe',
        },
        {
          criteria: 'Last Name',
          condition: 'is empty',
          query: '${EMPTY}',
        },
        {
          criteria: 'Work Status',
          condition: 'is',
          query: 'Remote',
        },
      ],
      share: 'everyone',
      operators: [
        {
          id: 0,
          operator: 'ADD',
        },
        {
          id: 1,
          operator: 'OR',
        },
      ],
    },
  },
  { value: 2, label: 'Create New View' },
];

export const CONDITION_LIST = [
  { value: 0, label: 'is' },
  { value: 1, label: "isn't" },
  { value: 2, label: 'contains' },
  { value: 3, label: "doesn't contain" },
  { value: 4, label: 'is empty' },
  { value: 5, label: 'is not empty' },
];

export const CRITERIA_LIST = [
  {
    value: 0,
    label: 'First Name',
  },
  {
    value: 1,
    label: 'Last Name',
  },
  {
    value: 2,
    label: 'Primary Email',
  },
  {
    value: 3,
    label: 'Secondary Email',
  },
  {
    value: 4,
    label: 'LinkedIn Url',
  },
  {
    value: 5,
    label: 'Work Status',
  },
  {
    value: 6,
    label: 'Source',
  },
  {
    value: 7,
    label: 'Skills',
  },
  {
    value: 8,
    label: 'Mobile',
  },
  {
    value: 9,
    label: 'Location',
  },
  {
    value: 10,
    label: 'State',
  },
  {
    value: 11,
    label: 'City',
  },
  {
    value: 12,
    label: 'Country',
  },
  {
    value: 13,
    label: 'Years of Experience',
  },
  {
    value: 14,
    label: 'Current Job title',
  },
  {
    value: 15,
    label: 'Current Company',
  },
  {
    value: 16,
    label: 'Education - Major',
  },
  {
    value: 17,
    label: 'Tags',
  },
  {
    value: 18,
    label: 'Resume Updated On',
  },
  {
    value: 19,
    label: 'Created On',
  },
  {
    value: 20,
    label: 'Last Emailed',
  },
  {
    value: 21,
    label: 'Last Activity Date',
  },
  {
    value: 22,
    label: 'Resume Available',
  },
  {
    value: 23,
    label: 'Added to Campaign',
  },
  {
    value: 24,
    label: 'Campaign Status',
  },
  {
    value: 25,
    label: 'DNC',
  },
];
