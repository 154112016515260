import { Modal, ModalContent, ModalOverlay, Spinner } from '@chakra-ui/react';

export default function ModalLoader() {
  return (
    <>
      <Modal isOpen={true} onClose={() => {}} isCentered>
        <ModalOverlay />
        <ModalContent backgroundColor="transparent" boxShadow="none">
          <Spinner
            colorScheme={'primary'}
            mx="auto"
            size="xl"
            color="primary.800"
          />
        </ModalContent>
      </Modal>
    </>
  );
}
