import { useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
export default function AtsAsyncSelect2(FieldProps: any) {
  const { candidatePgBtn } = useSelector((state: any) => state.candidates);

  const candidatedFilterData: string | null = null
  localStorage.getItem('candidatesFilter');
  const params =
    candidatedFilterData == null
      ? candidatePgBtn
      : JSON.parse(candidatedFilterData);

  return (
    <AsyncSelect
      {...FieldProps.field}
      onChange={(option) => {
        //   FieldProps.form.setFieldValue(FieldProps.field.name, option);
        if (typeof FieldProps.onChange === 'function') {
          FieldProps.onChange(option);
        }
      }}
      defaultValue={params.defTags || FieldProps.defaultValue}
      value={FieldProps.value}
      cacheOptions={FieldProps.cacheOptions}
      defaultOptions={FieldProps.defaultOptions}
      loadOptions={FieldProps.loadOptions}
      isMulti={FieldProps.isMulti}
      placeholder={FieldProps.placeholder}
      debounceTimeout={300}
      onMenuClose={FieldProps.onMenuClose}
      styles={FieldProps.styles}
      components={FieldProps.components}
    />
  );
}
