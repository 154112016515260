/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { useToast } from '@chakra-ui/react';
import {
  useGetScheduleListMutation,
  usePostScheduleListMutation,
} from 'store/outreach.slice';

import { tabOptions } from '../../constants';
import { DEFAULT_SCHEDULE_SCHEMA } from './constants';

export const useDefaultScheduleServices = () => {
  const toast = useToast();

  const { settingType } = useParams();

  const isDefaultSchedule = settingType === tabOptions[0];

  const [reqPostScheduleList, resPostScheduleList] =
    usePostScheduleListMutation();
  const [reqGetScheduleList] = useGetScheduleListMutation();

  const { scheduleList } = useSelector((state: any) => state.outreach);

  const initialValues = {
    timezone: scheduleList.timezone || 'US/Pacific',
    monday_start: scheduleList.monday_start || '00:00',
    monday_end: scheduleList.monday_end || '00:00',
    tuesday_start: scheduleList.tuesday_start || '00:00',
    tuesday_end: scheduleList.tuesday_end || '00:00',
    wednesday_start: scheduleList.wednesday_start || '00:00',
    wednesday_end: scheduleList.wednesday_end || '00:00',
    thursday_start: scheduleList.thursday_start || '00:00',
    thursday_end: scheduleList.thursday_end || '00:00',
    friday_start: scheduleList.friday_start || '00:00',
    friday_end: scheduleList.friday_end || '00:00',
    saturday_start: scheduleList.saturday_start || '00:00',
    saturday_end: scheduleList.saturday_end || '00:00',
    sunday_start: scheduleList.sunday_start || '00:00',
    sunday_end: scheduleList.sunday_end || '00:00',
  };

  const validationSchema = DEFAULT_SCHEDULE_SCHEMA;

  const handleSubmit = (data: any) => {
    reqPostScheduleList(data);
  };

  useEffect(() => {
    if (isDefaultSchedule) {
      reqGetScheduleList({});
    }
  }, [isDefaultSchedule]);

  useEffect(() => {
    if (resPostScheduleList.isSuccess) {
      toast({
        title: 'Schedule saved.',
        description: "We've saved your schedule settings.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [resPostScheduleList.isSuccess]);

  return {
    initialValues,
    validationSchema,
    handleSubmit,
    isLoading: resPostScheduleList.isLoading,
  };
};
