import * as Yup from 'yup';

export const EMAIL_CONNECTION_SCHEMA = Yup.lazy(() =>
  Yup.object()
    .shape({
      email: Yup.string(),
      random_interval_from: Yup.number().required(),
      random_interval_to: Yup.number().required(),
      email_limit_per_day: Yup.number().required(),
      percentage_step_0: Yup.number(),
      percentage_step_1: Yup.number(),
      percentage_step_2: Yup.number(),
      percentage_step_3: Yup.number(),
      percentage_step_4: Yup.number(),
      percentage_step_5: Yup.number(),
      dns: Yup.string().required(),
      signature: Yup.string().required(),
    })
    .test(
      'totalPercentage',
      'Total percentage should not exceed 100',
      function (value) {
        const {
          percentage_step_0,
          percentage_step_1,
          percentage_step_2,
          percentage_step_3,
          percentage_step_4,
          percentage_step_5,
        } = value;
        const totalPercentage =
          (percentage_step_0 || 0) +
          (percentage_step_1 || 0) +
          (percentage_step_2 || 0) +
          (percentage_step_3 || 0) +
          (percentage_step_4 || 0) +
          (percentage_step_5 || 0);
        return totalPercentage <= 100;
      }
    )
);

export const dnsOption = [
  {
    label: 'all',
    value: 'all',
  },
  {
    label: 'google',
    value: 'google',
  },
  {
    label: 'outlook',
    value: 'outlook',
  },
  {
    label: 'mimecase',
    value: 'mimcase',
  },
  {
    label: 'others',
    value: 'others',
  },
];
