import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import ClientsOverview from '../Tabs/Overview';
import ClientsContacts from '../Tabs/Contacts';
import ClientsJobs from '../Tabs/Jobs';
import ClientsAgreements from '../Agreement';
import ClientsSettings from '../Tabs/Settings';
import ClientsActivityLog from '../Tabs/ActivityLog';

import { getClient } from 'store/client.slice';

const TABS = [
  { id: 0, title: 'overview', display: 'Overview' },
  { id: 1, title: 'contacts', display: 'Contacts' },
  { id: 2, title: 'jobs', display: 'Jobs' },
  { id: 3, title: 'agreements', display: 'Agreements' },
  { id: 4, title: 'settings', display: 'Settings' },
  { id: 5, title: 'activity-log', display: 'Activity Log' },
];
const MyClientsTabs = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { MyClients, clientData } = useSelector((state: any) => state.clients);

  const activeTab = useMemo(() => {
    const tab = TABS.find((tab) => tab.title === params.clientsSubTab);

    return tab;
  }, [params.clientsSubTab, params]);

  // useEffect(() => {
  //   if (clientID) {
  //     const getClientData = MyClients.filter(
  //       (client: any) => client.id === Number(clientID)
  //     )[0];
  //     console.log("MyClients", MyClients)
  //     console.log("getClientData", getClientData)
  //     dispatch(getClient({ clientData: getClientData }));
  //     console.log("went to my clients tabs")
  //   }
  // }, [clientID]);

  const handleRoute = (tab: number) => {
    const activeTab = TABS[tab];
    if (activeTab) {
      navigate(
        `/clients/${params.clientsTab}/${clientData?.id}/${activeTab?.title}`
      );
    }
  };
  return (
    <Tabs colorScheme="purple" index={activeTab?.id} onChange={handleRoute}>
      <TabList fontSize="md" fontWeight="bold">
        {TABS.map((tab) => (
          <Tab _focus={{ boxShadow: 'none !important' }}>{tab.display}</Tab>
        ))}
      </TabList>

      <TabPanels>
        <TabPanel>
          {activeTab?.title === 'overview' && <ClientsOverview />}
        </TabPanel>
        <TabPanel>
          {activeTab?.title === 'contacts' && <ClientsContacts />}
        </TabPanel>
        <TabPanel>{activeTab?.title === 'jobs' && <ClientsJobs />}</TabPanel>
        <TabPanel>
          {activeTab?.title === 'agreements' && <ClientsAgreements />}
        </TabPanel>
        <TabPanel>
          {activeTab?.title === 'settings' && <ClientsSettings />}
        </TabPanel>
        <TabPanel>
          {activeTab?.title === 'activity-log' && <ClientsActivityLog />}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default MyClientsTabs;
