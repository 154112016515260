import { Button, Heading, Text, VStack } from "@chakra-ui/react";
import { useEffect } from "react";
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";

const NotFoundPage = (props: {
  setIsNavBar: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  useEffect(() => {
    props.setIsNavBar(false);
  }, [props]);
  return (
    <>
      <VStack
        justify="center"
        spacing="4"
        as="section"
        mt={["20", null, "40"]}
        textAlign="center"
      >
        <Heading>404 | Page Not Found</Heading>
        <Text fontSize={{ md: "xl" }}>The requested page was not found.</Text>
        <Link to="/dashboard">
          <Button
            as="a"
            aria-label="Back to Home"
            leftIcon={<FaHome />}
            colorScheme="teal"
            size="lg"
            onClick={() => props.setIsNavBar(true)}
          >
            Back to Home
          </Button>
        </Link>
      </VStack>
    </>
  );
};

export default NotFoundPage;
