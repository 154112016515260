import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import * as React from 'react';
import Button from './Button';

interface Props {
  totalPages: number;
  currentPage: number;
  totalEntries?: number;
  targetCount?: number;
  currentCount?: number;
  onPageChange: (page: number) => void;
  onEntryChange: (entries: number) => void;
  showEntries?: boolean;
}

const Pagination: React.FC<Props> = ({
  totalPages,
  currentPage,
  totalEntries = 0,
  targetCount = 50,
  currentCount = 0,
  onPageChange,
  onEntryChange,
  showEntries = true,
}) => {
  const handlePreviousClick = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const pageClick = (page: number) => {
    currentPage = page;
    onPageChange(page);
  };

  const entryChange = (entry: number) => {
    onEntryChange(entry);
  };

  const handlePageButton = () => {
    if (totalPages > 10) {
      if (currentPage < 5) {
        // Display pages 1 to 5 and the last page
        return [
          ...Array.from(Array(5), (page, key) => (
            <Button
              key={key}
              variant={key === currentPage - 1 ? 'active' : 'ghost'}
              onClick={() => pageClick(key + 1)}
            >
              {key + 1}
            </Button>
          )),
          <span key="ellipsis1">...</span>,
          <Button
            key={totalPages}
            variant={currentPage === totalPages ? 'active' : 'ghost'}
            onClick={() => pageClick(totalPages)}
          >
            {totalPages}
          </Button>,
        ];
      } else if (currentPage > totalPages - 4) {
        // Display the first page and pages (totalPages - 4) to totalPages
        return [
          <Button
            key={1}
            variant={currentPage === 1 ? 'active' : 'ghost'}
            onClick={() => pageClick(1)}
          >
            1
          </Button>,
          <span key="ellipsis1">...</span>,
          ...Array.from(Array(5), (page, key) => (
            <Button
              key={key + (totalPages - 4)}
              variant={
                key + (totalPages - 4) === currentPage ? 'active' : 'ghost'
              }
              onClick={() => pageClick(key + (totalPages - 4))}
            >
              {key + (totalPages - 4)}
            </Button>
          )),
        ];
      } else {
        // Display the first page, pages around the current page, and the last page
        return [
          <Button
            key={1}
            variant={currentPage === 1 ? 'active' : 'ghost'}
            onClick={() => pageClick(1)}
          >
            1
          </Button>,
          <span key="ellipsis1">...</span>,
          ...Array.from(Array(5), (page, key) => (
            <Button
              key={key + (currentPage - 2)}
              variant={
                key + (currentPage - 2) === currentPage ? 'active' : 'ghost'
              }
              onClick={() => pageClick(key + (currentPage - 2))}
            >
              {key + (currentPage - 2)}
            </Button>
          )),
          <span key="ellipsis2">...</span>,
          <Button
            key={totalPages}
            variant={currentPage === totalPages ? 'active' : 'ghost'}
            onClick={() => pageClick(totalPages)}
          >
            {totalPages}
          </Button>,
        ];
      }
    } else {
      // Display all pages if totalPages is less than or equal to 10
      return Array.from(Array(totalPages), (page, key) => (
        <Button
          key={key}
          variant={key === currentPage - 1 ? 'active' : 'ghost'}
          onClick={() => pageClick(key + 1)}
        >
          {key + 1}
        </Button>
      ));
    }
  };

  return (
    <Flex
      justifyContent="space-between"
      py="20px"
      height="56px"
      bg="#FFFFFF"
      boxSizing="border-box"
      border="1px solid"
      borderColor="default.white.400"
      fontFamily="NunitoSans Regular"
    >
      {showEntries && (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          px="30px"
        >
          <Box
            fontSize="14px"
            fontWeight={700}
            textAlign="left"
            color="#6B6C7B"
          >
            Showing
          </Box>
          <Menu>
            <MenuButton
              px={4}
              py={2}
              sx={{ fontSize: '14px', fontWeight: 700, color: '#2B2D42' }}
            >
              {targetCount}
              <ChevronDownIcon />
            </MenuButton>
            <MenuList>
              <MenuItem
                _focus={{ background: 'initial' }}
                sx={{ fontSize: '14px', fontWeight: 700, color: '#2B2D42' }}
                background={
                  targetCount === 10 &&
                  'var(--chakra-colors-gray-100) !important'
                }
                onClick={() => entryChange(10)}
              >
                10
              </MenuItem>
              <MenuItem
                _focus={{ background: 'initial' }}
                sx={{ fontSize: '14px', fontWeight: 700, color: '#2B2D42' }}
                background={
                  targetCount === 25 &&
                  'var(--chakra-colors-gray-100) !important'
                }
                onClick={() => entryChange(25)}
              >
                25
              </MenuItem>
              <MenuItem
                _focus={{ background: 'initial' }}
                sx={{ fontSize: '14px', fontWeight: 700, color: '#2B2D42' }}
                background={
                  targetCount === 50 &&
                  'var(--chakra-colors-gray-100) !important'
                }
                onClick={() => entryChange(50)}
              >
                50
              </MenuItem>
              <MenuItem
                _focus={{ background: 'initial' }}
                sx={{ fontSize: '14px', fontWeight: 700, color: '#2B2D42' }}
                background={
                  targetCount === 100 &&
                  'var(--chakra-colors-gray-100) !important'
                }
                onClick={() => entryChange(100)}
              >
                100
              </MenuItem>
            </MenuList>
          </Menu>
          <Box fontSize="14px" fontWeight={700} color="#6B6C7B">
            of {totalEntries} rows
          </Box>
        </Flex>
      )}

      <Flex
        justifyContent="flex-end"
        alignItems="center"
        color="#C5C5C5"
        paddingRight="20px"
      >
        <Button
          variant="ghost"
          iconColor="#C5C5C5"
          iconName="chevron-left"
          onClick={handlePreviousClick}
          disabled={currentPage === 1}
        />
        <Flex color="#6B6C7B" fontSize="14px" alignItems="baseline">
          {handlePageButton()}
        </Flex>
        <Button
          variant="ghost"
          iconColor="#C5C5C5"
          iconName="chevron-right"
          onClick={handleNextClick}
          disabled={currentPage === totalPages}
        />
      </Flex>
    </Flex>
  );
};

export default Pagination;
