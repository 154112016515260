import { Flex, Icon, Text } from '@chakra-ui/react';
import { UnparsedListProps } from '../types';
import { BiSolidReport } from 'react-icons/bi';
import { ResumeInt } from '../../types';

const UnparsedList = ({
  handleSelect,
  resumes,
  selected,
}: UnparsedListProps) => {
  return (
    <Flex
      direction="column"
      gap="6"
      borderRight="1px"
      borderColor="default.white.400"
      h="100%"
      position="relative"
    >
      <Text
        textAlign="left"
        color="primarytext"
        fontWeight="bold"
        fontSize="18px"
      >
        Unparsed Candidate List
      </Text>
      <Flex direction="column">
        {resumes.length > 0 &&
          resumes.map((resume: ResumeInt, index: number) => {
            return (
              <Flex
                key={index}
                cursor="pointer"
                w="100%"
                gap="10px"
                py="2"
                px="6"
                fontSize="16px"
                alignItems="center"
                justifyContent="flex-start"
                bg={selected === index ? '#FAF5FF' : 'white'}
                color={selected === index ? 'primary.800' : 'secondarytext'}
                onClick={() => handleSelect(index)}
              >
                <Icon as={BiSolidReport} />
                <Text>{resume.original_file_name}</Text>
                <Flex
                  py="1"
                  px="2"
                  borderRadius="2px"
                  bg="caution.100"
                  color="caution.400"
                  fontSize="14px"
                >
                  <Text>Failed</Text>
                </Flex>
              </Flex>
            );
          })}
      </Flex>
    </Flex>
  );
};

export default UnparsedList;
