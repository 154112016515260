import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from './api.slice';
import { appError } from './app.slice';

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    activities: [],
    widgets: [],
    deals: [],
    recruitmentPipelines: [],
    topRecruiters: [],
    acquisitionSource: [],
    kanban: [],
    weeklyActivities: [],
  },
  reducers: {
    updateDashboard: (state, { payload }) => ({ ...state, ...payload }),
    updateDeals: (state, { payload }) => ({
      ...state,
      deals: { ...state.deals, ...payload },
    }),
  },
});

export const { updateDashboard, updateDeals } = dashboardSlice.actions;
export default dashboardSlice.reducer;

const dashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    ActivityList: builder.mutation({
      query: ({ data }) => ({
        url: `/dashboard/activity`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateDashboard({ activities: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostGoal: builder.mutation({
      query: ({ data }) => ({
        url: `/dashboard/set-goal`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateDeals({ goal: data.data.goal }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetDeals: builder.mutation({
      query: (data) => ({
        url: `/dashboard/deals`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateDashboard({ deals: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetWidgets: builder.mutation({
      query: () => ({
        url: `/dashboard/widgets`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateDashboard({ widgets: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetKanban: builder.mutation({
      query: () => ({
        url: `/dashboard/kanban`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateDashboard({ kanban: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetRecruitmentPipelines: builder.mutation({
      query: () => ({
        url: `/dashboard/recruitment-pipelines`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateDashboard({ recruitmentPipelines: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetTopRecruiters: builder.mutation({
      query: () => ({
        url: `/dashboard/top-recruiters`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateDashboard({ topRecruiters: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetAcquisitionSource: builder.mutation({
      query: () => ({
        url: `/dashboard/acquisition-source`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateDashboard({ acquisitionSource: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    WeeklyActivityList: builder.mutation({
      query: ({ data }) => ({
        url: `/dashboard/weekly`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateDashboard({ weeklyActivities: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    BreakDownActivityList: builder.mutation({
      query: ({ data, signal }) => ({
        url: `/dashboard/breakdown`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
        signal,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    DayBreakActivityList: builder.mutation({
      query: ({ data, signal }) => ({
        url: `/dashboard/daybreak`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
        signal,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  useActivityListMutation,
  usePostGoalMutation,
  useGetDealsMutation,
  useGetWidgetsMutation,
  useGetKanbanMutation,
  useGetRecruitmentPipelinesMutation,
  useGetTopRecruitersMutation,
  useGetAcquisitionSourceMutation,
  useWeeklyActivityListMutation,
  useBreakDownActivityListMutation,
  useDayBreakActivityListMutation,
} = dashboardApiSlice;
