import { Box, Skeleton, SkeletonCircle, Td, Tr } from '@chakra-ui/react';

export default function ResumeLoading() {
  return (
    <>
      <Box padding={10} boxShadow="lg" bg="white">
        <SkeletonCircle size="50" />
        <Skeleton mt={5} height="10px" />
        <Skeleton mt={5} height="100px" />
        <Skeleton mt={5} height="10px" />
        <Skeleton mt={5} height="10px" />
      </Box>
    </>
  );
}
