import { useNavigate } from 'react-router';

import moment from 'moment';
import {
    Box,
    Flex,
    useColorModeValue,
    Slide,
} from '@chakra-ui/react';

interface FooterNotifProps {
    isOpen: boolean;
    onClose: any;
}

function FooterNotif({ isOpen, onClose }: FooterNotifProps) {
    const navigate = useNavigate()

    // refresh
    const handleRefresh = async () => {
        navigate(0)
    }
    return (
        <Box position="fixed" zIndex="9999999" width="100vw">
            <Slide direction='bottom' in={isOpen} >
                <Flex
                    bg="primary.800"
                    color={useColorModeValue('white', 'gray.800')}
                    minH="60px"
                    bottom="0"
                    position="fixed"
                    width="100vw"
                    // px={{ base: 8 }}
                    padding="8px"
                    borderBottom={1}
                    borderStyle="solid"
                    borderColor={useColorModeValue('gray.200', 'gray.900')}
                    align="center"
                    textAlign="center"
                    justifyContent="center"
                    onClick={handleRefresh}
                    cursor="pointer"
                >
                    <p>You went offline, click <u>here</u> to reload the page</p>
                </Flex>
            </Slide>

        </Box>
    );
}
export default FooterNotif;