import { Box, Flex, Tag, Button as ChakraButton } from '@chakra-ui/react';
import Button from 'Library/Button';
import { FaRegCopy } from 'react-icons/fa';

interface props {
  count: number;
  filter: number | null;
  bulkEmail: () => void;
  bulkChangeStatus: () => void;
  bulkAssociateToJob: () => void;
  bulkReject: () => void;
  bulkTags: () => void;
  mergeCandidates: () => void;
  isRejectLoading: boolean;
  all: boolean;
}

const BulkAction = ({
  count,
  filter,
  bulkEmail,
  bulkChangeStatus,
  bulkAssociateToJob,
  bulkReject,
  bulkTags,
  isRejectLoading,
  all = false,
  mergeCandidates,
}: props) => {
  return (
    <Flex pb={6} gap={4}>
      <Tag
        display="flex"
        borderRadius="4px"
        variant="subtle"
        color="#545454"
        bg="#FAF7FF"
        fontWeight="regular"
        fontSize="md"
        border="1px solid #AE8FE2"
        height="32px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex gap={2} fontSize="14px" alignItems="center">
          <Box color="#6930CA" fontSize="sm">
            {count}
          </Box>{' '}
          {/* {isCheck.length > 1 ? 'items' : 'item'} selected */}
          {count > 1 ? 'items' : 'item'} selected
        </Flex>
      </Tag>
      <Button variant="basic" leftIcon="comment-dots" size="sm">
        Send SMS
      </Button>
      <Button variant="basic" leftIcon="envelope" size="sm" onClick={bulkEmail}>
        Send Email
      </Button>
      {!all && (
        <Button
          variant="basic"
          leftIcon="person-circle-check"
          size="sm"
          onClick={bulkChangeStatus}
        >
          Change Status
        </Button>
      )}
      <Button
        variant="basic"
        leftIcon="eye"
        size="sm"
        onClick={bulkAssociateToJob}
      >
        Associate to Job
      </Button>
      <Button variant="basic" leftIcon="tags" size="sm" onClick={bulkTags}>
        Add Tags
      </Button>
      <Button
        variant="basic"
        leftIcon="circle-xmark"
        size="sm"
        onClick={bulkReject}
        loading={isRejectLoading}
      >
        Reject
      </Button>

      {all && (
        <ChakraButton
          variant="basic"
          leftIcon={<FaRegCopy />}
          size="sm"
          onClick={mergeCandidates}
          // disabled={isCheck.length !== 2}
          disabled={count !== 2}
        >
          Merge Candidates
        </ChakraButton>
      )}
    </Flex>
  );
};

export default BulkAction;
