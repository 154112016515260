import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import {
  Flex,
  Stack,
  Image,
  Icon,
  Text,
  useToast,
  Box,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  useDisclosure,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

import ReplyIcon from 'assets/images/leads/emails/reply.svg';
import ForwardIcon from 'assets/images/leads/emails/forward.svg';
import RedoIcon from 'assets/images/leads/emails/redo.svg';

import EmailsModal from 'components/app/Global/Email/Lead';

import {
  useGetEmailsMutation,
  usePostForwardThreadMutation,
  usePostReplyThreadMutation,
} from 'store/leads.slice';
import replacePlaceholders from 'utils/textPlaceholders';

const regexFW = /FW:/i;
const regexLtr = /id="divRplyFwdMsg" dir="ltr">/;

export const LeadsEmails = () => {
  const toast = useToast();

  const { leadData, emails } = useSelector((state: any) => state.leads);
  const { contactData } = useSelector((state: any) => state.contacts);

  const [isAccordion, setIsAccordion] = useState({ id: null, value: false });
  const [emailData, setEmailData] = useState<any>({});
  const [isSendVia, setIsSendVia] = useState('reply');

  const [reqGetEmails] = useGetEmailsMutation();
  const [reqPostReplyEmails, resPostReplyEmails] = usePostReplyThreadMutation();
  const [reqPostForwardEmails, resPostForwardEmails] =
    usePostForwardThreadMutation();

  useEffect(() => {
    if (emails && isAccordion.value) {
      const res = emails?.filter(
        (email: any) => email.id === isAccordion.id
      )[0];
      setEmailData(res);
    } else {
      setEmailData({});
    }
  }, [emails, isAccordion]);

  const {
    isOpen: isOpenEmail,
    onOpen: onOpenEmail,
    onClose: onCloseEmail,
  } = useDisclosure();

  const { placehoderPair } = useSelector((state: any) => state.template);

  const [placehoder, setPlaceHoders] = useState(placehoderPair);

  const placholders = (text: string) => {
    return replacePlaceholders(text, placehoder);
  };

  const forwardedMessageDiv = `
  <div style="display: flex; gap: 8px; align-items: center; margin-bottom: 10px">
    <img
      src="${RedoIcon}" height="24px" width="24px" alt="forward"
    />
    <span style="font-weight: 700;">Forwarded message</span>
  </div>
`;

  const handleEmail = (values: any) => {
    let cc: any = [];
    if (values?.cc && values?.cc?.length > 0) {
      values?.cc?.forEach((item: any) => {
        cc.push(item.value);
      });
    }
    let bcc: any = [];
    if (values?.bcc && values?.bcc?.length > 0) {
      values?.bcc?.forEach((item: any) => {
        bcc.push(item.value);
      });
    }

    if (isSendVia === 'reply') {
      const payload = {
        cc: cc,
        bcc: bcc,
        content: placholders(values?.body?.replace(/<div><\/div>/g, '<br />'))
          ?.replace(/<p>/g, '<div>')
          ?.replace(/<\/p>/g, '</div>'),
      };
      reqPostReplyEmails({ id: isAccordion.id, data: payload });
    } else {
      const payload = {
        cc: cc,
        bcc: bcc,
        to: values?.to,
        content: placholders(values?.body?.replace(/<div><\/div>/g, '<br />'))
          ?.replace(/<p>/g, '<div>')
          ?.replace(/<\/p>/g, '</div>'),
      };
      reqPostForwardEmails({ id: isAccordion.id, data: payload });
    }
  };

  useEffect(() => {
    setPlaceHoders(placehoderPair);
  }, [placehoderPair]);

  useEffect(() => {
    if (resPostReplyEmails.isSuccess) {
      onCloseEmail();
      reqGetEmails({ id: leadData.id || contactData.id });
    }

    if (resPostReplyEmails.isSuccess) {
      toast({
        title: 'Email Sent!',
        description: 'Successfully reply an email.',
        status: 'success',
        isClosable: true,
        duration: 3000,
        position: 'top',
      });
    }
  }, [resPostReplyEmails.isSuccess]);

  useEffect(() => {
    if (resPostForwardEmails.isSuccess) {
      onCloseEmail();
      reqGetEmails({ id: leadData.id || contactData.id });
    }

    if (resPostForwardEmails.isSuccess) {
      toast({
        title: 'Email Forwarded!',
        description: 'Successfully forwarded an email.',
        status: 'success',
        isClosable: true,
        duration: 3000,
        position: 'top',
      });
    }
  }, [resPostForwardEmails.isSuccess]);

  return (
    <Flex
      justifyContent="space-between"
      sx={{
        '& ::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Box
        sx={{
          overflowY: 'scroll',
          w: '30%',
          h: '70vh',
          borderRight: '1px solid #EEEEEE',
          p: '16px 8px 16px 0',
        }}
      >
        {emails?.map((email: any) => {
          const isOpenProfile =
            email.id === isAccordion.id && isAccordion.value === true;

          return (
            <Box
              sx={{
                border: isOpenProfile ? '1px solid #6930CA' : 'none',
                borderRadius: '4px',
              }}
              _hover={{
                bgColor: !isOpenProfile && '#FAF5FF',
              }}
            >
              <Stack
                sx={{
                  p: '8px',
                  borderBottom: '1px solid #EEEEEE',
                  bgColor: isOpenProfile && '#FAF5FF',
                  borderRadius: '4px 4px 0 0',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (email.id === isAccordion.id) {
                    setIsAccordion({ id: email.id, value: !isAccordion.value });
                  } else {
                    setIsAccordion({ id: email.id, value: isAccordion.value });
                  }
                }}
              >
                <Stack
                  spacing="8px"
                  sx={{
                    fontSize: '14px',
                    color: '#2B2D42',
                  }}
                >
                  <Flex justifyContent="space-between">
                    <Flex gap="4px">
                      <Icon
                        as={ChevronDownIcon}
                        fontSize="24px"
                        transition="all .25s ease-in-out"
                        transform={!isOpenProfile ? 'rotate(-90deg)' : ''}
                        color={isOpenProfile ? '#6930CA' : '#718096'}
                      />
                      <Text
                        sx={{
                          fontWeight: 600,
                          maxW: !isOpenProfile && '200px',
                        }}
                      >
                        {email.from.name}
                      </Text>
                    </Flex>

                    {!isOpenProfile && (
                      <Text
                        sx={{
                          fontSize: '12px',
                          color: '#2B2D42',
                          textAlign: 'right',
                        }}
                      >
                        {moment(email.thread[0].date)
                          .tz('America/Los_Angeles')
                          .format('ddd, MMM DD, YYYY hh:mmA')}{' '}
                        PST
                      </Text>
                    )}
                  </Flex>
                  <Stack
                    spacing="4px"
                    sx={{
                      pl: '28px',
                    }}
                  >
                    <Text
                      sx={{
                        fontWeight: 600,
                      }}
                    >
                      {email.subject}
                    </Text>
                    {!isOpenProfile && (
                      <>
                        <Box
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: 'initial',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: email.thread[0].preview,
                          }}
                        />
                      </>
                    )}
                  </Stack>
                </Stack>
              </Stack>
              {isOpenProfile && (
                <Box>
                  {email.thread.length > 0 &&
                    email.thread?.map((thread: any) => {
                      const isForwardedSubject = regexFW.test(thread?.subject);

                      return (
                        <Stack
                          sx={{
                            borderRadius: '4px',
                            borderBottom: '1px solid #EEEEEE',
                            p: '8px 8px',
                            pl: '36px',
                            color: '#2B2D42',
                            fontSize: '14px',
                            cursor: 'pointer',
                          }}
                        >
                          <Flex justifyContent="space-between">
                            <Text sx={{ fontWeight: 700 }}>
                              {thread?.from.name}
                            </Text>
                            <Text sx={{ fontSize: '12px', color: '#2B2D42' }}>
                              {moment(thread.date)
                                .tz('America/Los_Angeles')
                                .format('ddd, MMM DD, YYYY hh:mmA')}{' '}
                              PST
                            </Text>
                          </Flex>
                          <Box
                            sx={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              width: 'initial',
                            }}
                            dangerouslySetInnerHTML={{ __html: thread.preview }}
                          />
                          {isForwardedSubject && (
                            <div
                              style={{
                                display: 'flex',
                                gap: '8px',
                                alignItems: 'center',
                              }}
                            >
                              <img
                                src={RedoIcon}
                                height="24px"
                                width="24px"
                                alt="forward"
                              />
                              <span style={{ fontWeight: '700' }}>
                                Forwarded message
                              </span>
                            </div>
                          )}
                        </Stack>
                      );
                    })}
                </Box>
              )}
            </Box>
          );
        })}
      </Box>

      <Stack spacing="8px" sx={{ p: '16px 0 16px 8px', w: '70%', h: '70vh' }}>
        {emailData?.thread ? (
          <Box
            sx={{
              overflowY: 'scroll',
            }}
          >
            <Text
              sx={{
                p: '12px 24px',
                borderBottom: '1px solid #EEEEEE',
                fontSize: '14px',
                fontWeight: 600,
                color: '#2B2D42',
              }}
            >
              {emailData?.subject || ''}
            </Text>
            <Stack spacing="8px" sx={{ overflowY: 'scroll' }}>
              {emailData.thread?.length > 0 &&
                emailData.thread?.map((thread: any) => {
                  const isForwardedSubject = regexFW.test(thread?.subject);
                  const updatedContent = isForwardedSubject
                    ? thread.body.replace(regexLtr, (match: any) => {
                        return `${match}${forwardedMessageDiv}`;
                      })
                    : thread.body;

                  return (
                    <Stack
                      sx={{
                        borderRadius: '4px',
                        border: '1px solid #EEEEEE',
                        p: '16px 24px',
                        color: '#2B2D42',
                        fontSize: '14px',
                        cursor: 'pointer',
                      }}
                    >
                      <Stack spacing="4px">
                        <Flex justifyContent="space-between">
                          <Text sx={{ fontWeight: 700, maxW: '300px' }}>
                            {thread?.from?.name}{' '}
                            <Text as="span" sx={{ color: '#718096' }}>
                              {thread?.from?.email}
                            </Text>
                          </Text>

                          <Flex gap="16px" alignItems="center">
                            <Image
                              src={ReplyIcon}
                              h="16px"
                              w="16px"
                              alt="reply"
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                onOpenEmail();
                                setIsSendVia('reply');
                              }}
                            />
                            <Image
                              src={ForwardIcon}
                              h="16px"
                              w="16px"
                              alt="forward"
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                onOpenEmail();
                                setIsSendVia('forward');
                              }}
                            />
                            <Text sx={{ fontSize: '12px', color: '#9B9B9B' }}>
                              {moment(thread?.date)
                                .tz('America/Los_Angeles')
                                .format('ddd, MMM DD, YYYY hh:mmA')}{' '}
                              PST
                            </Text>
                          </Flex>
                        </Flex>

                        <Flex gap="4px" alignItems="center">
                          <Text sx={{ fontWeight: 700 }}>To:</Text>
                          <Text sx={{ color: '#718096' }}>
                            {thread?.to[0]?.email}
                          </Text>
                          <Menu>
                            {({ isOpen }) => (
                              <>
                                <MenuButton
                                  variant="borderless"
                                  isActive={isOpen}
                                  as={Button}
                                  rightIcon={<ChevronDownIcon />}
                                  sx={{
                                    display: 'block',
                                    h: 'fit-content',
                                    minW: 'fit-content',
                                    p: '4px 5px',
                                    color: '#718096',
                                    span: {
                                      m: 0,
                                    },
                                  }}
                                />
                                <MenuList w="500px" _focus={{}}>
                                  <MenuItem _focus={{}}>
                                    <Stack spacing={0}>
                                      <Flex gap="10px" sx={{ p: '10px' }}>
                                        <Text sx={{ fontWeight: 700 }}>
                                          To:
                                        </Text>
                                        <Text sx={{ color: '#718096' }}>
                                          {`${thread?.to[0]?.name} <${thread?.to[0]?.email}>`}
                                        </Text>
                                      </Flex>
                                      {thread.cc?.length > 0 && (
                                        <Flex gap="10px" sx={{ p: '10px' }}>
                                          <Text sx={{ fontWeight: 700 }}>
                                            Cc:
                                          </Text>
                                          <Text sx={{ color: '#718096' }}>
                                            {thread.cc
                                              .map(
                                                (cc: any) =>
                                                  `${cc.name} <${cc.email}>`
                                              )
                                              .join(', ')}
                                          </Text>
                                        </Flex>
                                      )}
                                      {thread.bcc?.length > 0 && (
                                        <Flex gap="10px" sx={{ p: '10px' }}>
                                          <Text sx={{ fontWeight: 700 }}>
                                            Bcc:
                                          </Text>
                                          <Text sx={{ color: '#718096' }}>
                                            {thread.bcc
                                              .map(
                                                (bcc: any) =>
                                                  `${bcc.name} <${bcc.email}>`
                                              )
                                              .join(', ')}
                                          </Text>
                                        </Flex>
                                      )}
                                    </Stack>
                                  </MenuItem>
                                </MenuList>
                              </>
                            )}
                          </Menu>
                        </Flex>
                      </Stack>
                      <Box
                        dangerouslySetInnerHTML={{ __html: updatedContent }}
                      />
                      {/* {isForwardedSubject && (
                        <div
                          style={{
                            display: 'flex',
                            gap: '8px',
                            alignItems: 'center',
                          }}
                        >
                          <img
                            src={RedoIcon}
                            height="24px"
                            width="24px"
                            alt="forward"
                          />
                          <span style={{ fontWeight: '700' }}>
                            Forwarded message
                          </span>
                        </div>
                      )} */}
                    </Stack>
                  );
                })}
            </Stack>
          </Box>
        ) : (
          <Stack
            sx={{ alignItems: 'center', justifyContent: 'center', h: '70vh' }}
          >
            <Text sx={{ fontWeight: '700' }}>
              Please select an item to read
            </Text>
          </Stack>
        )}

        {isOpenEmail && (
          <EmailsModal
            isOpen={isOpenEmail}
            onClose={() => {
              onCloseEmail();
            }}
            id={contactData?.id || leadData?.id}
            modalTitle={isSendVia === 'reply' ? 'Repy Email' : 'Forward Email'}
            email={contactData?.primary_email || leadData?.primary_email}
            subject={emailData.subject}
            isSubjectDisabled={isSendVia === 'reply'}
            allowedCategory={['Client', 'Contact', 'Signatures']}
            onCallback={(e: any) => handleEmail(e)}
          />
        )}
      </Stack>
    </Flex>
  );
};
