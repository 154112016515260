import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AIGeneratorCompose } from './Compose';
import { AIGeneratorGenerate } from './Generate';
import { AIGeneratorRewrite } from './Rewrite';

import {
  updateAI,
  usePostGeneratePromptMutation,
  usePostRegeneratePromptMutation,
} from 'store/ai.slice';
import { ErrorPage } from './Error';
import { AI_LIST } from './Rewrite/constants';

interface useAIGeneratorServicesProps {
  hasType: string;
}
export const useAIGeneratorServices = ({
  hasType,
}: useAIGeneratorServicesProps) => {
  const dispatch = useDispatch();
  const { aiList } = useSelector((state: any) => state.ai);

  const [reqPostGeneratePrompt, resPostGeneratePrompt] =
    usePostGeneratePromptMutation();
  const [reqPostRegeneratePrompt, resPostRegeneratePrompt] =
    usePostRegeneratePromptMutation();

  const [hasAIGenerate, setHasAIGenerate] = useState('');
  const [hasTone, setHasTone] = useState(null);
  const [hasPrompt, setHasPrompt] = useState(null);
  const [hasSubject, setHasSubject] = useState(null);
  const [hasBody, setHasBody] = useState(null);
  const [hasFunc, setHasFunc] = useState(null);
  const [isGenerate, setIsGenerate] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isMaximize, setIsMaximize] = useState(false);

  const handleValues = (subject: string, body: string) => {
    setHasSubject(subject);
    setHasBody(body);
  };

  const handleGenerate = useCallback(() => {
    const fetch = () => {
      dispatch(updateAI({ aiList: AI_LIST }));

      setIsGenerate(true);
      const payload = {
        subject: hasSubject,
        body: hasBody,
        tone: hasTone,
        prompt: hasPrompt,
        func: hasFunc,
        type: hasType,
      };

      reqPostGeneratePrompt(payload);
    };
    if (hasAIGenerate === 'compose' && hasPrompt) {
      fetch();
    }
    if (hasAIGenerate === 'rewrite') {
      fetch();
    }
    if (isError) {
      fetch();
    }
  }, [
    isError,
    hasPrompt,
    hasSubject,
    hasTone,
    hasPrompt,
    hasFunc,
    hasAIGenerate,
  ]);

  const handleRegenerate = useCallback(
    async ({ index, field }: any) => {
      if (hasPrompt) {
        const payload = {
          content: aiList,
          index: index,
          field: field,
          tone: hasTone,
          prompt: hasPrompt,
          type: hasType,
        };
        await reqPostRegeneratePrompt(payload);
      }
    },
    [aiList, hasTone, hasPrompt]
  );

  const handleInsert = () => {
    setHasAIGenerate('');
    setHasTone('');
    setHasPrompt('');
    setIsGenerate(false);
  };

  const handleAIGenerator = (setFieldValue: any) => {
    if (isError) {
      return (
        <ErrorPage
          handleGenerate={handleGenerate}
          isLoading={
            resPostGeneratePrompt?.isLoading ||
            resPostRegeneratePrompt?.isLoading
          }
        />
      );
    }

    if (isGenerate) {
      return (
        <AIGeneratorGenerate
          setIsGenerate={setIsGenerate}
          setHasPrompt={setHasPrompt}
          handleRegenerate={handleRegenerate}
          handleInsert={handleInsert}
          setFieldValue={setFieldValue}
          isRegenerated={resPostRegeneratePrompt?.isSuccess}
          isLoadingList={resPostGeneratePrompt?.isLoading}
          isLoading={resPostRegeneratePrompt?.isLoading}
          isMaximize={isMaximize}
          setIsMaximize={setIsMaximize}
        />
      );
    }

    if (hasAIGenerate === 'rewrite') {
      setHasFunc('rewrite');
      return (
        <AIGeneratorRewrite
          hasTone={hasTone}
          setHasTone={setHasTone}
          hasPrompt={hasPrompt}
          setHasPrompt={setHasPrompt}
          setHasAIGenerate={setHasAIGenerate}
          setIsGenerate={setIsGenerate}
          handleGenerate={handleGenerate}
          isMaximize={isMaximize}
          setIsMaximize={setIsMaximize}
        />
      );
    } else if (hasAIGenerate === 'compose') {
      setHasFunc('compose');
      return (
        <AIGeneratorCompose
          hasPrompt={hasPrompt}
          setHasPrompt={setHasPrompt}
          setHasAIGenerate={setHasAIGenerate}
          setHasTone={setHasTone}
          setIsGenerate={setIsGenerate}
          handleGenerate={handleGenerate}
          isMaximize={isMaximize}
          setIsMaximize={setIsMaximize}
        />
      );
    }
    return '';
  };

  useEffect(() => {
    if (
      resPostGeneratePrompt.isError ||
      resPostRegeneratePrompt.isError ||
      !aiList
    ) {
      setIsError(true);
    }
  }, [resPostGeneratePrompt.isError, resPostRegeneratePrompt.isError, aiList]);

  useEffect(() => {
    if (resPostGeneratePrompt.isSuccess) {
      if (aiList.length === 0) {
        setIsError(true);
      } else {
        setIsError(false);
      }
    }
  }, [resPostGeneratePrompt.isSuccess, aiList]);

  return {
    hasAIGenerate,
    setHasAIGenerate,
    hasTone,
    setHasTone,
    hasPrompt,
    setHasPrompt,
    hasSubject,
    setHasSubject,
    hasBody,
    setHasBody,
    isGenerate,
    setIsGenerate,
    handleValues,
    handleInsert,
    handleAIGenerator,
    setIsError,
    isMaximize,
    setIsMaximize,
  };
};
