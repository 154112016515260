import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import Button from 'Library/Button';
import EmailTemplateModal from './Modal';
import EmailTemplateTable from './Table';
import { useEmailTemplateTableServices } from './Table/useEmailTemplateTableServices';

const EmailTemplate = () => {
  const {
    isOpen: isOpenEmailTemplateModal,
    onOpen: onOpenEmailTemplateModal,
    onClose: onCloseEmailTemplateModal,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    rowId,
    emailTemplateData,
    totalTemplateList,
    handlePrevData,
    handleNextData,
    handleSendEmail,
    isLoading,
    initialValueEmail,
    validationSchemaEmail,
    emailTemplateList,
    emailTemplatePgBtn,
    isCheck,
    isIndeterminate,
    isCheckAll,
    handleCheck,
    handleSelectAll,
    handleChangePrimary,
    handleBulkDeleteEmailTemplate,
    handleSyncPrimary,
    emailTemplatePage,
    pageChange,
    entryChange,
  } = useEmailTemplateTableServices();

  return (
    <Box fontFamily="NunitoSans Regular">
      <Flex justifyContent="flex-end" mt="20px" mb="32px" px={1}>
        <Flex>
          <Button
            leftIcon="plus"
            variant="solid"
            onClick={onOpenEmailTemplateModal}
          >
            Add Email Template
          </Button>
        </Flex>
      </Flex>

      <EmailTemplateTable
        emailTemplateList={emailTemplateList}
        emailTemplatePgBtn={emailTemplatePgBtn}
        isCheck={isCheck}
        isIndeterminate={isIndeterminate}
        isCheckAll={isCheckAll}
        handleCheck={handleCheck}
        handleSelectAll={handleSelectAll}
        handleChangePrimary={handleChangePrimary}
        handleBulkDeleteEmailTemplate={handleBulkDeleteEmailTemplate}
        handleSyncPrimary={handleSyncPrimary}
        rowId={rowId}
        emailTemplateData={emailTemplateData}
        totalTemplateList={totalTemplateList}
        handlePrevData={handlePrevData}
        handleNextData={handleNextData}
        handleSendEmail={handleSendEmail}
        isLoading={isLoading}
        initialValueEmail={initialValueEmail}
        validationSchemaEmail={validationSchemaEmail}
        emailTemplatePage={emailTemplatePage}
        pageChange={pageChange}
        entryChange={entryChange}
      />

      {isOpenEmailTemplateModal && (
        <EmailTemplateModal
          isOpen={isOpenEmailTemplateModal}
          onClose={onCloseEmailTemplateModal}
        />
      )}
    </Box>
  );
};

export default EmailTemplate;
