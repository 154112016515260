import {
  Box,
  Flex,
  Icon,
  Tabs,
  TabList,
  Tab,
  TabPanels,
} from '@chakra-ui/react';
import { useState } from 'react';

import { ChevronLeftIcon } from '@chakra-ui/icons';
import EmailTemplates from './EmailTemplates/template';
import MsGraph from '../MsGraph';
import Users from './Users';
import RingCentral from './RingCentral';
import EmailSignatures from './EmailSignatures';
import ScheduleInterview from './ScheduleInterview';
import Teams from './Teams';
import useSettingsServices from './useSettingsServices';

export default function Settings() {
  const tabListIndex: any = [
    { id: 0, title: 'Users' },
    { id: 1, title: 'Teams' },
    { id: 2, title: 'MS Graph' },
    { id: 3, title: 'Email Template' },
    { id: 4, title: 'Email Signatures' },
    { id: 5, title: 'RingCentral' },
  ];

  const [tabIndex, setTabIndex] = useState(tabListIndex[0]);

  const {} = useSettingsServices({ setTabIndex });

  return (
    <Box fontFamily="NunitoSans Regular" h="100vh">
      <Tabs colorScheme="purple" variant="unstyled" index={tabIndex.id}>
        <Flex gap={2} background="#F9F9FB">
          <Box
            width="20%"
            pb={4}
            overflow="auto"
            borderRight="1px solid"
            borderColor="transparent"
            px={6}
          >
            <TabList flexDirection="column" alignItems="flex-start" gap="8px">
              {tabListIndex.map((tab: any, key: number) => {
                return (
                  <Tab
                    key={`tablist01-${key}`}
                    justifyContent="space-between"
                    width="100%"
                    border="0.5px solid rgba(155, 155, 155, 0.5)"
                    borderRadius="3px"
                    background="#fff"
                    p="12px 20px"
                    lineHeight="18px"
                    fontSize="14px"
                    fontWeight="600"
                    _selected={{
                      bg: '#EFE5FF',
                      border: '0.5px solid #EFE5FF',
                    }}
                    onClick={() => setTabIndex(tabListIndex[tab.id])}
                  >
                    {tab.title}
                    <Icon
                      as={ChevronLeftIcon}
                      transition="all .25s ease-in-out"
                      transform="rotate(180deg)"
                      w={6}
                      h={6}
                      ml="8px"
                      color={tabIndex.id === tab.id ? 'primary.800' : '#fff'}
                    />
                  </Tab>
                );
              })}
            </TabList>
          </Box>

          <Box width="80%">
            <TabPanels>
              {tabIndex.id === 0 && <Users />}
              {tabIndex.id === 1 && <Teams />}
              {tabIndex.id === 2 && <MsGraph />}
              {tabIndex.id === 3 && <EmailTemplates />}
              {tabIndex.id === 4 && <EmailSignatures />}
              {tabIndex.id === 5 && <RingCentral />}
            </TabPanels>
          </Box>
        </Flex>
      </Tabs>
    </Box>
  );
}
