import { Flex, Text, Link, Box } from '@chakra-ui/react';
import StreamAttachmentComponent from 'components/app/Candidates/stream';
import PDFViewer from 'components/app/PDFViewer';
import FAIcon from 'components/lib/FAIcon';
import { ResumeViewerProps } from '../types';

export default function ResumeViewer({
  resumeData,
  resumeUrl,
  isPdf,
  setResumeUrl,
}: ResumeViewerProps) {
  return (
    <>
      <Flex alignItems="flex-start" justifyContent="space-between">
        <Text
          textAlign="left"
          color="primarytext"
          fontWeight="bold"
          fontSize="18px"
        >
          Resume
        </Text>
        {resumeUrl !== '' && (
          <Link
            target="_blank"
            sx={{
              display: 'flex',
              gap: '5px',
              fontSize: '15px',
              color: 'primary.600',
            }}
            rel="noreferrer"
            onClick={() => {
              const link = document.createElement('a');
              const explode = resumeData?.file_name.split('.');
              const extension = explode.splice(-1)[0];
              link.download = resumeData?.original_file_name;
              if (extension === 'docx' || extension === 'doc') {
                link.download = link.download + '.' + extension;
              }
              link.href = resumeUrl;
              link.target = '_blank';
              link.click();
            }}
          >
            <span>
              <FAIcon iconName="download" />
            </span>
            Download
          </Link>
        )}
      </Flex>
      <Box textAlign="center">
        {isPdf ? (
          <PDFViewer
            pdfUrl={resumeData?.signedUrl}
            setResumeUrl={setResumeUrl}
            height="60vh"
          />
        ) : (
          <StreamAttachmentComponent
            filename={resumeData?.file_name}
            fileUrl={resumeData?.signedUrl}
            setResumeUrl={setResumeUrl}
            pdfHeight="60vh"
            docHeight="63vh"
          />
        )}
      </Box>
    </>
  );
}
