import { Box, Tag, TagLabel } from '@chakra-ui/react';
interface LeadStatusInteface {
  status: string;
  reason?: string;
}
export default function LeadStatus({
  status,
  reason = '',
}: LeadStatusInteface) {
  const label: any = [];
  label['Active'] = [];
  label['Active']['bg'] = '#D9F1E0';
  label['Active']['color'] = '#00A331';

  label['Approved'] = [];
  label['Approved']['bg'] = '#D9F1E0';
  label['Approved']['color'] = '#00A331';

  label['Draft'] = [];
  label['Draft']['bg'] = '#D9E7F2';
  label['Draft']['color'] = '#0061AB';

  label['Approval Pending'] = [];
  label['Approval Pending']['bg'] = '#F9ECDA';
  label['Approval Pending']['color'] = '#D68309';

  label['Rejected'] = [];
  label['Rejected']['bg'] = '#CB2C2C1A';
  label['Rejected']['color'] = '#CB2C2C';

  label['Inactive'] = [];
  label['Inactive']['bg'] = '#DFE0E3B2';
  label['Inactive']['color'] = '#6B6C7B';

  label['Replied'] = [];
  label['Replied']['bg'] = '#D9F1E0';
  label['Replied']['color'] = '#38B17A';

  label['Opened'] = [];
  label['Opened']['bg'] = '#F9ECDA';
  label['Opened']['color'] = '#D68309';

  label['Contacted'] = [];
  label['Contacted']['bg'] = '#D9E7F2';
  label['Contacted']['color'] = '#3182CE';

  label['Not Interested'] = [];
  label['Not Interested']['bg'] = '#FAEAEA';
  label['Not Interested']['color'] = '##CB2C2C';

  label['Out of Office'] = [];
  label['Out of Office']['bg'] = '#E7EDF4';
  label['Out of Office']['color'] = '#718096';

  label['Not the right person'] = [];
  label['Not the right person']['bg'] = '#E7EDF4';
  label['Not the right person']['color'] = '#718096';

  return (
    <Box>
      <Tag
        borderRadius="2px"
        variant="subtle"
        color={label[status] ? label[status].color : ''}
        bg={label[status] ? label[status].bg : ''}
        fontWeight="regular"
        fontSize="md"
      >
        <TagLabel>{status}</TagLabel>
      </Tag>
      {status === 'Rejected' ? (
        <Box fontSize="sm" color="#9B9B9B" whiteSpace="pre-line">
          {reason}
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
}
