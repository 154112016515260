export const status = Object.freeze({
  unchecked: 0,
  checked: 1,
  indeterminate: -1,
});

export const CANDIDATE_STATUS_ALL = [
  {
    id: 0,
    stat_id: null as any,
    status: 1,
    name: 'All',
    items: [
      {
        id: 1,
        stat_id: 8,
        status: 1,
        name: 'Blasted',
        number_order: 2,
      },
      {
        id: 2,
        stat_id: 2,
        status: 1,
        name: 'Screen',
        number_order: 1,
        items: [
          {
            id: 3,
            stat_id: 1,
            status: 1,
            status_id: 2,
            name: 'New',
            target: 'other',
          },
          {
            id: 4,
            stat_id: 2,
            status: 1,
            status_id: 2,
            name: 'Left a Voicemail/SMS',
            target: 'other',
          },
          {
            id: 5,
            stat_id: 4,
            status: 1,
            status_id: 2,
            name: 'Submit to AM',
            target: 'other',
          },
          {
            id: 6,
            stat_id: 13,
            status: 1,
            status_id: 2,
            name: 'Keep',
            target: 'other',
          },
          {
            id: 7,
            stat_id: 17,
            status: 1,
            status_id: 2,
            name: 'Emailed',
            target: 'other',
          },
          {
            id: 8,
            stat_id: 18,
            status: 1,
            status_id: 2,
            name: 'Contact',
            target: 'other',
          },
        ],
      },
      {
        id: 9,
        stat_id: 1,
        status: 1,
        name: 'Submits',
        number_order: 2,
      },
      {
        id: 10,
        stat_id: 3,
        status: 1,
        name: 'Interview',
        number_order: 3,
      },
      {
        id: 11,
        stat_id: 4,
        status: 1,
        name: 'Offers',
        number_order: 4,
      },
      {
        id: 12,
        stat_id: 5,
        status: 1,
        name: 'Hired',
        number_order: 5,
        items: [
          {
            id: 13,
            stat_id: 8,
            status: 1,
            status_id: 5,
            name: 'Hired',
            target: 'other',
          },
          {
            id: 14,
            stat_id: 9,
            status: 1,
            status_id: 5,
            name: 'Started',
            target: 'other',
          },
        ],
      },
      {
        id: 15,
        stat_id: 6,
        status: 1,
        name: 'Rejected',
        number_order: 6,
        items: [
          {
            id: 16,
            stat_id: 11,
            status: 1,
            status_id: 6,
            name: 'Cancelled Placement',
            target: 'hired',
          },
          {
            id: 17,
            stat_id: 12,
            status: 1,
            status_id: 6,
            name: 'Fall-Off',
            target: 'hired',
          },
          {
            id: 18,
            stat_id: 14,
            status: 1,
            status_id: 6,
            name: 'Recruiter Reject',
            target: 'other',
          },
          {
            id: 19,
            stat_id: 15,
            status: 1,
            status_id: 6,
            name: 'Client Reject',
            target: 'other',
          },
          {
            id: 20,
            stat_id: 16,
            status: 1,
            status_id: 6,
            name: 'Candidate Not Interested',
            target: 'other',
          },
        ],
      },
    ],
  },
];

export const CANDIDATE_STATUS_SCREEN = [
  {
    id: 0,
    stat_id: null as any,
    status: 1,
    name: 'All',
    items: [
      {
        id: 2,
        stat_id: 2,
        status: -1,
        name: 'Screen',
        number_order: 1,
        items: [
          {
            id: 3,
            stat_id: 1,
            status: 1,
            status_id: 2,
            name: 'New',
            target: 'other',
          },
          {
            id: 4,
            stat_id: 2,
            status: 0,
            status_id: 2,
            name: 'Left a Voicemail/SMS',
            target: 'other',
          },
          {
            id: 5,
            stat_id: 4,
            status: 0,
            status_id: 2,
            name: 'Submit to AM',
            target: 'other',
          },
          {
            id: 6,
            stat_id: 13,
            status: 0,
            status_id: 2,
            name: 'Keep',
            target: 'other',
          },
          {
            id: 7,
            stat_id: 17,
            status: 0,
            status_id: 2,
            name: 'Emailed',
            target: 'other',
          },
          {
            id: 8,
            stat_id: 18,
            status: 0,
            status_id: 2,
            name: 'Contact',
            target: 'other',
          },
        ],
      },
    ],
  },
];

export const CANDIDATE_STATUS_HIRED = [
  {
    id: 0,
    stat_id: null as any,
    status: 1,
    name: 'All',
    items: [
      {
        id: 12,
        stat_id: 5,
        status: 1,
        name: 'Hired',
        number_order: 5,
        items: [
          {
            id: 13,
            stat_id: 8,
            status: 1,
            status_id: 5,
            name: 'Hired',
            target: 'other',
          },
          {
            id: 14,
            stat_id: 9,
            status: 1,
            status_id: 5,
            name: 'Started',
            target: 'other',
          },
        ],
      },
    ],
  },
];

export const CANDIDATE_STATUS_REJECTED = [
  {
    id: 0,
    stat_id: null as any,
    status: 1,
    name: 'All',
    items: [
      {
        id: 15,
        stat_id: 6,
        status: 1,
        name: 'Rejected',
        number_order: 6,
        items: [
          {
            id: 16,
            stat_id: 11,
            status: 1,
            status_id: 6,
            name: 'Cancelled Placement',
            target: 'hired',
          },
          {
            id: 17,
            stat_id: 12,
            status: 1,
            status_id: 6,
            name: 'Fall-Off',
            target: 'hired',
          },
          {
            id: 18,
            stat_id: 14,
            status: 1,
            status_id: 6,
            name: 'Recruiter Reject',
            target: 'other',
          },
          {
            id: 19,
            stat_id: 15,
            status: 1,
            status_id: 6,
            name: 'Client Reject',
            target: 'other',
          },
          {
            id: 20,
            stat_id: 16,
            status: 1,
            status_id: 6,
            name: 'Candidate Not Interested',
            target: 'other',
          },
        ],
      },
    ],
  },
];
