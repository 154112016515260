import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from './api.slice';
import { appError } from './app.slice';

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState: {
    contactList: [],
    contactData: {},
    contactPage: {
      count: 0,
      currentPage: 1,
      lastPage: 1,
      nextPage: null,
      prevPage: null,
    },
    contactPageBtn: {
      page: 1,
      take: 50,
      query: '',
      types: [4],
      status: [2],
      filters: ['is_active'],
      contacts: false,
    },
    contactTabPageBtn: {
      page: 1,
      take: 50,
      query: '',
      types: [],
      status: [],
      contacts: false,
      active: 1,
    },
  },
  reducers: {
    listContact: (state, { payload }) => ({ ...state, ...payload }),
    getContact: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { listContact, getContact } = contactsSlice.actions;
export default contactsSlice.reducer;

const clientApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    ContactList: builder.mutation({
      query: ({ data, signal }) => ({
        url: '/clients/contacts',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
        signal,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listContact({ contactList: data.data.data }));
          dispatch(
            listContact({
              contactPage: {
                count: data.data.count,
                currentPage: data.data.currentPage,
                lastPage: data.data.lastPage,
                nextPage: data.data.nextPage,
                prevPage: data.data.nextPage,
              },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ViewContact: builder.mutation({
      query: ({ data, id }) => ({
        url: `/clients/contact/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getContact({ contactList: data.data.data }));
          dispatch(
            listContact({
              contactPage: {
                count: data.data.count,
                currentPage: data.data.currentPage,
                lastPage: data.data.lastPage,
                nextPage: data.data.nextPage,
                prevPage: data.data.nextPage,
              },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    UpdateContact: builder.mutation({
      query: ({ data, id }) => ({
        url: `/contacts/update/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getContact({ contactData: { ...data.data } }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    SendEmailContact: builder.mutation({
      query: ({ data, id }) => ({
        url: `/clients/send-email/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  useContactListMutation,
  useViewContactMutation,
  useUpdateContactMutation,
  useSendEmailContactMutation,
} = clientApiSlice;
