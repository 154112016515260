import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Link,
} from '@chakra-ui/react';
import ResumeForm from './Layout/Form/Resume';
import UnparsedList from './Layout/UnparsedList';
import { ManualResumeProps } from './types';
import { useManualResumeServices } from './useManualResumeServices';

const ManualResumeDrawer = ({ isOpen, onClose, job_id }: ManualResumeProps) => {
  const {
    handleClose,
    handleSelect,
    selected,
    drawerJobDetails,
    resumes,
    reqUnparsedList,
  } = useManualResumeServices({ isOpen, onClose, job_id });
  return (
    <Drawer onClose={handleClose} isOpen={isOpen} size="full">
      <DrawerOverlay top="60px" />
      <DrawerContent
        fontFamily="NunitoSans Regular"
        top="62px! important"
        position="absolute"
      >
        <DrawerCloseButton
          position="absolute"
          top="0px"
          right="0"
          borderRadius="unset"
          p="20px"
          color="default.secondarytext"
          _hover={{
            backgroundColor: 'none',
          }}
        />
        <Box
          py="19px"
          px="20px"
          bg="default.white.800"
          as="span"
          sx={{ fontSize: '20px' }}
          maxH="10vh"
        >
          <Link
            href={drawerJobDetails.jobLink}
            target="_blank"
            rel="noreferrer"
            sx={{ fontSize: '20px' }}
          >
            {drawerJobDetails.jobTitle}
          </Link>{' '}
          -{' '}
          <Link
            href={drawerJobDetails.clientLink}
            target="_blank"
            rel="noreferrer"
            sx={{ fontSize: '20px' }}
          >
            {drawerJobDetails.clientTitle}
          </Link>
        </Box>

        <DrawerBody
          py="19px"
          px="20px"
          overflow="hidden"
          position="relative"
          height="100%"
        >
          <Flex gap="8" h="100%" position="relative">
            <UnparsedList
              resumes={resumes}
              handleSelect={handleSelect}
              selected={selected}
            />
            <ResumeForm
              resumeData={resumes[selected]}
              handleSelect={handleSelect}
              selected={selected}
              resumesLength={resumes.length}
              onClose={onClose}
              job_id={job_id}
              reqUnparsedList={reqUnparsedList}
            />
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
export default ManualResumeDrawer;
