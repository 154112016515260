import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';

import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
} from '@chakra-ui/react';

import Button from 'Library/Button';
import FAIcon from '../../../lib/FAIcon';

import ScrollToFieldError from '../../ScrollError';
import LoadingPage from '../../Loading';
import UserTable from './UserTable';
import {
  getUser,
  useDeleteUserMutation,
  useUserListPaginateMutation,
} from 'store/user.slice';
import UserModal from './Modal';
import { swalContent, UserTypes } from 'types';
import { AtsConfirm } from 'utils/swal';

export default function Users() {
  const initialValue = {
    search: '',
  };
  const { userList } = useSelector((state: any) => state.user);
  const [reqUser, resUser] = useUserListPaginateMutation();
  const { UserPgBtn } = useSelector((state: any) => state.user);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userData, setUserData] = useState(null);
  const [delUser, resDelUser] = useDeleteUserMutation();

  const handleEditClick = async (id: number) => {
    const user = userList.filter((x: UserTypes) => x.id === Number(id))[0];
    setUserData(user);
    onOpen();
  };

  useEffect(() => {
    reqUser(UserPgBtn);
  }, [UserPgBtn, reqUser, resDelUser.isSuccess]);

  const onSubmit = (data: any) => {
    // let params = {
    //   page: 1,
    //   take: approvalPgBtn.take,
    //   query: data.search,
    //   status: data.status,
    //   types: data.types,
    // };
    // submitForm(params);
  };

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      //   let params = {
      // page: 1,
      // take: approvalPgBtn.take,
      // query: '',
      // status: approvalPgBtn.status,
      // types: approvalPgBtn.types,
      //   };
      //   dispatch(
      //     listApprovals({
      //       approvalPgBtn: params,
      //     })
      //   );
      //   reqApprovals(params);
    }
  };

  const onDelete = async (user: any) => {
    console.log({ user });
    const content: swalContent = {
      title: 'Are you sure?',
      text: `You are about to delete user: ${user.first_name} ${user.last_name}`,
      buttons: ['Cancel', 'Delete!'],
      icon: 'info',
    };

    const confirm = await AtsConfirm(content);
    if (confirm) {
      await delUser({ id: user.id });
    }
  };

  return (
    <Box fontFamily="NunitoSans Regular">
      <Flex justifyContent="space-between" px={6}>
        <Formik
          enableReinitialize
          initialValues={initialValue}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, handleSubmit }) => (
            <Flex
              justifyContent="space-around"
              maxWidth="40%"
              alignItems="center"
            >
              <Box pr={2}>
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />
                  <InputGroup background="#fff">
                    <InputRightElement
                      cursor="pointer"
                      children={<FAIcon iconName="search" />}
                    />
                    <Input
                      type="search"
                      placeholder="Search User"
                      value={values.search}
                      id="search"
                      onChange={(e) => {
                        handleChange(e);
                        clearSearch(e);
                      }}
                    />
                  </InputGroup>
                </form>
              </Box>
            </Flex>
          )}
        </Formik>
        <Button
          leftIcon="plus"
          variant="solid"
          onClick={() => {
            onOpen();
            setUserData(null);
          }}
        >
          Add New User
        </Button>
      </Flex>
      <Box>
        {resUser.isLoading && <LoadingPage />}
        <UserTable
          handleEditClick={(id) => handleEditClick(id)}
          onDelete={(id) => onDelete(id)}
        />
        {isOpen && (
          <UserModal isOpen={isOpen} onClose={onClose} userData={userData} />
        )}
      </Box>
    </Box>
  );
}
