// React hooks
import moment from 'moment';
import { useRef, useState, useEffect, useMemo } from 'react';

// Redux hooks
import { useDispatch, useSelector } from 'react-redux';

// React Router hooks
import { useParams } from 'react-router';

// Redux slices
import { updateApp } from 'store/app.slice';
import {
  useListCandidateFilterMutation,
  usePinListCandidateFilterMutation,
} from 'store/candidatefilter.slice';
import { useListCandidatesMutation } from 'store/candidates.slice';
import { useViewClientMutation } from 'store/client.slice';
import { useGetClientAgreementMutation } from 'store/clientagreement.slice';
import { useGetCandidateSourceMutation } from 'store/constant.slice';
import {
  useCreateJobPostPlacementMutation,
  useUpdateJobPostPlacementMutation,
} from 'store/jobs.slice';
import { useUserListMutation } from 'store/user.slice';

// Validation library
import * as Yup from 'yup';

interface useHiredModalServices {
  jobId: number;
  candidate: any;
  onCloseStatus: () => void;
  onClose: () => void;
  onCallback: () => void;
  isEdit?: boolean;
  logs?: any;
}

export const useHiredModalServices = ({
  jobId,
  candidate,
  onCloseStatus,
  onClose,
  onCallback,
  isEdit,
  logs,
}: useHiredModalServices) => {
  const dispatch = useDispatch();
  const params = useParams();

  const { clientAgreementData, clientAgreements, clientAttachments } =
    useSelector((state: any) => state.clientAgreements);
  const { jobData } = useSelector((state: any) => state.jobs);
  const { candidateData, candidatePgBtn } = useSelector(
    (state: any) => state.candidates
  );
  const { userList } = useSelector((state: any) => state.user);
  const { email } = useSelector((state: any) => state.auth);
  const { clientData } = useSelector((state: any) => state.clients);
  const { lead_source } = useSelector((state: any) => state.constants);

  const [reqClient, resClient] = useViewClientMutation();
  const [reqCandSource, resCandSource] = useGetCandidateSourceMutation();
  const [reqUsers, resUsers] = useUserListMutation();
  const [reqCreateForm, resCreateForm] = useCreateJobPostPlacementMutation();
  const [reqUpdateForm, resUpdateForm] = useUpdateJobPostPlacementMutation();

  const [reqFilterList] = useListCandidateFilterMutation();
  const [reqPinList] = usePinListCandidateFilterMutation();
  const [reqCandidates] = useListCandidatesMutation();

  const [reqget] = useGetClientAgreementMutation();

  const abortFilterControllerRef = useRef<AbortController | null>(null);
  const fetchPin = async () => {
    if (abortFilterControllerRef.current) {
      abortFilterControllerRef.current.abort();
    }

    abortFilterControllerRef.current = new AbortController();
    await reqPinList({
      signal: abortFilterControllerRef.current.signal,
    });
  };

  const [recOptions, setRecruiters] = useState([]);
  const initCcOption = Object.values(userList).map((item: any) => {
    return {
      label: [item.first_name, item.last_name].filter(Boolean).join(' '),
      value: item.email,
    };
  });
  const [ccOption, setCcOption] = useState<any>(initCcOption);

  const [leadSourceList, setLeadSourceList] = useState([]);
  const [candSourceList, setCandSourceList] = useState([]);

  const [isSplit, setIsSplit] = useState(false);
  const [jobDetails, setJobDetails] = useState<any>(null);

  const [fileData, setFileData] = useState(null);
  const [locationOption, setLocationOption] = useState([]);

  useEffect(() => {
    const setLocation = async () => {
      let options: any = [];
      const address = clientData.address;

      if (address !== undefined) {
        if (address?.length > 0) {
          await Promise.all(
            address.map(async (item: any) => {
              options.push({
                label: [
                  item.address,
                  item.city,
                  item.state,
                  item.zip_code,
                  item.country?.country,
                ]
                  .filter((addressPart) => addressPart !== '')
                  .join(', '),
                value: item.id,
                data: item,
              });
            })
          );
        }
      }
      setLocationOption(options);
    };
    if (clientData.address) {
      setLocation();
    }
  }, [clientData.address]);

  useEffect(() => {
    if (clientData?.agreements?.length || clientAgreementData.id) {
      reqget({
        id:
          clientData?.agreements[clientData?.agreements?.length - 1]?.id ||
          clientAgreementData.id,
      });
    }
  }, [clientData?.agreements, clientAgreementData.id]);

  useEffect(() => {
    reqUsers({});
    const job_id = jobId || Number(params.jobsId) || jobData?.id;
    const candidateJob =
      candidate?.jobs?.filter((job: any) => job.job_id === job_id)[0]?.job
        ?.client_id || jobData?.client_id;
    setJobDetails(
      candidate?.jobs[0]?.job
        ? candidate?.jobs?.filter((job: any) => job.job_id === job_id)[0]
        : jobData
    );
    if (candidateJob) {
      reqClient({ id: candidateJob });
    }
  }, [jobId, jobData?.id]);

  useMemo(() => {
    let options: any = [];
    if (lead_source?.length > 0) {
      lead_source.map((item: any) => {
        options.push({
          label: item.source,
          value: item.id,
        });
      });
    }
    setLeadSourceList(options);
  }, [lead_source]);

  useMemo(() => {
    reqCandSource({}).then((res: any) => {
      let option: any = [];
      if (res?.data?.data?.length > 0) {
        res?.data?.data.map((item: any) => {
          option.push({
            label: String(item.source),
            value: item.id,
            sub: item.sub,
          });
        });
      }

      setCandSourceList(option);
    });
  }, []);

  useEffect(() => {
    let users: any = [];
    if (resUsers.isSuccess) {
      if (resUsers.data?.data.length > 0) {
        Promise.all(
          resUsers.data?.data.map((item: any) => {
            users.push({
              label: [item.first_name, item.last_name].join(' '),
              value: item.id,
            });
          })
        );
      }
    }
    setRecruiters(users);
  }, [resUsers.data?.data, resUsers.isSuccess]);

  const primaryEmails = candidate?.emails?.filter((item: any) => item.primary);
  const clientSortAgreementData = [...clientAgreements]?.sort(
    (a: any, b: any) => {
      return (
        new Date(a?.date_signed).getTime() - new Date(b?.date_signed).getTime()
      );
    }
  )?.[0];

  console.log({ jobData, clientData, candidateData }, 'xxxx');
  const initPlacementForm = logs
    ? {
        candidate_id: logs.candidate_id,
        candidate_name: logs.candidate_name,
        candidate_email: logs.candidate_email,
        candidate_source_id: logs.candidate_source_id,
        job_id: logs.job_id,
        job_title: logs.job?.title,
        job_start_date: moment(logs.start_date).format('YYYY-MM-DD'),
        job_site_location_id: logs.location_id,
        job_work_type_id: logs.work_type_id,
        compensation_base_salary: logs.base_salary,
        compensation_fee_percentage: logs.fee,
        compensation_payable_days: logs.payable_days,
        compensation_guarantee_days: logs.guarantee_days,
        compensation_sign_on_bonus: logs.sign_on_bonus,
        compensation_discount_given: logs.fee_discount,
        compensation_total_fee_to_invoice: logs.total_fee_invoice,
        invoice_contact_id: logs.contact_id,
        invoice_company_name: logs.invoice_name,
        invoice_payment_due_date: moment(logs.payment_due_date).format(
          'YYYY-MM-DD'
        ),
        invoice_company_location_id: logs.invoice_location_id,
        recruiters: logs.recruiters.map((val: any) => {
          return {
            name: '',
            split_percentage: null as any,
            recruiter_id: val.recruiter_id,
            split: String(val.split),
          };
        }),
        client_source_id: logs.client_source_id,
        split_recruiter: logs.split_recruiter === false ? 'N' : 'Y',
        lifetime_value_client: logs.lifetime_value_client,
        notes: logs.notes,
        invoice_cc: ccOption?.filter((entry: any) =>
          logs.invoice_cc.split(',').includes(entry.value)
        ),
      }
    : {
        candidate_id: candidateData.id,
        candidate_name: candidate
          ? [candidate?.first_name, candidate?.last_name].join(' ')
          : [candidateData?.first_name, candidateData?.last_name].join(' '),
        candidate_email:
          primaryEmails?.length > 0 ? primaryEmails?.[0]?.email : '',
        candidate_source_id: candidateData?.source_id,
        job_id: params.jobsTab
          ? Number(params.jobsId)
          : jobDetails?.job_id || jobDetails?.id || jobData.id,
        job_title: jobData?.title || '',
        job_start_date: '',
        job_site_location_id: jobData.data.location.id,
        job_work_type_id: candidateData.jobs[0].job.work_type_id,
        compensation_base_salary: 0,
        compensation_fee_percentage:
          Number(clientSortAgreementData?.fee_pct) || (null as any),
        compensation_payable_days:
          Number(clientSortAgreementData?.payable) || (null as any),
        compensation_guarantee_days:
          Number(clientSortAgreementData?.guarantee) || (null as any),
        compensation_sign_on_bonus: 0,
        compensation_discount_given: 0,
        compensation_total_fee_to_invoice: 0,
        invoice_contact_id: jobData.data.lead.id,
        invoice_company_name: jobData.data.lead.company,
        invoice_payment_due_date: '',
        invoice_company_location_id: jobData.data.location.id,
        recruiters: [] as any,
        client_source_id: jobData.lead.lead_source_id,
        split_recruiter: 'N',
        lifetime_value_client: 0,
        notes: '',
        invoice_cc: ccOption?.filter(
          (entry: any) =>
            entry.value === 'roy@talently.com' || entry.value === email
        ),
      };
  const [initialValues, setInitialValues] = useState<any>(initPlacementForm);
  const formRef = useRef(null);
  const [company, setCompany] = useState<any>(null);

  useEffect(() => {
    let params = {} as any;
    if (jobData) {
      params = {
        ...params,
        job_title: logs?.job?.title || jobData?.title || '',
      };
    }
    if (candidateData) {
      params = {
        ...params,
        candidate_name: [
          candidateData?.first_name,
          candidateData?.last_name,
        ].join(' '),
        candidate_email:
          candidateData?.emails?.filter((item: any) => item.primary)[0]
            ?.email || '',
        job_work_type_id: candidateData.jobs[0].job.work_type_id,
      };
    }
    console.log('params ', params);
    setInitialValues({
      ...initialValues,
      ...params,
    });
  }, [jobData, candidateData]);

  useEffect(() => {
    if (resClient.isSuccess) {
      const clientData = resClient.data.data;
      setCompany(clientData);
      setInitialValues({
        ...initialValues,
        invoice_company_name: jobData.data.lead.company,
        compensation_fee_percentage: Number(
          clientData?.agreements?.[0]?.fee_pct
        ),
        compensation_payable_days: clientData?.agreements?.[0]?.payable,
        compensation_guarantee_days: clientData?.agreements?.[0]?.guarantee,
      });
      if (clientData) {
        let options: any = [];
        let optionsEmail: any = [];
        // await Promise.all(
        clientData.leads.map(async (item: any) => {
          options.push({
            label: [item.first_name, item.last_name].filter(Boolean).join(' '),
            value: String(item.id),
          });
          optionsEmail.push({
            label: [item.first_name, item.last_name].filter(Boolean).join(' '),
            value: item.primary_email,
          });
        });

        setInvoiceNameOption(options);
      }
    }
  }, [resClient.isSuccess]);

  useEffect(() => {
    let options: any = [];
    let optionsEmail: any = [];
    // await Promise.all(
    clientData?.leads?.map(async (item: any) => {
      options.push({
        label: [item.first_name, item.last_name].filter(Boolean).join(' '),
        value: String(item.id),
      });
      optionsEmail.push({
        label: [item.first_name, item.last_name].filter(Boolean).join(' '),
        value: item.primary_email,
      });
    });

    setInvoiceNameOption(options);
    setCcOption([...ccOption, ...optionsEmail]);
  }, [clientData?.leads]);

  useEffect(() => {
    if (clientData?.agreements || clientAgreements) {
      setInitialValues((prev: any) => ({
        ...prev,
        compensation_fee_percentage: clientData?.agreements?.[0]?.fee_pct,
        compensation_payable_days: clientData?.agreements?.[0]?.payable,
        compensation_guarantee_days: clientData?.agreements?.[0]?.guarantee,
      }));
    }
  }, [clientData?.agreements || clientAgreements]);

  useEffect(() => {
    if (jobData?.title) {
      setInitialValues({
        ...initialValues,
        job_title: jobData?.title,
      });
    }
  }, [jobData?.title]);

  // useEffect(() => {
  //   if (jobData?.lead?.lead_source?.source) {
  //     setInitialValues({
  //       ...initialValues,
  //       client_source_id: jobData?.lead?.lead_source?.source,
  //     });
  //   }
  // }, [jobData?.lead?.lead_source?.source]);

  // useEffect(() => {
  //   if (candidateData?.source?.source) {
  //     setInitialValues({
  //       ...initialValues,
  //       candidate_source_id: candidateData?.source?.source,
  //     });
  //   }
  // }, [candidateData?.source?.source]);

  const validationSchema = Yup.object().shape({
    candidate_id: Yup.number().required('Candidate ID is required'),
    candidate_name: Yup.string().required('Candidate name is required'),
    candidate_email: Yup.string()
      .email('Invalid email format')
      .required('Candidate email is required'),

    job_id: Yup.number().required('Job ID is required'),
    job_title: Yup.string().required('Job title is required'),
    job_start_date: Yup.date().required('Job start date is required'),
    job_work_type_id: Yup.number().required('Job work type ID is required'),
    job_site_location_id: Yup.array()
      .min(1, 'Pick at least 1 location')
      .nullable(),

    compensation_base_salary: Yup.string().required('Base salary is required'),
    compensation_fee_percentage: Yup.number().required(
      'Fee percentage is required'
    ),
    compensation_payable_days: Yup.number().required(
      'Payable days is required'
    ),
    compensation_guarantee_days: Yup.number().required(
      'Guarantee days is required'
    ),
    compensation_sign_on_bonus: Yup.string().required(
      'Sign-on bonus is required'
    ),
    compensation_discount_given: Yup.string().required(
      'Discount given is required'
    ),
    compensation_total_fee_to_invoice: Yup.string().required(
      'Total fee to invoice is required'
    ),

    invoice_contact_id: Yup.array()
      .min(1, 'Pick at least 1 contact')
      .nullable(),
    invoice_company_name: Yup.string().required(
      'Invoice company name is required'
    ),
    invoice_payment_due_date: Yup.date().required(
      'Invoice payment due date is required'
    ),
    invoice_company_location_id: Yup.array()
      .min(1, 'Pick at least 1 location')
      .nullable(),
    invoice_cc: Yup.array(),

    split_recruiter: Yup.string(),
    ...(isSplit && {
      recruiters: Yup.array().of(
        Yup.object().shape({
          recruiter_id: Yup.number().required('Recruiter is required'),
          split: Yup.number()
            .required('Split percentage is required')
            .nullable(),
        })
      ),
    }),
    client_source_id: Yup.string().required('Client source ID is required'),
    candidate_source_id: Yup.string().required(
      'Candidate source ID is required'
    ),
    lifetime_value_client: Yup.string(),
    notes: Yup.string(),
  });

  useEffect(() => {
    if (resCreateForm.isSuccess) {
      onCloseStatus();
      onClose();
      onCallback();
      dispatch(updateApp({ isCongrats: true }));
      setTimeout(() => {
        reqCandidates({ data: candidatePgBtn });
        reqFilterList({});
        fetchPin();
      }, 500);
    }
  }, [resCreateForm.isSuccess]);

  useEffect(() => {
    if (resUpdateForm.isSuccess) {
      onCloseStatus();
      onClose();
      onCallback();
    }
  }, [resUpdateForm.isSuccess]);

  const [invoiceNameOption, setInvoiceNameOption] = useState([]);

  const handleSubmit = async (data: any) => {
    data.agreements_id =
      clientData?.agreements[clientData?.agreements?.length - 1]?.id;
    data.attachment_id = clientAttachments[clientAttachments.length - 1].id;

    data.compensation_base_salary =
      typeof data?.compensation_base_salary === 'string'
        ? Number(data?.compensation_base_salary.replace(/,/g, ''))
        : data?.compensation_base_salary;

    data.compensation_discount_given =
      typeof data?.compensation_discount_given === 'string'
        ? Number(data?.compensation_discount_given.replace(/,/g, ''))
        : data?.compensation_discount_given;

    data.compensation_sign_on_bonus =
      typeof data?.compensation_sign_on_bonus === 'string'
        ? Number(data?.compensation_sign_on_bonus.replace(/,/g, ''))
        : data?.compensation_sign_on_bonus;

    data.compensation_fee_percentage =
      typeof data?.compensation_fee_percentage === 'string'
        ? Number(data?.compensation_fee_percentage)
        : data?.compensation_fee_percentage;

    data.compensation_total_fee_to_invoice =
      typeof data?.compensation_total_fee_to_invoice === 'string'
        ? Number(data?.compensation_total_fee_to_invoice.replace(/,/g, ''))
        : data?.compensation_total_fee_to_invoice;

    data.invoice_company_location_id =
      data.invoice_company_location_id?.value ||
      data.invoice_company_location_id;
    data.invoice_contact_id = data.invoice_contact_id;
    data.job_site_location_id =
      data.job_site_location_id?.value || data.job_site_location_id;
    data.invoice_cc = data.invoice_cc.map((val: any) => val.value);

    data.lifetime_value_client =
      typeof data?.lifetime_value_client === 'string'
        ? Number(data?.lifetime_value_client.replace(/,/g, ''))
        : data?.lifetime_value_client;

    data.job_work_type_id = Number(data.job_work_type_id);

    data.split_recruiter = data.split_recruiter === 'Y' ? true : false;
    console.log(data, 'xx submit');

    data.recruiters = data.recruiters || [];

    if (isEdit) {
      await reqUpdateForm({ id: logs?.id, data: data });
    } else {
      await reqCreateForm(data);
    }
  };

  return {
    formRef,
    initialValues,
    handleSubmit,
    validationSchema,
    company,
    invoiceNameOption,
    setIsSplit,
    recOptions,
    leadSourceList,
    candSourceList,
    isLoading: resCandSource.isLoading,
    clientAgreements,
    fileData,
    setFileData,
    locationOption,
    ccOption,
  };
};
