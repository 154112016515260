import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from './api.slice';
import { appError } from './app.slice';

export const leadAgreementSlice = createSlice({
  name: 'leadAgreements',
  initialState: {
    agreements: [],
    agreementData: [],
    attachments: [],
    leadAgreementPage: {
      count: 0,
      currentPage: 1,
      lastPage: 1,
      nextPage: null,
      prevPage: null,
    },
    leadAgreementPgBtn: {
      page: 1,
      take: 50,
      query: '',
    },
  },
  reducers: {
    getAgreement: (state, { payload }) => ({ ...state, ...payload }),
    removeAttachment: (state, { payload }) => {
      state.attachments.splice(payload, 1);
    },
  },
});

export const { getAgreement, removeAttachment } = leadAgreementSlice.actions;
export default leadAgreementSlice.reducer;

/* ----------------------------------- API ---------------------------------- */

export const leadAgreementApiSplice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    GetAgreement: builder.mutation({
      query: ({ id }) => ({
        url: `/lead-agreement/view/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getAgreement({ agreementData: data.data.data }));
          dispatch(getAgreement({ attachments: data.data.attachments }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),

    CreateAgreement: builder.mutation({
      query: ({ data, id }) => ({
        url: `/lead-agreement/create/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),

    UpdateAgreement: builder.mutation({
      query: ({ data, id }) => ({
        url: `/lead-agreement/update/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),

    DelAgreement: builder.mutation({
      query: ({ id }) => ({
        url: `/lead-agreement/delete/${id}`,
        method: 'DELETE',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),

    DelAgreementAttachment: builder.mutation({
      query: ({ data, id }) => ({
        url: `/lead-agreement/delete-file/${id}`,
        method: 'DELETE',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),
    ListLeadAgreement: builder.mutation({
      query: ({ data, id }) => ({
        url: `/lead-agreement/list/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getAgreement({ agreements: data.data.data }));
          dispatch(
            getAgreement({
              leadAgreementPage: {
                count: data.data.count,
                currentPage: data.data.currentPage,
                lastPage: data.data.lastPage,
                nextPage: data.data.nextPage,
                prevPage: data.data.nextPage,
              },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  useGetAgreementMutation,
  useCreateAgreementMutation,
  useUpdateAgreementMutation,
  useDelAgreementMutation,
  useDelAgreementAttachmentMutation,
  useListLeadAgreementMutation,
} = leadAgreementApiSplice;
