/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { tabOptions } from './constants';

export const useSequenceTabsServices = () => {
  const navigate = useNavigate();
  const { settingType, settingTypeId } = useParams();

  const { sequencesData, stepsData } = useSelector(
    (state: any) => state.outreach
  );

  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    const tabIndex = tabOptions.indexOf(settingType);
    setSelectedTab(tabIndex >= 0 ? tabIndex : 0);
  }, [settingType]);

  const handleChangeTab = (index: number) => {
    setSelectedTab(index);
    navigate(
      index === 0
        ? `/engage/sequences/recruitment/${tabOptions[index]}/${settingTypeId}/all`
        : `/engage/sequences/recruitment/${tabOptions[index]}/${settingTypeId}`
    );
  };

  return { sequencesData, stepsData, selectedTab, handleChangeTab };
};
