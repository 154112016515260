import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiSlice } from './api.slice';
import { appError } from './app.slice';
import axios from 'axios';
import { fileUploadedInt } from 'types';

export const uploadSlice = createSlice({
  name: 'uploads',
  initialState: {
    uploaded: [],
    attachments: [],
    resumeUploaded: [],
    resumeAttachments: [],
    resumeParsingQueue: [],
    attachmentInvite: [],
    attachmentConfirmation: [],
    url: '',
    path: '',
    prefix: 'Attachment',
    withPrefix: false,
    uploading: false,
  },
  reducers: {
    uploadList: (state, { payload }) => ({ ...state, ...payload }),
    setAttachment: (state, { payload }) => {
      state.attachments.push(payload);
    },
    removeAttachment: (state, { payload }) => {
      const attachments = state.attachments as any;
      const newAttachments = attachments.filter(
        (file: fileUploadedInt) => file.id !== payload
      );
      return { ...state, attachments: newAttachments };
    },
    removeResumeAttachment: (state, { payload }) => {
      const attachments = state.resumeAttachments as any;
      const newAttachments = attachments.filter(
        (file: fileUploadedInt) => file.id !== payload
      );
      return { ...state, resumeAttachments: newAttachments };
    },
    setUploaded: (state, { payload }) => {
      state.uploaded.push(payload);
    },
    setResumeUploaded: (state, { payload }) => {
      // console.log('checked', payload.data);
      state.resumeUploaded.push(payload);
    },
    setResumeAttachments: (state, { payload }) => {
      state.resumeAttachments.push(payload);
    },
    setProgress: (state, { payload }) => {
      try {
        const index = state.attachments.findIndex(
          (item) => item.id == payload.id
        );
        state.attachments[index] = payload;
      } catch (e) {
        console.log(e);
      }
    },
    setResumeProgress: (state, { payload }) => {
      try {
        const index = state.resumeAttachments.findIndex(
          (item) => item.id == payload.id
        );
        state.resumeAttachments[index] = payload;
      } catch (e) {
        console.log(e);
      }
    },
    setParsingProgress: (state, { payload }) => {
      try {
        const index = state.resumeParsingQueue.findIndex(
          (item) => item.id == payload.id
        );
        state.resumeParsingQueue[index] = payload;
      } catch (e) {
        console.log(e);
      }
    },
    addToParsingQueue: (state, { payload }) => {
      state.resumeParsingQueue.push(payload);
    },
    removeFromParsingQueue: (state, { payload }) => {
      state.resumeParsingQueue.splice(payload, 1);
    },
    cleanUpload: (state, { payload }) => ({ ...state, ...payload }),
    removeUploaded: (state, { payload }) => {
      // state.uploaded.splice(payload, 1);
      const attachments = state.uploaded as any;
      const newAttachments = attachments.filter(
        (file: fileUploadedInt) => file.id !== payload
      );
      return { ...state, uploaded: newAttachments };
    },
    removeResumeUploaded: (state, { payload }) => {
      // state.uploaded.splice(payload, 1);
      const attachments = state.resumeUploaded as any;
      const newAttachments = attachments.filter(
        (file: fileUploadedInt) => file.id !== payload
      );
      return { ...state, resumeUploaded: newAttachments };
    },
  },
});

export const {
  uploadList,
  setAttachment,
  removeResumeAttachment,
  removeAttachment,
  setUploaded,
  cleanUpload,
  setResumeAttachments,
  setResumeUploaded,
  setResumeProgress,
  setProgress,
  addToParsingQueue,
  removeFromParsingQueue,
  setParsingProgress,
  removeUploaded,
  removeResumeUploaded,
} = uploadSlice.actions;
export default uploadSlice.reducer;

/* ----------------------------------- API ---------------------------------- */

export const uploadApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    Upload: builder.mutation<{}, FormData>({
      query: (data) => ({
        url: `/upload/file/temp`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUploaded(data));
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),
    ResumeUpload: builder.mutation<{}, FormData>({
      query: (data) => ({
        url: `/upload/file/temp`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setResumeUploaded(data));
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const { useUploadMutation, useResumeUploadMutation } = uploadApiSlice;
