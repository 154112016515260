import {
  Box,
  Button as Button1,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  ModalFooter,
  Radio,
  RadioGroup,
  Stack,
  useDisclosure,
  Link,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  InputAddon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import AtsSelect3 from 'components/app/AtsSelect3';
import ScrollToFieldError from 'components/app/ScrollError';
import { Field, Formik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOpenJobsMutation } from 'store/jobs.slice';
import {
  clientSelectType,
  fileUploadedInt,
  InterviewPreviewInt,
  meetingModeInt,
  meetingTypeInt,
} from 'types';
import PreviewDetails from './previews/Details';
import replacePlaceholders from 'utils/textPlaceholders';
import NotesModal from '../../../Modals/Notes';
import AtsSelectContact from 'components/app/AtsSelectContact';
import {
  initialValueInt,
  interviewDetails,
  optionInterface,
  typeInt,
  modeInt,
  phoneInt,
  contactInt,
  leadInt,
} from './details.interface';
import {
  FaUser,
  FaPhone,
  FaMobileAlt,
  FaHome,
  FaBriefcase,
  FaTrash,
  FaCheck,
} from 'react-icons/fa';
import AtsIconSelect from 'components/app/AtsSelect/AtsIconSelect';
import { getJob } from 'store/jobs.slice';
import { useUpdateContactLeadMutation } from 'store/leads.slice';
import contactsPopulate from './contacts';
import { listTemplate, useRawListTemplateMutation } from 'store/template.slice';
import DateInputComponent from 'components/app/AtsDatePicker/DateInputComponent';
import AtsDatePicker from 'components/app/AtsDatePicker';
import FAIcon from 'components/lib/FAIcon';
import Button from 'Library/Button';

const InterviewDetails = ({
  onSubmit,
  placeholders,
  jobData,
  candidateData,
  jobsId,
  openJobs,
  initialValues,
  panelOptions,
  clientPanels,
  candidatePairing,
  userOptions,
  schedInterview,
  setSchedInterview,
  handleSwitchInterview,
  convertToMilitaryTime,
  onClose,
  handleResetTemplate,
}: interviewDetails) => {
  let intialOption: optionInterface[] = [];
  const selectRef = useRef(null);

  const { placehoderPair } = useSelector((state: any) => state.template);
  const { meeting_types } = useSelector((state: any) => state.constants);
  const [selectedTimezone, setSelectedTimezone] = useState('US/Pacific');
  const [hasJob] = useState(jobsId);
  const dispatch = useDispatch();
  const [reqUpdateContact, resUpdateContact] = useUpdateContactLeadMutation();
  const [defOption, setDefOption] = useState(intialOption);
  const [meetingPH, setMeetingPh] = useState('Meeting Location');
  const [meetingTitle, setMeetingTitle] = useState('Phone Number');
  const [meetingType, setMeetingType] = useState<string>(
    initialValues?.meeting_type?.label || 'Video Interview'
  );

  const [meetingTypeOptions, setMeetingTypeOptions] = useState<typeInt[]>([]);
  const [meetingModeOptions, setMeetingModeOptions] = useState<modeInt[]>([]);

  const [panelOption, setPanelOptions] = useState<any>([]);
  const [company, setCompany] = useState<clientSelectType | object>({});
  const [phoneOption, setPhoneOption] = useState<phoneInt[]>(
    initialValues?.contacts?.phone || []
  );
  const [contactOption, setContactOption] = useState<contactInt[]>([]);
  const [meetingMode, setMeetingMode] = useState<string>(
    initialValues?.meeting_mode?.label || ''
  );
  const [addPhone, setAddPhone] = useState<boolean>(false);
  const [contactType, setContactType] = useState<string>('work');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [contactId, setContactId] = useState<number | null>(
    initialValues?.contacts?.value || null
  );
  const [phoneValue, setPhoneValue] = useState<phoneInt | null>(
    initialValues?.phones || null
  );
  const [startTime, setSTartTime] = useState<number | null>(
    Number(initialValues?.start_time?.value || 0)
  );

  const [reqList, resList] = useRawListTemplateMutation();

  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999, // Adjust z-index here
    }),
  };

  const [reqOpen, resOpen] = useOpenJobsMutation();

  const [errMessageLoc, setErrorMessageLoc] = useState(
    'Meeting link is required.'
  );
  const [selectedDate, setSelectedDate] = useState(
    moment.tz(initialValues.timezone.value).format('MM-DD-YYYY')
  );
  const [minDate, setMinDate] = useState(
    moment.tz(moment(), initialValues.timezone.value)
  );

  useEffect(() => {
    // calling all template for invites
    const getData = async () => {
      await reqList({ id: 5 });
    };
    getData();
  }, []);

  useEffect(() => {
    if (jobData?.leads?.length > 0) {
      const loadContacts = async () => {
        // console.log({ leads: jobData.leads, jobData });
        let contacts: contactInt[] = await contactsPopulate(jobData.leads);
        setContactOption(contacts);
      };
      loadContacts();
    }
  }, [jobData]);

  const loadContacts = (): phoneInt[] => {
    let phones: phoneInt[] = [];
    if (candidateData?.contact?.length > 0) {
      Promise.all(
        candidateData.contact.map((item: any) => {
          const value = {
            value: item.number,
            label: item.number,
            type: item.type,
            primary: item.primary,
            icon: item.type === 'mobile' ? FaMobileAlt : FaPhone,
          };
          if (candidateData.contact.length === 1 || item.primary) {
            setPhoneValue(value);
          }
          phones.push(value);
        })
      );
    }
    return phones;
  };

  useEffect(() => {
    if (meetingMode === 'Client Calling Candidate') {
      const loadContacts = async () => {
        let phones: phoneInt[] = [];
        if (candidateData?.contact?.length > 0) {
          await Promise.all(
            candidateData.contact.map((item: any) => {
              const value = {
                value: item.number,
                label: item.number,
                type: item.type,
                primary: item.primary,
                icon: item.type === 'mobile' ? FaMobileAlt : FaPhone,
              };
              if (candidateData.contact.length === 1 || item.primary) {
                setPhoneValue(value);
              }
              phones.push(value);
            })
          );
        }

        setPhoneOption(phones);
      };
      loadContacts();
    }
    // console.log({ initialValues });
  }, [meetingMode, candidateData]);

  function generateTimeOptions(
    startMinutes: number,
    numOptions: number,
    minDate: any,
    interval: number
  ) {
    return Array.from({ length: numOptions }, (_, index) => {
      let totalMinutes: number = index * interval + startMinutes;

      const hours = Math.floor(totalMinutes / 60) % 12 || 12;
      const minutes = totalMinutes % 60;
      const period = totalMinutes < 720 ? 'AM' : 'PM';
      const formattedHour = hours.toString().padStart(2, '0');
      const formattedMinute = minutes.toString().padStart(2, '0');
      const label = `${formattedHour}:${formattedMinute} ${period}`;
      const value = totalMinutes;
      const currDate = moment(minDate).format('MM-DD-YYYY');
      const currDateInMins = minDate.hours() * 60 + minDate.minutes();
      const convertedSelectedDate = moment(selectedDate).format('MM-DD-YYYY');

      const isDisabled =
        currDate < convertedSelectedDate
          ? false
          : currDate === convertedSelectedDate
          ? totalMinutes <= currDateInMins
          : true;

      let payload = {
        label,
        value,
        isDisabled,
      };

      if (schedInterview === '1') {
        payload = {
          ...payload,
          isDisabled: false,
        };
      }

      return payload;
    });
  }

  // const [currentDateInMin, setCurrentDateInMin] = useState(
  //   minDate.hours() * 60 + minDate.minutes()
  // );

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      // subject: Yup.string().required('Subject is required.'),
      date: Yup.date().required('Date is required.'),
      job_id: Yup.number().required('Job is required.'),
      start_time: Yup.object()
        .shape({
          label: Yup.string().required('Start time is required.'),
          value: Yup.string(),
          isDisabled: Yup.boolean(),
        })
        .required('Start time is required.'),
      // .test(
      //   'is-false',
      //   'Start time selected is not allowed',
      //   (value: any) =>
      //     !(value.value <= currentDateInMin
      //       ? minDate.toISOString().split('T')[0] >= selectedDate
      //       : false)
      // ),
      end_time: Yup.object()
        .shape({
          label: Yup.string().required('End time is required.'),
          value: Yup.string().required('End time is required.'),
          isDisabled: Yup.boolean().required('End time is required.'),
        })
        .required('End time is required.'),
      // .test(
      //   'is-false',
      //   'End time selected is not allowed',
      //   (value: any) =>
      //     !(value.value <= currentDateInMin
      //       ? minDate.toISOString().split('T')[0] >= selectedDate
      //       : false)
      // ),
      meeting_type: Yup.object()
        .shape({
          label: Yup.string().required('Meeting type label is required.'),
          value: Yup.string().required('Meeting type value is required.'),
        })
        .required('Meeting type is required.'),
      meeting_mode: Yup.object()
        .shape({
          label: Yup.string(),
          value: Yup.number(),
        })
        .nullable()
        .default(null)
        .when('meeting_type.label', (label: any, schema: any) => {
          if (label === 'Video Interview' || label === 'Phone Interview') {
            return schema.required('Meeting mode is required.');
          }
          return schema.nullable();
        }),
      meeting_location: Yup.string().when(
        'meeting_type.label',
        (label, schema) => {
          if (label === 'On-Site') {
            return schema.required(errMessageLoc);
          }
          return schema.nullable();
        }
      ),
      contacts: Yup.object()
        .nullable()
        .default(null)
        .shape({
          label: Yup.string().nullable(),
          value: Yup.number().nullable(),
          phone: Yup.array().nullable(),
          email: Yup.string().nullable(),
        })
        .when('meeting_mode.label', (label, schema) => {
          if (label === 'Candidate Calling Client') {
            return schema.required('Please select a contact');
          }
          return schema.nullable();
        }),
      phones: Yup.object()
        .nullable()
        .default(null)
        .when('meeting_mode.label', (label, schema) => {
          if (label === 'Client Calling Candidate') {
            return schema.required('Please select a Phone number');
          }
          return schema.nullable();
        }),
      panel_members: Yup.array().of(
        Yup.object().shape({
          value: Yup.mixed().test(
            'is-string-or-number',
            'Must be a string or a number',
            (value) => {
              return typeof value === 'string' || typeof value === 'number';
            }
          ),
          label: Yup.string().required('Label is required'),
        })
      ),
      timezone: Yup.object()
        .shape({
          label: Yup.string(),
          value: Yup.string(),
        })
        .required('Timezone is required.'),
      body: Yup.string(),
      primary: Yup.boolean(),
    })
  );

  const handleSetErrorMessageLoc = (type: string) => {
    switch (type) {
      case 'Phone Interview':
        setErrorMessageLoc('Phone Number is required.');
        break;
      case 'On-Site':
        setErrorMessageLoc('Meeting Locaiton is required.');
        break;
      default:
        setErrorMessageLoc('Meeting Link is required.');
    }
  };

  const [timezones, setTimezones] = useState([]);

  const setMinimumDate = async (timezone: any) => {
    setMinDate(moment.tz(moment(), timezone));
  };
  useEffect(() => {
    if (jobData) {
      // console.log("jobData?.client_id", jobData?.client_id)
      setCompany({
        label: jobData ? jobData?.client?.name : '',
        value: jobData ? jobData?.client_id : '',
      });
    }
  }, [jobData]);

  useEffect(() => {
    const minimumDate = moment.tz(moment(), selectedTimezone);

    setStartTimeOptions(generateTimeOptions(0, 95, minimumDate, 30));
    setStartTimeOptionsAll(generateTimeOptions(0, 1410, minimumDate, 1));
    setEndTimeOptionsAll(generateTimeOptions(30, 1410, minimumDate, 1));
    setEndTimeOptions(generateTimeOptions(30, 95, minimumDate, 30));
    // setCurrentDateInMin(minimumDate.hours() * 60 + minimumDate.minutes());
    setMinDate(minDate);
  }, [selectedDate, selectedTimezone, startTime]);

  useEffect(() => {
    const fetchTimezones = async () => {
      const timezoneString = [
        'US/Pacific',
        'US/Central',
        'US/Eastern',
        'US/Alaska',
        'US/Mountain',
        'US/Hawaii',
        // 'EST',
        // 'Asia/Manila',
      ];

      const timezoneData = timezoneString.map((zone) => {
        const offset = moment.tz(zone).format('Z');
        const label = `${zone} (UTC${offset})`;
        return {
          label,
          value: zone === 'EST' ? 'Eastern Standard Time' : zone,
        };
      });

      setTimezones(timezoneData);
    };

    fetchTimezones();
  }, [candidateData]);

  const setModeOption = (mode: meetingModeInt[], type: string) => {
    let modes: modeInt[] = [];
    setMeetingType(type);
    if (mode.length > 0) {
      const list = [...mode];
      if (type === 'Phone Interview') {
        list.sort((a, b) => b.id - a.id);
      }

      list.map((item: meetingModeInt, key: number) => {
        modes.push({
          label: item.mode,
          value: item.id,
        });
        if (type === 'Phone Interview' && key === 1) {
          setMeetingMode(item.mode);
          // initialValues?.meeting_mode?
          initialValues = {
            ...initialValues,
            meeting_mode: {
              label: item.mode,
              value: item.id,
            },
          };
        } else if (key === 0) {
          setMeetingMode(item.mode);
          initialValues = {
            ...initialValues,
            meeting_mode: {
              label: item.mode,
              value: item.id,
            },
          };
        }
      });
    }
    setMeetingModeOptions(modes);
  };

  const endTimeRef = useRef(null);

  const [startTimeOptions, setStartTimeOptions] = useState(
    generateTimeOptions(480, 63, minDate, 30)
  );

  const [startTimeOptionsAll, setStartTimeOptionsAll] = useState(
    generateTimeOptions(480, 930, minDate, 1)
  );
  const [endTimeOptionsAll, setEndTimeOptionsAll] = useState(
    generateTimeOptions(510, 930, minDate, 1)
  );
  const [endTimeOptions, setEndTimeOptions] = useState(
    generateTimeOptions(510, 63, minDate, 30)
  );

  function adjustTime(timeValue: any, timeOptions: any) {
    const time = timeOptions.find((timeOption: any) => {
      return timeOption.value === timeValue;
    });
    return {
      label: time.label,
      value: time.value,
      isDisabled: time.isDisabled,
    };
  }

  function compareTime(startTime: any, endTime: any) {
    return startTime > endTime ? 1 : startTime === endTime ? 0 : -1;
  }

  useEffect(() => {
    if (!hasJob) {
      const data = {
        query: '',
        candidate_id: candidateData?.id,
      };
      reqOpen(data);
    }
    let types = [] as typeInt[];

    meeting_types.map((item: meetingTypeInt, key: number) => {
      types.push({
        value: item.type,
        label: item.type,
        id: item.id,
        mode: item.mode,
      });
      if (initialValues?.meeting_type?.id) {
        if (initialValues?.meeting_type?.id === item.id) {
          setModeOption(item.mode, item.type);
        }
      } else {
        if (key === 0) {
          setModeOption(item.mode, item.type);
        }
      }
    });
    setMeetingTypeOptions(types);
    setMeetingMode(initialValues?.meeting_mode?.label);
    setMeetingType(initialValues?.meeting_type?.label);
    setContactId(initialValues?.contacts?.value);
    setPhoneValue(initialValues?.phones);
    setPhoneOption(initialValues?.contacts?.phone || loadContacts);
  }, [initialValues]);

  useEffect(() => {
    if (resOpen.isSuccess) {
      let options: optionInterface[] = [];
      openJobs.map((job: any) => {
        options.push({
          label: job?.job?.title,
          value: job?.job?.id,
        });
      });
      setDefOption(options);
    }
  }, [resOpen, resOpen.isSuccess]);

  const {
    isOpen: isOpenNotes,
    onClose: onCloseNotes,
    onOpen: onOpenNotes,
  } = useDisclosure();

  const meetingTitleChange = (event: typeInt) => {
    if (event.label === 'Video Interview') {
      setMeetingTitle('Meeting Link');
    }
    if (event.label === 'On-Site') {
      setMeetingTitle('Meeting Location');
    }
    if (event.label === 'Phone Interview') {
      setMeetingTitle('Phone Number');
    }

    setModeOption(event.mode, event.label);
    setMeetingType(event.value);
  };

  const meetingTypeChange = (type: any, mode: any) => {
    setContactId(null);
    setMeetingMode(mode.label);
    if (type === 'Video Interview') {
      if (mode?.label === 'Microsoft Teams') {
        setMeetingPh('Link will be auto-created');
      } else {
        setMeetingPh('Meeting Link');
      }
    }
    if (type === 'On-Site') {
      setMeetingPh('Location');
      setMeetingMode('');
    }
    if (type === 'Phone Interview') {
      setMeetingPh('Phone Number');
    }
  };

  const formatDate = (dateString: any) => {
    try {
      const date = moment(dateString).format('MM/DD/YYYY');
      return date;
    } catch (e) {
      return '';
    }
  };
  const inputRef = useRef(null);
  const handleKeyDown = (event: any) => {
    // Prevent deleting "/" character
    if (
      event.key === '/' &&
      event.code === 'Backspace' &&
      event.value.includes('/')
    ) {
      event.preventDefault();
    }
    if (event.key === 'Tab') {
      const { value } = event.target;
      const dateParts = value.split('/');
      let month = dateParts[0] || '';
      let day = dateParts[1] || '';
      let year = dateParts[2] || '';

      event.preventDefault();
      const input = inputRef.current;

      if (input.selectionStart === 0) {
        input.setSelectionRange(3, 5);
        if (day === 'DD') day = '00';
      } else if (input.selectionStart === 3) {
        input.setSelectionRange(6, 10);
        if (year === 'YYYY') year = '0000';
      } else {
        input.setSelectionRange(0, 2);
        if (month === 'DD') month = '00';
      }
      event.target.value = month + '/' + day + '/' + year;
    }
  };
  const handleKeyPress = (event: any) => {
    const charCode = event.which || event.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      // Allow numeric characters (0-9)
      return true;
    }
    // Prevent alphabetic characters and other keys
    event.preventDefault();
    return false;
  };

  const changeStartAndEndTime = async (
    values: any,
    setFieldValue: any,
    currentDateInMinutes: number
  ) => {
    if (values.start_time.value < currentDateInMinutes) {
      const adjustStartTime = startTimeOptionsAll.find(
        (timeOption) =>
          timeOption.value >= currentDateInMinutes &&
          timeOption.value % 30 === 0
      );
      setFieldValue('start_time', adjustStartTime);
    }

    if (values.end_time.value < currentDateInMinutes) {
      const adjustEndTime = endTimeOptionsAll.find(
        (timeOption) =>
          timeOption.value >= currentDateInMinutes + 30 &&
          timeOption.value % 30 === 0
      );
      setFieldValue('end_time', adjustEndTime);
    }
  };

  const handleTimezoneChange = async (
    event: any,
    values: any,
    setFieldValue: any
  ) => {
    setFieldValue('timezone', event);
    const tz = event.label;
    const rawTz = tz.replace(/ \(UTC.*$/, '');
    setSelectedTimezone(rawTz);
    const minimumDate = moment.tz(moment(), rawTz);
    const currentDateInMinutes =
      minimumDate.hours() * 60 + minimumDate.minutes();
    setMinimumDate(rawTz);
    if (new Date(minimumDate.format('YYYY-MM-DD')) >= new Date(selectedDate)) {
      changeStartAndEndTime(values, setFieldValue, currentDateInMinutes);
    }
  };

  const handleDateChange = (event: any, values: any, setFieldValue: any) => {
    const { value } = event.target;
    let dateString = value;
    const dateParts = value.split('/');

    if (dateString === '') {
      setFieldValue('date', new Date());
    }

    if (dateParts.length !== 3) {
      return;
    }

    let [month, day, year] = dateParts;

    month = month.padStart(2, '0');

    const maxDaysPerMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    if (
      parseInt(month) < 1 ||
      parseInt(month) > 12 ||
      parseInt(day) < 1 ||
      parseInt(day) > maxDaysPerMonth[parseInt(month) - 1] ||
      parseInt(year) < 0
    ) {
      setFieldValue('date', new Date());
    } else {
      day = day.padStart(2, '0');
      year = year.padStart(4, '0');
      const formattedDate = `${month}/${day}/${year}`;
      setFieldValue('date', formattedDate);
      setSelectedDate(`${year}-${month}-${day}`);
    }
  };

  const filterStartTimeOptions = (
    option: any,
    rawInput: string,
    selected_time: number
  ) => {
    const { label, value } = option;
    // cleans raw inputs and label of all leading zeros and spaces to accommodate all valid use cases
    const cleanedTime = rawInput.replace(/\s|^0+/g, '').toLocaleUpperCase();
    const cleanedLabel = label.replace(/\s|^0+/g, '');

    if (
      cleanedLabel.startsWith(cleanedTime) &&
      (value % 30 == 0 || selected_time === value)
    ) {
      return true;
    }
    {
      const filteredOptions = startTimeOptions.filter((opt: any) =>
        opt.label.replace(/\s|^0+/g, '').startsWith(cleanedTime)
      );
      if (filteredOptions.length === 0) {
        if (cleanedLabel.startsWith(cleanedTime)) return true;
      }
    }
    return false;
  };

  const filterEndTimeOptions = (
    option: any,
    rawInput: string,
    selected_time: number
  ) => {
    const { label, value } = option;
    // cleans raw inputs and label of all leading zeros and spaces to accommodate all valid use cases
    const cleanedTime = rawInput.replace(/\s|^0+/g, '').toLocaleUpperCase();
    const cleanedLabel = label.replace(/\s|^0+/g, '');

    if (
      cleanedLabel.startsWith(cleanedTime) &&
      (value % 30 == 0 || selected_time === value)
    ) {
      return true;
    }
    {
      const filteredOptions = endTimeOptions.filter((opt: any) =>
        opt.label.replace(/\s|^0+/g, '').startsWith(cleanedTime)
      );
      if (filteredOptions.length === 0) {
        if (cleanedLabel.startsWith(cleanedTime)) return true;
      }
    }
    return false;
  };

  const handleStartTimeChange = (
    event: any,
    values: any,
    setFieldValue: any
  ) => {
    const startTime = event.value;
    setSTartTime(startTime);
    const endTime = adjustTime(startTime + 30, endTimeOptionsAll);
    setFieldValue('end_time', endTime);
    setFieldValue('start_time', event);
  };

  const handleEndTimeChange = (event: any, values: any, setFieldValue: any) => {
    const endTime = event.value;
    if (compareTime(values.start_time.value, endTime) >= 0) {
      const startTime = adjustTime(endTime - 30, startTimeOptionsAll);
      setFieldValue('start_time', startTime);
    }
    setFieldValue('end_time', event);
  };

  const detailsSubmit = (data: any) => {
    let phones = data.phones;

    if (data?.meeting_mode?.label === 'Candidate Calling Client') {
      phones = phoneValue;
    }
    const param = {
      ...data,
      phones,
      set_interview: schedInterview,
    };
    onSubmit(param);
  };

  const {
    isOpen: isOpenPreview,
    onOpen: onOpenPreview,
    onClose: onClosePreview,
  } = useDisclosure();

  const [PrevParam, setPrevParam] = useState<InterviewPreviewInt>();

  const locationPlaceholderPopulate = (values: any) => {
    if (values.meeting_type.label === 'Phone Interview') {
      return values?.phones?.value;
    }
    if (
      values.meeting_type.label === 'Video Interview' &&
      values.meeting_mode.label === 'Microsoft Teams' &&
      values.meeting_location === ''
    ) {
      return '{{interview_location}}';
    } else {
      return values.meeting_location;
    }
  };
  const updatePlaceholders = async (values: any) => {
    let meetingDate = moment(values.date).format('YYYY-MM-DD');
    let startTime = moment(
      `${meetingDate} ${convertToMilitaryTime(values.start_time.label)}`
    );
    let endTime = moment(
      `${meetingDate} ${convertToMilitaryTime(values.end_time.label)}`
    );

    let formattedDate = startTime.format('dddd, MMMM D'); // "Monday, February 12"
    let formattedStartTime = startTime.format('h:mm A'); // "11:30 AM"
    let formattedEndTime = endTime.format('h:mm A'); // "12:00 PM"
    let timeZone = startTime.tz(values.timezone.value).format('z'); // "PST" - Note: This might vary based on DST
    const dateTime = `${formattedDate} at ${formattedStartTime} to ${formattedEndTime} ${timeZone}`;

    let panel_members = [] as any;
    await Promise.all(
      values.panel_members.map(async (panel_email: any) => {
        const lead = await clientPanels.filter(
          (panel: any) => panel.primary_email === panel_email?.value
        )?.[0];
        if (lead) {
          const name = [lead?.first_name, lead?.last_name]
            .filter(Boolean)
            .join(' ');

          const panel = [
            name,
            lead?.title,
            lead?.linked_in_url
              ? `<a
              href="${lead.linked_in_url}"
              target="_blank"
            >
              ${lead?.linked_in_url}
            </a>`
              : '',
          ]
            .filter(Boolean)
            .join(' - ');
          panel_members.push(panel);
        }
      })
    );
    let panel_members_details = ``;

    panel_members.forEach((member: string) => {
      panel_members_details += `<li>${member}</li>`;
    });

    const interviewParam = {
      interview_type: values.meeting_type.label || '',
      // interview_location:
      //   values.meeting_mode?.label === 'Microsoft Teams'
      //     ? 'https://teams.microsoft.com/l/meetup-join/'
      //     : values.meeting_location || '',
      interview_location:
        locationPlaceholderPopulate(values) || values?.phones?.value || '',
      interview_date: dateTime || '',
      interview_mode: values.meeting_mode.label || '',
      panel_members_details: panel_members_details,
    };

    const placeholderPair = {
      ...placehoderPair,
      ...interviewParam,
    };

    candidatePairing(placeholderPair);
  };

  const onPanelInputChange = (e: any, setInterview: string) => {
    if (setInterview === '1') {
      setPanelOptions(userOptions);
    } else {
      if (e.length > 2) {
        const options = [...panelOptions, ...userOptions];
        setPanelOptions(options);
      } else {
        setPanelOptions(panelOptions);
      }
    }
  };

  useEffect(() => {
    setPanelOptions(panelOptions);
  }, [panelOptions]);

  const handleSetInterview = (value: any) => {
    setSchedInterview(value);
    if (value === '1') {
      setPanelOptions(userOptions);
    }
  };

  const submitNewContact = async (callback: (e: phoneInt) => void) => {
    // console.log({ phoneNumber, contactType, contactId, phoneOption });
    setAddPhone(false);
    setPhoneNumber('');
    setContactType('work');
    let value: phoneInt = null;
    let newvalue = new Set();
    await setPhoneOption((prevOptions) =>
      prevOptions.map((option) => {
        if (option.type === contactType) {
          value = {
            ...option,
            value: phoneNumber,
            label: phoneNumber,
            primary: true,
          };
          newvalue.add(JSON.stringify(value));
          return value;
        }
        newvalue.add(JSON.stringify({ ...option, primary: false }));
        return { ...option, primary: false };
      })
    );
    const uniquePhoneOptions = Array.from(newvalue).map((item: any) =>
      JSON.parse(item)
    ) as phoneInt[];
    setPhoneValue(value);
    updateLeadContact(uniquePhoneOptions);
    callback(value);
  };

  const updateLeadContact = async (phones: phoneInt[]) => {
    if (jobData?.leads.length > 0) {
      let leads = [...jobData.leads];
      let personal_phone = '';
      let work_phone = '';
      phones.forEach((phone: phoneInt) => {
        if (phone.type === 'personal') {
          personal_phone = phone.value;
        } else {
          work_phone = phone.value;
        }
      });

      leads = leads.map((item: any) => {
        if (item.id == contactId) {
          return {
            ...item,
            personal_phone,
            work_phone,
          };
        }
        return item;
      });

      const data = {
        ...jobData,
        leads,
      };

      dispatch(getJob({ jobData: data }));

      await reqUpdateContact({
        id: contactId,
        data: {
          personal: personal_phone,
          work: work_phone,
        },
      });
    }
  };

  const contactChange = async (event: contactInt) => {
    setPhoneOption(event.phone);
    setContactId(event.value);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={detailsSubmit}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
      }) => {
        const isVideoInterview =
          values.meeting_type?.label === 'Video Interview';

        return (
          <form onSubmit={handleSubmit} style={{ height: '100%' }}>
            <ScrollToFieldError />

            <Box h="100%" borderRadius="6px" m="0 8px" p="16px" bgColor="#fff">
              <RadioGroup defaultValue={schedInterview}>
                <Stack spacing={5} mb="24px" direction="row">
                  <Radio
                    color="primary.800"
                    colorScheme="purple"
                    value="0"
                    onChange={(e: any) => {
                      handleSetInterview(e.target.value);
                      setFieldValue('set_interview', e.target.value);
                      handleSwitchInterview(
                        setFieldValue,
                        'panel_members',
                        e.target.value
                      );
                      setPanelOptions(panelOptions);
                      setSelectedDate(
                        moment().subtract(1, 'days').format('MM-DD-YYYY')
                      );
                      setFieldValue(
                        'date',
                        (moment().subtract(1, 'days') as any)?._d
                      );
                    }}
                  >
                    Schedule Interview
                  </Radio>
                  <Radio
                    color="primary.800"
                    colorScheme="purple"
                    value="1"
                    onChange={(e: any) => {
                      handleSetInterview(e.target.value);
                      setFieldValue('set_interview', e.target.value);
                      handleSwitchInterview(
                        setFieldValue,
                        'panel_members',
                        e.target.value
                      );
                      setPanelOptions(userOptions);
                      setSelectedDate(
                        moment().subtract(1, 'days').format('MM-DD-YYYY')
                      );
                      setFieldValue(
                        'date',
                        (moment().subtract(1, 'days') as any)?._d
                      );
                    }}
                  >
                    Record Interview Manually
                  </Radio>
                </Stack>
              </RadioGroup>
              <Grid templateColumns="repeat(2, 1fr)" mb="24px" gap="10px">
                <GridItem w="100%">
                  <FormLabel fontSize="14px" lineHeight="18px">
                    Meeting Type{' '}
                    <Box as="span" color="caution.800">
                      *
                    </Box>
                  </FormLabel>
                  <AtsSelect3
                    id="meeting_type"
                    name="meeting_type"
                    variant="outline"
                    defaultValue={values.meeting_type}
                    value={values.meeting_type}
                    onChange={(event: any) => {
                      if (values.meeting_type !== event) {
                        handleResetTemplate();
                      }
                      meetingTitleChange(event);
                      handleSetErrorMessageLoc(event.label);
                      handleChange(event.label);
                      meetingTypeChange(event.label, values.meeting_mode);
                      setFieldValue('meeting_type', event);
                      if (event.label === 'Video Interview') {
                        setFieldValue('meeting_mode', {
                          label: 'Microsoft Teams',
                          value: 1,
                        });
                      } else if (event.label === 'Phone Interview') {
                        setFieldValue('meeting_mode', {
                          label: 'Client Calling Candidate',
                          value: 5,
                        });
                        setMeetingMode('Client Calling Candidate');
                        setFieldValue('phones', phoneOption[0] || {});
                      } else {
                        setFieldValue('meeting_mode', {
                          label: '',
                          value: 0,
                        });
                      }
                    }}
                    options={meetingTypeOptions}
                    styles={{
                      control: (styles: any) => ({
                        ...styles,
                        fontSize: '14px',
                        border: '1px solid #E7E9ED',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        boxShadow: 'none',
                        '&:hover': {},
                      }),
                      dropdownIndicator: (styles: any) => ({
                        ...styles,
                        color: '#676767',
                      }),
                      indicatorSeparator: () => ({
                        display: 'none',
                      }),
                      placeholder: (styles: any) => ({
                        ...styles,
                        fontSize: '14px',
                        color: '#9B9B9B',
                      }),
                      multiValue: (styles: any) => ({
                        ...styles,
                        background: '#F7FAFC',
                        border: '1px solid #E2E8F0',
                        borderRadius: '6px',
                        '&& div:first-of-type': {
                          color: '#2B2D42',
                        },
                      }),
                    }}
                  />
                </GridItem>

                {(meetingType == 'Video Interview' ||
                  meetingType == 'Phone Interview') && (
                  <>
                    <GridItem w="100%">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.meeting_mode && touched.meeting_mode
                        )}
                      >
                        <FormLabel fontSize="14px" lineHeight="18px">
                          Meeting Mode{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <AtsSelect3
                          id="meeting_mode"
                          name="meeting_mode"
                          variant="outline"
                          defaultValue={values.meeting_mode}
                          value={values.meeting_mode}
                          onChange={(event: any) => {
                            if (values.meeting_mode !== event) {
                              handleResetTemplate();
                            }
                            handleChange(event.label);
                            meetingTypeChange(values.meeting_type.label, event);
                            setFieldValue('meeting_mode', event);
                            setTimeout(() => {
                              setFieldValue('phones', phoneValue);
                              const lead =
                                contactOption.find(
                                  (item: contactInt) =>
                                    item.value === jobData.lead_id
                                ) || ({} as any);

                              setPhoneOption(lead?.phone);
                              setContactId(jobData.lead_id);
                              setFieldValue('contacts', lead);
                              setPhoneValue(null);
                            }, 200);
                          }}
                          options={meetingModeOptions}
                          styles={{
                            control: (styles: any) => ({
                              ...styles,
                              fontSize: '14px',
                              border: '1px solid #E7E9ED',
                              borderRadius: '4px',
                              cursor: 'pointer',
                              boxShadow: 'none',
                              '&:hover': {},
                            }),
                            dropdownIndicator: (styles: any) => ({
                              ...styles,
                              color: '#676767',
                            }),
                            indicatorSeparator: () => ({
                              display: 'none',
                            }),
                            placeholder: (styles: any) => ({
                              ...styles,
                              fontSize: '14px',
                              color: '#9B9B9B',
                            }),
                            multiValue: (styles: any) => ({
                              ...styles,
                              background: '#F7FAFC',
                              border: '1px solid #E2E8F0',
                              borderRadius: '6px',
                              '&& div:first-of-type': {
                                color: '#2B2D42',
                              },
                            }),
                          }}
                        />
                        <FormErrorMessage>
                          {String(errors.meeting_mode)}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    {meetingType === 'Phone Interview' &&
                      meetingMode === 'Candidate Calling Client' && (
                        <>
                          <GridItem>
                            <FormControl
                              isInvalid={Boolean(
                                !!errors.contacts && touched.contacts
                              )}
                            >
                              <FormLabel fontSize="14px" lineHeight="18px">
                                Contact{' '}
                                <Box as="span" color="caution.800">
                                  *
                                </Box>
                              </FormLabel>
                              <AtsSelect3
                                id="contacts"
                                name="contacts"
                                variant="outline"
                                onChange={(event: contactInt) => {
                                  contactChange(event);
                                  setPhoneValue(null);
                                  setFieldValue('contacts', event);

                                  let panel_members = [
                                    ...initialValues.panel_members,
                                  ];
                                  const find = panel_members.find(
                                    (item: any) => item.value === event.email
                                  );
                                  if (!find) {
                                    panel_members.push({
                                      label: event.label,
                                      value: event.email,
                                    });
                                  }

                                  setFieldValue('panel_members', panel_members);
                                }}
                                options={contactOption}
                                value={values.contacts}
                                styles={{
                                  control: (styles: any) => ({
                                    ...styles,
                                    fontSize: '14px',
                                    border: '1px solid #E7E9ED',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                    boxShadow: 'none',
                                    '&:hover': {},
                                  }),
                                  dropdownIndicator: (styles: any) => ({
                                    ...styles,
                                    color: '#676767',
                                  }),
                                  indicatorSeparator: () => ({
                                    display: 'none',
                                  }),
                                  placeholder: (styles: any) => ({
                                    ...styles,
                                    fontSize: '14px',
                                    color: '#9B9B9B',
                                  }),
                                  multiValue: (styles: any) => ({
                                    ...styles,
                                    background: '#F7FAFC',
                                    border: '1px solid #E2E8F0',
                                    borderRadius: '6px',
                                    '&& div:first-of-type': {
                                      color: '#2B2D42',
                                    },
                                  }),
                                }}
                              />
                              <FormErrorMessage>
                                {String(errors.contacts)}
                              </FormErrorMessage>
                            </FormControl>
                          </GridItem>
                          {contactId != null && (
                            <GridItem>
                              <Grid
                                templateColumns="repeat(1, 1fr)"
                                mb="24px"
                                gap="10px"
                              >
                                <GridItem>
                                  <FormControl
                                    isInvalid={Boolean(
                                      !!errors.phones && touched.phones
                                    )}
                                  >
                                    <FormLabel
                                      fontSize="14px"
                                      lineHeight="18px"
                                    >
                                      Phone Numbers{' '}
                                      <Box as="span" color="caution.800">
                                        *
                                      </Box>
                                    </FormLabel>
                                    <AtsIconSelect
                                      id="phones"
                                      name="phones"
                                      variant="outline"
                                      value={phoneValue}
                                      onChange={(event: phoneInt) => {
                                        setPhoneValue(event);
                                        setFieldValue('phones', event);
                                      }}
                                      options={phoneOption}
                                      styles={{
                                        control: (styles: any) => ({
                                          ...styles,
                                          fontSize: '14px',
                                          border: '1px solid #E7E9ED',
                                          borderRadius: '4px',
                                          cursor: 'pointer',
                                          boxShadow: 'none',
                                          '&:hover': {},
                                        }),
                                        dropdownIndicator: (styles: any) => ({
                                          ...styles,
                                          color: '#676767',
                                        }),
                                        indicatorSeparator: () => ({
                                          display: 'none',
                                        }),
                                        placeholder: (styles: any) => ({
                                          ...styles,
                                          fontSize: '14px',
                                          color: '#9B9B9B',
                                        }),
                                        multiValue: (styles: any) => ({
                                          ...styles,
                                          background: '#F7FAFC',
                                          border: '1px solid #E2E8F0',
                                          borderRadius: '6px',
                                          '&& div:first-of-type': {
                                            color: '#2B2D42',
                                          },
                                        }),
                                        menu: (provided: any) => ({
                                          ...provided,
                                          zIndex: 9999, // Adjust z-index here
                                        }),
                                      }}
                                    />
                                    <FormErrorMessage>
                                      {String(errors.phones)}
                                    </FormErrorMessage>
                                  </FormControl>
                                </GridItem>
                                <GridItem>
                                  {addPhone ? (
                                    <FormControl>
                                      <InputGroup>
                                        <InputLeftElement>
                                          <Button1
                                            size="sm"
                                            variant="outline"
                                            onClick={() =>
                                              setContactType(
                                                (prevType: string) =>
                                                  prevType === 'personal'
                                                    ? 'work'
                                                    : 'personal'
                                              )
                                            }
                                          >
                                            {contactType === 'personal' ? (
                                              <FaUser />
                                            ) : (
                                              <FaBriefcase />
                                            )}
                                          </Button1>
                                        </InputLeftElement>
                                        <Input
                                          type="tel"
                                          placeholder="Phone number"
                                          paddingRight="6em"
                                          onChange={(e) =>
                                            setPhoneNumber(e.target.value)
                                          }
                                          value={phoneNumber}
                                          sx={{
                                            height: '38px',
                                            border: '1px solid',
                                            borderColor: '#E7E9ED',
                                            p: '8px 12px',
                                            fontSize: '14px',
                                          }}
                                        />
                                        <InputRightElement>
                                          <Box
                                            position="absolute"
                                            right="0.5rem"
                                            top="50%"
                                            transform="translateY(-50%)"
                                            display="flex"
                                            zIndex="1"
                                          >
                                            <IconButton
                                              aria-label="cancel"
                                              variant="ghost"
                                              icon={<FaTrash />}
                                              size="sm"
                                              mr={1}
                                              onClick={() => {
                                                setAddPhone(false);
                                                setPhoneNumber('');
                                              }}
                                            />
                                            <IconButton
                                              aria-label="cancel"
                                              variant="ghost"
                                              icon={<FaCheck />}
                                              onClick={() =>
                                                submitNewContact((e) => {
                                                  setFieldValue('phones', e);
                                                })
                                              }
                                              size="sm"
                                            />
                                          </Box>
                                        </InputRightElement>
                                      </InputGroup>
                                    </FormControl>
                                  ) : (
                                    <Link onClick={() => setAddPhone(true)}>
                                      Add phone number
                                    </Link>
                                  )}
                                </GridItem>
                              </Grid>
                            </GridItem>
                          )}
                        </>
                      )}
                  </>
                )}
                {values.meeting_type?.label === 'On-Site' && (
                  <GridItem w="100%">
                    <FormControl
                      isInvalid={Boolean(
                        !!errors.meeting_location && touched.meeting_location
                      )}
                    >
                      <FormLabel fontSize="14px" lineHeight="18px">
                        {meetingTitle}{' '}
                        <Box as="span" color="caution.800">
                          *
                        </Box>
                      </FormLabel>
                      <Input
                        id="meeting_location"
                        name="meeting_location"
                        type="input"
                        placeholder={meetingPH}
                        value={values.meeting_location}
                        onChange={handleChange}
                        sx={{
                          height: '38px',
                          border: '1px solid',
                          borderColor: '#E7E9ED',
                          p: '8px 12px',
                          fontSize: '14px',
                        }}
                      />
                      <FormErrorMessage>
                        {String(errors.meeting_location)}
                      </FormErrorMessage>
                    </FormControl>
                  </GridItem>
                )}
              </Grid>

              {meetingMode === 'Client Calling Candidate' && (
                <FormControl
                  mb="24px"
                  isInvalid={Boolean(!!errors.phones && touched.phones)}
                >
                  <FormLabel fontSize="14px" lineHeight="18px">
                    Candidate - Contact{' '}
                    <Box as="span" color="caution.800">
                      *
                    </Box>
                  </FormLabel>
                  <AtsIconSelect
                    id="phones"
                    name="phones"
                    variant="outline"
                    value={phoneValue}
                    onChange={(event: phoneInt) => {
                      setPhoneValue(event);
                      setFieldValue('phones', event);
                    }}
                    options={phoneOption}
                    styles={{
                      control: (styles: any) => ({
                        ...styles,
                        fontSize: '14px',
                        border: '1px solid #E7E9ED',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        boxShadow: 'none',
                        '&:hover': {},
                      }),
                      dropdownIndicator: (styles: any) => ({
                        ...styles,
                        color: '#676767',
                      }),
                      indicatorSeparator: () => ({
                        display: 'none',
                      }),
                      placeholder: (styles: any) => ({
                        ...styles,
                        fontSize: '14px',
                        color: '#9B9B9B',
                      }),
                      multiValue: (styles: any) => ({
                        ...styles,
                        background: '#F7FAFC',
                        border: '1px solid #E2E8F0',
                        borderRadius: '6px',
                        '&& div:first-of-type': {
                          color: '#2B2D42',
                        },
                      }),
                      menu: (provided: any) => ({
                        ...provided,
                        zIndex: 9999, // Adjust z-index here
                      }),
                    }}
                  />
                  <FormErrorMessage>{String(errors.contacts)}</FormErrorMessage>
                </FormControl>
              )}

              {isVideoInterview && (
                <FormControl
                  mb="24px"
                  isInvalid={Boolean(
                    !!errors.meeting_location && touched.meeting_location
                  )}
                >
                  <FormLabel fontSize="14px" lineHeight="18px">
                    Meeting Link{' '}
                    <Box as="span" color="caution.800">
                      *
                    </Box>
                  </FormLabel>
                  <Input
                    id="meeting_location"
                    name="meeting_location"
                    type="input"
                    placeholder={meetingPH}
                    value={values.meeting_location}
                    onChange={handleChange}
                    bg={
                      values.meeting_mode.label === 'Microsoft Teams'
                        ? 'gray.100'
                        : 'white'
                    }
                    sx={{
                      height: '38px',
                      border: '1px solid',
                      borderColor: '#E7E9ED',
                      p: '8px 12px',
                      fontSize: '14px',
                    }}
                  />
                  <FormErrorMessage>
                    {String(errors.meeting_location)}
                  </FormErrorMessage>
                </FormControl>
              )}

              <Flex mb="24px" gap="10px" alignItems="flex-end">
                <FormControl isInvalid={Boolean(!!errors.date && touched.date)}>
                  <FormLabel fontSize="14px" lineHeight="18px">
                    Date{' '}
                    <Box as="span" color="caution.800">
                      *
                    </Box>
                  </FormLabel>
                  <AtsDatePicker
                    minDate={schedInterview === '0' ? new Date() : null}
                    onChange={(e: any) => {
                      setFieldValue('date', e);

                      setSelectedDate(moment(e).format('MM-DD-YYYY'));
                    }}
                    selected={values.date}
                  />
                  <FormErrorMessage>{String(errors.date)}</FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={Boolean(!!errors.start_time && touched.start_time)}
                >
                  <FormLabel fontSize="14px" lineHeight="18px">
                    Time{' '}
                    <Box as="span" color="caution.800">
                      *
                    </Box>
                  </FormLabel>
                  <AtsSelect3
                    id="start_time"
                    name="start_time"
                    placeholder="--:-- --"
                    variant="outline"
                    className={'MyDropdownStartTime'}
                    classNamePrefix={'MyDropdownStartTime'}
                    onChange={(e: any) =>
                      handleStartTimeChange(e, values, setFieldValue)
                    }
                    filterOption={(option: any, rawInput: string) =>
                      filterStartTimeOptions(
                        option,
                        rawInput,
                        values.start_time.value
                      )
                    }
                    isMulti={false}
                    defaultValue={values.start_time}
                    options={startTimeOptionsAll}
                    value={values.start_time}
                    styles={{
                      control: (styles: any) => ({
                        ...styles,
                        fontSize: '14px',
                        border: '1px solid #E7E9ED',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        boxShadow: 'none',
                        '&:hover': {},
                      }),
                      dropdownIndicator: (styles: any) => ({
                        ...styles,
                        color: '#676767',
                      }),
                      indicatorSeparator: () => ({
                        display: 'none',
                      }),
                      placeholder: (styles: any) => ({
                        ...styles,
                        fontSize: '14px',
                        color: '#9B9B9B',
                      }),
                      multiValue: (styles: any) => ({
                        ...styles,
                        background: '#F7FAFC',
                        border: '1px solid #E2E8F0',
                        borderRadius: '6px',
                        '&& div:first-of-type': {
                          color: '#2B2D42',
                        },
                      }),
                    }}
                  />
                  <FormErrorMessage>
                    {String((errors.start_time as any)?.label)}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={Boolean(!!errors.end_time && touched.end_time)}
                >
                  <AtsSelect3
                    id="end_time"
                    name="end_time"
                    ref={endTimeRef}
                    placeholder="--:-- --"
                    variant="outline"
                    className={'MyDropdownEndTime'}
                    classNamePrefix={'MyDropdownEndTime'}
                    onChange={(e: any) =>
                      handleEndTimeChange(e, values, setFieldValue)
                    }
                    filterOption={(option: any, rawInput: string) =>
                      filterEndTimeOptions(
                        option,
                        rawInput,
                        values.end_time.value
                      )
                    }
                    isMulti={false}
                    defaultValue={values.end_time}
                    options={endTimeOptionsAll}
                    value={values.end_time}
                    styles={{
                      control: (styles: any) => ({
                        ...styles,
                        fontSize: '14px',
                        border: '1px solid #E7E9ED',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        boxShadow: 'none',
                        '&:hover': {},
                      }),
                      dropdownIndicator: (styles: any) => ({
                        ...styles,
                        color: '#676767',
                      }),
                      indicatorSeparator: () => ({
                        display: 'none',
                      }),
                      placeholder: (styles: any) => ({
                        ...styles,
                        fontSize: '14px',
                        color: '#9B9B9B',
                      }),
                      multiValue: (styles: any) => ({
                        ...styles,
                        background: '#F7FAFC',
                        border: '1px solid #E2E8F0',
                        borderRadius: '6px',
                        '&& div:first-of-type': {
                          color: '#2B2D42',
                        },
                      }),
                    }}
                  />
                  <FormErrorMessage>
                    {String((errors.end_time as any)?.label)}
                  </FormErrorMessage>
                </FormControl>
              </Flex>

              <Flex mb="24px" gap="10px">
                <FormControl
                  isInvalid={Boolean(!!errors.timezone && touched.timezone)}
                >
                  <FormLabel fontSize="14px" lineHeight="18px">
                    Timezone{' '}
                    <Box as="span" color="caution.800">
                      *
                    </Box>
                  </FormLabel>

                  <AtsSelect3
                    id="timezone"
                    name="timezone"
                    variant="outline"
                    defaultValue={values.timezone}
                    value={values.timezone}
                    onChange={async (e: any) =>
                      handleTimezoneChange(e, values, setFieldValue)
                    }
                    options={timezones}
                    styles={{
                      control: (styles: any) => ({
                        ...styles,
                        fontSize: '14px',
                        border: '1px solid #E7E9ED',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        boxShadow: 'none',
                        '&:hover': {},
                      }),
                      dropdownIndicator: (styles: any) => ({
                        ...styles,
                        color: '#676767',
                      }),
                      indicatorSeparator: () => ({
                        display: 'none',
                      }),
                      placeholder: (styles: any) => ({
                        ...styles,
                        fontSize: '14px',
                        color: '#9B9B9B',
                      }),
                      multiValue: (styles: any) => ({
                        ...styles,
                        background: '#F7FAFC',
                        border: '1px solid #E2E8F0',
                        borderRadius: '6px',
                        '&& div:first-of-type': {
                          color: '#2B2D42',
                        },
                      }),
                    }}
                  />
                  <FormErrorMessage>{String(errors.timezone)}</FormErrorMessage>
                </FormControl>
              </Flex>

              <Box>
                <FormLabel fontSize="14px" lineHeight="18px">
                  Panel Members
                </FormLabel>
                <Field
                  id="panel_members"
                  name="panel_members"
                  component={AtsSelectContact}
                  // defaultValue={values.contact}
                  value={values.panel_members}
                  // onChange={() => {}}
                  client={company}
                  // onChange={(event: any) =>
                  //   setFieldValue('cc', event)
                  // }
                  hasCreate={values.set_interview === '0'}
                  options={panelOption}
                  isMulti={true}
                  placeholder="Type Panel Members"
                  defaultValue={values.panel_members}
                  onInputChange={(e: any) =>
                    onPanelInputChange(e, values.set_interview)
                  }
                  isSaveInput={true}
                  styles={{
                    control: (styles: any) => ({
                      ...styles,
                      fontSize: '14px',
                      border: '1px solid #E7E9ED',
                      borderRadius: '4px',
                      cursor: 'pointer',
                      boxShadow: 'none',
                      '&:hover': {},
                    }),
                    dropdownIndicator: (styles: any) => ({
                      ...styles,
                      color: '#676767',
                    }),
                    indicatorSeparator: () => ({
                      display: 'none',
                    }),
                    placeholder: (styles: any) => ({
                      ...styles,
                      fontSize: '14px',
                      color: '#9B9B9B',
                    }),
                    multiValue: (styles: any) => ({
                      ...styles,
                      background: '#F7FAFC',
                      border: '1px solid #E2E8F0',
                      borderRadius: '6px',
                      '&& div:first-of-type': {
                        color: '#2B2D42',
                      },
                    }),
                  }}
                />
              </Box>
            </Box>

            <ModalFooter
              position="sticky"
              bottom="0"
              background="default.white.100"
              boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
              gap={5}
              p="10px"
              zIndex={2}
            >
              <Flex width="100%" justifyContent="space-between">
                <Button
                  variant="outline"
                  onClick={onOpenNotes}
                  left="0px"
                  color="primary.800"
                >
                  Notes
                </Button>
                <Flex gap="10px">
                  <Button1
                    variant="borderless"
                    onClick={() => {
                      onClose();
                      dispatch(
                        listTemplate({ aiTemplate: [], templateRawList: [] })
                      );
                    }}
                  >
                    Cancel
                  </Button1>
                  <Button1
                    variant="solid"
                    type="submit"
                    onClick={() => {
                      updatePlaceholders(values);
                    }}
                  >
                    Next
                  </Button1>
                </Flex>
              </Flex>
            </ModalFooter>

            {isOpenPreview && (
              <PreviewDetails
                isOpen={isOpenPreview}
                onClose={onClosePreview}
                param={PrevParam}
              />
            )}
            {isOpenNotes && (
              <NotesModal isOpen={isOpenNotes} onClose={onCloseNotes} />
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default InterviewDetails;
