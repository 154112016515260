import { createSlice } from '@reduxjs/toolkit';
import { TALIE_LIST } from 'components/app/TalieAI/Drawer/constant';
import authService from 'services/auth.service';
import { apiSlice } from './api.slice';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    isCongrats: false,
    isTalieShow: false,
    talieList: [],
    isBackDrop: false,
    hasSwitchedAccount: null,
    settingsTabIndex: { id: 0, title: 'Users' },
  },
  reducers: {
    appError: (state, { payload }) => {
      const err: any = payload;
      if (err?.status == 403) {
        authService.logout();
      }
      return { ...state, error: payload };
    },
    updateApp: (state, { payload }) => ({ ...state, ...payload }),
  },
  extraReducers: () => {},
});

export const { appError, updateApp } = appSlice.actions;
export default appSlice.reducer;

const appApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    PostGenerateAssistant: builder.mutation({
      query: ({ data }) => ({
        url: '/ai/assistant',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
    }),
    PostGetThread: builder.mutation({
      query: ({ data }) => ({
        url: '/ai/assistant/current',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
    }),
  }),
});

export const { usePostGenerateAssistantMutation, usePostGetThreadMutation } =
  appApiSlice;
