import { Button, Input, Stack, Text } from '@chakra-ui/react';
import { useBuzzConnectionLoginServices } from './useBuzzConnectionLoginServices';

interface BuzzConnectionLoginProps {
  handleLogin: () => void;
}

export const BuzzConnectionLogin = ({
  handleLogin,
}: BuzzConnectionLoginProps) => {
  const {
    username,
    password,
    handleInputUsername,
    handleInputPassword,
    handleSubmit,
    isLoading,
  } = useBuzzConnectionLoginServices({ handleLogin });
  return (
    <Stack
      spacing="32px"
      fontFamily="NunitoSans Regular"
      sx={{
        bgColor: '#fff',
        border: '1px solid #E7EDF4',
        borderRadius: '5px',
        margin: '0 auto',
        p: '32px',
        width: '50%',
      }}
    >
      <Stack spacing="8px">
        <Text sx={{ fontSize: '16px', color: '#2B2D42' }}>Email</Text>
        <Input
          type="email"
          placeholder="Enter your email"
          value={username}
          onChange={handleInputUsername}
        />
      </Stack>

      <Stack spacing="8px">
        <Text sx={{ fontSize: '16px', color: '#2B2D42' }}>Password</Text>
        <Input
          type="password"
          placeholder="Enter your password"
          value={password}
          onChange={handleInputPassword}
        />
      </Stack>

      <Button
        variant="solid"
        type="button"
        sx={{
          bgcolor: '#6930CA',
          p: '16px',
          fontSize: '16px',
        }}
        _hover={{}}
        onClick={handleSubmit}
        isLoading={isLoading}
      >
        Sign in
      </Button>
    </Stack>
  );
};
