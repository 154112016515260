import { Flex, Stack, Text } from '@chakra-ui/react';
import CheckboxComponent from '../Component';
import { status } from '../constants';

export default function List(props: any) {
  const { items, compute, counts } = props;

  const hasAll = items?.items?.length > 0;

  return (
    <Stack
      sx={{
        pl: '13px',
      }}
    >
      {items.map((item: any, index: any) => {
        let childList = null;
        if (Array.isArray(item.items)) {
          childList = (
            <List items={item.items} compute={compute} counts={counts} />
          );
        }

        return (
          <div key={item.id}>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              w="100%"
              sx={{
                pl: hasAll && item.name !== 'All' && '18px !important',
                mb: item.name === 'All' && '10px',
              }}
            >
              <Flex gap="8px" alignItems="center">
                <CheckboxComponent
                  id={item.id}
                  name={item.name}
                  checked={item.status === status.checked}
                  indeterminate={item.status === status.indeterminate}
                  compute={compute}
                />
                <Text
                  sx={{
                    fontSize: '14px',
                    fontWeight: item.name === 'All' ? 600 : 400,
                    color: '#2B2D42',
                  }}
                >
                  {item.name}
                </Text>
                {counts && Object.keys(counts).length ? (
                  <Flex
                    bgColor={'#6930CA14'}
                    color="#6930CA"
                    borderRadius={'2px'}
                    minW={'23px'}
                    h={'18px'}
                    justifyContent="center"
                    alignItems="center"
                    p={'4px'}
                  >
                    <Text fontSize={'12px'} fontWeight={600}>
                      {counts[item.countId]}
                    </Text>
                  </Flex>
                ) : null}
              </Flex>
            </Flex>
            {childList}
          </div>
        );
      })}
    </Stack>
  );
}
