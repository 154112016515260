import { placeholderUrl } from '../utils/util';

export const DEFAULT_VALUES = {
  job_ad: {
    job_description: `
    <p><b>Job Title</b>: </p>
    <p><b>Location</b>: </p>
    <p><b>Salary</b>: </p>
    <p><b>Skills</b>: </p>
    <br/>
    <p><b>About Company / Opportunity</b>: </p>
    <br/>
    <p><b>Responsibilities</b>: </p>
    <br/>
    <p><b>Must-Have Skills</b>: </p>
    <ol style="list-style-position: inside; padding-left: 0;">
        <li></li>
        <li></li>
        <li></li>
    </ol>
    <br/>
    <p><b>Nice to Have Skills</b>: </p>
    <ol style="list-style-position: inside;  padding-left: 0;">
        <li></li>
        <li></li>
        <li></li>
    </ol>
`,
  },
};
export const CANDIDATE_PLACEHOLDER_PAIR = {
  candidate_first_name: 'Harvey',
  candidate_full_name: 'Harvey Barnes',
  candidate_email: 'harvey.barnes@email.com',
  candidate_current_position: 'Lead developer',
  candidate_current_company: 'Google',
  candidate_mobile: '89XXXXXXX7',
  candidate_linkedin_url: placeholderUrl(
    'https://www.linkedin.com/in/harvey.barnes.1234.dummmy/',
    'https://www.linkedin.com/in/harvey.barnes.1234.dummmy/'
  ),
  candidate_work_status: 'Need Visa',
  candidate_city: 'Neptune',
  candidate_state: 'New Jersey',
  candidate_total_years_of_exp: '5',
  candidate_degree: 'Bachelors Degree',
};
