import { Modal, Text, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Flex, FormControl, FormLabel, Input, Box, FormErrorMessage, Textarea, InputGroup, Alert, AlertIcon, AlertTitle, UnorderedList, ListItem, AlertDescription, CloseButton } from "@chakra-ui/react";
import AtsSelect from "components/app/AtsSelect";
import AtsSelect2 from "components/app/AtsSelect2";
import ScrollToFieldError from "components/app/ScrollError";
import { Field, Formik } from "formik";
import Button from "Library/Button";
import { TeamModalProps } from "./types";
import useModalTeamsServices from "./useModalTeamsServices";

export default function TeamModal({ isOpen, isOpenAlert, onCloseAlert, onClose, errMsg, errorList, setErrorList, resCreate, resUpdate, isEdit, initialValues, validationSchema, onSubmit }: TeamModalProps) {
    const { handleOptions, formRef, handleOptionChange, handleOptionValue, } = useModalTeamsServices({})

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="4xl"
            scrollBehavior="inside"
        >
            <ModalOverlay />
            <ModalContent color="default.primarytext" fontFamily="NunitoSans Regular"
                borderRadius="lg">
                <ModalHeader p="0" >
                    <Flex
                        borderTopRadius="lg"
                        alignItems="center"
                        bg="default.white.800">
                        <Flex px="6" py="2" w="100%" fontSize="lg">
                            <Text w="100%">{isEdit ? "Edit Team" : "Add Team"}</Text>
                        </Flex>

                        <ModalCloseButton
                            position="relative"
                            top="auto"
                            color="default.secondarytext"
                        />
                    </Flex>

                </ModalHeader>
                {isOpenAlert && (
                    <Box>
                        <Alert
                            status="error"
                            alignItems="flex-start"
                            justifyContent="flex-start"
                        >
                            <Flex gap="20px" position="relative" left="20px">
                                <AlertIcon />
                                <Box>
                                    <AlertTitle>Oh snap!</AlertTitle>
                                    <AlertDescription>
                                        {errMsg || (
                                            <>
                                                There were some problems in you input. Change a few
                                                things up and try again.
                                                <UnorderedList
                                                    mt="10px"
                                                    fontSize="13px"
                                                    lineHeight="1.5"
                                                    color="var(--chakra-colors-red-500)"
                                                >
                                                    {Object.values(errorList).map((key: any) => (
                                                        <ListItem key={key}>{key}</ListItem>
                                                    ))}
                                                </UnorderedList>
                                            </>
                                        )}
                                    </AlertDescription>
                                </Box>
                            </Flex>

                            <CloseButton
                                position="absolute"
                                right="20px"
                                onClick={onCloseAlert}
                            />
                        </Alert>
                    </Box>
                )}
                <ModalBody p="0" >
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                        innerRef={formRef}

                    >
                        {({ handleSubmit, handleChange, setFieldValue, values, errors, touched, }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <ScrollToFieldError />
                                    <Flex
                                        mx="24"
                                        my="5"
                                        h="100%"
                                        bg="white"
                                        direction="column"
                                        borderRadius="md"
                                        p="4"
                                        border="1px solid #EEEEEE"
                                        fontSize="md"
                                        gap="6"
                                    >
                                        <Flex direction="column">
                                            <FormControl
                                                isInvalid={Boolean(
                                                    !!errors.name && touched.name
                                                )}
                                            >
                                                <FormLabel
                                                    fontSize="md"
                                                    color="black"
                                                    lineHeight="18px"
                                                    fontWeight="bold"
                                                >
                                                    Name{' '}
                                                    <Box as="span" color="caution.800">
                                                        *
                                                    </Box>
                                                </FormLabel>
                                                <Input
                                                    id="name"
                                                    name="name"
                                                    type="text"
                                                    placeholder="Name"
                                                    variant="outline"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                />
                                                <FormErrorMessage>
                                                    {String(errors.name)}
                                                </FormErrorMessage>
                                            </FormControl>
                                        </Flex>
                                        {/* <Flex direction="column">
                                            <FormControl
                                                isInvalid={Boolean(
                                                    !!errors.description && touched.description
                                                )}
                                            >
                                                <FormLabel
                                                    fontSize="md"
                                                    color="black"
                                                    lineHeight="18px"
                                                    fontWeight="bold"
                                                >
                                                    Description{' '}
                                                    <Box as="span" color="caution.800">
                                                        *
                                                    </Box>
                                                </FormLabel>
                                                <Textarea
                                                    id="description"
                                                    name="description"
                                                    placeholder="Name"
                                                    variant="outline"
                                                    value={values.description}
                                                    onChange={handleChange}
                                                />
                                                <FormErrorMessage>
                                                    {String(errors.description)}
                                                </FormErrorMessage>
                                            </FormControl>
                                        </Flex> */}
                                        <Flex direction="column">
                                            <FormControl
                                                w="100%"
                                                isInvalid={Boolean(
                                                    !!errors.members && touched.members
                                                )}
                                            >
                                                <FormLabel
                                                    fontSize="md"
                                                    color="black"
                                                    lineHeight="18px"
                                                    fontWeight="bold"
                                                >
                                                    Select Team Members{' '}
                                                    <Box as="span" color="caution.800">
                                                        *
                                                    </Box>
                                                </FormLabel>
                                                <AtsSelect
                                                    id="members"
                                                    name="members"
                                                    placeholder="Select"
                                                    value={handleOptionValue(values.members)}
                                                    onChange={(e: any) => handleOptionChange("members", e, setFieldValue)}
                                                    options={handleOptions(values.members, values.manager)}
                                                    isMulti={true}
                                                />
                                                <FormErrorMessage>
                                                    {String(errors.members)}
                                                </FormErrorMessage>
                                            </FormControl>
                                        </Flex>
                                        <Flex direction="column">
                                            <FormControl
                                                w="100%"
                                                isInvalid={Boolean(
                                                    !!errors.manager && touched.manager
                                                )}
                                            >
                                                <FormLabel
                                                    fontSize="md"
                                                    color="black"
                                                    lineHeight="18px"
                                                    fontWeight="bold"
                                                >
                                                    Select Team Manager{' '}
                                                    <Box as="span" color="caution.800">
                                                        *
                                                    </Box>
                                                </FormLabel>
                                                <AtsSelect
                                                    id="manager"
                                                    name="manager"
                                                    placeholder="Select"
                                                    value={handleOptionValue(values.manager)}
                                                    onChange={(e: any) => handleOptionChange("manager", e, setFieldValue)}
                                                    options={handleOptions(values.members, values.manager)}
                                                    isMulti={false}
                                                />
                                                <FormErrorMessage>
                                                    {String(errors.manager)}
                                                </FormErrorMessage>
                                            </FormControl>
                                        </Flex>
                                    </Flex>
                                </form>
                            )
                        }}

                    </Formik>
                </ModalBody>
                <ModalFooter p='0'>
                    <Flex
                        px="6"
                        py="2"
                        w="100%"
                        borderTop="1px"
                        borderColor="default.gray.200"
                        justifyContent="flex-end"
                        alignItems="center"
                        gap="4"
                    >
                        <Button variant="outline" onClick={onClose}>Close</Button>
                        <Button
                            disabled={
                                isOpenAlert ||
                                resCreate.isLoading ||
                                resUpdate.isLoading
                            }
                            variant="solid"
                            loading={resCreate.isLoading || resUpdate.isLoading}
                            onClick={() => {
                                setErrorList(formRef?.current?.errors);
                                formRef.current?.handleSubmit()
                            }}
                        >
                            Submit
                        </Button>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}