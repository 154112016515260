import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Textarea,
} from '@chakra-ui/react';
import AtsDropZone from 'components/app/Dropzone';
import { Field, FieldProps } from 'formik';
import { InitialValueType } from '../type';

export default function NotesAndAttachments() {
  return (
    <>
      <Box mb="34px">
        <FormControl mb="3">
          <FormLabel>Attachments</FormLabel>
        </FormControl>
        <AtsDropZone
          multipleFile={true}
          uploadedEnd={(e: any) => {}}
          deletedFile={(key: string) => {}}
        />
      </Box>

      <Field name="notes">
        {({
          form: { errors, touched, values, handleChange },
        }: FieldProps<InitialValueType>) => (
          <FormControl
            isInvalid={Boolean(!!errors.lead_owner && touched.lead_owner)}
          >
            <FormLabel>Notes</FormLabel>
            <Field
              as={Textarea}
              id="notes"
              name="notes"
              type="text"
              placeholder="Here is a sample placeholder"
              variant="outline"
              value={values.notes}
              onChange={handleChange}
            />
            <Text
              mt="8px"
              fontSize="12px"
              lineHeight="18px"
              color="default.secondarytext"
            >
              Maximum 200 characters allowed
            </Text>
            <FormErrorMessage>{String(errors.notes)}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
    </>
  );
}
