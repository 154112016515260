import {
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

export default function TableLoader({ header }: { header: Array<any> }) {
  return (
    <TableContainer
      boxSizing="border-box"
      border="1px solid"
      borderColor="default.white.400"
      borderRadius="md"
      height="inherit"
      sx={{ overflowY: 'auto' }}
      minH="100%"
      maxH="100%"
    >
      <Table>
        <Thead>
          <Tr bg="#F9F9FB">
            {header.map((header, i) => (
              <Th key={i} fontSize="12px" color="#718096">
                {header}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody
          boxSizing="border-box"
          background="default.white.100"
          borderBottom="1px solid"
          borderColor="default.white.400"
        >
          {[1, 2, 3, 4, 5].map((num) => (
            <Tr color="default.primaryText" key={num}>
              {header.map((header, i) => (
                <Td key={i}>
                  <Skeleton width="100%" height="30px" />
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
