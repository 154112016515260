import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools'; // import utility for setting light and dark mode props

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

// define custom variants
const capsuleVariant = definePartsStyle((props) => {
  const { colorScheme: c } = props; // add colorScheme as a prop

  return {
    tab: {
      border: '2px solid',
      borderColor: 'transparent',
      bg: mode(`${c}.300`, `${c}.600`)(props),
      borderRadius: '10px',
      fontWeight: 700,
      color: '#718096',
      px: 4,
      py: 1.5,
      _selected: {
        bg: mode('#fff', 'gray.800')(props),
        color: mode(`${c}.800`, `${c}.300`)(props),
        borderColor: 'none',
      },
    },
    tablist: {
      bgColor: '#FAF6FF',
      padding: '4px',
      borderRadius: '10px',
    },
  };
});

const variants = {
  capsule: capsuleVariant,
};

// export the component theme
export const Tabs = defineMultiStyleConfig({
  variants,
});
