/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  initialCandidatePgBtn,
  updateOutreach,
  usePostListCandidatesMutation,
} from 'store/outreach.slice';

import { useExportCSVMutation } from 'store/outreach.slice';

export const useCandidateTabPanelServices = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const { settingTypeId } = useParams();
  const { sequencesData } = useSelector((state: any) => state.outreach);
  const { candidateSequencePgBtn } = useSelector(
    (state: any) => state.outreach
  );

  const [reqCandidateList] = usePostListCandidatesMutation();
  const [reqExport, resExport] = useExportCSVMutation();

  const [sequenceStatus, setSequenceStatus] = useState([]);

  const handleExport = () => {
    reqExport({ id: Number(settingTypeId) });
  };

  useEffect(() => {
    if (resExport.isSuccess) {
      const { data, fileName } = resExport.data.data;
      const blob = new Blob([data], { type: 'text/csv' });

      // Creating an object for downloading url
      const url = window.URL.createObjectURL(blob);

      // Creating an anchor(a) tag of HTML
      const a = document.createElement('a');

      // Passing the blob downloading url
      a.setAttribute('href', url);

      // Setting the anchor tag attribute for downloading
      // and passing the download file name
      a.setAttribute('download', fileName);

      // Performing a download with click
      a.click();
    }
  }, [resExport.isSuccess]);

  useEffect(() => {
    if (sequenceStatus) {
      const payload = {
        ...initialCandidatePgBtn,
        query: candidateSequencePgBtn.query,
        sequence_id: settingTypeId,
        sequence_candidate_status: sequenceStatus,
      };

      reqCandidateList(payload);
      dispatch(updateOutreach({ candidateSequencePgBtn: payload }));
    }
  }, [sequenceStatus]);

  const calculatePercentage = (count: number, total: number) => {
    if (total === 0 || count === 0) {
      return 'N/A';
    } else {
      const percentage = Math.round((count / total) * 100).toString();
      return percentage.endsWith('.00')
        ? percentage.slice(0, -3) + '%'
        : percentage + '%';
    }
  };

  const content: any = [
    {
      title: 'All Candidates',
      tooltip: 'Total number of candidates that exist in the sequences',
      value: sequencesData?.sequence_candidate_count_all,
      status: [],
      to: 'all',
    },
    {
      title: 'Ongoing',
      tooltip:
        'Total number of candidates who haven′t finished being sent email steps in the sequence',
      value: sequencesData?.sequence_candidate_count_ongoing,
      status: [1, 2, 3],
      to: 'ongoing',
    },
    {
      title: 'Messaged',
      tooltip: 'Total number of candidates who were messaged',
      value: sequencesData?.sequence_candidate_count_messaged,
      status: [2, 3, 4, 5],
      to: 'messaged',
    },
    {
      title: 'Opened',
      tooltip:
        'Percentage of candidates who have opened a message based on the number of contacts who were messaged.Total number of candidates who were messaged',
      value: calculatePercentage(
        sequencesData?.sequence_candidate_count_opened,
        sequencesData?.sequence_candidate_count_messaged
      ),
      status: [3, 4, 6],
      to: 'opened',
    },
    {
      title: 'Clicked',
      tooltip:
        'Percentage of candidates who have clicked a link within a message based on the number of contacts who were messaged.',
      value: calculatePercentage(
        sequencesData?.sequence_candidate_count_clicked,
        sequencesData?.sequence_candidate_count_messaged
      ),
      status: [6],
      to: 'clicked',
    },
    {
      title: 'Replied',
      tooltip:
        'Percentage of candidates who have replied to the message based on the number of contacts who were messaged.',
      value: calculatePercentage(
        sequencesData?.sequence_candidate_count_replied,
        sequencesData?.sequence_candidate_count_messaged
      ),
      status: [4],
      to: 'replied',
    },
    {
      title: 'Bounced',
      tooltip: 'Total number of candidates′ emails that have bounced.',
      value: sequencesData?.sequence_candidate_count_bounced,
      status: [5],
      to: 'bounced',
    },
    {
      title: 'Take Action',
      tooltip: 'The candidates have been messaged in the last 30 days',
      value: sequencesData?.sequence_candidate_count_take_action,
      status: [8, 9, 10],
      to: 'take-action',
    },
    {
      title: 'Others',
      tooltip: '',
      value: sequencesData?.sequence_candidate_count_others,
      status: [11, 12, 13, 14],
      to: 'others',
    },
  ];

  useEffect(() => {
    if (params.settingSubType) {
      const settingSubType = content?.filter(
        (val: any) => val.to === params.settingSubType
      )[0];
      setSequenceStatus(settingSubType?.status);
    }
  }, []);

  return {
    content,
    handleExport,
    sequenceStatus,
    setSequenceStatus,
  };
};
