import { Box, Flex, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import FAIcon from "components/lib/FAIcon";
import Button from "Library/Button";
import TeamModal from "./components/Modal";
import TeamsTable from "./components/Table";
import TeamDrawer from "./components/Drawer";
import { TeamsProps } from "./types";
import useTeamsServices from "./useTeamsServices";

export default function Teams({ }: TeamsProps) {
    const {
        isOpenTeamModal,
        isOpenViewTeam,
        isOpenAlert,
        onCloseAlert,
        isEdit,
        resTeams,
        resCreate,
        resUpdate,
        errMsg,
        errorList,
        teamList,
        teams,
        selectedViewTeam,
        initialValues,
        validationSchema,
        setErrorList,
        handleSubmit,
        handleEditTeam,
        handleSearch,
        handleDeleteTeam,
        handleOpenTeamModal,
        handleCloseTeamModal,
        handleViewTeam,
        handleCloseViewTeam,
    } = useTeamsServices({})

    return (
        <Box
            fontFamily="NunitoSans Regular"
        >
            <Flex justifyContent="space-between" mb="16px" px={1}>
                <Flex justifyContent="space-around" maxWidth="80%" alignItems="center">
                    <Box pr={2}>
                        <InputGroup background="#fff">
                            <InputRightElement
                                cursor="pointer"
                                children={<FAIcon iconName="search" />}
                            />
                            <Input
                                type="search"
                                placeholder="Search by team name"
                                id="search"
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                        </InputGroup>
                    </Box>

                </Flex>

                <Flex gap={5}>
                    <Button
                        leftIcon="plus"
                        variant="solid"
                        onClick={handleOpenTeamModal}
                    >
                        Add Team
                    </Button>
                </Flex>
            </Flex>
            <TeamsTable
                handleViewTeam={handleViewTeam}
                handleEdit={handleEditTeam}
                handleDelete={handleDeleteTeam}
                resTeams={resTeams}
                teams={teams}
            />

            {isOpenTeamModal && (
                <TeamModal
                    isOpen={isOpenTeamModal}
                    isOpenAlert={isOpenAlert}
                    onCloseAlert={onCloseAlert}
                    onClose={handleCloseTeamModal}
                    errMsg={errMsg}
                    errorList={errorList}
                    setErrorList={setErrorList}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    isEdit={isEdit}
                    resCreate={resCreate}
                    resUpdate={resUpdate}
                />
            )}
            {isOpenViewTeam && (
                <TeamDrawer
                    isOpen={isOpenViewTeam}
                    onClose={handleCloseViewTeam}
                    values={selectedViewTeam}
                    handleEdit={handleEditTeam}
                    handleDelete={handleDeleteTeam}
                    rowId={1}
                    total={teamList.length}
                />
            )}
        </Box>
    )
}