import { Box, Flex, IconButton, Link } from '@chakra-ui/react';
import FAIcon from 'components/lib/FAIcon';
import React from 'react';

interface PaginationInterface {
  isBlur: boolean;
  jobDetail: {
    jobTitle: string;
    jobLink: string;
    clientLink: string;
    clientTitle: string;
  };
  pagination: {
    present: number;
    total: number;
    onNext: () => void;
    onPrevious: () => void;
  };
}

export default function Pagination({
  isBlur,
  jobDetail,
  pagination,
}: PaginationInterface) {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      gap="18px"
      pl={4}
      pr={0}
      borderBottom="1px solid #E6E9EE"
      fontSize="14px"
      sx={{ filter: isBlur && 'blur(2px)' }}
    >
      <Box
        id={`tagTooltip`} //TODO: remove it if it is unused
        color="primary.600"
        fontSize="12px"
        lineHeight="18px"
        fontWeight={500}
        height="fit-content"
      >
        {jobDetail && (
          <Flex gap="5px">
            <Link href={jobDetail.jobLink} target="_blank" rel="noreferrer">
              {jobDetail.jobTitle}
            </Link>
            -
            <Link href={jobDetail.clientLink} target="_blank" rel="noreferrer">
              {jobDetail.clientTitle}
            </Link>
          </Flex>
        )}
      </Box>
      <Flex alignItems="center">
        <>
          <Flex gap="8px" mr="12px">
            Showing <b>{pagination.present}</b> of <b>{pagination.total}</b>
          </Flex>
          <Flex>
            <IconButton
              aria-label="previous-btn"
              variant="ghost"
              icon={<FAIcon iconName="chevron-left" />}
              borderLeft="1px solid #6B6C7B"
              borderRadius="unset"
              onClick={pagination.onPrevious}
            />
            <IconButton
              aria-label="next-btn"
              variant="ghost"
              icon={<FAIcon iconName="chevron-right" />}
              borderLeft="1px solid #6B6C7B"
              borderRadius="unset"
              onClick={pagination.onNext}
            />
          </Flex>
        </>
      </Flex>
    </Flex>
  );
}
