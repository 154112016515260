export function getDaysRemaining(startDate: Date, endDate: Date) {
    // Convert both dates to milliseconds
    const startMs = startDate.getTime();
    const endMs = endDate.getTime();

    // Calculate the difference in milliseconds
    const differenceMs = endMs - startMs;

    // Convert the difference to days
    const daysRemaining = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

    return daysRemaining;
}
