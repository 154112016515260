import * as Yup from 'yup';

import { Field, FieldArray, Formik } from 'formik';

import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Checkbox,
} from '@chakra-ui/react';
import ScrollToFieldError from 'components/app/ScrollError';
import Button from 'Library/Button';
import moment from 'moment';

interface initialValueInt {
  experiences: {
    company: string;
    job_title: string;
    start_date: string;
    end_date: string;
    currently_pursuing: boolean;
  }[];
}

interface CandidateExperienceProps {
  initialValues: initialValueInt;
  onSubmit: (e: any) => void;
  CandidateModalFooter?: any;
}

export default function CandidateExperience({
  initialValues,
  onSubmit,
  CandidateModalFooter,
}: CandidateExperienceProps) {
  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      experiences: Yup.array().of(
        Yup.object().shape({
          company: Yup.string(),
          job_title: Yup.string(),
          start_date: Yup.string(),
          end_date: Yup.string(),
          currently_pursuing: Yup.boolean(),
        })
      ),
    })
  );

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          isValid,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <ScrollToFieldError />
            {/* Experience Details */}
            <Box
              overflowY="scroll"
              maxHeight="450px"
              p="30px"
              background="#fff"
              borderRadius="8px"
              minHeight="450px"
            >
              <FieldArray name="experiences">
                {({ remove, push }) => (
                  <div>
                    {values.experiences?.length > 0 &&
                      values.experiences?.map((experience: any, index: any) => {
                        experience.start_date = moment(
                          experience.start_date
                        ).format('YYYY-MM-DD');
                        experience.end_date = moment(
                          experience.end_date
                        ).format('YYYY-MM-DD');
                        return (
                          <div className="row" key={index}>
                            <FormControl mb="34px">
                              <Flex
                                justifyContent="space-between"
                                alignItems="flex-end"
                              >
                                <FormLabel
                                  fontSize="14px"
                                  lineHeight="18px"
                                  color="default.secondarytext"
                                >
                                  Company
                                </FormLabel>
                                {values.experiences?.length > 0 && (
                                  <Box mb="10px">
                                    <Button
                                      leftIcon="close"
                                      onClick={() => remove(index)}
                                      size="sm"
                                    >
                                      Remove
                                    </Button>
                                  </Box>
                                )}
                              </Flex>
                              <Field
                                as={Input}
                                name={`experiences.${index}.company`}
                                placeholder="Company"
                                type="text"
                                variant="outline"
                                value={experience.company}
                                onChange={handleChange}
                              />
                              <FormErrorMessage>
                                {String(`experiences${index}.company`)}
                              </FormErrorMessage>
                            </FormControl>
                            <FormControl mb="34px">
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                Job Title
                              </FormLabel>
                              <Field
                                as={Input}
                                name={`experiences.${index}.job_title`}
                                placeholder="Job Title"
                                type="text"
                                variant="outline"
                                value={experience.job_title}
                                onChange={handleChange}
                              />
                              <FormErrorMessage>
                                {String(`experiences${index}.job_title`)}
                              </FormErrorMessage>
                            </FormControl>
                            <Flex gap="32px" mb="16px">
                              <FormControl>
                                <FormLabel
                                  fontSize="14px"
                                  lineHeight="18px"
                                  color="default.secondarytext"
                                >
                                  Start Date
                                </FormLabel>
                                <Field
                                  as={Input}
                                  name={`experiences.${index}.start_date`}
                                  placeholder="Select Date"
                                  size="md"
                                  type="date"
                                  value={experience.start_date}
                                  onChange={handleChange}
                                />
                              </FormControl>
                              <FormControl>
                                <FormLabel
                                  fontSize="14px"
                                  lineHeight="18px"
                                  color="default.secondarytext"
                                >
                                  End Date
                                </FormLabel>
                                <Field
                                  as={Input}
                                  name={`experiences.${index}.end_date`}
                                  placeholder="Select Date"
                                  size="md"
                                  type="date"
                                  value={experience.end_date}
                                  onChange={handleChange}
                                />
                              </FormControl>
                            </Flex>

                            <Box mb="32px">
                              <Checkbox
                                id={`experiences.${index}.currently_pursuing`}
                                name={`experiences.${index}.currently_pursuing`}
                                isChecked={experience.currently_pursuing}
                                onChange={handleChange}
                              >
                                Currently Pursuing
                              </Checkbox>
                            </Box>
                          </div>
                        );
                      })}
                    <Button
                      leftIcon="plus"
                      onClick={() =>
                        push({
                          company: '',
                          job_title: '',
                          start_date: '',
                          end_date: '',
                          currently_pursuing: false,
                        })
                      }
                    >
                      Add Another
                    </Button>
                  </div>
                )}
              </FieldArray>
            </Box>
            {CandidateModalFooter(handleSubmit, errors, isValid, values)}
          </form>
        )}
      </Formik>
    </>
  );
}
