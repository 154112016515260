import { debounce } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listCandidates } from 'store/candidates.slice';
import { listJobs, useListJobsMutation } from 'store/jobs.slice';

interface useSideBarServicesProps {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const useSideBarServices = ({
  setIsLoading,
}: useSideBarServicesProps) => {
  const dispatch = useDispatch();

  const storageName = 'jobFilter';

  const { jobPgBtn } = useSelector((state: any) => state.jobs);

  const [reqList, resList] = useListJobsMutation();

  const jobFilerData: string | null = localStorage.getItem(storageName);
  const initFilter = jobFilerData == null ? jobPgBtn : JSON.parse(jobFilerData);

  const [searchJobs, setSearchJobs] = useState(initFilter.query);
  const [statusJobTypePayload, setStatusJobTypePayload] = useState<any>(null);
  const [statusJobStatusPayload, setStatusJobStatusPayload] =
    useState<any>(null);

  const jobStatus = [
    {
      id: 1,
      stat_id: { open: true },
      status: initFilter.open ? 1 : 0,
      name: 'Open',
    },
    {
      id: 2,
      stat_id: { closed: true },
      status: initFilter.closed ? 1 : 0,
      name: 'Close',
    },
    {
      id: 3,
      stat_id: { draft: true },
      status: initFilter.draft ? 1 : 0,
      name: 'Draft',
    },
  ];

  const jobType = [
    {
      id: 1,
      stat_id: { quickJobs: true },
      status: initFilter.quickJobs ? 1 : 0,
      name: 'Quick Jobs',
    },
    {
      id: 2,
      stat_id: { full: true },
      status: initFilter.full ? 1 : 0,
      name: 'Full Jobs',
    },
    {
      id: 3,
      stat_id: { job_assigned: true },
      status: initFilter.job_assigned ? 1 : 0,
      name: 'Jobs Assigned to me',
    },
  ];

  // Debounced Search Handler
  const handleSearchJobs = debounce((e: any) => {
    let query = e.target.value;

    if (searchJobs === '') {
      query = '';
    }

    const payload = {
      ...jobPgBtn,
      page: 1,
      query: query,
    };

    if (query.length >= 3 || searchJobs.length >= 3) {
      submitForm(payload);
    }
  }, 300);

  const submitForm = (params: any) => {
    dispatch(
      listJobs({
        jobPgBtn: params,
      })
    );
    fetchJobs(params);
    localStorage.setItem(storageName, JSON.stringify(params));
  };

  // AbortController for fetchJobs
  const abortControllerJobRef = useRef<AbortController | null>(null);

  // Function to Fetch Jobs
  const fetchJobs = async (params: object) => {
    if (abortControllerJobRef.current) {
      abortControllerJobRef.current.abort();
    }

    abortControllerJobRef.current = new AbortController();
    reqList({ data: params, signal: abortControllerJobRef.current.signal });
    dispatch(listCandidates({ candidatesCount: [], candidatesAllCount: 0 }));
  };

  useEffect(() => {
    // Create mappings for job types and job statuses
    const jobTypeMapping: any = {
      quickJobs: true,
      full: true,
      job_assigned: true,
    };

    const jobStatusMapping: any = {
      open: true,
      closed: true,
      draft: true,
    };

    // Create the updated statusJobTypePayload based on the current state
    const updatedJobTypePayload =
      statusJobTypePayload &&
      Object.keys(jobTypeMapping).map((key) => {
        const currentValue = statusJobTypePayload.find(
          (item: any) => item[key]
        )?.[key];
        return {
          [key]:
            currentValue !== undefined ? currentValue : !jobTypeMapping[key],
        };
      });

    // Create the updated statusJobStatusPayload based on the current state
    const updatedJobStatusPayload =
      statusJobStatusPayload &&
      Object.keys(jobStatusMapping).map((key) => {
        const currentValue = statusJobStatusPayload.find(
          (item: any) => item[key]
        )?.[key];
        return {
          [key]:
            currentValue !== undefined ? currentValue : !jobStatusMapping[key],
        };
      });

    // Combine the updated job type and job status payloads
    const combinedSort = Object.assign(
      {},
      ...(updatedJobTypePayload || []),
      ...(updatedJobStatusPayload || [])
    );

    const updatedParam = { ...initFilter, ...combinedSort };

    submitForm(updatedParam);
  }, [statusJobTypePayload, statusJobStatusPayload]);

  useEffect(() => {
    setIsLoading(resList.isLoading);
  }, [resList.isLoading]);

  return {
    searchJobs,
    setSearchJobs,
    handleSearchJobs,
    statusJobTypePayload,
    setStatusJobTypePayload,
    statusJobStatusPayload,
    setStatusJobStatusPayload,
    jobStatus,
    jobType,
  };
};
