const CryptoJS = require('crypto-js');
const key = process.env.REACT_APP_SECRET;
const URL = process.env.REACT_APP_RESUME_URL;

const encrypt = (text: any) => {
  const encrypted = CryptoJS.AES.encrypt(text.toString(), key).toString();
  const urlSafeEncrypted = encrypted
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
  return urlSafeEncrypted;
};

const decrypt = (text: any) => {
  const bytes = CryptoJS.AES.decrypt(text, key);
  return bytes.toString(CryptoJS.enc.Utf8);
};

const generateSecretKey = (): string => {
  const keyLength = 32; // 32 bytes = 256 bits (AES-256)
  const buffer = new Uint8Array(keyLength);
  crypto.getRandomValues(buffer);
  return Array.from(buffer, (byte) => byte.toString(16).padStart(2, '0')).join(
    ''
  );
};

const to_resume_url = (id: any) => {
  return `${URL}${encrypt(id)}`;
};

export { encrypt, decrypt, to_resume_url, generateSecretKey };
