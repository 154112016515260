/* eslint-disable react-hooks/exhaustive-deps */
import { Table, Tbody, TableContainer, Box, Stack } from '@chakra-ui/react';

import Pagination from 'Library/Pagination';

import { useSequencesTableServices } from './useSequencesTableServices';
import { TableHeader } from './TableHeader';
import { TableContent } from './TableContent';

const SequencesListTable = () => {
  const {
    sequencesPgBtn,
    resPostSequencesList,
    sequencesList,
    openAccordionItems,
    handleActive,
    handleDuplicate,
    handleArchive,
    sequencesPage,
    pageChange,
    entryChange,
    handlePrimarySequence,
    handleFilter,
    handleOpenAccordion,
    isLoading
  } = useSequencesTableServices();

  return (
    <Stack spacing="32px" px="8px">
      <TableContainer
        boxSizing="border-box"
        border="1px solid"
        borderColor="default.white.400"
        borderRadius="md"
        height="90vh"
        sx={{ overflowY: 'scroll', boxShadow: '0px 2px 8px 0px #0000000A' }}
      >
        <Table>
          <TableHeader handleFilter={handleFilter} />

          <Tbody bgColor="#fff" fontSize="16px" color="#6B6C7B">
            <TableContent
              openAccordionItems={openAccordionItems}
              handlePrimarySequence={handlePrimarySequence}
              handleActive={handleActive}
              handleDuplicate={handleDuplicate}
              handleArchive={handleArchive}
              handleOpenAccordion={handleOpenAccordion}
              isLoading={isLoading}
              sequencesList={sequencesList}
            />
          </Tbody>
        </Table>
      </TableContainer>

      <Box sx={{ boxShadow: '0px -4px 8px 0px #0000000A' }}>
        <Pagination
          totalPages={sequencesPage.lastPage}
          currentPage={sequencesPage.currentPage}
          onPageChange={(page) => pageChange(page)}
          totalEntries={sequencesPage.count}
          onEntryChange={(entries) => entryChange(entries)}
          currentCount={sequencesList.length}
          targetCount={sequencesPgBtn.take}
        />
      </Box>
    </Stack>
  );
};
export default SequencesListTable;
