import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateOutreach,
  usePostBuzzCheckMutation,
  usePostBuzzDisconnectMutation,
  usePostBuzzSelectCampaignMutation,
  usePostBuzzSelectCredentialsMutation,
} from 'store/outreach.slice';

interface useBuzzConnectionSetupServicesProps {
  handleLogin: () => void;
  hasSetup: boolean;
  setHasSetup: any;
}

export const useBuzzConnectionSetupServices = ({
  handleLogin,
  hasSetup,
  setHasSetup,
}: useBuzzConnectionSetupServicesProps) => {
  const dispatch = useDispatch();

  const { buzzData } = useSelector((state: any) => state.outreach);

  const [reqPostBuzzDisconnect, resPostBuzzDisconnect] =
    usePostBuzzDisconnectMutation();
  const [reqPostBuzzSelectCredentials, resPostBuzzSelectCredentials] =
    usePostBuzzSelectCredentialsMutation();
  const [reqPostBuzzSelectCampaign, resPostBuzzSelectCampaign] =
    usePostBuzzSelectCampaignMutation();
  const [reqPostBuzzCheck, resPostBuzzCheck] = usePostBuzzCheckMutation();

  const [selectCredential, setSelectCredential] = useState(
    buzzData?.buzz_connection?.credentials_id
  );
  const [selectCampaign, setSelectCampaign] = useState(
    buzzData?.buzz_connection?.campaign_id
  );

  const handleLogout = () => {
    reqPostBuzzDisconnect({});
  };

  const handleSelectCredential = (val: any) => {
    setSelectCredential(val);
    reqPostBuzzSelectCredentials({ credentials_id: val });
  };

  const handleSelectCampaign = (val: any) => {
    setSelectCampaign(val);
    reqPostBuzzSelectCampaign({ campaign_id: val });
  };

  const handleSubmit = () => {
    reqPostBuzzCheck({});
  };

  const handleUpdate = () => {
    setHasSetup(false);
  };

  useEffect(() => {
    if (resPostBuzzCheck.isSuccess) {
      setHasSetup(true);
      dispatch(
        updateOutreach({
          buzzData: {
            ...buzzData,
            selectedCredential: selectCredential,
            selectedCampaign: selectCampaign,
          },
        })
      );
    }
  }, [resPostBuzzCheck.isSuccess]);

  useEffect(() => {
    if (resPostBuzzSelectCredentials.isSuccess) {
      dispatch(
        updateOutreach({
          buzzData: { ...buzzData, ...resPostBuzzSelectCredentials.data.data },
        })
      );
    }
  }, [resPostBuzzSelectCredentials.isSuccess]);

  useEffect(() => {
    if (resPostBuzzDisconnect.isSuccess) {
      dispatch(updateOutreach({ buzzData: {} }));
      setHasSetup(false);
      handleLogin();
    }
  }, [resPostBuzzDisconnect.isSuccess]);

  return {
    selectCredential,
    selectCampaign,
    hasSetup,
    handleLogout,
    handleSelectCredential,
    handleSelectCampaign,
    handleSubmit,
    handleUpdate,
    buzzData,
    isLoadingSubmit:
      resPostBuzzSelectCampaign.isLoading || resPostBuzzCheck.isLoading,
    isLoadingDisconnect: resPostBuzzDisconnect.isLoading,
  };
};
