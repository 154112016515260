import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from './api.slice';
import { appError } from './app.slice';

export const candidateInterviewSlice = createSlice({
  name: 'candidatesInterview',
  initialState: {
    candidateInterviews: [],
    interviewBody: '',
    candidateInterviewPage: {
      count: 0,
      currentPage: 1,
      lastPage: 1,
      nextPage: null,
      prevPage: null,
    },
    candidateInterviewPgBtn: {
      page: 1,
      take: 50,
      query: '',
    },
  },
  reducers: {
    listCandidateInterview: (state, { payload }) => ({ ...state, ...payload }),
    getInterviewBody: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { listCandidateInterview, getInterviewBody } =
  candidateInterviewSlice.actions;
export default candidateInterviewSlice.reducer;

/* ----------------------------------- API ---------------------------------- */
export const candidatesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    ListCandidateInterview: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidate-interview/list/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            listCandidateInterview({ candidateInterviews: data.data.data })
          );
          dispatch(
            listCandidateInterview({
              candidateInterviewPage: {
                count: data.data.count,
                currentPage: data.data.currentPage,
                lastPage: data.data.lastPage,
                nextPage: data.data.nextPage,
                prevPage: data.data.nextPage,
              },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    CreateInterviewBody: builder.mutation({
      query: ({ data }) => ({
        url: `/candidate-interview-body/create`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          console.log('data in create interview body', data);
          dispatch(getInterviewBody({ interviewBody: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    UpdateInterviewBody: builder.mutation({
      query: ({ data }) => ({
        url: `/candidate-interview-body/update`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          console.log('data in update interview body', data);
          dispatch(getInterviewBody({ interviewBody: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ViewInterviewBody: builder.mutation({
      query: () => ({
        url: `/candidate-interview-body/body`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(getInterviewBody({ interviewBody: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    CancelInterview: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidate-interview/cancel-interview/${id}`,
        method: 'DELETE',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    UpdateInterview: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidate-interview/update/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  useListCandidateInterviewMutation,
  useCreateInterviewBodyMutation,
  useUpdateInterviewBodyMutation,
  useViewInterviewBodyMutation,
  useCancelInterviewMutation,
  useUpdateInterviewMutation,
} = candidatesApiSlice;
