import {
  Box,
  Divider,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tag,
  TagLabel,
} from '@chakra-ui/react';
import Button from 'Library/Button';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDuplicateTemplateMutation, useListTemplateMutation } from 'store/template.slice';
import replacePTags from 'utils/replaceTags';
import replacePlaceholders from 'utils/textPlaceholders';

interface propsInt {
  param: any;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  handleEdit: (item: any) => void;
  handleDel: (item: any) => void;
  selectedData: any;
}

const PreviewTemplate = ({ param, isOpen, onClose, title, handleEdit, handleDel, selectedData }: propsInt) => {
  const { placehoderPair, templatePageBtn } = useSelector((state: any) => state.template);
  const placeholders = (text: string) => {
    return replacePlaceholders(text, placehoderPair);
  };
  const [reqListTemplate, resListTemplate] = useListTemplateMutation();
  const [reqDuplicate, resDuplicate] = useDuplicateTemplateMutation()

  const handleDuplicate = () => {
    reqDuplicate({ id: param.id })
  }

  useEffect(() => {
    if (resDuplicate.isSuccess) {
      reqListTemplate(templatePageBtn)
      onClose()
    }
  }, [resDuplicate.isSuccess])

  const handleDelete = async () => {
    handleDel(param)
  }
  const handleEditTemplate = async () => {
    handleEdit(selectedData)
  }
  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onClose();
        }}
        size="3xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="NunitoSans Regular">
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            {title}
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          <ModalBody borderRadius="0 0 4px 4px" p="24px">
            <Flex gap="5px" direction="column" color="#2B2D42">
              <Flex sx={{ fontSize: '16px', fontWeight: 600 }}>
                <Box width="120px">Template Group</Box>
                <Box as="span" sx={{ ml: '30px', fontWeight: 300 }}>
                  {param.group_name}
                </Box>
              </Flex>
              <Flex sx={{ fontSize: '16px', fontWeight: 600 }}>
                <Box width="120px">Template Name</Box>
                <Box as="span" sx={{ ml: '30px', fontWeight: 300 }}>
                  {param.title}
                </Box>
              </Flex>
              <Flex sx={{ fontSize: '16px', fontWeight: 600 }}>
                <Box width="120px">Sender Email</Box>
                <Box as="span" sx={{ ml: '30px', fontWeight: 300 }}>
                  {param.email}
                </Box>
              </Flex>
              <Flex sx={{ fontSize: '16px', fontWeight: 600 }}>
                <Box width="120px">Subject</Box>
                <Box as="span" sx={{ ml: '30px', fontWeight: 300 }}>
                  {param.subject}
                </Box>
              </Flex>
              <Divider my="16px" />
              <Box>
                <div
                  style={{
                    fontFamily: 'verdana',
                    fontSize: '12px',
                    margin: 0,
                    padding: 0,
                  }}
                  dangerouslySetInnerHTML={{ __html: replacePTags(param.body) }}
                />
              </Box>
              <Flex gap="5px" direction="column">
                {param.attachments?.length > 0 &&
                  param.attachments?.map((item: any, key: number) => {
                    return (
                      <Tag key={'attachments-' + key} width="fit-content">
                        <Stack spacing={1}>
                          <HStack>
                            <TagLabel sx={{ fontWeight: 600 }}>{item}</TagLabel>
                          </HStack>
                        </Stack>
                      </Tag>
                    );
                  })}
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            {param.isOwn ? (
              <Flex sx={{ justifyContent: 'space-between', w: '100%' }}>
                <Button
                  leftIcon="trash"
                  variant="delete"
                  colorScheme="purple"
                  onClick={() => { handleDelete() }}
                >
                  Delete
                </Button>
                <Flex gap="10px">
                  <Button
                    leftIcon="copy"
                    colorScheme="purple"
                    onClick={() => { handleDuplicate() }}
                    loading={resDuplicate.isLoading}
                  >
                    Duplicate
                  </Button>
                  <Button
                    leftIcon="pencil"
                    variant="solid"
                    colorScheme="purple"
                    onClick={() => { handleEditTemplate() }}
                  >
                    Edit
                  </Button>
                </Flex>
              </Flex>
            ) : (
              <Flex sx={{ justifyContent: 'flex-end', gap: '10px' }}>
                <Button colorScheme="purple" onClick={onClose}>
                  Close
                </Button>
                <Button
                  leftIcon="copy"
                  variant="solid"
                  colorScheme="purple"
                  onClick={() => { handleDuplicate() }}
                >
                  Duplicate
                </Button>
              </Flex>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PreviewTemplate;
