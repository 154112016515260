import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import * as Yup from 'yup';

import {
  useGetAgreementMutation,
  useUpdateAgreementMutation,
  useListLeadAgreementMutation,
} from 'store/leadagreement.sclice';
import { useGetLeadsMutation } from 'store/leads.slice';
import { cleanUpload } from 'store/uploads.slice';
import { LeadsAgrrmentModalProps } from './types';

export function useEditAgreementServices({ onClose }: LeadsAgrrmentModalProps) {
  const dispatch = useDispatch();

  const { leadData } = useSelector((state: any) => state.leads);
  const { uploaded, uploading } = useSelector((state: any) => state.uploads);
  const { leadAgreementPgBtn } = useSelector(
    (state: any) => state.leadagreement
  );
  const { agreementData, attachments } = useSelector(
    (state: any) => state.leadagreement
  );
  const [reqget, resget] = useGetAgreementMutation();

  const params = useParams();
  const leadID = params.leadsId;

  const [reqleads] = useGetLeadsMutation();
  const [requpdate, resupdate] = useUpdateAgreementMutation();
  const [reqAgreement] = useListLeadAgreementMutation();

  const initialValues = {
    title: agreementData?.title,
    content: agreementData?.content,
    date_signed: moment(agreementData?.date_signed).format('YYYY-MM-DD'),
    valid_until: agreementData?.no_expiry
      ? ''
      : moment(agreementData?.valid_until).format('YYYY-MM-DD'),
    no_expiry: agreementData.no_expiry,
    fee_pct: Number(agreementData.fee_pct),
    payable: agreementData.payable,
    guarantee: agreementData.guarantee || '',
    guarantee_type: agreementData.guarantee_type || '',
  };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      title: Yup.string().required('Agreement name is required.'),
      content: Yup.string(),
      date_signed: Yup.string().required('Date signed is required.'),
      valid_until: Yup.string().when('no_expiry', {
        is: false,
        then: Yup.string().required('Valid until is required'),
        otherwise: Yup.string().notRequired(),
      }),
      fee_pct: Yup.number().required('Fee Percentage is required'),
      payable: Yup.string().required('Payable is required'),
      guarantee: Yup.string().required('Guarantee is required'),
      guarantee_type: Yup.string().required('Guarantee Type is required'),
      no_expiry: Yup.boolean(),
    })
  );

  const onSubmit = async (data: any) => {
    data['attachments'] = uploaded;
    if (data.no_expiry) {
      data['valid_until'] = null;
    }
    data['fee_pct'] = String(data['fee_pct']);
    data['payable'] = Number(data['payable']);
    data['guarantee'] = Number(data['guarantee']);
    await requpdate({ data, id: agreementData?.id });
    await reqget({ id: agreementData?.id });
  };

  useEffect(() => {
    if (resupdate.isSuccess) {
      dispatch(cleanUpload({ attachments: [] }));
      dispatch(cleanUpload({ uploaded: [] }));
      reqAgreement({ data: leadAgreementPgBtn, id: leadID });
      onClose();
    }
  }, [
    dispatch,
    leadAgreementPgBtn,
    leadData.id,
    leadID,
    onClose,
    reqAgreement,
    reqleads,
    resupdate.isSuccess,
  ]);

  return {
    agreementData,
    initialValues,
    validationSchema,
    onSubmit,
    uploading,
    attachments,
  };
}
