import {
  Box,
  Checkbox,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import Pagination from 'Library/Pagination';

import Button from 'Library/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  getCandidateLogs,
  useCandidateActivityLogsMutation,
} from 'store/candidateActivity';
import { useEffect } from 'react';
import TrLoading from '../../../TrLoading';
import moment from 'moment';
import { useActivityServices } from './useActivityServices';
import { ActivityGroup } from './components/activityGroup';
import { ActivityRowLoading } from './components/activtyRowLoading';
import PlacementModal from 'components/app/PlacementForm/modal';
import HiredModal from 'modules/Jobs/Layout/ViewCandidatesModule/Modal/HiredModal';

const CandidatesActivity = () => {
  const {
    filterList,
    checkedFilters,
    activities,
    resLogs,
    placementData,
    isOpenPlacement,
    onClosePlacement,
    handleOpenPlacementForm,
    handleActivityType,
    handleCheckFilter,
    handleIcons,
    handleCallback,
  } = useActivityServices();

  return (
    <Box
      justifyContent="space-between"
      // ADDING EXTRA PADDING FROM PARENT 24PX
      px="12px"
    >
      <Flex gap="20px" justifyContent="flex-start" mb="24px">
        {filterList.map((filter: string, index: number) => {
          return (
            <Checkbox
              colorScheme="purple"
              color="#9B9B9B"
              isChecked={checkedFilters.includes(String(index))}
              onChange={() => handleCheckFilter(String(index))}
            >
              {filter}
            </Checkbox>
          );
        })}
      </Flex>
      <Flex px="10px" direction="column">
        {resLogs.isLoading && activities ? (
          <ActivityRowLoading />
        ) : (
          Object.keys(activities).map((date: string) => {
            if (activities?.[date]) {
              return (
                <ActivityGroup
                  activitiesPerDate={activities[date]}
                  date={date}
                  handleIcons={handleIcons}
                  handleActivityType={handleActivityType}
                  handleOpenPlacementForm={handleOpenPlacementForm}
                />
              );
            }
          })
        )}
      </Flex>
      {isOpenPlacement && (
        <HiredModal
          isOpen={isOpenPlacement}
          onClose={onClosePlacement}
          onCallback={() => {
            handleCallback();
          }}
          logs={placementData}
          isEdit
        />
      )}
      {/* <ActivityRowLoading /> */}
    </Box>
  );
};

export default CandidatesActivity;
