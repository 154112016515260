import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  useDisclosure,
  useToast,
  ButtonGroup,
  Divider,
} from '@chakra-ui/react';

import Verified from '../../../../assets/images/MdVerified.svg';

import toUrl from 'utils/toUrl';
import Button from 'Library/Button';
import {
  useGetCandidatesMutation,
  useListCandidatesMutation,
} from 'store/candidates.slice';
import FAIcon from 'components/lib/FAIcon';
import WorkStatus from './WorkStatus';
import Degree from './Degree';
import CandidateSource from './CandidateSource';
import { usePlaceHolderPairMutation } from 'store/template.slice';
import { useLocation, useParams } from 'react-router';
import { useGetJobsMutation } from 'store/jobs.slice';
import { BiCopy } from 'react-icons/bi';
import { MdCall } from 'react-icons/md';
import { BsChatDotsFill } from 'react-icons/bs';
import InformationContact from './Contact';
import CandidateNotes from './CandidateNotes';
import EmailsModal from 'components/app/Global/Email/Modal';

const CandidatesPersonalInformation = () => {
  const { candidateData, candidatePgBtn } = useSelector(
    (state: any) => state.candidates
  );

  const [isHideContact, setIsHideContact] = useState(false);
  const [isHideEmails, setIsHideEmails] = useState(false);
  const params = useParams();
  const { search } = useLocation();
  const toast = useToast();

  const [reqCandidatesList] = useListCandidatesMutation();
  const [reqGetCandidate] = useGetCandidatesMutation();
  const [candidateEmail, setCandidateEmail] = useState<string | null>(null);
  const [reqPair] = usePlaceHolderPairMutation();
  const [reqjob] = useGetJobsMutation();
  const abortControllerRef = useRef<AbortController | null>(null);

  const [workStatusUpdate, SetWorkStatusUpdate] = useState(false);
  const queryParam = new URLSearchParams(search);
  const jobId =
    queryParam.get('jobId') && !isNaN(Number(queryParam.get('jobId')))
      ? Number(queryParam.get('jobId'))
      : Number(params?.jobsId) || null;
  const openSendEmailClick = async (email: string) => {
    setCandidateEmail(email);
    const data = {
      candidate_id: candidateData?.id,
      job_id: jobId,
    } as any;
    await reqPair(data);
    onOpenEmail();
    // // await fetchCandidate(); might not be necessary to call here
    if (jobId) {
      await reqjob({ id: jobId });
    }
  };

  const {
    isOpen: isOpenEmail,
    onOpen: onOpenEmail,
    onClose: onCloseEmail,
  } = useDisclosure();

  const contactPopulate = (data: any) => {
    const contactSet: any = [];

    Promise.all(
      data.map(async (item: any) => {
        contactSet.push(item);
      })
    );

    const memoizedContacts = (data: any) => {
      return (
        <>
          {data.map((contact: any, index: any) => {
            return (
              <InformationContact
                index={index}
                Verified={Verified}
                contact={contact}
                candidateData={candidateData}
              />
            );
          })}
        </>
      );
    };

    if (!isHideContact && contactSet.length > 3) {
      return (
        <>
          {memoizedContacts(contactSet.slice(0, 3))}
          <Box
            id={`tagTooltip`}
            color="primary.600"
            fontSize="14px"
            lineHeight="14.62px"
            height="fit-content"
            cursor="pointer"
            onClick={() => setIsHideContact((prev) => !prev)}
          >
            Show {contactSet.length - 3}+ contacts
          </Box>
        </>
      );
    }
    return memoizedContacts(data);
  };

  const mailPopulate = (data: any) => {
    const mailSet: any = [];

    Promise.all(
      data.map(async (item: any) => {
        mailSet.push(item);
      })
    );

    const memoizedEmails = (data: any) => {
      return (
        <>
          {data.map((email: any, index: any) => {
            return (
              <Flex gap="5px" alignItems="center" key={index}>
                <Box sx={{ display: 'flex', fontWeight: 400, gap: '7px' }}>
                  <Link onClick={() => openSendEmailClick(email?.email || '')}>
                    {index + 1 !== candidateData?.emails.length
                      ? email?.email
                      : email?.email}
                  </Link>
                </Box>
                <Box>
                  <Link onClick={() => copyEmail(email?.email || '')}>
                    <Icon as={BiCopy} />
                  </Link>
                </Box>
                {email?.valid && (
                  <Image src={Verified} alt="logo" height="14px" />
                )}
                {email?.primary && (
                  <Box
                    sx={{
                      button: {
                        p: '4px 8px',
                        borderRadius: '6px',
                        height: '20px',
                      },
                    }}
                  >
                    <Button variant="solid">Primary</Button>
                  </Box>
                )}
              </Flex>
            );
          })}
        </>
      );
    };

    if (!isHideEmails && mailSet.length > 3) {
      return (
        <>
          {memoizedEmails(mailSet.slice(0, 3))}
          <Box
            id={`tagTooltip`}
            color="primary.600"
            fontSize="14px"
            lineHeight="14.62px"
            height="fit-content"
            cursor="pointer"
            onClick={() => setIsHideEmails((prev) => !prev)}
          >
            Show {mailSet.length - 3}+ emails
          </Box>
        </>
      );
    }
    return memoizedEmails(data);
  };

  useEffect(() => {
    if (workStatusUpdate) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();
      reqCandidatesList({
        data: candidatePgBtn,
        signal: abortControllerRef.current.signal,
      });
      reqGetCandidate({ id: candidateData.id });
    }
  }, [candidateData?.id, candidatePgBtn, workStatusUpdate]);

  const copyEmail = (email: string) => {
    navigator.clipboard
      .writeText(email)
      .then(() => {
        toast({
          title: 'Email copied',
          duration: 1000,
          position: 'top',
        });
      })
      .catch((e: any) => {
        console.warn({ e });
      });
  };

  return (
    <Box color="default.primarytext">
      <Flex py={3} gap="50px">
        <Box
          fontSize="16px"
          lineHeight="18px"
          color="default.gray.600"
          textAlign="left"
          width="100px"
        >
          Contact
        </Box>
        <Flex
          sx={{
            flexDir: 'column',
            textAlign: 'left',
            fontSize: '16px',
            lineHeight: '17.05px',
            gap: '8px',
          }}
        >
          {candidateData?.contact && contactPopulate(candidateData?.contact)}
        </Flex>
      </Flex>
      <Flex py={3} gap="50px">
        <Box
          fontSize="16px"
          lineHeight="18px"
          color="default.gray.600"
          textAlign="left"
          width="100px"
        >
          Email
        </Box>
        <Flex
          sx={{
            flexDir: 'column',
            textAlign: 'left',
            fontSize: '16px',
            lineHeight: '17.05px',
            gap: '8px',
          }}
        >
          {candidateData?.emails && mailPopulate(candidateData?.emails)}
        </Flex>
      </Flex>
      <Flex py={3} gap="50px" alignItems="center">
        <WorkStatus
          candidateData={candidateData}
          SetWorkStatusUpdate={SetWorkStatusUpdate}
        />
      </Flex>

      <Flex py={3} gap="50px" alignItems="center">
        <Degree candidateData={candidateData} />
      </Flex>
      <Flex py={3} gap="50px">
        <Box
          fontSize="16px"
          lineHeight="18px"
          color="default.gray.600"
          textAlign="left"
          width="100px"
        >
          Linkedin
        </Box>
        <Flex
          sx={{
            textAlign: 'left',
            fontSize: '16px',
            lineHeight: '17.05px',
            gap: '5px',
          }}
        >
          {candidateData?.linkedin_url != "" && candidateData?.linkedin_url != null
            ? (<Link
              href={toUrl(candidateData?.linkedin_url)}
              target="_blank"
              style={{
                fontWeight: 400,
                fontSize: '16px',
                width: '300px',
                overflow: 'hidden',
              }}
              rel="noreferrer"
            >
              {candidateData?.linkedin_url}
            </Link>)
            : (<Box></Box>)
          }

        </Flex>
      </Flex>
      <Flex py={3} gap="50px">
        <Box
          fontSize="16px"
          lineHeight="18px"
          color="default.gray.600"
          textAlign="left"
          width="100px"
        >
          Address
        </Box>
        <Box
          textAlign="left"
          fontSize="16px"
          lineHeight="18px"
          sx={{ textWrap: 'balance' }}
        >
          {[candidateData?.city, candidateData?.state_province]
            .filter(Boolean)
            .join(', ')}
        </Box>
      </Flex>
      <CandidateSource candidateData={candidateData} />
      <Divider />
      <CandidateNotes />
      {isOpenEmail && (
        <EmailsModal
          isOpen={isOpenEmail}
          onClose={onCloseEmail}
          email={candidateEmail}
          id={candidateData.id}
          candidate_id={candidateData.id}
          job_id={jobId}
          allowedCategory={['Candidates', 'Jobs', 'Signatures']}

        />
      )}
    </Box>
  );
};

export default CandidatesPersonalInformation;
