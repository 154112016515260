import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  useCreateAttachmentMutation,
  useListLeadAttachmentMutation,
} from 'store/leadattachment.slice';
import { useGetLeadsMutation } from 'store/leads.slice';
import { cleanUpload, uploadList } from 'store/uploads.slice';

import { changeUploadName } from 'utils/newFilename';
import { LeadsAttachmentModalProps } from './types';

export function useAddAttachmentServices({
  onClose,
}: LeadsAttachmentModalProps) {
  const dispatch = useDispatch();
  const { leadData } = useSelector((state: any) => state.leads);
  const { uploaded, prefix, uploading } = useSelector(
    (state: any) => state.uploads
  );
  const { LAPgBtn } = useSelector((state: any) => state.leadattachment);
  const [reqcreate, rescreate] = useCreateAttachmentMutation();
  const [reqList] = useListLeadAttachmentMutation();
  const [reqleads] = useGetLeadsMutation();
  const [prefixStr, setPrefix] = useState(prefix);

  const submitAgreement = async (data: any) => {
    console.log('uploaded', uploaded);
    data['attachments'] = await changeUploadName(uploaded, prefix);
    await reqcreate({ data, id: leadData?.id });
  };

  const initialValues = {
    prefix: 'Attachments',
  };

  useEffect(() => {
    dispatch(cleanUpload({ withPrefix: true }));
    dispatch(uploadList({ prefix: 'Attachment' }));
    if (rescreate.isSuccess) {
      dispatch(cleanUpload({ attachments: [] }));
      dispatch(cleanUpload({ uploaded: [] }));
      reqleads({ id: leadData?.id });
      reqList({ data: LAPgBtn, id: leadData?.id });
      onClose();
    }
  }, [
    LAPgBtn,
    dispatch,
    leadData?.id,
    onClose,
    reqList,
    reqleads,
    rescreate.isSuccess,
  ]);
  const changePrefix = async (e: any) => {
    let prefix = e.target.value;
    setPrefix(prefix);
    dispatch(uploadList({ prefix: prefix }));
  };

  return { initialValues, prefixStr, changePrefix, uploading, submitAgreement };
}
