export const color = [
  { bg: '#BEE6D1', color: '#000000' },
  { bg: '#E1E3B7', color: '#000000' },
  { bg: '#CCBAD4', color: '#000000' },
  { bg: '#E5C1D1', color: '#000000' },
  { bg: '#C7B8E3', color: '#000000' },
  { bg: '#B4E4E5', color: '#000000' },
  { bg: '#B9CCB6', color: '#000000' },
  { bg: '#E0D3DE', color: '#000000' },
  { bg: '#DBBBC2', color: '#000000' },
  { bg: '#D1B7D3', color: '#000000' },
  { bg: '#C2CAE5', color: '#000000' },
  { bg: '#BDCEC7', color: '#000000' },
  { bg: '#E3C6D7', color: '#000000' },
  { bg: '#B4E5DD', color: '#000000' },
  { bg: '#C7CAB5', color: '#000000' },
  { bg: '#B9D7E0', color: '#000000' },
  { bg: '#C2E0E5', color: '#000000' },
  { bg: '#C4BEDA', color: '#000000' },
  { bg: '#B5E5BA', color: '#000000' },
  { bg: '#B5BEE4', color: '#000000' },
  { bg: '#CADBBE', color: '#000000' },
  { bg: '#DCC7C3', color: '#000000' },
  { bg: '#CEBBC6', color: '#000000' },
  { bg: '#C0E3C0', color: '#000000' },
  { bg: '#C4CFCB', color: '#000000' },
  { bg: '#E4DFCD', color: '#000000' },
  { bg: '#BBB7B8', color: '#000000' },
  { bg: '#CEE3B5', color: '#000000' },
  { bg: '#C0C0D0', color: '#000000' },
  { bg: '#B5C2CA', color: '#000000' },
];
