import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useListTeamsMutation } from "store/teams.slice"
import { TeamDataInt, TeamInt, TeamsTableProps } from "./types"

export default function useTableTeamsServices({ resTeams, teams }: TeamsTableProps) {


    const tableHeaders = [
        "Team Name",
        "Manager",
        "No. of Members",
        "Team Manager",
        "Date Added",
        ""
    ]
    return {
        tableHeaders,
    }
}