/* eslint-disable react-hooks/exhaustive-deps */
import {
  Tr,
  Td,
  Table,
  Tbody,
  Th,
  TableContainer,
  Box,
  Thead,
  useDisclosure,
  Stack,
  Text,
} from '@chakra-ui/react';
import TrLoading from 'components/app/TrLoading';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  updateOutreach,
  usePostSMSHistoryListMutation,
} from 'store/outreach.slice';
import { SMSHistoryDrawer } from '../Drawer';
import { useSMSHistoryTableServices } from './useSMSHistoryTableServices';

const SMSHistoryListTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const { smsHistoryList, smsHistoryPgBtn, isLoading } =
    useSMSHistoryTableServices();

  const [reqPostSMSHistoryList, resPostSMSHistoryList] =
    usePostSMSHistoryListMutation();

  const {
    isOpen: isOpenSMSHistoryDrawer,
    onOpen: onOpenSMSHistoryDrawer,
    onClose: onCloseSMSHistoryDrawer,
  } = useDisclosure({ defaultIsOpen: false });

  useEffect(() => {
    if (params.settingType === 'sms-history') {
      reqPostSMSHistoryList(smsHistoryPgBtn);
    }
  }, [params.settingType]);

  useEffect(() => {
    if (
      params.settingType === 'sms-history' &&
      params.settingTypeId &&
      resPostSMSHistoryList.isSuccess
    ) {
      const mappedSMSHistorys = smsHistoryList
        .map((template: any, index: any) => {
          return {
            ...template,
            rowId: index + 1,
          };
        })
        .filter((x: any) => x.id === Number(params.settingTypeId))[0];

      dispatch(
        updateOutreach({
          smsHistoryData: mappedSMSHistorys,
        })
      );

      onOpenSMSHistoryDrawer();
    }
  }, [
    params.settingType,
    params.settingTypeId,
    resPostSMSHistoryList.isSuccess,
  ]);

  return (
    <Box px={6} justifyContent="space-between">
      <TableContainer
        boxSizing="border-box"
        border="1px solid"
        borderColor="default.white.400"
        borderRadius="md"
        height="65vh"
        sx={{ overflow: 'scroll' }}
      >
        <Table>
          <Thead>
            <Tr bg="default.white.600">
              <Th
                sx={{
                  width: '50px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Automation Name
              </Th>
              <Th
                sx={{
                  width: '50px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Trigger
              </Th>
              <Th
                sx={{
                  width: '50px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                SMS Message
              </Th>
              <Th
                sx={{
                  width: '50px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Recipient
              </Th>
              <Th
                sx={{
                  width: '50px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Date Sent
              </Th>
              <Th
                sx={{
                  width: '50px',
                  fontSize: '10px',
                  color: '#9B9B9B',
                  letterSpacing: '1.5px',
                }}
              >
                Status
              </Th>
            </Tr>
          </Thead>
          {isLoading ? (
            <TrLoading rows={3} columns={6} />
          ) : (
            <Tbody bgColor="#fff" fontSize="16px" color="#6B6C7B">
              {smsHistoryList.map((item: any, index: number) => {
                return (
                  <Tr cursor="pointer">
                    <Td
                      onClick={() => {
                        onOpenSMSHistoryDrawer();
                        navigate(`${location.pathname}/${item.id}`);
                      }}
                    >
                      <Text sx={{ width: '150px', whiteSpace: 'pre-line' }}>
                        {item?.outreach_sms_automation?.name}
                      </Text>
                    </Td>
                    <Td
                      onClick={() => {
                        onOpenSMSHistoryDrawer();
                        navigate(`${location.pathname}/${item.id}`);
                      }}
                    >
                      <Text sx={{ width: '150px', whiteSpace: 'pre-line' }}>
                        {item?.outreach_sms_automation?.trigger}
                      </Text>
                    </Td>
                    <Td
                      onClick={() => {
                        onOpenSMSHistoryDrawer();
                        navigate(`${location.pathname}/${item.id}`);
                      }}
                    >
                      <Text sx={{ width: '300px', whiteSpace: 'pre-line' }}>
                        {item?.sms_message}
                      </Text>
                    </Td>
                    <Td
                      onClick={() => {
                        onOpenSMSHistoryDrawer();
                        navigate(`${location.pathname}/${item.id}`);
                      }}
                    >
                      <Stack>
                        <Text>
                          {[
                            item.candidate.first_name,
                            item.candidate.last_name,
                          ].join(' ')}
                        </Text>
                        <Text fontWeight={700}>{item?.phone}</Text>
                      </Stack>
                    </Td>
                    <Td
                      onClick={() => {
                        onOpenSMSHistoryDrawer();
                        navigate(`${location.pathname}/${item.id}`);
                      }}
                    >
                      <Stack>
                        <Text>
                          {moment(item?.created_at).format('MMM DD, YYYY')}
                        </Text>
                        <Text fontWeight={700}>
                          {moment(item?.created_at).format('HH:MM A')}
                        </Text>
                      </Stack>
                    </Td>
                    <Td
                      onClick={() => {
                        onOpenSMSHistoryDrawer();
                        navigate(`${location.pathname}/${item.id}`);
                      }}
                    >
                      {item.is_sent === 1
                        ? 'Success'
                        : item.is_sent === 0
                        ? 'On Queue'
                        : 'Failed'}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>

        {isOpenSMSHistoryDrawer && (
          <SMSHistoryDrawer
            isOpen={isOpenSMSHistoryDrawer}
            onClose={onCloseSMSHistoryDrawer}
          />
        )}
      </TableContainer>
    </Box>
  );
};
export default SMSHistoryListTable;
