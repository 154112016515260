import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Stack,
  Text,
} from '@chakra-ui/react';
import parse from 'html-react-parser';
import moment from 'moment';

export const ViewModal = ({ isOpen, onClose, data }: any) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      size="3xl"
      scrollBehavior="inside"
    >
      <ModalContent fontFamily="NunitoSans Regular">
        <ModalHeader
          background="default.white.800"
          borderRadius="4px 4px 0"
          p="21px 32px"
          fontSize="18px"
          lineHeight="18px"
          textAlign="left"
          textTransform="capitalize"
        >
          View {data?.sequence_candidate_status?.status}
        </ModalHeader>
        <ModalCloseButton
          top="13px"
          right="20px"
          color="default.secondarytext"
        />
        <ModalBody borderRadius="0 0 4px 4px">
          <Box p="9px 8px">
            <Text
              sx={{
                pt: '18px',
                pb: '24px',
                fontSize: '20px',
                fontWeight: 600,
              }}
            >
              {data?.email_sent_log?.email_data?.subject_swapped}
            </Text>
            <Stack spacing="8px" color="#2B2D42">
              <Text
                sx={{
                  fontSize: '16px',
                }}
              >
                <b>To</b> {data?.email_sent_log?.email}
              </Text>
              <Text
                sx={{
                  borderBottom: '1px solid #EEEEEE',
                  fontSize: '16px',
                  pb: '18px',
                }}
              >
                <b>Date</b>{' '}
                {moment(data?.email_sent_log?.email_data?.created_at).format(
                  'MMM DD, YYYY'
                )}
              </Text>
            </Stack>
          </Box>
          <Box
            sx={{
              p: '18px 8px',
              fontSize: '16px',
              borderBottom: '1px solid #EEEEEE',
            }}
          >
            {parse(data?.email_sent_log?.email_data?.content_swapped || '')}
          </Box>
        </ModalBody>

        <ModalFooter
          position="sticky"
          bottom="0"
          background="default.white.100"
          boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
        >
          <Button variant="solid" colorScheme="purple" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
