import { defineStyleConfig } from '@chakra-ui/styled-system';

export const FormLabel = defineStyleConfig({
  baseStyle: {
    color: 'default.secondarytext',
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 700,
  },
});
