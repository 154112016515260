import { ActivityData, ActivityGroupProps, ActivityRowProps } from "../types"
import { Box, Flex, Image, Table, Tbody, Td, Tr } from "@chakra-ui/react"
import moment from "moment"
import { ActivityInformation } from "./activityInformation"
export const ActivityGroup = ({ activitiesPerDate, date, handleIcons, handleActivityType, handleOpenPlacementForm }: ActivityGroupProps) => {
    return (
        <>
            <Flex>{moment(date).format('MMM DD, YYYY')}</Flex>
            <Flex py="2" px="4" direction="column">
                {
                    activitiesPerDate.map((activity: ActivityData, index: number) => {
                        return (
                            <ActivityRow
                                activity={activity}
                                index={index}
                                total={activitiesPerDate.length}
                                icon={handleIcons(activity.type, activity.action)}
                                type={handleActivityType(activity.type, activity.action)}
                                handleOpenPlacementForm={handleOpenPlacementForm}
                            />
                        )
                    })
                }
            </Flex>

        </>
    )
}

const ActivityRow = ({ activity, index, total, icon, type, handleOpenPlacementForm, }: ActivityRowProps) => {
    // console.log("activity", activity)
    // console.log("result", (type == "create" && !activity.message.includes("Career's Page")
    //     && !activity.message.includes("LinkedIn") && !activity.message.includes("Job Board") && !activity.message.includes("Social Media")
    //     && !activity.message.includes("User Referral")))

    // console.log("type", type)
    const showUser = () => {
        const isCreateType = type === "create";
        const isChangeType = type === "change";
        const message = activity.message;

        const isExcludedMessage = [
            "Career's Page",
            "LinkedIn",
            "Job Board",
            "Social Media",
            "User Referral"
        ].some(excluded => message.includes(excluded));

        const isThroughCareersPage = message.includes("through Career's page");

        if (isChangeType && !isThroughCareersPage) {
            return false;
        }

        if (isCreateType && isExcludedMessage) {
            return false;
        }

        return true;
    };

    return (
        <Flex direction="column" h="100%" >
            <Table alignItems="flex-start" h="100%" margin="0">
                <Tr border="none" gap='2' >
                    <Td p="0" border="none" h="100%" pr="4" w="100px">
                        <Flex h="100%" w="fit-content">
                            <Flex w="100px" alignItems="center" h="50px" color="default.gray.1000" justifyContent="center" fontSize="14px" >
                                {moment(activity.created_at).format("h:mmA")}
                            </Flex>
                        </Flex>
                    </Td>
                    <Td p="0" border="none" pr="4" w="50px">
                        <Flex h="100%" minH="50px" w="50px" direction="column">
                            <Flex w="50px" minH="50px" rounded="full" border="1px solid" justifyContent="center" alignItems="center" borderColor="default.gray.1000" >
                                <Image src={icon} minH="20px" maxW="25px" />
                            </Flex>
                            <Flex flex="1 1 auto" h="100%" justifyContent="center" display={index + 1 == total ? "none" : "flex"}>
                                <Flex w="1px" h="100%" bg="default.gray.1000" >
                                </Flex>
                            </Flex>
                        </Flex>
                    </Td>
                    <Td p="0" border="none" >
                        <Flex wrap="wrap" width="100%"
                            gap={!showUser() ? "0" : "2"}
                            pb={!showUser() ? "0" : "4"} direction="column">
                            <Flex>{activity.message}</Flex>
                            {
                                showUser() &&
                                (
                                    <> {(activity.logs?.user?.first_name || activity.logs?.user?.last_name) ? (
                                        <Box>By {" "}<b>{[activity.logs.user?.first_name, activity.logs?.user?.last_name].filter(Boolean).join(" ")}</b></Box>
                                    ) : (
                                        <Box>By {" "}<b>{"Talie"}</b></Box>
                                    )}
                                    </>
                                )
                            }
                            {
                                // DISPLAY ADDITIONAL INFORMATION IF NOT CREATE, ASSOCIATE, OR CHANGE PRIMARY RESUME
                                !(type == "update" && activity?.type?.includes("merge"))
                                    && type != "create" && (type != "associate" || (type == "associate"
                                        && (activity.action?.includes("change") || activity.action?.includes("reject"))))
                                    && activity.type != "primary"
                                    ? (
                                        <ActivityInformation
                                            type={type}
                                            logs={activity.logs}
                                            key={index}
                                            message={activity.message}
                                            handleOpenPlacementForm={handleOpenPlacementForm} />
                                    ) : (
                                        <Flex h="20px" display={index + 1 == total ? "none" : "flex"}></Flex>
                                    )
                            }

                        </Flex>
                    </Td>
                </Tr>
            </Table>
        </Flex >
    )

}