import { Box, Flex, Text } from '@chakra-ui/react';
import FAIcon from 'components/lib/FAIcon';

export const PaginationLayout = ({
  pageNo,
  pageTotal,
  handlePrev,
  handleNext,
}: any) => {
  return (
    <Flex gap="8px" alignItems="center" color="#6930CA">
      <Box
        sx={{
          fontSize: '12px',
          cursor: 'pointer',
        }}
        onClick={handlePrev}
      >
        <FAIcon iconName="chevron-left" />
      </Box>
      <Text sx={{ fontSize: '10px', fontWeight: 700 }}>
        {pageNo}/{pageTotal}
      </Text>
      <Box
        sx={{
          fontSize: '12px',
          cursor: 'pointer',
        }}
        onClick={handleNext}
      >
        <FAIcon iconName="chevron-right" />
      </Box>
    </Flex>
  );
};
