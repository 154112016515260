import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, FieldArray, Field } from 'formik';
import * as Yup from 'yup';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Checkbox,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  Stack,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { cleanUpload, uploadList } from 'store/uploads.slice';
import { CheckIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import {
  getCandidate,
  useCreateCandidatesMutation,
  useListCandidatesMutation,
  useGetCandidatesMutation,
  useUpdateCandidatesMutation,
} from 'store/candidates.slice';
import Button from 'Library/Button';
import AtsDropZone from '../../Dropzone';

import { CandidatesTypes } from 'types';
import { changeUploadName } from 'utils/newFilename';
import moment from 'moment';
import {
  useGetCandidateSourceMutation,
  useGetCandidateWorkStatusMutation,
  useGetCountriesMutation,
} from 'store/constant.slice';
import { BsCurrencyDollar } from 'react-icons/bs';
import AddressComponent from '../components/address';
import LoadingEditCandidate from '../components/CandidateLoadingModal';
import AtsSelect from '../../AtsSelect';
import AtsSelect3 from '../../AtsSelect3';
import { useUserListMutation } from 'store/user.slice';
import FAIcon from 'components/lib/FAIcon';
import { authStorage } from 'utils/localStorage';
import ScrollToFieldError from 'components/app/ScrollError';

interface CandidatesModalProps {
  isOpen: any;
  onClose: any;
  edit?: boolean;
  id?: number;
  onSuccess?: () => void;
}

const CandidatesModal = ({
  isOpen,
  onClose,
  edit = false,
  id = null,
  onSuccess,
}: CandidatesModalProps) => {
  const dispatch = useDispatch();

  const { candidatePgBtn, candidateData } = useSelector(
    (state: any) => state.candidates
  );
  const { uploaded, prefix, uploading, resumeUploaded } = useSelector(
    (state: any) => state.uploads
  );
  const { countries, candidate_work_status, candidate_source, educations } =
    useSelector((state: any) => state.constants);
  const authSt = authStorage();
  const { userList, UserPgBtn } = useSelector((state: any) => state.user);

  const [reqCountry] = useGetCountriesMutation();
  const [reqCandidates, resCandidates] = useListCandidatesMutation();
  const [reqCreateCandidate, resCreateCandidate] =
    useCreateCandidatesMutation();
  const [reqCanWorkStat] = useGetCandidateWorkStatusMutation();
  const [reqCandidate, resCandidate] = useGetCandidatesMutation();
  const [reqCandidateUpdate, resCandidateUpdate] =
    useUpdateCandidatesMutation();
  const [candSource, setCandSource] = useState([]);
  const [candSubSource, setCandSubSource] = useState([]);
  const [userSource, setUserSource] = useState([]);
  const [candSourceVisible, setCandSourceVisible] = useState(false);
  const [sourceDef, setSourceDef] = useState([]);
  const [sourceSubDef, setSourceSubDef] = useState([]);
  const [degree, setDegree] = useState([]);
  const [countryOption, setCountryOption] = useState([]);
  const [countryDefOption, setCountryDefOption] = useState({});

  const skillPopulate = (skills: any) => {
    let data: any = [];
    skills?.map((item: any) => {
      data.push(item.skill.skill);
    });

    return data;
  };

  const tagPopulate = (tags: any) => {
    let data: any = [];
    tags?.map((item: any) => {
      data.push(item.tag.tag_name);
    });

    return data;
  };

  const populateEducation = (educations: any) => {
    let data: any = [];
    educations?.map((item: any) => {
      data.push({
        school: item.school,
        major_department: item.major_department,
        degree_level_id: item?.degree_level_id || 6,
        start_date: moment(item.start_date).format('MM/DD/YYYY HH:MM:SS'),
        end_date: moment(item.end_date).format('MM/DD/YYYY HH:MM:SS'),
        currently_pursuing: item.currently_pursuing,
      });
    });

    return data;
  };

  const populateExp = (experiences: any) => {
    let data: any = [];
    experiences?.map((item: any) => {
      data.push({
        company: item.company,
        job_title: item.job_title,
        start_date: moment(item.start_date).format('MM/DD/YYYY HH:MM:SS'),
        end_date: moment(item.end_date).format('MM/DD/YYYY HH:MM:SS'),
        currently_pursuing: item.currently_pursuing,
      });
    });
    return data;
  };

  const populateContact = (contact: any) => {
    let data: any = [];
    contact?.map((item: any) => {
      data.push({
        number: item.number,
        type: item.type,
        primary: item.primary,
        category: {
          value: item.category,
          label: (
            <FAIcon
              iconName={item.category === 'personal' ? 'user' : 'briefcase'}
            />
          ),
        },
      });
    });
    if (data.length === 0) {
      data.push({
        number: '',
        type: 'phone',
        primary: true,
        category: {
          value: 'personal',
          label: <FAIcon iconName="user" />,
        },
      });
    }
    return data;
  };

  const populateEmails = (emails: any) => {
    let data: any = [];

    emails?.map((item: any) => {
      data.push({
        email: item.email,
        primary: item.primary,
        category: {
          value: item.category || 'personal',
          label: (
            <FAIcon
              iconName={item.category === 'personal' ? 'user' : 'briefcase'}
            />
          ),
        },
      });
    });

    if (data.length === 0) {
      data.push({
        email: '',
        primary: true,
        category: {
          value: 'personal',
          label: <FAIcon iconName="user" />,
        },
      });
    }
    return data;
  };

  const populateDegree = () => {
    let options: any = [];
    educations?.map((item: any) => {
      options.push({
        value: item.id,
        label: item.name,
      });
    });
    setDegree(options);
  };

  const degreeDefault = (id: any) => {
    try {
      const data = educations
        .filter((item: any) => item.id === id)
        .map((item: any) => ({
          value: item.id,
          label: item.name,
        }));
      return data;
    } catch (e) {
      console.warn(`id ${id}`, e);
      return [];
    }
  };

  useEffect(() => {
    if (isOpen) {
      reqCountry({});
      populateDegree();
      reqCanWorkStat({});
    }
    if (candidateData) {
      if (candidateData?.source) {
        let option: any = [];
        option.push({
          label: candidateData?.source?.source,
          value: candidateData?.source?.id,
          sub: candidateData?.source?.sub,
        });
        setSourceDef(option);

        if (candidateData?.source?.source !== "Career's Page")
          setCandSourceVisible(true);
      }
      if (candidateData?.subsource) {
        let option: any = [];
        option.push({
          label: candidateData?.subsource?.sub_source,
          value: candidateData?.subsource?.id,
        });
        subSource(candidateData?.source?.sub);
        setSourceSubDef(option);
        setCandSourceVisible(true);
      }
      if (candidateData?.subsource) {
        let option: any = [];
        option.push({
          label: candidateData?.subsource?.sub_source,
          value: candidateData?.subsource?.id,
        });
        subSource(candidateData?.source?.sub);
        setSourceSubDef(option);
        setCandSourceVisible(true);
      }
      if (candidateData?.sub_source_id) {
        candidate_source.map((source: any) => {
          if (source.id === candidateData?.source?.id) {
            source?.sub?.map((sub: any) => {
              subSource(source?.sub);
              setCandSourceVisible(true);
              if (sub.id === candidateData?.sub_source_id) {
                const sub_source = [{
                  label: sub?.sub_source,
                  value: sub.id,
                }];
                setSourceSubDef(sub_source);
              }
            })
          }
        })
      }
      if (candidateData?.user_source) {
        let option: any = [];
        option.push({
          label: [
            candidateData?.user_source?.first_name,
            candidateData?.user_source?.last_name,
          ].join(' '),
          value: candidateData?.user_source?.id,
        });
        setSourceSubDef(option);
        setCandSourceVisible(true);

        setCandSubSource(userSource);
      }
      if (candidateData?.user_source_id) {
        Object.values(userList).map((item: any) => {
          let option: any = [];
          if (item.id == candidateData?.user_source_id) {
            option.push({
              label: [
                item?.first_name,
                item?.last_name,
              ].join(' '),
              value: item.id,
            });
            setSourceSubDef(option);
            setCandSourceVisible(true);
            setCandSubSource(userSource);
          }
        })
      }

      if (candidateData?.source?.id === 3 || candidateData?.source?.id === 6) {
        setCandSourceVisible(false);
        const data = {
          value: authSt?.id,
          label: [authSt?.first_name, authSt?.last_name].join(' '),
        };
        let option: any = [];
        option.push(data);
        setSourceSubDef(option);
        setCandSubSource(userSource);
      }
    }
  }, [isOpen, candidateData]);

  useEffect(() => {
    let sourceOption: any = [];
    let subSourceOption: any = [];

    if (candidate_source?.length > 0) {
      candidate_source.map((item: any) => {
        sourceOption.push({
          label: item.source,
          value: item.id,
          sub: item.sub,
        });
      });
    }
    setCandSource(sourceOption);
    Object.values(userList).map((item: any) => {
      subSourceOption.push({
        value: item.id,
        label: [item?.first_name, item?.last_name].join(' '),
      });
    });
    setUserSource(subSourceOption);
  }, [candidate_source, userList]);

  const [candidateFormData, setCandidateFormData] = useState<any>({});

  let initialValues: CandidatesTypes =
    edit &&
      ((candidateData && Object.keys(candidateData).length !== 0) ||
        (candidateFormData && Object.keys(candidateFormData).length !== 0))
      ? {
        // personal
        first_name:
          candidateFormData?.first_name || candidateData?.first_name || '',
        last_name:
          candidateFormData?.last_name || candidateData?.last_name || '',
        dnc: candidateFormData?.dnc || candidateData?.dnc,
        emails: candidateFormData.emails ||
          populateEmails(candidateData.emails) || [
            {
              email: '',
              primary: true,
              category: {
                value: 'personal',
                label: <FAIcon iconName="user" />,
              },
            },
          ],
        contact: candidateFormData.contact ||
          populateContact(candidateData.contact) || [
            {
              number: '',
              type: 'phone',
              primary: true,
              category: {
                value: 'personal',
                label: <FAIcon iconName="user" />,
              },
            },
          ],
        linkedin_url:
          candidateFormData?.linkedin_url ||
          candidateData?.linkedin_url ||
          '',
        work_status:
          candidateFormData?.work_status ||
          candidateData?.work_status_id?.toString(),
        source_type: candidateFormData?.source_type || sourceDef[0],
        sub_source_type:
          candidateFormData?.sub_source_type || sourceSubDef[0] || null,
        skills:
          candidateFormData?.skills ||
          skillPopulate(candidateData.skills) ||
          [],
        zip_code: candidateFormData?.zip_code || candidateData?.zip_code,
        city: candidateFormData?.city || candidateData?.city,
        state_province:
          candidateFormData?.state_province || candidateData?.state_province,
        country:
          candidateFormData?.country ||
          (countries?.length > 0 &&
            countries
              .map((item: any) => ({
                label: item.country,
                value: item.id,
                code: item.code,
              }))
              .filter((val: any) => val.label === candidateData.country))[0],
        // professional
        total_year_of_experience:
          candidateFormData?.total_year_of_experience ||
          candidateData?.total_year_of_experience,
        total_months_of_experience:
          candidateFormData?.total_months_of_experience ||
          candidateData?.total_months_of_experience,
        latest_job_title:
          candidateFormData?.latest_job_title ||
          candidateData?.latest_job_title,
        latest_employer:
          candidateFormData?.latest_employer ||
          candidateData?.latest_employer,
        latest_salary:
          candidateFormData?.latest_salary ||
          candidateData?.latest_salary?.toLocaleString(),
        expected_salary_start:
          candidateFormData?.expected_salary_start ||
          candidateData?.expected_salary_start?.toLocaleString(),
        expected_salary_end:
          candidateFormData?.expected_salary_end ||
          candidateData?.expected_salary_end?.toLocaleString(),
        attachments: [],
        // exp
        experiences:
          candidateFormData.experiences ||
          populateExp(candidateData.experience) ||
          [],
        // educ
        educations:
          candidateFormData.educations ||
          populateEducation(candidateData.educations) ||
          [],
        //tags
        tags: candidateFormData.tags || tagPopulate(candidateData.tags) || [],
        //
        resume_link:
          candidateFormData?.resume_link || candidateData?.resume_link || '',
        sub_source:
          candidateFormData?.sub_source || sourceSubDef[0]?.label || '',
      }
      : candidateFormData && Object.keys(candidateFormData).length !== 0
        ? {
          // personal
          first_name:
            candidateFormData?.first_name || candidateData?.first_name || '',
          last_name:
            candidateFormData?.last_name || candidateData?.last_name || '',
          emails: candidateFormData.emails ||
            populateEmails(candidateData.emails) || [
              {
                email: '',
                primary: true,
                category: {
                  value: 'personal',
                  label: <FAIcon iconName="user" />,
                },
              },
            ],
          contact: candidateFormData.contact ||
            populateContact(candidateData.contact) || [
              {
                number: '',
                type: 'phone',
                primary: true,
                category: {
                  value: 'personal',
                  label: <FAIcon iconName="user" />,
                },
              },
            ],
          linkedin_url:
            candidateFormData?.linkedin_url ||
            candidateData?.linkedin_url ||
            '',
          work_status:
            candidateFormData?.work_status ||
            candidateData?.work_status_id?.toString() ||
            null,
          source_type: candidateFormData?.source_type || sourceDef[0] || [],
          sub_source_type:
            candidateFormData?.sub_source_type || sourceSubDef[0] || null,
          skills:
            candidateFormData?.skills ||
            skillPopulate(candidateData.skills) ||
            [],
          zip_code:
            candidateFormData?.zip_code || candidateData?.zip_code || '',
          city: candidateFormData?.city || candidateData?.city || '',
          state_province:
            candidateFormData?.state_province ||
            candidateData?.state_province ||
            '',
          country:
            candidateFormData?.country ||
            (countries?.length > 0 &&
              countries
                .map((item: any) => ({
                  label: item.country,
                  value: item.id,
                  code: item.code,
                }))
                .filter(
                  (val: any) => val.label === candidateData.country
                ))[0] ||
            countryDefOption,
          // professional
          total_year_of_experience:
            candidateFormData?.total_year_of_experience ||
            candidateData?.total_year_of_experience,
          total_months_of_experience:
            candidateFormData?.total_months_of_experience ||
            candidateData?.total_months_of_experience,
          latest_job_title:
            candidateFormData?.latest_job_title ||
            candidateData?.latest_job_title ||
            '',
          latest_employer:
            candidateFormData?.latest_employer ||
            candidateData?.latest_employer ||
            '',
          latest_salary:
            candidateFormData?.latest_salary ||
            candidateData?.latest_salary?.toLocaleString() ||
            (undefined as any),
          expected_salary_start:
            candidateFormData?.expected_salary_start ||
            candidateData?.expected_salary_start?.toLocaleString() ||
            (undefined as any),
          expected_salary_end:
            candidateFormData?.expected_salary_end ||
            candidateData?.expected_salary_end?.toLocaleString() ||
            (undefined as any),
          attachments: [],
          // exp
          experiences:
            candidateFormData.experiences ||
            populateExp(candidateData.experience) ||
            [],
          // educ
          educations:
            candidateFormData.educations ||
            populateEducation(candidateData.educations) ||
            [],
          //tags
          tags: candidateFormData.tags || tagPopulate(candidateData.tags) || [],
          resume_link:
            candidateFormData?.resume_link || candidateData?.resume_link || '',
          sub_source:
            candidateFormData?.sub_source || sourceSubDef[0]?.label || '',
        }
        : {
          // personal
          first_name: '',
          last_name: '',
          dnc: false,
          emails: [
            {
              email: '',
              primary: true,
              category: {
                value: 'personal',
                label: <FAIcon iconName="user" />,
              },
            },
          ],
          contact: [
            {
              number: '',
              type: 'phone',
              primary: true,
              category: {
                value: 'personal',
                label: <FAIcon iconName="user" />,
              },
            },
          ],
          linkedin_url: '',
          work_status: '',
          source_type: '',
          sub_source_type: null,
          skills: [],
          zip_code: '',
          city: '',
          state_province: '',
          country: countryDefOption,
          // professional
          total_year_of_experience: null,
          total_months_of_experience: null,
          latest_job_title: '',
          latest_employer: '',
          latest_salary: null,
          expected_salary_start: null,
          expected_salary_end: null,
          attachments: [],
          // exp
          experiences: [],
          // educ
          educations: [],
          // tags
          tags: [],
          resume_link: '',
          sub_source: '',
        };
  let initialPersonalValues =
    edit &&
      ((candidateData && Object.keys(candidateData).length !== 0) ||
        (candidateFormData && Object.keys(candidateFormData).length !== 0))
      ? {
        // personal
        first_name:
          candidateFormData?.first_name || candidateData?.first_name || '',
        last_name:
          candidateFormData?.last_name || candidateData?.last_name || '',
        dnc: candidateFormData?.dnc || candidateData?.dnc,
        emails: candidateFormData.emails ||
          populateEmails(candidateData.emails) || [
            {
              email: '',
              primary: true,
              category: {
                value: 'personal',
                label: <FAIcon iconName="user" />,
              },
            },
          ],
        contact: candidateFormData.contact ||
          populateContact(candidateData.contact) || [
            {
              number: '',
              type: 'phone',
              primary: true,
              category: {
                value: 'personal',
                label: <FAIcon iconName="user" />,
              },
            },
          ],
        linkedin_url:
          candidateFormData?.linkedin_url ||
          candidateData?.linkedin_url ||
          '',
        work_status:
          candidateFormData?.work_status ||
          candidateData?.work_status_id?.toString(),
        source_type: candidateFormData?.source_type || sourceDef[0],
        sub_source_type:
          candidateFormData?.sub_source_type || sourceSubDef[0] || null,
        skills:
          candidateFormData?.skills ||
          skillPopulate(candidateData.skills) ||
          [],
        zip_code: candidateFormData?.zip_code || candidateData?.zip_code,
        city: candidateFormData?.city || candidateData?.city,
        state_province:
          candidateFormData?.state_province || candidateData?.state_province,
        country:
          candidateFormData?.country ||
          (countries?.length > 0 &&
            countries
              .map((item: any) => ({
                label: item.country,
                value: item.id,
                code: item.code,
              }))
              .filter((val: any) => val.label === candidateData.country))[0],
      }
      : candidateFormData && Object.keys(candidateFormData).length !== 0
        ? {
          // personal
          first_name: candidateFormData?.first_name || '',
          last_name: candidateFormData?.last_name || '',
          dnc: candidateFormData?.dnc || false,
          emails: candidateFormData.emails ||
            populateEmails(candidateData.emails) || [
              {
                email: '',
                primary: true,
                category: {
                  value: 'personal',
                  label: <FAIcon iconName="user" />,
                },
              },
            ],
          contact: candidateFormData.contact ||
            populateContact(candidateData.contact) || [
              {
                number: '',
                type: 'phone',
                primary: true,
                category: {
                  value: 'personal',
                  label: <FAIcon iconName="user" />,
                },
              },
            ],
          linkedin_url: candidateFormData?.linkedin_url || '',
          work_status: candidateFormData?.work_status || null,
          source_type: candidateFormData?.source_type || '',
          sub_source_type: candidateFormData?.sub_source_type || null,
          skills:
            candidateFormData?.skills ||
            skillPopulate(candidateFormData.skills) ||
            [],
          zip_code: candidateFormData?.zip_code || '',
          city: candidateFormData?.city || '',
          state_province: candidateFormData?.state_province || '',
          country: candidateFormData?.country || countryDefOption,
        }
        : {
          // personal
          first_name: '',
          last_name: '',
          dnc: false,
          emails: [
            {
              email: '',
              primary: true,
              category: {
                value: 'personal',
                label: <FAIcon iconName="user" />,
              },
            },
          ],
          contact: [
            {
              number: '',
              type: 'phone',
              primary: true,
              category: {
                value: 'personal',
                label: <FAIcon iconName="user" />,
              },
            },
          ],
          linkedin_url: '',
          work_status: '',
          source_type: '',
          sub_source_type: null as any,
          skills: [] as any,
          zip_code: '',
          city: '',
          state_province: '',
          country: countryDefOption,
        };

  let initialProfessionalValues: any =
    edit &&
      ((candidateData && Object.keys(candidateData).length !== 0) ||
        (candidateFormData && Object.keys(candidateFormData).length !== 0))
      ? {
        // professional
        total_year_of_experience:
          candidateFormData?.total_year_of_experience ||
          candidateData?.total_year_of_experience,
        total_months_of_experience:
          candidateFormData?.total_months_of_experience ||
          candidateData?.total_months_of_experience,
        latest_job_title:
          candidateFormData?.latest_job_title ||
          candidateData?.latest_job_title,
        latest_employer:
          candidateFormData?.latest_employer ||
          candidateData?.latest_employer,
        latest_salary:
          candidateFormData?.latest_salary ||
          candidateData?.latest_salary?.toLocaleString(),
        expected_salary_start:
          candidateFormData?.expected_salary_start ||
          candidateData?.expected_salary_start?.toLocaleString(),
        expected_salary_end:
          candidateFormData?.expected_salary_end ||
          candidateData?.expected_salary_end?.toLocaleString(),
        attachments: [],
      }
      : candidateFormData && Object.keys(candidateFormData).length !== 0
        ? {
          // professional
          total_year_of_experience:
            candidateFormData?.total_year_of_experience ||
            candidateData?.total_year_of_experience,
          total_months_of_experience:
            candidateFormData?.total_months_of_experience ||
            candidateData?.total_months_of_experience,
          latest_job_title:
            candidateFormData?.latest_job_title ||
            candidateData?.latest_job_title ||
            '',
          latest_employer:
            candidateFormData?.latest_employer ||
            candidateData?.latest_employer ||
            '',
          latest_salary:
            candidateFormData?.latest_salary ||
            candidateData?.latest_salary?.toLocaleString() ||
            (undefined as any),
          expected_salary_start:
            candidateFormData?.expected_salary_start ||
            candidateData?.expected_salary_start?.toLocaleString() ||
            (undefined as any),
          expected_salary_end:
            candidateFormData?.expected_salary_end ||
            candidateData?.expected_salary_end?.toLocaleString() ||
            (undefined as any),
          attachments: [],
        }
        : {
          // professional
          total_year_of_experience: null,
          total_months_of_experience: null,
          latest_job_title: '',
          latest_employer: '',
          latest_salary: undefined as any,
          expected_salary_start: undefined as any,
          expected_salary_end: undefined as any,
          attachments: [],
        };

  let initialExpValues: any =
    edit &&
      ((candidateData && Object.keys(candidateData).length !== 0) ||
        (candidateFormData && Object.keys(candidateFormData).length !== 0))
      ? {
        // exp
        experiences:
          candidateFormData.experiences ||
          populateExp(candidateData.experience) ||
          [],
        // educ
        educations:
          candidateFormData.educations ||
          populateEducation(candidateData.educations) ||
          [],
        //tags
        tags: candidateFormData.tags || tagPopulate(candidateData.tags) || [],
        //
        resume_link:
          candidateFormData?.resume_link || candidateData?.resume_link || '',
        sub_source:
          candidateFormData?.sub_source || sourceSubDef[0]?.label || '',
      }
      : candidateFormData && Object.keys(candidateFormData).length !== 0
        ? {
          // exp
          experiences:
            candidateFormData.experiences ||
            populateExp(candidateData.experience) ||
            [],
          // educ
          educations:
            candidateFormData.educations ||
            populateEducation(candidateData.educations) ||
            [],
          //tags
          tags: candidateFormData.tags || tagPopulate(candidateData.tags) || [],
          //
          resume_link:
            candidateFormData?.resume_link || candidateData?.resume_link || '',
          sub_source:
            candidateFormData?.sub_source || sourceSubDef[0]?.label || '',
        }
        : {
          // exp
          experiences: [],
          // educ
          educations: [],
          // tags
          tags: [],
          //
          resume_link: '',
          sub_source: '',
        };

  let initialEducValues: any =
    edit &&
      ((candidateData && Object.keys(candidateData).length !== 0) ||
        (candidateFormData && Object.keys(candidateFormData).length !== 0))
      ? {
        // educ
        educations:
          candidateFormData.educations ||
          populateEducation(candidateData.educations) ||
          [],
      }
      : candidateFormData && Object.keys(candidateFormData).length !== 0
        ? {
          // educ
          educations:
            candidateFormData.educations ||
            populateEducation(candidateData.educations) ||
            [],
        }
        : {
          // educ
          educations: [],
        };

  const validationSchema = edit
    ? Yup.lazy(() =>
      Yup.object().shape({
        // personal
        first_name: Yup.string(),
        last_name: Yup.string().required('Last name is required.'),
        linkedin_url: Yup.string(),
        work_status: Yup.string().nullable(),
        source_type: Yup.object().shape({
          label: Yup.string().required('Pick at least 1 Source'),
          value: Yup.number(),
          sub: Yup.array(),
        }),
        sub_source_type: Yup.object().when('source_type', {
          is: (value: any) => value.label === "Career's Page",
          then: Yup.object().shape({}).nullable(),
          otherwise: Yup.object().shape({
            label: Yup.string().required('Pick at least 1 Sub Source'),
            value: Yup.number(),
          }),
        }),
        skills: Yup.array().of(Yup.string()),
        zip_code: Yup.string().required('Zip Code is required').nullable(),
        city: Yup.string().required('City is required').nullable(),
        state_province: Yup.string().required('State is required').nullable(),
        country: Yup.object().required('Country is required'),

        // professional
        total_year_of_experience: Yup.number().nullable(),
        total_months_of_experience: Yup.number().nullable(),
        latest_job_title: Yup.string(),
        latest_employer: Yup.string(),
        latest_salary: Yup.string().matches(
          /^[\d,]+$/gi,
          'Salary is invalid'
        ),
        expected_salary_start: Yup.string().matches(
          /^[\d,]+$/gi,
          'Salary is invalid'
        ),
        expected_salary_end: Yup.string().matches(
          /^[\d,]+$/gi,
          'Salary is invalid'
        ),
        attachments: Yup.array().of(
          Yup.object().shape({
            file_name: Yup.string(),
            file_size: Yup.number(),
            file_type: Yup.string(),
            location: Yup.string(),
            original_file_name: Yup.string(),
          })
        ),
        emails: Yup.array()
          .of(
            Yup.object().shape({
              email: Yup.string().required('Email is required'),
              primary: Yup.boolean(),
            })
          )
          .test(
            'emails-one-primary',
            'There should only be one primary email',
            (value) => {
              const primaryCount = value.filter(
                (item) => item?.primary
              ).length;
              return primaryCount === 1;
            }
          )
          .test('email-unique', 'Email should be unique', (value) => {
            const numArr = value.map((item: any) => item.email);
            return numArr.length === new Set(numArr).size;
          }),
        contact: Yup.array()
          .of(
            Yup.object().shape({
              number: Yup.string().matches(
                /^[\d ()+-.]+$/gi,
                'Contact is invalid'
              ),
              primary: Yup.boolean(),
              type: Yup.string(),
            })
          )
          .test(
            'contact-one-primary',
            'There should only be one primary contact',
            (value) => {
              const primaryCount = value.filter(
                (item) => item?.primary
              ).length;
              return primaryCount === 1;
            }
          )
          .test(
            'contact-unique',
            'Contact number should be unique',
            (value) => {
              const numArr = value.map((item: any) => item.number);
              return numArr.length === new Set(numArr).size;
            }
          ),
        // exp
        experiences: Yup.array().of(
          Yup.object().shape({
            company: Yup.string(),
            job_title: Yup.string(),
            start_date: Yup.string(),
            end_date: Yup.string(),
            currently_pursuing: Yup.boolean(),
          })
        ),
        // educ
        educations: Yup.array().of(
          Yup.object().shape({
            school: Yup.string(),
            major: Yup.string(),
            degree_level_id: Yup.number(),
            start_date: Yup.string(),
            end_date: Yup.string(),
            currently_pursuing: Yup.boolean(),
          })
        ),
        // tags
        tags: Yup.array().of(Yup.string()),
        //
        resume_link: Yup.string(),
        sub_source: Yup.string(),
      })
    )
    : Yup.lazy(() =>
      Yup.object().shape({
        // personal
        first_name: Yup.string(),
        last_name: Yup.string().required('Last name is required.'),
        linkedin_url: Yup.string(),
        work_status: Yup.string().nullable(),
        source_type: Yup.object().shape({
          label: Yup.string().required('Pick at least 1 Source'),
          value: Yup.number(),
          sub: Yup.array(),
        }),
        sub_source_type: Yup.object().when('source_type', {
          is: (value: any) => value.label === "Career's Page",
          then: Yup.object().shape({}).nullable(),
          otherwise: Yup.object().shape({
            label: Yup.string().required('Pick at least 1 Sub Source'),
            value: Yup.number(),
          }),
        }),
        skills: Yup.array().of(Yup.string()),
        zip_code: Yup.string().nullable(),
        city: Yup.string().nullable(),
        state_province: Yup.string().nullable(),
        country: Yup.object(),

        // professional
        total_year_of_experience: Yup.number().nullable(),
        total_months_of_experience: Yup.number().nullable(),
        latest_job_title: Yup.string(),
        latest_employer: Yup.string(),
        latest_salary: Yup.string().matches(
          /^[\d,]+$/gi,
          'Salary is invalid'
        ),
        expected_salary_start: Yup.string().matches(
          /^[\d,]+$/gi,
          'Salary is invalid'
        ),
        expected_salary_end: Yup.string().matches(
          /^[\d,]+$/gi,
          'Salary is invalid'
        ),
        attachments: Yup.array().of(
          Yup.object().shape({
            file_name: Yup.string(),
            file_size: Yup.number(),
            file_type: Yup.string(),
            location: Yup.string(),
            original_file_name: Yup.string(),
          })
        ),
        emails: Yup.array()
          .of(
            Yup.object().shape({
              email: Yup.string().required('Email is required'),
              primary: Yup.boolean(),
            })
          )
          .test(
            'emails-one-primary',
            'There should only be one primary email',
            (value) => {
              const primaryCount = value.filter(
                (item) => item?.primary
              ).length;
              return primaryCount === 1;
            }
          )
          .test('email-unique', 'Email should be unique', (value) => {
            const numArr = value.map((item: any) => item.email);
            return numArr.length === new Set(numArr).size;
          }),
        contact: Yup.array()
          .of(
            Yup.object().shape({
              number: Yup.string().matches(
                /^[\d ()+-.]+$/gi,
                'Contact is invalid'
              ),
              primary: Yup.boolean(),
              type: Yup.string(),
            })
          )
          .test(
            'contact-one-primary',
            'There should only be one primary contact',
            (value) => {
              const primaryCount = value.filter(
                (item) => item?.primary
              ).length;
              return primaryCount === 1;
            }
          )
          .test(
            'contact-unique',
            'Contact number should be unique',
            (value) => {
              const numArr = value.map((item: any) => item.number);
              return numArr.length === new Set(numArr).size;
            }
          ),
        // exp
        experiences: Yup.array().of(
          Yup.object().shape({
            company: Yup.string(),
            job_title: Yup.string(),
            start_date: Yup.string(),
            end_date: Yup.string(),
            currently_pursuing: Yup.boolean(),
          })
        ),
        // educ
        educations: Yup.array().of(
          Yup.object().shape({
            school: Yup.string(),
            major: Yup.string(),
            degree_level_id: Yup.number(),
            start_date: Yup.string(),
            end_date: Yup.string(),
            currently_pursuing: Yup.boolean(),
          })
        ),
        // tags
        tags: Yup.array().of(Yup.string()),
        //
        resume_link: Yup.string(),
        sub_source: Yup.string(),
      })
    );

  const validationPersonalSchema = edit
    ? Yup.lazy(() =>
      Yup.object().shape({
        // personal
        first_name: Yup.string(),
        last_name: Yup.string().required('Last name is required.'),
        // emails
        emails: Yup.array()
          .of(
            Yup.object().shape({
              email: Yup.string().required('Email is required'),
              primary: Yup.boolean(),
            })
          )
          .test(
            'emails-one-primary',
            'There should only be one primary email',
            (value) => {
              const primaryCount = value?.filter(
                (item) => item?.primary
              ).length;
              return primaryCount === 1;
            }
          )
          .test('email-unique', 'Email should be unique', (value) => {
            const numArr = value?.map((item: any) => item?.email);
            return numArr.length === new Set(numArr).size;
          }),
        // contact
        contact: Yup.array()
          .of(
            Yup.object().shape({
              number: Yup.string().matches(
                /^[\d ()+-.]+$/gi,
                'Contact is invalid'
              ),
              primary: Yup.boolean(),
              type: Yup.string(),
            })
          )
          .test(
            'contact-one-primary',
            'There should only be one primary contact',
            (value) => {
              const primaryCount = value?.filter(
                (item) => item?.primary
              ).length;
              return primaryCount === 1;
            }
          )
          .test(
            'contact-unique',
            'Contact number should be unique',
            (value) => {
              const numArr = value?.map((item: any) => item?.number);
              return numArr.length === new Set(numArr).size;
            }
          ),
        linkedin_url: Yup.string(),
        work_status: Yup.string().nullable(),
        source_type: Yup.object().shape({
          label: Yup.string().required('Pick at least 1 Source'),
          value: Yup.number(),
          sub: Yup.array(),
        }),
        sub_source_type: Yup.object().when('source_type', {
          is: (value: any) => value.label === "Career's Page",
          then: Yup.object().shape({}).nullable(),
          otherwise: Yup.object().shape({
            label: Yup.string().required('Pick at least 1 Sub Source'),
            value: Yup.number(),
          }),
        }),
        skills: Yup.array().of(Yup.string()),
        zip_code: Yup.string().nullable(),
        city: Yup.string().nullable(),
        state_province: Yup.string().nullable(),
        country: Yup.object(),
      })
    )
    : Yup.lazy(() =>
      Yup.object().shape({
        // personal
        first_name: Yup.string(),
        last_name: Yup.string().required('Last name is required.'),
        // emails
        emails: Yup.array()
          .of(
            Yup.object().shape({
              email: Yup.string().required('Email is required'),
              primary: Yup.boolean(),
            })
          )
          .test(
            'emails-one-primary',
            'There should only be one primary email',
            (value) => {
              const primaryCount = value?.filter(
                (item) => item?.primary
              ).length;
              return primaryCount === 1;
            }
          )
          .test('email-unique', 'Email should be unique', (value) => {
            const numArr = value?.map((item: any) => item?.email);
            return numArr.length === new Set(numArr).size;
          }),
        // contact
        contact: Yup.array()
          .of(
            Yup.object().shape({
              number: Yup.string().matches(
                /^[\d ()+-.]+$/gi,
                'Contact is invalid'
              ),
              primary: Yup.boolean(),
              type: Yup.string(),
            })
          )
          .test(
            'contact-one-primary',
            'There should only be one primary contact',
            (value) => {
              const primaryCount = value?.filter(
                (item) => item?.primary
              ).length;
              return primaryCount === 1;
            }
          )
          .test(
            'contact-unique',
            'Contact number should be unique',
            (value) => {
              const numArr = value?.map((item: any) => item?.number);
              return numArr.length === new Set(numArr).size;
            }
          ),
        linkedin_url: Yup.string(),
        work_status: Yup.string().nullable(),
        source_type: Yup.object().shape({
          label: Yup.string().required('Pick at least 1 Source'),
          value: Yup.number(),
          sub: Yup.array(),
        }),
        sub_source_type: Yup.object().when('source_type', {
          is: (value: any) => value.label === "Career's Page",
          then: Yup.object().shape({}).nullable(),
          otherwise: Yup.object().shape({
            label: Yup.string().required('Pick at least 1 Sub Source'),
            value: Yup.number(),
          }),
        }),
        skills: Yup.array().of(Yup.string()),
        zip_code: Yup.string().required('Zip Code is required').nullable(),
        city: Yup.string().required('City is required').nullable(),
        state_province: Yup.string().required('State is required').nullable(),
        country: Yup.object().required('Country is required'),
      })
    );

  const validationProfessionalSchema = Yup.lazy(() =>
    Yup.object().shape({
      // professional
      total_year_of_experience: Yup.number().nullable(),
      total_months_of_experience: Yup.number().nullable(),
      latest_job_title: Yup.string(),
      latest_employer: Yup.string(),
      latest_salary: Yup.string().matches(/^[\d,]+$/gi, 'Salary is invalid'),
      expected_salary_start: Yup.string().matches(
        /^[\d,]+$/gi,
        'Salary is invalid'
      ),
      expected_salary_end: Yup.string().matches(
        /^[\d,]+$/gi,
        'Salary is invalid'
      ),
      attachments: Yup.array().of(
        Yup.object().shape({
          file_name: Yup.string(),
          file_size: Yup.number(),
          file_type: Yup.string(),
          location: Yup.string(),
          original_file_name: Yup.string(),
        })
      ),
    })
  );

  const validationExpSchema = Yup.lazy(() =>
    Yup.object().shape({
      // exp
      experiences: Yup.array().of(
        Yup.object().shape({
          company: Yup.string(),
          job_title: Yup.string(),
          start_date: Yup.string(),
          end_date: Yup.string(),
          currently_pursuing: Yup.boolean(),
        })
      ),
    })
  );

  const validationEducSchema = Yup.lazy(() =>
    Yup.object().shape({
      // educ
      educations: Yup.array().of(
        Yup.object().shape({
          school: Yup.string(),
          major: Yup.string(),
          start_date: Yup.string(),
          end_date: Yup.string(),
          degree_level_id: Yup.number(),
          currently_pursuing: Yup.boolean(),
        })
      ),
    })
  );

  const tabListIndex: any = [
    { id: 0, title: 'Personal Information' },
    { id: 1, title: 'Professional Details' },
    { id: 2, title: 'Experience Details' },
    { id: 3, title: 'Education Details' },
    { id: 4, title: 'Tags' },
  ];

  const [tabIndex, setTabIndex] = useState(tabListIndex[0]);
  const [hasSkills, setHasSkills] = useState('');
  const [hasTags, setHasTags] = useState('');

  useEffect(() => {
    if (edit) {
      reqCandidate({ id });
    }
  }, [edit, id]);

  useEffect(() => {
    if (resCandidate.isSuccess) {
    }
  }, [resCandidate.isSuccess]);

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false });

  const onSubmit = async (data: any) => {
    data['attachments'] = await changeUploadName(uploaded, prefix);
    data['resumes_id'] = resumeUploaded.map((resume: any) => resume?.id);
    data.latest_salary = Number(
      (data.latest_salary as string)?.replace(/,/g, '')
    );
    data.expected_salary_start = Number(
      (data.expected_salary_start as string)?.replace(/,/g, '')
    );
    data.expected_salary_end = Number(
      (data.expected_salary_end as string)?.replace(/,/g, '')
    );

    data.source_type = data.source_type?.label;
    data.sub_source_type = data.sub_source_type?.value || null;

    const checkEmailPrimary = data.emails.some(
      (item: any) => item.primary === true
    );
    data.emails[0].primary = checkEmailPrimary ? data.emails[0]?.primary : true;

    if (data.contact.length > 0) {
      const checkContactPrimary = data.contact.some(
        (item: any) => item.primary === true
      );
      data.contact[0].primary = checkContactPrimary
        ? data.contact[0]?.primary
        : true;
      const filteredContact = data.contact.filter(
        (item: any) => item.number !== ''
      );
      data.contact = filteredContact;
    }

    data.contact = data.contact.map((item: any) => {
      return {
        ...item,
        category: item.category.value,
      };
    });

    data.emails = data.emails.map((item: any) => {
      return {
        ...item,
        category: item.category.value,
      };
    });

    data.country = data.country.label;

    if (edit) {
      await reqCandidateUpdate({ data, id });
    } else {
      data['data_source'] = 'form'
      await reqCreateCandidate(data);
    }

    setTabIndex(tabListIndex[0]);
  };

  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    if (resCreateCandidate.isSuccess || resCandidateUpdate.isSuccess) {
      onCloseAlert();
      onClose();
      dispatch(getCandidate({ candidateData: {} }));

      //this will be needed for the candidate list group by jobs
      if (typeof onSuccess === 'function') {
        onSuccess();
      } else {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }

        abortControllerRef.current = new AbortController();
        reqCandidates({
          data: candidatePgBtn,
          signal: abortControllerRef.current.signal,
        });
      }
      //putting this in here. being outside of this condition making the resumeUploaded empty before submitting

      dispatch(cleanUpload({ resumeUploaded: [] }));
      dispatch(uploadList({ prefix: 'Attachment' }));
      dispatch(cleanUpload({ attachments: [] }));
      dispatch(cleanUpload({ uploaded: [] }));
    } else if (resCreateCandidate.isError) {
      onOpen();
    }
  }, [
    resCreateCandidate.isError,
    resCreateCandidate.isSuccess,
    resCandidateUpdate.isSuccess,
  ]); //removing unnecessary dependencies here

  const subSource = (sub: any) => {
    let option: any = [];
    if (sub?.length > 0) {
      sub.map((item: any) => {
        option.push({
          label: item?.sub_source,
          value: item?.id,
        });
      });
    }
    setCandSubSource(option);
  };

  useEffect(() => {
    let options: any = [];
    if (countries?.length > 0) {
      countries.map((item: any) => {
        options.push({
          label: item.country,
          value: item.id,
          code: item.code,
        });

        if (item.code === 'us') {
          setCountryDefOption({
            label: item.country,
            value: item.id,
            code: item.code,
          });
        }
      });
    }
    setCountryOption(options);
  }, [countries]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onCloseAlert();
          onClose();
          !edit && dispatch(getCandidate({ candidateData: {} }));
        }}
        size="5xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="NunitoSans Regular">
          <ModalHeader
            background="#F4F6F9"
            borderBottom="1px solid gainsboro"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            {edit ? 'Edit Candidate' : 'Add Candidate'}
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          {isOpenAlert && (
            <Box>
              <Alert status="error" justifyContent="space-around">
                <AlertIcon />
                <Box>
                  <AlertTitle>Oh snap!</AlertTitle>
                  <AlertDescription>
                    There was an error processing your request. Change a few
                    things up and try again.
                  </AlertDescription>
                </Box>
                <CloseButton
                  position="relative"
                  right={-1}
                  top={-3}
                  onClick={onCloseAlert}
                />
              </Alert>
            </Box>
          )}
          <ModalBody
            borderRadius="0 0 4px 4px"
            p={0}
            fontFamily="NunitoSans Regular"
          >
            <Tabs colorScheme="purple" variant="unstyled" index={tabIndex.id}>
              <Flex gap={2} background="#F9F9FB">
                <Box
                  width="30%"
                  py={4}
                  overflowY="scroll"
                  borderRight="1px solid"
                  borderColor="transparent"
                  px={6}
                >
                  <TabList
                    flexDirection="column"
                    alignItems="flex-start"
                    gap="8px"
                  >
                    {tabListIndex.map((tab: any) => {
                      return (
                        <Tab
                          key={tab.id}
                          justifyContent="space-between"
                          width="100%"
                          border="0.5px solid rgba(155, 155, 155, 0.5)"
                          borderRadius="3px"
                          background="#fff"
                          p="12px 20px"
                          lineHeight="18px"
                          fontSize="14px"
                          fontWeight="600"
                          _selected={{
                            bg: '#EFE5FF',
                            border: '0.5px solid #EFE5FF',
                          }}
                        >
                          {tab.title}
                          <Icon
                            as={ChevronLeftIcon}
                            transition="all .25s ease-in-out"
                            transform={isOpen ? 'rotate(180deg)' : ''}
                            w={6}
                            h={6}
                            ml="8px"
                            color={
                              tabIndex.id === tab.id ? 'primary.800' : '#fff'
                            }
                          />
                        </Tab>
                      );
                    })}
                  </TabList>
                </Box>

                <Box width="70%">
                  <TabPanels>
                    {tabIndex.id === 0 && (
                      <Formik
                        initialValues={initialPersonalValues}
                        validationSchema={validationPersonalSchema}
                        onSubmit={(data, { setSubmitting, validateForm }) => {
                          setCandidateFormData((prev: any) => ({
                            ...prev,
                            ...data,
                          }));
                          setSubmitting(false);
                          validateForm().then((errors) => {
                            if (
                              Object.keys(errors).length === 0 &&
                              tabListIndex?.length !== tabIndex.id + 1
                            ) {
                              setTabIndex(tabListIndex[tabIndex.id + 1]);
                            }
                          });
                        }}
                        enableReinitialize={true}
                      >
                        {({
                          values,
                          handleChange,
                          handleSubmit,
                          errors,
                          touched,
                          isValid,
                          setFieldValue,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <ScrollToFieldError />
                            <TabPanels>
                              {resCandidate.isLoading ? (
                                <Box
                                  overflowY="scroll"
                                  minHeight="450px"
                                  p="30px"
                                  background="#fff"
                                  borderRadius="8px"
                                >
                                  <LoadingEditCandidate />
                                </Box>
                              ) : (
                                <Box
                                  overflowY="scroll"
                                  maxHeight="450px"
                                  p="30px"
                                  background="#fff"
                                  borderRadius="8px"
                                >
                                  <Flex gap="32px" mb="34px">
                                    <FormControl
                                      isInvalid={Boolean(
                                        !!errors.first_name &&
                                        touched.first_name
                                      )}
                                    >
                                      <FormLabel
                                        fontSize="14px"
                                        lineHeight="18px"
                                        color="default.secondarytext"
                                      >
                                        First Name
                                      </FormLabel>
                                      <Input
                                        id="first_name"
                                        name="first_name"
                                        type="text"
                                        placeholder="First Name"
                                        variant="outline"
                                        value={values.first_name}
                                        onChange={handleChange}
                                      />
                                      <FormErrorMessage>
                                        {String(errors.first_name)}
                                      </FormErrorMessage>
                                    </FormControl>
                                    <FormControl
                                      isInvalid={Boolean(
                                        !!errors.last_name && touched.last_name
                                      )}
                                    >
                                      <FormLabel
                                        fontSize="14px"
                                        lineHeight="18px"
                                        color="default.secondarytext"
                                      >
                                        Last Name{' '}
                                        <Box as="span" color="caution.800">
                                          *
                                        </Box>
                                      </FormLabel>
                                      <Input
                                        id="last_name"
                                        name="last_name"
                                        type="text"
                                        placeholder="Last Name"
                                        variant="outline"
                                        value={values.last_name}
                                        onChange={handleChange}
                                      />
                                      <FormErrorMessage>
                                        {String(errors.last_name)}
                                      </FormErrorMessage>
                                    </FormControl>
                                  </Flex>
                                  <Flex gap="32px" mb="34px">
                                    <FormControl>
                                      <Checkbox
                                        colorScheme="purple"
                                        color="default.secondarytext"
                                        mx={1}
                                        onChange={handleChange}
                                        name="dnc"
                                        id="dnc"
                                        defaultChecked={values.dnc}
                                      >
                                        DNC
                                      </Checkbox>
                                    </FormControl>
                                  </Flex>
                                  <Flex gap="32px" mb="34px">
                                    <Box
                                      overflowY="auto"
                                      maxHeight="450px"
                                      width="100%"
                                    >
                                      <FieldArray name="contact">
                                        {({ remove, push }) => (
                                          <div>
                                            <FormLabel
                                              fontSize="14px"
                                              lineHeight="18px"
                                              color="default.secondarytext"
                                            >
                                              Contact
                                            </FormLabel>

                                            {values.contact?.map(
                                              (cont: any, index: any) => (
                                                <FormControl
                                                  isInvalid={Boolean(
                                                    !!errors.contact &&
                                                    touched.contact
                                                  )}
                                                >
                                                  <FormControl
                                                    isInvalid={Boolean(
                                                      !!(
                                                        errors.contact as any[]
                                                      )?.[index]?.number &&
                                                      (
                                                        touched.contact as any[]
                                                      )?.[index]?.number
                                                    )}
                                                  >
                                                    <Flex
                                                      width="100%"
                                                      direction="row"
                                                      key={index}
                                                      mb="12px"
                                                      alignItems="center"
                                                      gap="12px"
                                                    >
                                                      <Flex width="70%">
                                                        <Field
                                                          as={Input}
                                                          name={`contact.${index}.number`}
                                                          placeholder="Contact"
                                                          size="md"
                                                          type="text"
                                                          onChange={
                                                            handleChange
                                                          }
                                                        />
                                                      </Flex>

                                                      <Flex width="25%">
                                                        <FormControl
                                                          isInvalid={Boolean(
                                                            !!(
                                                              errors.contact as any[]
                                                            )?.[index]
                                                              ?.category &&
                                                            (
                                                              touched.contact as any[]
                                                            )?.[index]
                                                              ?.category
                                                          )}
                                                        >
                                                          <Flex
                                                            justifyContent="center"
                                                            alignItems="center"
                                                          >
                                                            <AtsSelect
                                                              variant="outline"
                                                              menuPosition="fixed"
                                                              defaultValue={
                                                                values.contact[
                                                                  index
                                                                ].category
                                                              }
                                                              onChange={(
                                                                e: any
                                                              ) => {
                                                                setFieldValue(
                                                                  `contact.${index}.category`,
                                                                  e
                                                                );
                                                              }}
                                                              options={[
                                                                {
                                                                  value:
                                                                    'personal',
                                                                  label: (
                                                                    <FAIcon iconName="user" />
                                                                  ),
                                                                },
                                                                {
                                                                  value: 'work',
                                                                  label: (
                                                                    <FAIcon iconName="briefcase" />
                                                                  ),
                                                                },
                                                              ]}
                                                              isSearchable={
                                                                false
                                                              }
                                                              styles={{
                                                                zIndex: '9999',
                                                                indicatorSeparator:
                                                                  () => { },
                                                                dropdownIndicator:
                                                                  (
                                                                    defaultStyles: any
                                                                  ) => ({
                                                                    ...defaultStyles,
                                                                    paddingLeft:
                                                                      '0px',
                                                                    '& svg': {
                                                                      width:
                                                                        '10px',
                                                                      height:
                                                                        '10px',
                                                                    },
                                                                  }),
                                                              }}
                                                            />
                                                          </Flex>
                                                        </FormControl>
                                                      </Flex>

                                                      <Flex
                                                        width="5%"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                      >
                                                        <FormControl>
                                                          <Flex
                                                            justifyContent="center"
                                                            alignItems="center"
                                                          >
                                                            <Radio
                                                              id={`contact.${index}.primary`}
                                                              name={`contactPrimary`} // Set the same name for all radio buttons
                                                              value={index} // Use the index as the value
                                                              isChecked={
                                                                cont.primary
                                                              }
                                                              onChange={(e) => {
                                                                // Handle radio button change to update the selected row
                                                                const updatedContact =
                                                                  values.contact.map(
                                                                    (
                                                                      contact: any,
                                                                      key: number
                                                                    ) => ({
                                                                      ...contact,
                                                                      primary:
                                                                        key ===
                                                                        index,
                                                                    })
                                                                  );
                                                                setFieldValue(
                                                                  'contact',
                                                                  updatedContact
                                                                );
                                                              }}
                                                            ></Radio>
                                                          </Flex>
                                                        </FormControl>
                                                      </Flex>
                                                      <Flex
                                                        width="5%"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                      >
                                                        {values.contact.length >
                                                          1 && (
                                                            <Flex
                                                              onClick={() => {
                                                                remove(index);
                                                                if (
                                                                  values.contact
                                                                    .length === 2
                                                                ) {
                                                                  // console.log(
                                                                  //   'went here to make last one email primary to true'
                                                                  // );
                                                                  const updatedContact =
                                                                    values.contact
                                                                      .map(
                                                                        (
                                                                          contact: any,
                                                                          key: number
                                                                        ) => ({
                                                                          ...contact,
                                                                          primary:
                                                                            true,
                                                                        })
                                                                      )
                                                                      .filter(
                                                                        (
                                                                          contact: any,
                                                                          key: number
                                                                        ) =>
                                                                          index !==
                                                                          key
                                                                      );
                                                                  setFieldValue(
                                                                    'contact',
                                                                    updatedContact
                                                                  );
                                                                }
                                                              }}
                                                              justifyContent="center"
                                                              alignItems="center"
                                                              color="#D5605D"
                                                              cursor="pointer"
                                                            >
                                                              <FAIcon iconName="trash" />
                                                            </Flex>
                                                          )}
                                                      </Flex>
                                                    </Flex>
                                                    <FormErrorMessage mb="12px">
                                                      {String(
                                                        (
                                                          errors.contact as any[]
                                                        )?.[index]?.number
                                                      )}
                                                    </FormErrorMessage>
                                                  </FormControl>
                                                  {index + 1 ===
                                                    values.contact.length &&
                                                    typeof errors.contact !==
                                                    'object' && (
                                                      <FormErrorMessage mb="12px">
                                                        {String(errors.contact)}
                                                      </FormErrorMessage>
                                                    )}
                                                </FormControl>
                                              )
                                            )}

                                            <Button
                                              leftIcon="plus"
                                              onClick={() => {
                                                push({
                                                  number: '',
                                                  type: 'phone',
                                                  primary: false,
                                                  category: {
                                                    value: 'personal',
                                                    label: (
                                                      <FAIcon iconName="user" />
                                                    ),
                                                  },
                                                });
                                              }}
                                            >
                                              Add Contact
                                            </Button>
                                          </div>
                                        )}
                                      </FieldArray>
                                    </Box>
                                    <Box
                                      overflowY="auto"
                                      maxHeight="450px"
                                      width="100%"
                                    >
                                      <FieldArray name="emails">
                                        {({ remove, push }) => (
                                          <div>
                                            <FormLabel
                                              fontSize="14px"
                                              lineHeight="18px"
                                              color="default.secondarytext"
                                            >
                                              Email{' '}
                                              <Box
                                                as="span"
                                                color="caution.800"
                                              >
                                                *
                                              </Box>
                                            </FormLabel>

                                            {values.emails?.map(
                                              (email: any, index: any) => (
                                                <FormControl
                                                  isInvalid={Boolean(
                                                    !!errors.emails &&
                                                    touched.emails
                                                  )}
                                                >
                                                  <FormControl
                                                    isInvalid={Boolean(
                                                      !!(
                                                        errors.emails as any[]
                                                      )?.[index]?.email &&
                                                      (
                                                        touched.emails as any[]
                                                      )?.[index]?.email
                                                    )}
                                                  >
                                                    <Flex
                                                      width="100%"
                                                      direction="row"
                                                      key={index}
                                                      mb="12px"
                                                      alignItems="center"
                                                      gap="12px"
                                                    >
                                                      <Flex width="70%">
                                                        <Field
                                                          as={Input}
                                                          name={`emails.${index}.email`}
                                                          placeholder="Email Address"
                                                          size="md"
                                                          type="text"
                                                          onChange={
                                                            handleChange
                                                          }
                                                        />
                                                      </Flex>

                                                      <Flex width="25%">
                                                        <FormControl
                                                          isInvalid={Boolean(
                                                            !!(
                                                              errors.emails as any[]
                                                            )?.[index]
                                                              ?.category &&
                                                            (
                                                              touched.emails as any[]
                                                            )?.[index]
                                                              ?.category
                                                          )}
                                                        >
                                                          <Flex
                                                            justifyContent="center"
                                                            alignItems="center"
                                                          >
                                                            <AtsSelect
                                                              variant="outline"
                                                              menuPosition="fixed"
                                                              defaultValue={
                                                                values.emails[
                                                                  index
                                                                ].category
                                                              }
                                                              onChange={(
                                                                e: any
                                                              ) => {
                                                                setFieldValue(
                                                                  `emails.${index}.category`,
                                                                  e
                                                                );
                                                              }}
                                                              options={[
                                                                {
                                                                  value:
                                                                    'personal',
                                                                  label: (
                                                                    <FAIcon iconName="user" />
                                                                  ),
                                                                },
                                                                {
                                                                  value: 'work',
                                                                  label: (
                                                                    <FAIcon iconName="briefcase" />
                                                                  ),
                                                                },
                                                              ]}
                                                              isSearchable={
                                                                false
                                                              }
                                                              styles={{
                                                                zIndex: '9999',
                                                                indicatorSeparator:
                                                                  () => { },
                                                                dropdownIndicator:
                                                                  (
                                                                    defaultStyles: any
                                                                  ) => ({
                                                                    ...defaultStyles,
                                                                    paddingLeft:
                                                                      '0px',
                                                                    '& svg': {
                                                                      width:
                                                                        '10px',
                                                                      height:
                                                                        '10px',
                                                                    },
                                                                  }),
                                                              }}
                                                            />
                                                          </Flex>
                                                        </FormControl>
                                                      </Flex>

                                                      <Flex
                                                        width="5%"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                      >
                                                        <Flex
                                                          justifyContent="center"
                                                          alignItems="center"
                                                        >
                                                          <Radio
                                                            id={`emails.${index}.primary`}
                                                            name={`emailsPrimary`} // Set the same name for all radio buttons
                                                            value={index} // Use the index as the value
                                                            isChecked={
                                                              email.primary
                                                            }
                                                            onChange={(e) => {
                                                              // Handle radio button change to update the selected row
                                                              const updatedEmails =
                                                                values.emails.map(
                                                                  (
                                                                    email: any,
                                                                    key: number
                                                                  ) => ({
                                                                    ...email,
                                                                    primary:
                                                                      key ===
                                                                      index,
                                                                  })
                                                                );
                                                              setFieldValue(
                                                                'emails',
                                                                updatedEmails
                                                              );
                                                            }}
                                                          ></Radio>
                                                        </Flex>
                                                      </Flex>

                                                      <Flex
                                                        width="10%"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                      >
                                                        {values.emails.length >
                                                          1 && (
                                                            <Flex
                                                              onClick={() => {
                                                                remove(index);
                                                                if (
                                                                  values.emails
                                                                    .length === 2
                                                                ) {
                                                                  // console.log(
                                                                  //   'went here to make last one email primary to true'
                                                                  // );
                                                                  const updatedEmails =
                                                                    values.emails
                                                                      .map(
                                                                        (
                                                                          email: any,
                                                                          key: number
                                                                        ) => ({
                                                                          ...email,
                                                                          primary:
                                                                            true,
                                                                        })
                                                                      )
                                                                      .filter(
                                                                        (
                                                                          email: any,
                                                                          key: number
                                                                        ) =>
                                                                          index !==
                                                                          key
                                                                      );
                                                                  setFieldValue(
                                                                    'emails',
                                                                    updatedEmails
                                                                  );
                                                                }
                                                              }}
                                                              justifyContent="center"
                                                              alignItems="center"
                                                              color="#D5605D"
                                                              cursor="pointer"
                                                            >
                                                              <FAIcon iconName="trash" />
                                                            </Flex>
                                                          )}
                                                      </Flex>
                                                    </Flex>
                                                    <FormErrorMessage mb="12px">
                                                      {String(
                                                        (
                                                          errors.emails as any[]
                                                        )?.[index]?.email
                                                      )}
                                                    </FormErrorMessage>
                                                  </FormControl>
                                                  {index + 1 ===
                                                    values.emails.length &&
                                                    typeof errors.emails !==
                                                    'object' && (
                                                      <FormErrorMessage mb="12px">
                                                        {String(errors.emails)}
                                                      </FormErrorMessage>
                                                    )}
                                                </FormControl>

                                                // </div>
                                              )
                                            )}

                                            <Button
                                              leftIcon="plus"
                                              onClick={() => {
                                                push({
                                                  email: '',
                                                  primary: false,
                                                  category: {
                                                    value: 'personal',
                                                    label: (
                                                      <FAIcon iconName="user" />
                                                    ),
                                                  },
                                                });
                                              }}
                                            >
                                              Add Email
                                            </Button>
                                          </div>
                                        )}
                                      </FieldArray>
                                    </Box>
                                  </Flex>

                                  <Flex gap="32px" mb="34px">
                                    <FormControl
                                      isInvalid={Boolean(
                                        !!errors.linkedin_url &&
                                        touched.linkedin_url
                                      )}
                                    >
                                      <FormLabel
                                        fontSize="14px"
                                        lineHeight="18px"
                                        color="default.secondarytext"
                                      >
                                        LinkedIn Url
                                      </FormLabel>
                                      <Input
                                        id="linkedin_url"
                                        name="linkedin_url"
                                        type="text"
                                        placeholder="LinkedIn Url"
                                        variant="outline"
                                        value={values.linkedin_url}
                                        onChange={handleChange}
                                      />
                                    </FormControl>
                                    <FormControl
                                      isInvalid={Boolean(
                                        !!errors.work_status &&
                                        touched.work_status
                                      )}
                                    >
                                      <FormLabel
                                        fontSize="14px"
                                        lineHeight="18px"
                                        color="default.secondarytext"
                                      >
                                        Work Status
                                      </FormLabel>

                                      <AtsSelect
                                        id="work_status"
                                        name="work_status"
                                        placeholder="Select"
                                        variant="outline"
                                        onChange={(e: any) => {
                                          setFieldValue(
                                            'work_status',
                                            String(e.value)
                                          );
                                        }}
                                        defaultValue={
                                          (candidate_work_status?.length > 0 &&
                                            candidate_work_status
                                              .map((item: any) => ({
                                                label: item.status,
                                                value: item.id,
                                              }))
                                              .filter(
                                                (val: any) =>
                                                  val.value ===
                                                  Number(values.work_status)
                                              )[0]) ||
                                          values.work_status
                                        }
                                        options={
                                          candidate_work_status?.length > 0 &&
                                          candidate_work_status.map(
                                            (item: any) => ({
                                              label: item.status,
                                              value: item.id,
                                            })
                                          )
                                        }
                                      />
                                      <FormErrorMessage>
                                        {String(errors.work_status)}
                                      </FormErrorMessage>
                                    </FormControl>
                                  </Flex>
                                  <Flex gap="32px" mb="34px">
                                    <FormControl
                                      isInvalid={Boolean(
                                        !!errors.source_type &&
                                        touched.source_type
                                      )}
                                    >
                                      <FormLabel
                                        fontSize="14px"
                                        lineHeight="18px"
                                        color="default.secondarytext"
                                      >
                                        Candidate Source{' '}
                                        <Box as="span" color="caution.800">
                                          *
                                        </Box>
                                      </FormLabel>
                                      <AtsSelect
                                        options={candSource}
                                        // isLoading={resCanSource.isLoading}
                                        defaultValue={values.source_type}
                                        onChange={(e: any) => {
                                          // && (values.work_status)

                                          if (
                                            e.value === 3 ||
                                            e.value === 5 ||
                                            e.value === 6
                                          ) {
                                            const data = {
                                              value: authSt?.id,
                                              label: [
                                                authSt?.first_name,
                                                authSt?.last_name,
                                              ].join(' '),
                                            };
                                            setFieldValue(
                                              'sub_source_type',
                                              data
                                            );

                                            setCandSubSource(userSource);
                                            if (e.value === 3) {
                                              setCandSourceVisible(false);
                                            } else {
                                              setCandSourceVisible(true);
                                            }
                                          } else {
                                            subSource(e?.sub);
                                            setCandSourceVisible(true);
                                            const data = e?.sub?.[0]
                                              ? {
                                                label: e.sub[0].sub_source,
                                                value: e.sub[0].id,
                                              }
                                              : null;
                                            setFieldValue(
                                              'sub_source_type',
                                              data
                                            );
                                            if (e.sub.length === 0) {
                                              setFieldValue(
                                                'sub_source_type',
                                                null
                                              );
                                              setCandSourceVisible(false);
                                            }
                                          }
                                          setFieldValue('source_type', e);
                                        }}
                                      />
                                      <FormErrorMessage>
                                        {errors.source_type &&
                                          touched.source_type &&
                                          String(
                                            (errors.source_type as any).label
                                          )}
                                      </FormErrorMessage>
                                    </FormControl>
                                    {candSourceVisible ? (
                                      <FormControl
                                        isInvalid={Boolean(
                                          !!errors.sub_source_type &&
                                          touched.sub_source_type &&
                                          !candSourceVisible
                                        )}
                                      >
                                        <FormLabel
                                          fontSize="14px"
                                          lineHeight="18px"
                                          color="default.secondarytext"
                                        >
                                          Sub Source
                                        </FormLabel>
                                        <AtsSelect3
                                          options={candSubSource}
                                          defaultValue={values.sub_source_type}
                                          value={values.sub_source_type}
                                          onChange={(e: any) => {
                                            setFieldValue('sub_source_type', e);
                                          }}
                                        />
                                        <FormErrorMessage>
                                          {errors.sub_source_type &&
                                            touched.sub_source_type &&
                                            String(
                                              (errors.sub_source_type as any)
                                                .label
                                            )}
                                        </FormErrorMessage>
                                      </FormControl>
                                    ) : (
                                      <FormControl></FormControl>
                                    )}
                                  </Flex>

                                  <Flex gap="32px" mb="34px">
                                    <FormControl>
                                      <FieldArray name="skills">
                                        {({ push }) => {
                                          const handleKeyDown = (e: any) => {
                                            if (e.key === 'Enter') {
                                              push(hasSkills);
                                              setHasSkills('');
                                            }
                                          };
                                          return (
                                            <FormControl mb="16px">
                                              <Flex
                                                justifyContent="space-between"
                                                alignItems="flex-end"
                                              >
                                                <FormLabel
                                                  fontSize="14px"
                                                  lineHeight="18px"
                                                  color="default.secondarytext"
                                                >
                                                  Skills
                                                </FormLabel>
                                              </Flex>
                                              <InputGroup>
                                                <Input
                                                  name="skills"
                                                  id="kills"
                                                  placeholder="Type Skills Name"
                                                  type="text"
                                                  variant="outline"
                                                  value={hasSkills}
                                                  onChange={(e) =>
                                                    setHasSkills(e.target.value)
                                                  }
                                                  onKeyDown={handleKeyDown}
                                                />
                                                <InputRightElement
                                                  background="#F3EEFB"
                                                  borderRadius="0 4px 4px 0"
                                                  zIndex={0}
                                                  children={
                                                    <CheckIcon color="priamry.800" />
                                                  }
                                                />
                                              </InputGroup>
                                              <Input display="none" />
                                            </FormControl>
                                          );
                                        }}
                                      </FieldArray>
                                      <FieldArray name="skills">
                                        {({ remove }) => (
                                          <Flex
                                            gap="10px"
                                            display="inline-block"
                                          >
                                            {values.skills?.length > 0 &&
                                              values.skills?.map(
                                                (skill: any, index: any) => (
                                                  <Tag
                                                    style={{
                                                      display: 'inline-block',
                                                    }}
                                                    key={index}
                                                    mr="10px"
                                                    mb="10px"
                                                  >
                                                    <Stack spacing={1}>
                                                      <HStack>
                                                        <TagLabel maxW="100px">
                                                          {skill as String}
                                                        </TagLabel>
                                                        <TagCloseButton
                                                          onClick={() => {
                                                            remove(index);
                                                          }}
                                                        />
                                                      </HStack>
                                                    </Stack>
                                                  </Tag>
                                                )
                                              )}
                                          </Flex>
                                        )}
                                      </FieldArray>
                                    </FormControl>
                                  </Flex>

                                  <Box>
                                    <Text mb="34px">Address Information</Text>
                                    <AddressComponent
                                      setFieldValue={setFieldValue}
                                      handleChange={handleChange}
                                      countryOption={countryOption}
                                      countryField={{
                                        name: 'country',
                                        value: values.country,
                                        errors: errors.country,
                                        touched: touched.country,
                                      }}
                                      cityField={{
                                        name: 'city',
                                        value: values.city,
                                        errors: errors.city,
                                        touched: touched.city,
                                      }}
                                      stateField={{
                                        name: 'state_province',
                                        value: values.state_province,
                                        errors: errors.state_province,
                                        touched: touched.state_province,
                                      }}
                                      zipField={{
                                        name: 'zip_code',
                                        value: values.zip_code,
                                        errors: errors.zip_code,
                                        touched: touched.zip_code,
                                      }}
                                      edit={edit}
                                    />
                                  </Box>
                                </Box>
                              )}
                            </TabPanels>

                            <ModalFooter
                              position="sticky"
                              bottom="0"
                              background="#F9F9FB"
                              mb="20px"
                              mr="20px"
                              gap="16px"
                            >
                              <Button
                                variant="solid"
                                disabled={isOpenAlert}
                                onClick={handleSubmit}
                              >
                                Next
                              </Button>
                            </ModalFooter>
                          </form>
                        )}
                      </Formik>
                    )}
                    {tabIndex.id === 1 && (
                      <Formik
                        initialValues={initialProfessionalValues}
                        validationSchema={validationProfessionalSchema}
                        onSubmit={(data, { setSubmitting, validateForm }) => {
                          setCandidateFormData((prev: any) => ({
                            ...prev,
                            ...data,
                          }));
                          setSubmitting(false);
                          validateForm().then((errors) => {
                            if (
                              Object.keys(errors).length === 0 &&
                              tabListIndex?.length !== tabIndex.id + 1
                            ) {
                              setTabIndex(tabListIndex[tabIndex.id + 1]);
                            }
                          });
                        }}
                        enableReinitialize={true}
                      >
                        {({
                          values,
                          handleChange,
                          handleSubmit,
                          errors,
                          touched,
                          setFieldValue,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <ScrollToFieldError />
                            {/* Professional Details */}
                            <Box
                              overflowY="scroll"
                              maxHeight="450px"
                              p="30px"
                              background="#fff"
                              borderRadius="8px"
                            >
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                Total Experience
                              </FormLabel>
                              <Flex gap="32px" mb="34px">
                                <FormControl
                                  isInvalid={Boolean(
                                    !!errors.total_year_of_experience &&
                                    touched.total_year_of_experience
                                  )}
                                >
                                  <InputGroup>
                                    <InputLeftAddon children="Years" />
                                    <Input
                                      id="total_year_of_experience"
                                      name="total_year_of_experience"
                                      type="text"
                                      min={0}
                                      max={100}
                                      value={values.total_year_of_experience}
                                      onChange={(e: any) => {
                                        const inputValue = e.target.value;
                                        const sanitizedValue =
                                          inputValue.replace(/[^\d]/g, ''); // Remove non-digit characters
                                        const clampedValue = Math.min(
                                          100,
                                          Math.max(null, sanitizedValue)
                                        ); // Clamp value between 0 and 11

                                        handleChange({
                                          target: {
                                            name: 'total_year_of_experience',
                                            value: clampedValue,
                                          },
                                        });
                                      }}
                                    />
                                  </InputGroup>

                                  <FormErrorMessage>
                                    {String(errors.total_year_of_experience)}
                                  </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                  isInvalid={Boolean(
                                    !!errors.total_months_of_experience &&
                                    touched.total_months_of_experience
                                  )}
                                >
                                  <InputGroup>
                                    <InputLeftAddon children="Months" />
                                    <Input
                                      id="total_months_of_experience"
                                      name="total_months_of_experience"
                                      type="text"
                                      min={0}
                                      max={11}
                                      value={values.total_months_of_experience}
                                      onChange={(e: any) => {
                                        const inputValue = e.target.value;
                                        const sanitizedValue =
                                          inputValue.replace(/[^\d]/g, ''); // Remove non-digit characters
                                        const clampedValue = Math.min(
                                          11,
                                          Math.max(null, sanitizedValue)
                                        ); // Clamp value between 0 and 11

                                        handleChange({
                                          target: {
                                            name: 'total_months_of_experience',
                                            value: clampedValue,
                                          },
                                        });
                                      }}
                                    />
                                  </InputGroup>

                                  <FormErrorMessage>
                                    {String(errors.total_months_of_experience)}
                                  </FormErrorMessage>
                                </FormControl>
                              </Flex>
                              <Flex gap="32px" mb="34px">
                                <FormControl
                                  isInvalid={Boolean(
                                    !!errors.latest_job_title &&
                                    touched.latest_job_title
                                  )}
                                >
                                  <FormLabel
                                    fontSize="14px"
                                    lineHeight="18px"
                                    color="default.secondarytext"
                                  >
                                    Current / Latest Job Title
                                  </FormLabel>
                                  <Input
                                    id="latest_job_title"
                                    name="latest_job_title"
                                    type="text"
                                    placeholder="Job Title"
                                    variant="outline"
                                    value={values.latest_job_title}
                                    onChange={handleChange}
                                  />
                                  <FormErrorMessage>
                                    {String(errors.latest_job_title)}
                                  </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                  isInvalid={Boolean(
                                    !!errors.latest_employer &&
                                    touched.latest_employer
                                  )}
                                >
                                  <FormLabel
                                    fontSize="14px"
                                    lineHeight="18px"
                                    color="default.secondarytext"
                                  >
                                    Curent / Latest Employer
                                  </FormLabel>
                                  <Input
                                    id="latest_employer"
                                    name="latest_employer"
                                    type="text"
                                    placeholder="Employer"
                                    variant="outline"
                                    value={values.latest_employer}
                                    onChange={handleChange}
                                  />
                                  <FormErrorMessage>
                                    {String(errors.latest_employer)}
                                  </FormErrorMessage>
                                </FormControl>
                              </Flex>
                              <Flex gap="32px" mb="34px">
                                <FormControl
                                  isInvalid={Boolean(
                                    !!errors.latest_salary &&
                                    touched.latest_salary
                                  )}
                                >
                                  <FormLabel
                                    fontSize="14px"
                                    lineHeight="18px"
                                    color="default.secondarytext"
                                  >
                                    Current / Latest Salary
                                  </FormLabel>
                                  <InputGroup>
                                    <InputLeftElement
                                      pointerEvents="none"
                                      children={
                                        <Icon
                                          as={BsCurrencyDollar}
                                          color="gray.500"
                                        />
                                      }
                                    />
                                    <Input
                                      id="latest_salary"
                                      name="latest_salary"
                                      type="text"
                                      placeholder="0.00"
                                      variant="outline"
                                      textAlign="right"
                                      value={values.latest_salary}
                                      onChange={(event: any) => {
                                        const { value } = event.target;

                                        // Remove any existing commas from the input
                                        const sanitizedValue = value.replace(
                                          /,/g,
                                          ''
                                        );

                                        // Format the number with commas
                                        const formattedValue =
                                          Number(
                                            sanitizedValue
                                          )?.toLocaleString();

                                        setFieldValue(
                                          'latest_salary',
                                          formattedValue
                                        );
                                      }}
                                    />
                                  </InputGroup>
                                  <FormErrorMessage>
                                    {String(errors.latest_salary)}
                                  </FormErrorMessage>
                                </FormControl>
                                <Flex gap="32px" mb="34px"></Flex>
                                <FormControl
                                  isInvalid={Boolean(
                                    !!errors.expected_salary_start &&
                                    touched.expected_salary_start
                                  )}
                                >
                                  <FormLabel
                                    fontSize="14px"
                                    lineHeight="18px"
                                    color="default.secondarytext"
                                  >
                                    Expected Salary (Start)
                                  </FormLabel>
                                  <InputGroup>
                                    <InputLeftElement
                                      pointerEvents="none"
                                      children={
                                        <Icon
                                          as={BsCurrencyDollar}
                                          color="gray.500"
                                        />
                                      }
                                    />
                                    <Input
                                      id="expected_salary_start"
                                      name="expected_salary_start"
                                      type="text"
                                      placeholder="0.00"
                                      variant="outline"
                                      textAlign="right"
                                      value={values.expected_salary_start}
                                      onChange={(event: any) => {
                                        const { value } = event.target;

                                        // Remove any existing commas from the input
                                        const sanitizedValue = value.replace(
                                          /,/g,
                                          ''
                                        );

                                        // Format the number with commas
                                        const formattedValue =
                                          Number(
                                            sanitizedValue
                                          )?.toLocaleString();

                                        setFieldValue(
                                          'expected_salary_start',
                                          formattedValue
                                        );
                                      }}
                                    />
                                  </InputGroup>
                                  <FormErrorMessage>
                                    {String(errors.expected_salary_start)}
                                  </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                  isInvalid={Boolean(
                                    !!errors.expected_salary_end &&
                                    touched.expected_salary_end
                                  )}
                                >
                                  <FormLabel
                                    fontSize="14px"
                                    lineHeight="18px"
                                    color="default.secondarytext"
                                  >
                                    Expected Salary (End)
                                  </FormLabel>
                                  <InputGroup>
                                    <InputLeftElement
                                      pointerEvents="none"
                                      children={
                                        <Icon
                                          as={BsCurrencyDollar}
                                          color="gray.500"
                                        />
                                      }
                                    />
                                    <Input
                                      id="expected_salary_end"
                                      name="expected_salary_end"
                                      type="text"
                                      placeholder="0.00"
                                      variant="outline"
                                      textAlign="right"
                                      value={values.expected_salary_end}
                                      onChange={(event: any) => {
                                        const { value } = event.target;

                                        // Remove any existing commas from the input
                                        const sanitizedValue = value.replace(
                                          /,/g,
                                          ''
                                        );

                                        // Format the number with commas
                                        const formattedValue =
                                          Number(
                                            sanitizedValue
                                          )?.toLocaleString();

                                        setFieldValue(
                                          'expected_salary_end',
                                          formattedValue
                                        );
                                      }}
                                    />
                                  </InputGroup>
                                  <FormErrorMessage>
                                    {String(errors.expected_salary_end)}
                                  </FormErrorMessage>
                                </FormControl>
                              </Flex>

                              <Box mb="34px">
                                <FormControl mb="3">
                                  <FormLabel
                                    fontSize="14px"
                                    lineHeight="18px"
                                    color="default.secondarytext"
                                  >
                                    Resume
                                  </FormLabel>
                                </FormControl>
                                <AtsDropZone
                                  multipleFile={true}
                                  isResume
                                  uploadedEnd={(e: any) => { }}
                                  deletedFile={(key: string) => { }}
                                />
                              </Box>
                            </Box>

                            <ModalFooter
                              position="sticky"
                              bottom="0"
                              background="#F9F9FB"
                              mb="20px"
                              mr="20px"
                              gap="16px"
                            >
                              {tabIndex.id > 0 && (
                                <Button
                                  disabled={isOpenAlert}
                                  mr={3}
                                  onClick={() => {
                                    setTabIndex(tabListIndex[tabIndex.id - 1]);
                                    setCandidateFormData((prev: any) => ({
                                      ...prev,
                                      ...values,
                                    }));
                                  }}
                                >
                                  Previous
                                </Button>
                              )}
                              {tabIndex.id < 4 && (
                                <Button
                                  variant="solid"
                                  disabled={isOpenAlert}
                                  onClick={handleSubmit}
                                >
                                  Next
                                </Button>
                              )}
                            </ModalFooter>
                          </form>
                        )}
                      </Formik>
                    )}
                    {tabIndex.id === 2 && (
                      <Formik
                        initialValues={initialExpValues}
                        validationSchema={validationExpSchema}
                        onSubmit={(data, { setSubmitting, validateForm }) => {
                          setCandidateFormData((prev: any) => ({
                            ...prev,
                            ...data,
                          }));
                          setSubmitting(false);
                          validateForm().then((errors) => {
                            if (
                              Object.keys(errors).length === 0 &&
                              tabListIndex?.length !== tabIndex.id + 1
                            ) {
                              setTabIndex(tabListIndex[tabIndex.id + 1]);
                            }
                          });
                        }}
                        enableReinitialize={true}
                      >
                        {({ values, handleChange, handleSubmit }) => (
                          <form onSubmit={handleSubmit}>
                            <ScrollToFieldError />
                            {/* Experience Details */}
                            <Box
                              overflowY="scroll"
                              maxHeight="450px"
                              p="30px"
                              background="#fff"
                              borderRadius="8px"
                              minHeight="450px"
                            >
                              <FieldArray name="experiences">
                                {({ remove, push }) => (
                                  <div>
                                    {values.experiences?.length > 0 &&
                                      values.experiences?.map(
                                        (experience: any, index: any) => {
                                          experience.start_date = moment(
                                            experience.start_date
                                          ).format('YYYY-MM-DD');
                                          experience.end_date = moment(
                                            experience.end_date
                                          ).format('YYYY-MM-DD');
                                          return (
                                            <div className="row" key={index}>
                                              <FormControl mb="34px">
                                                <Flex
                                                  justifyContent="space-between"
                                                  alignItems="flex-end"
                                                >
                                                  <FormLabel
                                                    fontSize="14px"
                                                    lineHeight="18px"
                                                    color="default.secondarytext"
                                                  >
                                                    Company
                                                  </FormLabel>
                                                  {values.experiences?.length >
                                                    0 && (
                                                      <Box mb="10px">
                                                        <Button
                                                          leftIcon="close"
                                                          onClick={() =>
                                                            remove(index)
                                                          }
                                                          size="sm"
                                                        >
                                                          Remove
                                                        </Button>
                                                      </Box>
                                                    )}
                                                </Flex>
                                                <Field
                                                  as={Input}
                                                  name={`experiences.${index}.company`}
                                                  placeholder="Company"
                                                  type="text"
                                                  variant="outline"
                                                  value={experience.company}
                                                  onChange={handleChange}
                                                />
                                                <FormErrorMessage>
                                                  {String(
                                                    `experiences${index}.company`
                                                  )}
                                                </FormErrorMessage>
                                              </FormControl>
                                              <FormControl mb="34px">
                                                <FormLabel
                                                  fontSize="14px"
                                                  lineHeight="18px"
                                                  color="default.secondarytext"
                                                >
                                                  Job Title
                                                </FormLabel>
                                                <Field
                                                  as={Input}
                                                  name={`experiences.${index}.job_title`}
                                                  placeholder="Job Title"
                                                  type="text"
                                                  variant="outline"
                                                  value={experience.job_title}
                                                  onChange={handleChange}
                                                />
                                                <FormErrorMessage>
                                                  {String(
                                                    `experiences${index}.job_title`
                                                  )}
                                                </FormErrorMessage>
                                              </FormControl>
                                              <Flex gap="32px" mb="16px">
                                                <FormControl>
                                                  <FormLabel
                                                    fontSize="14px"
                                                    lineHeight="18px"
                                                    color="default.secondarytext"
                                                  >
                                                    Start Date
                                                  </FormLabel>
                                                  <Field
                                                    as={Input}
                                                    name={`experiences.${index}.start_date`}
                                                    placeholder="Select Date"
                                                    size="md"
                                                    type="date"
                                                    value={
                                                      experience.start_date
                                                    }
                                                    onChange={handleChange}
                                                  />
                                                </FormControl>
                                                <FormControl>
                                                  <FormLabel
                                                    fontSize="14px"
                                                    lineHeight="18px"
                                                    color="default.secondarytext"
                                                  >
                                                    End Date
                                                  </FormLabel>
                                                  <Field
                                                    as={Input}
                                                    name={`experiences.${index}.end_date`}
                                                    placeholder="Select Date"
                                                    size="md"
                                                    type="date"
                                                    value={experience.end_date}
                                                    onChange={handleChange}
                                                  />
                                                </FormControl>
                                              </Flex>

                                              <Box mb="32px">
                                                <Checkbox
                                                  id={`experiences.${index}.currently_pursuing`}
                                                  name={`experiences.${index}.currently_pursuing`}
                                                  isChecked={
                                                    experience.currently_pursuing
                                                  }
                                                  onChange={handleChange}
                                                >
                                                  Currently Pursuing
                                                </Checkbox>
                                              </Box>
                                            </div>
                                          );
                                        }
                                      )}
                                    <Button
                                      leftIcon="plus"
                                      onClick={() =>
                                        push({
                                          company: '',
                                          job_title: '',
                                          start_date: '',
                                          end_date: '',
                                          currently_pursuing: false,
                                        })
                                      }
                                    >
                                      Add Another
                                    </Button>
                                  </div>
                                )}
                              </FieldArray>
                            </Box>

                            <ModalFooter
                              position="sticky"
                              bottom="0"
                              background="#F9F9FB"
                              mb="20px"
                              mr="20px"
                              gap="16px"
                            >
                              {tabIndex.id > 0 && (
                                <Button
                                  disabled={isOpenAlert}
                                  mr={3}
                                  onClick={() => {
                                    setTabIndex(tabListIndex[tabIndex.id - 1]);
                                    setCandidateFormData((prev: any) => ({
                                      ...prev,
                                      ...values,
                                    }));
                                  }}
                                >
                                  Previous
                                </Button>
                              )}
                              {tabIndex.id < 4 && (
                                <Button
                                  variant="solid"
                                  disabled={isOpenAlert}
                                  onClick={handleSubmit}
                                >
                                  Next
                                </Button>
                              )}
                            </ModalFooter>
                          </form>
                        )}
                      </Formik>
                    )}
                    {tabIndex.id === 3 && (
                      <Formik
                        initialValues={initialEducValues}
                        validationSchema={validationEducSchema}
                        onSubmit={(data, { setSubmitting, validateForm }) => {
                          setCandidateFormData((prev: any) => ({
                            ...prev,
                            ...data,
                          }));
                          setSubmitting(false);
                          validateForm().then((errors) => {
                            if (
                              Object.keys(errors).length === 0 &&
                              tabListIndex?.length !== tabIndex.id + 1
                            ) {
                              setTabIndex(tabListIndex[tabIndex.id + 1]);
                            }
                          });
                        }}
                        enableReinitialize={true}
                      >
                        {({
                          values,
                          handleChange,
                          handleSubmit,
                          setFieldValue,
                          errors,
                          touched,
                          isValid,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <ScrollToFieldError />
                            {/* Education Details */}
                            <Box
                              overflowY="scroll"
                              maxHeight="450px"
                              p="30px"
                              background="#fff"
                              borderRadius="8px"
                              minHeight="450px"
                            >
                              <FieldArray name="educations">
                                {({ remove, push }) => (
                                  <div>
                                    {values.educations?.length > 0 &&
                                      values.educations?.map(
                                        (education: any, index: any) => {
                                          education.start_date = moment(
                                            education.start_date
                                          ).format('YYYY-MM-DD');
                                          education.end_date = moment(
                                            education.end_date
                                          ).format('YYYY-MM-DD');
                                          return (
                                            <div className="row" key={index}>
                                              <FormControl mb="34px">
                                                <Flex
                                                  justifyContent="space-between"
                                                  alignItems="flex-end"
                                                >
                                                  <FormLabel
                                                    fontSize="14px"
                                                    lineHeight="18px"
                                                    color="default.secondarytext"
                                                  >
                                                    Institute/School
                                                  </FormLabel>
                                                  {values.educations?.length >
                                                    0 && (
                                                      <Box mb="10px">
                                                        <Button
                                                          leftIcon="close"
                                                          onClick={() =>
                                                            remove(index)
                                                          }
                                                          size="sm"
                                                        >
                                                          Remove
                                                        </Button>
                                                      </Box>
                                                    )}
                                                </Flex>
                                                <Field
                                                  as={Input}
                                                  name={`educations.${index}.school`}
                                                  placeholder="Institute/School"
                                                  type="text"
                                                  variant="outline"
                                                  value={education.school}
                                                  onChange={handleChange}
                                                />
                                                <FormErrorMessage>
                                                  {String(
                                                    `educations${index}.school`
                                                  )}
                                                </FormErrorMessage>
                                              </FormControl>
                                              <Flex gap="34px" mb="16px">
                                                <FormControl>
                                                  <FormLabel
                                                    fontSize="14px"
                                                    lineHeight="18px"
                                                    color="default.secondarytext"
                                                  >
                                                    Major/Department
                                                  </FormLabel>
                                                  <Field
                                                    as={Input}
                                                    name={`educations.${index}.major_department`}
                                                    placeholder="Major/Department"
                                                    type="text"
                                                    variant="outline"
                                                    value={
                                                      education.major_department
                                                    }
                                                    onChange={handleChange}
                                                  />
                                                  <FormErrorMessage>
                                                    {String(
                                                      `educations${index}.major_department`
                                                    )}
                                                  </FormErrorMessage>
                                                </FormControl>
                                                <FormControl
                                                  isInvalid={Boolean(
                                                    !!errors.degree_level_id &&
                                                    touched.degree_level_id
                                                  )}
                                                >
                                                  <FormLabel
                                                    fontSize="14px"
                                                    lineHeight="18px"
                                                    color="default.secondarytext"
                                                  >
                                                    Degree
                                                  </FormLabel>

                                                  <AtsSelect
                                                    id="degree_level_id"
                                                    name="degree_level_id"
                                                    placeholder="Select"
                                                    variant="outline"
                                                    defaultValue={degreeDefault(
                                                      education.degree_level_id
                                                    )}
                                                    onChange={(e: any) => {
                                                      setFieldValue(
                                                        `educations.${index}.degree_level_id`,
                                                        Number(e.value)
                                                      );
                                                    }}
                                                    options={degree}
                                                  />
                                                  <FormErrorMessage>
                                                    {String(
                                                      errors.degree_level_id
                                                    )}
                                                  </FormErrorMessage>
                                                </FormControl>
                                              </Flex>
                                              <Flex gap="34px" mb="16px">
                                                <FormControl>
                                                  <FormLabel
                                                    fontSize="14px"
                                                    lineHeight="18px"
                                                    color="default.secondarytext"
                                                  >
                                                    Start Date
                                                  </FormLabel>
                                                  <Field
                                                    as={Input}
                                                    name={`educations.${index}.start_date`}
                                                    placeholder="Select Date"
                                                    size="md"
                                                    type="date"
                                                    value={education.start_date}
                                                    onChange={handleChange}
                                                  />
                                                </FormControl>
                                                <FormControl>
                                                  <FormLabel
                                                    fontSize="14px"
                                                    lineHeight="18px"
                                                    color="default.secondarytext"
                                                  >
                                                    End Date
                                                  </FormLabel>
                                                  <Field
                                                    as={Input}
                                                    name={`educations.${index}.end_date`}
                                                    placeholder="Select Date"
                                                    size="md"
                                                    type="date"
                                                    value={education.end_date}
                                                    onChange={handleChange}
                                                  />
                                                </FormControl>
                                              </Flex>

                                              <Box mb="32px">
                                                <Checkbox
                                                  id={`educations.${index}.currently_pursuing`}
                                                  name={`educations.${index}.currently_pursuing`}
                                                  isChecked={
                                                    education.currently_pursuing
                                                  }
                                                  onChange={handleChange}
                                                >
                                                  Currently Pursuing
                                                </Checkbox>
                                              </Box>
                                            </div>
                                          );
                                        }
                                      )}
                                    <Button
                                      leftIcon="plus"
                                      onClick={() =>
                                        push({
                                          school: '',
                                          major_department: '',
                                          start_date: '',
                                          end_date: '',
                                          degree_level_id: null,
                                          currently_pursuing: false,
                                        })
                                      }
                                    >
                                      Add Another
                                    </Button>
                                  </div>
                                )}
                              </FieldArray>
                            </Box>

                            <ModalFooter
                              position="sticky"
                              bottom="0"
                              background="#F9F9FB"
                              mb="20px"
                              mr="20px"
                              gap="16px"
                            >
                              {tabIndex.id > 0 && (
                                <Button
                                  disabled={isOpenAlert}
                                  mr={3}
                                  onClick={() => {
                                    setTabIndex(tabListIndex[tabIndex.id - 1]);
                                    setCandidateFormData((prev: any) => ({
                                      ...prev,
                                      ...values,
                                    }));
                                  }}
                                >
                                  Previous
                                </Button>
                              )}
                              {tabIndex.id < 4 && (
                                <Button
                                  variant="solid"
                                  disabled={isOpenAlert}
                                  onClick={handleSubmit}
                                >
                                  Next
                                </Button>
                              )}
                            </ModalFooter>
                          </form>
                        )}
                      </Formik>
                    )}
                    {tabIndex.id === 4 && (
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(data, { setSubmitting, validateForm }) => {
                          if (tabIndex.id === 4) {
                            onSubmit({ ...data, ...candidateFormData });
                          } else {
                            setCandidateFormData((prev: any) => ({
                              ...prev,
                              ...data,
                            }));
                            setSubmitting(false);
                            validateForm().then((errors) => {
                              if (
                                Object.keys(errors).length === 0 &&
                                tabListIndex?.length !== tabIndex.id + 1
                              ) {
                                setTabIndex(tabListIndex[tabIndex.id + 1]);
                              }
                            });
                          }
                        }}
                        enableReinitialize={true}
                      >
                        {({ values, handleSubmit, errors, isValid }) => (
                          <form onSubmit={handleSubmit}>
                            <ScrollToFieldError />
                            {/* Tags */}
                            <Box
                              overflowY="scroll"
                              minHeight="450px"
                              p="30px"
                              background="#fff"
                              borderRadius="8px"
                            >
                              <FormControl>
                                <FieldArray name="tags">
                                  {({ push }) => {
                                    const handleKeyDown = (e: any) => {
                                      if (e.key === 'Enter') {
                                        push(hasTags);
                                        setHasTags('');
                                      }
                                    };
                                    return (
                                      <FormControl mb="16px">
                                        <Flex
                                          justifyContent="space-between"
                                          alignItems="flex-end"
                                        >
                                          <FormLabel
                                            fontSize="14px"
                                            lineHeight="18px"
                                            color="default.secondarytext"
                                          >
                                            Add Tags
                                          </FormLabel>
                                        </Flex>
                                        <InputGroup>
                                          <Input
                                            name="tag"
                                            id="tag"
                                            placeholder="Type Tag Name"
                                            type="text"
                                            variant="outline"
                                            value={hasTags}
                                            onChange={(e) =>
                                              setHasTags(e.target.value)
                                            }
                                            onKeyDown={handleKeyDown}
                                          />
                                          <InputRightElement
                                            background="#F3EEFB"
                                            borderRadius="0 4px 4px 0"
                                            children={
                                              <CheckIcon color="priamry.800" />
                                            }
                                          />
                                        </InputGroup>
                                        <Input display="none" />
                                      </FormControl>
                                    );
                                  }}
                                </FieldArray>
                                <FieldArray name="tags">
                                  {({ remove }) => (
                                    <Flex gap="10px" display="inline-block">
                                      {values.tags?.length > 0 &&
                                        values.tags?.map((tag, index) => (
                                          <Tag
                                            style={{
                                              display: 'inline-block',
                                            }}
                                            key={index}
                                            mr="10px"
                                            mb="10px"
                                          >
                                            <Stack spacing={1}>
                                              <HStack>
                                                <TagLabel maxW="100px">
                                                  {tag as String}
                                                </TagLabel>
                                                <TagCloseButton
                                                  onClick={() => {
                                                    remove(index);
                                                  }}
                                                />
                                              </HStack>
                                            </Stack>
                                          </Tag>
                                        ))}
                                    </Flex>
                                  )}
                                </FieldArray>
                              </FormControl>
                            </Box>

                            <ModalFooter
                              position="sticky"
                              bottom="0"
                              background="#F9F9FB"
                              mb="20px"
                              mr="20px"
                              gap="16px"
                            >
                              {tabIndex.id > 0 && (
                                <Button
                                  disabled={isOpenAlert}
                                  mr={3}
                                  onClick={() => {
                                    setTabIndex(tabListIndex[tabIndex.id - 1]);
                                    setCandidateFormData((prev: any) => ({
                                      ...prev,
                                      ...values,
                                    }));
                                  }}
                                >
                                  Previous
                                </Button>
                              )}
                              {tabIndex.id === 4 && (
                                <Button
                                  variant="solid"
                                  onClick={() => {
                                    handleSubmit();
                                    console.log(errors);
                                    !isValid && onOpen();
                                  }}
                                  disabled={isOpenAlert || uploading}
                                  loading={
                                    resCreateCandidate.isLoading ||
                                    resCandidateUpdate.isLoading
                                  }
                                >
                                  {edit ? 'Update' : 'Save'}
                                </Button>
                              )}
                            </ModalFooter>
                          </form>
                        )}
                      </Formik>
                    )}
                  </TabPanels>
                </Box>
              </Flex>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CandidatesModal;
