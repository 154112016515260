import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, FieldArray, Field } from 'formik';
import * as Yup from 'yup';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Checkbox,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  useToast,
  Divider,
} from '@chakra-ui/react';

import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  useCreateRecruitersMutation,
  useGetJobsMutation,
  useListJobsMutation,
  useUpdateRecruitersMutation,
} from 'store/jobs.slice';
import Button from 'Library/Button';
import { useUserListMutation } from 'store/user.slice';
import { useParams } from 'react-router';
import AtsSelect from 'components/app/AtsSelect';
import ScrollToFieldError from 'components/app/ScrollError';

interface JobsModalProps {
  isOpen: any;
  onClose: any;
  recruitmentData?: any;
  update?: boolean;
}

const JobsRecruitersModal = ({
  isOpen,
  onClose,
  recruitmentData,
  update = false,
}: JobsModalProps) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const { jobData, jobPgBtn } = useSelector((state: any) => state.jobs);
  const { userList } = useSelector((state: any) => state.user);

  const params = useParams();
  const jobID = params.jobsId;

  const [reqJobs] = useListJobsMutation();
  const [reqGetJob] = useGetJobsMutation();
  const [reqUsers, resUsers] = useUserListMutation();
  const [reqCreateRecruiters, resCreateRecruiters] =
    useCreateRecruitersMutation();
  const [reqUpdateRecruiters, resUpdateRecruiters] =
    useUpdateRecruitersMutation();
  const [recOptions, setRecruiters] = useState([]);

  useEffect(() => {
    // setRecruiters
    let users: any = [];
    if (resUsers.isSuccess) {
      if (resUsers.data?.data.length > 0) {
        Promise.all(
          resUsers.data?.data.map((item: any) => {
            users.push({
              label: [item.first_name, item.last_name].join(' '),
              value: item.id,
            });
          })
        );
      }
    }
    setRecruiters(users);
  }, [resUsers.data?.data, resUsers.isSuccess]);

  const initialValues: any = {
    recruiters: [
      {
        name:
          Object.values(userList)
            .map((item: any) => ({
              label: [item.first_name, item.last_name].join(' '),
              value: item.id,
            }))
            .filter(
              (val: any) => val.value === recruitmentData?.recruiter.id
            )[0] || null,
        split_percentage: recruitmentData?.split || 0,
        post_new_job_ads: recruitmentData?.post_new_job_ads || false,
        no_submittal_review: recruitmentData?.no_submittal_review || false,
        review_send_as_client_owner:
          recruitmentData?.review_send_as_client_owner || false,
        review_send_as_client_recruiter:
          recruitmentData?.review_send_as_client_recruiter || false,
        interview_send_as_client_owner:
          recruitmentData?.interview_send_as_client_owner || false,
        interview_send_as_recruiter:
          recruitmentData?.interview_send_as_recruiter || false,
      },
    ],
  };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      recruiters: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('Recruiter is required'),
          split_percentage: Yup.number().required(
            'Split percentage is required'
          ),
          post_new_job_ads: Yup.boolean(),
          no_submittal_review: Yup.boolean(),
          review_send_as_client_owner: Yup.boolean(),
          review_send_as_client_recruiter: Yup.boolean(),
          interview_send_as_client_owner: Yup.boolean(),
          interview_send_as_recruiter: Yup.boolean(),
        })
      ),
    })
  );

  const tabListIndex: any = [{ id: 0, title: 'Recruiting Team' }];

  const [tabIndex, setTabIndex] = useState(tabListIndex[0]);

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false });

  const onSubmit = async (data: any) => {
    if (recruitmentData && update) {
      reqUpdateRecruiters({ id: recruitmentData.id, data: data.recruiters[0] });
    } else {
      reqCreateRecruiters({ id: jobID, data });
    }
  };

  useEffect(() => {
    reqUsers({});
    if (resCreateRecruiters.isSuccess || resUpdateRecruiters.isSuccess) {
      onCloseAlert();
      onClose();
      reqJobs({ data: jobPgBtn });
      reqGetJob({ id: jobData.id });

      try {
        let title = '';
        let description = '';
        let status: 'error' | 'warning' | 'success' | 'info' = 'success';

        if (resCreateRecruiters.isSuccess) {
          title = 'Added Recruitment Team';
          description = 'New recruitment team successfully added';
        } else if (resUpdateRecruiters.isSuccess) {
          title = 'Updated Recruitment Team';
          description = 'The recruitment team has been updated';
          status = 'info';
        }

        toast({
          title: title,
          description: description,
          status: status,
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      } catch (e) {
        console.log('error', e);
      }
    } else if (resCreateRecruiters.isError) {
      onOpen();
    }
  }, [
    jobPgBtn,
    dispatch,
    onClose,
    onCloseAlert,
    onOpen,
    resCreateRecruiters.isError,
    resCreateRecruiters.isSuccess,
    jobData.id,
    toast,
    resUpdateRecruiters.isSuccess,
  ]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onCloseAlert();
          onClose();
        }}
        size="5xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="NunitoSans Regular">
          <ModalHeader
            background="#F4F6F9"
            borderBottom="1px solid gainsboro"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            {recruitmentData
              ? 'Edit Recruitment Team'
              : 'Create New Recruitment Team'}
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          {isOpenAlert && (
            <Box>
              <Alert status="error" justifyContent="space-around">
                <AlertIcon />
                <Box>
                  <AlertTitle>Oh snap!</AlertTitle>
                  <AlertDescription>
                    There was an error processing your request. Change a few
                    things up and try again.
                  </AlertDescription>
                </Box>
                <CloseButton
                  position="relative"
                  right={-1}
                  top={-3}
                  onClick={onCloseAlert}
                />
              </Alert>
            </Box>
          )}
          <ModalBody
            borderRadius="0 0 4px 4px"
            p={0}
            fontFamily="NunitoSans Regular"
            overflow="hidden"
          >
            <Tabs colorScheme="purple" variant="unstyled" index={tabIndex.id}>
              <Flex gap={2} background="#F9F9FB">
                <Box
                  width="30%"
                  py={4}
                  overflowY="hidden"
                  borderRight="1px solid"
                  borderColor="transparent"
                  px={6}
                >
                  <TabList
                    flexDirection="column"
                    alignItems="flex-start"
                    gap="8px"
                  >
                    {tabListIndex.map((tab: any) => {
                      return (
                        <Tab
                          key={`tabsJA-${tab.id}`}
                          justifyContent="space-between"
                          width="100%"
                          border="0.5px solid rgba(155, 155, 155, 0.5)"
                          borderRadius="3px"
                          background="#fff"
                          p="12px 20px"
                          lineHeight="18px"
                          fontSize="14px"
                          fontWeight="600"
                          _selected={{
                            bg: '#EFE5FF',
                            border: '0.5px solid #EFE5FF',
                          }}
                          onClick={() => setTabIndex(tabListIndex[tab.id])}
                        >
                          {tab.title}
                          <Icon
                            as={ChevronLeftIcon}
                            transition="all .25s ease-in-out"
                            transform={isOpen ? 'rotate(180deg)' : ''}
                            w={6}
                            h={6}
                            ml="8px"
                            color={
                              tabIndex.id === tab.id ? 'primary.800' : '#fff'
                            }
                          />
                        </Tab>
                      );
                    })}
                  </TabList>
                </Box>

                <Box width="70%">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({
                      values,
                      handleChange,
                      handleSubmit,
                      errors,
                      touched,
                      isValid,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <ScrollToFieldError />
                        <TabPanels>
                          <TabPanel>
                            {/* Recruiting Team */}
                            <Box
                              overflow="auto"
                              maxHeight="450px"
                              p="30px"
                              background="#fff"
                              borderRadius="8px"
                              minHeight="65vh"
                            >
                              <FieldArray name="recruiters">
                                {({ remove, push }) => (
                                  <div>
                                    {values.recruiters.length > 0 &&
                                      values.recruiters.map(
                                        (recruiter: any, index: number) => {
                                          const recruiter_name = `recruiters[${index}].name`;
                                          const split_name = `recruiters[${index}].split_percentage`;
                                          const post_new_job_ads = `recruiters[${index}]post_new_job_ads`;
                                          const no_submittal_review = `recruiters[${index}]no_submittal_review`;
                                          const review_send_as_client_owner = `recruiters[${index}]review_send_as_client_owner`;
                                          const review_send_as_client_recruiter = `recruiters[${index}]review_send_as_client_recruiter`;
                                          const interview_send_as_client_owner = `recruiters[${index}]interview_send_as_client_owner`;
                                          const interview_send_as_recruiter = `recruiters[${index}]interview_send_as_recruiter`;
                                          return (
                                            <>
                                              <Box
                                                className="row"
                                                key={`recr-${index}`}
                                              >
                                                <FormControl mb="34px">
                                                  <Flex
                                                    justifyContent="space-between"
                                                    alignItems="flex-end"
                                                  >
                                                    <FormLabel
                                                      fontSize="14px"
                                                      lineHeight="18px"
                                                      color="default.secondarytext"
                                                    >
                                                      Recruiter
                                                    </FormLabel>
                                                    {values.recruiters.length >
                                                      0 && (
                                                      <Box mb="10px">
                                                        <Button
                                                          leftIcon="close"
                                                          onClick={() =>
                                                            remove(index)
                                                          }
                                                          size="sm"
                                                        >
                                                          Remove
                                                        </Button>
                                                      </Box>
                                                    )}
                                                  </Flex>
                                                  <AtsSelect
                                                    name={recruiter_name}
                                                    id={recruiter_name}
                                                    placeholder="Select"
                                                    variant="outline"
                                                    onChange={(e: any) =>
                                                      setFieldValue(
                                                        recruiter_name,
                                                        String(e.value)
                                                      )
                                                    }
                                                    defaultValue={
                                                      recruiter.name
                                                    }
                                                    options={
                                                      recOptions?.length > 0 &&
                                                      recOptions.map(
                                                        (item: any) => ({
                                                          label: item.label,
                                                          value: item.value,
                                                        })
                                                      )
                                                    }
                                                  />
                                                  <FormErrorMessage>
                                                    {String(recruiter_name)}
                                                  </FormErrorMessage>
                                                </FormControl>

                                                <Flex mb="34px">
                                                  <FormControl>
                                                    <FormLabel
                                                      fontSize="14px"
                                                      lineHeight="18px"
                                                      color="default.secondarytext"
                                                    >
                                                      Select split percentage
                                                    </FormLabel>
                                                    <Field
                                                      as={Input}
                                                      id={split_name}
                                                      name={split_name}
                                                      placeholder="Split Percentage"
                                                      type="number"
                                                      variant="outline"
                                                      max={100}
                                                      min={0}
                                                      value={
                                                        recruiter.split_percentage
                                                      }
                                                      onChange={handleChange}
                                                    />

                                                    <FormErrorMessage>
                                                      {String(split_name)}
                                                    </FormErrorMessage>
                                                  </FormControl>
                                                </Flex>

                                                <Box mb="20px">
                                                  <Checkbox
                                                    id={post_new_job_ads}
                                                    name={post_new_job_ads}
                                                    isChecked={
                                                      recruiter.post_new_job_ads
                                                    }
                                                    onChange={handleChange}
                                                  >
                                                    Allow recruiter to post new
                                                    jobs ads
                                                  </Checkbox>
                                                </Box>

                                                <Box mb="20px">
                                                  <FormLabel
                                                    fontSize="14px"
                                                    lineHeight="18px"
                                                    color="default.secondarytext"
                                                    mb="16px"
                                                  >
                                                    Submittal Review
                                                  </FormLabel>

                                                  <Box mb="10px">
                                                    <Checkbox
                                                      id={no_submittal_review}
                                                      name={no_submittal_review}
                                                      isChecked={
                                                        recruiter.no_submittal_review
                                                      }
                                                      onChange={handleChange}
                                                    >
                                                      No submittal review
                                                    </Checkbox>
                                                  </Box>
                                                  <Box mb="10px">
                                                    <Checkbox
                                                      id={
                                                        review_send_as_client_owner
                                                      }
                                                      name={
                                                        review_send_as_client_owner
                                                      }
                                                      isChecked={
                                                        recruiter.review_send_as_client_owner
                                                      }
                                                      onChange={handleChange}
                                                    >
                                                      Review submittal and send
                                                      as client owner
                                                    </Checkbox>
                                                  </Box>
                                                  <Box mb="10px">
                                                    <Checkbox
                                                      id={
                                                        review_send_as_client_recruiter
                                                      }
                                                      name={
                                                        review_send_as_client_recruiter
                                                      }
                                                      isChecked={
                                                        recruiter.review_send_as_client_recruiter
                                                      }
                                                      onChange={handleChange}
                                                    >
                                                      Review submittal and send
                                                      as recruiter
                                                    </Checkbox>
                                                  </Box>
                                                </Box>

                                                <Box mb="20px">
                                                  <FormLabel
                                                    fontSize="14px"
                                                    lineHeight="18px"
                                                    color="default.secondarytext"
                                                    mb="16px"
                                                  >
                                                    Interview Settings
                                                  </FormLabel>

                                                  <Box mb="10px">
                                                    <Checkbox
                                                      id={
                                                        interview_send_as_client_owner
                                                      }
                                                      name={
                                                        interview_send_as_client_owner
                                                      }
                                                      isChecked={
                                                        recruiter.interview_send_as_client_owner
                                                      }
                                                      onChange={handleChange}
                                                    >
                                                      Send interview as client
                                                      owner
                                                    </Checkbox>
                                                  </Box>
                                                  <Box mb="10px">
                                                    <Checkbox
                                                      id={
                                                        interview_send_as_recruiter
                                                      }
                                                      name={
                                                        interview_send_as_recruiter
                                                      }
                                                      isChecked={
                                                        recruiter.interview_send_as_recruiter
                                                      }
                                                      onChange={handleChange}
                                                    >
                                                      Send interview as
                                                      recruiter
                                                    </Checkbox>
                                                  </Box>
                                                </Box>
                                              </Box>
                                              <Divider mb="50px" />
                                            </>
                                          );
                                        }
                                      )}
                                    <Button
                                      leftIcon="plus"
                                      onClick={() =>
                                        push({
                                          name: 0,
                                          split_percentage: 0,
                                          post_new_job_ads: false,
                                          no_submittal_review: false,
                                          review_send_as_client_owner: false,
                                          review_send_as_client_recruiter:
                                            false,
                                          interview_send_as_client_owner: false,
                                          interview_send_as_recruiter: false,
                                        })
                                      }
                                    >
                                      {values.recruiters.length > 0
                                        ? 'Add Another Recruiter'
                                        : 'Add Recruiter'}
                                    </Button>
                                  </div>
                                )}
                              </FieldArray>
                            </Box>
                          </TabPanel>
                          <ModalFooter
                            position="sticky"
                            bottom="0"
                            background="#F9F9FB"
                            mb="20px"
                            mr="20px"
                            gap="16px"
                          >
                            <Button
                              variant="solid"
                              onClick={() => {
                                console.log(errors);
                                handleSubmit();
                                !isValid && onOpen();
                              }}
                              disabled={isOpenAlert}
                            >
                              Save
                            </Button>
                          </ModalFooter>
                        </TabPanels>
                      </form>
                    )}
                  </Formik>
                </Box>
              </Flex>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default JobsRecruitersModal;
