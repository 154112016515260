import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Text,
  Input,
  FormErrorMessage,
  Radio,
  Divider,
  InputGroup,
  InputRightElement,
  Stack,
  HStack,
  TagLabel,
  TagCloseButton,
  InputLeftAddon,
  InputLeftElement,
  Icon,
  Checkbox,
  Tag,
} from '@chakra-ui/react';
import AtsSelect from 'components/app/AtsSelect';
import ScrollToFieldError from 'components/app/ScrollError';
import FAIcon from 'components/lib/FAIcon';
import { Field, FieldArray, Formik } from 'formik';
import { OptionsInt, ResumeFormProps } from '../../types';
import { useResumeFormServices } from './useResumeFormServices';

import Button from 'Library/Button';
import { CheckIcon } from '@chakra-ui/icons';
import AddressComponent from 'components/app/Candidates/components/address';
import { BsCurrencyDollar } from 'react-icons/bs';
import AtsDropZone from 'components/app/Dropzone';
import ResumeViewer from '../../Viewer';

export default function ResumeForm({
  resumeData,
  handleSelect,
  selected,
  resumesLength,
  onClose,
  job_id,
  reqUnparsedList,
}: ResumeFormProps) {
  const {
    onSubmit,
    setResumeUrl,
    setHasSkills,
    setHasTags,
    onOpenAlert,
    handleDelete,
    candidate_work_status,
    isPdf,
    resumeUrl,
    initialValues,
    countryOption,
    hasSkills,
    hasTags,
    contactCategoryOptions,
    formRef,
    validationSchema,
    resManualWithJobCreate,
    resDelResume,
  } = useResumeFormServices({
    resumeData,
    handleSelect,
    selected,
    resumesLength,
    onClose,
    job_id,
    reqUnparsedList,
  });
  return (
    <Flex direction="column" w="100%" position="relative" key={resumeData?.id}>
      <Flex className="body" gap="8" w="100%" position="relative" h="80%">
        {/* RESUME VIEWER PORTION */}
        <Flex
          direction="column"
          width="55%"
          h="100%"
          gap="6"
          position="relative"
        >
          <ResumeViewer
            resumeData={resumeData}
            resumeUrl={resumeUrl}
            setResumeUrl={setResumeUrl}
            isPdf={isPdf}
          />
        </Flex>
        {/* MANUAL RESUME FORM PORTION */}
        <Flex width="45%">
          <Formik
            initialValues={initialValues}
            innerRef={formRef}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              errors,
              touched,
              isValid,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <ScrollToFieldError />
                <Flex
                  gap="36px"
                  w="100%"
                  direction="column"
                  h="100%"
                  position="relative"
                >
                  <Flex
                    w="100%"
                    direction="column"
                    gap="6"
                    maxH="66vh"
                    h="100%"
                    position="relative"
                    overflowY="scroll"
                  >
                    <Text
                      textAlign="left"
                      color="primarytext"
                      fontWeight="bold"
                      fontSize="18px"
                    >
                      Personal Details
                    </Text>

                    {/* Personal Details */}
                    <Box
                      border="1px"
                      borderColor="default.white.200"
                      p="4"
                      h="100%"
                      background="#fff"
                      borderRadius="8px"
                    >
                      <Flex gap="4" mb="4">
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.first_name && touched.first_name
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="dark"
                          >
                            First Name
                          </FormLabel>
                          <Input
                            id="first_name"
                            name="first_name"
                            type="text"
                            placeholder="First Name"
                            variant="outline"
                            value={values.first_name}
                            onChange={handleChange}
                          />
                          <FormErrorMessage>
                            {String(errors.first_name)}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.last_name && touched.last_name
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="dark"
                          >
                            Last Name{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <Input
                            id="last_name"
                            name="last_name"
                            type="text"
                            placeholder="Last Name"
                            variant="outline"
                            value={values.last_name}
                            onChange={handleChange}
                          />
                          <FormErrorMessage>
                            {String(errors.last_name)}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>
                      <Flex gap="4" mb="4">
                        <Box width="100%">
                          <FieldArray name="contact">
                            {({ remove, push }) => (
                              <div>
                                <FormLabel
                                  fontSize="14px"
                                  lineHeight="18px"
                                  color="dark"
                                >
                                  Contact
                                </FormLabel>

                                {values.contact?.map(
                                  (cont: any, index: any) => (
                                    <FormControl
                                      isInvalid={Boolean(
                                        !!errors.contact && touched.contact
                                      )}
                                    >
                                      <FormControl
                                        isInvalid={Boolean(
                                          !!(errors.contact as any[])?.[index]
                                            ?.number &&
                                            (touched.contact as any[])?.[index]
                                              ?.number
                                        )}
                                      >
                                        <Flex
                                          width="100%"
                                          direction="row"
                                          key={index}
                                          mb="12px"
                                          alignItems="center"
                                          gap="12px"
                                        >
                                          <Flex width="70%">
                                            <Field
                                              as={Input}
                                              name={`contact.${index}.number`}
                                              placeholder="Contact"
                                              size="md"
                                              type="text"
                                              onChange={handleChange}
                                            />
                                          </Flex>

                                          <Flex width="25%">
                                            <FormControl
                                              isInvalid={Boolean(
                                                !!(errors.contact as any[])?.[
                                                  index
                                                ]?.category &&
                                                  (touched.contact as any[])?.[
                                                    index
                                                  ]?.category
                                              )}
                                            >
                                              <Flex
                                                justifyContent="center"
                                                alignItems="center"
                                                zIndex="9999"
                                              >
                                                <AtsSelect
                                                  variant="outline"
                                                  defaultValue={
                                                    contactCategoryOptions[0]
                                                  }
                                                  onChange={(e: any) => {
                                                    setFieldValue(
                                                      `contact.${index}.category`,
                                                      e.value
                                                    );
                                                  }}
                                                  value={contactCategoryOptions.find(
                                                    (option: OptionsInt) =>
                                                      option.value ===
                                                      values.contact[index]
                                                        .category
                                                  )}
                                                  options={
                                                    contactCategoryOptions
                                                  }
                                                  styles={{
                                                    zIndex: '9999',
                                                    position: 'absolute',
                                                    indicatorSeparator:
                                                      () => {},
                                                    dropdownIndicator: (
                                                      defaultStyles: any
                                                    ) => ({
                                                      ...defaultStyles,
                                                      '& svg': {
                                                        width: '10px',
                                                      },
                                                    }),
                                                  }}
                                                />
                                              </Flex>
                                            </FormControl>
                                          </Flex>

                                          <Flex
                                            width="5%"
                                            justifyContent="center"
                                            alignItems="center"
                                          >
                                            <FormControl>
                                              <Flex
                                                justifyContent="center"
                                                alignItems="center"
                                              >
                                                <Radio
                                                  id={`contact.${index}.primary`}
                                                  name={`contactPrimary`} // Set the same name for all radio buttons
                                                  value={index} // Use the index as the value
                                                  isChecked={cont.primary}
                                                  onChange={(e) => {
                                                    // Handle radio button change to update the selected row
                                                    const updatedContact =
                                                      values.contact.map(
                                                        (
                                                          contact: any,
                                                          key: number
                                                        ) => ({
                                                          ...contact,
                                                          primary:
                                                            key === index,
                                                        })
                                                      );
                                                    setFieldValue(
                                                      'contact',
                                                      updatedContact
                                                    );
                                                  }}
                                                ></Radio>
                                              </Flex>
                                            </FormControl>
                                          </Flex>
                                          <Flex
                                            width="5%"
                                            justifyContent="center"
                                            alignItems="center"
                                          >
                                            {index > 0 && (
                                              <Flex
                                                onClick={() => remove(index)}
                                                justifyContent="center"
                                                alignItems="center"
                                                color="#D5605D"
                                                cursor="pointer"
                                              >
                                                <FAIcon iconName="trash" />
                                              </Flex>
                                            )}
                                          </Flex>
                                        </Flex>
                                        <FormErrorMessage mb="12px">
                                          {String(
                                            (errors.contact as any[])?.[index]
                                              ?.number
                                          )}
                                        </FormErrorMessage>
                                      </FormControl>
                                      {index + 1 === values.contact.length &&
                                        typeof errors.contact !== 'object' && (
                                          <FormErrorMessage mb="12px">
                                            {String(errors.contact)}
                                          </FormErrorMessage>
                                        )}
                                    </FormControl>
                                  )
                                )}

                                <Button
                                  leftIcon="plus"
                                  variant="ghost"
                                  rest={{
                                    color: 'primary.800',
                                    _hover: {
                                      bg: 'primary.100',
                                    },
                                  }}
                                  onClick={() => {
                                    push({
                                      number: '',
                                      type: 'phone',
                                      primary: false,
                                      category: 'Personal',
                                    });
                                  }}
                                >
                                  Add Contact
                                </Button>
                              </div>
                            )}
                          </FieldArray>
                        </Box>
                      </Flex>
                      <Flex gap="4" mb="4">
                        <Box overflowY="auto" maxHeight="450px" width="100%">
                          <FieldArray name="emails">
                            {({ remove, push }) => (
                              <div>
                                <FormLabel
                                  fontSize="14px"
                                  lineHeight="18px"
                                  color="dark"
                                >
                                  Email
                                </FormLabel>

                                {values.emails?.map(
                                  (email: any, index: any) => (
                                    <FormControl
                                      isInvalid={Boolean(
                                        !!errors.emails && touched.emails
                                      )}
                                    >
                                      <FormControl
                                        isInvalid={Boolean(
                                          !!(errors.emails as any[])?.[index]
                                            ?.email &&
                                            (touched.emails as any[])?.[index]
                                              ?.email
                                        )}
                                      >
                                        <Flex
                                          width="100%"
                                          direction="row"
                                          key={index}
                                          mb="12px"
                                          alignItems="center"
                                          gap="12px"
                                        >
                                          <Flex width="80%">
                                            <Field
                                              as={Input}
                                              name={`emails.${index}.email`}
                                              placeholder="Email Address"
                                              size="md"
                                              type="text"
                                              onChange={handleChange}
                                            />
                                          </Flex>

                                          <Flex
                                            width="10%"
                                            justifyContent="center"
                                            alignItems="center"
                                          >
                                            <Flex
                                              justifyContent="center"
                                              alignItems="center"
                                            >
                                              <Radio
                                                id={`emails.${index}.primary`}
                                                name={`emailsPrimary`} // Set the same name for all radio buttons
                                                value={index} // Use the index as the value
                                                isChecked={email.primary}
                                                onChange={(e) => {
                                                  // Handle radio button change to update the selected row
                                                  const updatedEmails =
                                                    values.emails.map(
                                                      (
                                                        email: any,
                                                        key: number
                                                      ) => ({
                                                        ...email,
                                                        primary: key === index,
                                                      })
                                                    );
                                                  setFieldValue(
                                                    'emails',
                                                    updatedEmails
                                                  );
                                                }}
                                              ></Radio>
                                            </Flex>
                                          </Flex>

                                          <Flex
                                            width="10%"
                                            justifyContent="center"
                                            alignItems="center"
                                          >
                                            {index > 0 && (
                                              <Flex
                                                onClick={() => remove(index)}
                                                justifyContent="center"
                                                alignItems="center"
                                                color="#D5605D"
                                                cursor="pointer"
                                              >
                                                <FAIcon iconName="trash" />
                                              </Flex>
                                            )}
                                          </Flex>
                                        </Flex>
                                        <FormErrorMessage mb="12px">
                                          {String(
                                            (errors.emails as any[])?.[index]
                                              ?.email
                                          )}
                                        </FormErrorMessage>
                                      </FormControl>
                                      {index + 1 === values.emails.length &&
                                        typeof errors.emails !== 'object' && (
                                          <FormErrorMessage mb="12px">
                                            {String(errors.emails)}
                                          </FormErrorMessage>
                                        )}
                                    </FormControl>
                                  )
                                )}

                                <Button
                                  leftIcon="plus"
                                  variant="ghost"
                                  rest={{
                                    color: 'primary.800',
                                    _hover: {
                                      bg: 'primary.100',
                                    },
                                  }}
                                  onClick={() => {
                                    push({
                                      email: '',
                                      primary: false,
                                    });
                                  }}
                                >
                                  Add Email
                                </Button>
                              </div>
                            )}
                          </FieldArray>
                        </Box>
                      </Flex>
                      <Flex gap="4" mb="4">
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.profile_url && touched.profile_url
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="dark"
                          >
                            Profile URL
                          </FormLabel>
                          <Input
                            id="profile_url"
                            name="profile_url"
                            type="text"
                            placeholder="Profile URL"
                            variant="outline"
                            value={values.profile_url}
                            onChange={handleChange}
                          />
                          <FormErrorMessage>
                            {String(errors.profile_url)}
                          </FormErrorMessage>
                        </FormControl>

                        <FormControl
                          isInvalid={Boolean(
                            !!errors.linkedin_url && touched.linkedin_url
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="dark"
                          >
                            LinkedIn Url
                          </FormLabel>
                          <Input
                            id="linkedin_url"
                            name="linkedin_url"
                            type="text"
                            placeholder="LinkedIn Url"
                            variant="outline"
                            value={values.linkedin_url}
                            onChange={handleChange}
                          />
                        </FormControl>
                      </Flex>
                      <Flex gap="32px" mb="34px">
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.work_status && touched.work_status
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Work Status
                          </FormLabel>
                          <AtsSelect
                            id="work_status"
                            name="work_status"
                            placeholder="Select"
                            variant="outline"
                            onChange={(e: any) =>
                              setFieldValue('work_status', String(e.value))
                            }
                            defaultValue={
                              (candidate_work_status?.length > 0 &&
                                candidate_work_status
                                  .map((item: any) => ({
                                    label: item.status,
                                    value: item.id,
                                  }))
                                  .filter(
                                    (val: any) =>
                                      val.value === Number(values.work_status)
                                  )[0]) ||
                              values.work_status
                            }
                            options={
                              candidate_work_status?.length > 0 &&
                              candidate_work_status.map((item: any) => ({
                                label: item.status,
                                value: item.id,
                              }))
                            }
                          />
                          <FormErrorMessage>
                            {String(errors.work_status)}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>
                      <Flex gap="32px">
                        <FormControl>
                          <FieldArray name="skills">
                            {({ push }) => {
                              const handleKeyDown = (e: any) => {
                                if (e.key === 'Enter') {
                                  push(hasSkills);
                                  setHasSkills('');
                                }
                              };
                              return (
                                <FormControl mb="16px">
                                  <Flex
                                    justifyContent="space-between"
                                    alignItems="flex-end"
                                  >
                                    <FormLabel
                                      fontSize="14px"
                                      lineHeight="18px"
                                      color="default.secondarytext"
                                    >
                                      Skills
                                    </FormLabel>
                                  </Flex>
                                  <InputGroup>
                                    <Input
                                      name="skills"
                                      id="kills"
                                      placeholder="Type Skills Name"
                                      type="text"
                                      variant="outline"
                                      value={hasSkills}
                                      onChange={(e) =>
                                        setHasSkills(e.target.value)
                                      }
                                      onKeyDown={handleKeyDown}
                                    />
                                    <InputRightElement
                                      background="#F3EEFB"
                                      borderRadius="0 4px 4px 0"
                                      children={
                                        <CheckIcon color="priamry.800" />
                                      }
                                    />
                                  </InputGroup>
                                  <Input display="none" />
                                </FormControl>
                              );
                            }}
                          </FieldArray>
                          <FieldArray name="skills">
                            {({ remove }) => (
                              <Flex gap="10px" display="inline-block">
                                {values.skills?.length > 0 &&
                                  values.skills?.map(
                                    (skill: any, index: any) => (
                                      <Tag
                                        style={{
                                          display: 'inline-block',
                                        }}
                                        key={index}
                                        mr="10px"
                                        mb="10px"
                                      >
                                        <Stack spacing={1}>
                                          <HStack>
                                            <TagLabel maxW="100px">
                                              {skill as String}
                                            </TagLabel>
                                            <TagCloseButton
                                              onClick={() => {
                                                remove(index);
                                              }}
                                            />
                                          </HStack>
                                        </Stack>
                                      </Tag>
                                    )
                                  )}
                              </Flex>
                            )}
                          </FieldArray>
                        </FormControl>
                      </Flex>

                      <Divider />

                      <Box>
                        <Text mb="34px">Address Information</Text>
                        <AddressComponent
                          setFieldValue={setFieldValue}
                          handleChange={handleChange}
                          countryOption={countryOption}
                          countryField={{
                            name: 'country',
                            value: values.country,
                            errors: errors.country,
                            touched: touched.country,
                          }}
                          cityField={{
                            name: 'city',
                            value: values.city,
                            errors: errors.city,
                            touched: touched.city,
                          }}
                          stateField={{
                            name: 'state_province',
                            value: values.state_province,
                            errors: errors.state_province,
                            touched: touched.state_province,
                          }}
                          zipField={{
                            name: 'zip_code',
                            value: values.zip_code,
                            errors: errors.zip_code,
                            touched: touched.zip_code,
                          }}
                        />
                      </Box>

                      <Divider />

                      {/* Professional Details */}
                      <Box fontWeight="bold" fontSize="lg" my="20px">
                        Professional Details
                      </Box>
                      <FormLabel
                        fontSize="14px"
                        lineHeight="18px"
                        color="default.secondarytext"
                      >
                        Total Experience (Year)
                      </FormLabel>
                      <Flex gap="32px" mb="34px">
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.total_year_of_experience &&
                              touched.total_year_of_experience
                          )}
                        >
                          <InputGroup>
                            <InputLeftAddon children="Years" />
                            <Input
                              id="total_year_of_experience"
                              name="total_year_of_experience"
                              type="number"
                              min={0}
                              max={100}
                              value={values.total_year_of_experience}
                              onChange={(e: any) => {
                                const inputValue = e.target.value;
                                const sanitizedValue = inputValue.replace(
                                  /[^\d]/g,
                                  ''
                                ); // Remove non-digit characters
                                const clampedValue = Math.min(
                                  100,
                                  Math.max(0, sanitizedValue)
                                ); // Clamp value between 0 and 100

                                handleChange({
                                  target: {
                                    name: 'total_year_of_experience',
                                    value: clampedValue,
                                  },
                                });
                              }}
                            />
                          </InputGroup>

                          <FormErrorMessage>
                            {String(errors.total_year_of_experience)}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.total_months_of_experience &&
                              touched.total_months_of_experience
                          )}
                        >
                          <InputGroup>
                            <InputLeftAddon children="Months" />
                            <Input
                              id="total_months_of_experience"
                              name="total_months_of_experience"
                              type="text"
                              min={0}
                              max={11}
                              value={values.total_months_of_experience}
                              onChange={(e: any) => {
                                const inputValue = e.target.value;
                                const sanitizedValue = inputValue.replace(
                                  /[^\d]/g,
                                  ''
                                ); // Remove non-digit characters
                                const clampedValue = Math.min(
                                  11,
                                  Math.max(0, sanitizedValue)
                                ); // Clamp value between 0 and 100

                                handleChange({
                                  target: {
                                    name: 'total_months_of_experience',
                                    value: clampedValue,
                                  },
                                });
                              }}
                            />
                          </InputGroup>

                          <FormErrorMessage>
                            {String(errors.total_months_of_experience)}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>
                      <Flex gap="32px" mb="34px">
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.latest_job_title &&
                              touched.latest_job_title
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Current / Latest Job Title
                          </FormLabel>
                          <Input
                            id="latest_job_title"
                            name="latest_job_title"
                            type="text"
                            placeholder="Job Title"
                            variant="outline"
                            value={values.latest_job_title}
                            onChange={handleChange}
                          />
                          <FormErrorMessage>
                            {String(errors.latest_job_title)}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.latest_employer && touched.latest_employer
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Curent / Latest Employer
                          </FormLabel>
                          <Input
                            id="latest_employer"
                            name="latest_employer"
                            type="text"
                            placeholder="Employer"
                            variant="outline"
                            value={values.latest_employer}
                            onChange={handleChange}
                          />
                          <FormErrorMessage>
                            {String(errors.latest_employer)}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>
                      <Flex gap="32px" mb="34px">
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.latest_salary && touched.latest_salary
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Current / Latest Salary
                          </FormLabel>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              children={
                                <Icon as={BsCurrencyDollar} color="gray.500" />
                              }
                            />
                            <Input
                              id="latest_salary"
                              name="latest_salary"
                              type="text"
                              placeholder="0.00"
                              variant="outline"
                              textAlign="right"
                              value={values.latest_salary}
                              onChange={(event: any) => {
                                const { value } = event.target;

                                // Remove any existing commas from the input
                                const sanitizedValue = value.replace(/,/g, '');

                                // Format the number with commas
                                const formattedValue =
                                  Number(sanitizedValue)?.toLocaleString();

                                setFieldValue('latest_salary', formattedValue);
                              }}
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {String(errors.latest_salary)}
                          </FormErrorMessage>
                        </FormControl>
                        <Flex gap="32px" mb="34px"></Flex>
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.expected_salary_start &&
                              touched.expected_salary_start
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Expected Salary (Start)
                          </FormLabel>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              children={
                                <Icon as={BsCurrencyDollar} color="gray.500" />
                              }
                            />
                            <Input
                              id="expected_salary_start"
                              name="expected_salary_start"
                              type="text"
                              placeholder="0.00"
                              variant="outline"
                              textAlign="right"
                              value={values.expected_salary_start}
                              onChange={(event: any) => {
                                const { value } = event.target;

                                // Remove any existing commas from the input
                                const sanitizedValue = value.replace(/,/g, '');

                                // Format the number with commas
                                const formattedValue =
                                  Number(sanitizedValue)?.toLocaleString();

                                setFieldValue(
                                  'expected_salary_start',
                                  formattedValue
                                );
                              }}
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {String(errors.expected_salary_start)}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.expected_salary_end &&
                              touched.expected_salary_end
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Expected Salary (End)
                          </FormLabel>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              children={
                                <Icon as={BsCurrencyDollar} color="gray.500" />
                              }
                            />
                            <Input
                              id="expected_salary_end"
                              name="expected_salary_end"
                              type="text"
                              placeholder="0.00"
                              variant="outline"
                              textAlign="right"
                              value={values.expected_salary_end}
                              onChange={(event: any) => {
                                const { value } = event.target;

                                // Remove any existing commas from the input
                                const sanitizedValue = value.replace(/,/g, '');

                                // Format the number with commas
                                const formattedValue =
                                  Number(sanitizedValue)?.toLocaleString();

                                setFieldValue(
                                  'expected_salary_end',
                                  formattedValue
                                );
                              }}
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {String(errors.expected_salary_end)}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>

                      <Box mb="34px">
                        <FormControl mb="3">
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Resume
                          </FormLabel>
                        </FormControl>
                        <AtsDropZone
                          multipleFile={true}
                          uploadedEnd={(e: any) => {}}
                          deletedFile={(key: string) => {}}
                        />
                      </Box>

                      <Divider />

                      {/* Experience Details */}
                      <Box fontWeight="bold" fontSize="lg" my="40px">
                        Experience Details
                      </Box>
                      <FieldArray name="experiences">
                        {({ remove, push }) => (
                          <div>
                            {values.experiences?.length > 0 &&
                              values.experiences?.map(
                                (experience: any, index: any) => (
                                  <div className="row" key={index}>
                                    <FormControl mb="34px">
                                      <Flex
                                        justifyContent="space-between"
                                        alignItems="flex-end"
                                      >
                                        <FormLabel
                                          fontSize="14px"
                                          lineHeight="18px"
                                          color="default.secondarytext"
                                        >
                                          Company
                                        </FormLabel>
                                        {values.experiences?.length > 0 && (
                                          <Box mb="10px">
                                            <Button
                                              leftIcon="close"
                                              onClick={() => remove(index)}
                                              size="sm"
                                            >
                                              Remove
                                            </Button>
                                          </Box>
                                        )}
                                      </Flex>
                                      <Field
                                        as={Input}
                                        name={`experiences.${index}.company`}
                                        placeholder="Company"
                                        type="text"
                                        variant="outline"
                                        value={experience.company}
                                        onChange={handleChange}
                                      />
                                      <FormErrorMessage>
                                        {String(`experiences${index}.company`)}
                                      </FormErrorMessage>
                                    </FormControl>
                                    <FormControl mb="34px">
                                      <FormLabel
                                        fontSize="14px"
                                        lineHeight="18px"
                                        color="default.secondarytext"
                                      >
                                        Job Title
                                      </FormLabel>
                                      <Field
                                        as={Input}
                                        name={`experiences.${index}.job_title`}
                                        placeholder="Job Title"
                                        type="text"
                                        variant="outline"
                                        value={experience.job_title}
                                        onChange={handleChange}
                                      />
                                      <FormErrorMessage>
                                        {String(
                                          `experiences${index}.job_title`
                                        )}
                                      </FormErrorMessage>
                                    </FormControl>
                                    <Flex gap="32px" mb="16px">
                                      <FormControl>
                                        <FormLabel
                                          fontSize="14px"
                                          lineHeight="18px"
                                          color="default.secondarytext"
                                        >
                                          Start Date
                                        </FormLabel>
                                        <Field
                                          as={Input}
                                          name={`experiences.${index}.start_date`}
                                          placeholder="Select Date"
                                          size="md"
                                          type="date"
                                          value={experience.start_date}
                                          onChange={handleChange}
                                        />
                                      </FormControl>
                                      <FormControl>
                                        <FormLabel
                                          fontSize="14px"
                                          lineHeight="18px"
                                          color="default.secondarytext"
                                        >
                                          End Date
                                        </FormLabel>
                                        <Field
                                          as={Input}
                                          name={`experiences.${index}.end_date`}
                                          placeholder="Select Date"
                                          size="md"
                                          type="date"
                                          value={experience.end_date}
                                          onChange={handleChange}
                                        />
                                      </FormControl>
                                    </Flex>

                                    <Box mb="32px">
                                      <Checkbox
                                        id={`experiences.${index}.currently_pursuing`}
                                        name={`experiences.${index}.currently_pursuing`}
                                        isChecked={
                                          experience.currently_pursuing
                                        }
                                        onChange={handleChange}
                                      >
                                        Currently Pursuing
                                      </Checkbox>
                                    </Box>
                                  </div>
                                )
                              )}
                            <Button
                              leftIcon="plus"
                              onClick={() =>
                                push({
                                  company: '',
                                  job_title: '',
                                  start_date: '',
                                  end_date: '',
                                  currently_pursuing: false,
                                })
                              }
                            >
                              Add Another
                            </Button>
                          </div>
                        )}
                      </FieldArray>

                      <Divider mt="40px" />

                      {/* Education Details */}
                      <Box fontWeight="bold" fontSize="lg" my="40px">
                        Education Details
                      </Box>
                      <FieldArray name="educations">
                        {({ remove, push }) => (
                          <div>
                            {values.educations?.length > 0 &&
                              values.educations?.map(
                                (education: any, index: any) => (
                                  <div className="row" key={index}>
                                    <FormControl mb="34px">
                                      <Flex
                                        justifyContent="space-between"
                                        alignItems="flex-end"
                                      >
                                        <FormLabel
                                          fontSize="14px"
                                          lineHeight="18px"
                                          color="default.secondarytext"
                                        >
                                          Institute/School
                                        </FormLabel>
                                        {values.experiences?.length > 0 && (
                                          <Box mb="10px">
                                            <Button
                                              leftIcon="close"
                                              onClick={() => remove(index)}
                                              size="sm"
                                            >
                                              Remove
                                            </Button>
                                          </Box>
                                        )}
                                      </Flex>
                                      <Field
                                        as={Input}
                                        name={`educations.${index}.school`}
                                        placeholder="Institute/School"
                                        type="text"
                                        variant="outline"
                                        value={education.school}
                                        onChange={handleChange}
                                      />
                                      <FormErrorMessage>
                                        {String(`educations${index}.school`)}
                                      </FormErrorMessage>
                                    </FormControl>
                                    <FormControl mb="34px">
                                      <FormLabel
                                        fontSize="14px"
                                        lineHeight="18px"
                                        color="default.secondarytext"
                                      >
                                        Major/Department
                                      </FormLabel>
                                      <Field
                                        as={Input}
                                        name={`educations.${index}.major_department`}
                                        placeholder="Major/Department"
                                        type="text"
                                        variant="outline"
                                        value={education.major_department}
                                        onChange={handleChange}
                                      />
                                      <FormErrorMessage>
                                        {String(
                                          `educations${index}.major_department`
                                        )}
                                      </FormErrorMessage>
                                    </FormControl>
                                    <Flex gap="32px" mb="16px">
                                      <FormControl>
                                        <FormLabel
                                          fontSize="14px"
                                          lineHeight="18px"
                                          color="default.secondarytext"
                                        >
                                          Start Date
                                        </FormLabel>
                                        <Field
                                          as={Input}
                                          name={`educations.${index}.start_date`}
                                          placeholder="Select Date"
                                          size="md"
                                          type="date"
                                          value={education.start_date}
                                          onChange={handleChange}
                                        />
                                      </FormControl>
                                      <FormControl>
                                        <FormLabel
                                          fontSize="14px"
                                          lineHeight="18px"
                                          color="default.secondarytext"
                                        >
                                          End Date
                                        </FormLabel>
                                        <Field
                                          as={Input}
                                          name={`educations.${index}.end_date`}
                                          placeholder="Select Date"
                                          size="md"
                                          type="date"
                                          value={education.end_date}
                                          onChange={handleChange}
                                        />
                                      </FormControl>
                                    </Flex>

                                    <Box mb="32px">
                                      <Checkbox
                                        id={`educations.${index}.currently_pursuing`}
                                        name={`educations.${index}.currently_pursuing`}
                                        isChecked={education.currently_pursuing}
                                        onChange={handleChange}
                                      >
                                        Currently Pursuing
                                      </Checkbox>
                                    </Box>
                                  </div>
                                )
                              )}
                            <Button
                              leftIcon="plus"
                              onClick={() =>
                                push({
                                  school: '',
                                  major_department: '',
                                  start_date: '',
                                  end_date: '',
                                  currently_pursuing: false,
                                })
                              }
                            >
                              Add Another
                            </Button>
                          </div>
                        )}
                      </FieldArray>

                      <Divider mt="40px" />

                      {/* Tags */}
                      <Box fontWeight="bold" fontSize="lg" mt="40px" mb="20px">
                        Tags
                      </Box>
                      <FormControl>
                        <FieldArray name="tags">
                          {({ push }) => {
                            const handleKeyDown = (e: any) => {
                              if (e.key === 'Enter') {
                                push(hasTags);
                                setHasTags('');
                              }
                            };
                            return (
                              <FormControl mb="16px">
                                <Flex
                                  justifyContent="space-between"
                                  alignItems="flex-end"
                                >
                                  <FormLabel
                                    fontSize="14px"
                                    lineHeight="18px"
                                    color="default.secondarytext"
                                  >
                                    Add Tags
                                  </FormLabel>
                                </Flex>
                                <InputGroup>
                                  <Input
                                    name="tag"
                                    id="tag"
                                    placeholder="Type Tag Name"
                                    type="text"
                                    variant="outline"
                                    value={hasTags}
                                    onChange={(e) => setHasTags(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                  />
                                  <InputRightElement
                                    background="#F3EEFB"
                                    borderRadius="0 4px 4px 0"
                                    children={<CheckIcon color="priamry.800" />}
                                  />
                                </InputGroup>
                                <Input display="none" />
                              </FormControl>
                            );
                          }}
                        </FieldArray>
                        <FieldArray name="tags">
                          {({ remove }) => (
                            <Flex gap="10px" display="inline-block">
                              {values.tags?.length > 0 &&
                                values.tags?.map((tag, index) => (
                                  <Tag
                                    style={{
                                      display: 'inline-block',
                                    }}
                                    key={index}
                                    mr="10px"
                                    mb="10px"
                                  >
                                    <Stack spacing={1}>
                                      <HStack>
                                        <TagLabel maxW="100px">
                                          {tag as String}
                                        </TagLabel>
                                        <TagCloseButton
                                          onClick={() => {
                                            remove(index);
                                          }}
                                        />
                                      </HStack>
                                    </Stack>
                                  </Tag>
                                ))}
                            </Flex>
                          )}
                        </FieldArray>
                      </FormControl>
                    </Box>
                  </Flex>
                </Flex>
              </form>
            )}
          </Formik>
        </Flex>
      </Flex>

      <Flex
        className="footer"
        gap="10px"
        position="relative"
        justifyContent="flex-end"
        h="10%"
      >
        <Button
          variant="delete"
          type="button"
          onClick={() => {
            handleDelete(resumeData);
          }}
          loading={resDelResume.isLoading}
        >
          Delete
        </Button>
        <Button
          variant="outline"
          type="button"
          onClick={() => {
            handleSelect(selected + 1 > resumesLength - 1 ? 0 : selected + 1);
          }}
        >
          Skip
        </Button>
        <Button
          variant="solid"
          type="submit"
          onClick={() => {
            formRef.current.handleSubmit();
            !formRef.current.isValid && onOpenAlert();
          }}
          loading={resManualWithJobCreate.isLoading}
        >
          Submit and Resolve
        </Button>
      </Flex>
    </Flex>
  );
}
